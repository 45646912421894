import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { WaypointService } from 'src/app/commons/service/waypoint.service';
import { UserPopupComponent } from 'src/app/profile-shared/user-popup/user-popup.component';
import { environment } from '../../../environments/environment';
import { AlertService } from '../../commons/service/alert.service';
import { CommonService } from '../../commons/service/common.service';
import { HereMapsService } from '../../commons/service/here-maps.service';
import { SharedService } from '../../commons/service/shared.service';
import { AppSettings } from '../../commons/setting/app_setting';
import { CarrierSidePopupComponent } from '../../shared/carrier-side-popup/carrier-side-popup.component';
import { PopupComponent } from '../../shared/popup/popup.component';
import * as moment from 'moment';
import { NetworkPopupComponent } from 'src/app/network-shared/network-popup/network-popup.component';
import { StatusSetting } from 'src/app/commons/setting/status_setting';
import { SubscriptionPopupComponent } from 'src/app/shared/subscription-popup/subscription-popup.component';
// import { MapsAPILoader } from '@agm/core';
import { DisputePopupComponent } from '../dispute-popup/dispute-popup.component';
import { LoadPopupComponent } from '../load-popup/load-popup.component';
import { HereMapsService8 } from 'src/app/commons/service/here-maps8.service';
declare var require: any;

export interface shipInfo {
  amount: any;
  shipmentId: any;
}
@Component({
  selector: 'app-spot-loads-side-panel',
  templateUrl: './spot-loads-side-panel.component.html',
  styleUrls: ['./spot-loads-side-panel.component.scss']
})
export class SpotLoadsSidePanelComponent implements OnInit {
  @Input() shipmentDetail: any;
  public showUnLockButton:boolean=true
  public eCarrierCheckData:any
  @Input() currentStatus: any;
  @Input() currentRouteParams: any;
  @Input() shipmentDetailHome: any;
  @Input() id: any;
  @Input() subscriptionData: any;
  sidePanelLoader: boolean = false;
  @Output() closeSidePanel = new EventEmitter();
  @Output() refreshList = new EventEmitter();
  public userType: any;
  skeletonLoader = true;
  skeletonLoaderhistory = true;
  skeletonLoaderAdd = true;
  documentSklitor = true;
  skeletonLoaderCard = true;
  shipmentHistoryGet: any;
  driverDetails: any;
  public waypoint: any; 
  public isTemprature = false;
  gifLoader: boolean;
  public disableMessage: boolean;
  public currentTabShow: any;
  public subCurrentTab = '';
  public cardListing = [];
  public currentlocation: any;
  public lowestShipmentBid: any;
  public geoCoder: any;
  public locationLat: any;
  public locationLong: any;
  public loading = false;
  public currentLat: any;
  public currentLon: any;
  public currentDate = new Date();
  public totalPage = 1;
  public page = 1;
  public carrierList: any;
  public allCarrierSkeloton = false;
  public bidHistorySekeletonLoader = false;
  public overBidCount: any;
  public card: any;
  public driverDetailsShipment: any;
  public preCarrierList: any;
  public preCarrierSkeloton = false;
  public currentId: any;
  public currentBid: any;
  public acceptBidList: any;
  public previousBid: any;
  public previousBidHistory: any;
  public getDisputeData: any;
  public serverPathForUrl: any;
  public selectedIndex = 0;
  public checkTimeDispute = false;
  public detailTabIndicator: boolean = false;
  public driverTabIndicator: boolean = false;
  public documentTabIndicator: boolean = false;
  public loadHistoryIndicator: boolean = false;
  public shipmentBidIndicator: boolean = false;
  public carrierShipmentBidIndicator: boolean = false;
  public BidHistoryIndicator: boolean = false;
  public etaInformation: any;
  public userId: any;
  public ratingOwnerDetail: {};
  public currentWithdraw = false;
  public isHomePage: boolean = false;
  public ifCurrentBid: boolean = false;
  public ifAcceptBid: boolean = false;
  public ifPreviousBid: boolean = false;
  public ifAccepted: boolean = false;
  public pickupWayPoint: any = [];
  public dropoffWayPoint: any = [];
  public shipmentId: any;
  public userDetails: any;
  public eCarrierCheckRisk:any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private hereMapsService: HereMapsService,
    private hereMapsService8: HereMapsService8,
    public commonService: CommonService,
    public dialog: MatDialog,
    public location: Location,
    private alertService: AlertService,
    private cd: ChangeDetectorRef,
    public sharedService: SharedService,
    private waypointService: WaypointService,
    // private mapsAPILoader: MapsAPILoader,
  ) { }

  async ngOnInit() {
    this.eCarrierCheckRisk=StatusSetting.eCarrierCheckToolTip;
    this.userType = localStorage.getItem('user_type');
    this.sidePanelLoader = true;
    if (this.shipmentDetailHome) {
      this.id = this.shipmentDetailHome;
      this.isHomePage = true;
    } else {
      this.id = this.shipmentDetail.id;
      this.isHomePage = false;
    }

   
    
    this.waypoint = await this.waypointService.wayPointGet(this.id);
    this.sidePanelLoader = false;
    if (this.waypoint) {
      for (let shipmentIndex in this.waypoint) {
        if (this.waypoint && this.waypoint[shipmentIndex].type === 'pickup') {
          this.waypoint[shipmentIndex].date = this.getDateFormat(this.waypoint[shipmentIndex].date.toString());
          this.pickupWayPoint.push(this.waypoint[shipmentIndex]);
        }
        if (this.waypoint && this.waypoint[shipmentIndex].type === 'dropoff') {
          this.waypoint[shipmentIndex].date = this.getDateFormat(this.waypoint[shipmentIndex].date.toString());
          this.dropoffWayPoint.push(this.waypoint[shipmentIndex]);
        }
      }
    }
    if (this.userType === 'CARRIER' && this.shipmentDetail?.carriers?.userId == this.shipmentDetail?.drivers?.userId) {
      this.ratingOwnerDetail = {
        key: 'carrierId',
        id: this.shipmentDetail?.carriers?.userId
      }
    }
    else if(this.userType === 'CARRIER' && this.shipmentDetail?.carriers?.userId != this.shipmentDetail?.drivers?.userId){
      this.ratingOwnerDetail = {
        key: 'driverId',
        id: this.shipmentDetail?.drivers?.userId
      }
    }
    if (this.userType === 'SHIPPER' && this.shipmentDetail?.carriers?.userId == this.shipmentDetail?.drivers?.userId) {
      this.ratingOwnerDetail = {
        key: 'carrierId',
        id: this.shipmentDetail?.carriers?.userId
      }  
    }
    else if(this.userType === 'SHIPPER' && this.shipmentDetail?.carriers?.userId != this.shipmentDetail?.drivers?.userId){
      this.ratingOwnerDetail = {
        key: 'driverId',
        id: this.shipmentDetail?.drivers?.userId
      }
    }
    else if(!this.shipmentDetail?.drivers){
      this.ratingOwnerDetail = {
        key: 'carrierId',
        id: this.shipmentDetail?.carriers?.userId
      }  
    }
   
    this.userType = localStorage.getItem('user_type');
    this.userId = localStorage.getItem('user_id');

    this.detailTabIndicator =
      this.currentStatus === 'inProgressByLoads' || this.currentStatus === 'inProgressByLane' ||
      this.currentStatus === 'upcomingByLoads' || this.currentStatus === 'upcomingByLane'
      this.currentStatus === 'pastByLoads' || this.currentStatus === 'pastByLane' ||
      this.currentStatus === 'pendingByLoads' || this.currentStatus === 'pendingByLane';
      this.currentStatus === 'allLoads' || this.shipmentDetail?.statusSlug === 'readyToBid' ||
      this.currentStatus === 'quotedLoads' ||
        this.currentStatus === 'allLoadsByLane' ||
        this.currentStatus === 'quotedLoadsByLane' ||
        this.currentStatus === 'awardedLoads' ||
        this.currentStatus === 'paymentPending' || this.currentStatus === 'expiredLoadsByLane'
        this.currentStatus === 'loadsAwardedByLane' || this.currentStatus === 'loadsLostByLane' || this.currentStatus === 'offerRateConfirmed' || this.currentStatus === 'expiredLoads';

    this.driverTabIndicator =
      this.currentStatus === 'inProgressByLoads' || this.currentStatus === 'inProgressByLane' ||
      this.currentStatus === 'upcomingByLoads' || this.currentStatus === 'upcomingByLane' ||
      this.currentStatus === 'pendingByLoads' || this.currentStatus === 'pendingByLane';
    this.documentTabIndicator =
      this.currentStatus === 'inProgressByLoads' || this.currentStatus === 'pastByLoads' || this.currentStatus === 'pastByLane' || this.currentStatus === 'inProgressByLane';
    this.loadHistoryIndicator =
      this.currentStatus === 'inProgressByLoads' || this.currentStatus === 'inProgressByLane' ||
      this.currentStatus === 'upcomingByLoads' || this.currentStatus === 'upcomingByLane' ||
      this.currentStatus === 'pastByLoads' || this.currentStatus === 'pastByLane' ||
      this.currentStatus === 'pendingByLoads' || this.currentStatus === 'pendingByLane' || this.currentStatus === 'expiredLoads' || this.currentStatus === 'expiredLoadsByLane';
    this.shipmentBidIndicator =
      (this.currentStatus === 'currentBids' || this.currentStatus === 'quotedLoads' || this.currentStatus === 'awardedLoads'
      || this.currentStatus === 'quotedLoadsByLane' || this.currentStatus === 'loadsAwardedByLane' || this.currentStatus === 'paymentPending' ||
        this.currentStatus === 'confirmedBids') && 
      this.userType === 'SHIPPER';
    this.carrierShipmentBidIndicator =
      (this.currentStatus === 'availableToBid' || this.currentStatus === 'quotedLoads' || this.currentStatus === 'awardedLoads' ||
        this.currentStatus === 'currentBids' ||  this.currentStatus === 'quotedLoadsByLane' || this.currentStatus === 'loadsAwardedByLane' ||
        this.currentStatus === 'awardedBids' || this.currentStatus === 'paymentPending' ||  this.shipmentDetail?.statusSlug === 'readyToBid' ||
        this.currentStatus === 'offerRate' || this.currentStatus === 'offerRateConfirmed') &&
      this.userType === 'CARRIER';
    this.BidHistoryIndicator =
      (this.currentStatus === 'pendingByLoads' || this.currentStatus === 'pendingByLane') && this.userType === 'SHIPPER';
    this.getCurrentStatus('details');

    this.serverPathForUrl = environment.serverPathForUrl;
    this.currentId = localStorage.getItem('user_id');
    if (
      this.shipmentDetail &&
      this.shipmentDetail.drivers &&
      this.userId === this.shipmentDetail.drivers.userId
    ) {
      this.disableMessage = true;
    } else {
      this.disableMessage = false;
    }

    if (this.userType === 'CARRIER') {
      this.getLowestBid();
    }
    let userDetail;
    if (localStorage.getItem('user_type') === 'CARRIER') {
      userDetail = this.sharedService.getAddress();
      if (userDetail !== null) {
        this.userDetails = userDetail;
        this.userInfo(userDetail);
      }

      this.commonService.userData.subscribe((userData) => {
        if(userData){
          this.userDetails=userData;
          this.userInfo(userData);
        }
      });
    }
  }

  userInfo(userDtail) {
    this.userDetails = userDtail;
  }

  getDateFormat(inputDate: any) {
    if (inputDate !== '' && typeof inputDate !== undefined) {
      return inputDate.replaceAll('-', '/');
    } else {
      return '';
    }
  }

  checkCardDAy(stday: any) {
    let daysnubmber;
    if (stday) {
      daysnubmber = stday;
    } else {
      daysnubmber = AppSettings.laneaxisTerms;
    }
    return daysnubmber;
  }

  getTripStatus(tripStatus) {
    var moment = require('moment-timezone');
    if (tripStatus) {
      this.shipmentDetail.tripStatus = tripStatus;
      this.showPosition(
        this.shipmentDetail.tripStatus.latitude,
        this.shipmentDetail.tripStatus.longitude
      );
      this.gifLoader = false;
      if (tripStatus.ETA) {
        let dropTimeZone = this.hereMapsService.getTimeZone(
          this.shipmentDetail.dropTimeZone
        );
        let currentTime = moment().tz(dropTimeZone).format('MM/DD/YYYY HH:mm');
        let currentTS = Date.parse(currentTime);
        this.etaInformation = tripStatus.ETA + currentTS;
      }
    } else {
      this.etaInformation = null;
    }
  }

  checkWithdrawCondition(actionByUserID) {
    let user_id = localStorage.getItem('user_id');
    if (user_id === actionByUserID && this.userType === 'CARRIER') return true;
    else return false;
  }

  checkAcceptCondition(actionByUserID, statusLabel) {
    let user_id = localStorage.getItem('user_id');
    if (
      statusLabel !== 'Awaiting confirmation' &&
      user_id !== actionByUserID &&
      this.userType === 'CARRIER'
    )
      return true;
    else return false;
  }

  //To copy web tracking url
  getWebTrackingLink() {
    if (
      this.shipmentDetail &&
      this.shipmentDetail.shippers &&
      this.shipmentDetail.shippers.email
    ) {
      var url = environment.domainPoint + '/load/track-your-load?load_id=' + this.shipmentDetail.uniqueId 
    } 
    else if (this.shipmentDetail && this.shipmentDetail.shipperEmail) {
       url = environment.domainPoint + '/load/track-your-load?load_id=' + this.shipmentDetail.uniqueId 
    }
    this.copyWebTrackingLink(url);
  }

  //copying web tracking link
  copyWebTrackingLink(val: string) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.alertService.showNotificationMessage(
      'success',
      'bottom',
      'right',
      'txt_s',
      'check_circle',
      'Web Tracking Link',
      'Web tracking link copied.'
    );
  }

  checkEditShipmentCondition() {
    if (this.userType === 'SHIPPER' && this.shipmentDetail && this.shipmentDetail.shippers &&
      (this.shipmentDetail.statusSlug === 'readyToBid' || this.shipmentDetail.statusSlug === 'offerRate' ||
        this.currentStatus === 'inProgressByLoads' || this.currentStatus === 'inProgressByLane' || this.currentStatus === 'upcomingByLoads' || this.currentStatus === 'upcomingByLane' || this.currentStatus === 'pendingByLoads' || this.currentStatus === 'pendingByLane')) {
      return true;
    } else if (this.userType === 'CARRIER' && this.shipmentDetail && this.shipmentDetail.carriers && !this.shipmentDetail.shippers &&
      (this.currentStatus === 'inProgressByLoads' || this.currentStatus === 'inProgressByLane' || this.currentStatus === 'upcomingByLoads' || this.currentStatus === 'upcomingByLane' || this.currentStatus === 'pendingByLoads' || this.currentStatus === 'pendingByLane' ||
        !(this.currentStatus === 'pastByLoads' || this.currentStatus === 'pastByLane' || this.currentStatus === 'dispute'))) {
      return true;
    } else {
      return false;
    }
  }

  checkCancelShipmentCondition() {
    if (
      this.userType === 'SHIPPER' &&
      this.shipmentDetail &&
      this.shipmentDetail.shippers &&
      this.shipmentDetail.status !== '15' &&
      (this.currentStatus === 'availableLoads' ||
        this.currentStatus === 'readyToBid' || this.currentStatus === 'inProgressByLoads' || this.currentStatus === 'inProgressByLane' ||
        this.currentStatus === 'upcomingByLoads' || this.currentStatus === 'upcomingByLane' ||
        this.currentStatus === 'pendingByLoads' || this.currentStatus === 'pendingByLane')
    ) {
      return true;
    } else if (
      this.userType === 'CARRIER' &&
      this.shipmentDetail &&
      this.shipmentDetail.carriers &&
      this.shipmentDetail.status !== '15' &&
      !this.shipmentDetail.shippers &&
      (this.currentStatus === 'inProgressByLoads' || this.currentStatus === 'inProgressByLane' ||
        this.currentStatus === 'upcomingByLoads' || this.currentStatus === 'upcomingByLane' ||
        this.currentStatus === 'pendingByLoads' || this.currentStatus === 'pendingByLane')
    ) {
      return true;
    } else {
      return false;
    }
  }

  // Function to get Track Shipment Condition 
  checkWebTrackShipmentCondition() {
    if (
      this.currentStatus === 'availableToBid' || this.currentStatus === 'quotedLoads' || this.currentStatus === 'paymentPending' ||
      this.currentStatus === 'currentBids' || this.shipmentDetail.statusSlug === 'readyToBid' || 
      this.currentStatus === 'awardedBids' ||
      this.currentStatus === 'awardedLoads' ||
      this.currentStatus === 'offerRate' ||
      this.currentStatus === 'offerRateConfirmed'
    ) {
      return false;
    } else if (
      this.shipmentDetail &&
      this.shipmentDetail.shippers &&
      this.shipmentDetail.shippers.email &&
      this.shipmentDetail.status !== '59'
    ) {
      return true;
    } else if (
      this.shipmentDetail &&
      this.shipmentDetail.shipperEmail &&
      this.shipmentDetail.status !== '59'
    ) {
      return true;
    } else {
      return false;
    }
  }

  checkMessageShipmentCondition() {
    if (
      this.currentStatus === 'availableToBid' || this.currentStatus === 'quotedLoads' 
      || this.currentStatus === 'paymentPending' || this.currentStatus === 'expiredLoads' || this.currentStatus === 'expiredLoadsByLane' ||
      this.currentStatus === 'currentBids' || this.shipmentDetail.statusSlug === 'readyToBid' ||
      this.currentStatus === 'awardedBids' ||
      this.currentStatus === 'awardedLoads' ||
      this.currentStatus === 'offerRate' ||
      this.currentStatus === 'offerRateConfirmed'
    ) {
      return false;
    } else if (
      this.shipmentDetail &&
      this.shipmentDetail.shippers &&
      this.shipmentDetail.shippers.email &&
      this.shipmentDetail.status !== '59'
    ) {
      return true;
    } else if (
      this.shipmentDetail &&
      this.shipmentDetail.shipperEmail &&
      this.shipmentDetail.status !== '59'
    ) {
      return true;
    } else {
      return false;
    }
  }

  checkFileDisputeShipmentCondition() {
    if (this.shipmentDetail && this.shipmentDetail.updatedAt) {
      let days = 1;
      let deliveredDate = new Date(this.shipmentDetail.updatedAt + ' ' + 'UTC');
      deliveredDate.setDate(deliveredDate.getDate() + days);
      let now = new Date();
      const deliveryDateTime: any =
        moment(deliveredDate).format('YYYY-MM-DD HH:mm');
      const currentDateTime: any = moment(now).format('YYYY-MM-DD HH:mm');
      if (deliveryDateTime > currentDateTime) {
        this.checkTimeDispute = true;
      } else {
        this.checkTimeDispute = false;
      }
    } else {
      this.checkTimeDispute = false;
    }

    if (
      this.shipmentDetail &&
      this.checkTimeDispute &&
      this.shipmentDetail.shippers &&
      this.shipmentDetail.status === 51
    ) {
      return true;
    } else {
      return false;
    }
  }

  shipmentGet() {
    this.sidePanelLoader = true;
    return new Promise((resolve, reject) => {
      let uri = null;
      let newParams = {
        id: this.id,
      };
      if (newParams) uri = this.commonService.getAPIUriFromParams(newParams);
      let APIparams = {
        apiKey: AppSettings.APIsNameArray.SHIPMENT.NEWGET,
        uri: uri,
      };
      this.commonService.getList(APIparams).subscribe(async (ServerRes) => {
        if (ServerRes.success === true) {
          this.sidePanelLoader = false;
          ServerRes.response.dropStatus = this.waypoint[this.waypoint.length - 1].status;
          ServerRes.response.dropTimeZone = this.waypoint[this.waypoint.length - 1].timeZone;
          ServerRes.response.dropTimeslot = this.waypoint[this.waypoint.length - 1].timeSlot;
          ServerRes.response.dropDate = this.waypoint[this.waypoint.length - 1].date;
          this.shipmentDetail = ServerRes.response;
          if (
            this.shipmentDetail && this.sharedService.checkEligiblityForTripStatus(ServerRes.response.status) && !this.isHomePage) {
            if (ServerRes.response.status < 42) {
              this.gifLoader = true;
              this.hereMapsService8
                .calculateMultiRoute(this.shipmentDetail, this.waypoint).subscribe((hereMapsRoutingResponse)=>{
                  if (hereMapsRoutingResponse) {
                    var tripStatus = this.hereMapsService.calculateTripStatus(
                      hereMapsRoutingResponse,
                      this.shipmentDetail
                    );
                    if (tripStatus) this.getTripStatus(tripStatus);
                  }
                })
            } else {
              var tripStatus =
                this.hereMapsService.calculateRoutewithCallingHereMaps(
                  ServerRes.response
                );
              this.getTripStatus(tripStatus);
            }
          } else {
            this.etaInformation = null;
          }
          resolve(ServerRes.response);
        } else {
          resolve(null);
          this.sidePanelLoader = false;
        }
      });
    });
  }

  async getCurrentStatus(type: any) {
    if (type === 'details') {
      
      if (!this.shipmentDetail) {
        this.shipmentDetail = await this.shipmentGet();
      } else {
        if (
          this.shipmentDetail &&
          this.shipmentDetail.tripStatus &&
          this.shipmentDetail.Text !== ''
        )
         {
          this.getTripStatus(this.shipmentDetail.tripStatus);}
      }
      if (this.shipmentDetail && this.shipmentDetail.createdAt) {
        this.shipmentDetail.createdAt = new Date(
          this.shipmentDetail.createdAt + ' ' + 'UTC'
        );
      }
      if (this.shipmentDetail && this.shipmentDetail.updatedAt) {
        this.shipmentDetail.updatedAt = new Date(
          this.shipmentDetail.updatedAt + ' ' + 'UTC'
        );
      }
    } else if (type === 'action') {
      this.shipmentDetail = await this.shipmentGet();
    }
    if (this.currentStatus === 'inProgressByLoads' || this.currentStatus === 'inProgressByLane' || this.currentStatus === 'pendingByLoads' || this.currentStatus === 'pendingByLane' || this.currentStatus === 'upcomingByLoads' 
    || this.currentStatus === 'upcomingByLane' || this.currentStatus === 'inProgress' || this.currentStatus === 'pending' || this.currentStatus === 'upcoming' )
      this.currentTabShow = 'driver';
    if (this.currentStatus === 'expiredLoads' || this.currentStatus === 'expiredLoadsByLane') {
      this.currentTabShow = 'details';
      this.skeletonLoader = false;
    }
    if (this.currentStatus === 'pastByLoads' || this.currentStatus === 'pastByLane') {
      if (this.shipmentDetail.status !== 59 && this.shipmentDetail.status !== 15) {
        this.currentTabShow = 'document';
      } else {
        this.currentTabShow = 'details';
        this.skeletonLoader = false;
      }

    }
    if (
      (this.currentStatus === 'availableToBid' || this.currentStatus === 'allLoadsByLane' ||
        this.currentStatus === 'currentBids' || this.currentStatus === 'allLoads' || this.currentStatus === 'loadsAwardedByLane' || this.currentStatus === 'loadsLostByLane' ||
        this.currentStatus === 'awardedBids' || this.currentStatus === 'quotedLoads' || this.currentStatus === 'awardedLoads' || this.currentStatus === 'paymentPending' ||
        this.currentStatus === 'offerRate' || this.currentStatus === 'quotedLoadsByLane' || this.currentStatus === 'offerRateConfirmed' ||
        this.currentStatus === 'offerRateConfirmed') &&
      this.userType === 'CARRIER'
    ) {
      this.currentTabShow = 'carrierLoadBids';
      this.getHistoryData();
    }

    if (
      (this.currentStatus === 'currentBids' ||
        this.currentStatus === 'confirmedBids') &&
      this.userType === 'SHIPPER'
    ) {
      this.currentTabShow = 'loadBids';
      this.getPreData();
      this.getData();
      this.getCardListing();
    }
    if (this.currentStatus === 'nonAwardedBids' || this.currentStatus === 'loadsLost') {
      this.currentTabShow = 'history';
      this.bidHistorySekeletonLoader = true;
      this.getHistoryData();
    }
  }

  closePanel(value: any, type: any) {
    this.closeSidePanel.emit({ value, type });
  }

  async currentTabActive(type: any) {
    if (
      this.currentStatus === 'inProgressByLoads' || this.currentStatus === 'inProgressByLane' || this.currentStatus === 'inProgress' ||
      this.currentStatus === 'upcomingByLoads' || this.currentStatus === 'upcomingByLane' || this.currentStatus === 'upcoming' ||
      this.currentStatus === 'pastByLoads' || this.currentStatus === 'pastByLane' || this.currentStatus === 'expiredLoadsByLane' || this.currentStatus === 'past' ||
      this.currentStatus === 'pendingByLoads' || this.currentStatus === 'pendingByLane' || this.currentStatus === 'expiredLoads' || this.currentStatus === 'pending'
    )
      this.loadHistoryIndicator = true;

    if (type === 'details') {

      this.currentTabShow = type;
      this.skeletonLoader = false;
      if (this.userType === 'CARRIER') {
        this.ratingOwnerDetail = {
          key: 'shipperId',
          id: this.shipmentDetail?.shippers?.userId
        }
      }
      if (this.userType === 'SHIPPER') {
        this.ratingOwnerDetail = {
          key: 'carrierId',
          id: this.shipmentDetail?.carriers?.userId
        }
      }
    }
    if (type === 'driver') {
      this.currentTabShow = type;
      if (this.shipmentDetail.tripStatus) {
        if (
          this.shipmentDetail.tripStatus.latitude &&
          this.shipmentDetail.tripStatus.longitude
        ) {
          this.showPosition(
            this.shipmentDetail.tripStatus.latitude,
            this.shipmentDetail.tripStatus.longitude
          );
        }
      } else {
        this.documentSklitor = false;
        this.currentLon = undefined;
        this.currentLat = undefined;
        this.driverDetails = '';
      }
      if (this.userType === 'CARRIER' && this.shipmentDetail?.carrierId == this.shipmentDetail?.driverId) {
        this.ratingOwnerDetail = {
          key: 'carrierId',
          id: this.shipmentDetail?.carriers?.userId
        }
      }
    
     else if (this.userType === 'CARRIER' && this.shipmentDetail?.carrierId != this.shipmentDetail?.driverId) {
        this.ratingOwnerDetail = {
          key: 'driverId',
          id: this.shipmentDetail?.drivers?.userId
        }
      }
     else if (this.userType === 'SHIPPER' && this.shipmentDetail?.carrierId == this.shipmentDetail?.driverId) {
        this.ratingOwnerDetail = {
          key: 'carrierId',
          id: this.shipmentDetail?.carriers?.userId
        }
      }
     else if (this.userType === 'SHIPPER' && this.shipmentDetail?.carrierId != this.shipmentDetail?.driverId) {
        this.ratingOwnerDetail = {
          key: 'driverId',
          id: this.shipmentDetail?.drivers?.userId
        }
      }
      else if(!this.shipmentDetail?.driverId){
        this.ratingOwnerDetail = {
          key: 'carrierId',
          id: this.shipmentDetail?.carriers?.userId
        }
      }
    }
   
    if (type === 'load') {
      this.loadHistoryIndicator = false;
      this.currentTabShow = type;
      this.skeletonLoaderhistory = true;
      this.shipmentHistory();
     
    }
    if (type === 'document') {
      this.currentTabShow = type;
      this.documentSklitor = true;
    }
    if (type === 'loadBids') {
      this.currentTabShow = 'loadBids';
      this.getPreData();
      this.getData();
      this.getCardListing();
    }
    if (type === 'history') {
      this.currentTabShow = 'history';
      this.bidHistorySekeletonLoader = true;
      this.getHistoryData();
    }
    if (type === 'assingDriver') {
      this.currentTabShow = 'assingDriver';
    }
    if (type === 'changeDriver') {
      this.currentTabShow = 'changeDriver';
    }
    if (type === 'carrierLoadBids') {
      this.currentTabShow = 'carrierLoadBids';
      this.getHistoryData();
    }
    if (type === 'dispute') {
      this.currentTabShow = 'dispute';
    }
  }

  // Shipment history data
  shipmentHistory() {
    let uri = null;
    let params = {
      shipmentId: this.shipmentDetail.id,
    };
    //get dynamic URI for APIs
    if (params) uri = this.commonService.getAPIUriFromParams(params);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.SHIPMENT.SHIPMENTHISTORY,
      uri: uri,
    };
    this.commonService.getList(APIparams).subscribe(
      (ServerRes) => {
        if (ServerRes.success === true) {
          this.shipmentHistoryGet = ServerRes.response;
          for (var docIndex in this.shipmentHistoryGet) {
            this.shipmentHistoryGet[docIndex].createdAt = new Date(
              this.shipmentHistoryGet[docIndex].createdAt + ' ' + 'UTC'
            );
          }
          this.skeletonLoaderhistory = false;
        } else {
          this.shipmentHistoryGet = [];
          this.skeletonLoaderhistory = false;
        }
      },
      (error) => {
        this.shipmentHistoryGet = [];
        this.skeletonLoaderhistory = false;
      }
    );
  }

  getLowestBid() {
    let uri = null;
    if (this.shipmentDetailHome) {
      this.id = this.shipmentDetailHome;
    } else {
      this.id = this.shipmentDetail.id;
    }

    let params = {
      shipmentId: this.id,
    };
    //get dynamic URI for APIs
    if (params) uri = this.commonService.getAPIUriFromParams(params);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.BIDS.LOWESTBID,
      uri: uri,
    };
    this.commonService.getList(APIparams).subscribe(
      (ServerRes) => {
        if (ServerRes.success === true) {
          this.lowestShipmentBid = ServerRes.response;
          this.skeletonLoaderhistory = false;
        } else {
          this.lowestShipmentBid = [];
          this.skeletonLoaderhistory = false;
        }
      },
      (error) => {
        this.lowestShipmentBid = [];
        this.skeletonLoaderhistory = false;
      }
    );
  }

 


  showPosition(lat, lng) {
    const apiKey = 'AIzaSyAMMBwZYg03hSwJEnODnIWf14YJKjWz_2A';
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;
    let that = this;
    if(!lat || !lng)
      {
        return
      }
    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(response => {
        let results = response?.results
        let newname = '';
        if (results[0].address_components[3] !== undefined) {
          newname = results[0].address_components[3].long_name;
        }else if (results[1]?.address_components[3]?.long_name !== undefined) {
          newname = results[1].address_components[3].long_name;
        } else {
          newname = '';
        }
        that.currentlocation = (results[0].formatted_address ? results[0].formatted_address : results[1].formatted_address);
        that.cd.detectChanges();
      })
      .catch(error => {
        console.error('Error fetching address:', error);
        that.currentlocation = '-';
        that.cd.detectChanges(); // return addressDriver;
      });
  }

  public generateFake(count: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes;
  }

  getAPIParam() {
    let APIparams, params;
    params = {
      limit: 10,
      tabType: 'connected',
      shipmentId: this.shipmentDetail.id,
      isBid: 1,
      page: this.page,
    };
    APIparams = {
      apiKey: AppSettings.APIsNameArray.SHIPMENTFORSHIPPER.CARRIER,
      uri: this.commonService.getAPIUriFromParams(params),
    };
    return APIparams;
  }

  getData() {
    this.allCarrierSkeloton = false;
    this.page = 1;
    var APIparams = this.getAPIParam();
    this.commonService.getList(APIparams).subscribe((ServerRes) => {
      this.allCarrierSkeloton = true;
      if (ServerRes.response && ServerRes.response.length > 0) {
        for (let v = 0; v < ServerRes.response.length; v++) {
          ServerRes.response[v].isSelected = false;
        }
        this.carrierList = ServerRes.response;
        this.totalPage = ServerRes.totalPages;
      } else {
        this.carrierList = [];
        this.totalPage = 0;
        this.selectedIndex = 0;
      }
    });
  }
  
  // Prefered carrier
  getPreAPIParam() {
    let APIparams, params;
    params = {
      limit: 10,
      tabType: 'preferred',
      shipmentId: this.route.snapshot.params['loadId'],
      isBid: 1,
      page: this.page,
    };
    APIparams = {
      apiKey: AppSettings.APIsNameArray.SHIPMENTFORSHIPPER.CARRIER,
      uri: this.commonService.getAPIUriFromParams(params),
    };
    return APIparams;
  }

  //py : using pre carrier
  getPreData() {
    this.preCarrierSkeloton = false;
    this.page = 1;
    var APIparams = this.getPreAPIParam();
    this.commonService.getList(APIparams).subscribe((ServerRes) => {
      this.preCarrierSkeloton = true;
      if (ServerRes.response && ServerRes.response.length > 0) {
        this.selectedIndex = 0;
        for (let v = 0; v < ServerRes.response.length; v++) {
          ServerRes.response[v].isPSelected = false;
        }
        this.preCarrierList = ServerRes.response;
        this.totalPage = ServerRes.totalPages;
      } else {
        this.preCarrierList = [];
        this.totalPage = 1;
        this.selectedIndex = 1;
      }
    });
  }

  getHiAPIParam() {
    let APIparams, params;
    params = { limit: 10, shipmentId: this.shipmentDetail.id, page: this.page };
    APIparams = {
      apiKey: AppSettings.APIsNameArray.BIDS.HISTORY,
      uri: this.commonService.getAPIUriFromParams(params),
    };
    return APIparams;
  }

  //Bid history
  getHistoryData() {
    this.bidHistorySekeletonLoader = true;
    this.page = 1;
    var APIparams = this.getHiAPIParam();
    this.commonService.getList(APIparams).subscribe((ServerRes) => {
      this.bidHistorySekeletonLoader = false;
      this.overBidCount = ServerRes.overBidCount;

      if (ServerRes.response) {
        if (ServerRes.response.currentBids) {
          for (let v = 0; v < ServerRes.response.currentBids.length; v++) {
            ServerRes.response.currentBids[v].actionTs = new Date(
              ServerRes.response.currentBids[v]['actionTs'] + ' ' + 'UTC'
            );
          }
        }
        this.currentBid = ServerRes.response.currentBids;
        if (ServerRes.response.acceptedBids) {
          for (let v = 0; v < ServerRes.response.acceptedBids.length; v++) {
            ServerRes.response.acceptedBids[v].actionTs = new Date(
              ServerRes.response.acceptedBids[v]['actionTs'] + ' ' + 'UTC'
            );
          }
        }
        this.acceptBidList = ServerRes.response.acceptedBids;

        if (ServerRes.response.previousBids) {
          for (let v = 0; v < ServerRes.response.previousBids.length; v++) {
            ServerRes.response.previousBids[v].actionTs = new Date(
              ServerRes.response.previousBids[v]['actionTs'] + ' ' + 'UTC'
            );
          }
        }
        this.previousBid = ServerRes.response.previousBids;
        this.previousBidHistory = ServerRes.response.previousBids;

        this.currentBid.forEach((value) => {
          if (value.status == '4' || this.shipmentDetail.visibility === 'private') {
            this.ifAccepted = true;
          } else {
            this.ifAccepted = false;
          }
        });

        this.currentBid.forEach((value) => {
          if (value.status == '4' || value.actionBy == this.currentId || this.shipmentDetail.visibility === 'private') {
            this.ifCurrentBid = true;
          } else {
            this.ifCurrentBid = false;
          }
        });
        this.acceptBidList.forEach((value) => {
          if (value.status == '4' || value.actionBy == this.currentId || this.shipmentDetail.visibility === 'private') {
            this.ifAcceptBid = true;
          } else {
            this.ifAcceptBid = false;
          }
        });
        this.currentBid.forEach((value) => {
          if (value.status == '4' || this.shipmentDetail.visibility === 'private') {
            this.ifPreviousBid = true;
          } else {
            this.ifPreviousBid = false;
          }
        });


        this.totalPage = ServerRes.totalPages;

        let newAmount;
        if (this.currentBid.length > 0) {
          newAmount = this.currentBid[0].bidAmount;
        } else {
          newAmount = null;
        }
        if (this.currentWithdraw) {
          const shipmentData = {
            amount: newAmount,
            shipmentId: this.shipmentDetail.id,
          };
          this.sharedService.updateBidAmount.next(shipmentData);
          this.currentWithdraw = false;
        }
      } else {
        this.currentBid = [];
        this.acceptBidList = [];
        this.previousBid = [];
        this.previousBidHistory = [];
        this.totalPage = 0;
      }
    });
  }

  getCardListing(params = {}) {
    this.skeletonLoaderCard = false;
    let uri = null;
    let paramsNew = {
      type: 'card',
    };
    if (paramsNew) uri = this.commonService.getAPIUriFromParams(paramsNew);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.LOADPAYMENT.CARDLIST,
      uri: uri,
    };
    this.commonService.getList(APIparams).subscribe(
      (ServerRes) => {
        if (ServerRes.success === true) {
          this.cardListing = ServerRes.response.data;
          this.skeletonLoaderCard = true;
        } else {
          this.cardListing = [];
          this.skeletonLoaderCard = true;
        }
      },
      (error) => {
        this.skeletonLoaderCard = true;
        this.cardListing = [];
      }
    );
  }

  // Assign driver and change driver
  shipmentAssignDriver(shipmentDetail: any) {
    const dialogRef = this.dialog.open(CarrierSidePopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width: '530px',
      data: {
        openPop: 'crtAssignDriver',
        shipmentRecord: this.waypoint,
        shipmentDetail: shipmentDetail,
        typeSection: 'changeDriver',
        occurence: 'side_panel',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.event === 'success') {
        this.id = result.id;
        this.getCurrentStatus('action');
      } 
    });
  }

  // Carrier side accept bid popup
  acceptBidCarrier(bidId: any,amount: any,note: any,userName: any,companyName: any,paymentMethod: any,bidingStatus: any) {
    const dialogRef = this.dialog.open(LoadPopupComponent, {
      backdropClass: AppSettings.backdropClass,
      disableClose: true,
      width: AppSettings.popWidth,
      data: {
        openPop: 'acceptBid',
        userName: companyName,
        userNameOther: userName,
        amount: amount,
        note: note,
        bidId: bidId,
        shipmentId: this.shipmentDetail.id,
        uniqueId: this.shipmentDetail.uniqueId,
        paymentMethod: paymentMethod,
        bidingStatus: bidingStatus,
        occurence: 'side_panel',
        shipmentDetail: this.shipmentDetail,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'Ok') {
          this.currentTabActive('carrierLoadBids');
          this.refreshList.emit();
        }else if(result.event === 'accept_and_confirm'){
          this.acceptConfirm(bidId,amount,note,userName,companyName,paymentMethod,bidingStatus);
        }
      }
    });
  }

  bidOverBids(
    bidFor: any,
    shipperName: any,
    amount: any,
    paymentMethod: any,
    contractDocument: any,
    shipmentId: any
  ) {
    const dialogRef = this.dialog.open(LoadPopupComponent, {
      backdropClass: AppSettings.backdropClass,
      disableClose: true,
      width: AppSettings.popWidth,
      data: {
        openPop: 'overBids',
        userNameOther: shipperName,
        userName: shipperName,
        shipmentId: shipmentId,
        bidFor: bidFor,
        amount: amount,
        paymentMethod: paymentMethod,
        contractDocument: contractDocument,
        freightRate: this.shipmentDetail.freightRate,
        occurence: 'side_panel',
        shipmentDetail: this.shipmentDetail,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'Ok') {
          this.getHistoryData();
          this.refreshList.emit();
        }
      }
    });
  }


  bidPopup(bidFor: any,shipperName: any,amount: any,paymentMethod: any,contractDocument: any,inputAmout?:any)
  {
    const dialogRef = this.dialog.open(LoadPopupComponent, {
      backdropClass: AppSettings.backdropClass,
      disableClose: true,
      width: AppSettings.popWidth,
      data: {
        openPop: 'bid',
        userNameOther: shipperName,
        userName: shipperName,
        shipmentId: this.id,
        bidFor: bidFor,
        amount: amount,
        paymentMethod: paymentMethod,
        contractDocument: contractDocument,
        occurence: 'side_panel',
        shipmentDetail: this.shipmentDetail,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'Ok') {
          
          this.currentTabActive('carrierLoadBids');
          this.refreshList.emit();
        }
      }
    });
  }


  offerRatePopup( bidFor,
    shipperName,
    amount,
    paymentMethod,
    contractDocument,
    shipmentId)
  {
    let actionByOffer;
    if (
      this.shipmentDetail.isOfferRate === 'Y' &&
      this.shipmentDetail.overBid === 'Y'
    ) {
      actionByOffer = 'confirmOfferPrice';
    } else {
      actionByOffer = 'No';
    }
    const dialogRef = this.dialog.open(LoadPopupComponent, {
      backdropClass: AppSettings.backdropClass,
      disableClose: true,
      width: AppSettings.popWidth,
      data: {
        openPop: 'acceptOfferRate',
        userNameOther: shipperName,
        userName: shipperName,
        shipmentId: shipmentId,
        bidFor: bidFor,
        amount: amount,
        paymentMethod: paymentMethod,
        contractDocument: contractDocument,
        actionByOffer: actionByOffer,
        occurence: 'side_panel',
        shipmentDetail: this.shipmentDetail,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'Ok') {
          this.getHistoryData();
          this.refreshList.emit();
        }
      }
    });
  }

  checkCertificateInsuranceOffer(bidFor:any,shipperName:any,amount:any,paymentMethod:any,contractDocument:any,shipmentId:any){
    const dialogRef = this.dialog.open(UserPopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width: "670px",
      data: { openPop: "certificate"},
    });
    dialogRef.afterClosed().subscribe((result) => {
      if(result.event=="Ok"){
        this.offerRatePopup(bidFor,shipperName,amount,paymentMethod,contractDocument,shipmentId)
      }
    });
  }

  acceptOfferRate(bidFor: any,shipperName: any,amount: any,paymentMethod: any,contractDocument: any,shipmentId: any) {
    if(!this.shipmentDetail.bid){
      this.openEquipmentPopup(bidFor,shipperName,amount,paymentMethod,contractDocument,shipmentId,'offerRate')
    }else if(this.userDetails.coiExpired){
      this.checkCertificateInsuranceOffer(bidFor,shipperName,amount,paymentMethod,contractDocument,shipmentId)
    }else {
      this.offerRatePopup(bidFor,shipperName,amount,paymentMethod,contractDocument,shipmentId)
    }
  }


  openEquipmentPopup(bidFor: any,shipperName: any,amount: any,paymentMethod: any,contractDocument: any,inputAmout?:any, type?:any){   
      const dialogRef = this.dialog.open(PopupComponent, {
        backdropClass: AppSettings.backdropClass,
        width: AppSettings.popWidth,
        disableClose: true,
        data: {
          openPop: 'equipmentNotMatchShipment',
          contain:this.shipmentDetail
        },
      });
      dialogRef.afterClosed().subscribe((result) => {

        
        if(result.event =='Ok' && type =='bid'){ 
          this.bidPopup(bidFor,shipperName,amount,paymentMethod,contractDocument,inputAmout)
        }else if(this.userDetails.coiExpired && type=="offerRate"){
          this.checkCertificateInsuranceOffer(bidFor,shipperName,amount,paymentMethod,contractDocument,this.shipmentDetail.shipment_id)
        }else if (result.event =='Ok' && type !='bid'){
          this.openDriverAlertPopupBid(bidFor,shipperName,amount,paymentMethod,contractDocument,this.shipmentDetail.shipment_id)
        }  
      });
  }

  openDriverAlertPopupBid(bidFor,shipperName,amount,paymentMethod,contractDocument,shipment_id)
  {
    const dialogRef = this.dialog.open(PopupComponent, {
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      height:'600px',
      disableClose: true,
      data: {
        openPop: 'driverMobileNotification',
        contain:event
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.event == 'Ok') {
        this.offerRatePopup(bidFor,shipperName,amount,paymentMethod,contractDocument,shipment_id)
      }
    });
  }

  confirmBidOfferRate(
    bidId: any,
    bidFor: any,
    shipperName: any,
    amount: any,
    paymentMethod: any,
    contractDocument: any,
    shipmentId: any
  ) {
    let actionByOffer;
    if (
      this.shipmentDetail.isOfferRate === 'Y' &&
      this.shipmentDetail.overBid === 'Y'
    ) {
      actionByOffer = 'confirmOfferPrice';
    } else {
      actionByOffer = 'No';
    }
    const dialogRef = this.dialog.open(LoadPopupComponent, {
      backdropClass: AppSettings.backdropClass,
      disableClose: true,
      width: AppSettings.popWidth,
      data: {
        openPop: 'acceptBidConfirmOffer',
        userNameOther: shipperName,
        userName: shipperName,
        shipmentId: shipmentId,
        bidId: bidId,
        uniqueId: this.shipmentDetail.uniqueId,
        bidFor: bidFor,
        amount: amount,
        paymentMethod: paymentMethod,
        contractDocument: contractDocument,
        actionByOffer: actionByOffer,
        occurence: 'side_panel',
        shipmentDetail: this.shipmentDetail,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'Ok') {
          this.getHistoryData();
          this.refreshList.emit();
        }
      }
    });
  }

  openDriverAlertPopupConfirm(bidId: any,amount: any, note: any, userName: any, companyName: any, paymentMethod: any, bidingStatus: any)
  {
    const dialogRef = this.dialog.open(PopupComponent, {
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      height:'600px',
      disableClose: true,
      data: {
        openPop: 'driverMobileNotification',
        contain:event
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.event == 'Ok') {
        this.acceptConfirm(bidId,amount, note, userName, companyName, paymentMethod, bidingStatus)
      }
    });
  }

  // Carrier side confirm bid popup
  acceptConfirm( bidId: any,amount: any, note: any, userName: any, companyName: any, paymentMethod: any, bidingStatus: any){
    if(this.userDetails.coiExpired){
      const dialogRef = this.dialog.open(UserPopupComponent, {
        disableClose: true,
        backdropClass: AppSettings.backdropClass,
        width: "670px",
        data: { openPop: "certificate"},
      });
      dialogRef.afterClosed().subscribe((result) => {
        if(result.event=='Ok'){
          this.getHistoryData();
          this.refreshList.emit();
        }
      });
   }else{
    this.acceptConfirmCheck(bidId,amount,note,userName,companyName,paymentMethod,bidingStatus)
   }
}

acceptConfirmCheck(
    bidId: any,
    amount: any,
    note: any,
    userName: any,
    companyName: any,
    paymentMethod: any,
    bidingStatus: any
  ) {
    const dialogRef = this.dialog.open(LoadPopupComponent, {
      backdropClass: AppSettings.backdropClass,
      disableClose: true,
      width: AppSettings.popWidth,
      data: {
        openPop: 'acceptBidConfirm',
        userName: companyName,
        userNameOther: userName,
        amount: amount,
        note: note,
        bidId: bidId,
        shipmentId: this.shipmentDetail.id,
        uniqueId: this.shipmentDetail.uniqueId,
        paymentMethod: paymentMethod,
        bidingStatus: bidingStatus,
        occurence: 'side_panel',
        shipmentDetail: this.shipmentDetail,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'Ok') {
          this.currentTabActive('carrierLoadBids');
          this.refreshList.emit();
        }
      }
    });
  }

  // Cancel bid popup
  cancelBid(
    bidId: any,
    amount: any,
    note: any,
    userName: any,
    paymentMethod: any
  ) {
    const dialogRef = this.dialog.open(LoadPopupComponent, {
      backdropClass: AppSettings.backdropClass,
      disableClose: true,
      width: AppSettings.popWidth,
      data: {
        openPop: 'cancelreBid',
        userName: userName,
        bidId: bidId,
        amount: amount,
        note: note,
        shipmentId: this.shipmentDetail.id,
        uniqueId: this.shipmentDetail.uniqueId,
        paymentMethod: paymentMethod,
        occurence: 'side_panel',
        shipmentDetail: this.shipmentDetail,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'Ok') {
          this.currentTabActive('carrierLoadBids');
          this.refreshList.emit();
        }
      }
    });
  }
  // rebid bid popup
  redBid(
    amount: any,
    note: any,
    bidFor: any,
    userName: any,
    paymentMethod: any
  ) {
    const dialogRef = this.dialog.open(LoadPopupComponent, {
      backdropClass: AppSettings.backdropClass,
      disableClose: true,
      width: AppSettings.popWidth,
      data: {
        openPop: 'rebid',
        userNameOther: this.shipmentDetail.shippers.companyName,
        userName: userName,
        amount: amount,
        note: note,
        shipmentId: this.shipmentDetail.id,
        bidFor: bidFor,
        paymentMethod: paymentMethod,
        occurence: 'side_panel',
        shipmentDetail: this.shipmentDetail,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'Ok') {
          this.refreshList.emit();
          
          this.currentTabActive('carrierLoadBids');
        }
      }
    });
  }
  // Enter bid popup
  bid(bidFor: any,shipperName: any,amount: any,paymentMethod: any,contractDocument: any,inputAmout?:any) {
    if(!this.shipmentDetail.bid)
    {
      this.openEquipmentPopup(bidFor,shipperName,amount,paymentMethod,contractDocument,inputAmout,'bid')
    }
    else 
    {
      this.bidPopup(bidFor,shipperName,amount,paymentMethod,contractDocument,inputAmout)
    }
  }
  /*Cancel Shipment*/
  newShipmentCancel(id: any, uniqueId: any) {
    if (this.userType == 'SHIPPER' && (this.subscriptionData?.isPremium != true || this.subscriptionData?.subscriptionStatus == 'canceled'))
    {
      this.getSubscriptionAlertPopup(false);
    } else {
    let confirmby: any;
    let buttontext = "Cancel";
    if ((this.userType === 'SHIPPER') && (this.currentStatus === 'pendingByLoads' || this.currentStatus === 'pendingByLane' || this.currentStatus === 'upcomingByLoads' || this.currentStatus === 'upcomingByLane' || this.currentStatus === 'inProgressByLoads' || this.currentStatus === 'inProgressByLane')) {
      confirmby = 'confirmCancelShipment';
      this.cancelShipmentPayment(confirmby, id, uniqueId, buttontext);
    } else {
      confirmby = 'confirmcancel';
      this.cancelShipment(confirmby, id, uniqueId, buttontext);
    }
   }
  }

  cancelShipmentPayment(confirmby: any, id: any, uniqueId: any, buttontext: any) {
    let buttonnew = 'Go Back';
    const dialogRef = this.dialog.open(PopupComponent, {
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      data: {
        openPop: confirmby,
        id: id,
        uniqueId: uniqueId,
        buttontext: buttontext,
        buttonnew: buttonnew,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'Ok') {
          this.cancelShipmentSubmitPayment(id, 'firstTimeCall');
        }
      }
    });
  }

  //Cancel shipment popup
  cancelShipment(
    confirmby: any,
    id: any,
    uniqueId: any,
    buttontext: any
  ) {
    let buttonnew = 'Go Back';
    const dialogRef = this.dialog.open(PopupComponent, {
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      data: {
        openPop: confirmby,
        id: id,
        uniqueId: uniqueId,
        buttontext: buttontext,
        buttonnew: buttonnew,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'Ok') {
          this.cancelShipmentSubmit(id, 'firstTimeCall');
        }
      }
    });
  }

  //Cancel shipment api called.
  cancelShipmentSubmitPayment(id: any, check: any) {
    this.loading = true;
    let postBody = null;
    let action = '';
    if (check === 'firstTimeCall') {
      action = 'cancel';
    } else {
      action = 'assign';
    }
    let driverId;
    if (this.shipmentDetail.drivers) {
      postBody = {
        shipmentId: this.shipmentDetail.id,
        driverId: driverId,
        carrierId: localStorage.getItem('user_id'),
        action: action,
      };
    } else {
      postBody = {
        shipmentId: this.shipmentDetail.id,
        carrierId: localStorage.getItem('user_id'),
        action: action,
      };
    }
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.NEWPAYMENT.CANCELSHIPMENTPAYMENT,
      uri: '',
      postBody: postBody,
    };
    this.commonService.post(APIparams).subscribe(
      async (success) => {
        if (success.success === true) {
          this.id = id;
          this.getCurrentStatus('action');
          this.refreshList.emit();
          if (check === 'firstTimeCall') {
          } else {
            this.alertService.showNotificationMessage(
              'success',
              'bottom',
              'right',
              'txt_s',
              'check_circle',
              'Cancel Shipment',
              success.message
            );
          }
          this.loading = false;
        } else if (success.success === false) {
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_d',
            'cancel',
            'Cancel Shipment',
            success.message
          );
          this.loading = false;
        }
      },
      (error) => {
        this.loading = false;
        this.alertService.showNotificationMessage(
          'danger',
          'bottom',
          'right',
          'txt_d',
          'cancel',
          'Cancel Shipment',
          AppSettings.ERROR
        );
      }
    );
  }

  //Cancel shipment api called.
  cancelShipmentSubmit(id: any, check: any) {
    this.loading = true;
    let postBody = null;
    let action = '';
    if (check === 'firstTimeCall') {
      action = 'cancel';
    } else {
      action = 'assign';
    }
    let driverId;
    if (this.shipmentDetail.drivers) {
      postBody = {
        shipmentId: this.shipmentDetail.id,
        driverId: driverId,
        carrierId: localStorage.getItem('user_id'),
        action: action,
      };
    } else {
      postBody = {
        shipmentId: this.shipmentDetail.id,
        carrierId: localStorage.getItem('user_id'),
        action: action,
      };
    }
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.SHIPMENT.SHIPMENTCANCEL,
      uri: '',
      postBody: postBody,
    };
    this.commonService.post(APIparams).subscribe(
      async (success) => {
        if (success.success === true) {
          this.id = id;
          this.getCurrentStatus('action');
          this.refreshList.emit();
          if (check === 'firstTimeCall') {
          } else {
            this.alertService.showNotificationMessage(
              'success',
              'bottom',
              'right',
              'txt_s',
              'check_circle',
              'Cancel Shipment',
              success.message
            );
          }
          this.loading = false;
        } else if (success.success === false) {
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_d',
            'cancel',
            'Cancel Shipment',
            success.message
          );
          this.loading = false;
        }
      },
      (error) => {
        this.loading = false;
        this.alertService.showNotificationMessage(
          'danger',
          'bottom',
          'right',
          'txt_d',
          'cancel',
          'Cancel Shipment',
          AppSettings.ERROR
        );
      }
    );
  }

  //dispute shipment popup
  disputeShipment($event, id: any, uniqueId: any, title: any) {
    if (this.userType == 'SHIPPER' && (this.subscriptionData?.isPremium != true || this.subscriptionData?.subscriptionStatus == 'canceled'))
    {
      this.getSubscriptionAlertPopup(false);
    } else {
    $event.stopPropagation();
    const dialogRef = this.dialog.open(DisputePopupComponent, {
      backdropClass: AppSettings.backdropClass,
      width: '600px',
      data: {
        openPop: 'fileDispute',
        shipmentId: id,
        uniqueId: uniqueId,
        title: title,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'Ok') {
        }
      }
    });
   }
  }

  createDateChange(updatedAt) {
    if (updatedAt) {
      return moment.utc(updatedAt).format('MMM dd, yyyy HH: mm');
    }
  }

  getBannerStatus(statusSlug: any) {
    let updatedAt;
    let deliverydate;
    let info = this.sharedService.getShipmentBannerStatus(
      statusSlug,
      updatedAt,
      deliverydate
    );
    return info.message;
  }

  openNewTabShipment() {
    const fullPath = this.getFullPath(this.route);
    if (this.currentRouteParams === 'inProgressByLoads' || this.currentStatus === 'inProgressByLane' || this.currentRouteParams === 'carrierLoadBids' || this.currentRouteParams === 'loadBids') {
      if (this.shipmentDetail && this.shipmentDetail.status === 15) {
        let newCurrentStatus = 'summary';
        const url = this.router.serializeUrl(
          this.router.createUrlTree([
            fullPath+`/load/view/${this.shipmentDetail.id}/${newCurrentStatus}`,
          ])
        );
        window.open(url, '_blank');
      }else if(this.shipmentDetail && this.shipmentDetail.status === 59){
        let newCurrentStatus = 'details';
        const url = this.router.serializeUrl(
        this.router.createUrlTree([
          fullPath+`/load/view/${this.shipmentDetail.id}/${newCurrentStatus}`,
        ])
      );
      window.open(url, '_blank');
      } else {
        const url = this.router.serializeUrl(
          this.router.createUrlTree([
            fullPath+ `/load/view/${this.shipmentDetail.id}/${this.currentRouteParams}`,
          ])
        );
        window.open(url, '_blank');
      }

    } else {
      if (this.shipmentDetail && this.shipmentDetail.status === 15) {
        let newCurrentStatus = 'summary';
        const url = this.router.serializeUrl(
          this.router.createUrlTree([
            fullPath +`/load/view/${this.shipmentDetail.id}/${newCurrentStatus}`,
          ])
        );
        window.open(url, '_blank');
      }else if(this.shipmentDetail && this.shipmentDetail.status === 59){
        let newCurrentStatus = 'details';
        const url = this.router.serializeUrl(
        this.router.createUrlTree([
          fullPath+`/load/view/${this.shipmentDetail.id}/${newCurrentStatus}`,
        ])
      );
      window.open(url, '_blank');
      } else if(this.userType=='CARRIER' && (this.currentRouteParams === 'allLoads' || this.currentRouteParams === 'allLoadsByLane' || this.currentRouteParams === 'loadsLostByLane' || this.currentRouteParams === 'loadsLost')){
        let newCurrentStatus = 'details';
        const url = this.router.serializeUrl(
        this.router.createUrlTree([
          fullPath+ `/load/view/${this.shipmentDetail.id}/${newCurrentStatus}`,
        ])
      ); 
      window.open(url, '_blank'); 
      } else if(this.userType=='CARRIER' && (this.currentRouteParams === 'quotedLoads' || this.currentRouteParams === 'paymentPending' || this.currentRouteParams === 'quotedLoadsByLane' || this.currentRouteParams === 'awardedLoads'
      || this.currentRouteParams === 'paymentPendingByLane' || this.currentRouteParams === 'loadsAwardedByLane')){
        let newCurrentStatus = 'carrierLoadBids';
        const url = this.router.serializeUrl(
        this.router.createUrlTree([
          fullPath+ `/load/view/${this.shipmentDetail.id}/${newCurrentStatus}`,
        ])
      ); 
      window.open(url, '_blank');
      } 
      else {
        const url = this.router.serializeUrl(
          this.router.createUrlTree([
            fullPath+ `/load/view/${this.shipmentDetail.id}/${this.currentRouteParams}`,
          ])
        );
        window.open(url, '_blank');
      }

    }
  }

  private getFullPath(route: ActivatedRoute): string {
    const segments = [];
    while (route) {
      if (route.snapshot.url.length) {
        segments.unshift(...route.snapshot.url.map(segment => segment.path));
      }
      route = route.parent;
    }
    
    return segments.join('/');
  }

  openTabShipment() {
    const fullPath = this.getFullPath(this.route);
    if (this.currentRouteParams === 'booking' || this.currentRouteParams === 'carrierLoadBids' || this.currentRouteParams === 'loadBids') {
      if (this.shipmentDetail &&  this.shipmentDetail.status === 15) {
        this.router.navigate([
          fullPath+'/load/view',
          this.shipmentDetail.id,
          'summary',
        ]);
      }else if(this.shipmentDetail && this.shipmentDetail.status === 59){
        this.router.navigate([
          fullPath+'/load/view',
          this.shipmentDetail.id,
          'details',
        ]);
      } else {
        this.router.navigate([
          fullPath+'/load/view',
          this.shipmentDetail.id,
          this.currentRouteParams,
        ]);
      }
    } else {
      if (this.shipmentDetail && this.shipmentDetail.status === 15) {
        this.router.navigate([fullPath+
          '/load/view',
          this.shipmentDetail.id,
          'summary',
        ]);
      }else if(this.shipmentDetail && this.shipmentDetail.status === 59){
        this.router.navigate([fullPath+
          '/load/view',
          this.shipmentDetail.id,
          'details',
        ]);
      } else if (this.userType=='CARRIER' && (this.currentRouteParams === 'allLoads' || this.currentRouteParams === 'allLoadsByLane' || this.currentRouteParams === 'loadsLostByLane' || this.currentRouteParams === 'loadsLost')) {
        this.router.navigate([
          fullPath+'/load/view',
          this.shipmentDetail.id,
          'details',
        ]);
      } else if (this.userType=='CARRIER' && (this.currentRouteParams === 'quotedLoads' || this.currentRouteParams === 'paymentPending' || this.currentRouteParams === 'quotedLoadsByLane' || this.currentRouteParams === 'awardedLoads'
      || this.currentRouteParams === 'paymentPendingByLane' || this.currentRouteParams === 'loadsAwardedByLane')) {
        this.router.navigate([
          fullPath+'/load/view',
          this.shipmentDetail.id,
          'carrierLoadBids',
        ]);
      } 
      else {
        this.router.navigate([
          fullPath+'/load/view',
          this.shipmentDetail.id,
          this.currentRouteParams,
          
        ]);
      }
    }
  }

  navigateShipper(id: any) {
    if(this.userType=='SHIPPER'){
         this.router.navigate(['/shipper/profile', id]);
    }
    else{
        this.router.navigate(['/carrier/profile', id]);
    }
  }

  trailSpecialRequipment(dataInfo: any) {
    if (dataInfo && dataInfo.length > 0) {
      if (dataInfo.includes('Temp-controlled')) {
        this.isTemprature = true;
      }
      return (dataInfo.toString().split(',').join(', ')).trim();
    } else {
      return '-';
    }
  }

  // Open e-Carrier check popUp
  ecarriercheck(acceptBidList) {
    const dialogRef = this.dialog.open(NetworkPopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width:'1000px',
      height:'calc(100vh - 100px)',
      data: { openPop: 'ecarriercheck',dotNumber : acceptBidList?.carriers?.dotNumber ? acceptBidList?.carriers?.dotNumber : acceptBidList?.dotNumber},
    });
    dialogRef.afterClosed().subscribe((result) => {
    });
  }

  getSubscriptionAlertPopup(type){
    const dialogRef = this.dialog.open(SubscriptionPopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width: type == 'isUnlockSafetyScore' ? '500px' : AppSettings.popWidth,
      data: { 
        openPop: 'subscriptionAlert',
        subscriptionData: this.subscriptionData,
        type: type
       },
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.loading=false;

    });
    this.loading=false;
  }

  editShipment($event, id: any) {
    if (this.userType == 'SHIPPER' && (this.subscriptionData?.isPremium != true || this.subscriptionData?.subscriptionStatus == 'canceled'))
    {
      this.getSubscriptionAlertPopup(false);
    } else {
      $event.stopPropagation();
      this.router.navigate(['/load/edit', id]);
    }
  }

  unlockSafetyScore(acceptBidList:any,dotNumber,type:any){
    this.loading=true
    if (this.userType == 'SHIPPER' && (((this.subscriptionData?.subscriptionCreditHistory?.defaultCredit + this.subscriptionData?.subscriptionCreditHistory?.additionalCredit)==0) && this.subscriptionData?.isPremium != true && this.subscriptionData?.subscriptionStatus == 'canceled') 
    || (this.subscriptionData?.isPremium == true && this.subscriptionData?.subscriptionStatus == 'active' && ((this.subscriptionData?.subscriptionCreditHistory?.defaultCredit + this.subscriptionData?.subscriptionCreditHistory?.additionalCredit)==0)))
    {
      this.getSubscriptionAlertPopup('isUnlockSafetyScore');
    } else {
    let uri = null;
    let newParams = {
      dotNumber: dotNumber
    };
    if (newParams) uri = this.commonService.getAPIUriFromParams(newParams);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.CARRIER.ECARRIERCHECK,
      uri: uri,
    };
    this.commonService.getList(APIparams).subscribe(
       (ServerRes) => {
        if(ServerRes?.success==true){
          this.showUnLockButton=false;
          this.eCarrierCheckData=ServerRes?.response;
          if(acceptBidList?.bids?.status=='3'){
            if(this.eCarrierCheckData?.profile?.equipmentType){
              this.shipmentDetail.carriers.equipmentType = ServerRes?.response?.profile?.equipmentType
            } 
          if (this.eCarrierCheckData) {
            this.shipmentDetail.carriers.shipperCarrierScoreMapping = true;
          }
          this.shipmentDetail.carriers.carrierSafetyScore={
            id : ServerRes?.response?.profile?.id,
            authority : ServerRes?.response?.authority,
            riskLevel : ServerRes?.response?.riskLevel,
            riskLabel: ServerRes?.response?.riskLabel,
            recentCrashes :ServerRes?.response?.recentCrashesCount,
            dotNumber : ServerRes?.response?.profile?.dotNumber,
            recentInspections: ServerRes?.response?.recentInspectionsCount
          };
         }
         else if(acceptBidList?.status=='3'){
          if(this.eCarrierCheckData?.profile?.equipmentType){
            this.acceptBidList[0].equipmentType=ServerRes?.response?.profile?.equipmentType 
          } 
            this.acceptBidList[0].shipperCarrierScoreMapping = true;
            this.acceptBidList[0].carrierSafetyScore={
              id : ServerRes?.response?.profile?.id,
              authority : ServerRes?.response?.authority,
              riskLevel : ServerRes?.response?.riskLevel,
              riskLabel: ServerRes?.response?.riskLabel,
              recentCrashes :ServerRes?.response?.recentCrashesCount,
              dotNumber : ServerRes?.response?.profile?.dotNumber,
              recentInspections: ServerRes?.response?.recentInspectionsCount
            };
          }
        this.loading=false
        this.alertService.showNotificationMessage(
          'danger',
          'bottom',
          'right',
          'txt_d',
          'check_circle',
          'Safety Score Unavailable',
          ServerRes?.message
        );
       }
        else if(ServerRes.isCreditAvailable==false && ServerRes?.success==false){
          const dialogRef = this.dialog.open(NetworkPopupComponent, {
            disableClose: true,
            backdropClass: AppSettings.backdropClass,
            width:'500px',
            data: { openPop: 'eCarreirCreadits', },

          });
          dialogRef.afterClosed().subscribe((result) => {
            this.loading=false;
          });
         this.loading=false
        }
        else if(ServerRes?.success==false){
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_d',
            'check_circle',
            'Safety Score Unavailable',
            ServerRes?.message
          );
        this.loading=false
        }
      },
      (error) => {      
        this.alertService.showNotificationMessage(
          'danger',
          'bottom',
          'right',
          'txt_d',
          'check_circle',
          'Safety Score Unavailable',
          error?.error?.message
        );
      this.loading = false;
    });
    }
  }

}
