import { Component, OnInit, Input, ElementRef, ViewChild, OnDestroy, AfterViewInit, Renderer2, Output, EventEmitter } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { FeedbackComponent } from '../commons/feedback/feedback.component';
import { CommonService } from '../commons/service/common.service';
import { AppSettings } from '../commons/setting/app_setting';
import { SharedService } from '../commons/service/shared.service';
import { AlertService } from '../commons/service/alert.service';
import { environment } from '../../environments/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { FacebookPixelService } from '../commons/service/facebook-pixel.service';
import { ActivatedRoute } from "@angular/router";
import * as moment from 'moment';
declare const $: any;

declare global {
  interface Window {
    Appcues: any;
    dataLayer: any;
  }
}
declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  icon_selected: string;
  class: string;
  name: string;
  subMenu: any[];
  id: string;
  count: string
}

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.css'],
})

export class LeftMenuComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('toggleButton1', { static: false }) toggleButton1: ElementRef;
  @ViewChild('menu', { static: false }) menu: ElementRef;
  public proImage: any;
  @Input() hideLeftMenu:any
  public activeSideMenu: any;
  location: Location;
  public activeSubTabs: any
  mobile_menu_visible: any = 0;
  public sidebutton: boolean;
  menuItems: any[];
  public userAccessTokan: any;
  public checkNavbar: any;
  public chIcon: any;
  public sortReverse = false;
  public menI: any;
  public userName: any;
  public params: any;
  public newparams: any;
  public recordGet: any;
  public currentRoute: any;
  public userDataForBanner: any;
  public userType: any;
  public emailVerified: any;
  public phoneVerified: any;
  public userDetailForRedirect: any;
  public notificationList: any;
  public message: any;
  public labelNameValue: any;
  public totalNotificationCount: any;
  public notifcionsklitor = false;
  public isActive = false;
  public pageNo = 2;
  public page: any;
  public totalPages: any;
  public focus = 0;
  public totalUnreadTabs = 0;
  sidebarVisible: any;
  public loading = false;
  public scrollvariable: any;
  public spinnderLoader = false;
  public newnotificationList = [];
  public selectedTab = "bookings";
  public newStatusscroll = false;
  public routeSub: any;
  isMenuOpen = false;
  public subscriptionId = null;
  public skeletonLoad = false;
  public currentDate: any;
  public subTabSelected: any;
  @Input() userNameUpdate: string;
  @Output() outputFunction = new EventEmitter()
  @Input() userProfileImageUpdate: string;
  public serverPathForUrl: any;
  public cancelRequestCheck: any = null;
  public cancelRequestProfile: any = null;
  public selectedIndex = 'Booking';
  isAllBookingsCounts: boolean = false;
  isAllBookingsCountsForC: boolean = false;
  isAllShipmentCounts: boolean = false;
  isAllShipmentCountsForC: boolean = false;
  isallSpotLoadsCounts: boolean = false;
  public allUnreadNotification = false;
  isallSpotLoadsCountsForC: boolean = false;
  public allNetworkCount = 0;
  public allShipmentCounts: any;
  public allShipmentCountsForC: any;
  public allSpotLoadsCounts: any;
  step: any;
  public allSpotLoadsCountsForC: any;
  userInfo: any;
  constructor(
    public dialog: MatDialog,
    location: Location,
    private router: Router,
    private route: ActivatedRoute,
    private commonService: CommonService,
    private alertService: AlertService,
    private renderer: Renderer2,
    public sharedService: SharedService,
    public facebookPixelService: FacebookPixelService,
    private deviceService: DeviceDetectorService,
  ) {
    this.location = location;
    this.sidebarVisible = false;
    this.sidebutton = false;
    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.menu !== undefined && this.toggleButton1 !== undefined) {
        if (
          !this.toggleButton1.nativeElement.contains(e.target) &&
          !this.menu.nativeElement.contains(e.target)
        ) {
          this.isMenuOpen = false;
        }
      }
    });
  }

  ngOnInit() {
    if(localStorage.getItem('login')=='after_login'){
      this.markReadAlertNotification('');
    }
    this.userType = localStorage.getItem('user_type');
    this.emailVerified = localStorage.getItem('email_verified');
    this.phoneVerified = localStorage.getItem('phone_verified');
    const isMobile = this.deviceService.isMobile();
    if (isMobile) {
      this.commonService.userData.subscribe((userData) => {
        this.userInfo = userData;
        if (localStorage.getItem('user_type') == 'CARRIER' && this.userInfo.subscription == true) {
          this.router.navigate(['/dashboard/limit-mobile-view']);
        }
        if (localStorage.getItem('user_type') == 'SHIPPER' && this.userInfo.subscription == true) {
          this.router.navigate(['/dashboard/limit-mobile-view']);
        }
        if (localStorage.getItem('user_type') != 'CARRIER' && localStorage.getItem('user_type') != 'SHIPPER') {
          this.router.navigate(['/dashboard/limit-mobile-view']);
        }
      });
    }

    if (localStorage.getItem('user_type') == 'CARRIER') {
      this.sharedService.faceBookPixel('753986152142037');
    }

    this.serverPathForUrl = environment.serverPathForUrl;
    this.newparams = {
      limit: 10,
    };
    this.commonService.callToLogout.subscribe((userData) => {
      this.logoutUser();
    });
    this.sharedService.notifyEmailVerification.subscribe(() => {
      this.emailVerified = true;
    });
    this.commonService.allMarkRedBellnotiion.subscribe((userData) => {
      this.totalNotificationCount = '';
    });
    this.commonService.sendTokanUpdate.subscribe((userData) => {
      this.userType = localStorage.getItem('user_type');
      this.userName = null;
      this.proImage = null;

      if (localStorage.getItem('user_type') === 'CARRIER') {

        this.recordGet = AppSettings.APIsNameArray.CARRIER.GET;
      }
      if (localStorage.getItem('user_type') === 'SHIPPER') {
        this.recordGet = AppSettings.APIsNameArray.SHIPPER.GET;
      }
      if (localStorage.getItem('user_type') === 'STORAGE') {
        this.recordGet = AppSettings.APIsNameArray.COLDSTORAGE.GET;
      }
      this.getProfileCheck();
    });
    this.commonService.profileUpdate.subscribe((userData) => {
      this.getProfileCheck();

    });
    this.commonService.bellNotificationUpdate.subscribe((userData) => {
      if(localStorage.getItem('login') === 'after_login' && !this.hideLeftMenu){
        this.notifictionList(this.selectedTab);
        this.topBell();
      }
  
    });
    if(localStorage.getItem('login') === 'after_login' && !this.hideLeftMenu){
      this.topBell();
    }
    if (localStorage.getItem('login') === 'after_login' && localStorage.getItem('access_token') && localStorage.getItem('stripeConenct') !== 'active' && (localStorage.getItem('user_type') === 'CARRIER' || localStorage.getItem('user_type') === 'STORAGE')) {
      this.checkSubStatusConnect();
    } else if (localStorage.getItem('tag') === 'GTFO' && localStorage.getItem('user_type') === 'SHIPPER') {
      // this.checkSubStatusConnect();
    }
    // this.checkUserMenuProfile();
    if (this.router) {
      this.getProfileCheck();
      let route = this.router.url.split('/');
      if (route[2] == 'my-network') {
        this.activeSubTabs = 'My_Network';
        this.step = 3;
      }
      else if (route[0] == '/network/home' || (route[1] == 'network' && route[2] == 'search')) {
        this.activeSubTabs = 'home';
        this.step = 3;

      }
      // else if (route[0] == 'pref') {
      //   this.activeSubTabs = 'home';
      //   this.step = 0;

      // }
      else if (route[0] == '/booking/lane/postedLanes') {
        this.activeSubTabs = 'recurringLanes';
        this.step = 1;

      }
      else if (route[0] == '/recurring-lanes/confirm-list') {
        this.activeSubTabs = 'activeShipmentFromLane';
        this.step = 1;

      }
      // else if(route[0]=='/'){
      //   this.activeSubTabs='recurringLanes';


      // }
      let newRoute;
      if (this.userType === 'STORAGE') {
        if (route[2]) {
          newRoute = route[2].split('?');
          this.currentRoute = newRoute[0];
        } else {
          newRoute = route[1].split('?');
          this.currentRoute = newRoute[0];
        }
      } else {
        if (route[1]) {
          newRoute = route[1].split('?');
          this.currentRoute = newRoute[0];
        }
      }
      localStorage.setItem('currentRouteUrl', this.router.url);
      if (localStorage.getItem('login') === 'after_login' && localStorage.getItem('stripeConenct') !== 'active' && localStorage.getItem('access_token') && (localStorage.getItem('user_type') === 'CARRIER' || localStorage.getItem('user_type') === 'STORAGE')){
        this.checkSubStatusConnect();
      } else if (localStorage.getItem('tag') === 'GTFO' && localStorage.getItem('user_type') === 'SHIPPER') {
        // this.checkSubStatusConnect();
      }
    }
   
    let routerURl = this.route.params;

    this.routeSub = this.router.events.subscribe((event) => {
      if (event) {
        this.sharedService.updateLeftMenuByBell.subscribe((notificationRoute) => {
          this.router.navigate([notificationRoute]);
          if (notificationRoute) {
            event = notificationRoute;
          }
        });
        if (event instanceof NavigationEnd)
          {
            let tabType =event.url.split('/')[1]
            let subTabType = event.url.split('/')[2]
               if(tabType == 'recurring-lanes' && subTabType=='create')
                {
                  this.activeSubTabs ='createRecurringLane'
                  this.step = 1; 
                }
                else if(tabType == 'recurring-lanes')
                  {
                    this.step = 1; 
                    this.activeSubTabs = null;
                  }
              else if(tabType == 'spot-rate-loads')
                {
                  this.step = 2; 
                  this.activeSubTabs = null;
                }
                else if(tabType == 'load' && subTabType=='create' && this.userType == 'SHIPPER')
                  {
                    this.activeSubTabs ='spotCreateLoads'
                    this.step = 2; 
                  }
                else if(tabType == 'load' && (subTabType=='create' || subTabType=='edit') && this.userType == 'CARRIER')
                  {
                    this.activeSubTabs ='shipmentCreatedByMe'
                    this.step = null; 
                  }
                else if(tabType == 'network')
                  {
                    this.step = 3; 
                    this.activeSubTabs = null;
                  }
                
                  else if(tabType == 'bid-quote-assistant')
                    {
                      this.step = null; 
                      this.activeSubTabs = 'bid-quote-assistant';
                    }
                  else if(tabType == 'storage')
                    {
                      this.step = 4; 
                    }
                    else if(tabType == 'messages')
                      {
                        this.step = null; 
                        this.activeSubTabs = null;
                      }
                    else if(tabType == 'shipper' || tabType == 'carrier')
                      {
                        if(subTabType !== 'subscription-overview' && subTabType !== 'profile')
                        this.step = 5; 
                        else if(subTabType == 'subscription-overview')
                        {
                          this.step =6;
                        }
                        else if (subTabType == 'profile')
                          {
                            this.step = -1
                            this.activeSubTabs = null;
                          }
                      }
                      else if(tabType == 'billing')
                        {
                          this.step = 6; 
                        }
                        else if(tabType == 'driver')
                         {
                           this.step = null; 
                           this.activeSubTabs = 'Drivers';
                         }
                         else if(tabType == 'tutorial')
                          {
                            this.step = null; 
                            this.activeSubTabs = 'tutorialGuides';
                          }
              
          }
        // if (event instanceof NavigationEnd) {
        //   let route = event.url.split('/');
        //   if (event.url.includes('/network/my-network/connected') || event.url.includes('/network/preferred') || event.url.includes('/network/my-network/invitation') ) {
        //     this.activeSubTabs = 'My_Network';
        //     this.step = 0;
        //   }
        //   else if (event.url.includes('/network/home') || (event.url.includes('/network/search/list') || event.url.includes('/network/search/mapview') )) {
        //     this.activeSubTabs = 'home';
        //     this.step = 0;

        //   }
        //   else if (window.location.href.includes('booking') && window.location.href.includes('lane') && window.location.href.includes('postedLanes')) {
        //     this.activeSubTabs = 'recurringLanes';
        //     this.step = 1;

        //   }
        //   else if (window.location.href.includes('recurring-lanes/create-lanes')) {
        //     this.activeSubTabs = 'createRecurringLane';
        //     this.step = 1;

        //   }
        //   else if (window.location.href.includes('recurring-lane') && window.location.href.includes('active-lanes')) {
        //     this.activeSubTabs = 'activeLoadsFromLane';
        //     this.step = 1;
        //   }
        //   else if (window.location.href.includes('recurring-lane') && window.location.href.includes('confirmRateHistory')) {
        //     this.activeSubTabs = 'confirmedRatesHistory';
        //     this.step = 1;
        //   }
        //   else if (window.location.href.includes('inProgressByLoads') || window.location.href.includes('upcomingByLoads') || window.location.href.includes('pendingByLoads') || window.location.href.includes('pastByLoads')) {
        //     this.activeSubTabs = 'activeLoads';
        //     this.step = 2;

        //   }
        //   else if (window.location.href.includes('/storage/') && (window.location.href.includes('shipper') || window.location.href.includes('search') || window.location.href.includes('mapview'))) {
        //     this.activeSubTabs = 'Storage Network';
        //     this.step = 3;

        //   }
        //   else if (window.location.href.includes('storage-order') && (window.location.href.includes('list') || window.location.href.includes('orders') || window.location.href.includes('create') || window.location.href.includes('bidding') || window.location.href.includes('confirmed'))) {
        //     this.activeSubTabs = 'Storage Order';
        //     this.step = 3;

        //   }
        //   else if (window.location.href.includes('subscription-overview') || event.url?.includes('carrier/premium-plan') || event.url?.includes('shipper/premium-plan')) {
        //     this.step = 5;
        //     this.activeSubTabs = 'subscription';
        //   }
        //   else if (window.location.href.includes('billing') || window.location.href.includes('buy-more-load')) {
        //     this.step = 5;
        //     this.activeSubTabs = 'billing';
        //   }
        //   else if (window.location.href.includes('notification-control-panel')) {
        //     this.step = 4;
        //     this.activeSubTabs = 'notificationControl';
        //   }
        //   else if (window.location.href.includes('/shipper/transaction') || window.location.href.includes('/carrier/transaction') || window.location.href.includes('/storage/transactions')) {
        //     this.step = 4;
        //     this.activeSubTabs = 'transactions';
        //   }
        //   else if (window.location.href.includes('eferral')) {
        //     this.step = 4;
        //     this.activeSubTabs = 'referralBenefits';
        //   }
        //   else if (window.location.href.includes('/tutorial')) {
        //     this.step = null;
        //     this.activeSubTabs = 'tutorialGuides'

        //   }
        //   else if (window.location.href.includes('/messages')) {
        //     this.step = null;
        //     this.activeSubTabs = 'Messages'
        //   }
        //   else if (window.location.href.includes('/shipper/profile') || window.location.href.includes('/carrier/profile')) {
        //     this.step = null
        //     this.activeSubTabs = '';

        //   }
        //   else if(event?.url.includes('/driver/') && event?.url.includes('/driver/list/All') || event?.url.includes('/driver/assign-shipment') || (event?.url.includes('/Available') || event?.url.includes('/Active') || event?.url.includes('Invited')) || event?.url.includes('/driver/view/') && (event?.url.includes('/documents') || event?.url.includes('/details') || event?.url.includes('/in-transit') || event?.url.includes('/confirmed') || event?.url.includes('completed')) || event?.url.includes('/driver/edit/') && (event.url.includes('/details') || event.url.includes('/document'))){
        //     this.step = null;      
        //     this.activeSubTabs = 'Drivers';
        //   }
        //   else if (window.location.href.includes('/shipper/setting') || window.location.href.includes('/carrier/setting') || window.location.href.includes('/storage/setting')) {
        //     this.activeSubTabs = 'settings';
        //     this.step = 4;
        //   }
        //   // else if(event?.url.includes('/load-created-by-me/inProgress') || (event?.url?.includes('shipment/view') || event?.url?.includes('summary') || event?.url?.includes('shipment') || event?.url?.includes('route-plan') || event?.url?.includes('documents'))){
        //   else if(event?.url.includes('/load-created-by-me/inProgress')){
        //     this.step = null; 
        //     this.activeSubTabs = 'shipmentCreatedByMe';
        //   }
        //   else if(event?.url.includes('/dispute')){
        //     this.step = null; 
        //     this.activeSubTabs = 'dispute';
        //   }
        //   else if(event?.url.includes('/bid-quote-assistant')){
        //     this.step = null; 
        //     this.activeSubTabs = 'bid-quote-assistant';
        //   }
        //   else if(event?.url.includes('/spot-rate-loads')){
        //     this.step = 2; 
        //     if(event?.url.includes('/created-loads')){
        //      this.activeSubTabs='spotCreateLoads'
        //     }
        //     else if(event?.url.includes('/active-loads')){
        //       this.activeSubTabs='activeLoads'
        //      }
        //      else if(event?.url.includes('/available-loads')){
        //       this.activeSubTabs='availableLoads'
        //      }
        //   }
        //   else if(event?.url?.includes('/recurring-lanes/')){
        //     this.step = 1; 
        //     if(event?.url?.includes('/available-lanes/')){
        //       this.activeSubTabs='availableLane' 
        //     }
        //     else if(event?.url?.includes('/active-lanes/')){
        //       this.activeSubTabs='activeLoadsFromLane' 
        //     }
        //     else if(event?.url?.includes('/create-lanes/')){
        //       this.activeSubTabs='createRecurringLane' 
        //     }
        //   }

        //   // else if(event?.url=='/booking/lane/confirm-list'){
        //   //   this.activeSubTabs='notificationControl';
        //   //    this.step=1
        //   // }
        //   // else if(event?.url=='/notification-control-panel/network'){
        //   //   this.activeSubTabs='notificationControl'
        //   // }
        //   // else if(event?.url=='/notification-control-panel/network'){
        //   //   this.activeSubTabs='notificationControl'
        //   // }
        //   let newRoute;
        //   if (this.userType === 'STORAGE') {
        //     if (route[1] == 'storage-order') {
        //       newRoute = route[1];
        //       this.currentRoute = newRoute;
        //     } else if (route[2]) {
        //       newRoute = route[2].split('?');
        //       this.currentRoute = newRoute[0];
        //     } else {
        //       newRoute = route[1].split('?');
        //       this.currentRoute = newRoute[0];
        //     }
        //   } else {
        //     if (route[2] == 'profile') {
        //       newRoute = route[2].split('?');
        //       this.currentRoute = newRoute[0];
        //     } else {
        //       newRoute = route[1].split('?');
        //       this.currentRoute = newRoute[0];

        //     }
        //   }
        //   this.isActive = false;
        //   localStorage.setItem('currentRouteUrl', this.router.url);
        //   if (localStorage.getItem('login') === 'after_login' && localStorage.getItem('stripeConenct') !== 'active' && localStorage.getItem('access_token') && (localStorage.getItem('user_type') === 'CARRIER' || localStorage.getItem('user_type') === 'STORAGE')) {
        //     this.checkSubStatusConnect();
        //   } else if (localStorage.getItem('tag') === 'GTFO' && localStorage.getItem('user_type') === 'SHIPPER') {
        //     // this.checkSubStatusConnect();
        //   }
        // }
      }
    });
    // this.menuItems = ROUTES.filter((menuItem) => menuItem);
  }

  ngAfterViewInit() {
    let notification = {};
    let count = 0;
    if(localStorage.getItem('login') === 'after_login' && !this.hideLeftMenu){
      this.notifictionList(this.selectedTab);
    }
    
  }

  openExpensionPanel(sideMenus) {
    this.activeSideMenu = sideMenus;
  }
  changeFirebaseToken() {
    this.sharedService.changeFirebaseToken.next(null);
  }

  // active submenu class function
  tabSelection(tabs) {
    if (tabs == 'network') {
      this.router.navigateByUrl('/network/my-network/connected');
    }
    this.subTabSelected = tabs
  }
  

  setStep(index: number) {
    this.step = index;
  }
  settingPage(type: any) {
    if (this.userType === 'CARRIER') {
      if (type === 'setting') {
        this.router.navigateByUrl('/carrier/setting/change-password');
      } else {
        this.router.navigateByUrl('/carrier/profile');
      }
    } else if (this.userType === 'SHIPPER') {
      if (type === 'setting') {
        this.router.navigateByUrl('/shipper/setting/change-password');
      } else if (type === 'profile') {
        this.router.navigateByUrl('/shipper/profile');
      }
    } else if (this.userType === 'STORAGE') {
      if (type === 'setting' && localStorage.getItem('locationChek') === '1') {
        this.router.navigateByUrl('/storage/setting/change-password');
      } else if (
        type === 'profile' &&
        localStorage.getItem('locationChek') === '1'
      ) {
        this.router.navigateByUrl('/storage/profile');
      } else if (
        (type === 'profile' || type === 'setting') &&
        localStorage.getItem('locationChek') === '0'
      ) {
        this.router.navigateByUrl('/storage/location/list');
      }
    }
  }
  toggleMenu() {
    this.userDetailForRedirect = this.sharedService.getAddress();
    if ((localStorage.getItem('user_type') === 'SHIPPER') && (this.userDetailForRedirect?.phyAddressLine1 && this.userDetailForRedirect?.phyCity && this.userDetailForRedirect?.phyState && this.userDetailForRedirect?.phyZip && this.userDetailForRedirect?.equipmentType
      && this.userDetailForRedirect?.preferredRegions && this.userDetailForRedirect?.signatureFont && this.userDetailForRedirect?.profileImage && this.userDetailForRedirect?.contractDocument)) {
      this.isMenuOpen = !this.isMenuOpen;
      this.selectedTab = "bookings";
      if (this.isMenuOpen) {
        this.notifictionList(this.selectedTab);
      }
    }

    if ((localStorage.getItem('user_type') === 'CARRIER') && (this.userDetailForRedirect?.phyAddressLine1 && this.userDetailForRedirect?.phyCity && this.userDetailForRedirect?.phyState && this.userDetailForRedirect?.phyZip && this.userDetailForRedirect?.equipmentType
      && this.userDetailForRedirect?.preferredRegions && this.userDetailForRedirect?.signatureFont && this.userDetailForRedirect?.profileImage && this.userDetailForRedirect?.coverImage && this.userDetailForRedirect?.insuranceAmount && this.userDetailForRedirect?.insuranceCompanyName && this.userDetailForRedirect?.insuranceCompanyEmail)) {
      this.isMenuOpen = !this.isMenuOpen;
      this.selectedTab = "bookings";
      if (this.isMenuOpen) {
        this.notifictionList(this.selectedTab);
      }
    }

    // if ((localStorage.getItem('user_type') === 'CARRIER') && this.userDetailForRedirect?.subscription === null) {
    //   this.isMenuOpen = false;        // PREVIOUS SUBSCRIPTION CODE
    // }

    if ((localStorage.getItem('user_type') === 'STORAGE')) {
      this.isMenuOpen = !this.isMenuOpen;
      this.selectedTab = "bookings";
      if (this.isMenuOpen) {
        this.notifictionList(this.selectedTab);
      }
    }
  }

  generateFake(count: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes;
  }
  timeout: any;
  ngOnDestroy() {
    if (this.routeSub) {
      this.routeSub.unsubscribe();
    }
    clearTimeout(this.timeout);
  }
  checkUserMenuProfile() {
    this.userType = localStorage.getItem('user_type');
    // this.menuItems = ROUTES.filter((menuItem) => menuItem);

    if (localStorage.getItem('user_type') === 'CARRIER') {
      this.recordGet = AppSettings.APIsNameArray.CARRIER_REGISTRATION.GET;
      if (!localStorage.getItem('userName')) {
        this.getProfileCheck();
      } else {
        this.userName = localStorage.getItem('userName');
        this.proImage = localStorage.getItem('userProfileImage');
      }
    } else if (localStorage.getItem('user_type') === 'SHIPPER') {
      this.recordGet = AppSettings.APIsNameArray.SHIPPER.GET;
      if (!localStorage.getItem('userName')) {
        this.getProfileCheck();
      } else {
        this.userName = localStorage.getItem('userName');
        this.proImage = localStorage.getItem('userProfileImage');
      }

    } else if (localStorage.getItem('user_type') === 'STORAGE') {
      this.recordGet = AppSettings.APIsNameArray.COLDSTORAGE.GET;
      if (!localStorage.getItem('userName')) {
        this.getProfileCheck();
      } else {
        this.userName = localStorage.getItem('userName');
        this.proImage = localStorage.getItem('userProfileImage');
      }
    }
  }

  redirectMainPage() {
    this.userDetailForRedirect = this.sharedService.getAddress();
    if (localStorage.getItem('user_type') === 'SHIPPER' && this.userDetailForRedirect?.subscription == null) {
      this.router.navigateByUrl('shipper/subscription-plan');
    } else if (localStorage.getItem('user_type') === 'SHIPPER') {
      this.router.navigateByUrl('/shipper/profile');
    } else if (localStorage.getItem('user_type') === 'CARRIER' && this.userDetailForRedirect?.subscription == null) {
      this.router.navigateByUrl('carrier/subscription-plan');
    } else if (localStorage.getItem('user_type') === 'CARRIER') {
      this.router.navigateByUrl('/carrier/profile');
    } else if (localStorage.getItem('user_type') === 'STORAGE') {
      this.router.navigateByUrl('/storage/location/list');
    }

    if (localStorage.getItem('user_type') === 'before_login') {
      this.sharedService.configLoginCallUrl.next(null);
    }
  }

  setActiveMenu(name: any) {
    this.userDetailForRedirect = this.sharedService.getAddress();
    if (
      name === 'storage-order' &&
      this.userType === 'STORAGE' &&
      localStorage.getItem('locationChek') === '0'
    ) {
      this.currentRoute = 'location';
      this.router.navigateByUrl('/storage/location/list');
      this.markReadAlertNotification('');
    }
    if (localStorage.getItem('user_type') == 'CARRIER') {
      if ((!this.userDetailForRedirect?.phyAddressLine1 || !this.userDetailForRedirect?.phyCity || !this.userDetailForRedirect?.phyState || !this.userDetailForRedirect?.phyZip || !this.userDetailForRedirect?.equipmentType
        || !this.userDetailForRedirect?.preferredRegions || !this.userDetailForRedirect?.signatureFont || !this.userDetailForRedirect?.profileImage || !this.userDetailForRedirect?.coverImage || !this.userDetailForRedirect?.insuranceAmount || !this.userDetailForRedirect?.insuranceCompanyName
        || !this.userDetailForRedirect?.insuranceCompanyEmail) && this.userDetailForRedirect?.subscription === null) {
        // this.router.navigateByUrl('carrier/subscription-plan');  // PREVIOUS SUBSCRIPTION CODE
        this.router.navigateByUrl('/carrier/onboarding');  // NEW SUBSCRIPTION CODE
        this.markReadAlertNotification('');
      }

      if ((!this.userDetailForRedirect?.phyAddressLine1 || !this.userDetailForRedirect?.phyCity || !this.userDetailForRedirect?.phyState || !this.userDetailForRedirect?.phyZip || !this.userDetailForRedirect?.equipmentType
        || !this.userDetailForRedirect?.preferredRegions || !this.userDetailForRedirect?.signatureFont || !this.userDetailForRedirect?.profileImage || !this.userDetailForRedirect?.coverImage || !this.userDetailForRedirect?.insuranceAmount || !this.userDetailForRedirect?.insuranceCompanyName
        || !this.userDetailForRedirect?.insuranceCompanyEmail) && this.userDetailForRedirect?.subscription != null) {
        this.router.navigateByUrl('/carrier/onboarding');
        this.markReadAlertNotification('');
      }

      else if (name === 'network') {
        this.subTabSelected = 'My_Network'
        this.markReadAlertNotification('connected');
      }
      else if (name === 'booking') {
        this.markReadAlertNotification('newPrivateShipment');
      } else if (name === 'shipment') {
        this.markReadAlertNotification('inProgress');
      }
      else {
        this.markReadAlertNotification('');
      }
    } else if (localStorage.getItem('user_type') == 'SHIPPER') {

      if (!this.userDetailForRedirect?.phyAddressLine1 || !this.userDetailForRedirect?.phyCity || !this.userDetailForRedirect?.phyState || !this.userDetailForRedirect?.phyZip || !this.userDetailForRedirect?.equipmentType
        || !this.userDetailForRedirect?.preferredRegions || !this.userDetailForRedirect?.signatureFont || !this.userDetailForRedirect?.profileImage || !this.userDetailForRedirect?.contractDocument) {
        this.router.navigateByUrl('/shipper/onboarding');
      }
      if (name === 'network') {
        this.markReadAlertNotification('connected');
      } else if (name === 'booking') {
        this.markReadAlertNotification('currentBids');
      } else if (name === 'shipment') {
        this.markReadAlertNotification('inProgress');
      }
      else if (name === 'profile') {
        this.markReadAlertNotification('');
      } else {
        this.markReadAlertNotification('');
      }

    } else {
      // this.checkUserMenuProfile();
      this.currentRoute = name;
      if (localStorage.getItem('user_type') === 'before_login') {
        this.sharedService.configLoginCallUrl.next(null);
      }
      // this.checkUserMenuProfile();
      this.currentRoute = name;
      if (localStorage.getItem('user_type') === 'before_login') {
        this.sharedService.configLoginCallUrl.next(null);
      }
    }
  }
  markReadAlertNotification(value: any) {
    let carrier = localStorage.getItem('user_type') === 'CARRIER';
    let shipper = localStorage.getItem('user_type') === 'SHIPPER';
    if (localStorage.getItem('user_type') == 'CARRIER' || localStorage.getItem('user_type') == 'SHIPPER') {
      this.commonService.putAlertNotificationOnRead(value).subscribe((data) => {
        if (data.response) {
          this.allNetworkCount = data.response.connected + data.response.invite;
          this.sharedService.AlertNotifyService.next({ name: 'network', counts: this.allNetworkCount });
          if (shipper) {
            this.allSpotLoadsCounts = data.response.assignByLoads + data.response.assignToCarrierByLoads + data.response.createByLoads + data.response.acceptByLoads
            data.response.navigateToPickupByLoads + data.response.deliveredByLoads + data.response.cancelByLoads;
            if (this.allSpotLoadsCounts != 0) {
              this.isallSpotLoadsCounts = true;
            } else {
              this.isallSpotLoadsCounts = false;
            }
            this.allShipmentCounts = data.response.assign + data.response.assignToCarrier + data.response.create + data.response.accept + data.response.navigateToPickup + data.response.delivered + data.response.cancel + data.response.dispute + data.response.expired;
            this.sharedService.AlertNotifyService.next({ name: 'spot rate load', counts: this.allSpotLoadsCounts });
            this.sharedService.AlertNotifyService.next({ name: 'shipment', counts: this.allShipmentCounts });
          } else if (carrier) {
            this.allSpotLoadsCountsForC = data.response.currentBid + data.response.awardedBids + data.response.offerRateConfirmed + data.response.newPrivateShipment + data.response.newOfferRate;
            this.allShipmentCountsForC = data.response.assign + data.response.assignToCarrier + data.response.create + data.response.accept + data.response.navigateToPickup + data.response.delivered + data.response.cancel + data.response.dispute + data.response.expired;
            this.sharedService.AlertNotifyService.next({ name: 'bidding', counts: this.allSpotLoadsCountsForC });
            this.sharedService.AlertNotifyService.next({ name: 'shipment', counts: this.allShipmentCountsForC });
          }
        }
      });
    }
  }

  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    var tik = titlee.slice(1);
    var newStr = tik.replace('-', ' ');
    if (newStr) {
      return newStr.slice(0).toUpperCase();
    }
    return 'Dashboard';
  }

  sideMenuClick() {
    this.userDetailForRedirect = this.sharedService.getAddress();
    if ((localStorage.getItem('user_type') === 'SHIPPER') && (!this.userDetailForRedirect?.phyAddressLine1 || !this.userDetailForRedirect?.phyCity || !this.userDetailForRedirect?.phyState || !this.userDetailForRedirect?.phyZip || !this.userDetailForRedirect?.equipmentType
      || !this.userDetailForRedirect?.preferredRegions || !this.userDetailForRedirect?.signatureFont || !this.userDetailForRedirect?.profileImage || !this.userDetailForRedirect?.contractDocument)) {
      this.router.navigateByUrl('/shipper/onboarding');
    }

    if ((localStorage.getItem('user_type') === 'CARRIER') && (!this.userDetailForRedirect?.phyAddressLine1 || !this.userDetailForRedirect?.phyCity || !this.userDetailForRedirect?.phyState || !this.userDetailForRedirect?.phyZip || !this.userDetailForRedirect?.equipmentType
      || !this.userDetailForRedirect?.preferredRegions || !this.userDetailForRedirect?.signatureFont || !this.userDetailForRedirect?.profileImage || !this.userDetailForRedirect?.coverImage || !this.userDetailForRedirect?.insuranceAmount || !this.userDetailForRedirect?.insuranceCompanyName
      || !this.userDetailForRedirect?.insuranceCompanyEmail) && this.userDetailForRedirect?.subscription === null) {
      // this.router.navigateByUrl('carrier/subscription-plan');  // PREVIOUS SUBSCRIPTION CODE
      this.router.navigateByUrl('/carrier/onboarding');  // NEW SUBSCRIPTION CODE
    }

    if ((localStorage.getItem('user_type') === 'CARRIER') && (!this.userDetailForRedirect?.phyAddressLine1 || !this.userDetailForRedirect?.phyCity || !this.userDetailForRedirect?.phyState || !this.userDetailForRedirect?.phyZip || !this.userDetailForRedirect?.equipmentType
      || !this.userDetailForRedirect?.preferredRegions || !this.userDetailForRedirect?.signatureFont || !this.userDetailForRedirect?.profileImage || !this.userDetailForRedirect?.coverImage || !this.userDetailForRedirect?.insuranceAmount || !this.userDetailForRedirect?.insuranceCompanyName
      || !this.userDetailForRedirect?.insuranceCompanyEmail) && this.userDetailForRedirect?.subscription != null) {
      this.router.navigateByUrl('/carrier/onboarding');
    }
  }

  logoutUser() {
    if (localStorage.getItem('uniqueId') !== null && localStorage.getItem('fcmToken') !== null) {
      this.deleteDeviceTokan();
    }
    this.userNameUpdate = '';
    this.userName = '';
    this.userType = '';
    localStorage.clear();
    sessionStorage.clear();
    this.outputFunction.emit('leftLogiut');

    this.router.navigateByUrl('/');
    this.sharedService.configLoginCallUrl.next(null);
    this.sharedService.setAddress(null);
    localStorage.setItem('login', 'before_login');
    this.activeSubTabs = null;
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('before_login_user');
    body.classList.remove('after_login_user');
  }

  deleteDeviceTokan() {
    let token;
    if (localStorage.getItem('lastfcmToken')) {
      token = localStorage.getItem('lastfcmToken');
    } else {
      token = localStorage.getItem('fcmToken');
    }
    if (token) {
      let value = {
        deviceId: localStorage.getItem('uniqueId'),
        token: localStorage.getItem('fcmToken'),
      };
      let APIparams = {
        apiKey: AppSettings.APIsNameArray.NOTIFICATION.DEVICETOKENDELETE,
        uri: '',
        postBody: value,
      };
      this.commonService.post(APIparams).subscribe(
        (success) => {
          if (success.success === true) {
          } else if (success.success === false) {
            this.alertService.showNotificationMessage(
              'danger',
              'bottom',
              'right',
              'txt_d',
              'cancel',
              'Remove Tokan',
              success.message
            );
          }
        },
        (error) => {
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_g',
            'error',
            'Unexpected Error',
            AppSettings.ERROR
          );
        }
      );

    }
  }

  openDialog(activeSubTabs): void {
    // this.activeSubTabs=activeSubTabs;
    // this.step=null;
    const dialogRef = this.dialog.open(FeedbackComponent, {
      width: '500px',
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
    });
    dialogRef.afterClosed().subscribe((result) => {

    });
  }

  /*get Driver name */
  checkSubStatusConnect() {
    // if (this.cancelRequestCheck) {
    //   this.cancelRequestCheck.unsubscribe();
    // }
    this.checkSubStatus();
  }

  checkSubStatus(params = {}) {
    let uri = null;
    uri = this.commonService.getAPIUriFromParams({});
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.NEWPAYMENT.CHECKSTATUS,
      uri: uri,
    };
    this.cancelRequestCheck = this.commonService
      .getList(APIparams)
      .subscribe((ServerRes) => {
        if (ServerRes.success === true) {
          this.cancelRequestCheck = null;
          if (localStorage.getItem('user_type') === 'CARRIER' || localStorage.getItem('user_type') === 'STORAGE') {
            if (ServerRes.response.connectAccountStatus === true) {
              localStorage.setItem('stripeConenct', 'active');
            } else if (ServerRes.response.connectAccountStatus === false) {
              localStorage.setItem('stripeConenct', 'Pending');
            } else {
              localStorage.setItem('stripeConenct', '');
            }
          } else if (localStorage.getItem('user_type') === 'SHIPPER' && localStorage.getItem('tag') === 'GTFO') {
            let numberOfDay: any = moment.unix(ServerRes.response.current_period_end);
            let getNewDate = moment(new Date(numberOfDay)).format('YYYY-MM-DD HH:mm');
            localStorage.setItem('checkDate', getNewDate);
            if (ServerRes.response.subscriptionStatus === 'active') {
              localStorage.setItem('premium', '1');
            } else {
              localStorage.setItem('premium', '0');
              this.logoutUser();
            }
          }
          this.commonService.updateConnectAccount.next(ServerRes.response);

        }
      });
  }
  getProfileCheck() {
    // if (this.cancelRequestProfile) {
    //   this.cancelRequestProfile.unsubscribe();
    // }
    this.getProfile();
  }

  /*get Driver name */
  async  getProfile(params = {}) {
    this.sharedService.setAddress(null);
    let uri = '';
    if (this.params) uri = this.commonService.getAPIUriFromParams(this.params);
    let APIparams = {
      apiKey: this.recordGet,
      uri: uri,
    };
    this.cancelRequestProfile = this.commonService
      .getList(APIparams)
      .subscribe((ServerRes) => {
        this.sharedService.setAddress(null);
        this.commonService.afteLoginUpdateLoader.next(null);
        if (ServerRes.success === true) {
          this.cancelRequestProfile = null;
          this.userDataForBanner = ServerRes.response;
          this.sharedService.setAddress(ServerRes.response);
          if (
            ServerRes.response &&
            ServerRes.response.phyCity &&
            ServerRes.response.phyState
          ) {
            const SourceLocation =
              ServerRes.response.phyCity + ',' + ServerRes.response.phyState;
            this.sharedService.setUserLocationType(SourceLocation);
          }
          this.commonService.userData.next(ServerRes.response);
          if (
            localStorage.getItem('user_type') === 'CARRIER' ||
            localStorage.getItem('user_type') === 'STORAGE'
          ) {
            localStorage.setItem('email', ServerRes.response.email);
            localStorage.setItem('isMLWallet', ServerRes.response.isMLWallet);
            localStorage.setItem('lane', ServerRes.response.lane);
            localStorage.setItem(
              'equipmentType',
              ServerRes.response.equipmentType
            );
            localStorage.setItem('industries', ServerRes.response.industries);
          }

          if (localStorage.getItem('user_type') === 'CARRIER') {
            localStorage.setItem('userName', ServerRes.response.legalName);
            if (ServerRes.response.coiDoc && !ServerRes.response.coiExpired) {
              localStorage.setItem(
                'certificateOfInsurance',
                ServerRes.response.coiDoc
              );
            } else if (
              (ServerRes.response.coiDoc && ServerRes.response.coiExpired) ||
              (!ServerRes.response.coiDoc && ServerRes.response.coiExpired)
            ) {
              localStorage.setItem('certificateOfInsurance', 'null');
            }
            this.userName = localStorage.getItem('userName');
          }
          if (localStorage.getItem('user_type') === 'SHIPPER') {
            localStorage.setItem('userName', ServerRes.response.companyName);
            localStorage.setItem(
              'contractDocument',
              ServerRes.response.contractDocument
            );
            this.userName = localStorage.getItem('userName');
          }
          if (localStorage.getItem('user_type') === 'STORAGE') {
            localStorage.setItem('userName', ServerRes.response.companyName);
            this.userName = localStorage.getItem('userName');
          }
          if (ServerRes.response.profileImage) {
            localStorage.setItem(
              'userProfileImage',
              ServerRes.response.profileImage
            );
            this.proImage = localStorage.getItem('userProfileImage');
          }
        }
      });
  }
  getConfig() {
    let uri = null;
    let newParams = {};
    if (newParams) uri = this.commonService.getAPIUriFromParams(newParams);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.OTHER.CONFIG,
      uri: uri,
    };
    this.commonService.getList(APIparams).subscribe((ServerRes) => {
      if (ServerRes.success === true) {
        this.sharedService.setConfig(ServerRes.response);
        this.commonService.configData.next(ServerRes.response);
      }
    });
  }

  clickRefresh() {
    this.notifictionList(this.selectedTab);
  }
  onScroll() {
    if (this.page !== this.totalPages) {
      this.page = this.page + 1;
      this.spinnderLoader = true;
      this.getMoreNotification();
    }
  }

  getAPIParamNoti() {
    let APIparams, params;
    params = { limit: 10, page: this.page, tabType: this.selectedTab };
    APIparams = {
      apiKey: AppSettings.APIsNameArray.NOTIFICATION.BEllNOTIFICATION,
      uri: this.commonService.getAPIUriFromParams(params),
    };
    return APIparams;
  }

  getAPIParamNotify() {
    let APIparams, params;
    params = { limit: 10, page: this.page };
    APIparams = {
      apiKey: AppSettings.APIsNameArray.NOTIFICATION.BEllNOTIFICATION,
      uri: this.commonService.getAPIUriFromParams(params),
    };
    return APIparams;
  }

  topBell() {
    this.notifcionsklitor = false;
    this.page = 1;
    var APIparams = this.getAPIParamNotify();
    this.newnotificationList = []
    this.commonService.getList(APIparams).subscribe(
      (ServerRes) => {
        this.notifcionsklitor = true;
        if (
          ServerRes.response?.bellNotifications &&
          ServerRes.response?.bellNotifications?.length > 0
        ) {
          for (let notiIndex in ServerRes.response.bellNotifications) {
            ServerRes.response.bellNotifications[notiIndex].createdAt =
              new Date(
                ServerRes.response.bellNotifications[notiIndex].createdAt +
                ' ' +
                'UTC'
              );
          }
          this.newnotificationList = ServerRes.response.bellNotifications;
          this.totalNotificationCount = ServerRes.response.unreadNotifications;
          this.totalUnreadTabs = ServerRes.response.unreadTabs
          this.allUnreadNotification = Object.values(ServerRes.response.unreadTabs).includes(1)
          this.totalPages = ServerRes.response.totalPages;
        } else {
          this.notificationList = [];
          this.totalNotificationCount = 0;
          this.notifcionsklitor = true;
        }
      },
      (error) => {
        this.notificationList = [];
        this.totalNotificationCount = 0;
        this.notifcionsklitor = true;
      }
    );
  };

  notifictionList(tabType: any) {
    if (tabType.length < 10) {
      this.selectedTab = tabType.toLowerCase();
      this.notifcionsklitor = false;
      this.page = 1;
      var APIparams = this.getAPIParamNoti();
      this.newnotificationList = []
      this.commonService.getList(APIparams).subscribe(
        (ServerRes) => {
          this.allUnreadNotification = Object.values(ServerRes.response.unreadTabs).includes(1)
          this.totalUnreadTabs = ServerRes.response.unreadTabs
          this.notifcionsklitor = true;
          if (
            ServerRes.response?.bellNotifications &&
            ServerRes.response?.bellNotifications?.length > 0
          ) {
            for (let notiIndex in ServerRes.response.bellNotifications) {
              ServerRes.response.bellNotifications[notiIndex].createdAt =
                new Date(
                  ServerRes.response.bellNotifications[notiIndex].createdAt +
                  ' ' +
                  'UTC'
                );
            }
            this.newnotificationList = ServerRes.response.bellNotifications;
            this.totalPages = ServerRes.response.totalPages;
            this.totalNotificationCount = ServerRes.response.unreadNotifications;

            var obj = {
              eventName: 'refresh_notification',
              event_category: 'bellNotification',
              userType: localStorage.getItem('user_type'),
              EmailID: localStorage.getItem('email'),
            };
            this.sharedService.track(obj);
          } else {
            this.notificationList = [];
            this.totalNotificationCount = 0;
            this.notifcionsklitor = true;
          }
        },
        (error) => {
          this.notificationList = [];
          this.totalNotificationCount = 0;
          this.notifcionsklitor = true;
        }
      );
    }

  }
  redirectToSubscription() {
    if (localStorage.getItem('user_type') === 'CARRIER') {
      this.router.navigate(['/carrier/subscription-overview'])
    }
    if (localStorage.getItem('user_type') === 'SHIPPER') {

      this.router.navigate(['/shipper/subscription-overview'])
    }
  }

  activeSubMenu(activeSubTabs) {
    if (activeSubTabs == 'home') {
      this.router.navigate(['/network/home'])
      this.activeSubTabs = activeSubTabs;
      this.commonService.networkUrl.next(activeSubTabs)
      this.step = 3;
    }
    else if (activeSubTabs == 'My_Network') {
      this.router.navigateByUrl('/network/my-network/connected')
      this.activeSubTabs = activeSubTabs;
      this.commonService.networkUrl.next(activeSubTabs)
      this.step = 3
    }
    else if (activeSubTabs == 'recurringLanes') {
      this.activeSubTabs = activeSubTabs;
      this.router.navigateByUrl('/booking/lane/postedLanes')
      this.step = 1
    }
    else if (activeSubTabs == 'availableLane') {
      this.activeSubTabs = activeSubTabs;
      this.router.navigateByUrl('/recurring-lanes/available-lanes/allLanes')
      this.commonService.networkUrl.next(activeSubTabs)

      this.step = 1
    }
    else if (activeSubTabs == 'createRecurringLane') {
      this.activeSubTabs = activeSubTabs;
      this.router.navigateByUrl('/recurring-lanes/create-lanes/allLanes')
      this.commonService.networkUrl.next(activeSubTabs)

      this.step = 1
    }
    else if (activeSubTabs == 'activeLoadsFromLane') {
      this.activeSubTabs = activeSubTabs;
      this.router.navigateByUrl('/recurring-lanes/active-lanes/inProgressByLane')
      this.step = 1
    }
    else if (activeSubTabs == 'confirmedRatesHistory') {
      this.activeSubTabs = activeSubTabs;
      this.router.navigateByUrl('/recurring-lanes/confirmRateHistory')
      this.step = 1
    }
    else if (activeSubTabs == 'spotCreateLoads') {
      this.activeSubTabs = activeSubTabs;
      this.router.navigateByUrl('/spot-rate-loads/created-loads/allLoads')
      this.commonService.networkUrl.next(activeSubTabs)
      this.step = 2
    }
    else if (activeSubTabs == 'availableLoads') {
      this.activeSubTabs = activeSubTabs;
      this.router.navigateByUrl('/spot-rate-loads/available-loads/allLoads')
      this.commonService.networkUrl.next(activeSubTabs)

      this.step = 2
    }
    else if (activeSubTabs == 'activeLoads') {
      this.activeSubTabs = activeSubTabs;
      this.router.navigateByUrl('/spot-rate-loads/active-loads/inProgressByLoads')
      this.commonService.networkUrl.next(activeSubTabs)

      this.step = 2
      this.markReadAlertNotification('inProgressByLoads');
    }
    else if (activeSubTabs == 'Storage Network') {
      this.activeSubTabs = activeSubTabs;
      this.step = 4
      this.router.navigateByUrl('/storage/shipper')
    }
    else if (activeSubTabs == 'Storage Order') {
      this.activeSubTabs = activeSubTabs;
      this.step = 4
      this.router.navigateByUrl('/storage-order/list/orders')

    }
    else if (activeSubTabs == 'User Management') {
      this.activeSubTabs = activeSubTabs;
      this.router.navigateByUrl('/storage-order/list/orders');
      this.step = null

    }
    else if (activeSubTabs == 'tutorialGuides') {
      this.activeSubTabs = activeSubTabs;
      if (this.userType == 'SHIPPER') {
        this.step = null;
        this.router.navigateByUrl('/tutorial/shipper/recurring-lanes/create-a-lane');
      }
      else if (this.userType == 'CARRIER') {
        this.step = null;
        this.router.navigateByUrl('/tutorial/carrier/recurring-lanes/bid-on-lane');
      }
    }
    else if (activeSubTabs == 'bid-quote-assistant') {
      this.activeSubTabs = activeSubTabs;
      // this.router.navigateByUrl('/bid-quote-assistant')
      this.step = null
    }
    else if (activeSubTabs == 'billing') {
      this.activeSubTabs = activeSubTabs;
      this.step = 6

      if (this.userType == 'SHIPPER') {
        this.router.navigateByUrl('/billing/address');
      }
      else if (this.userType == 'CARRIER') {
        this.router.navigateByUrl('/billing/overview');
      }
      else if (this.userType == 'STORAGE') {
        this.router.navigateByUrl('/billing/overview');

      }
    }
    else if (activeSubTabs == 'subscription') {
      this.activeSubTabs = activeSubTabs;
      this.step = 5;
      if (this.userType == 'SHIPPER') {
        this.router.navigateByUrl('/shipper/subscription-overview');
      }
      else if (this.userType == 'CARRIER') {
        this.router.navigateByUrl('/carrier/subscription-overview');
      }
    }
    else if (activeSubTabs == 'transactions') {
      this.activeSubTabs = activeSubTabs;
      this.step = 4

      if (this.userType == 'SHIPPER') {
        this.router.navigateByUrl('/shipper/transaction/loads?orderDir=DESC');
      }
      else if (this.userType == 'CARRIER') {
        this.router.navigateByUrl('/carrier/transaction/loads?tabIndex=0&orderDir=DESC');
      }
      else if (this.userType == 'STORAGE') {
        this.router.navigateByUrl('/storage/transactions?orderDir=DESC');
      }

    }
    else if (activeSubTabs == 'referralBenefits') {
      this.activeSubTabs = activeSubTabs;
      this.router.navigateByUrl('/carrier/referral/refer-carrier');
      this.step = 5;

    }
    else if (activeSubTabs == 'notificationControl') {
      this.activeSubTabs = activeSubTabs;
      if (this.userType == 'SHIPPER') {
        this.router.navigateByUrl('/shipper/notification-control-panel/network');
      this.step = 5;
      }
      if (this.userType == 'CARRIER') {
        this.router.navigateByUrl('/carrier/notification-control-panel/network');
        this.step = 4;
      }
    }
    else if (activeSubTabs == 'Messages') {
      const body = document.getElementsByTagName('body')[0];
      this.activeSubTabs = activeSubTabs;
      this.step = null
      this.router.navigateByUrl('/messages/active_load/All')
    }
    else if (activeSubTabs == 'settings') {
      this.activeSubTabs = activeSubTabs;
      this.step = 4;
      if (this.userType == 'SHIPPER') {
        this.router.navigateByUrl('/shipper/setting/change-password')
      }
      else if (this.userType == 'CARRIER') {
        this.router.navigateByUrl('/carrier/setting/change-password')
      }
      else if (this.userType == 'STORAGE') {
        this.router.navigateByUrl('/storage/setting/change-password')
      }
    }

    else if(activeSubTabs=='shipmentCreatedByMe'){
      this.activeSubTabs = activeSubTabs;
      this.step = null;
      this.router.navigateByUrl('/load-created-by-me/inProgress')
    }
    else if (window.location.href.includes('/shipper/profile') || window.location.href.includes('/carrier/profile')) {
      this.step = null
      this.activeSubTabs = '';
    }
    else if(activeSubTabs == 'dispute'){
      this.activeSubTabs = activeSubTabs;
      this.step = null
      this.router.navigateByUrl('/dispute/newDispute')
    }
    else if(activeSubTabs == 'Drivers'){
      this.step = null;
      this.activeSubTabs = activeSubTabs;
      this.router.navigateByUrl('/driver/list/All')
    }
  }



  getMoreNotification() {
    var APIparams = this.getAPIParamNoti();
    this.commonService.getList(APIparams).subscribe((ServerRes) => {
      if (
        ServerRes.response.bellNotifications &&
        ServerRes.response.bellNotifications.length > 0
      ) {
        this.spinnderLoader = false;
        for (let v = 0; v < ServerRes.response.bellNotifications.length; v++) {
          if (ServerRes.response.bellNotifications[v])
            this.newnotificationList.push(
              ServerRes.response.bellNotifications[v]
            );
        }
      }
    });
  }

  notificationAction(id, userId, status) {
    var APIparams = {
      apiKey: AppSettings.APIsNameArray.MANAGE_CONNECTION[status],
      uri: '',
      postBody: {
        userPkId: userId,
        bellId: id,
      },
    };
    this.commonService.post(APIparams).subscribe((ServerRes) => {
      if (ServerRes.success === true) {
        for (let notiIndex in this.newnotificationList) {
          if (
            this.newnotificationList[notiIndex].id &&
            this.newnotificationList[notiIndex].id === id
          ) {
            this.newnotificationList[notiIndex].data.actionTaken = 0;
          }
        }
        this.markReadAlertNotification('');
        if (status === 'CONNECT') {
          this.callGoogleAnalytics(userId);
          this.sharedService.NetworkNotifyService.next("Accepted");

          this.alertService.showNotificationMessage(
            'success',
            'bottom',
            'right',
            'txt_s',
            'check_circle',
            'Notification',
            ' Invitation accepted.'
          );
        } else {
          this.alertService.showNotificationMessage(
            'success',
            'bottom',
            'right',
            'txt_s',
            'check_circle',
            'Notification',
            'Invitation rejected.'
          );
        }
      } else {
        this.alertService.showNotificationMessage(
          'danger',
          'bottom',
          'right',
          'txt_d',
          'cancel',
          'Notification',
          ServerRes.message
        );
      }
    });
  }

  markAllRed() {
    this.notifcionsklitor = false;
    let uri = null;
    uri = this.commonService.getAPIUriFromParams({});
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.NOTIFICATION.ALLMARKREAD,
      postBody: { 'tabType': this.selectedTab.toLowerCase() },
      uri: uri,
    };
    this.commonService.put(APIparams).subscribe(
      (ServerRes) => {
        if (ServerRes.success === true) {
          this.totalNotificationCount = '';
          for (let notiIndex in this.newnotificationList) {
            this.newnotificationList[notiIndex].isRead = 1;
          }
          this.totalNotificationCount = '';
          this.notifcionsklitor = true;
          var obj = {
            eventName: 'mark_all_read',
            event_category: 'bellNotification',
            userType: localStorage.getItem('user_type'),
            EmailID: localStorage.getItem('email'),
          };
          this.sharedService.track(obj);
          this.notifictionList(this.selectedTab);
        } else {
          this.notifcionsklitor = true;
        }
      },
      (error) => {
        this.notifcionsklitor = true;
      }
    );
    // this.notifictionList(this.selectedTab);
  }

   getNotifiction(params = {}) {
    this.notifcionsklitor = false;
    let uri = null;
    this.newparams = {
      limit: 10,
    }
    uri = this.commonService.getAPIUriFromParams(this.newparams);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.NOTIFICATION.BEllNOTIFICATION,
      uri: uri,
    };
    this.commonService.getList(APIparams).subscribe(
      (ServerRes) => {
        if (ServerRes.success === true) {
          this.notificationList = ServerRes.response.bellNotifications;
          for (let notiIndex in this.notificationList) {
            this.notificationList[notiIndex].createdAt = new Date(
              this.notificationList[notiIndex].createdAt + ' ' + 'UTC'
            );
          }

          this.totalNotificationCount = ServerRes.response.unreadTabs;
          if (ServerRes.response.bellNotifications.length > 0) {
            this.scrollvariable = false;
          } else {
            this.scrollvariable = true;
          }
          if (this.newparams.page > 1) {
            this.newnotificationList = this.newnotificationList.concat(
              this.notificationList
            );
            this.pageNo += 1;
            this.focus += 7;
            this.spinnderLoader = false;
            setTimeout(() => this.setScrollview(this.focus), 100);
          } else {
            this.spinnderLoader = false;
            this.newnotificationList = this.notificationList;
            this.notifcionsklitor = true;
          }
        } else {
          this.newnotificationList = [];
          this.totalNotificationCount = '';
          this.notifcionsklitor = true;
          this.scrollvariable = false;
        }
      },
      (error) => {
        this.newnotificationList = [];
        this.totalNotificationCount = '';
        this.notifcionsklitor = true;
        this.scrollvariable = false;
      }
    );

  }
  setScrollview(focus: any) {
    if (focus !== null) {
      var elmnt = document.getElementById('setnotification_' + this.focus);
      if (elmnt !== null) {
        elmnt.scrollIntoView();
      }
    }
  }
  getInitials(name) {
    let acronym;
    let specialChar;
    let countLength;
    if (name) {
      acronym = name
        .split(/\s/)
        .reduce((response, word) => (response += word.slice(0, 1)), '');
      countLength = acronym.length;
      if (countLength === 1) {
        acronym = name.substr(0, 2);
      } else if (countLength >= 2) {
        specialChar = acronym.substr(2, 1);
        acronym = acronym.substr(0, 2).replace(/[^\w\s]/gi, specialChar);
      }
    } else acronym = '';
    return acronym;
  }

  callGoogleAnalytics(userId) {
    this.params = {
      id: userId,
    };
    const uri = this.commonService.getAPIUriFromParams(this.params);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.MANAGE_CONNECTION.GETPROFILE,
      uri: uri,
    };
    this.commonService.getList(APIparams).subscribe((ServerRes) => {
      if (ServerRes.success === true) {
        let targetLocation = '-';
        if (
          ServerRes.response &&
          ServerRes.response.phyCity &&
          ServerRes.response.phyState
        ) {
          targetLocation =
            ServerRes.response.phyCity + ',' + ServerRes.response.phyState;
        }
        let name;
        if (ServerRes.response.companyName) {
          name = ServerRes.response.companyName;
        }
        if (ServerRes.response.legalName) {
          name = ServerRes.response.legalName;
        }
        var obj = {
          eventName: 'network_accept_request',
          event_category: 'Network',
          Occurence: 'bell_notification',
          userType: localStorage.getItem('user_type'),
          EmailID: localStorage.getItem('email'),
          dotNumber: ServerRes.response.dotNumber
            ? ServerRes.response.dotNumber
            : '-',
          TargetID: name,
          TargetLocation: targetLocation,
          SourceLocation: this.sharedService.getUserLocationType(),
        };
        this.sharedService.track(obj);
      }
    });
  }

}


