<div class="container_header">
    <div class="ch_l">
      <span class="material-icons page_back" (click)="backHistory()">arrow_back</span>
      <span class="ch_ID ">#{{ loadDetail?.uniqueId }}</span>
      <span class="ch_statue">
        <div class="ship_status" *ngIf="loadDetail?.statusSlug !== 'readyToBid' && loadDetail?.statusSlug !== 'offerRate' && loadDetail?.statusSlug !== 'assignToCarrier' && loadDetail?.statusSlug !== 'expired' && loadDetail?.statusSlug !== 'cancel' && loadDetail?.statusSlug !== 'create' && loadDetail?.statusSlug !== 'assign'">
          <span class="status_label txt_w " [ngStyle]="{ 'background-color': loadDetail?.tripStatus?.Color }">
            <span>{{ loadDetail?.tripStatus?.Text }}</span>
            <div *ngIf="gifLoader !== false">
              <img src="{{serverPathForUrl}}/portal/images/after-login/LaneAxis-loader.gif" style="max-height: 16px"/>
            </div>
          </span>
        </div> 
      </span>
    </div>
  
    <div class="ch_c" *ngIf="loadDetail?.carriers || loadDetail?.status >= 2">
      <div class="line_view_status" *ngIf="loadDetail?.statusSlug">
        <app-progress-load [statusBars]="loadDetail?.statusBars" [statusLabel]="loadDetail?.statusLabel" [statusSlug]="loadDetail?.statusSlug" [updatedAt]="loadDetail?.updatedAt" [deliverydate]="loadDetail?.deliverydate" [statusType]="loadDetail?.status" [fillColor]="loadDetail?.tripStatus?.Color"></app-progress-load>
      </div>
    </div>
    <div class="ch_r">
      <!-- <p *ngIf="(loadDetail?.drivers && loadDetail?.parentStatus !== 1 && loadDetail?.parentStatus !== 3 && !loadDetail?.carriers && loadDetail?.shippers) || (!loadDetail?.shippers && loadDetail?.carriers && (loadDetail?.status === 15 || loadDetail?.status === 59))" class="ship_message disa">
        <img src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-message-blue.svg" class="me-1"/>
        <span class="fs_14 fsm_5 txt_p ">Message Load</span>
      </p>
      <p *ngIf="(loadDetail?.drivers && loadDetail?.parentStatus !== 1 && loadDetail?.parentStatus !== 3 && loadDetail?.carriers && loadDetail?.shippers && loadDetail?.status > 2 && loadDetail?.status !== 15 && loadDetail?.status !== 59) ||
        (loadDetail?.carriers && !loadDetail?.shippers && loadDetail?.status > 2 && loadDetail?.status !== 15 && loadDetail?.status !== 59)" class="ship_message" (click)="newMessageRedirect(loadDetail)">
        <img src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-message-blue.svg" class="me-1"/>
        <span class="fs_14 fsm_5 txt_p ">Message Load</span>
      </p> -->
      <div *ngIf=" loadDetail?.status === 15 || (!loadDetail?.carriers && loadDetail?.status !== 2 && loadDetail?.status !== 3 && loadDetail?.status !== 59)" style="font-size: 24px" class="drop_menu dropdown disa">
        <span class="dropdown-toggle" data-toggle="dropdown">
          <span class="icon-ic-dots dot_icon"></span>
        </span>
      </div>
      <div class="drop_menu dropdown dot_dropdown" *ngIf=" (loadDetail?.carriers && loadDetail?.status !== 15) || loadDetail?.status === 2 || loadDetail?.status === 3 || loadDetail?.status === 59">
        <span class="dropdown-toggle" data-toggle="dropdown">
          <span class="icon-ic-dots dot_icon"><i class="fa-solid fa-ellipsis"></i></span>
        </span>
        <div class="dropdown-menu ship_herd_menu">
          <span class="dropdown-item disabled_drop_btn pointer" [class.disabled]="!checkWebTrackShipmentCondition()" (click)="getWebTrackingLink()">Web Tracking Link</span>
          <span class="dropdown-item disabled_drop_btn pointer " *ngIf="this.currentStatus === 'past' && (this.currentStatus !== 'inProgress' || this.currentStatus !== 'upcoming' || this.currentStatus !== 'pending')" [routerLink]="['/messages/past_load/All']" [queryParams]="{activeChat: 'true', id : loadDetail?.id, title : loadDetail?.title}" [class.disabled]="!checkMessageShipmentCondition()">Message Load</span> 
          <span class="dropdown-item disabled_drop_btn pointer " *ngIf="this.currentStatus !== 'past' && (searchRole === ACTIVE || this.currentStatus === 'inProgress' || this.currentStatus === 'upcoming' || this.currentStatus === 'pending')" [routerLink]="['/messages/active_load/All']" [queryParams]="{activeChat: 'true', id : loadDetail?.id, title : loadDetail?.title}" [class.disabled]="!checkMessageShipmentCondition()">Message Load</span>
          <span class="dropdown-item disabled_drop_btn pointer" (click)="editShipment($event,loadDetail?.id)" [class.disabled]="!checkEditShipmentCondition()" >Edit Load</span>
          <span class="dropdown-item disabled_drop_btn pointer" [class.disabled]="!checkCancelShipmentCondition()" (click)="newShipmentCancel(loadDetail?.id,loadDetail?.uniqueId)">Cancel Load</span>
          <span class="dropdown-item disabled_drop_btn pointer" [class.disabled]="!checkFileDisputeShipmentCondition()" (click)=" disputeShipment( $event, loadDetail?.id, loadDetail.uniqueId, loadDetail?.title)">File A Dispute</span>
        </div>
      </div>
    </div>
  </div>
  
  <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px',fullScreenBackdrop:true }"></ngx-loading>