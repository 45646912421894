
<div class="user_pro_banner" >
    <div class="d-flex align-items-center w_16" [ngClass]="laneShipperDetail?.lastBid?.actionBy !=this.selfUserId ? 'pointer' : ''" *ngIf="laneShipperDetail?.carrier" (click)="eventActionProfileSidePanel(laneShipperDetail,'profile_side_panel')">
        <div class="carrierN_img">
            <span *ngIf="laneShipperDetail?.isPreferred === 1" class="material-icons star_marked">star</span>
            <img *ngIf="laneShipperDetail?.carrier?.profileImage" src="{{laneShipperDetail?.carrier?.profileImage}}" style="border-radius: 50%; max-width: 50px;" class="img-fluid">
            <span class="initial_name initial_r_32" style="border-radius: 50%; max-width: 50px;"
              *ngIf="!laneShipperDetail?.carrier?.profileImage" >{{laneShipperDetail?.carrier?.legalName | acronymOfName}}</span>
          </div>
   
        <div class="ms-3">
            <div class="name_icon">
            <span class="fs_18 fw_500 txt_b text_truncate pointer" matTooltip="{{laneShipperDetail?.carrier?.legalName ? laneShipperDetail?.carrier?.legalName : '-'}}">{{laneShipperDetail?.carrier?.legalName ? laneShipperDetail?.carrier?.legalName : '-'}}</span>
            <img *ngIf="laneShipperDetail?.carrier?.subscription" src="{{serverPathForUrl}}/portal/images/after-login/premium_plan_icon.png"
                 class="img-fluid verified_icon">
                </div>
            <div class="ship_card_rating">
                <span class="fs_12 mr-1">Carrier</span>
            <app-rating-view [rate]="laneShipperDetail?.carrier?.ratingReview?.rate" [ratingReview]="laneShipperDetail?.carrier?.ratingReview" [ratingOwnerDetail]="ratingOwnerDetail"
              [shipmentDetail]="laneShipperDetail" [type]="type"
              *ngIf="laneShipperDetail?.carrier?.ratingReview?.rate && userType ==='CARRIER'"></app-rating-view>
          </div>
        </div>
    </div>
    <div class="d-flex align-items-center" *ngIf="laneShipperDetail?.shipper && laneShipperDetail?.lastBid?.actionBy != selfUserId" [ngClass]="(laneShipperDetail?.lastBid?.actionBy != this.selfUserId && laneShipperDetail?.lastBid?.status==2) ? 'pointer' : ''"  (click)="eventActionProfileSidePanel(laneShipperDetail,'profile_side_panel')">
        <div class="carrierN_img">
            <span *ngIf="laneShipperDetail?.isPreferred === 1" class="material-icons star_marked">star</span>
            <img *ngIf="laneShipperDetail?.shipper?.profileImage" src="{{laneShipperDetail?.shipper?.profileImage}}" style="border-radius: 50%; max-width: 50px;" class="img-fluid">
            <span class="initial_name initial_r_32" style="border-radius: 50%; max-width: 50px;"
              *ngIf="!laneShipperDetail?.shipper?.profileImage" >{{laneShipperDetail?.shipper?.companyName | acronymOfName}}</span>
          </div>
   
        <div class="ml_15">
            <span class="fs_18 fw_500 txt_b text-truncate" [ngClass]="!laneShipperDetail?.isConnected ? 'blur' : ''" >{{laneShipperDetail?.shipper?.companyName ? laneShipperDetail?.shipper?.companyName : '-'}}</span>
           
            <div class="ship_card_rating">
                <span class="fs_12 mr-1">Shipper</span>
            <app-rating-view [rate]="laneShipperDetail?.shipper?.ratingReview?.rate" [ratingReview]="laneShipperDetail?.shipper?.ratingReview" [ratingOwnerDetail]="ratingOwnerDetail"
              [shipmentDetail]="laneShipperDetail" [type]="type"
              *ngIf="laneShipperDetail?.shipper?.ratingReview?.rate"></app-rating-view>
          </div>
        </div>
    </div>
    <div class="text_truncate user_mess" matTooltip="{{laneShipperDetail?.lastBid?.note ? laneShipperDetail?.lastBid?.note : ''}}">{{laneShipperDetail?.lastBid?.note ? laneShipperDetail?.lastBid?.note : '-'}}</div>
    <div *ngIf="laneShipperDetail?.lastBid">
        <p class="txt_p cu_bid">Current Quote Rate:</p>
        <p class="price_p">
            <span>${{(laneShipperDetail?.lastBid?.bidAmount ? laneShipperDetail?.lastBid?.bidAmount : '-') | twoDecimalPipe}}</span>
            
        <span class="txt_lb">
            <app-rate-per-mile clw_16ass="fs_14 fsm_5 mt-1 txt_db" [amount]="laneShipperDetail?.lastBid?.bidAmount" [miles]="laneShipperDetail?.miles"></app-rate-per-mile>
        </span></p>
    </div>
    <div *ngIf="!laneShipperDetail?.lastBid  && laneShipperDetail.rateType=='1'">
        <p class="txt_p">Quote Rate:</p>
        <p class="price_p">-</p>
    </div>
    <div *ngIf="!laneShipperDetail?.lastBid && laneShipperDetail.rateType=='2'">
        <p class="txt_p">Fixed Rate:</p>
        <p class="price_p"><span>${{laneShipperDetail?.estimatedPrice ? laneShipperDetail?.estimatedPrice : '-'}}</span>
        <span>
            <app-rate-per-mile clw_16ass="fs_14 fsm_5 mt-1 txt_db" [amount]="laneShipperDetail?.estimatedPrice" [miles]="laneShipperDetail?.miles"></app-rate-per-mile>
        </span></p>
    </div>

    <div *ngIf="!isArchived" class="d_flex_w_27">
       <span *ngIf="userType ==='CARRIER' && !laneShipperDetail?.lastBid && laneShipperDetail?.rateType == '1'" class="view_btn"><button
          (click)="eventAction(laneShipperDetail,'firstLaneBidCarrier')">Submit Your Quote</button>
       </span> 
       <span *ngIf="userType ==='CARRIER' && laneShipperDetail?.rateType == '2' && laneShipperDetail?.isConnected == true && laneShipperDetail?.lastBid?.status != 2" class="act_btn">
          <button (click)="eventAction(laneShipperDetail,'laneAcceptBidForFixRate')"class="">Accept</button>
       </span>

      <span class="re_bid_btn" *ngIf="currentStatus == 'laneRate' && laneShipperDetail?.lastBid && laneShipperDetail?.lastBid?.status != 2"><button class="btn" (click)="eventAction(laneShipperDetail,'laneRebidCarrier')">Re-quote</button></span> 
      <span class="Accept_btn" *ngIf="laneShipperDetail?.lastBid && laneShipperDetail?.lastBid?.actionBy != selfUserId && currentStatus == 'laneRate' && laneShipperDetail?.isConnected == true && laneShipperDetail?.lastBid?.status != 2 && laneShipperDetail?.rateType == '1'"><button class="btn" (click)="eventAction(laneShipperDetail,'laneAcceptBidShipperCarrier')">Accept</button></span>
      <span class="Accept_btn adisabled" *ngIf="laneShipperDetail?.lastBid?.actionBy == selfUserId && currentStatus == 'laneRate' && laneShipperDetail?.lastBid?.status != 2 && laneShipperDetail?.rateType == '1' && laneShipperDetail?.isConnected == true"><button class="btn bg_green adisabled">Accept</button></span>
      <span class="Accept_btn" *ngIf="laneShipperDetail?.lastBid && laneShipperDetail?.lastBid?.actionBy != selfUserId && laneShipperDetail?.isConnected == false && currentStatus == 'laneRate' && laneShipperDetail?.lastBid?.status != 2 && laneShipperDetail?.rateType == '1'"><button class="btn" (click)="eventAction(laneShipperDetail,'connectInviteLaneCarrier')">Accept</button></span>
      <span class="Accept_btn adisabled" *ngIf="laneShipperDetail?.lastBid?.actionBy == selfUserId && currentStatus == 'laneRate' && laneShipperDetail?.lastBid?.status != 2 && laneShipperDetail?.rateType == '1' && laneShipperDetail?.isConnected == false"><button class="btn">Accept</button></span>
      <span *ngIf="laneShipperDetail?.lastBid?.status == 2 && laneShipperDetail?.rateType == '1'" class="confi_btn"><span class="txt_green">Confirmed</span></span>
      <span *ngIf="laneShipperDetail?.lastBid?.status == 2 && laneShipperDetail?.rateType == '2'" class="confi_btn"><span class="txt_green">Confirmed</span></span>
      <span class="Accept_btn" *ngIf="userType ==='CARRIER' && laneShipperDetail?.rateType == '2' && currentStatus == 'laneRate' && laneShipperDetail?.isConnected == false && laneShipperDetail?.lastBid?.status != 2"><button class="btn" (click)="eventAction(laneShipperDetail,'connectInviteLaneCarrierForFixRate')">Accept</button></span>
    </div>

    <div *ngIf="isArchived == true" class="d_flex_w_27">
        <span *ngIf="userType ==='CARRIER' && !laneShipperDetail?.lastBid && laneShipperDetail?.rateType == '1'" class="view_btn adisabled"><button
            >Submit Your Quote</button>
         </span> 
         <span *ngIf="userType ==='CARRIER' && laneShipperDetail?.rateType == '2' && laneShipperDetail?.isConnected == true && laneShipperDetail?.lastBid?.status != 2" class="act_btn">
            <button class="adisabled">Accept</button>
         </span>
        <span class="re_bid_btn" *ngIf="currentStatus == 'laneRate' && laneShipperDetail?.lastBid && laneShipperDetail?.lastBid?.status != 2"><button class="btn adisabled">Re-bid</button></span> 
        <span class="Accept_btn" *ngIf="laneShipperDetail?.lastBid && laneShipperDetail?.lastBid?.actionBy != selfUserId && currentStatus == 'laneRate' && laneShipperDetail?.isConnected == true && laneShipperDetail?.lastBid?.status != 2 && laneShipperDetail?.rateType == '1'"><button class="btn adisabled">Accept</button></span>
        <span class="Accept_btn adisabled" *ngIf="laneShipperDetail?.lastBid?.actionBy == selfUserId && currentStatus == 'laneRate' && laneShipperDetail?.lastBid?.status != 2 && laneShipperDetail?.rateType == '1' && laneShipperDetail?.isConnected == true"><button class="btn bg_green adisabled">Accept</button></span>
        <span class="Accept_btn" *ngIf="laneShipperDetail?.lastBid && laneShipperDetail?.lastBid?.actionBy != selfUserId && laneShipperDetail?.isConnected == false && currentStatus == 'laneRate' && laneShipperDetail?.lastBid?.status != 2 && laneShipperDetail?.rateType == '1'"><button class="btn adisabled">Accept</button></span>
        <span class="Accept_btn " *ngIf="laneShipperDetail?.lastBid?.actionBy == selfUserId && currentStatus == 'laneRate' && laneShipperDetail?.lastBid?.status != 2 && laneShipperDetail?.rateType == '1' && laneShipperDetail?.isConnected == false"><button class="btn adisabled">Accept</button></span>
        <span *ngIf="laneShipperDetail?.lastBid?.status == 2 && laneShipperDetail?.rateType == '1'" class="confi_btn"><span class="txt_green">Confirmed</span></span>
        <span *ngIf="laneShipperDetail?.lastBid?.status == 2 && laneShipperDetail?.rateType == '2'" class="confi_btn"><span class="txt_green">Confirmed</span></span>
        <span class="Accept_btn" *ngIf="userType ==='CARRIER' && laneShipperDetail?.rateType == '2' && currentStatus == 'laneRate' && laneShipperDetail?.isConnected == false && laneShipperDetail?.lastBid?.status != 2"><button class="btn adisabled">Accept</button></span>
    </div>
</div>
<div class="red_strip" *ngIf="!laneShipperDetail?.isNetworkCreditExists">
    <span >Invite Credits is required to confirm this lane, <span (click)="buyCredit()" class="underline pointer">click here</span> to buy Invite credits</span>
</div>

<div class="" style="position: relative;" *ngIf="sidePanelProfileDataId">
    <div class="side-show-panel">
      <div class="side_bar_con">
        <app-shipper-side-panel [sidePanelProfileData]="sidePanelProfileData" [sidePanelProfileDataId]="sidePanelProfileDataId" [userType]="userType" 
        (closeSidePanel)="closePanel($event)"></app-shipper-side-panel>
      </div>
    </div>
  </div>