<div class="route_plan_con" *ngIf="!skeletonLoader">
    <div class="play_route_box">
      <div class="row">
        <div class="col-md-12">
          <div class="dri_card_head">
            <p class="line_before">Route Plan (Drag and drop title to record & plan your route)</p>
          </div>
  
          <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
            <div class="route_row {{item?.type}} {{item?.status}}"
              [ngClass]="dateErrorMessage === true && item?.errorIndex ? 'error_card' : ''"
              *ngFor="let item of wayPoints; let i = index;" [cdkDragDisabled]="((item?.status === 'Completed') || (userType=='CARRIER' && loadDetail?.shipperId) || loadDetail?.laneId) ? true : false" cdkDrag>
              <div class="pl_row new_route_tag">
                <p class="new_tag" *ngIf="!item?.id">New</p>
                <div class="pl_row_l">
                  <img src="https://s3.us-east-1.amazonaws.com/staticprod.laneaxis.com/portal/images/after-login/route-pickup.png"
                    class="img-fluid route_pickup">
                  <img src="https://s3.us-east-1.amazonaws.com/staticprod.laneaxis.com/portal/images/after-login/route-dropoff.png"
                    class="img-fluid route_dropoff">
                </div>
                <div class="pl_row_r">
                  <div class="pl_card">
                    <div class="pl_card_l">
                      <p class="pl_card_ltl">
                        <span class="pl_tlt text-truncate" style="max-width: 200px;">{{item?.type ? (item?.type | titlecase) : '-'}} Point
                          {{item?.locationOrder ? item?.locationOrder : '-'}} |
                          {{item?.businessName ? item?.businessName : '-'}}</span>
                        <span class="ship_sts {{item?.status}}">{{item?.status ? item?.status : 'Open'}}</span>
  
                        <span class="ship_sts" [ngStyle]="{'background-color': item?.tripStatus?.Color}"
                          *ngIf="item?.tripStatus?.Text && loadDetail?.statusSlug != 'readyToBid' && loadDetail?.statusSlug != 'offerRate'">{{item?.tripStatus?.Text}}</span>
                        <span *ngIf="!item?.tripStatus?.Text && item?.tripStatus?.Text != ''">
                          <img src="{{serverPathForUrl}}/portal/images/after-login/LaneAxis-loader.gif" class="lodr_gif">
                        </span>
                      </p>
  
                      <p class="pl_dis text_truncate">
                        <img src="{{serverPathForUrl}}/portal/images/after-login/icon-map-marker.svg"
                          class="img-fluid">
                        <span>{{item?.locationLongName ? item?.locationLongName : '-'}}</span>
                      </p>
                      <div class="pl_card_b"
                        [ngClass]="(edit_card && selectedIndex === i) ? 'edtCard_show' : 'edtCard_hide'">
                        <p class="pl_subdis">
                          <img src="{{serverPathForUrl}}/portal/images/after-login/icon-clock.svg"
                            class="img-fluid">
                          <span>{{item?.date | date: 'MMM dd, yyyy'}} at {{item?.timeSlot ? item?.timeSlot: "-"}}
                            {{item?.timeZone ? item?.timeZone : "-"}}</span>
                          <span *ngIf="userType === 'SHIPPER' && loadDetail && loadDetail?.shippers && !loadDetail?.laneId">
                            <img src="{{serverPathForUrl}}/portal/images/after-login/icon-pencil.svg"
                              class="img-fluid icon_pencil" (click)="cardClickEvent(i)"></span>
                          <span
                            *ngIf="userType === 'CARRIER' && loadDetail && loadDetail?.carriers && !loadDetail?.shippers">
                            <img src="{{serverPathForUrl}}/portal/images/after-login/icon-pencil.svg"
                              class="img-fluid icon_pencil" (click)="cardClickEvent(i)"></span>
                        </p>
                        <div class="edit_card_box">
                          <form class="mb-0 custum_input_css inputInPopup" [formGroup]="wayPointEditForm"
                            (ngSubmit)="dateTimeEditFormSubmit(wayPointEditForm, i)" autocomplete="off">
                            <div class="row">
                              <div class="col-md-4 ic_calander">
                                <p class="fs_14 fsn_4 txt_lb mb-1">Edit Date <span class="txt_d"> *</span></p>
                                <mat-form-field appearance="none" class="drop_bg cal_input">
                                  <input matInput formControlName="pickupDate" [matDatepicker]="dp2"
                                    placeholder="Select Date" [min]="minDate" [max]="maxDate"
                                    (dateChange)="pickDateEvent($event)" [readonly]="true"
                                    class="form-control custom_input" />
                                  <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
                                  <mat-datepicker #dp2 disabled="false"></mat-datepicker>
                                  <mat-error *ngIf="wayPointEditForm.get('pickupDate').errors?.['required']"
                                    class="error_mess">Date is required.</mat-error>
                                  <mat-error *ngIf="wayPointEditForm.get('pickupDate').errors?.['matDatepickerMin']"
                                    class="error_mess">Date is Expired.</mat-error>
                                </mat-form-field>
                              </div>
                              <div class="col-md-4 ic_dropdown">
                                <p class="fs_14 fsn_4 txt_lb mb-1">Edit Time <span class="txt_d"> *</span></p>
                                <mat-form-field appearance="none">
                                  <mat-select (selectionChange)="timeRestriction($event)" placeholder="Select Time"
                                    formControlName="pickupTime" name="pickupTime" id="pickupTime"
                                    class="form-control custom_input">
                                    <mat-option *ngFor="let timevalue of pickupTimeArr" [value]="timevalue"
                                      [disabled]="timevalue.disabled">{{ timevalue.time }}</mat-option>
                                  </mat-select>
                                  <mat-error *ngIf="true" class="error_mess">Time is required.</mat-error>
                                  <small *ngIf="isTimeSloteAvailble" class="error_mess">{{noTimeSloteError}}</small>
                                  <small *ngIf="isTimeSloteExpired" class="error_mess">Time slot has been expired</small>
                                </mat-form-field>
                              </div>
                              <div class="col-md-4 ic_dropdown">
                                <p class="fs_14 fsn_4 txt_lb mb-1">Edit Time Zone <span class="txt_d"> *</span></p>
                                <mat-form-field appearance="none">
                                  <mat-select formControlName="pickupTimeZone" name="pickupTimeZone" id="pickupTimeZone"
                                    placeholder="Select Time Zone" class="form-control custom_input">
                                    <mat-option *ngFor="let piTimeZone of pickupTZ" [value]="piTimeZone.tzShort"
                                      matTooltip="{{ piTimeZone.tzLong }}">{{ piTimeZone.tzShort }}</mat-option>
                                  </mat-select>
                                  <mat-error *ngIf="wayPointEditForm?.get('pickupTimeZone').errors?.['required']"
                                    class="error_mess">Time Zone is required.</mat-error>
                                </mat-form-field>
                              </div>
                            </div>
                            <div class="apoent_date_actn">
                              <button [disabled]="invalidDropDate" type="submit" class="btn custum_button button_primary"
                                id="update" name="update">Update</button>
                              <button type="button" class="btn custum_button cnl_btn ms-4" id="cancel" name="cancel"
                                (click)="onNoClick()">Cancel</button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div class="pl_card_r">
                      <p class="plr_b" [ngClass]="loadDetail?.laneId ? 'route_disabled' : ''" 
                        *ngIf="userType === 'SHIPPER' && loadDetail && loadDetail?.shippers && !loadDetail?.laneId">
                        <img src="https://s3.us-east-1.amazonaws.com/staticprod.laneaxis.com/portal/images/after-login/route-edit.png"
                          (click)="editRoute(i)" class="img-fluid route_edit">
                        <img src="https://s3.us-east-1.amazonaws.com/staticprod.laneaxis.com/portal/images/after-login/route-delete.png"
                          (click)="deleteRoute(i)" class="img-fluid route_delete">
                      </p>
                      <p class="plr_b " [ngClass]="loadDetail?.laneId ? 'route_disabled' : ''" 
                       *ngIf="userType === 'SHIPPER' && loadDetail && loadDetail?.shippers && loadDetail?.laneId">
                      <img src="https://s3.us-east-1.amazonaws.com/staticprod.laneaxis.com/portal/images/after-login/route-edit.png"
                       class="img-fluid route_edit opacity-50">
                      <img src="https://s3.us-east-1.amazonaws.com/staticprod.laneaxis.com/portal/images/after-login/route-delete.png"
                       class="img-fluid route_delete opacity-50">
                     </p>
                      <p class="plr_b " [ngClass]="loadDetail?.laneId ? 'route_disabled' : ''" 
                        *ngIf="userType === 'CARRIER' && loadDetail && loadDetail?.carriers && !loadDetail?.shippers">
                        <img src="https://s3.us-east-1.amazonaws.com/staticprod.laneaxis.com/portal/images/after-login/route-edit.png"
                          (click)="editRoute(i)" class="img-fluid route_edit">
                        <img src="https://s3.us-east-1.amazonaws.com/staticprod.laneaxis.com/portal/images/after-login/route-delete.png"
                          (click)="deleteRoute(i)" class="img-fluid route_delete">
                      </p>
                      <p class="plr_arrived"
                        *ngIf="item?.status === 'Completed' && loadDetail?.statusSlug !== 'expired' && loadDetail?.statusSlug !== 'cancel'">
                        <span class="txt_b">Arrived:</span>
                        <span class="txt_lb"> {{item?.arrivedAt ? (item?.arrivedAt | date: 'MMM dd, yyyy') : '-'}}</span>
                        <span class="ms-2"> {{item?.arrivedAt ? (item?.arrivedAt | date: 'HH:mm:ss') : '-'}}</span>
                      </p>
                      <p class="plr_arrived"
                        *ngIf="item?.status !== 'Completed' && loadDetail?.statusSlug !== 'expired' && loadDetail?.statusSlug !== 'cancel'">
                        <span class="txt_b">ETA: </span>
                        <span *ngIf="!item.eta">
                          <img src="{{serverPathForUrl}}/portal/images/after-login/LaneAxis-loader.gif"
                            class="lodr_gif">
                        </span>
                        <span *ngIf="item.eta" class="txt_lb">{{item.eta !== "-" ? (item.eta | date: 'MMM dd, yyyy') : "-"}}</span>
                        <span *ngIf="item.eta" class="ms-2">{{item.eta !== "-" ? (item.eta | date: 'HH:mm:ss') : ""}}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <p class="error_crd_mess" *ngIf="dateErrorMessage && item?.errorIndex">
                {{item?.type | titlecase}} point {{item?.locationOrder}} waypoint's date & time should be greater than the
                {{wayPoints[i-1].type | titlecase}} point {{wayPoints[i-1].locationOrder}} waypoint.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="rpc_action"
      *ngIf="userType === 'SHIPPER' && loadDetail && loadDetail?.shippers && loadDetail?.statusSlug !== 'expired' 
      && loadDetail?.statusSlug !== 'cancel' && loadDetail?.statusSlug !== 'completed' && loadDetail?.statusSlug !== 'delivered'">
      <span class="drop_menu dropdown">
        <button type="button" class="btn dropdown-toggle" data-toggle="dropdown" *ngIf="!loadDetail?.laneId">
          <span style="display: flex; align-items: center;">
            <i class="fa-solid fa-plus fs_20 fs_m_5 me-2"></i>
            <span >Save and Add another Waypoint</span>
          </span>
          <span  class="icon-ic-Down fs_20 fs_m_5 ms-1 d-flex"></span>
        </button>
        <button type="button" class="btn dropdown-toggle opacity-50 disableSave" *ngIf="loadDetail?.laneId">
          <span style="display: flex; align-items: center;">
            <i class="fa-solid fa-plus fs_20 fs_m_5 me-2"></i>
            <span >Save and Add another Waypoint</span>
          </span>
          <span class="icon-ic-Down fs_20 fs_m_5 ms-1 d-flex opacity-50"></span>
        </button>
        <div class="dropdown-menu" *ngIf="!loadDetail?.laneId">
          <span class="dropdown-item pointer" (click)="addRoute('pick')">+ Save and Add another pickup
            Waypoint</span>
          <span class="dropdown-item pointer" (click)="addRoute('drop')">+ Save and Add another dropoff
            Waypoint</span>
        </div>
      </span>
      <button *ngIf="loadDetail?.laneId" class="custum_button button_success ml-4 fs_16 opacity-50" style="border: none;"  disabled>Save Route</button>
      <button *ngIf="!loadDetail?.laneId" class="btn custum_button button_success ml-4 fs_16" (click)="saveRoute()" style="box-shadow: 0px 8px 16px 0px rgba(4, 26, 52, 0.3019607843);">Save Route</button>
    </div>
  
    <div class="rpc_action"
      *ngIf="userType === 'CARRIER' && loadDetail && loadDetail?.carriers && !loadDetail?.shippers && 
    loadDetail?.statusSlug !== 'expired' && loadDetail?.statusSlug !== 'cancel' && loadDetail?.statusSlug !== 'completed' && loadDetail?.statusSlug !== 'delivered'">
      <span class="drop_menu dropdown">
        <button type="button" class="btn dropdown-toggle" data-toggle="dropdown">
          <span style="display: flex; align-items: center;">
            <i class="fa-solid fa-plus fs_20 fs_m_5 me-2"></i>
            <span >Save and Add another Waypoint</span>
          </span>
          <span class="icon-ic-Down fs_20 fs_m_5 ms-1 d-flex"></span>
        </button>
        <div class="dropdown-menu">
          <span class="dropdown-item pointer" (click)="addRoute('pick')">+ Save and Add another pickup
            Waypoint</span>
          <span class="dropdown-item pointer" (click)="addRoute('drop')">+ Save and Add another dropoff
            Waypoint</span>
        </div>
      </span>
      <button class="btn custum_button button_success ml-4" (click)="saveRoute()">Save Route</button>
    </div>
  </div>
  
  
  <div class="route_plan_con skt_lodr" *ngIf="skeletonLoader">
    <div class="play_route_box">
      <div class="row">
        <div class="col-md-12">
          <p>
            <ngx-skeleton-loader [theme]="{ width: '100%', height: '18px' }"
              class="loader_m_0 skt_btn_loader mb-2"></ngx-skeleton-loader>
          </p>
          <div class="plb_instraction">
            <ngx-skeleton-loader [theme]="{ width: '100%', height: '46px' }"
              class="loader_m_0 skt_btn_loader mb-2"></ngx-skeleton-loader>
          </div>
          <div class="pl_row" *ngFor="let fake of generateFake(3)">
            <div class="pl_row_l">
              <ngx-skeleton-loader appearance="circle" [theme]="{ width: '36px', height: '36px' }"
                class="loader_m_0 skt_btn_loader mb-2"></ngx-skeleton-loader>
            </div>
            <div class="pl_row_r">
              <div class="pl_card">
                <ngx-skeleton-loader [theme]="{ width: '100%', height: '18px' }"
                  class="loader_m_0 skt_btn_loader mb-2"></ngx-skeleton-loader>
                <ngx-skeleton-loader [theme]="{ width: '100%', height: '16px' }"
                  class="loader_m_0 skt_btn_loader mb-2"></ngx-skeleton-loader>
                <ngx-skeleton-loader [theme]="{ width: '100%', height: '12px' }"
                  class="loader_m_0 skt_btn_loader mb-2"></ngx-skeleton-loader>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>