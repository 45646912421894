<div class="tab_header" id="list-tab" role="tablist">
    <span class="tab_btn_box" *ngIf="(userType==='CARRIER' || userType==='SHIPPER') && (loadDetail?.status !== 59)">
       <button [ngClass]="currentTabShow ==='summary' ? 'activeProfileTab':''" class="tab_header_btn" (click)="currentTabActive('summary')">Summary</button>
    </span>
 
    <span class="tab_btn_box" *ngIf="userType==='CARRIER' || userType==='SHIPPER'">
       <button [ngClass]="currentTabShow==='details' ? 'activeProfileTab':''" class="tab_header_btn" (click)="currentTabActive('details')">Details</button>
    </span>
 
    <span class="tab_btn_box" *ngIf="loadDetail?.status !== 59">
       <button [ngClass]="currentTabShow==='route-plan' ? 'activeProfileTab':''" class="tab_header_btn" (click)="currentTabActive('route-plan')">Route Plan</button>
    </span>
 
    <span class="tab_btn_box" *ngIf="loadDetail?.shippers && !loadDetail?.carriers && userType==='CARRIER' && (loadDetail.status === 2 || loadDetail.status === 3)">
       <button [ngClass]="currentTabShow==='carrierLoadBids' ? 'activeProfileTab':''" class="tab_header_btn" (click)="currentTabActive('carrierLoadBids')">Load Booking</button>
    </span>
 
    <span class="tab_btn_box" *ngIf="(loadDetail?.status >= 10 && loadDetail?.status !== 59) || (loadDetail && !loadDetail?.carriers && loadDetail?.status === 1 && loadDetail?.status !== 59 && loadDetail?.bids != null) ">
       <button [ngClass]="currentTabShow==='driver' ? 'activeProfileTab':''" class="tab_header_btn" (click)="currentTabActive('driver')">Driver</button>
    </span>
 
    <span class="tab_btn_box" *ngIf="loadDetail?.status >= 10 && loadDetail?.status !== 59 && loadDetail?.status !== 15">
       <button [ngClass]="currentTabShow==='documents' ? 'activeProfileTab':''" class="tab_header_btn" (click)="currentTabActive('documents')">Documents</button>
    </span>
 
    <span class="tab_btn_box" *ngIf="(loadDetail?.carriers && loadDetail?.status >=10) || (loadDetail?.shippers && loadDetail?.status=== 59) || (loadDetail?.statusSlug==='completed' || loadDetail?.statusSlug==='delivered' || loadDetail?.statusSlug==='dispute' )">
       <button [ngClass]="currentTabShow==='load' ? 'activeProfileTab':''" class="tab_header_btn" (click)="currentTabActive('load')">View Load History</button>
    </span>
    <span class="tab_btn_box" *ngIf="userType==='SHIPPER' && (loadDetail?.statusSlug==='readyToBid' || loadDetail?.statusSlug==='offerRate')">
       <button [ngClass]="currentTabShow==='loadBids' ? 'activeProfileTab':''" class="tab_header_btn" (click)="currentTabActive('loadBids')">Load Booking</button>
    </span>
 
    <span class="tab_btn_box" style="min-width: 110px;" *ngIf="loadDetail?.status >=10 && loadDetail?.shipperId && loadDetail?.status !== 59">
       <button [ngClass]="currentTabShow==='history' ? 'activeProfileTab':''" class="tab_header_btn" (click)="currentTabActive('history')">Quote History</button>
    </span>
 
    <span class="tab_btn_box" *ngIf="(loadDetail?.statusSlug==='readyToBid' || loadDetail?.statusSlug==='offerRate') && !loadDetail?.carriers && userType==='SHIPPER'">
       <button [ngClass]="currentTabShow === 'addCarrierBid' ? 'activeProfileTab':''" class="tab_header_btn" (click)="currentTabActive('addCarrierBid')">Add Carrier to quote</button>
    </span>
 
    <span class="tab_btn_box"  *ngIf="loadDetail?.shippers?.id && loadDetail?.status === 54">
       <button class="tab_header_btn" [ngClass]="currentTabShow==='receipt' ? 'activeProfileTab':''" (click)="currentTabActive('receipt')">Receipt</button>
    </span>
    <span class="tab_btn_box" *ngIf="loadDetail?.status >=51 && loadDetail?.status !==59">
       <button class="tab_header_btn" [ngClass]="currentTabShow==='breadcrumb' ? 'activeProfileTab':''" (click)="currentTabActive('breadcrumb')">Breadcrumb Trail</button>
    </span>
    
    <span class="tab_btn_box" *ngIf="(userType==='SHIPPER' && loadDetail?.ratingReview !== null && (loadDetail?.statusSlug==='delivered' || loadDetail?.statusSlug==='completed'))">
       <button class="tab_header_btn" [ngClass]="currentTabShow==='rating' ? 'activeProfileTab':''" (click)="currentTabActive('rating')">Review and Rating</button>
    </span>
 
    <span class="tab_btn_box" *ngIf="(userType==='CARRIER' && loadDetail?.ratingReview !== null && (loadDetail?.statusSlug==='delivered' || loadDetail?.statusSlug==='completed'))">
       <button class="tab_header_btn" [ngClass]="currentTabShow==='rating' ? 'activeProfileTab':''" (click)="currentTabActive('rating')">Review and Rating</button>
    </span>
 
    <span class="tab_btn_box" *ngIf="loadDetail?.shippers?.id && loadDetail?.status ===53">
       <button class="tab_header_btn" [ngClass]="currentTabShow==='dispute' ? 'activeProfileTab':''" (click)="currentTabActive('dispute')">Dispute</button>
    </span>
 
    <span class="tab_btn_box" *ngIf="loadDetail?.shippers?.id && loadDetail?.status ===53">
       <button class="tab_header_btn" [ngClass]="currentTabShow==='response' ? 'activeProfileTab':''" (click)="currentTabActive('response')">Responses</button>
    </span>
 </div>