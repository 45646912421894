<div class="popup_box" *ngIf="data?.openPop !=='enterprisePlanRequestedPopup'">
  <span class="close_popup_Icon close_icon" (click)="onNoConfirmClick()" ><img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon"></span>
  <p class="heading-txt">Contact Us For Enterprise Plan</p>
  <div class="grey_header"><span><img src="https://s3.us-east-2.amazonaws.com/staticdev.laneaxis.com/shipper/profile-image/3156_20230808_064614_blob"
        class="sub_img"></span>
    <p class="popup_heading">Enterprise Plan</p>
  </div>
  <div class="input_box">
    <form [formGroup]="enterPriceForm" (ngSubmit)="submitInterPricePlan(enterPriceForm)" autocomplete="off"
      class="mb-0 custum_input_css inputInPopup" autocomplete="off">
      <div>
        <p class="m-0"><span class="name">Full Name</span><span class="txt_d ms-1">*</span></p>
        <mat-form-field appearance="none">
          <input matInput formControlName="name" placeholder="Enter Full Name" maxlength="65"
            class="form-control custom_input">
          <mat-error *ngIf="enterPriceForm.get('name').errors?.['required']"
            class="error_mess">Full name is required.</mat-error>
          <mat-error *ngIf="enterPriceForm.get('name').errors?.['pattern']" class="error_mess">Please
            enter valid information.</mat-error>
          <mat-error *ngIf="enterPriceForm.get('name').errors?.['maxlength']" class="error_mess">Only
            64 characters are allowed.</mat-error>
        </mat-form-field>
      </div>
      <div>
        <p class="m-0"><span class="name">Company Name</span><span class="txt_d fs_14 ms-1">*</span></p>
        <mat-form-field appearance="none">
          <input matInput formControlName="companyName" placeholder="Enter Company Name" maxlength="129"
            class="form-control custom_input">
          <mat-error *ngIf="enterPriceForm.get('companyName').errors?.['required']"
            class="error_mess">Company name is required.</mat-error>
          <mat-error *ngIf="enterPriceForm.get('companyName').errors?.['pattern']" class="error_mess">
            Please enter valid information.</mat-error>
          <mat-error *ngIf="enterPriceForm.get('companyName').errors?.['maxlength']" class="error_mess">
            Only 128 characters are allowed.</mat-error>
        </mat-form-field>
      </div>
      <div>
        <p class="m-0"><span class="name">Email Address</span><span class="txt_d fs_14 ms-1">*</span></p>
        <mat-form-field appearance="none">
          <input matInput formControlName="email" placeholder="Enter Email Address" maxlength="129"
            class="form-control custom_input">
          <mat-error *ngIf="enterPriceForm.get('email').errors?.['required']" class="error_mess"> Email
            is required.</mat-error>
          <mat-error *ngIf="enterPriceForm.get('email').errors?.['pattern']" class="error_mess"> Please
            enter valid email address.</mat-error>
          <mat-error
            *ngIf="(enterPriceForm.get('email').errors?.['maxlength']) && !(enterPriceForm.get('email').errors?.['pattern'])"
            class="error_mess">Email must not exceed 128 characters.</mat-error>
        </mat-form-field>
      </div>
      <div>
        <div class="selection_optionZQ custom_checkbox">
          <p class="fs_14 fsn_4 txt_lb mb-0">Phone Number<span class="txt_d fs_14"> *</span><span
              class="fs_14 txt_b fsm_5 filter_title">
              <img [src]="defualtPickupCountryFlag" name="cla" width="auto" height="15px" style="margin-left: 5px;">
            </span></p>
          <div class="num_country_code ic_dropdown">
            <mat-form-field appearance="none" class="frm_cunty_code">
              <div class="country_code_fld">
                <mat-select formControlName="countryCode" (selectionChange)="selectPickCountryCode($event.value)"
                  class="form-control custom_input">
                  <mat-option *ngFor="let cou of countryList" [value]="cou.countryAbbr" class="checkbox_flag">
                    <img [src]="cou.flag" name="cla" width="20px" height="15px">
                    <span style="margin-left: 5px;">+{{cou.countryCode}}</span>
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="enterPriceForm.get('countryCode').errors?.['required']"
                  class="error_mess"> Country code is required.</mat-error>
              </div>
            </mat-form-field>
            <mat-form-field appearance="none">
              <input matInput formControlName="phone" placeholder="Enter Phone Number"
                class="form-control custom_input" minlength="10" maxlength="10" (keypress)="keyPress($event)"
                (input)="keyPasteCarrier($event)">
              <mat-error *ngIf="enterPriceForm.get('phone').errors?.['required']"
                class="error_mess">Phone number is required.</mat-error>
              <mat-error *ngIf="enterPriceForm.get('phone').errors?.['minlength']" class="error_mess">Min
                length should be 10 digit.</mat-error>
              <mat-error *ngIf="enterPriceForm.get('phone').errors?.['maxlength']" class="error_mess">Max length 10
                digit.</mat-error>
              <mat-error
                *ngIf="(enterPriceForm.get('phone').errors?.['pattern']) && !(enterPriceForm.get('phone').errors?.['minlength'])"
                class="error_mess">Phone number should be number.</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div>
        <p class="m-0"><span class="name">Equipment Type</span><span class="txt_d ms-1">*</span></p>
        <mat-form-field appearance="none" class="removeappearance" class="drop_bg ic_dropdown ic_dropdownQ">
          <mat-select placeholder="Select Equipment Type" class="form-control custom_input"
            formControlName="equipmentType" (selectionChange)="selectEquipmentLength($event)">
            <mat-option *ngFor="let equi of equipmentListing" [value]="equi.slug">{{equi.label}}
            </mat-option>
          </mat-select>
          <mat-error
            *ngIf="enterPriceForm.get('equipmentType').errors?.['required']"
            class="error_mess">Equipment type is required.</mat-error>
        </mat-form-field>
      </div>
      <div>
        <p class="m-0"><span class="name">FTL Shipment Volume</span><span class="txt_d fs_14 ms-1">*</span></p>
        <mat-form-field appearance="none" class="ic_dropdown ic_dropdownQ">
          <mat-select formControlName="ftlVolume" (selectionChange)="selectFtlVolume($event.value)" placeholder="Monthly FTL Volume" class="form-control custom_input">
            <mat-option *ngFor="let ftl of ftlVolumes" [value]="ftl?.id">
              <span style="margin-left: 5px;">{{ftl.name}}</span>
            </mat-option>
          </mat-select>
          <mat-error *ngIf="enterPriceForm.get('ftlVolume').errors?.['required']"
          class="error_mess">FTL Shipment Volume is required.</mat-error>
        </mat-form-field>
      </div>
      <div class="t_align_center">
        <button type="submit" class="submit_btn">Submit </button>
      </div>
    </form>
  </div>
</div>

<div *ngIf="data?.openPop ==='enterprisePlanRequestedPopup'">
  <div class="header_popup">
     <p class="fs_20 fsb_6 txt_b line_before f_clash enter_poup">Get Enterprise Plan</p>
     <span class="close_popup_Icon" (click)="onNoConfirmClick()">
        <span class="icon-ic-cross close_Icon"><i class="fa-solid fa-xmark"></i></span>
     </span>
  </div>
  <div class="text-center">
     <p class="fs_16 fsn_4 txt_lb mb_24">We already have received your request for Enterprise plan, Our LaneAxis Representative will contact you shortly.</p>
     <div class="btn_action">
        <button type="button" class="btn custum_button button_primary btn_primary mb_16"
           (click)="onNoConfirmClick()">OK</button>
     </div>
  </div>
</div>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px',fullScreenBackdrop:true }"></ngx-loading>
