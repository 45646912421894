<div class="" *ngIf="waypoint.length > 0">
    <p class="panel_card_tlt mt-0">Pickup Informations <span>(Total {{pickupWayPoint?.length}} Pickup)</span></p>
    <div class="updated_card">
      <div class="row pick_cards">
        <div class="col-md-4" *ngFor="let pickupData of pickupWayPoint; let pickupIndex = index">
          <div class="detail_card_con ms-0">
            <span class="card_counts">{{pickupIndex + 1}}</span>
            <div class="con_wrapper">
              <div class="con_wrapper_row">
                <div class="row_label">Business Name</div>
                <div class="row_value text_truncate pointer" matTooltip="{{pickupData?.businessName}}">{{pickupData?.businessName ? pickupData?.businessName : '-'}}</div>
              </div>
              <div class="con_wrapper_row">
                <div class="row_label">
                  <span class="tlt_line">Pickup Location Point {{pickupIndex + 1}}</span>
                </div>
                <div class="row_value two_line_ckt pointer" *ngIf="userType==='SHIPPER'">{{pickupData?.locationLongName}}</div>
                <div class="row_value two_line_ckt pointer" *ngIf="userType==='CARRIER' && shipmentDetail?.parentStatus !== 1 && shipmentDetail?.parentStatus !== 3 && shipmentDetail?.parentStatus !== 4 && shipmentDetail?.parentStatus !== 5">{{pickupData?.locationLongName}}</div>
                <div class="row_value two_line_ckt pointer" *ngIf="userType==='CARRIER' && (shipmentDetail?.parentStatus === 1 || shipmentDetail?.parentStatus === 3 || shipmentDetail?.parentStatus === 4 || shipmentDetail?.parentStatus === 5)">{{pickupData?.locationLongName ? pickupData?.locationLongName : '-'}}
                </div>
              </div>
              <div class="con_wrapper_row">
                <div class="row_label">ZIP Code</div>
                <div class="row_value">{{pickupData?.zip}}</div>
              </div>
              <div class="con_wrapper_row">
                <div class="row_label">Date & Time</div>
                <div class="row_value" *ngIf="pickupData?.date" style="word-break: break-word;">
                  <span>{{pickupData?.date | date: 'MMM dd, yyyy'}} {{pickupData?.timeSlot}} {{pickupData?.timeZone}}</span>
                </div>
              </div>
              <div class="con_wrapper_row crd_btm_actns" (click)="showDetailCommodity(pickupData)">
                <span class="view_card_url">View More Information</span>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <p class="panel_card_tlt mt-3">Dropoff Informations (Total {{dropoffWayPoint?.length}} Dropoff )</p>
      <div class="row drop_cars">
        <div class="col-md-4" *ngFor="let dropoffData of dropoffWayPoint;let dropoffIndex = index">
          <div class="detail_card_con ms-0">
            <span class="card_counts">{{dropoffIndex + 1}}</span>
            <div class="con_wrapper">
              <div class="con_wrapper_row">
                <div class="row_label">Business Name</div>
                <div class="row_value text_truncate pointer" matTooltip="{{dropoffData?.businessName}}">{{dropoffData?.businessName ? dropoffData?.businessName : '-'}}</div>
              </div>
              <div class="con_wrapper_row">
                <div class="row_label">
                  <span class="tlt_line">Dropoff Location Point {{dropoffIndex + 1}}</span>
                </div>
                <div class="row_value two_line_ckt pointer" *ngIf="userType==='SHIPPER'" matTooltip="">{{dropoffData?.locationLongName}}</div>
                <div class="row_value two_line_ckt pointer" *ngIf="userType==='CARRIER' && shipmentDetail?.parentStatus !== 1 && shipmentDetail?.parentStatus !== 3 && shipmentDetail?.parentStatus !== 4 && shipmentDetail?.parentStatus !== 5">{{dropoffData?.locationLongName}}</div>
                <div class="row_value two_line_ckt pointer" *ngIf="userType==='CARRIER' && (shipmentDetail?.parentStatus === 1 || shipmentDetail?.parentStatus === 3 || shipmentDetail?.parentStatus === 4 || shipmentDetail?.parentStatus === 5)">{{dropoffData?.locationLongName ? dropoffData?.locationLongName : '-'}}
                </div>
              </div>
              <div class="con_wrapper_row">
                <div class="row_label">ZIP Code</div>
                <div class="row_value">{{dropoffData?.zip}}</div>
              </div>
              <div class="con_wrapper_row">
                <div class="row_label">Date & Time</div>
                <div class="row_value" *ngIf="dropoffData?.date" style="word-break: break-word;">
                  <span>{{dropoffData?.date | date: 'MMM dd, yyyy'}} {{dropoffData?.timeSlot}} {{dropoffData?.timeZone}}</span>
                </div>
              </div>
              <div  class="con_wrapper_row crd_btm_actns" (click)="showDetailCommodity(dropoffData)">
                <span class="view_card_url">View More Information</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div class="" *ngIf="waypoint?.length===0">
    <div class="title_action">
      <p class="line_before mb-0">Load Documents</p>
    </div>
    <div class="empty_docs">
      <div class="Empty_State_body b_shadow_0" style="padding: 3rem 0;">
        <div class="Empty_State_con">
          <div class="Empty_State_img">
            <img class="img-fluid"
              src="{{serverPathForUrl}}/portal/images/after-login/Empty_State_icons/gr-no-documents.svg">
          </div>
          <div>
            <p class="fs_24 fsb_6 txt_b pb-1" style="margin: 30px 0 12px;">No waypoint<span
                class="txt_p">yet...</span> </p>
            <p class="fs_16 fsn_4 txt_lb pb-1" style="margin: 0px 0 20px ">This is no waypoint found</p>
          </div>
          <div class="text-center"></div>
        </div>
      </div>
    </div>
  </div>