import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertService } from '../../commons/service/alert.service';
import { CommonService } from '../../commons/service/common.service';
import { AppSettings } from '../../commons/setting/app_setting';
import { environment } from 'src/environments/environment';

export interface DialogData {
  openPop:any;
  title:any;
  id:any;
}

@Component({
  selector: 'app-document-popup',
  templateUrl: './document-popup.component.html',
  styleUrls: ['./document-popup.component.css'],
})

export class DocumentPopupComponent implements OnInit {
  public addDocUpload: FormGroup;
  public editDocUpload: FormGroup;
  public documentSubmmited = false;
  public loading = false;
  public name:any;
  public disbu: any;
  public imgURL:any;
  public serverPathForUrl: any;

  constructor(
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    private commonService: CommonService,
    public router: Router,
    public dialogRef: MatDialogRef<DocumentPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit() {
    this.serverPathForUrl = environment.serverPathForUrl;
    this.addDocUpload = this.formBuilder.group({
      document: ['', [Validators.required]],
      description: ['', [Validators.required]],
      shipmentId: [this.data.id, [Validators.required]],
    });
    if (this.data.title) {
      this.editDocUpload = this.formBuilder.group({
        description: [this.data.title.description, [Validators.required]],
      });
    }
  }
  
  onNoConfirmClick() {
    this.dialogRef.close({ event: 'fail' });
  }

  donePopup() {
    this.dialogRef.close({ event: 'OK' });
  }

  closePopup() {
    this.dialogRef.close({ event: 'close' });
  }

  onSelectedFile(event) {
    if (event.target.files.length === 0) return;
    var reader = new FileReader();
    this.name = event.target.files[0].name;
    this.disbu = false;
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.imgURL = reader.result;
    };
    if (event.target.files.length) {
      const file = event.target.files[0];
      this.addDocUpload.get('document').setValue(file);
    }
  }
  
  uploadEditDocumentMulti({ value, valid }) {
    if (valid) {
      this.loading = true;
      const formData = new FormData();
      formData.append(
        'description',
        this.editDocUpload.get('description').value
      );
      formData.append('docId', this.data.title.id);
      let APIparams = {
        apiKey: AppSettings.APIsNameArray.SHIPMENT.SHIPPERDOCEDIT,
        uri: '',
        postBody: formData,
      };
      this.commonService.put(APIparams).subscribe(
        (success) => {
          if (success.success === true) {
            this.alertService.showNotificationMessage(
              'success',
              'bottom',
              'right',
              'txt_s',
              'check_circle',
              'Shipper Document Title',
              'You have successfully edited title.'
            );
            this.loading = false;
            this.dialogRef.close({ event: 'Ok' });
          } else if (success.success === false) {
            this.loading = false;
            this.alertService.showNotificationMessage(
              'danger',
              'bottom',
              'right',
              'txt_d',
              'check_circle',
              'Shipper Document Title',
              'You have not successfully edited title.'
            );
          }
        },
        (error) => {
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_g',
            'error',
            'Unexpected Error',
            AppSettings.ERROR
          );
        }
      );
    }
  }

  uploadDocumentMulti({ value, valid }) {
    this.documentSubmmited=true;
    if (valid) {
      this.loading = true;
      const formData = new FormData();
      formData.append('document', this.addDocUpload.get('document').value);
      formData.append(
        'description',
        this.addDocUpload.get('description').value
      );
      formData.append('shipmentId', this.data.id);
      let APIparams = {
        apiKey: AppSettings.APIsNameArray.SHIPMENT.SHIPPERDOC,
        uri: '',
        postBody: formData,
      };
      this.commonService.postMediaMethod(APIparams).subscribe(
        (success) => {
          this.documentSubmmited=false;
          if (success.success === true) {
            this.alertService.showNotificationMessage(
              'success',
              'bottom',
              'right',
              'txt_s',
              'check_circle',
              'Shipper Document',
              'You have successfully uploaded a document.'
            );
            this.loading = false;
            this.dialogRef.close({ event: 'Ok' });
          } else if (success.success === false) {
            this.loading = false;
            this.alertService.showNotificationMessage(
              'danger',
              'bottom',
              'right',
              'txt_d',
              'check_circle',
              'Shipper Document',
              'You have not successfully uploaded a document.'
            );
          }
        },
        (error) => {
          this.documentSubmmited=false;
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_g',
            'error',
            'Unexpected Error',
            AppSettings.ERROR
          );
        }
      );
    }
  }
}
