<div class="side_contaier">
	<div class="side_contaiert">
		<div class="header_bar">
			<div class="side_headerl">
				<a *ngIf="userType==='CARRIER'" [routerLink]="['/driver/view', driverSidePanelData?.id ,'details']"
					target="_blank"><u class="fs_14 fsm_5 txt_p ">Open in New tab</u></a>
				<a *ngIf="userType==='SHIPPER'" [routerLink]="['/driver/view', driverSidePanelData?.id ,'details']"
					target="_blank"><u class="fs_14 fsm_5 txt_p ">Open in New tab</u></a>
			</div>
			<div class="side_headerr" (click)="closePanel(profileSidePanelData,'close')">
				<span class="icon-ic-cross fs_24 txt_lb pointer"><i class="fa-solid fa-xmark"></i></span>
			</div>
		</div>

		<div class="map_card_back">
			<span class="material-icons back_icon txt_w"
				(click)="closePanel(profileSidePanelData,'changeView')">arrow_back</span>
		</div>
		<div class="map_card_back">
			<span class="material-icons back_icon txt_w"
				(click)="closePanel(profileSidePanelData,'close')">arrow_back</span>
		</div>
		<div class="side_content v_scrollBar">
			<div class="header_con">
				<div class="header_img">
					<img *ngIf="driverSidePanelData?.coverImage" [src]="driverSidePanelData?.coverImage"
						class="img-fluid">
				</div>
				<div class="header_info">
					<div class="head_img">
						<div class="img_Con miW_84">
							<span *ngIf="driverSidePanelData?.profileImage" class="user_image">
								<img [src]="driverSidePanelData?.profileImage" class="img-fluid">
							</span>
							<span *ngIf="driverSidePanelData?.companyName && !driverSidePanelData?.profileImage"
								class="missed_img ">{{driverSidePanelData?.companyName | acronymOfName}}</span>
							<span *ngIf="driverSidePanelData?.name && !driverSidePanelData?.profileImage"
								class="missed_img ">{{driverSidePanelData?.name | acronymOfName}}</span>
						</div>
						<p class="head_name  card_cName" *ngIf="driverSidePanelData?.name">
							<span class="text_truncate  pointer" matTooltip="{{driverSidePanelData?.name}}">{{driverSidePanelData?.name}}</span>
							<span *ngIf="userType=='SHIPPER' && driverSidePanelData?.subscription!==null">

								<img *ngIf="driverSidePanelData?.subscription"
									src="{{serverPathForUrl}}/portal/images/after-login/premium_plan_icon.png"
									class="img-fluid verified_icon">
							</span>
						</p>

						<p class="head_name  card_cName" *ngIf="driverSidePanelData?.companyName">
							<span class="text_truncate ">{{driverSidePanelData?.companyName}}</span>
						</p>
					</div>
					<div class="side_panel_rating">
						<app-rating-view [rate]="driverSidePanelData?.ratingReview?.rate"
							[ratingReview]="driverSidePanelData?.ratingReview" [ratingOwnerDetail]="ratingOwnerDetail"
							[shipmentDetail]="driverSidePanelData"
							*ngIf="driverSidePanelData?.ratingReview?.rate"></app-rating-view>
					</div>
					<div class="revanu_loca">
						<p class="location">
							<span class="loca_lable">Location: </span>
							<span class="loca_value"
								*ngIf="!currentlocation?.fullAddress"> -
							</span>
							<span class="loca_value" *ngIf="currentlocation"> {{
								currentlocation?.fullAddress}}</span>
						</p>
					</div>
					<span class="sidebar_btn action_pending" *ngIf="driverSidePanelData?.networkLabel==='PENDING'">
						<span class="icon-ic-pending txt_w me-1"></span>
						<span class="">Pending</span>
					</span>

					<!-- login user-->
					<span class="sidebar_btn action_accept pointer" *ngIf="driverSidePanelData?.networkLabel==='Accept'"
						(click)="userLoginPopup(findUserType)">
						<span class="icon-ic-tick fs_16 me-1"></span>
						<span class="">Accept</span>
					</span>

					<!-- login user-->
					<span class="sidebar_btn action_accept" *ngIf="driverSidePanelData?.networkLabel==='INVITATION'">
						<span class="icon-ic-envelope fs_16 me-1"></span>
						<span class="">New Invitation</span>
					</span>

					<span class="sidebar_btn bg_s txt_w" *ngIf="driverSidePanelData?.networkLabel==='CONNECTED'">
						<span class="">Connected</span>
					</span>
					<p class="accept_mess" *ngIf="driverSidePanelData?.note">
						<span class="fsm_5 me-1"
							*ngIf="driverSidePanelData.legalName">{{driverSidePanelData.legalName}}</span>
						<span class="fsm_5 me-1"
							*ngIf="driverSidePanelData.companyName">{{driverSidePanelData.companyName}}</span>
						<i class="fsn_4">{{driverSidePanelData?.note}}</i>
					</p>
				</div>
			</div>

			<div class="contact_info_container py-0">
				<div class="info_conT mt_30">
					<p class="mb-0 fs_20 fsb_6 txt_b line_before">

						<span style="margin: 0 0 0 10px;" class="">Professional Information </span>
					</p>
				</div>
				<div class="buss_infoSide">
					<div class="row">
						<div class="col-md-5">
							<p class="buss_lable">Phone Number</p>
						</div>
						<div class="col-md-7">
							<span *ngIf="driverSidePanelData?.countryCode && driverSidePanelData?.phone" class="ctry_flag">
								<img [src]="countryFlag" name="cla">
							  </span>
							<span class="buss_value">
								{{(driverSidePanelData?.phone ? driverSidePanelData?.phone : '-')}}

							</span>
						</div>

						<div class="col-md-5">
							<p class="buss_lable">Date of Birth</p>
						</div>
						<div class="col-md-7">
							<p class="buss_value">
								<span [hidden]="!driverSidePanelData?.dob">{{ driverSidePanelData?.dob | date: 'MMM dd, yyyy'}}</span>
								<span [hidden]="driverSidePanelData?.dob">-</span>
							</p>
						</div>

						<div class="col-md-5">
							<p class="buss_lable">Home Address</p>
						</div>
						<div class="col-md-7">
							<p class="buss_value">
								<span>{{driverSidePanelData?.address ? driverSidePanelData?.address : '-' }}</span>
								<span> {{driverSidePanelData?.city ? driverSidePanelData?.city +"," : ''}}</span>
								<span> {{driverSidePanelData?.state ? driverSidePanelData?.state +"," : ''}}</span>
								<span> {{driverSidePanelData?.country ? driverSidePanelData?.country +"," : ''}}</span>
								<span> {{driverSidePanelData?.pinCode ? driverSidePanelData?.pinCode : ''}}</span>
							</p>
						</div>
					</div>
				</div>
			</div>

			<div class="contact_info_container py-0">
				<div class="info_conT mt_30">
					<p class="mb-0 fs_20 fsb_6 txt_b line_before">
						<span style="margin: 0 0 0 10px;" class="">Professional Information </span>
					</p>
				</div>
				<div class="buss_infoSide">
					<div class="row">
						<div class="col-md-5">
							<p class="buss_lable">Equipment Type</p>
						</div>
						<div class="col-md-7">
							<p class="buss_value">
								<span>
									{{checkEquipmentType(driverSidePanelData?.equipmentName)}}</span>
							</p>
						</div>

						<div class="col-md-5">
							<p class="buss_lable">DOT Number</p>
						</div>
						<div class="col-md-7">
							<p class="buss_value">{{driverSidePanelData?.dotNumber ? driverSidePanelData?.dotNumber :
								'-' }}</p>
						</div>

						<div class="col-md-5">
							<p class="buss_lable">CDL Number
							</p>
						</div>
						<div class="col-md-7">
							<p class="buss_value">{{driverSidePanelData?.cdlNumber && driverSidePanelData?.cdlNumber!==
								null && driverSidePanelData?.cdlNumber!== 'null' && driverSidePanelData?.cdlNumber!==
								'undefined' ? driverSidePanelData?.cdlNumber : '-'}}</p>
						</div>

						<div class="col-md-5">
							<p class="buss_lable">Total miles Driven</p>
						</div>
						<div class="col-md-7">
							<p class="buss_value">{{driverSidePanelData?.milesDriven ? driverSidePanelData?.milesDriven
								+ " Miles" : "0"}}</p>
						</div>

						<div class="col-md-5">
							<p class="buss_lable">Total Trips</p>
						</div>
						<div class="col-md-7">
							<p class="buss_value">{{driverSidePanelData?.totalShipments ?
								driverSidePanelData?.totalShipments : '-' }}</p>
						</div>

						<div class="col-md-5">
							<p class="buss_lable">Experience</p>
						</div>
						<div class="col-md-7">
							<p class="buss_value">
								<span [hidden]="!driverSidePanelData?.workingSince">{{ driverSidePanelData?.workingSince | date: 'MMM dd, yyyy'}}</span>
								<span [hidden]="driverSidePanelData?.workingSince">-</span>
							</p>
						</div>

						<div class="col-md-5">
							<p class="buss_lable">Allow Driver to Negotiate & Accept Loads</p>
						</div>
						<div class="col-md-7">
							<p class="buss_value"> <span
									[hidden]="!driverSidePanelData?.isLoadsInterested===true">Yes</span>
								<span
									[hidden]="!driverSidePanelData?.isLoadsInterested===false || !driverSidePanelData?.isLoadsInterested === false">No</span>
							</p>
						</div>

						<div class="col-md-5">
							<p class="buss_lable">Number of Accidents</p>
						</div>
						<div class="col-md-7">
							<p class="buss_value">{{driverSidePanelData?.noOfAccident ?
								driverSidePanelData?.noOfAccident : '-' }}</p>
						</div>

						<div class="col-md-5">
							<p class="buss_lable">Last Shipment Date</p>
						</div>
						<div class="col-md-7">
							<p class="buss_value"> <span [hidden]="!driverSidePanelData?.lastLoad?.date">{{
									driverSidePanelData?.lastLoad?.date | date: 'MMM dd, yyyy ' }}
								</span>
								<span [hidden]="driverSidePanelData?.lastLoad?.date">-</span>
							</p>
						</div>

						
						<div class="col-md-5" *ngIf="userType=='SHIPPER'">
							<p class="buss_lable">About Me</p>
						</div>
						<div class="col-md-7" *ngIf="userType=='SHIPPER'">
							<p class="buss_value">{{driverSidePanelData?.aboutMe ? driverSidePanelData?.aboutMe : "-"}}
							</p>
						</div>

					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="side_contaierb">
		<a *ngIf="!getUseProfileNewTab && findUserType==='CARRIER'" [routerLink]="['/network/find-a-carrier']"
			[queryParams]="{id: driverSidePanelData.id}" target="_blank"
			class="fs_14 fsn_4 txt_p pro_view_btn d-block">View
			Full Profile in new tab</a>
		<a *ngIf="!getUseProfileNewTab && findUserType==='SHIPPER'" [routerLink]="['/network/find-a-shipper']"
			[queryParams]="{id: driverSidePanelData.id}" target="_blank"
			class="fs_14 fsn_4 txt_p pro_view_btn d-block">View
			Full Profile in new tab</a>
		<button *ngIf="userType==='CARRIER'" class="pro_view_btn btn custum_button button_primary w-100"
			[routerLink]="['/driver/view', driverSidePanelData?.id ,'details']">View Full Profile</button>
		<button *ngIf="userType==='SHIPPER'" class="pro_view_btn btn custum_button button_primary w-100"
			[routerLink]="['/driver/view', driverSidePanelData?.id ,'details']">View Full Profile</button>
	</div>
</div>