<div class="wapper_body_rb_con">
    <div class="create_lanes_p" id="booking_tab">
      <div class="recurring_lanes_filter">
        <div class="filter_input_bx" *ngIf="skeletonLoader">
          <div class="row">
            <div class="col-md-2 ic_dropdown">
              <p class="fltr_lans_lbl fs_12 fsn_4 txt_lb">Equipment Type</p>
              <mat-form-field appearance="none" class="removeappearance" class="drop_bg">
                <mat-select placeholder="Select Equipment Type" class="form-control custom_input" [(ngModel)]="searchModel.equipmentId" #equipmentId="ngModel" [ngModelOptions]="{standalone: true}">
                  <mat-option *ngFor="let equip of equipmentListing" [value]="equip?.id" (click)="equipmentSelected(equip)">{{equip?.label}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
  
            <div class="col-md-2 ic_dropdown">
              <p class="fltr_lans_lbl fs_12 fsn_4 txt_lb">State</p>
              <mat-form-field appearance="none" class="">
                <mat-select class="form-control custom_input" placeholder="Select State" [(ngModel)]="searchModel.stateId" #stateId="ngModel" [ngModelOptions]="{standalone: true}">
                  <mat-option class="mat_dropdown" *ngFor="let state of phStates" [value]="state?.sortname" (click)="stateSelected(state)" class="mat_dropdown"> {{state?.name}}</mat-option>
                </mat-select>
                <mat-error class="error_mess"></mat-error>
              </mat-form-field>
            </div>
  
            <div class="col-md-2">
              <p class="fltr_lans_lbl fs_12 fsn_4 txt_lb">Search</p>
              <mat-form-field appearance="none">
                <input matInput placeholder="Search by Lane Name and Lane ID" maxlength="64" [(ngModel)]="searchModel.title" #title="ngModel" [ngModelOptions]="{standalone: true}" (keyup)="searchQuantity($event)" autocomplete="off" class="form-control custom_input">
              </mat-form-field>
            </div>
  
            <div class="col-md-4">
              <div class="filter_btn">
                <span class="ap_btn unactive_ap_btn" *ngIf="filterCheck">Apply Filter</span>
                <span class="ap_btn active_ap_btn" *ngIf="!filterCheck" (click)="applyFilterSubmit()">Apply Filter</span>
                <span *ngIf="filterCheck" class="ap_btn unactive_clr_btn ms-1">Clear All</span>
                <span *ngIf="!filterCheck" (click)="clearFilter()" class="ap_btn active_clr_btn ms-1">Clear All</span>
              </div>
            </div>
          </div>
        </div>
        <div class="filter_input_bx" *ngIf="!skeletonLoader">
          <ngx-skeleton-loader [theme]="{ width: '100%', height: '12px' }" class=""></ngx-skeleton-loader>
          <ngx-skeleton-loader [theme]="{ width: '100%', height: '30px' }" class=""></ngx-skeleton-loader>
        </div>
        <div class="filtered_bx">
          <app-load-view-filter [titleFilter]="titleFilter" [equipmentNameShow]="equipmentNameShow" [stateShow]="stateShow" [isLane]="isLane" (clearOneFilter)="singleFilterClose($event)" class="lane_filtter"></app-load-view-filter>
        </div>
      </div>
  
      <div style="position: relative;">
        <div class="booking_lane_container network_container cus_matTab">
          <div animationDuration="0ms" class="parent_tabs">
            <div label="Lanes" id="lanes">
              <div class="" *ngIf="userType === 'SHIPPER'">
                <div *ngIf="!skeletonLoader" class="mb-1">
                  <ngx-skeleton-loader [theme]="{ width: '110px', height: '13px' }" class="loader_m_0 skt_btn_loader mb-2" *ngFor="let fake of generateFake(4)"></ngx-skeleton-loader>
                </div>
  
                <div class="height_78vh" *ngIf="skeletonLoader && lanesList?.length > 0">

                  <div class="row jj mt-4" infiniteScroll [infiniteScrollDistance]="3" [infiniteScrollThrottle]="100" 
                (scrolled)="onScroll()" [scrollWindow]="false">
                    <div class="col-md-3" *ngFor="let laneRecord of lanesList">
                      <app-lane-card [laneRecord]="laneRecord" [currentRouteParams]="currentRouteParams" [currentStatus]="currentStatus" [subscriptionData]="subscriptionData" [isDisabled]="isDisabled" (eventPassAction)="eventAction($event)"></app-lane-card>
                    </div>
                  </div>
                </div>
                <div class="row mx-auto" *ngIf="!skeletonLoader && lanesList?.length === 0">
                  <div class="col-lg-3 col-md-6" *ngFor="let fake of generateFake(4)">
                    <app-load-skeleton-card></app-load-skeleton-card>
                  </div>
                </div>
                <div class="row mx-auto" *ngIf="skeletonLoader && lanesList?.length === 0">
                  <div class="col-md-12">
                    <app-load-empty-screen [currentStatus]="currentStatus" [currentSearchStatus]="currentSearchStatus"></app-load-empty-screen>
                  </div>
                </div>
              </div>
  
              <div class="" *ngIf="userType === 'CARRIER'">
                <div *ngIf="!skeletonLoader" class="mb-4">
                    <ngx-skeleton-loader [theme]="{ width: '87px', height: '14px' }"
                        class="loader_m_0 skt_btn_loader mb-2"></ngx-skeleton-loader>
                    <ngx-skeleton-loader [theme]="{ width: '87px', height: '14px' }"
                        class="loader_m_0 skt_btn_loader mb-2"></ngx-skeleton-loader>
                </div>
                <div class="lane_sub_tabs" id="lane_sub_tabs" *ngIf="skeletonLoader">
                    <span class="tabLabel " [ngClass]="subTabMenu === 'availableLanes' ? 'active' : ''"
                        (click)="subTabMenuFilter('availableLanes')">Available Lanes ({{availableLanes}})
                    </span>
                    <span [ngClass]="subTabMenu === 'confirmedLanes' ? 'active' : ''" class="tabLabel "
                        (click)="subTabMenuFilter('confirmedLanes')">Confirmed Lanes ({{confirmCount}})
                    </span>
                </div>
  
                <div class="height_78vh" *ngIf="skeletonLoader && lanesList?.length > 0">
                    <div class="row kk" style="padding: 0 10px;">
                        <div class="col-md-3" *ngFor="let laneRecord of lanesList" infiniteScroll [infiniteScrollDistance]="3" [infiniteScrollThrottle]="100" 
                        (scrolled)="onScroll()" [scrollWindow]="false">
                            <app-lane-card [laneRecord]="laneRecord" [currentRouteParams]="currentRouteParams" [currentStatus]="currentStatus" [subscriptionData]="subscriptionData"
                                (eventPassAction)="eventAction($event)"></app-lane-card>
                        </div>
                    </div>
                </div>
                <div class="row mx-auto" *ngIf="skeletonLoader && lanesList?.length === 0">
                    <div class="col-md-12">
                      <app-load-empty-screen [currentStatus]="currentStatus" [currentSearchStatus]="currentSearchStatus"></app-load-empty-screen>
                    </div>
                </div>
                <div class="row mx-auto" *ngIf="!skeletonLoader && lanesList?.length === 0">
                    <div class="col-lg-3 col-md-6" *ngFor="let fake of generateFake(4)">
                        <app-load-skeleton-card></app-load-skeleton-card>
                    </div>
                </div>
              </div>
            </div>
          </div>
  
          <div class="content_loader mt-3 mb-4" *ngIf="lanesList?.length > 0 && spinnerLoader && page > 1">
            <span class="loader_image">
              <img src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/loader-blue.svg" class="img-fluid">
            </span>
          </div>
          <div *ngIf="lanesList?.length > 0 && dataNotFound" class="noMore_record">
            <span>There are no more records to show.</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="" style="position: relative;" *ngIf="sidePanelProfileDataId">
      <div class="side-show-panel">
        <div class="side_bar_con">
          <app-shipper-side-panel [sidePanelProfileDataId]="sidePanelProfileDataId" [userType]="userType" [subscriptionData]="subscriptionData"
          (closeSidePanel)="closePanel($event)"></app-shipper-side-panel>
        </div>
      </div>
    </div>