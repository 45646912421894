<div class="ship_card_skelaton">
  <div class="ship_card_skelatont">
    <!-- <div class="mb_8"> 
      <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '12px', margin: '0px'}"></ngx-skeleton-loader>
      <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '16px', margin: '0px'}"></ngx-skeleton-loader>
    </div> -->

    <!-- <div class="mb_10">
      <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '12px', margin: '0px'}"></ngx-skeleton-loader>
      <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '16px', margin: '0px'}"></ngx-skeleton-loader>
    </div> -->

    <div class="mb_12">
      <!-- <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '12px', margin: '0px'}"></ngx-skeleton-loader> -->
      <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '16px', margin: '0px'}"></ngx-skeleton-loader>
    </div>

    <div class="mb_12">
      <!-- <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '12px', margin: '0px'}"></ngx-skeleton-loader> -->
      <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '16px', margin: '0px'}"></ngx-skeleton-loader>
    </div>

    <div class="ske_pay">
      <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '20px', margin: '0px'}"></ngx-skeleton-loader>
    </div>
  </div>
  <div class="ship_card_skelatonb">
    <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '38px', margin: '0px'}"></ngx-skeleton-loader>
  </div>
</div>