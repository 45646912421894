import { Component, OnInit } from '@angular/core';
// import { MapsAPILoader } from '@agm/core';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { CommonService } from '../../commons/service/common.service';
import { HereMapsService } from '../../commons/service/here-maps.service';
import { SharedService } from '../../commons/service/shared.service';
import { AppSettings } from '../../commons/setting/app_setting';
import { STATUS, STATUS_COLOR_CODES } from '../../commons/setting/Heremaps.constants';
import { HereMapsService8 } from 'src/app/commons/service/here-maps8.service';
declare var require: any;

export interface DialogData {
  name: string;
  condition: string;
  imgUrl: any;
}

@Component({
  selector: 'app-track-your-load',
  templateUrl: './track-your-load.component.html',
  styleUrls: ['./track-your-load.component.scss']
})
export class TrackYourLoadComponent implements OnInit {
  public currentTab = 'track';
  skeletonLoader = true;
  skeletonLoaderhistory = true;
  documentSklitor = true;
  shipmentDetail: any;
  driverDetails: any;
  public submitted = false;
  public createdDateTime: any;
  public manTab = 'track';
  public currentTabShow = 'shipment';
  public currentlocation: any;
  public geoCoder: any;
  public locationLat: any;
  public locationLong: any;
  public statusInformation: any;
  public isTemprature = false;
  public loading = false;
  mapStatus: boolean = false;
  public currentLat: any;
  public currentLon: any;
  public gifLoader: boolean = false;
  public trackForm: FormGroup;
  public getShipmentId: any;
  public SplitedID: any;
  public shipmentMessage: any;
  public levelLine = 0;
  public isLogin: any;
  public loginStatus = false;
  public serverPathForUrl: any;
  public checkNavbar: any;
  public waypointSelekton = false;
  public waypointData: any;

  constructor(
    private formBuilder: FormBuilder,
    private commonService: CommonService,
    private hereMapsService: HereMapsService,
    private hereMapsService8: HereMapsService8,
    // private mapsAPILoader: MapsAPILoader,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private location: Location,
    private sharedService: SharedService,
  ) { }

  ngOnInit() {
    this.isLogin = localStorage.getItem('login');
    this.serverPathForUrl = environment.serverPathForUrl;
    this.checkNavbar = localStorage.getItem('login');
    if (localStorage.getItem('access_token') !== null) {
      this.loginStatus = true;
    } else {
      this.loginStatus = false;
    }
    // this.mapsAPILoader.load().then(() => {
    //   this.geoCoder = new google.maps.Geocoder();
    // });

    let newUrl = this.router.url.replace(/%20/g, '+');
    let newUrdddl = this.getParams(newUrl);
    if (newUrdddl) {
      this.getShipmentId = newUrdddl['load_id'];
    }
    this.trackForm = this.formBuilder.group({
      shipmentId: [this.getShipmentId, [Validators.required]],
     
    });

    if (this.isLogin === 'after_login') this.trackSubmit(this.trackForm);

    if (this.loginStatus) {
      this.route.queryParams.subscribe((params) => {
        if (Object.keys(params).length > 0) {
          let newShipmentId = params['load_id'].split(/([0-9]+)/);
          let shipId = parseInt(newShipmentId[1]);
          this.router.navigateByUrl('/spot-rate-loads/active-loads/inProgressByLoads/load/view/' + shipId + '/details');
        } else {
          this.router.navigateByUrl('network/home');
        }
      });
    }
  }


  getParams(url) {
    var params = {};
    var parser = document.createElement('a');
    parser.href = url;
    var query = parser.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      params[pair[0]] = decodeURIComponent(pair[1]);
    }
    return params;
  }

  loginPage() {
    if (localStorage.getItem('access_token')) {
      this.commonService.sendTokanUpdate.next(null);
    }
    this.router.navigate(['/']);
  }

  trackSubmit({ value, valid }) {
    this.submitted = true;
    if (valid) {
      this.loading = true;
      this.getShipmentId = value.shipmentId;
      this.skeletonLoader = true;
      this.router.navigate(['/load/track-your-load'], {
        queryParams: {
          load_id: value.shipmentId,
        },
      });
      this.getSingelRecord({});
    }
  }

  async currentTabActive(type: any) {
    if (type === 'shipment') {
      this.currentTabShow = type;
      this.skeletonLoader = true;
      this.getSingelRecord();
    }
    if (type === 'driver') {
      this.mapStatus = false;
      this.currentTabShow = type;
      if (this.driverDetails !== null) {
        this.currentLat = this.driverDetails.locationLat;
        this.currentLon = this.driverDetails.locationLong;
        this.documentSklitor = false;
        if (this.currentLat !== null && this.currentLon !== null) {
          this.currentlocation = await this.showPosition(
            this.currentLat,
            this.currentLon
          );
        }
      } else {
        this.documentSklitor = false;
        this.currentLon = undefined;
        this.currentLat = undefined;
        this.driverDetails = '';
      }
    }
    if (type === 'load') {
      this.currentTabShow = type;
      this.skeletonLoaderhistory = true;
    }
    if (type === 'document') {
      this.currentTabShow = type;
      this.documentSklitor = true;
    }
    if (type === 'waypoint') {
      this.currentTabShow = type;
      this.waypointSelekton = false;
      this.waypointData = await this.wayPointList();
      this.waypointSelekton = true;
    }
  }

  backHistory() {
    if (this.isLogin === 'after_login') {
      this.location.back();
    } else {
      this.loginPage();
    }
  }

  getSingelRecord(params = {}) {
    let uri = null;
    params = {
      shipmentId: this.getShipmentId,
    };
    if (params) uri = this.commonService.getAPIUriFromParams(params);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.TRACK.SHIPMENT,
      uri: uri,
    };
    this.commonService.getList(APIparams).subscribe(
      async (ServerRes) => {
        if (ServerRes.success === true) {
          this.SplitedID = ServerRes.response.id;
          this.waypointData = await this.wayPointList();
          ServerRes.response.dropStatus = this.waypointData[this.waypointData.length - 1].status;
          ServerRes.response.dropTimeZone = this.waypointData[this.waypointData.length - 1].timeZone;
          ServerRes.response.dropTimeslot = this.waypointData[this.waypointData.length - 1].timeSlot;
          ServerRes.response.dropDate = this.waypointData[this.waypointData.length - 1].date;
          this.shipmentDetail = ServerRes.response
          this.driverDetails = ServerRes.response.driver;
          this.shipmentDetail = ServerRes.response;
          if (ServerRes.response.status) {
            this.levelShow(ServerRes);
          }
          this.createdDateTime = new Date(
            ServerRes.response.createdAt + ' ' + 'UTC'
          );
          this.getMilesCompleted(ServerRes.response);
          if (ServerRes.response && this.sharedService.checkEligiblityForTripStatus(ServerRes.response.status)) {
            if (ServerRes.response.status < 42) {
              this.gifLoader = true;
              if (ServerRes.response.driver) {
                ServerRes.response.drivers = ServerRes.response.driver;
                ServerRes.response.drivers.latitude = ServerRes.response.driver.locationLat;
                ServerRes.response.drivers.longitude = ServerRes.response.driver.locationLong;
                this.hereMapsService8.calculateMultiRoute(ServerRes.response, this.waypointData).subscribe((hereMapsRoutingResponse)=>{
                  (hereMapsRoutingResponse) => {
                    if (hereMapsRoutingResponse) {
                      var tripStatus = this.hereMapsService.calculateTripStatus(hereMapsRoutingResponse, ServerRes.response);
                      this.getTripStatus(tripStatus);
                    }
                  }
                },(error) => {
                  if (error) {
                    var tripStatus = this.hereMapsService.getTripResponse(STATUS_COLOR_CODES.BLUE, STATUS.NO_ROUTE, '', '', '', 0, ServerRes.response);
                    this.getTripStatus(tripStatus);
                  }
                })
              } else {
                var tripStatus = this.hereMapsService.getTripResponse(STATUS_COLOR_CODES.BLUE, STATUS.NO_ROUTE, '', '', '', 0, ServerRes.response);
                this.getTripStatus(tripStatus);
              }
            } else {
              let tripStatus = this.hereMapsService.calculateRoutewithCallingHereMaps(ServerRes.response);
              this.getTripStatus(tripStatus);
            }
          } else {
            this.statusInformation = null;
          }
          this.manTab = 'track_shipment';
          this.skeletonLoader = false;
          this.loading = false;
        } else {
          this.shipmentDetail = '';
          this.skeletonLoader = false;
          this.shipmentMessage = ServerRes.message;
          this.loading = false;
        }
      },
      (error) => {
        if (error) {
          this.shipmentDetail = '';
          this.skeletonLoader = false;
          this.shipmentMessage = 'Shipment not found';
          this.loading = false;
        }
      }
    );
  }
  levelShow(ServerRes) {
    if (
      ServerRes.response.status > 11 &&
      ServerRes.response.status <= 20
    ) {
      this.levelLine = 1;
    } else if (
      ServerRes.response.status > 20 &&
      ServerRes.response.status <= 23
    ) {
      this.levelLine = 2;
    } else if (
      ServerRes.response.status >= 24 &&
      ServerRes.response.status <= 42
    ) {
      this.levelLine = 3;
    } else if (
      ServerRes.response.status >= 43 &&
      ServerRes.response.status <= 50
    ) {
      this.levelLine = 4;
    } else if (
      ServerRes.response.status === 51 ||
      ServerRes.response.status > 51
    ) {
      this.levelLine = 5;
    }
  }

  // Funtion to get completed miles by status 
  getMilesCompleted(shipment) {
    if (shipment.status === 51 || shipment.status === 53 || shipment.status === 54 || shipment.status === 55 || shipment.status === 58) {
      this.shipmentDetail.tripStatus = {
        milesCompleted: shipment.miles,
      };
    }
  }

  getTripStatus(tripStatus) {
    var moment = require('moment-timezone');
    if (tripStatus) {
      this.shipmentDetail.tripStatus = tripStatus;
      this.gifLoader = false;
      if (tripStatus.ETA) {
        let dropTimeZone = this.hereMapsService.getTimeZone(
          this.shipmentDetail.dropTimeZone
        );
        let currentTime = moment().tz(dropTimeZone).format('MM/DD/YYYY HH:mm');
        let currentTS = Date.parse(currentTime);
        this.statusInformation = tripStatus.ETA + currentTS;
      }
    } else {
      this.statusInformation = null;
    }
  }

  showPosition1(locationLat, locationLong) {
    let addressDriver = [];
    return new Promise((resolve, reject) => {
      let selatlong = { lat: locationLat, lng: locationLong };
      this.geoCoder.geocode(
        { location: selatlong },
        function (results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
            let newname = '';
            if (results[0].address_components[3] !== undefined) {
              newname = results[0].address_components[3].long_name;
            } else {
              newname = '';
            }
            addressDriver['shortAddress'] =
              results[0].address_components[2].long_name + ',' + ' ' + newname;
            addressDriver['fullAddress'] = results[0].formatted_address;
            resolve(addressDriver);
          } else {
            reject();
          }
        }
      );
    });
  }

  showPosition(lat, lng) {
    let addressDriver = [];
    const apiKey = 'AIzaSyAMMBwZYg03hSwJEnODnIWf14YJKjWz_2A';
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;
    let that = this;
    if(!lat || !lng)
      {
        return
      }
    return new Promise((resolve, reject) => {
    fetch(url)
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(response => {
      let results = response?.results
      let newname = '';
      if (results[0].address_components[3] !== undefined) {
        newname = results[0].address_components[3].long_name;
      } else {
        newname = '';
      }
      addressDriver['shortAddress'] =
        results[0].address_components[2].long_name + ',' + ' ' + newname;
      addressDriver['fullAddress'] = results[0].formatted_address;
      resolve(addressDriver);
    })
    .catch(error => {
      console.error('Error fetching address:', error);
      reject();
    })
    });
  }

  public generateFake(count: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes;
  }

  checkEquipmentType(equipment: any) {
    if (equipment && equipment.length > 0 && Array.isArray(equipment)) {
      return equipment.toString()
    }
    else if (equipment && equipment.length > 0) {
      return equipment
    }
    else {
      return '-'
    }
  }

  trailSpecialRequipment(dataInfo: any) {
    if (dataInfo && dataInfo.length > 0) {
      if (dataInfo.includes('Temp-controlled')) {
        this.isTemprature = true;
      }
      return (dataInfo.toString().split(',').join(', ')).trim();
    } else {
      return '-';
    }
  }

  wayPointList() {
    return new Promise((resolve, reject) => {
      let uri = null;
      let newParams = {
        shipmentId: this.SplitedID,
      };
      if (newParams) uri = this.commonService.getAPIUriFromParams(newParams);
      let APIparams = {
        apiKey: AppSettings.APIsNameArray.EXTERNAL.WAYPOINTS,
        uri: uri,
      };
      this.commonService.getList(APIparams).subscribe(
        async (ServerRes) => {
          if (ServerRes.success === true) {
            resolve(ServerRes.response.waypoints);
            this.waypointSelekton = true;
          } else {
            resolve(null);
            this.waypointSelekton = true;
          }
        },
        (error) => {
          this.waypointSelekton = true;
          resolve(null);
        }
      );
    });
  }

    // To convert date in UTC
    UTCDate(date: any) {
      date = new Date(date + ' ' + 'UTC');
      return date;
    }
  
  
}
