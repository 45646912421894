import { Component, OnInit, Inject} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '../../../environments/environment';
import { CommonService } from '../../commons/service/common.service';
import {FormGroup, Validators,FormBuilder} from '@angular/forms';
import { AppSettings } from '../../commons/setting/app_setting';
import { AlertService } from '../../commons/service/alert.service';
import { SharedService } from 'src/app/commons/service/shared.service';
import { StatusSetting } from 'src/app/commons/setting/status_setting';

export interface DialogData {
  openPop: string;
  contain: string;
  title: string;
  type: string;
  allRecord:any;
  status:any;
  documentId:any;
  id:any;
  planDetail:any;
  dotNumber:any,
  record:any
}

@Component({
  selector: 'app-network-popup',
  templateUrl: './network-popup.component.html',
  styleUrls: ['./network-popup.component.css'],
})

export class NetworkPopupComponent implements OnInit {
  public eCarrierCheckData:any;
  public SMSPercentiles:any;
  public extraViolationKey:any=[];
  public extraViolationValues:any=[];
  public usertype: any;
  public redirectUrl: any; 
  public defualtPickupCountryFlag: any ='https://s3.us-east-2.amazonaws.com/staticdev.laneaxis.com/portal/images/shipment-countries-flags/US.png';
  getRecord:any;
  public countryList: any;
  public newparamsLdata: any;
  public inviteFormCarrier: FormGroup;
  public inviteFormShipper: FormGroup;
  public invitationForm: FormGroup;
  public emailPattern = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$';  
  public numOnly = '[0-9]*';
  public userSubmitted = false;
  public loading=false;
  public searchModel: any = {};
  public documentCheck = false;
  public documentType = false;
  public disableDriver: boolean = true;
  public serverPathForUrl:any;
  public shipperContract:any;
  public userType:any;
  public submitInviation=false;
  public regionSelectedData:any;
  public selectedRegion:any;
  public subscriptionData:any;
  public outOfServicePercentages:any
  public authorityAndInsurance:any

  constructor(
    private formBuilder: FormBuilder,
    public sharedService: SharedService,
    private commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private alertService: AlertService,
    public dialogRef: MatDialogRef<NetworkPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}
    
  ngOnInit() {
    this.SMSPercentiles=StatusSetting?.SMSPercentiles;
    this.outOfServicePercentages =StatusSetting?.outOfServicePercentages;
    this.authorityAndInsurance = StatusSetting?.authorityAndInsurance
    let configValue = this.sharedService.getConfig();

    if (configValue !== undefined) {
      this.getConfigSet(configValue);
    }
    this.commonService.configData.subscribe((userData) => {
      this.getConfigSet(userData);
    });
    if(localStorage.getItem('user_type')=='CARRIER') {
       this.getActiveSubscription()
    }
  
    this.serverPathForUrl = environment.serverPathForUrl;
    this.shipperContract =
      'https://s3.us-east-2.amazonaws.com/staticdev.laneaxis.com/shipper/contract_document/laneAxisShipperCarrierContractTemplate.pdf';
    this.userType = localStorage.getItem('user_type');
    if (this.data.allRecord && this.data.allRecord.contractDocument) {
      this.documentCheck = true;
    } else if (
      this.data.allRecord &&
      this.data.allRecord.coiDoc &&
      !this.data.allRecord.coiExpired
    ) {
      this.documentCheck = true;
    } else {
      this.documentCheck = false;
    }
    this.inviteFormCarrier = this.formBuilder.group({
      dotNumber: ['', [Validators.pattern(this.numOnly),Validators.maxLength(10),]],
      companyName: [
        '',
        [
          Validators.required,
          Validators.maxLength(64),
          Validators.pattern(/^([a-zA-Z0-9&-@]+\s?)*$/),
        ],
      ],
      firstName: [
        '',
        [
          Validators.required,
          Validators.maxLength(64),
          Validators.pattern(/^([a-zA-Z0-9]+\s?)*$/),
        ],
      ],
      lastName: [
        '',
        [
          Validators.required,
          Validators.maxLength(64),
          Validators.pattern(/^([a-zA-Z0-9]+\s?)*$/),
        ],
      ],
      email: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.maxLength(128),
          Validators.pattern(this.emailPattern),
        ],
      ],
      countryCode: [
        'US',
      [
        Validators.required,
      ],
    ],

      phone: [
        '',
        [
          Validators.required,
          Validators.pattern(this.numOnly),
          Validators.minLength(10),
          Validators.maxLength(10),
        ],
      ],
    });
    
    this.invitationForm = this.formBuilder.group({
      know: ['',[Validators.required]],
      message: ['',[Validators.maxLength(200)]]
    }),

    this.inviteFormShipper = this.formBuilder.group({
      companyName: [
        '',
        [
          Validators.required,
          Validators.maxLength(64),
          Validators.pattern(/^([a-zA-Z0-9&-@]+\s?)*$/),
        ],
      ],
      websiteUrl:[
        '',
        [
          Validators.required,
          Validators.maxLength(256),
          Validators.pattern(
            '^(http://www.|https://www.|http://|https://)[a-z0-9]+([-.]{1}[a-z0-9]+)*.[a-z]{2,5}(:[0-9]{1,5})?(/.*)?$'
          ),
        ],
      ],
      firstName: [
        '',
        [
          Validators.required,
          Validators.maxLength(64),
          Validators.pattern(/^([a-zA-Z0-9]+\s?)*$/),
        ],
      ],
      lastName: [
        '',
        [
          Validators.required,
          Validators.maxLength(64),
          Validators.pattern(/^([a-zA-Z0-9]+\s?)*$/),
        ],
      ],
      email: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.maxLength(128),
          Validators.pattern(this.emailPattern),
        ],
      ],
      countryCode: ['US',
      [
        Validators.required,
      ],
    ],
      phone: [
        '',
        [
          Validators.required,
          Validators.pattern(this.numOnly),
          Validators.minLength(10),
          Validators.maxLength(10),
        ],
      ],
    });
    if(this.data.allRecord && this.data.allRecord.preferredRegions?.length > 0){
      this.manageRegions();
    }
  }

  getConfigSet(configValue: any) {
    this.countryList = configValue.countries;
  }

  ngAfterViewInit(){
    if(this.data?.openPop=='ecarriercheck' && this.userType=='SHIPPER'){
      if(this.data?.dotNumber){
        this.getECarreirCheck(this.data?.dotNumber);
      }
    }
    else if(this.data?.openPop=='ecarriercheck' && this.userType=='CARRIER'){
      if(this.data?.dotNumber){
        this.getCarreirSafetyRecord(this.data?.dotNumber);
      }
    }
  }

  getECarreirCheck(dotNumber:any){
      this.loading = true;
      let uri = null;
      let newParams = {
        dotNumber: dotNumber
      };
      if (newParams) uri = this.commonService.getAPIUriFromParams(newParams);
      let APIparams = {
        apiKey: AppSettings.APIsNameArray.CARRIER.ECARRIERCHECK,
        uri: uri,
      };
      this.commonService.getList(APIparams).subscribe(
         (ServerRes) => {
          if(ServerRes.success ==true){
            this.eCarrierCheckData=ServerRes?.response;
            for(let data of this.eCarrierCheckData?.recentInspections){
              for(let keys of data?.violations?.extraViolations){
                this.extraViolationKey.push(keys?.key)
                this.extraViolationValues.push(keys?.value)
              }
            }
            this.loading=false;
          }
          else{
            this.eCarrierCheckData=[];
            this.loading=false;
          }
        })
  }

  manageRegions(){
    this.regionSelectedData=[];
    this.selectedRegion =[];
    if (this.data.allRecord && this.data.allRecord.preferredRegions) {
      let newStateid = [];
      let newStateName = [];
      let data = this.data.allRecord.preferredRegions;
      var regions = {};
      for (var i = 0; i < data?.length; i++) {
        var regionName = data[i].region;
        if (!regions[regionName]) {
          regions[regionName] = [];
        }
        regions[regionName].push({
          stateId: data[i].id,
          stateName: data[i].state,
        });
      }
      for (let regionGroupName in regions) {
        for (let key in regions[regionGroupName]) {
          newStateid.push(regions[regionGroupName][key]["stateId"]);
          newStateName.push(regions[regionGroupName][key]["stateName"]);
        }
        this.selectedRegion.push(regionGroupName)
        this.regionSelectedData.push({
          region: regionGroupName,
          stateId: newStateid,
          stateNameArray: newStateName,
        });
        newStateid = [];
        newStateName = [];
      }
    }
  }

  carrierShipperSubmit({ value, valid }) {
    this.userSubmitted = true;
    if (valid) {
      this.loading = true;
      let APIparams = {
        apiKey: AppSettings.APIsNameArray.EXTERNAL.INVITE,
        uri: '',
        postBody: value,
      };
      this.commonService.post(APIparams).subscribe(
        (success) => {
          if (success.success === true) {
            let newMessage =
              'Invitation to Shipper ' +
              value.companyName +
              ' sent successfully.';
            this.alertService.showNotificationMessage(
              'success',
              'bottom',
              'right',
              'txt_s',
              'check_circle',
              'Shipper Invite',
              newMessage
            );
            this.loading = false;
            this.dialogRef.close({ event: 'Ok' });
          } else if (success.success === false) {
            this.loading = false;
            this.alertService.showNotificationMessage(
              'danger',
              'bottom',
              'right',
              'txt_d',
              'check_circle',
              'Shipper Invite',
              success.message
            );
          }
        },
        (error) => {
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_g',
            'error',
            'Unexpected Error',
            AppSettings.ERROR
          );
          this.loading = false;
        }
      );
    }
  }

  shipperCarrierSubmit({ value, valid }) {
    this.userSubmitted = true;
    if (valid) {
      this.loading = true;
      let userType = null;
      if (localStorage.getItem('user_type') === 'CARRIER') {
        userType = AppSettings.APIsNameArray.EXTERNAL.CARRIERREFERRAL;
      } else {
        userType = AppSettings.APIsNameArray.EXTERNAL.INVITE;
      }
      let APIparams = {
        apiKey: userType,
        uri: '',
        postBody: value,
      };
      this.commonService.post(APIparams).subscribe(
        (success) => {
          if (success.success === true) {
            let newMessage;
            if (localStorage.getItem('user_type') === 'CARRIER'){
                newMessage ='Referral to carrier ' + value.companyName +' sent successfully.';
            }
            if (localStorage.getItem('user_type') === 'SHIPPER'){
                newMessage ='Invitation to carrier ' + value.companyName +' sent successfully.';
            }
            this.alertService.showNotificationMessage(
              'success',
              'bottom',
              'right',
              'txt_s',
              'check_circle',
              'Carrier Invite',
              newMessage
            );
            this.loading = false;
            this.dialogRef.close({ event: 'Ok' });
          } else if (success.success === false) {
            this.loading = false;
            this.alertService.showNotificationMessage(
              'danger',
              'bottom',
              'right',
              'txt_d',
              'check_circle',
              'Carrier Invite',
              success.message
            );
          }
        },
        (error) => {
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_g',
            'error',
            'Unexpected Error',
            AppSettings.ERROR
          );
          this.loading = false;
        }
      );
    }
  }

  keyPress(event: any) {
    const pattern = /^[0-9]*$/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  keyPasteShipper(event: any) {
    const pattern = /^[0-9]*$/;
    if (!pattern.test(event.target.value)) {
      return this.inviteFormShipper.get('phone').setValue('');
    }
  }

  keyPasteCarrier(event: any) {
    const pattern = /^[0-9]*$/;
    if (!pattern.test(event.target.value)) {
      return this.inviteFormCarrier.get('phone').setValue('');
    }
  }
 
  onNoConfirmClick() {
    this.dialogRef.close({ event: 'fail' });
  }

  onConfirmationClick() {
    this.dialogRef.close({ event: 'Ok' });
  }

  onSuccess(): void {
    this.dialogRef.close({ event: 'OK' });
  }

  onNoClick(): void {
    this.dialogRef.close({ event: 'fail' });
  }

  acceptRequest() {
    this.dialogRef.close({ documentType: this.documentCheck, event: 'Ok' });
  }

  checkDocument($event) {
    this.documentCheck = $event.checked;
  }

  acceptDoc() {
    this.loading = true;
    let postBody = null;
    if (localStorage.getItem('user_type') === 'CARRIER') {
      postBody = { shipperId: this.data.id, contractId: this.data.documentId };
    }
    if (localStorage.getItem('user_type') === 'SHIPPER') {
      postBody = { carrierId: this.data.id, contractId: this.data.documentId };
    }

    let APIparams = {
      apiKey: AppSettings.APIsNameArray.DOCUMENT.ACCEPT,
      uri: '',
      postBody: postBody,
    };
    this.commonService.post(APIparams).subscribe(
      (success) => {
        if (success.success === true) {
          this.documentType = true;
          this.loading = false;
        } else if (success.success === false) {
          this.loading = false;
          this.documentType = false;
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_d',
            'check_circle',
            'Accept to Upload new Document',
            'Some reseaon document is not accepted.'
          );
        }
      },
      (error) => {
        this.documentType = false;
        this.alertService.showNotificationMessage(
          'danger',
          'bottom',
          'right',
          'txt_g',
          'error',
          'Unexpected Error',
          AppSettings.ERROR
        );
        this.loading = false;
      }
    );
  }
  
  onCreateAccount(checkType: any) {
    let convertUrl = null;
    this.route.queryParams.subscribe((params) => {
      if (Object.keys(params).length > 0) {
        let newCompanyParams = params['companyName'];
        let newCompanyname = encodeURIComponent(newCompanyParams);
        this.newparamsLdata = params;
        if (
          params['latitude'] &&
          params['longitude'] &&
          params['state'] &&
          params['addressString'] &&
          params['companyName']
        ) {
          convertUrl =
            environment.domainPoint +
            '/network/search/list?latitude=' +
            params['latitude'] +
            '&' +
            'longitude=' +
            params['longitude'] +
            '&' +
            'state=' +
            params['state'] +
            '&' +
            'addressString=' +
            params['addressString'] +
            '&' +
            'companyName=' +
            newCompanyname;
        } else if (
          params['latitude'] &&
          params['longitude'] &&
          params['state'] &&
          params['addressString']
        ) {
          convertUrl =
            environment.domainPoint +
            '/network/search/list?latitude=' +
            params['latitude'] +
            '&' +
            'longitude=' +
            params['longitude'] +
            '&' +
            'state=' +
            params['state'] +
            '&' +
            'addressString=' +
            params['addressString'];
        } else if (params['companyName']) {
          convertUrl =
            environment.domainPoint +
            '/network/search/list?companyName=' +
            newCompanyname;
        }
      }
    });
    if (convertUrl) {
      this.redirectUrl = encodeURIComponent(convertUrl);
    } else {
      this.redirectUrl = null;
    }
    this.closePopup();
    if (localStorage.getItem('access_token')) {
      this.commonService.sendTokanUpdate.next(null);
      let urlCheck = decodeURIComponent(this.redirectUrl);
      this.router.navigate(['/network/search/list/'], {
        queryParams: {
          companyName: this.newparamsLdata['companyName'],
          latitude: this.newparamsLdata['latitude'],
          longitude: this.newparamsLdata['longitude'],
          state: this.newparamsLdata['state'],
          addressString: this.newparamsLdata['addressString'],
        },
      });
    } else {
      if (checkType === 'createAccount') {
        if (this.data.type === 'SHIPPER') {
          this.router.navigate(['/'], {
            queryParams: {
              signup: 'true',
              user: 'carrier',
              redirectUrl: this.redirectUrl,
            },
          });
        } else {
          this.router.navigate(['/'], {
            queryParams: {
              signup: 'true',
              user: 'shipper',
              redirectUrl: this.redirectUrl,
            },
          });
        }
      } else {
        this.router.navigate(['/'], {
          queryParams: { redirectUrl: this.redirectUrl },
        });
      }
    }
  }

  closePopup(): void {
    this.dialogRef.close({ event: 'fail' });
  }

  sendInvite() {
    this.dialogRef.close({
      message: this.searchModel.message,
      documentType: this.documentCheck,
      event: 'Ok',
    });
  }

  inviteCarrierSubmit({value, valid}){
    this.submitInviation=true;
    if(valid){
      this.submitInviation=false;
      if(this.data.planDetail?.subscriptionCreditHistory?.defaultCredit === 0 && this.data.planDetail?.subscriptionCreditHistory?.additionalCredit===0 )
      {
        this.dialogRef.close({
          message: this.searchModel.message,
          documentType: this.documentCheck,
          isKnown:value.know,
          event: 'goCheck',
        });
        return
      }
      this.dialogRef.close({
        message: this.searchModel.message,
        documentType: this.documentCheck,
        isKnown:value.know,
        event: 'Ok',
      });
    }
  }

  gotoPriemum(){
    this.dialogRef.close({ event: 'goCheck' });
    this.router.navigate(['/carrier/premium-plan']);
  }

  gotoPriemumAdditional(){
    this.dialogRef.close({ event: 'goCheck' });
    this.router.navigate(['/carrier/subscription-overview/additional-invites']);
  }

  regionAddSpace(regionType){
    return regionType.join(', ');
  }

  getActiveSubscription() {
    this.loading=true;
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.SUBSCRIPTION.ACTIVEPLAN,
    };
    this.commonService.getList(APIparams).subscribe((ServerRes) => {
      if (ServerRes.success === true) {
       this.loading=false;
        this.subscriptionData =  ServerRes.response;
       }
    });
  } 

  selectPickCountryCode(event: any) {
    this.getRecord = this.countryList.filter((item) => item.countryAbbr == event);
    this.defualtPickupCountryFlag = this.getRecord[0]?.flag;
  }

  getCarreirSafetyRecord(dotNumber:any) {
    let uri = null;
    let newParams = {
      dotNumber: dotNumber
    };
    if (newParams) uri = this.commonService.getAPIUriFromParams(newParams);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.CARRIER.ECARRIERCHECKRECORD,
      uri: uri,
    };
    this.commonService.getList(APIparams).subscribe(
       (ServerRes) => {
        this.eCarrierCheckData=ServerRes.response;
       
      })
  }

  selectColour(eCarrierCheckData){
    var color;
    if(eCarrierCheckData=='0%'){
     return color="#D9D9D9"
    }
    else if(eCarrierCheckData > '0%' && eCarrierCheckData <= '25%'){
      return  color="#4CD79E"
    }
    else if(eCarrierCheckData > '25%' && eCarrierCheckData <= '70%'){
      return  color ="#F1C644";

    }
    else if(eCarrierCheckData > '70%'){
      return color="#FC0A0A";
    }
  }

  redirectToBuyAdditional(){
    this.router.navigateByUrl('shipper/subscription-overview/additional-credits');
    this.dialogRef.close({ event: 'fail' });
  }

  selectColourLabel(riskLabel){
    var background;
    if(riskLabel=='1'){
     return background= '#FC0A0A';
    }
    else if(riskLabel=='2'){
     return background= '#B87B43';
    }
    else if(riskLabel=='3'){
      return background= '#F1C644';
    }
    else if(riskLabel=='4'){
      return  background= '#16B613';
    }
    else if(!riskLabel || riskLabel=='0'){
      return  background= '#D9D9D9;';
    }
  }

}
