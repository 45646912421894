<div class="shipmnt-side-panel">
    <div class="side_contaier">
      <div class="side_contaiert">
        <div class="header_bar">
          <div class="side_headerl" *ngIf="shipmentDetail">
            <a class="pointer" target="_blank" (click)="openNewTabShipment()">
              <u class="fs_14 fsn_4 txt_p ">Open in New tab</u></a>
          </div>
          <div class="side_headerr" (click)="closePanel(shipmentDetail,'close')">
            <img class="icon-ic-cross pointer" src="{{serverPathForUrl}}/portal/icon/ic_close_black.svg">
          </div>
        </div>
        <div class="side_content hidden_scrollBar" *ngIf="!sidePanelLoader">
          <div class="ship_detail_head">
            <div class="side_con_header">
              <div class="id_action">
                <div>
                  <p class="id_label">Load number</p>
                  <p class="id_value ">#{{shipmentDetail?.uniqueId}}</p>
                </div>
                <div class="" style="display: flex; align-items: center;">
                  <span class="ch_statue">
                    <div class="ship_status">
                      <span class="status_label" *ngIf="currentStatus === 'upcomingByLoads' || currentStatus === 'inProgressByLoads'"
                        [ngStyle]="{'background-color': shipmentDetail?.tripStatus?.Color }"
                        style="color:white">{{shipmentDetail?.tripStatus?.Text}}
                        <div *ngIf="shipmentDetail?.tripStatus && !shipmentDetail?.tripStatus?.Text">
                          <img src="{{serverPathForUrl}}/portal/images/after-login/LaneAxis-loader.gif"
                            style="max-height: 16px;">
                        </div>
                      </span>
                    </div>
                  </span>
                  <div style="font-size: 24px;"
                    *ngIf="(shipmentDetail?.status === 15) || (!shipmentDetail?.carriers && shipmentDetail?.status !== 2 && shipmentDetail?.status !== 59)"
                    class="drop_menu dropdown disa">
                    <span class="dropdown-toggle" data-toggle="dropdown">
                      <span class="icon-ic-dots dot_icon"><i class="fa-solid fa-ellipsis"></i></span>
                    </span>
                  </div>
  
                  <div class="drop_menu dropdown dot_dropdown"
                    *ngIf="(shipmentDetail?.carriers && shipmentDetail?.status !== 15) || ((shipmentDetail?.status === 2) || (shipmentDetail?.status === 59))">
                    <span class="dropdown-toggle" data-toggle="dropdown">
                      <span class="icon-ic-dots dot_icon"><i class="fa-solid fa-ellipsis"></i></span>
                    </span>
                    <div class="dropdown-menu ship_herd_menu">
                      <span class="dropdown-item disabled_drop_btn pointer "
                        [class.disabled]="!checkWebTrackShipmentCondition()" (click)="getWebTrackingLink()">Web Tracking Link </span>
                      <span class="dropdown-item disabled_drop_btn pointer "
                        *ngIf="this.currentStatus === 'pastByLoads' && (this.currentStatus !== 'inProgressByLoads' || this.currentStatus !== 'upcomingByLoads' || this.currentStatus !== 'pendingByLoads')"
                        [routerLink]="['/messages/past_load/All']"
                        [queryParams]="{activeChat: 'true', id : shipmentDetail?.id, title : shipmentDetail?.title}"
                        [class.disabled]="!checkMessageShipmentCondition()">Message Load</span>
  
                      <span class="dropdown-item disabled_drop_btn pointer "
                        *ngIf="this.currentStatus !== 'pastByLoads' && (searchRole === ACTIVE || this.currentStatus === 'inProgressByLoads' || this.currentStatus === 'upcomingByLoads' || this.currentStatus === 'pendingByLoads')"
                        [routerLink]="['/messages/active_load/All']"
                        [queryParams]="{activeChat: 'true', id : shipmentDetail?.id, title : shipmentDetail?.title}"
                        [class.disabled]="!checkMessageShipmentCondition()">Message Load</span>
  
                      <span class="dropdown-item disabled_drop_btn pointer "
                        (click)="editShipment($event,shipmentDetail?.id)"
                        [class.disabled]="!checkEditShipmentCondition()">Edit Load</span>
                      <span class="dropdown-item disabled_drop_btn pointer "
                        [class.disabled]="!checkCancelShipmentCondition()"
                        (click)="newShipmentCancel(shipmentDetail?.id ,shipmentDetail?.uniqueId)">Cancel Load</span>
                      <span [class.disabled]="!checkFileDisputeShipmentCondition()"
                        class="dropdown-item disabled_drop_btn pointer "
                        (click)="disputeShipment($event,shipmentDetail?.id,shipmentDetail.uniqueId ,shipmentDetail?.title)">File
                        A Dispute</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="line_status_con" *ngIf="shipmentDetail?.carriers || shipmentDetail?.status === 2">
                <div class="line_view_status" *ngIf="shipmentDetail?.statusSlug">
                  <app-progress-shipment [statusLabel]="shipmentDetail?.statusLabel"
                    [statusBars]="shipmentDetail?.statusBars" [statusSlug]="shipmentDetail?.statusSlug"
                    [updatedAt]="shipmentDetail?.updatedAt" [deliverydate]="shipmentDetail?.deliverydate"
                    [statusType]="shipmentDetail?.status" [fillColor]="shipmentDetail?.tripStatus?.Color">
                  </app-progress-shipment>
                </div>
                <p class="m-0 fs_12 fsm_5 txt_p">{{shipmentDetail?.bannerStatus?.message}}</p>
                <p class="m-0 fs_12 fsm_5 txt_p">{{getDisputeData?.bannerDetail?.message}}</p>
              </div>
            </div>
  
            <div class="ship_other_detail">
              <div class="row">
                <div class="col-md-12">
                  <div class="l_label_value">
                    <p class="l_label">Load Name</p>
                    <p class="l_value fs_20 text_truncate txt_cap ">{{shipmentDetail?.title ? shipmentDetail?.title
                      : '-'}}</p>
                  </div>
                </div>
                <div class="col-md-6" *ngIf="userType==='SHIPPER'">
                  <div class="l_label_value">
                    <p class="l_label">Visibility</p>
                    <p class="l_value">
                      <span class="visibilty_icon" *ngIf="shipmentDetail.visibility==='public'">
                        <span class="icon-ic-show txt_s me-2"></span>
                        <span>Public</span>
                      </span>
                      <span class="visibilty_icon" *ngIf="shipmentDetail.visibility==='private'">
                        <span class="icon-ic-hide txt_y me-2 visibilty_pri"></span>
                        <span>Private</span>
                      </span>
                    </p>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="l_label_value">
                    <p class="l_label">Updated On</p>
                    <p class="l_value">{{shipmentDetail.updatedAt | date: 'MMM dd, yyyy HH:mm'}}</p>
                  </div>
                </div>
                <div class="col-md-6" *ngIf="userType==='SHIPPER'">
                  <div class="l_label_value">
                    <p class="l_label">Created Date</p>
                    <p class="l_value">{{shipmentDetail.createdAt | date: 'MMM dd, yyyy HH:mm'}}</p>
                  </div>
                </div>
                <div class="col-md-12" *ngIf="loadHistoryIndicator">
                  <div>
                    <button class="btn custum_button view_history" (click)="currentTabActive('load')">View Load
                      History</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="tabs_details_con">
            <div class="tab_header" id="list-tab" role="tablist">
              <button *ngIf="detailTabIndicator || this.currentStatus === 'quotedLoads' || this.currentStatus === 'quotedLoadsByLane' || this.currentStatus === 'expiredLoads'|| 
              this.currentStatus === 'expiredLoadsByLane' || this.currentStatus === 'allLoads' || this.currentStatus === 'allLoadsByLane'"
               [ngClass]="currentTabShow==='details' ? 'activeProfileTab':''"
                class="tab_header_btn " (click)="currentTabActive('details')">Details</button>
              <button *ngIf="driverTabIndicator" [ngClass]="currentTabShow==='driver' ? 'activeProfileTab':''"
                class="tab_header_btn " (click)="currentTabActive('driver')">Driver</button>
              <button *ngIf="documentTabIndicator && shipmentDetail.status !== 59 && shipmentDetail.status !== 15"
                [ngClass]="currentTabShow==='document' ? 'activeProfileTab':''" class="tab_header_btn "
                (click)="currentTabActive('document')">Documents</button>
              <button *ngIf="carrierShipmentBidIndicator"
                [ngClass]="currentTabShow==='carrierLoadBids' ? 'activeProfileTab':''" class="tab_header_btn "
                (click)="currentTabActive('carrierLoadBids')">Quotes</button>
              <button *ngIf="shipmentBidIndicator" [ngClass]="currentTabShow==='loadBids' ? 'activeProfileTab':''"
                class="tab_header_btn " (click)="currentTabActive('loadBids')">Quotes</button>
              <button *ngIf="BidHistoryIndicator" [ngClass]="currentTabShow==='history' ? 'activeProfileTab':''"
                class="tab_header_btn " (click)="currentTabActive('history')">Quotes History</button>
            </div>
  
            <div class="sidePanel_tab v_scrollBar">
              <div class="loadHistory_panel" *ngIf="currentTabShow === 'load'">
                <app-trip-history [shipmentId]="shipmentDetail.id" [status]="shipmentDetail.status"
                  [typeOfSection]="'side-panel'"></app-trip-history>
              </div>
  
              <div *ngIf="currentTabShow ==='details'">
                <div *ngIf="shipmentDetail && !skeletonLoader">
                  <div class="ship_profile_info" *ngIf="userType==='CARRIER' && shipmentDetail?.shippers"
                    [ngClass]="shipmentDetail?.shippers?.id ? 'pointer' : '' ">
                    <div class="llv_con" *ngIf="userType==='CARRIER' && shipmentDetail?.shippers">
                      <div class="llv_l" *ngIf="userType==='CARRIER' && shipmentDetail?.shippers"
                        (click)='navigateShipper(shipmentDetail?.shippers?.id)'>
                        <span class="shipr_img" *ngIf="shipmentDetail?.shippers?.profileImage">
                          <img src="{{shipmentDetail?.shippers?.profileImage}}" class="img-fluid">
                        </span>
                        <span class="initial_name initial_r_40"
                          *ngIf="!shipmentDetail?.shippers?.profileImage">{{shipmentDetail?.shippers?.companyName |
                          acronymOfName}}</span>
                        <div class="ms-2">
                          <p class="fs_12 fsn_4 txt_lb mb-0">Your Shipper</p>
                          <p class="fs_16 fsb_6 txt_b mb-0 text_truncate "
                            *ngIf="shipmentDetail?.shippers?.companyName" style="max-width: 236px;"
                            matTooltip="{{shipmentDetail?.shippers?.companyName}}">
                            {{shipmentDetail?.shippers?.companyName}}
                          </p>
  
                        </div>
                      </div>
  
  
                      <div class="llv_r"
                        *ngIf="this.currentStatus === 'pastByLoads' && (this.currentStatus !== 'inProgressByLoads' || this.currentStatus !== 'upcomingByLoads' || this.currentStatus !== 'pendingByLoads')">
                        <span class="icon_bg mess_icon disa" [routerLink]="['/messages/past_load/All']"
                          [queryParams]="{activeChat: 'true', id : shipmentDetail?.id, title : shipmentDetail?.title}">
                          <img class="img-fluid"
                            src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-message-blue.svg">
                        </span>
                      </div>
                      <div class="llv_r"
                        *ngIf="this.currentStatus !== 'pastByLoads' && (searchRole === ACTIVE || this.currentStatus === 'inProgressByLoads' || this.currentStatus === 'upcomingByLoads' || this.currentStatus === 'pendingByLoads')">
                        <span class="icon_bg mess_icon" [routerLink]="['/messages/active_load/All']"
                          [queryParams]="{activeChat: 'true', id : shipmentDetail?.id, title : shipmentDetail?.title}">
                          <img class="img-fluid"
                            src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-message-blue.svg">
                        </span>
                      </div>
  
                    </div>
                    <div class="ship_side_panel_rating">
                      <app-rating-view [rate]="shipmentDetail?.shippers?.ratingReview?.rate"
                        [ratingOwnerDetail]="ratingOwnerDetail" [ratingReview]="shipmentDetail?.shippers?.ratingReview"
                        [shipmentDetail]="shipmentDetail" *ngIf="shipmentDetail?.shippers?.ratingReview?.rate">
                      </app-rating-view>
                    </div>
                  </div>
                  <div class="ship_profile_info"
                    *ngIf="userType==='CARRIER' && !shipmentDetail?.shippers && shipmentDetail?.shipperName">
                    <div class="llv_con"
                      *ngIf="userType==='CARRIER' && !shipmentDetail?.shippers && shipmentDetail?.shipperName">
                      <div class="llv_l" (click)='navigateShipper(shipmentDetail?.shippers?.id)'
                        [ngClass]="shipmentDetail?.shippers?.id ? 'pointer' : '' ">
                        <span class="initial_name initial_r_40" *ngIf="shipmentDetail?.shipperName">
                          {{shipmentDetail?.shipperName | acronymOfName}}
                        </span>
                        <div class="ms-2">
                          <p class="fs_12 fsn_4 txt_lb mb-0">Your Shipper</p>
                          <!-- && ifAccepted -->
                          <p class="fs_16 fsb_6 txt_b mb-0 text_truncate " *ngIf="shipmentDetail?.shipperName"
                            style="max-width: 236px;">
                            {{shipmentDetail?.shipperName}}
                          </p>
  
                        </div>
                      </div>
                      <div class="llv_r"
                        *ngIf="shipmentDetail?.drivers && shipmentDetail?.parentStatus !== 1 && shipmentDetail?.parentStatus !== 3 && shipmentDetail?.shippers?.id">
                        <span class="icon_bg mess_icon disabled">
                          <img class="img-fluid"
                            src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-message-blue.svg">
                        </span>
                      </div>
                    </div>
                    <div class="ship_side_panel_rating">
                      <app-rating-view [rate]="shipmentDetail?.ratingReview?.shipperFinalRating"
                        [ratingOwnerDetail]="ratingOwnerDetail" [ratingReview]="shipmentDetail?.ratingReview"
                        [shipmentDetail]="shipmentDetail" *ngIf="shipmentDetail?.ratingReview?.shipperFinalRating">
                      </app-rating-view>
                    </div>
                  </div>
                  <div class="ship_profile_info" *ngIf="userType==='SHIPPER' && shipmentDetail?.carriers">
                    <div class="llv_con" *ngIf="userType==='SHIPPER' && shipmentDetail?.carriers">
                      <div class="llv_l" *ngIf="userType==='SHIPPER' && shipmentDetail?.carriers">
                        <span class="shipr_img" *ngIf="shipmentDetail?.carriers?.profileImage">
                          <img src="{{shipmentDetail?.carriers?.profileImage}}" class="img-fluid">
                        </span>
                        <span class="initial_name initial_r_40" *ngIf="!shipmentDetail?.carriers?.profileImage">
                          {{shipmentDetail?.carriers?.legalName | acronymOfName}}
                        </span>
                        <div class="ms-2" (click)='navigateShipper(shipmentDetail?.carriers?.id)'
                          [ngClass]="shipmentDetail?.carriers?.id ? 'pointer' : '' ">
                          <p class="fs_12 fsn_4 txt_lb mb-0">Your Carrier</p>
                          <p class="fs_16 fsb_6 txt_b mb-0 text_truncate "
                            *ngIf="shipmentDetail?.carriers?.legalName" style="max-width: 236px;">
                            {{shipmentDetail?.carriers?.legalName}}
                            <span *ngIf="userType=='SHIPPER'">
                              <img *ngIf="shipmentDetail?.carriers?.subscription"
                                src="{{serverPathForUrl}}/portal/images/after-login/premium_plan_icon.png"
                                class="img-fluid verified_icon">
                            </span>
                          </p>
                        </div>
                      </div>
  
                      <div class="llv_r"
                        *ngIf="this.currentStatus === 'pastByLoads' && (this.currentStatus !== 'inProgressByLoads' || this.currentStatus !== 'upcomingByLoads' || this.currentStatus !== 'pendingByLoads')">
                        <span class="icon_bg mess_icon" [routerLink]="['/messages/past_load/All']"
                          [queryParams]="{activeChat: 'true', id : shipmentDetail?.id, title : shipmentDetail?.title}">
                          <img class="img-fluid"
                            src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-message-blue.svg">
                        </span>
                      </div>
  
                      <div class="llv_r"
                        *ngIf="this.currentStatus !== 'pastByLoads' && (searchRole === ACTIVE || this.currentStatus === 'inProgressByLoads' || this.currentStatus === 'upcomingByLoads' || this.currentStatus === 'pendingByLoads')">
                        <span class="icon_bg mess_icon" [routerLink]="['/messages/active_load/All']"
                          [queryParams]="{activeChat: 'true', id : shipmentDetail?.id, title : shipmentDetail?.title}">
                          <img class="img-fluid"
                            src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-message-blue.svg">
                        </span>
                      </div>
                    </div>
                    <div class="ship_side_panel_rating">
                      <app-rating-view [rate]="shipmentDetail?.carriers?.ratingReview?.rate"
                        [ratingReview]="shipmentDetail?.carriers?.ratingReview" [ratingOwnerDetail]="ratingOwnerDetail"
                        [shipmentDetail]="shipmentDetail" *ngIf="shipmentDetail?.carriers?.ratingReview?.rate">
                      </app-rating-view>
                    </div>
                  </div>
                  <div class="ecarrier_bg p_24"
                    *ngIf="userType=='SHIPPER' && shipmentDetail?.carriers?.legalName && shipmentDetail?.carriers?.shipperCarrierScoreMapping">
                    <div class="ecarrier_check_box">
                      <div>
                        <span class="d-block pointer">
                          <img src="\assets\icon\info_icon_green.svg" matTooltip="{{eCarrierCheckRisk?.lowRisk}}"
                            *ngIf="shipmentDetail?.carriers?.carrierSafetyScore?.riskLevel=='4'">
                          <img src="\assets/icon/ecarrier_info_light_red.svg" matTooltip="{{eCarrierCheckRisk?.highRisk}}"
                            *ngIf="shipmentDetail?.carriers?.carrierSafetyScore?.riskLevel=='2'">
                          <img src="\assets/icon/ingo_icon_yellow.svg" matTooltip="{{eCarrierCheckRisk?.elevatedRisk}}"
                            *ngIf="shipmentDetail?.carriers?.carrierSafetyScore?.riskLevel=='3'">
                          <img src="\assets/icon/ecarrier_info_light_pure_red.svg"
                            matTooltip="{{eCarrierCheckRisk?.disqualified}}"
                            *ngIf="shipmentDetail?.carriers?.carrierSafetyScore?.riskLevel=='1'">
                        </span>
                        <span class="fs_14 txt_b">{{shipmentDetail?.carriers?.carrierSafetyScore?.riskLabel ?
                          shipmentDetail?.carriers?.carrierSafetyScore?.riskLabel : '-'}}</span>
                      </div>
  
                      <div *ngIf="shipmentDetail?.carriers?.carrierSafetyScore?.authority">
                        <span class="d-block"><img src="\assets\icon\ecarrier_succes_icon.svg"></span>
                        <span class="fs_14 txt_b">Authority</span>
                      </div>
  
                      <div>
                        <span class="d-block"><img src="\assets\icon\ecarrier_crases_icon_red.svg"></span>
                        <span class="fs_14 txt_b">Crashes<span
                            class="text_red ms-1">({{shipmentDetail?.carriers?.carrierSafetyScore?.recentCrahses ?
                            shipmentDetail?.carriers?.carrierSafetyScore?.recentCrahses : '0'}})</span></span>
                      </div>
  
                      <div>
                        <span class="d-block"><span
                            class="c_nu">{{shipmentDetail?.carriers?.carrierSafetyScore?.recentInspection ?
                            shipmentDetail?.carriers?.carrierSafetyScore?.recentInspection : '0'}}</span></span>
                        <span class="fs_14 txt_b">Inspection</span>
                      </div>
                    </div>
                    <div style="text-align: right;" class="view_snapshot_btn pointer"
                      (click)="ecarriercheck(shipmentDetail)">
                      <button class="fs_12 txt_p fsm_5">View Safety Report</button>
                    </div>
                  </div>
                  <div class="unlock_btn p_24"
                    *ngIf="userType=='SHIPPER' && shipmentDetail?.carriers?.legalName && !shipmentDetail?.carriers?.shipperCarrierScoreMapping"
                    (click)="unlockSafetyScore(shipmentDetail,shipmentDetail?.carriers?.dotNumber,'unlockSafetyScore')">
                    <button><img src="\assets\icon\unlock_btn_icon.svg" class="mr-1">Unlock Safety Score</button>
                  </div>
  
                  <div class="detail_card_con">
                    <p class="line_before ">
                      <span>Pickup Information</span>
                      <span class="fs_14 fsm_5 txt_lb">(Total {{pickupWayPoint?.length}} Pickup)</span>
                    </p>
                    <div class="hs_card" *ngFor="let pickupData of pickupWayPoint; let pickupIndex = index">
                      <div class="hs_card_l">
                        <p class="hs_tlt">{{pickupData?.businessName ? pickupData?.businessName : '-'}}</p>
                        <p class="hs_dis text_truncate">{{pickupData?.locationLongName ? pickupData?.locationLongName :
                          '-'}}</p>
                        <p class="hs_subdis">{{pickupData?.date | date: 'MMM dd, yyyy'}} {{pickupData?.timeSlot}}
                          {{pickupData?.timeZone}}</p>
                      </div>
                      <div class="hs_card_r">
                        <p class="hsr_t">Pickup {{pickupIndex + 1}}</p>
                      </div>
                    </div>
                  </div>
                  <div class="detail_card_con">
                    <p class="line_before ">
                      <span>Dropoff Information</span>
                      <span class="fs_14 fsm_5 txt_lb">(Total {{dropoffWayPoint?.length}} Dropoff)</span>
                    </p>
                    <div class="hs_card" *ngFor="let dropoffData of dropoffWayPoint;let dropoffIndex = index">
                      <div class="hs_card_l">
                        <p class="hs_tlt">{{dropoffData?.businessName ? dropoffData?.businessName : '-'}}</p>
                        <p class="hs_dis text_truncate">{{dropoffData?.locationLongName}}</p>
                        <p class="hs_subdis">{{dropoffData?.date | date: 'MMM dd, yyyy'}} {{dropoffData?.timeSlot}}
                          {{dropoffData?.timeZone}}</p>
                      </div>
                      <div class="hs_card_r">
                        <p class="hsr_t">Dropoff {{dropoffIndex + 1}}</p>
                      </div>
                    </div>
                  </div>
                  <div class="detail_card_con">
                    <p class="line_before ">Equipment Details</p>
                    <div class="con_wrapper">
                      <div class="con_wrapper_row">
                        <div class="row_label">Equipment Type</div>
                        <div class="row_value">{{shipmentDetail.equipmentName ? shipmentDetail.equipmentName : '-'}}</div>
                      </div>
                      <div class="con_wrapper_row">
                        <div class="row_label">Equipment Length</div>
                        <div class="row_value">{{shipmentDetail?.equipmentLength ?
                          shipmentDetail?.equipmentLength +' Inches' : '-'}}</div>
                      </div>
                      <div class="con_wrapper_row">
                        <div class="row_label">Purchase Order</div>
                        <div class="row_value"> {{shipmentDetail.purchaseOrderId ? shipmentDetail.purchaseOrderId : '-'}}
                        </div>
                      </div>
                      <div class="con_wrapper_row">
                        <div class="row_label">Accessorials</div>
                        <div class="row_value">{{trailSpecialRequipment(shipmentDetail?.trailerSpecs)}}</div>
                      </div>
                      <div class="con_wrapper_row" *ngIf="isTemprature">
                        <div class="row_label">Temperature Controlled</div>
                        <div class="row_value ms-1">{{shipmentDetail?.temperature ? shipmentDetail?.temperature: '-'}}
                          <span *ngIf="shipmentDetail?.temperature">F</span>
                        </div>
                      </div>
                    </div>
                  </div>
  
                  <div *ngIf="driverDetailsShipment">
                    <div *ngIf="userType==='SHIPPER'">
                      <div class="detail_card_con">
                        <p class="line_before ">Driver Information</p>
                        <div class="con_wrapper">
                          <div class="con_wrapper_row">
                            <div class="row_label">Name</div>
                            <div class="row_value">{{driverDetailsShipment.name ? driverDetailsShipment.name : '-'}}</div>
                          </div>
                          <div class="con_wrapper_row">
                            <div class="row_label">Contact Number</div>
                            <div class="row_value">{{driverDetailsShipment.phone | phone :
                              driverDetailsShipment?.countryCode}}</div>
                          </div>
                          <div class="con_wrapper_row">
                            <div class="row_label">CDL No.</div>
                            <div class="row_value"> {{(driverDetailsShipment.cdlNumber &&
                              driverDetailsShipment.cdlNumber!=='null') ? driverDetailsShipment.cdlNumber : '-'}}</div>
                          </div>
                          <div class="con_wrapper_row">
                            <div class="row_label">Vehicle No.</div>
                            <div class="row_value">{{driverDetailsShipment.vehicleNo ? driverDetailsShipment.vehicleNo :
                              '-'}}</div>
                          </div>
                          <div class="con_wrapper_row">
                            <div class="row_label"> Equipment Type </div>
                            <div class="row_value">-</div>
                          </div>
                          <div class="con_wrapper_row">
                            <div class="row_label">Rate</div>
                            <div class="row_value"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
  
                  <div class="detail_card_con" *ngIf="shipmentDetail?.shippers && shipmentDetail?.shippers?.id">
                    <p class="line_before ">Payment Terms</p>
                    <div class="con_wrapper">
                      <div class="con_wrapper_row">
                        <div class="row_label">Payment Method</div>
                        <div class="row_value">
                          <span class="fsb_6 " *ngIf="shipmentDetail?.paymentMethod === 1">USD Money</span>
                        </div>
                      </div>
                      <div class="con_wrapper_row mb-0">
                        <div class="row_label">
                          <span class="txt_b fsb_6 ">{{shipmentDetail?.terms ? shipmentDetail?.terms : ''}}</span>
                        </div>
                        <div class="row_value" *ngIf="shipmentDetail.terms === 'Shipper Terms'">
                          {{checkCardDAy(shipmentDetail?.stDays)}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Driver Details -->
              <div class="ship_driver_info" *ngIf="currentTabShow === 'driver'">
                <div *ngIf="shipmentDetail?.drivers">
                  <div class="mapPanel" *ngIf="shipmentDetail?.routeImageUrl">
                    <img src="{{shipmentDetail?.routeImageUrl}}" class="img-fluid">
                  </div>
                  <div class="detail_card_con pt-0">
                    <div class="dri_profile_info">
                      <div class="llv_con">
                        <div class="llv_l">
                          <span class="shipr_img" *ngIf="shipmentDetail?.drivers?.profileImage">
                            <img src="{{shipmentDetail?.drivers?.profileImage}}" class="img-fluid">
                          </span>
                          <span class="shipr_img" *ngIf="!shipmentDetail?.drivers?.profileImage">
                            <span class="initial_name initial_r_48">{{shipmentDetail?.drivers?.name | acronymOfName}}</span>
                          </span>
                          <div class="ms-2">
                            <p class="fs_12 fsn_4 txt_lb mb-0">Your Driver</p>
                            <p class="fs_16 fsb_6 txt_b mb-0 text_truncate "
                              *ngIf="userId === shipmentDetail?.drivers?.userId">You</p>
                            <p class="fs_16 fsb_6 txt_b mb-0 text_truncate txt_cap  pointer"
                              *ngIf="userId !== shipmentDetail?.drivers?.userId" matTooltip="{{shipmentDetail?.drivers?.name ?
                                shipmentDetail?.drivers?.name : '-'}}">{{shipmentDetail?.drivers?.name ?
                              shipmentDetail?.drivers?.name : '-'}}</p>
                          </div>
                        </div>
                        <div class="llv_r" *ngIf="userType==='CARRIER'">
                          <span *ngIf="userId !== shipmentDetail?.drivers?.userId" class="icon_bg mess_icon"
                            [routerLink]="['/messages/driver/All']"
                            [queryParams]="{activeChat: 'true', id : shipmentDetail?.drivers?.userId, title : shipmentDetail?.drivers?.name}">
                            <img class="img-fluid"
                              src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-message-blue.svg">
                          </span>
                          <span class="mess_icon ms-2"
                            matTooltip="{{shipmentDetail?.drivers?.phone| phone : shipmentDetail?.countryCode}}">
                            <span class="icon-ic-phone fs_24"><i class="fa-solid fa-phone"></i></span>
                          </span>
                        </div>
                      </div>
                      <div class="ship_side_panel_rating">
                        <app-rating-view [rate]="shipmentDetail?.drivers?.ratingReview?.rate"
                          [ratingReview]="shipmentDetail?.drivers?.ratingReview" [ratingOwnerDetail]="ratingOwnerDetail"
                          [shipmentDetail]="shipmentDetail" *ngIf="shipmentDetail?.drivers?.ratingReview?.rate">
                        </app-rating-view>
                      </div>
                    </div>
                    <div class="trip_his_con">
                      <p class="fs_12 fsm_5 txt_lb mb-0 text-uppercase">Trip History</p>
                      <div class="trip_his">
                        <div class="sc_loca_picup">
                          <img
                            src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-tick-with-white-circle.svg"
                            class="img-fluid">
                          <p class="p_loca_time fs_12">
                            <span class="fsm_5 txt_b">{{getBannerStatus(shipmentDetail?.statusSlug)}}</span>
                            <span class="fsn_4 txt_lb" *ngIf="shipmentDetail?.updatedAt"> on {{shipmentDetail?.updatedAt | date: 'MMM dd, yyyy'}} at
                              {{createDateChange(shipmentDetail?.updatedAt) | date: 'HH:mm'}}
                              {{shipmentDetail.pickupTimeZone}}
                            </span>
                          </p>
                        </div>
                        <div class="sc_loca_drop">
                          <span class="icon-ic-location txt_p fs_24"></span>
                          <p class="d_loca_time fs_12">
                            <span class="fsm_5 txt_b">Load deliver</span>
                            <span class="fsn_4 txt_lb" *ngIf="!shipmentDetail?.deliverydate"> on {{shipmentDetail.dropDate | date: 'MMM dd, yyyy'}} at {{shipmentDetail.dropTimeslot}}
                              {{shipmentDetail.dropTimeZone}}</span>
                            <span class="fsn_4 txt_lb" *ngIf="shipmentDetail?.deliverydate"> on
                              {{shipmentDetail.deliverydate | date: 'MMM dd, yyyy'}} at {{shipmentDetail.deliverydate | date: 'HH:mm'}} {{shipmentDetail.dropTimeZone}}</span>
                          </p>
                        </div>
                      </div>
                    </div>
  
                    <div class="driver_orher_info">
                      <div class="dri_card_head">
                        <p class="line_before mb-0 ">Assigned Driver Details</p>
                        <p class="fs_14 fsm_5 txt_p pointer mb-0" *ngIf="userType==='CARRIER'"><u
                            (click)="shipmentAssignDriver(shipmentDetail)">Change Driver</u></p>
                      </div>
                      <div class="con_wrapper">
                        <div class="con_wrapper_row">
                          <div class="row_label">Current Location</div>
                          <div class="row_value" *ngIf="currentlocation">{{currentlocation}}</div>
                          <div class="row_value" *ngIf="!currentlocation">-</div>
  
                        </div>
                        <div class="con_wrapper_row">
                          <div class="row_label">Miles Completed</div>
                          <div class="row_value"> {{shipmentDetail?.tripStatus?.milesCompleted ?
                            shipmentDetail?.tripStatus?.milesCompleted : '0'}} Miles</div>
                        </div>
                        <div class="con_wrapper_row">
                          <div class="row_label">ETA</div>
                          <div class="row_value" *ngIf="etaInformation">
                            <span>{{etaInformation | date: 'MMM dd, yyyy'}}</span>
                            <span class="ms-2">{{etaInformation | date: 'HH:mm:ss'}}</span>
                          </div>
                          <div class="row_value" *ngIf="!etaInformation">
                            <span class="ms-2">-</span>
                          </div>
                        </div>
                        <div *ngIf="userType==='CARRIER'" class="con_wrapper_row">
                          <div class="row_label">Contact Number</div>
                          <div class="row_value" *ngIf="shipmentDetail?.drivers?.phone">{{shipmentDetail?.drivers?.phone |
                            phone}}</div>
                          <div class="row_value" *ngIf="!shipmentDetail?.drivers?.phone">-</div>
                        </div>
                        <div class="con_wrapper_row">
                          <div class="row_label">CDL No.</div>
                          <div class="row_value">{{(shipmentDetail?.drivers?.cdlNumber &&
                            shipmentDetail?.drivers?.cdlNumber!=='null') ? shipmentDetail?.drivers?.cdlNumber : '-'}}
                          </div>
                        </div>
                        <div class="con_wrapper_row">
                          <div class="row_label">Equipment Type</div>
                          <div class="row_value " style="word-break: break-word">{{ shipmentDetail?.drivers?.equipmentName
                            ? shipmentDetail?.drivers?.equipmentName : '-'}}
                          </div>
                        </div>
  
                      </div>
                    </div>
                  </div>
                </div>
  
                <!-- Driver Details Empty State -->
                <div *ngIf="!shipmentDetail?.drivers">
                  <div class="Empty_State_body">
                    <div class="Empty_State_con">
                      <div class="Empty_State_img">
                        <img class="img-fluid"
                          src="{{serverPathForUrl}}/portal/images/after-login/Empty_State_icons/gr-assign-driver.svg">
                      </div>
                      <p class="fs_24 fsb_6 txt_b pb-1" *ngIf="userType==='SHIPPER'" style="margin: 30px 0 12px;">No
                        Driver Assigned <span class="txt_p"> Yet</span> </p>
                      <p class="fs_16 fsn_4 txt_lb pb-1" *ngIf="userType==='SHIPPER'" style="margin: 0px 0 20px;">Shipment
                        is not assigned to any driver yet.</p>
                      <p class="fs_24 fsb_6 txt_b pb-1" *ngIf="userType==='CARRIER'" style="margin: 30px 0 12px;">No
                        Driver Assigned <span class="txt_p"> Yet</span> </p>
                      <p class="fs_16 fsn_4 txt_lb pb-1" *ngIf="userType==='CARRIER'" style="margin: 0px 0 20px;">You have
                        either not added any drivers or all added drivers are currently assigned to loads. If you are also
                        a driver, You have the option of assigning the load to yourself.</p>
                      <div class="text-center">
                        <button class="btn custum_button button_primary" *ngIf="userType==='CARRIER'"
                          (click)="shipmentAssignDriver(shipmentDetail)">Assign Driver</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  
              <!-- Document Details  -->
              <div class="detail_card_con" *ngIf="currentTabShow === 'document'">
                <app-document-multiple [shipmentId]="shipmentDetail?.id" [loadDetail]="shipmentDetail" [type]="'side-panel'"></app-document-multiple>
              </div>
  
              <div class="bids_info" *ngIf="currentTabShow === 'carrierLoadBids'">
                <div *ngIf="!bidHistorySekeletonLoader">
                  <div class="detail_card_con"
                    *ngIf="lowestShipmentBid?.lowestBid && userType === 'CARRIER' && shipmentDetail?.isOfferRate==='N'">
                    <p class="line_before mb-3 ">Lowest quote</p>
                    <ul class="lowest_bids">
                      <li *ngIf="shipmentDetail?.paymentMethod === 1">
                        <span class="loest_bid_lbl">Lowest quote :</span>
                        <span class=" txt_b">{{lowestShipmentBid?.lowestBid ? ('$' + lowestShipmentBid?.lowestBid) : '-'}}</span>
                      </li>
                      <li *ngIf="shipmentDetail?.paymentMethod === 1">
                        <span class="loest_bid_lbl">Second Lowest quote :</span>
                        <span class=" txt_b">{{lowestShipmentBid?.secondLowestBid ? ('$' +
                          lowestShipmentBid?.secondLowestBid) : '-'}}</span>
                      </li>
                      <li *ngIf="shipmentDetail?.paymentMethod === 1">
                        <span class="loest_bid_lbl">Third Lowest quote :</span>
                        <span class=" txt_b">{{lowestShipmentBid?.thirdLowestBid ? ('$' +
                          lowestShipmentBid?.thirdLowestBid) : '-'}}</span>
                      </li>
                    </ul>
                  </div>
                  <!-- Empty current bid -->
                  <div class="detail_card_con" *ngIf="currentBid?.length === 0 && !acceptBidList?.length">
                    <div *ngIf="shipmentDetail?.isOfferRate==='N'">
                      <p class="line_before mb-2 ">Current quote</p>
                      <span *ngIf="shipmentDetail?.isOfferRate==='Y' && shipmentDetail?.overBid==='Y'"
                        class="fs_18 txt_b fsm_5 mb-3 d-block">Higher quotes/overbids available: {{overBidCount}}</span>
                    </div>
  
                    <div *ngIf="shipmentDetail?.isOfferRate==='Y'">
                      <p class="line_before mb-2 ">Current Offer Rate - {{shipmentDetail?.freightRate ? "$" +
                        shipmentDetail?.freightRate : '-'}}</p>
                      <span *ngIf="shipmentDetail?.isOfferRate==='Y' && shipmentDetail?.overBid==='Y'"
                        class="fs_18 txt_b fsm_5 mb-3 d-block">Higher quotes/overbids available: {{overBidCount}}</span>
                    </div>
  
                    <p class="fs_16 fsm_5 txt_lb"
                      *ngIf="(shipmentDetail?.isOfferRate==='N') && (shipmentDetail?.overBid==='N' || !shipmentDetail?.overBid)">
                      You have not entered a quote yet for this Load.
                      Use the 'Enter Quote' button to submit your first quote to the Shipper.</p>
                    <p class="fs_16 fsm_5 txt_lb"
                      *ngIf="(shipmentDetail?.isOfferRate==='Y') && (shipmentDetail?.overBid==='Y')">You have not entered
                      a quote yet for this Load. Use the ' Higher quote/overbid' or 'Accept' button to submit your
                      first quote to the Shipper.</p>
                    <p class="fs_16 fsm_5 txt_lb"
                      *ngIf="(shipmentDetail?.isOfferRate==='Y') && (shipmentDetail?.overBid==='N')">Use the 'Accept'
                      button to confirm the Offer Rate.</p>
  
                    <div class="crnt_bid_action">
                      <button *ngIf="shipmentDetail?.isOfferRate==='N'" type="button"
                        class="btn button_primary custum_button"
                        (click)="bid(shipmentDetail?.shippers?.userId,shipmentDetail?.shippers?.companyName,shipmentDetail?.freightRate,shipmentDetail?.paymentMethod,shipmentDetail?.shippers?.contractDocument,shipmentDetail?.shippers?.estimatedPrice)">Enter
                        Quote</button>
                      <button
                        *ngIf="(shipmentDetail?.isOfferRate==='Y' && shipmentDetail?.overBid==='Y') || currentBid[0]?.statusLabel==='Counter quote'"
                        type="button" class="btn button_primary custum_button"
                        (click)="bidOverBids(shipmentDetail?.shippers?.userId,shipmentDetail?.shippers?.companyName,shipmentDetail?.freightRate,shipmentDetail?.paymentMethod,shipmentDetail?.shippers?.contractDocument,shipmentDetail?.id)">Overbid</button>
                      <button
                        *ngIf="(shipmentDetail?.isOfferRate==='Y' && (shipmentDetail?.overBid==='N' || shipmentDetail?.overBid==='Y'))"
                        type="button" class="btn button_primary custum_button"
                        (click)="acceptOfferRate(shipmentDetail?.shippers?.userId,shipmentDetail?.shippers?.companyName,shipmentDetail?.freightRate,shipmentDetail?.paymentMethod,shipmentDetail?.shippers?.contractDocument,shipmentDetail?.id)">Accept
                        Offer</button>
                    </div>
                  </div>
  
                  <!-- current Bid -->
                  <div class="detail_card_con" *ngIf="currentBid?.length > 0">
                    <p class="line_before mb-3 ">Current quote or Offer Rate <span
                        *ngIf="shipmentDetail?.isOfferRate==='Y' && shipmentDetail?.overBid==='Y'"
                        style="margin-left: 20px;"> Higher quote/overbid available : {{overBidCount}} </span></p>
                    <div class="bidsInfo_con">
                      <div class="bidsInfo_conT" style="align-items: start;">
                        <div class="bidsInfo_conTl" *ngIf="!ifCurrentBid">
                          <span class="bids_img">
                            <img *ngIf="currentBid[0]?.profileImage" src="{{currentBid[0]?.profileImage}}"
                              class="img-fluid">
                            <span *ngIf="!currentBid[0]?.profileImage"
                              class="initial_name initial_r_30">{{currentBid[0]?.userName | acronymOfName}}</span>
                          </span>
                          <div class="conTltxt">
                            <p class="fs_16 fsb_6 txt_b mb-0 d-flex">
                              <span class="text_truncate "
                                style="display: inline-block; max-width: 96px;">Shipper</span>
                            </p>
                            <p class="fs_12 fsn_4 txt_lb mb-0">{{currentBid[0]?.actionTs | date: 'MMM dd, yyyy'}} at
                              {{currentBid[0]?.actionTs | date: 'HH:mm:ss'}}</p>
                          </div>
                        </div>
                        <div class="bidsInfo_conTl" *ngIf="ifCurrentBid">
                          <span class="bids_img">
                            <img *ngIf="currentBid[0]?.profileImage" src="{{currentBid[0]?.profileImage}}"
                              class="img-fluid">
                            <span *ngIf="!currentBid[0]?.profileImage"
                              class="initial_name initial_r_30">{{currentBid[0]?.userName | acronymOfName}}</span>
  
                          </span>
                          <div class="conTltxt">
                            <p class="fs_16 fsb_6 txt_b mb-0 d-flex ">
                              <span class="text_truncate "
                                style="display: inline-block; max-width: 96px;">{{currentBid[0]?.userName ?
                                currentBid[0]?.userName : '-'}}</span>
                              <span *ngIf="currentId === currentBid[0]?.actionBy" class="ms-1  fs_15">(Me)</span>
                              <img *ngIf="currentBid[0]?.subscription"
                                src="{{serverPathForUrl}}/portal/images/after-login/premium_plan_icon.png"
                                class="img-fluid verified_iconSide">
                            </p>
  
                            <p class="fs_12 fsn_4 txt_lb mb-0">{{currentBid[0]?.actionTs | date: 'MMM dd, yyyy'}} at
                              {{currentBid[0]?.actionTs | date: 'HH:mm:ss'}}</p>
                          </div>
  
                        </div>
                        <div class="bidsInfo_conTr" *ngIf="shipmentDetail?.isOfferRate==='N'">
                          <p class="fs_11 fsn_4 txt_lb mb-0">{{currentBid[0]?.statusLabel | uppercase}}</p>
                          <p class="fsb_7 txt_lb mb-0 usdUdsd contract_offerRate">
                            <span *ngIf="shipmentDetail?.paymentMethod === 2" class="ms-1 usdc_iconS"></span>
                            <span *ngIf="shipmentDetail?.paymentMethod === 2">{{currentBid[0]?.bidAmount |
                              sixDecimalPipe}}</span>
                            <span *ngIf="shipmentDetail?.paymentMethod === 1">${{currentBid[0]?.bidAmount |
                              twoDecimalPipe}}</span>
                            <app-rate-per-mile *ngIf="shipmentDetail?.paymentMethod === 1" class="fs_12"
                              [amount]="currentBid[0]?.bidAmount" [miles]="shipmentDetail?.miles"></app-rate-per-mile>
                          </p>
                        </div>
                        <div class="bidsInfo_conTr" *ngIf="shipmentDetail?.isOfferRate==='Y'">
                          <p class="fs_11 fsn_4 txt_lb mb-0">{{currentBid[0]?.statusLabel | uppercase}}</p>
                          <p class="fsb_7 txt_lb mb-0 usdUdsd contract_offerRate">
                            <span *ngIf="shipmentDetail?.paymentMethod === 2" class="ms-1 usdc_iconS"></span>
                            <span *ngIf="shipmentDetail?.paymentMethod === 2">{{currentBid[0]?.bidAmount |
                              sixDecimalPipe}}</span>
                            <span *ngIf="shipmentDetail?.paymentMethod === 1">${{currentBid[0]?.bidAmount |
                              twoDecimalPipe}}</span>
                            <app-rate-per-mile *ngIf="shipmentDetail?.paymentMethod === 1" class="fs_12"
                              [amount]="currentBid[0]?.bidAmount" [miles]="shipmentDetail?.miles"></app-rate-per-mile>
                          </p>
                        </div>
                      </div>
                      <div class="bidsMess" *ngIf="currentBid[0]?.note">
                        <img src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-message-black.svg"
                          class="img-fluid">
                        <p class="fs_12 fsn_4 txt_b mb-0 text_truncate" matTooltip="{{currentBid[0]?.note}}">
                          <i>{{currentBid[0]?.note}}</i>
                        </p>
                      </div>
  
                      <div class="bids_action" *ngIf="currentBid[0]?.status <= 1">
                        <p class="actionl" *ngIf="checkWithdrawCondition(currentBid[0]?.actionBy)"
                          (click)="cancelBid(currentBid[0]?.id,currentBid[0]?.bidAmount,currentBid[0]?.note, shipmentDetail?.shippers?.companyName,shipmentDetail?.paymentMethod)">
                          <img
                            src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-withdraw-bid.svg"
                            class="img-fluid">
                          <span class="fs_14 fsm_5 txt_d ms-2">Withdraw</span>
                        </p>
                        <p class="adisabled actionl"
                          *ngIf="userId !== currentBid[0]?.actionBy && currentBid[0]?.statusLabel ==='Awaiting confirmation'">
                          <img
                            src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-withdraw-bid.svg"
                            class="img-fluid"><span class="fs_14 fsm_5 txt_p ms-2">Withdraw</span>
                        </p>
                        <p class="actionl"
                          *ngIf="checkAcceptCondition(currentBid[0]?.actionBy,currentBid[0]?.statusLabel) && shipmentDetail?.isOfferRate==='N'"
                          (click)="acceptBidCarrier(currentBid[0]?.id,currentBid[0]?.bidAmount,currentBid[0]?.note,shipmentDetail.shippers.companyName,currentBid[0]?.userName,shipmentDetail?.paymentMethod,currentBid[0]?.status)">
                          <img src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-accept-bid.svg"
                            class="img-fluid">
                          <span class="fs_14 fsm_5 txt_s ms-2">Accept</span>
                        </p>
                        <p class="actionl"
                          *ngIf="checkAcceptCondition(currentBid[0]?.actionBy,currentBid[0]?.statusLabel) && shipmentDetail?.isOfferRate==='Y'"
                          (click)="confirmBidOfferRate(currentBid[0]?.id,shipmentDetail?.shippers?.userId,shipmentDetail?.shippers?.companyName,currentBid[0]?.bidAmount,shipmentDetail?.paymentMethod,shipmentDetail?.shippers?.contractDocument,shipmentDetail?.id)">
                          <img src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-accept-bid.svg"
                            class="img-fluid">
                          <span class="fs_14 fsm_5 txt_s ms-2">Accept</span>
                        </p>
                        <p class="actionr"
                          *ngIf="shipmentDetail?.isOfferRate==='Y' && shipmentDetail?.overBid==='Y' && overBidCount > 0"
                          (click)="bidOverBids(shipmentDetail?.shippers?.userId,shipmentDetail?.shippers?.companyName,currentBid[0]?.bidAmount,shipmentDetail?.paymentMethod,shipmentDetail?.shippers?.contractDocument,shipmentDetail?.id)">
                          <img src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-rebid-bid.svg"
                            class="img-fluid">
                          <span class="fs_14 fsm_5 txt_p ms-2"> Higher quote/overbid</span>
                        </p>
                        <p class="actionr"
                          *ngIf="(shipmentDetail?.isOfferRate==='N' || !shipmentDetail?.isOfferRate) && (!shipmentDetail?.overBid)"
                          (click)="redBid(currentBid[0]?.bidAmount,currentBid[0]?.note,shipmentDetail?.shippers?.userId,currentBid[0]?.userName,shipmentDetail?.paymentMethod)">
                          <img src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-rebid-bid.svg"
                            class="img-fluid">
                          <span class="fs_14 fsm_5 txt_p ms-2">Counter quote</span>
                        </p>
  
                        <p *ngIf="shipmentDetail?.isOfferRate==='Y' && shipmentDetail?.overBid==='Y' && overBidCount <=0"
                          class="actionr adisabled" style="cursor: not-allowed">
                          <img src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-rebid-bid.svg"
                            class="img-fluid">
                          <span class="fs_14 fsm_5 txt_p ms-2"> Higher quote/overbid</span>
                        </p>
                      </div>
  
                      <div class="bids_action bid_confirm" *ngIf="currentBid[0]?.status > 1 && currentStatus !== 'loadsLostByLane'">
                        <button *ngIf="currentBid[0]?.status === 2" type="button"
                          class="btn custum_button button_primary br_4 w-100"
                          (click)="openDriverAlertPopupConfirm(currentBid[0]?.id,currentBid[0]?.bidAmount,currentBid[0]?.note,shipmentDetail.shippers.companyName,currentBid[0]?.userName,shipmentDetail?.paymentMethod,currentBid[0]?.status)"
                          matTooltip="Confirm"> Confirm </button>
  
                        <span
                          *ngIf="currentBid[0]?.status === 4 && currentBid[0]?.statusLabel ==='Carrier confirmed' || currentBid[0]?.statusLabel ==='Confirmed' ||  currentBid[0]?.statusLabel ==='Offer Rate Confirmed' || currentBid[0]?.statusLabel ==='Counter quote Confirmed'"
                          class="fs_16 txt_s fsm_5 " matTooltip="{{currentBid[0]?.statusLabel}}">Confirmed</span>
                        <span *ngIf="currentBid[0]?.status === 4 && currentBid[0]?.statusLabel
                        ==='Overbid'" class="fs_16 txt_s fsm_5" matTooltip=" Higher quote/overbid"> Higher
                          quote/overbid</span>
                        <span *ngIf="currentBid[0]?.status === 3" class="txt_p"
                          matTooltip="{{currentBid[0]?.statusLabel}}">{{currentBid[0]?.statusLabel}}</span>
                      </div>
                    </div>
                  </div>
  
                  <!-- Accept Bid -->
                  <div class="detail_card_con" *ngIf="acceptBidList?.length > 0">
                    <p class="line_before mb-3 ">Accepted quote </p>
                    <div class="bidsInfo_con">
                      <div class="bidsInfo_conT">
                        <div class="bidsInfo_conTl">
                          <span class="bids_img">
                            <img *ngIf="acceptBidList[0]?.profileImage" src="{{acceptBidList[0]?.profileImage}}"
                              class="img-fluid">
                            <span *ngIf="!acceptBidList[0]?.profileImage"
                              class="initial_name initial_r_30">{{acceptBidList[0]?.userName | acronymOfName}}</span>
                          </span>
                          <div class="conTltxt">
                            <p class="fs_16 fsb_6 txt_b mb-0 d-flex">
                              <span class="text_truncate "
                                style="display: inline-block; max-width: 205px;">{{acceptBidList[0]?.userName ?
                                acceptBidList[0]?.userName : '-'}}</span>
                              <span *ngIf="currentId === acceptBidList[0]?.actionBy" class="ms-1 ">(Me)</span>
                            </p>
                            <p class="fs_12 fsn_4 txt_lb mb-0">{{acceptBidList[0]?.actionTs | date: 'MMM dd, yyyy'}} at
                              {{acceptBidList[0]?.actionTs | date: 'HH:mm:ss'}}</p>
                          </div>
                        </div>
                        <div class="bidsInfo_conTr">
                          <p class="fs_11 fsn_4 txt_lb mb-0">{{acceptBidList[0]?.statusLabel | uppercase}}</p>
                          <p class="fsb_7 mb-0 usdUdsd contract_offerRate">
                            <span *ngIf="shipmentDetail?.paymentMethod === 2" class="ms-1 usdc_iconS"></span>
                            <span *ngIf="shipmentDetail?.paymentMethod === 2">{{acceptBidList[0]?.bidAmount |
                              sixDecimalPipe}}</span>
                            <span *ngIf="shipmentDetail?.paymentMethod === 1">${{acceptBidList[0]?.bidAmount |
                              twoDecimalPipe}}</span>
                            <app-rate-per-mile *ngIf="shipmentDetail?.paymentMethod === 1" class="fs_12"
                              [amount]="acceptBidList[0]?.bidAmount" [miles]="shipmentDetail?.miles"></app-rate-per-mile>
                          </p>
                        </div>
                      </div>
                      <div class="bidsMess" *ngIf="acceptBidList[0]?.note">
                        <img src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-message-black.svg"
                          class="img-fluid">
                        <p class="fs_12 fsn_4 txt_b mb-0 text_truncate" matTooltip="{{acceptBidList[0]?.note}}">
                          <i>{{acceptBidList[0]?.note}}</i>
                        </p>
                      </div>
                    </div>
                  </div>
  
                  <!-- Previous Bid -->
                  <div class="detail_card_con" *ngIf="previousBid?.length > 0">
                    <p class="line_before mb-3 ">Previous Bids</p>
                    <div class="bidsInfo_con ope_50" *ngFor="let historyRecord of previousBid;let j=index;">
                      <div class="bidsInfo_conT">
                        <div class="bidsInfo_conTl">
                          <span class="bids_img">
                            <img class="img-fluid" *ngIf="historyRecord?.profileImage"
                              src="{{historyRecord?.profileImage}}">
                            <span class="initial_name initial_r_32 "
                              *ngIf="!historyRecord?.profileImage">{{historyRecord.userName |
                              acronymOfName}}</span>
                          </span>
                          <div class="conTltxt">
                            <p class="fs_16 fsb_6 txt_b mb-0 d-flex">
                              <span class=""
                                *ngIf="historyRecord.bidAmount && currentId !== historyRecord.actionBy && !ifPreviousBid">
                                Shipper
                              </span>
                              <span class="" *ngIf="historyRecord.bidAmount && currentId !== historyRecord.actionBy && ifPreviousBid">
                                {{historyRecord.userName ? historyRecord.userName : '-'}}
                              </span>
                              <span class="" *ngIf="historyRecord.bidAmount && currentId === historyRecord.actionBy">
                                {{historyRecord.userName ? historyRecord.userName : '-'}}
                              </span>
                              <span *ngIf="historyRecord.bidAmount && currentId === historyRecord.actionBy"
                                class="">
                                (Me)
                              </span>
                            </p>
                            <p class="fs_12 fsn_4 txt_lb mb-0">{{historyRecord.actionTs | date: 'MMM dd, yyyy'}} at
                              {{historyRecord.actionTs | date: 'HH:mm'}}</p>
                          </div>
                        </div>
                        <div class="bidsInfo_conTr">
                          <p class="fs_11 fsn_4 txt_lb mb-0">{{historyRecord.statusLabel | uppercase}}</p>
                          <p class="fsb_7 txt_b mb-0 usdUdsd contract_offerRate">
                            <span *ngIf="shipmentDetail?.paymentMethod === 2" class="df_c">
                              <span class="ms-1 usdc_iconS"></span>
                              <span>{{historyRecord.bidAmount | sixDecimalPipe}}</span>
                            </span>
                            <span *ngIf="shipmentDetail?.paymentMethod === 1">${{historyRecord.bidAmount |
                              twoDecimalPipe}}</span>
                            <app-rate-per-mile *ngIf="shipmentDetail?.paymentMethod === 1" class="fs_12"
                              [amount]="historyRecord?.bidAmount" [miles]="shipmentDetail?.miles"></app-rate-per-mile>
                          </p>
                        </div>
                      </div>
                      <div class="bidsMess" *ngIf="historyRecord?.note">
                        <img src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-message-black.svg"
                          class="img-fluid">
                        <p class="fs_12 fsn_4 txt_b mb-0 text_truncate" matTooltip="{{historyRecord?.note}}">
                          <i>{{historyRecord?.note}}</i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- skelaton Bid-->
                <div class="skt_bidHistory" *ngIf="bidHistorySekeletonLoader">
                  <div class="skt_currentBid">
                    <ngx-skeleton-loader count="1" [theme]="{ width: '88%', height: '20px'}" class="loader_mt_10"
                      style="padding: 0 24px;"></ngx-skeleton-loader>
                    <div class="skt_bgBidCard bg_w mb-1">
                      <div class="skt_bgBidCardt">
                        <div class="skt_bgBidCardtl">
                          <ngx-skeleton-loader count="1" [theme]="{ width: '40px', height: '45px'}" class="loader_mb_0">
                          </ngx-skeleton-loader>
                        </div>
                        <div class="skt_bgBidCardtr">
                          <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '20px'}" class="loader_mb_5">
                          </ngx-skeleton-loader>
                          <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '16px'}" class="loader_mb_0">
                          </ngx-skeleton-loader>
                        </div>
                      </div>
                      <div class="skt_bgBidCardb">
                        <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '16px'}" class="loader_mb_0">
                        </ngx-skeleton-loader>
                      </div>
                      <div class="skt_bgBidCardt"
                        style="margin: 12px 0 0; border-top: 1px solid #c8dec9; padding: 12px 0 0;">
                        <div class="skt_bgBidCardtl" style="width: 48%; text-align: center;">
                          <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '42px'}" class="loader_m_0">
                          </ngx-skeleton-loader>
                        </div>
                        <div class="skt_bgBidCardtr" style="width: 48%; text-align: center;">
                          <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '42px'}" class="loader_m_0">
                          </ngx-skeleton-loader>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Skelaton Previous Bid -->
                  <div class="skt_previousBid">
                    <ngx-skeleton-loader count="1" [theme]="{ width: '88%', height: '20px'}" class="loader_mt_10"
                      style="padding: 0 24px;"></ngx-skeleton-loader>
                    <div class="skt_bgBidCard bg_w ope_7 mb-3" style="border-bottom: 4px solid #EDEDED;">
                      <div class="skt_bgBidCardt">
                        <div class="skt_bgBidCardtl">
                          <ngx-skeleton-loader count="1" [theme]="{ width: '40px', height: '45px'}" class="loader_mb_0">
                          </ngx-skeleton-loader>
                        </div>
                        <div class="skt_bgBidCardtr">
                          <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '20px'}" class="loader_mb_5">
                          </ngx-skeleton-loader>
                          <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '16px'}" class="loader_mb_0">
                          </ngx-skeleton-loader>
                        </div>
                      </div>
                      <div class="skt_bgBidCardb">
                        <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '16px'}" class="loader_mb_0">
                        </ngx-skeleton-loader>
                      </div>
                    </div>
                    <div class="skt_bgBidCard bg_w ope_7 mb-3" style="border-bottom: 4px solid #EDEDED;">
                      <div class="skt_bgBidCardt">
                        <div class="skt_bgBidCardtl">
                          <ngx-skeleton-loader count="1" [theme]="{ width: '40px', height: '45px'}" class="loader_mb_0">
                          </ngx-skeleton-loader>
                        </div>
                        <div class="skt_bgBidCardtr">
                          <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '20px'}" class="loader_mb_5">
                          </ngx-skeleton-loader>
                          <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '16px'}" class="loader_mb_0">
                          </ngx-skeleton-loader>
                        </div>
                      </div>
                      <div class="skt_bgBidCardb">
                        <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '16px'}" class="loader_mb_0">
                        </ngx-skeleton-loader>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  
              <!-- shipment bid sections -->
              <div class="shipment_bidding tab_header" *ngIf="currentTabShow === 'loadBids'">
                <div *ngIf="!preCarrierSkeloton && !allCarrierSkeloton && !skeletonLoaderCard">
                  <div *ngIf="subCurrentTab !=='acceptShipment'">
                    <div class="expected_bidRate">
                      <span class="fs_16 fsm_5 txt_b">Expected Rate: ${{shipmentDetail?.freightRate ?
                        shipmentDetail?.freightRate : '-'}}</span>
                    </div>
                    <mat-tab-group animationDuration="0ms" [selectedIndex]="selectedIndex">
                      <mat-tab label="Preferred Carriers">
                        <div class="selection_tabOne shipment_bidding_car">
                          <div class="">
                            <table class="table mb-0"
                              *ngIf="preCarrierSkeloton && preCarrierList && preCarrierList?.length > 0">
                              <thead>
                                <tr style="height: 60px;">
                                  <th scope="col" style="width: 320px;">
                                    <span class="fs_16 fsm_5 txt_lb">Carrier</span>
                                  </th>
                                  <th scope="col" style="width: 200px;">
                                    <span class="fs_16 fsm_5 txt_lb">Previous Quote</span>
                                    <span class="icon-ic-info fs_14 txt_lb ms-2"
                                      matTooltip="Click on a Arrow for sorting Previous bids"></span>
                                  </th>
                                  <th scope="col" style="width: 200px;">
                                    <span class="fs_16 fsm_5 txt_lb ">Current Quote</span>
                                    <span class="icon-ic-info fs_14 txt_lb ms-2"
                                      matTooltip="Click on a Arrow for sorting Current quote"></span>
                                  </th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let carrierRecord of preCarrierList;let i=index;">
                                  <td scope="row" style="vertical-align: initial;">
                                    <div class="carrierN">
                                      <span class="initial_name initial_r_64">{{carrierRecord.legalName |
                                        acronymOfName}}</span>
                                      <span
                                        class="abl_ca_name text_truncate fs_16 fsm_5 txt_b">{{carrierRecord.legalName}}</span>
                                    </div>
                                  </td>
                                  <td class="fs_16 fsn_4 txt_b" style="vertical-align: initial;">
                                    <p style="margin:0px;">{{carrierRecord?.previousBid ?
                                      carrierRecord?.previousBid.bidAmount :'-'}}
                                      <span *ngIf="currentId === carrierRecord?.previousBid?.actionBy">(Me)</span>
                                      <span class="icon-ic-accepted txt_s fs_24"
                                        *ngIf="carrierRecord?.previousBid?.isAccepted === '1'"
                                        matTooltip="Carrier has accepted the bid"></span>
                                    </p>
                                    <span class="fs_12 fsn_4 txt_lb">{{carrierRecord?.previousBid ?
                                      carrierRecord?.previousBid.note :'-'}}</span>
                                  </td>
                                  <td class="fs_16 fsn_4 txt_b" style="vertical-align: initial;">
                                    <p style="margin:0px;">{{carrierRecord?.currentBid ?
                                      carrierRecord?.currentBid.bidAmount :'-'}}
                                      <span *ngIf="currentId === carrierRecord?.currentBid?.actionBy">(Me)</span>
                                      <span class="icon-ic-accepted txt_s fs_24"
                                        *ngIf="carrierRecord?.currentBid?.isAccepted === '1'"
                                        matTooltip="Carrier has accepted the bid"></span>
                                    </p>
                                    <span class="fs_12 fsn_4 txt_lb">{{carrierRecord?.currentBid ?
                                      carrierRecord?.currentBid.note :'-'}}</span>
                                  </td>
                                  <td class="fs_16 fsn_4 txt_b" style="width: 120px; vertical-align: initial;">
                                    <div style="display: flex; justify-content: flex-end;">
                                      <span
                                        *ngIf="carrierRecord?.currentBid?.actionBy === currentId && carrierRecord?.currentBid?.isAccepted ===0"
                                        class="txt_s"
                                        style="display: flex; justify-content: center; align-items: center;"> Counter
                                        quote sent</span>
                                      <span *ngIf="((carrierRecord?.currentBid === null))"
                                        class="icon-ic-tick fs_16 txt_w bg_s"
                                        style="display: flex; justify-content: center; align-items: center; width: 32px; height: 32px; border-radius: 50%;"></span>
                                      <span
                                        *ngIf="(carrierRecord?.currentBid !== null) && (carrierRecord?.currentBid?.actionBy !== currentId)|| (carrierRecord?.currentBid?.isAccepted ==='1' && carrierRecord?.currentBid?.actionBy === currentId)"
                                        (click)="redBid(carrierRecord?.currentBid?.bidAmount,carrierRecord?.currentBid?.note,carrierRecord?.userId,carrierRecord.legalName,shipmentDetail?.paymentMethod)"
                                        class="icon-ic-redo fs_16 pointer txt_w bg_p"
                                        style="display: flex; justify-content: center; align-items: center; width: 32px; height: 32px; border-radius: 50%; margin-left: 16px;"
                                        matTooltip="Counter quote"></span>
                                      <span *ngIf="carrierRecord?.currentBid === null"
                                        class="icon-ic-redo fs_16 txt_w bg_p"
                                        style="display: flex; justify-content: center; align-items: center; width: 32px; height: 32px; border-radius: 50%; margin-left: 16px;"></span>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div *ngIf="!preCarrierSkeloton">
                            <table class="table mb-0">
                              <thead>
                                <tr style="height: 60px;">
                                  <th scope="col" style="width: 320px;">
                                    <div class="loader_mb_0">
                                      <ngx-skeleton-loader count="1" [theme]="{ width: '40%', height: '16px'}">
                                      </ngx-skeleton-loader>
                                    </div>
                                  </th>
                                  <th scope="col" style="width: 200px;">
                                    <div class="loader_mb_0">
                                      <ngx-skeleton-loader count="1" [theme]="{ width: '40%', height: '16px'}">
                                      </ngx-skeleton-loader>
                                    </div>
                                  </th>
                                  <th scope="col" style="width: 200px;">
                                    <div class="loader_mb_0">
                                      <ngx-skeleton-loader count="1" [theme]="{ width: '40%', height: '16px'}">
                                      </ngx-skeleton-loader>
                                    </div>
                                  </th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr style="height: 65px;" *ngFor="let fake of generateFake(5)">
                                  <td>
                                    <div class="loader_mb_0">
                                      <ngx-skeleton-loader count="1" [theme]="{ width: '60%', height: '16px'}">
                                      </ngx-skeleton-loader>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="loader_mb_0">
                                      <ngx-skeleton-loader count="1" [theme]="{ width: '60%', height: '16px'}">
                                      </ngx-skeleton-loader>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="loader_mb_0">
                                      <ngx-skeleton-loader count="1" [theme]="{ width: '60%', height: '16px'}">
                                      </ngx-skeleton-loader>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="loader_mb_0" style="text-align: end;">
                                      <ngx-skeleton-loader count="1" [theme]="{ width: '70%', height: '16px'}">
                                      </ngx-skeleton-loader>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div *ngIf="preCarrierList?.length === 0 && preCarrierSkeloton">
                            <div class="Empty_State_body" style="height: calc(100vh - 230px);">
                              <div class="Empty_State_con">
                                <div class="Empty_State_img">
                                  <img class="img-fluid"
                                    src="{{serverPathForUrl}}/portal/images/after-login/Empty_State_icons/graphic_no-transactions.svg ">
                                </div>
                                <div>
                                  <p class="fs_24 fsb_6 txt_b pb-1" style="margin: 30px 0 12px;">No Load bids... <span
                                      class="txt_p"> Yet</span> </p>
                                  <p class="fs_16 fsn_4 txt_lb pb-1" style="margin: 0px 0 20px;">"Lorem Ipsum is simply
                                    dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                                    industry's standard dummy text ever since the 1500s"</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </mat-tab>
                      <mat-tab label="All Carriers">
                        <div class="selection_tabTwo shipment_bidding_car">
                          <div>
                            <table class="table mb-0"
                              *ngIf="allCarrierSkeloton && carrierList && carrierList?.length > 0">
                              <thead>
                                <tr style="height: 60px;">
                                  <th scope="col" style="width: 320px;">
                                    <span class="fs_16 fsm_5 txt_lb">Carrier</span>
                                  </th>
                                  <th scope="col" style="width: 200px;">
                                    <span class="fs_16 fsm_5 txt_lb">Previous Quote </span>
                                  </th>
                                  <th scope="col" style="width: 200px;">
                                    <span class="fs_16 fsm_5 txt_lb">Current Quote</span>
                                  </th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let carrierRecord of carrierList;let j=index;">
                                  <td scope="row" style="vertical-align: initial;">
                                    <div class="carrierN">
                                      <span class="initial_name initial_r_64">{{carrierRecord.legalName |
                                        acronymOfName}}</span>
                                      <span
                                        class="abl_ca_name text_truncate fs_16 fsm_5 txt_b">{{carrierRecord.legalName}}</span>
                                    </div>
                                  </td>
                                  <td class="fs_16 fsn_4 txt_b" style="vertical-align: initial;">
                                    <p style="margin:0px;">{{carrierRecord?.previousBid ?
                                      carrierRecord?.previousBid.bidAmount :'-'}}
                                      <span *ngIf="currentId === carrierRecord?.previousBid?.actionBy">(Me)</span>
                                      <span class="icon-ic-accepted txt_s fs_24"
                                        *ngIf="carrierRecord?.previousBid?.isAccepted === '1'"
                                        matTooltip="Carrier has accepted the bid"></span>
                                    </p>
                                    <span class="fs_12 fsn_4 txt_lb">{{carrierRecord?.previousBid ?
                                      carrierRecord?.previousBid.note :'-'}}</span>
                                  </td>
                                  <td class="fs_16 fsn_4 txt_b" style="vertical-align: initial;">
                                    <p style="margin:0px;">{{carrierRecord?.currentBid ?
                                      carrierRecord?.currentBid.bidAmount :'-'}}
                                      <span *ngIf="currentId === carrierRecord?.currentBid?.actionBy">(Me)</span>
                                      <span class="icon-ic-accepted txt_s fs_24"
                                        *ngIf="carrierRecord?.currentBid?.isAccepted === '1'"
                                        matTooltip="Carrier has accepted the bid"></span>
                                    </p>
                                    <span class="fs_12 fsn_4 txt_lb">{{carrierRecord?.currentBid ?
                                      carrierRecord?.currentBid.note :'-'}}</span>
                                  </td>
                                  <td class="fs_16 fsn_4 txt_b" style="width: 120px; vertical-align: initial;">
                                    <div style="display: flex; justify-content: flex-end;">
  
                                      <span class="txt_s"
                                        *ngIf="carrierRecord?.currentBid?.actionBy === currentId && carrierRecord?.currentBid?.isAccepted ===0"
                                        style=" display: flex; justify-content: center; align-items: center;"> Counter quote sent</span>
  
                                      <span *ngIf="((carrierRecord?.currentBid === null))"
                                        class="icon-ic-tick fs_16 txt_w bg_s"
                                        style="display: flex; justify-content: center; align-items: center; width: 32px; height: 32px; border-radius: 50%;">
                                      </span>
  
                                      <span
                                        *ngIf="(carrierRecord?.currentBid !== null) && (carrierRecord?.currentBid?.actionBy !== currentId)|| (carrierRecord?.currentBid?.isAccepted === '1' && carrierRecord?.currentBid?.actionBy === currentId)"
                                        (click)="redBid(carrierRecord?.currentBid?.bidAmount,carrierRecord?.currentBid?.note,carrierRecord?.userId,carrierRecord.legalName,shipmentDetail?.paymentMethod)"
                                        class="icon-ic-redo fs_16 pointer txt_w bg_p"
                                        style="display: flex; justify-content: center; align-items: center; width: 32px; height: 32px; border-radius: 50%; margin-left: 16px;"
                                        matTooltip="Counter quote"></span>
  
                                      <span *ngIf="carrierRecord?.currentBid === null"
                                        class="icon-ic-redo fs_16 txt_w bg_p"
                                        style="display: flex; justify-content: center; align-items: center; width: 32px; height: 32px; border-radius: 50%; margin-left: 16px;">
                                      </span>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div *ngIf="!allCarrierSkeloton">
                            <table class="table mb-0">
                              <thead>
                                <tr style="height: 60px;">
                                  <th scope="col" style="width: 320px;">
                                    <div class="loader_mb_0">
                                      <ngx-skeleton-loader count="1" [theme]="{ width: '40%', height: '16px'}">
                                      </ngx-skeleton-loader>
                                    </div>
                                  </th>
                                  <th scope="col" style="width: 200px;">
                                    <div class="loader_mb_0">
                                      <ngx-skeleton-loader count="1" [theme]="{ width: '40%', height: '16px'}">
                                      </ngx-skeleton-loader>
                                    </div>
                                  </th>
                                  <th scope="col" style="width: 200px;">
                                    <div class="loader_mb_0">
                                      <ngx-skeleton-loader count="1" [theme]="{ width: '40%', height: '16px'}">
                                      </ngx-skeleton-loader>
                                    </div>
                                  </th>
                                  <th scope="col"></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr style="height: 65px;" *ngFor="let fake of generateFake(5)">
                                  <td>
                                    <div class="loader_mb_0">
                                      <ngx-skeleton-loader count="1" [theme]="{ width: '60%', height: '16px'}">
                                      </ngx-skeleton-loader>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="loader_mb_0">
                                      <ngx-skeleton-loader count="1" [theme]="{ width: '60%', height: '16px'}">
                                      </ngx-skeleton-loader>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="loader_mb_0">
                                      <ngx-skeleton-loader count="1" [theme]="{ width: '60%', height: '16px'}">
                                      </ngx-skeleton-loader>
                                    </div>
                                  </td>
                                  <td>
                                    <div class="loader_mb_0" style="text-align: end;">
                                      <ngx-skeleton-loader count="1" [theme]="{ width: '70%', height: '16px'}">
                                      </ngx-skeleton-loader>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div *ngIf="carrierList?.length === 0 && allCarrierSkeloton">
                            <div class="Empty_State_body" style="height: calc(100vh - 230px);">
                              <div class="Empty_State_con">
                                <div class="Empty_State_img">
                                  <img class="img-fluid"
                                    src="{{serverPathForUrl}}/portal/images/after-login/Empty_State_icons/graphic_no-transactions.svg ">
                                </div>
                                <div>
                                  <p class="fs_24 fsb_6 txt_b pb-1" style="margin: 30px 0 12px;">No Load bids... <span
                                      class="txt_p"> Yet</span> </p>
                                  <p class="fs_16 fsn_4 txt_lb pb-1" style="margin: 0px 0 20px;">"Lorem Ipsum is simply
                                    dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                                    industry's standard dummy text ever since the 1500s"</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </mat-tab>
                    </mat-tab-group>
                    <div class="row">
                      <div class="col-md-12">
                        <p class="select_action">
                          <span>Select more carriers</span>
                          <span class="icon-Right fs_16" style="height: 17px"></span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <!-- Shipment accept shipper side section -->
  
                  <!-- skelaton Bid-->
                  <div class="skt_bidHistory" *ngIf="preCarrierSkeloton || allCarrierSkeloton || skeletonLoaderCard">
                    <!-- Skelaton Current Bid -->
                    <div class="skt_currentBid">
                      <ngx-skeleton-loader count="1" [theme]="{ width: '88%', height: '20px'}" class="loader_mt_10"
                        style="padding: 0 24px;"></ngx-skeleton-loader>
                      <div class="skt_bgBidCard bg_w mb-1">
                        <div class="skt_bgBidCardt">
                          <div class="skt_bgBidCardtl">
                            <ngx-skeleton-loader count="1" [theme]="{ width: '40px', height: '45px'}" class="loader_mb_0">
                            </ngx-skeleton-loader>
                          </div>
                          <div class="skt_bgBidCardtr">
                            <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '20px'}" class="loader_mb_5">
                            </ngx-skeleton-loader>
                            <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '16px'}" class="loader_mb_0">
                            </ngx-skeleton-loader>
                          </div>
                        </div>
                        <div class="skt_bgBidCardb">
                          <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '16px'}" class="loader_mb_0">
                          </ngx-skeleton-loader>
                        </div>
                        <div class="skt_bgBidCardt"
                          style="margin: 12px 0 0; border-top: 1px solid #c8dec9; padding: 12px 0 0;">
                          <div class="skt_bgBidCardtl" style="width: 48%; text-align: center;">
                            <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '42px'}" class="loader_m_0">
                            </ngx-skeleton-loader>
                          </div>
                          <div class="skt_bgBidCardtr" style="width: 48%; text-align: center;">
                            <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '42px'}" class="loader_m_0">
                            </ngx-skeleton-loader>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Skelaton Previous Bid -->
                    <div class="skt_previousBid">
                      <ngx-skeleton-loader count="1" [theme]="{ width: '88%', height: '20px'}" class="loader_mt_10"
                        style="padding: 0 24px;"></ngx-skeleton-loader>
                      <div class="skt_bgBidCard bg_w ope_7 mb-3" style="border-bottom: 4px solid #EDEDED;">
                        <div class="skt_bgBidCardt">
                          <div class="skt_bgBidCardtl">
                            <ngx-skeleton-loader count="1" [theme]="{ width: '40px', height: '45px'}" class="loader_mb_0">
                            </ngx-skeleton-loader>
                          </div>
                          <div class="skt_bgBidCardtr">
                            <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '20px'}" class="loader_mb_5">
                            </ngx-skeleton-loader>
                            <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '16px'}" class="loader_mb_0">
                            </ngx-skeleton-loader>
                          </div>
                        </div>
                        <div class="skt_bgBidCardb">
                          <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '16px'}" class="loader_mb_0">
                          </ngx-skeleton-loader>
                        </div>
                      </div>
                      <div class="skt_bgBidCard bg_w ope_7 mb-3" style="border-bottom: 4px solid #EDEDED;">
                        <div class="skt_bgBidCardt">
                          <div class="skt_bgBidCardtl">
                            <ngx-skeleton-loader count="1" [theme]="{ width: '40px', height: '45px'}" class="loader_mb_0">
                            </ngx-skeleton-loader>
                          </div>
                          <div class="skt_bgBidCardtr">
                            <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '20px'}" class="loader_mb_5">
                            </ngx-skeleton-loader>
                            <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '16px'}" class="loader_mb_0">
                            </ngx-skeleton-loader>
                          </div>
                        </div>
                        <div class="skt_bgBidCardb">
                          <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '16px'}" class="loader_mb_0">
                          </ngx-skeleton-loader>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  
              <!-- History bid sections -->
              <div *ngIf="currentTabShow === 'history'">
                <div *ngIf="!bidHistorySekeletonLoader">
                  <!-- Accepted_Bid -->
                  <div class="detail_card_con" *ngIf="acceptBidList?.length > 0">
                    <p class="line_before mb-3 ">Accepted Quote</p>
                    <div class="bidsInfo_con">
                      <div class="bidsInfo_conT">
                        <div class="bidsInfo_conTl">
                          <span class="bids_img">
                            <img *ngIf="acceptBidList[0]?.profileImage" src="{{acceptBidList[0]?.profileImage}}"
                              class="img-fluid">
                            <span *ngIf="!acceptBidList[0]?.profileImage"
                              class="initial_name initial_r_30">{{acceptBidList[0]?.profileImage | acronymOfName}}</span>
                          </span>
                          <div class="conTltxt">
                            <p class="fs_16 fsb_6 txt_b mb-0 ">{{acceptBidList[0]?.userName ?
                              acceptBidList[0]?.userName : '-'}}
                              <span class="" *ngIf="currentId === acceptBidList[0]?.actionBy">(Me)</span>
                            </p>
                            <p class="fs_12 fsn_4 txt_lb mb-0">{{acceptBidList[0]?.actionTs | date: 'MMM dd, yyyy'}} at
                              {{acceptBidList[0]?.actionTs | date: 'MM:hh:ss'}}</p>
                          </div>
                        </div>
                        <div class="bidsInfo_conTr">
                          <p class="fs_11 fsn_4 mb-0 txt_s text-uppercase">{{acceptBidList[0]?.statusLabel | uppercase}}
                          </p>
                          <p class="fs_16 fsb_7 txt_s mb-0">{{acceptBidList[0]?.bidAmount | currency : 'USD'}}</p>
                        </div>
                      </div>
                      <div class="bidsMess" *ngIf="acceptBidList[0]?.note">
                        <img src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-message-black.svg"
                          class="img-fluid">
                        <p class="fs_12 fsn_4 txt_b mb-0 text_truncate" matTooltip="{{acceptBidList[0]?.note}}">
                          <i>{{acceptBidList[0]?.note}}</i>
                        </p>
                      </div>
                    </div>
  
                    <div class="ecarrier_bg" *ngIf="userType=='SHIPPER' && acceptBidList[0]?.shipperCarrierScoreMapping">
                      <div class="ecarrier_check_box">
                        <div>
                          <span class="d-block pointer">
                            <img src="\assets\icon\info_icon_green.svg" matTooltip="{{eCarrierCheckRisk?.lowRisk}}"
                              *ngIf="acceptBidList[0]?.carrierSafetyScore?.riskLevel=='4'">
                            <img src="\assets/icon/ecarrier_info_light_red.svg"
                              matTooltip="{{eCarrierCheckRisk?.highRisk}}"
                              *ngIf="acceptBidList[0]?.carrierSafetyScore?.riskLevel=='2'">
                            <img src="\assets/icon/ingo_icon_yellow.svg" matTooltip="{{eCarrierCheckRisk?.elevatedRisk}}"
                              *ngIf="acceptBidList[0]?.carrierSafetyScore?.riskLevel=='3'">
                            <img src="\assets/icon/ecarrier_info_light_pure_red.svg"
                              matTooltip="{{eCarrierCheckRisk?.disqualified}}"
                              *ngIf="acceptBidList[0]?.carrierSafetyScore?.riskLevel=='1'">
                          </span>
                          <span class="fs_14 txt_b">{{acceptBidList[0]?.carrierSafetyScore?.riskLabel ?
                            acceptBidList[0]?.carrierSafetyScore?.riskLabel : '-'}}</span>
                        </div>
  
                        <div *ngIf="acceptBidList[0]?.carrierSafetyScore?.authority">
                          <span class="d-block"><img src="\assets\icon\ecarrier_succes_icon.svg"></span>
                          <span class="fs_14 txt_b">Authority</span>
                        </div>
  
                        <div>
                          <span class="d-block"><img src="\assets\icon\ecarrier_crases_icon_red.svg"></span>
                          <span class="fs_14 txt_b">Crashes<span
                              class="text_red ms-1">({{acceptBidList[0]?.carrierSafetyScore?.recentCrashes ?
                              acceptBidList[0]?.carrierSafetyScore?.recentCrashes : '0'}})</span></span>
                        </div>
  
                        <div>
                          <span class="d-block"><span
                              class="c_nu">{{acceptBidList[0]?.carrierSafetyScore?.recentInspections ?
                              acceptBidList[0]?.carrierSafetyScore?.recentInspections : '0'}}</span></span>
                          <span class="fs_14 txt_b">Inspection</span>
                        </div>
                      </div>
                      <div style="text-align: right;" class="view_snapshot_btn pointer"
                        (click)="ecarriercheck(acceptBidList[0])">
                        <button class="fs_14 txt_p fsm_5">View Safety Report</button>
                      </div>
                    </div>
                    <div class="unlock_btn" *ngIf="userType=='SHIPPER' && acceptBidList[0]?.legalName && !acceptBidList[0]?.shipperCarrierScoreMapping"
                      (click)="unlockSafetyScore(acceptBidList[0],acceptBidList[0]?.dotNumber,'unlockSafetyScore')">
                      <button><img src="\assets\icon\unlock_btn_icon.svg" class="mr-1">Unlock Safety Score</button>
                    </div>
                   
                  </div>
                  <!-- Previous_Bid -->
                  <div class="detail_card_con" *ngIf="previousBidHistory?.length > 0">
                    <p class="line_before mb-3 ">Previous Quote</p>
                    <div class="bidsInfo_con ope_50" *ngFor="let historyRecord of previousBidHistory;let j=index;">
                      <div class="bidsInfo_conT">
                        <div class="bidsInfo_conTl">
                          <span class="bids_img">
                            <img *ngIf="historyRecord?.profileImage" src="{{historyRecord?.profileImage}}"
                              class="img-fluid">
                            <span *ngIf="!historyRecord?.profileImage"
                              class="initial_name initial_r_30">{{historyRecord?.userName | acronymOfName}}</span>
                          </span>
                          <div class="conTltxt">
                            <p class="fs_16 fsb_6 txt_b mb-0 ">{{historyRecord.userName ? historyRecord.userName :
                              '-'}}
                              <span class="" *ngIf="currentId === historyRecord.actionBy">(Me)</span>
                            </p>
                            <p class="fs_12 fsn_4 txt_lb mb-0">{{historyRecord.actionTs | date: 'MMM dd, yyyy'}} at
                              {{historyRecord.actionTs | date: 'HH:mm:ss'}}</p>
                          </div>
                        </div>
                        <div class="bidsInfo_conTr">
                          <p class="fs_11 fsm_5 txt_lb mb-0 ">{{historyRecord.statusLabel | uppercase}}</p>
                          <p class="fs_16 fsb_7 txt_b mb-0">{{historyRecord.bidAmount ? (historyRecord.bidAmount |
                            currency : 'USD') :'-'}}</p>
                        </div>
                      </div>
                      <div class="bidsMess" *ngIf="historyRecord?.note">
                        <img src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-message-black.svg"
                          class="img-fluid">
                        <p class="fs_12 fsn_4 txt_b mb-0 text_truncate" matTooltip="{{historyRecord?.note}}">
                          <i>{{historyRecord?.note}}</i>
                        </p>
                      </div>
                    </div>
                  </div>
  
                  <!-- Empty state -->
                  <div *ngIf="previousBidHistory?.length === 0 && acceptBidList?.length === 0">
                    <div class="Empty_State_body">
                      <div class="Empty_State_con">
                        <div class="Empty_State_img">
                          <img class="img-fluid"
                            src="{{serverPathForUrl}}/portal/images/after-login/Empty_State_icons/graphic_no-transactions.svg ">
                        </div>
                        <div>
                          <p class="fs_24 fsb_6 txt_b pb-1" style="margin: 30px 0 12px ">No quote History...
                            <span class="txt_p"> Yet</span>
                          </p>
                          <p class="fs_16 fsn_4 txt_lb pb-1" style="margin: 0px 0 20px;">You currently dont have any Bids
                            on this Load. Assign the Load to the carriers in your network to start the Bidding
                            process. You can directly negotiate the Load rate by Accepting a quote or Re-Bidding
                            on the
                            Carriers quote price.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- skelaton Bid History-->
                <div class="skt_bidHistory" *ngIf="bidHistorySekeletonLoader">
                  <!-- Skelaton Accepted_Bid -->
                  <div class="skt_acceptBid">
                    <ngx-skeleton-loader count="1" [theme]="{ width: '88%', height: '20px'}" class="loader_mt_10"
                      style="padding: 0 24px;"></ngx-skeleton-loader>
                    <div class="skt_bgBidCard">
                      <div class="skt_bgBidCardt">
                        <div class="skt_bgBidCardtl">
                          <ngx-skeleton-loader count="1" [theme]="{ width: '40px', height: '45px'}" class="loader_mb_0">
                          </ngx-skeleton-loader>
                        </div>
                        <div class="skt_bgBidCardtr">
                          <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '20px'}" class="loader_mb_5">
                          </ngx-skeleton-loader>
                          <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '16px'}" class="loader_mb_0">
                          </ngx-skeleton-loader>
                        </div>
                      </div>
                      <div class="skt_bgBidCardb">
                        <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '16px'}" class="loader_mb_0">
                        </ngx-skeleton-loader>
                      </div>
                    </div>
                  </div>
                  <!-- Skelaton Previous Bid -->
                  <div class="skt_previousBid">
                    <ngx-skeleton-loader count="1" [theme]="{ width: '88%', height: '20px'}" class="loader_mt_10"
                      style="padding: 0 24px;"></ngx-skeleton-loader>
                    <div class="skt_bgBidCard bg_w ope_7 mb-3" style="border-bottom: 4px solid #EDEDED;">
                      <div class="skt_bgBidCardt">
                        <div class="skt_bgBidCardtl">
                          <ngx-skeleton-loader count="1" [theme]="{ width: '40px', height: '45px'}" class="loader_mb_0">
                          </ngx-skeleton-loader>
                        </div>
                        <div class="skt_bgBidCardtr">
                          <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '20px'}" class="loader_mb_5">
                          </ngx-skeleton-loader>
                          <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '16px'}" class="loader_mb_0">
                          </ngx-skeleton-loader>
                        </div>
                      </div>
                      <div class="skt_bgBidCardb">
                        <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '16px'}" class="loader_mb_0">
                        </ngx-skeleton-loader>
                      </div>
                    </div>
                    <div class="skt_bgBidCard bg_w ope_7 mb-3" style="border-bottom: 4px solid #EDEDED;">
                      <div class="skt_bgBidCardt">
                        <div class="skt_bgBidCardtl">
                          <ngx-skeleton-loader count="1" [theme]="{ width: '40px', height: '45px'}" class="loader_mb_0">
                          </ngx-skeleton-loader>
                        </div>
                        <div class="skt_bgBidCardtr">
                          <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '20px'}" class="loader_mb_5">
                          </ngx-skeleton-loader>
                          <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '16px'}" class="loader_mb_0">
                          </ngx-skeleton-loader>
                        </div>
                      </div>
                      <div class="skt_bgBidCardb">
                        <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '16px'}" class="loader_mb_0">
                        </ngx-skeleton-loader>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <!--side panel loader-->
        <div *ngIf="sidePanelLoader" class="w-100">
          <!-- skelaton header -->
          <div class="skt_shipDetailHead dark_skt">
            <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '36px'}" class="loader_mb_5">
            </ngx-skeleton-loader>
            <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '24px'}" class="loader_mb_5">
            </ngx-skeleton-loader>
  
            <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '20px'}" class="loader_mt_10 loader_mb_5">
            </ngx-skeleton-loader>
            <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '20px'}" class="loader_mb_5">
            </ngx-skeleton-loader>
  
            <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '20px'}" class="loader_mt_10 loader_mb_5">
            </ngx-skeleton-loader>
            <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '20px'}" class="loader_mb_5">
            </ngx-skeleton-loader>
  
            <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '42px'}" class="loader_mt_10 loader_mb_5">
            </ngx-skeleton-loader>
          </div>
          <!-- skelaton loader for Shipment Details -->
          <div class="skelaton_shipper_view">
            <div class="row">
              <div class="col-sm-6">
                <div class="list-view">
                  <div class="list-card-block pb-1">
                    <h3 class="mb-2">
                      <ngx-skeleton-loader count="1" [theme]="{ width: '70%', height: '22px'}"></ngx-skeleton-loader>
                    </h3>
                    <div class="list-lable-wrapper mb-1">
                      <div class="list-lable-placeholder">
                        <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '15px'}"></ngx-skeleton-loader>
                        <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '15px'}"></ngx-skeleton-loader>
                        <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '15px'}"></ngx-skeleton-loader>
                        <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '15px'}"></ngx-skeleton-loader>
                        <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '15px'}"></ngx-skeleton-loader>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="list-view">
                  <div class="list-card-block pb-1">
                    <h3 class="mb-2">
                      <ngx-skeleton-loader count="1" [theme]="{ width: '70%', height: '22px'}"></ngx-skeleton-loader>
                    </h3>
                    <div class="list-lable-wrapper mb-1">
                      <div class="list-lable-placeholder">
                        <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '15px'}"></ngx-skeleton-loader>
                        <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '15px'}"></ngx-skeleton-loader>
                        <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '15px'}"></ngx-skeleton-loader>
                        <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '15px'}"></ngx-skeleton-loader>
                        <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '15px'}"></ngx-skeleton-loader>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="list-view">
              <div class="list-card-block pb-1">
                <h3 class="mb-3">
                  <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '22px'}"></ngx-skeleton-loader>
                </h3>
                <div class="list-lable-wrapper mb-3">
                  <div class="list-lable-placeholder">
                    <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '15px'}"></ngx-skeleton-loader>
                    <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '15px'}"></ngx-skeleton-loader>
                    <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '15px'}"></ngx-skeleton-loader>
                  </div>
                  <div class="list-lable-placeholder">
                    <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '15px'}"></ngx-skeleton-loader>
                    <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '15px'}"></ngx-skeleton-loader>
                    <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '15px'}"></ngx-skeleton-loader>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="side_contaierb">
        <button class="pro_view_btn btn custum_button button_primary w-100" (click)="openTabShipment()">View Full
          Details</button>
      </div>
    </div>
  </div>
  <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px',fullScreenBackdrop:true }"></ngx-loading>