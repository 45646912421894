import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AppSettings } from 'src/app/commons/setting/app_setting';
import { PopupComponent } from 'src/app/shared/popup/popup.component';
import { SubscriptionPopupComponent } from 'src/app/shared/subscription-popup/subscription-popup.component';
import { LoadPopupComponent } from '../load-popup/load-popup.component';

@Component({
  selector: 'app-lane-card',
  templateUrl: './lane-card.component.html',
  styleUrls: ['./lane-card.component.scss']
})
export class LaneCardComponent implements OnInit {
  @Output() eventPassAction = new EventEmitter();
  @Input() sidePanelProfileDataId: any;
  @Input() laneRecord: any;
  @Input() currentStatus: any;
  @Input() subscriptionData: any;
  public skeletonLoader = true;
  public sidePanelProfileData: any;
  public userType: any;
  public bidAmount:any;
  public estimateAmount:any;
  public monthlyFrequency:any;
  
  constructor(
    private router: Router,
    public dialog: MatDialog,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.userType = localStorage.getItem('user_type')
    this.estimateAmount=this.laneRecord?.estimatedPrice?.toString()?.length;
    this.bidAmount=this.laneRecord?.bids?.bidAmount?.toString()?.length;
    this.monthlyFrequency=this.laneRecord?.monthlyFrequency;
    if (window.location.href.includes('recurring-lanes/confirmRateHistory'))  {
      localStorage.setItem('isConfirmRateUpdate', 'true');
    } else {
      localStorage.setItem('isConfirmRateUpdate', 'false');
    }
  }

  private getFullPath(route: ActivatedRoute): string {
    const segments = [];
    while (route) {
      if (route.snapshot.url.length) {
        segments.unshift(...route.snapshot.url.map(segment => segment.path));
      }
      route = route.parent;
    }
    return segments.join('/');
  }

  viewLaneBidBidding(id:any){
    const fullPath = this.getFullPath(this.route);
    this.router.navigate([fullPath+'/view/', id, 'bidding']);   
    localStorage.setItem('isConfirmRateUpdate', 'false');
  }

  viewLaneBid(id: any) {
    const fullPath = this.getFullPath(this.route);
    if (this.currentStatus === 'confirmedLanes' && this.laneRecord.status == 3) {
      this.router.navigate([fullPath+'/view/', id, 'details']);
      localStorage.setItem('isConfirmRateUpdate', 'false');
    } else if (this.currentStatus === 'confirmRateHistory') {
      this.router.navigate([fullPath+'/view/', id, 'details']);
      localStorage.setItem('isConfirmRateUpdate', 'true');
    } else if (this.currentStatus === 'archivedLanes') {
      this.router.navigate([fullPath+'/view/', id, 'details']);
      localStorage.setItem('isConfirmRateUpdate', 'false');
    } else {
      // this.router.navigate(['/recurring-lanes/view/', id, 'details']);
   }
  }

  eventAction(event: any, type: any) {
    if (this.userType ==='CARRIER'){
      if(type == 'firstLaneBid' || type == 'connectInviteLaneFixRate' || type == 'laneAcceptBidForFixRate')
      {
        const dialogRef = this.dialog.open(PopupComponent, {
          backdropClass: AppSettings.backdropClass,
          width: AppSettings.popWidth,
          disableClose: true,
          data: {
            openPop: 'equipmentNotMatch',
            contain:event
          },
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result.event == 'Ok') {
            this.eventPassAction.emit({ event, type });
          }
        });
      }
      else 
      {
        this.eventPassAction.emit({ event, type });
      }
    }
  }

  milesCeil(miles){
    return  Math.ceil(miles)
  }

  
  getSubscriptionAlertPopup(){
    const dialogRef = this.dialog.open(SubscriptionPopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      data: { 
        openPop: 'subscriptionAlert',
        subscriptionData: this.subscriptionData
       },
    });
    dialogRef.afterClosed().subscribe((result) => {
    });
  }


  openEditForm(id){
    if (this.userType == 'SHIPPER' && (this.subscriptionData?.isPremium != true || this.subscriptionData?.subscriptionStatus == 'canceled'))
    {
      this.getSubscriptionAlertPopup();
    } else {
      this.router.navigate(['recurring-lanes/edit/shipper/'+id])
    }
  }

  openCarrierRecordView(laneRecord: any){
    const fullPath = this.getFullPath(this.route);
    if (this.userType === 'CARRIER'){
      this.router.navigate([fullPath+'/view/', laneRecord.id, 'details']);
    }
    else if(this.userType === 'SHIPPER'){
      this.router.navigate([fullPath+'/view/', laneRecord.id, 'details']);
    }
  }

  openNewTabRecord(laneRecord: any,currentStatus:any) {
    const fullPath = this.getFullPath(this.route);
    let newCurrentStatus = 'details';
    const url = this.router.serializeUrl(
      this.router.createUrlTree([
        fullPath+`/view/${laneRecord.id}/${newCurrentStatus}`,
      ])
    );
    window.open(url, '_blank');
  }
 
  eventActionProfileSidePanel(event: any, type: any){
    this.sidePanelProfileData=event
    this.sidePanelProfileDataId=event?.shippers?.id
    this.eventPassAction.emit({ event, type });
  }

  closePanel(event){
    this.sidePanelProfileDataId='';
  }

  openArchived(id, isArchived) {
    if (this.userType == 'SHIPPER' && (this.subscriptionData?.isPremium != true || this.subscriptionData?.subscriptionStatus == 'canceled'))
    {
      this.getSubscriptionAlertPopup();
    } else {
    const dialogRef = this.dialog.open(LoadPopupComponent, {
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      data: {
        openPop: 'archivedLane',
        laneId: id,
        isArchived: isArchived
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'close') {
        }
        if (result && result.success == true) {
          window.location.reload();
        }
      }
    });
   }
  }

}
