<div class="create_lanes_p lane_view_details">
    <div style="position: relative;" class="container">
      <div class="btn_crt_ship">
        <span class="btn_crt" *ngIf="userType === 'SHIPPER'">
          <button type="button" *ngIf="!isArchived" class="btn" (click)="createLane()">
            <i class="fa-solid fa-plus fs_20 fs_m_5 me-2"></i>
            <span class=" fs_16 fw_500">Create New Lane</span> 
          </button>
          <button type="button" *ngIf="laneDetail?.status == 3 && !isArchived" class="btn ms-3" (click)="confirmLaneList()">
            <i class="fa-solid fa-plus fs_20 fs_m_5 me-2"></i>
            <span class=" fs_16 fw_500" >Convert Lane to Load</span>
          </button>
        </span>
      </div>
    </div>
    <div class="common_header">
      <div class="container lane_view_header">
        <p class="headdign">
          <span class="material-icons-outlined arrow_back pointer" (click)="goToList()">arrow_back</span>
          <span class="fs_21 fw_500 txt_b text-truncate">#{{laneDetail?.uniqueId}} </span>
        </p>
        <span class="btn_crt" *ngIf="userType === 'CARRIER' && !isArchived && laneDetail?.rateType =='1' && currentStatus=='laneRate'">
          <button *ngIf="!skeletonLoader" type="button"  [disabled]="laneShipperDetail?.lastBid?.status == 2" class="btn" (click)="openBidQuote()">
            <span class=" fs_16 fw_500">Quote Assistant</span> 
          </button>
        </span>
      </div>
    </div>
  
    <div class="container">
      <div class="red_strip" *ngIf="isArchived == true">This Lane is archived, all rate confirmations on this lane have been canceled.</div>
      <div class="rec_bid_card" *ngIf="!skeletonLoader">
        <div class="">
          <div class="d-flex align-items-center">
            <span  class="rec_bid_title" matTooltip="{{laneDetail?.title}}">{{laneDetail?.title ? laneDetail?.title : '-'}} </span>
            <span *ngIf="userType == 'SHIPPER' && laneDetail?.status!=3 && isArchived != true">
              <img src="{{serverPathForUrl}}/portal/images/after-login/icon-pencil.svg"
                class="img-fluid icon_pencil" (click)="openEditForm(laneDetail?.id)">
            </span>
            <div class="drop_menu dropdown dot_dropdown" *ngIf="userType == 'SHIPPER' && isArchived != true">
              <span class="dropdown-toggle" data-toggle="dropdown">
                <span class="icon-ic-dots dot_icon pointer"><i class="fa-solid fa-ellipsis"></i></span>
              </span>
              <div class="dropdown-menu ship_herd_menu">
                <span class="dropdown-item disabled_drop_btn pointer" *ngIf="laneDetail?.status!=3 && isArchived != true" (click)="openEditForm(laneDetail?.id)">Edit Lane</span>
                <span class="dropdown-item disabled_drop_btn pointer" *ngIf="!isArchived" (click)="openArchived(laneDetail?.id, true)">Archive Lane</span>
              </div>
            </div>
          </div>
          <div class="keypoints"><span>{{laneDetail?.equipmentName ? laneDetail?.equipmentName : '-'}}</span>
            <span>{{laneDetail?.miles ? milesCeil(laneDetail?.miles) : '-'}} Miles</span>
          </div>
          <div class="sc_locations ">
            <div class="sc_loca_picup">
              <span class="icon-ic-pickupq loca_iconFlag"><img
                  src="/assets/icon/new-flag-card.svg"
                  class="img-fluid "></span>
              <div class="p_loca_time">
                <p  *ngIf="((userType=='SHIPPER') || (userType=='CARRIER' && laneDetail?.status=='3' && laneDetail?.bidReceived))" class="loca_picup ">{{laneDetail?.waypoints[0]?.locationLongName ?
                  laneDetail?.waypoints[0]?.locationLongName : '-'}}</p>
                    <span class="loca_picup " *ngIf="((userType=='CARRIER' && (laneDetail?.status=='2' || laneDetail?.status=='1')) || (userType=='CARRIER' && laneDetail?.status=='3' && !laneDetail?.bidReceived))">{{(laneDetail?.waypoints[0]?.city
                      ? laneDetail?.waypoints[0]?.city : laneDetail?.waypoints[0]?.state) +", "+
                      laneDetail?.waypoints[0]?.state +", "+ laneDetail?.waypoints[0]?.countryName}}</span>
              </div>
            </div>
            <div class="sc_loca_drop">
              <span class="icon-ic-location loca_icon"><img src="/assets/icon/new-location-icon.svg" style="margin-left: 10px;"></span>
              <div class="d_loca_time">
                <p  *ngIf="((userType=='SHIPPER') || (userType=='CARRIER' && laneDetail?.status=='3' && laneDetail?.bidReceived))" class="loca_drop ">{{laneDetail?.waypoints[1]?.locationLongName ?
                  laneDetail?.waypoints[1]?.locationLongName : '-'}}</p>
                    <span  class="loca_picup " *ngIf="((userType=='CARRIER' && (laneDetail?.status=='2' || laneDetail?.status=='1')) || (userType=='CARRIER' && laneDetail?.status=='3' && !laneDetail?.bidReceived))">{{(laneDetail?.waypoints[1]?.city
                      ? laneDetail?.waypoints[1]?.city : laneDetail?.waypoints[1]?.state) +", "+ laneDetail?.waypoints[1]?.state +", "+ laneDetail?.waypoints[1]?.countryName}}</span>
              </div>
            </div>
          </div>
  
          <span class="btn_crt" *ngIf="userType == 'SHIPPER'">
            <button type="button" *ngIf="laneDetail?.status == 3 && isArchived != true" class="btn d-flex" (click)="createLaneByShipment()">
              <i class="fa-solid fa-plus fs_20 fs_m_5 me-2"></i>
              <span class=" fs_16 fw_500" >Create Load using this Lane</span>
            </button>
            <button type="button" *ngIf="isArchived == true" class="btn d-flex" (click)="openCreateLaneForm(laneDetail?.id)">
              <i class="fa-solid fa-plus fs_20 fs_m_5 me-2"></i>
              <span class=" fs_16 fw_500" >Create lane by using this Lane</span>
            </button>
          </span>
        </div>
        <div class="blue_box" *ngIf="userType == 'SHIPPER' && currentStatus == 'bidding'">
          <p *ngIf="userType == 'SHIPPER'">Quotes <br> Received</p>
          <p style="font-size: 30px; margin: 0px;">{{laneDetail?.bidReceived ? laneDetail?.bidReceived : '-'}}</p>
        </div>
        <div class="blue_box" *ngIf="userType == 'SHIPPER' && currentStatus == 'booked'">
          <p *ngIf="userType == 'SHIPPER'">Quotes <br> Confirmed</p>
          <p style="font-size: 30px; margin: 0px;">{{totalLanes ? totalLanes : '-'}}</p>
        </div>
        <div class="blue_box" *ngIf="userType == 'SHIPPER' && currentStatus == 'confirmedLoads'">
          <p *ngIf="userType == 'SHIPPER'">Quotes <br> Confirmed</p>
          <p style="font-size: 30px; margin: 0px;">{{totalLanes ? totalLanes : '-'}}</p>
        </div>
      </div>
  
      <div class="rec_bid_card_skeleton" *ngIf="skeletonLoader">
        <div class="skelaton_part border_l w-50" *ngFor="let fake of generateFake(2)">
          <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '150px'}"
            class="loader_m_0"></ngx-skeleton-loader>
        </div>
      </div>
    </div>
  
    <div>
      <div class="ship_avance_src" *ngIf="!skeletonLoader && (currentStatus == 'bidding' || currentStatus == 'booked' || currentStatus == 'confirmedLoads')">
        <div class="ship_avance">
          <div class="advance_src">
            <div class="user_search">
              <div class="srch_top network_src src_cancel_icons m-0">
                <mat-form-field appearance="none">
                  <input matInput placeholder="Search" maxlength="64" [(ngModel)]="searchModel.title" #title="ngModel"
                    [ngModelOptions]="{standalone: true}" (keyup)="searchQuantity($event)" autocomplete="off"
                    class="custom_input">
                </mat-form-field>
              </div>
            </div>
            <div class="search_action" (click)="applyFilterSearch()">
              <img src="{{serverPathForUrl}}/portal/images/search-filter-icon/filter_seach_icon.svg">
            </div>
          </div>

          <div class="advance_filter" *ngIf="currentStatus !== 'confirmedLoads'" (click)="showFilterSort()">
            <span class="advance_filter_icon">
              <img src="{{serverPathForUrl}}/portal/icon/active_load_filtter_icon.svg"
                class="img-fluid">
            </span>
          </div>
        </div>
      </div>
      <div class="fil_pop">
        <div class="filtter_popup" *ngIf="showFilter">
          <div class="txt_p fs_14 fw_600">Sort By</div>
          <div class="filtter_check_box">
            <mat-checkbox value="bid_amount" [(ngModel)]="pricecheck" (change)="sorting($event, 'bid_amount')"> Rate - Lowest to
              Highest </mat-checkbox>
            <mat-checkbox value="rating" [(ngModel)]="ratecheck" (change)="sorting($event, 'rating')"> Rating - Highest
              to Lowest</mat-checkbox>
          </div>
          <div class="apl_btn">
            <button (click)="sortedList()">Apply</button>
          </div>
        </div>
      </div>
    </div> 
    <app-load-view-filter [carrierNameListShow]="carrierNameListShow" (clearOneFilter)="singleFilterClose($event)" class="mt-4"></app-load-view-filter>
  
      <mat-tab-group class="cus_matTab view_bids_tabs" [selectedIndex]="selectedIndex" (click)="onTabChanged($event)" animationDuration="0ms" id="ship_car_tabs" >
        <mat-tab label="Carriers Quotes" *ngIf="userType == 'SHIPPER'">
          <div class=" fw_500 txt_lb mt-4 text-end" *ngIf="totalLanes > 10">Showing 10 out of {{totalLanes}}</div>
          <div *ngIf="!skeletonLoader && carrierlanesList?.length > 0">
              <div *ngFor="let laneBidRecord of carrierlanesList">
                <app-lane-carrier-list [laneBidRecord]="laneBidRecord" [isArchived]="isArchived" [currentRouteParams]="currentRouteParams" [subscriptionData]="subscriptionData"
                [currentStatus]="currentStatus" (eventPassAction)="eventAction($event)"></app-lane-carrier-list>
              </div>
          </div>
          <div class="row mx-auto" *ngIf="!skeletonLoader && carrierlanesList?.length === 0">
            <div class="col-md-12">
                <app-load-empty-screen [currentStatus]="currentStatus" [currentSearchStatus]="currentSearchStatus"></app-load-empty-screen>
            </div>
          </div>
          <div class="profile_skelaton mt_30" *ngIf="skeletonLoader">
            <div class="skelaton_part border_l w-25" *ngFor="let fake of generateFake(4)">
              <ngx-skeleton-loader count="1" [theme]="{ width: '70%', height: '40px'}"
                class="loader_m_0"></ngx-skeleton-loader>
            </div>
          </div>
        </mat-tab>
  
        <mat-tab label="Summary"> <app-lane-summary></app-lane-summary></mat-tab>
        <mat-tab label="Details"> 
          <app-lane-detail [laneDetail]="laneDetail" [currentStatus]="currentStatus"></app-lane-detail>
        </mat-tab>
  
        <mat-tab label="Lane Rate" *ngIf="userType == 'CARRIER'">
          <div *ngIf="!skeletonLoader && laneShipperDetail">
            <app-lane-shipper-list [currentStatus]="currentStatus" [isArchived]="isArchived" [laneDetail]="laneDetail" [laneShipperDetail]="laneShipperDetail" [currentRouteParams]="currentRouteParams"
            (eventPassAction)="eventAction($event)"></app-lane-shipper-list>
          </div> 
          <div class="profile_skelaton mt_30 me-3 ms-3" *ngIf="skeletonLoader">
            <div class="skelaton_part border_l w-25" *ngFor="let fake of generateFake(4)">
            <ngx-skeleton-loader count="1" [theme]="{ width: '70%', height: '40px'}"
                class="loader_m_0"></ngx-skeleton-loader>
            </div>
          </div>
        </mat-tab>
  
        <mat-tab label="Confirmed Rates" *ngIf="userType == 'SHIPPER'">
          <div class=" fw_500 txt_lb mt-4 text-end" *ngIf="totalLanes > 10"><span>Showing 10 out of</span> {{totalLanes}}</div>
          <div *ngIf="!skeletonLoader && carrierlanesList?.length > 0">
              <div *ngFor="let laneBidRecord of carrierlanesList">
                <app-lane-carrier-list [laneBidRecord]="laneBidRecord" [currentRouteParams]="currentRouteParams" [subscriptionData]="subscriptionData"
                [currentStatus]="currentStatus" (eventPassAction)="eventAction($event)"></app-lane-carrier-list>
              </div>
          </div>
          <div class="row mx-auto" *ngIf="!skeletonLoader && carrierlanesList?.length === 0">
            <div class="col-md-12">
                <app-load-empty-screen [currentStatus]="currentStatus" [currentSearchStatus]="currentSearchStatus"></app-load-empty-screen>
            </div>
          </div>
          <div class="profile_skelaton mt_30" *ngIf="skeletonLoader">
            <div class="skelaton_part border_l w-25" *ngFor="let fake of generateFake(4)">
              <ngx-skeleton-loader count="1" [theme]="{ width: '70%', height: '40px'}"
                class="loader_m_0"></ngx-skeleton-loader>
            </div>
          </div>
        </mat-tab> 

        <mat-tab label="Confirmed Loads" *ngIf="userType == 'SHIPPER' && isConfirmRateUpdate == 'true'">  
          <div class=" fw_500 txt_lb mt-4 text-end" *ngIf="totalLanes > 10"><span>Showing 10 out of</span> {{totalLanes}}</div>
          <div *ngIf="!skeletonLoader && laneShipperLoadConfirmedDetail?.length > 0">
              <div *ngFor="let laneBidRecord of laneShipperLoadConfirmedDetail">
                <app-confirmed-load-list [laneBidRecord]="laneBidRecord" [currentRouteParams]="currentRouteParams" [subscriptionData]="subscriptionData"
                [currentStatus]="currentStatus" (eventPassAction)="eventAction($event)"></app-confirmed-load-list>
              </div>
          </div>
          <div class="row mx-auto" *ngIf="!skeletonLoader && laneShipperLoadConfirmedDetail?.length === 0">
            <div class="col-md-12">
                <app-load-empty-screen [currentStatus]="currentStatus" [currentSearchStatus]="currentSearchStatus"></app-load-empty-screen>
            </div>
          </div>
          <div class="profile_skelaton mt_30" *ngIf="skeletonLoader">
            <div class="skelaton_part border_l w-25" *ngFor="let fake of generateFake(4)">
              <ngx-skeleton-loader count="1" [theme]="{ width: '70%', height: '40px'}"
                class="loader_m_0"></ngx-skeleton-loader>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
      
      <div class="content_loader mt-3 mb-4" *ngIf="carrierlanesList?.length > 0 && spinnerLoader">
        <span class="loader_image">
          <img src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/loader-blue.svg" class="img-fluid">
        </span>
      </div>
  </div>
  
  