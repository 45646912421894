export const environment = {
  production: true,
  pointType:"uat",
  laneAxisDotCom: 'https://sandbox.laneaxis.com',
  apiEndpoint: 'https://api-uat.laneaxis.com',
  domainPoint: 'https://uat-network.laneaxis.com',
  searchRestriction: ['us'],
  searchMultipleRestriction: ['us', 'ca', 'mx'],
  stripePublicKey: 'pk_test_jqNFapP28xsD2U7ZWC4sH1Bl00n5lthUOE',
  heremapapiKey: 'iQVSw9yPlsVLkIIsqZu5VpZYDRF7V0PHyofw0fUUMo0',
  googleAnalyticsKey: 'G-4Z4JHTML9X',
  paymentApiEndpoint: 'https://payapi-uat.laneaxis.com',
  axisTokanApiEndpoint: 'https://axispayapi-uat.laneaxis.com',
  magicLinkPublicKey: 'pk_live_856CED3E4F6736FD',
  imagePathUrl: 'https://s3.us-east-2.amazonaws.com/staticdev.laneaxis.com',
  inviteLink: 'https://onelink.to/laneaxis-inc/',
  serverPathForUrl: 'https://staticprod.laneaxis.com',
  firebase: {
    apiKey: 'AIzaSyAk9K2J8YYFttAUxQPVewn-x1-hmtSwtUQ',
    authDomain: 'laneaxis-prod.firebaseapp.com',
    databaseURL: 'https://laneaxis-network-uat.firebaseio.com',
    projectId: 'laneaxis-prod',
    storageBucket: 'laneaxis-prod.appspot.com',
    messagingSenderId: '697419055463',
    appId: '1:697419055463:web:922aa36f18f2ab13866fc2',
    measurementId: 'G-WW8Y664DNQ',
  },
};