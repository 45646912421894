<div class="text-center feed_back_popup">
  <form [formGroup]="feedbackForm" (ngSubmit)="feedbackSubmit(feedbackForm)" class="mb-0 custum_input_css">
    <div class="header_popup mb-4">
      <p class="fs_20 fsm_5 txt_b line_before ">Please Add your Feedback</p>
      <span class="close_popup_Icon" (click)="onNoClick()">
        <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
      </span>
    </div>
    <div mat-dialog-content class="feedback_input" id="feedback_input">
      <mat-form-field appearance="none">
        <textarea matInput maxLength="256" formControlName="description" type="text" class="form-control custom_input"
          placeholder="Enter your Feedback here" style="background: var(--clBluh); min-height: 100px; padding: 0.9rem">
        </textarea>
        <mat-error *ngIf="feedbackForm.get('description').errors?.['required'] && submitted"
          class="error_mess">Feedback is required.</mat-error>
        <mat-error *ngIf="feedbackForm.get('description').errors?.['maxlength']" 
          class="error_mess">Feedback must not exceed 255 characters.</mat-error>
      </mat-form-field>
    </div>
    <div class="mt-3">
      <button [disabled]="disabledButton" type="submit" class="btn custum_button button_primary w-100 fs_16 fsn_4 boxS_0">
        <div *ngIf="disabledButton" class="spinner-border spinner_border txt_w" role="status">
          <span class="sr-only"><!-- Loading... --></span>
        </div>
        <span class="">Send Feedback</span>
      </button>
      <!-- w-100 -->
      <button type="button" class="btn custum_button txt_lb boxS_0 bg_w mb-0 mt-2" (click)="onNoClick()">Cancel</button>
    </div>
  </form>
</div>
`
