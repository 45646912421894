<div class="accountNot_connected creat_loads_con">
  <div class="shipmetList_con">
    <div class="create_shipment_list_body">
      <div class="container p-0">
        <div class="shipment_list_container cus_matTab {{currentStatus}}">
        <div class="ship_avance_src">
          <div class="ship_advance_src_body">
            <div class="advance_src">
              <div class="user_search">
                <div class="srch_top network_src src_cancel_icons m-0">
                  <mat-form-field appearance="none">
                    <input matInput placeholder="Search" maxlength="64" [(ngModel)]="searchModel.title" #title="ngModel" [ngModelOptions]="{standalone: true}" (keyup)="searchQuantity($event)" autocomplete="off" class="custom_input">
                  </mat-form-field>
                </div>
              </div>
              <div class="search_action" (click)="applyFilterSearch()">
                <img class="icon-ic-search" src="{{serverPathForUrl}}/portal/images/search-filter-icon/filter_seach_icon.svg">
              </div>
            </div>
            <div class="advance_filter" (click)="advanceFilter()">
              <span class="advance_filter_icon">
                <img src="{{serverPathForUrl}}/portal/icon/active_load_filtter_icon.svg" class="img-fluid">
              </span>
            </div>
          </div>
        </div>
        <div class="shipment_list_container cus_matTab">
          <div class="ship_avance_src">
            <div class="label_one">
              <div class="second_label_tab">
                <div class="tabLabels">
                   <div *ngIf="!skeletonLoader" class="mobile_tabs">
                    <ngx-skeleton-loader [theme]="{ width: '87px', height: '14px' }" class="loader_m_0 skt_btn_loader tabLabel mb-2"></ngx-skeleton-loader>
                    <ngx-skeleton-loader [theme]="{ width: '87px', height: '14px' }" class="loader_m_0 skt_btn_loader tabLabel mb-2"></ngx-skeleton-loader>
                    <ngx-skeleton-loader [theme]="{ width: '87px', height: '14px' }" class="loader_m_0 skt_btn_loader tabLabel mb-2"></ngx-skeleton-loader>
                   </div>
                  <div *ngIf="skeletonLoader" class="mobile_tabs">
                    <span class="tabLabel" (click)="subTabMenuFilter('allLoads')" [ngClass]="(currentStatus === 'allLoads' || currentStatus === 'quotedLoads') ? 'tabActive' : ''">
                      <span *ngIf="userType=='SHIPPER'">Posted Loads</span>
                      <span *ngIf="userType=='CARRIER'">Available Loads</span>
                      <span *ngIf="tabCount"> ({{tabCount.allLoads + tabCount.quotedLoads}})</span>
                    </span>
                    <span class="tabLabel" *ngIf="userType=='SHIPPER'"  (click)="subTabMenuFilter('paymentPending')" [ngClass]="currentStatus === 'paymentPending' ? 'tabActive' : ''">
                      <span>Load Confirmed - Awaiting Payment</span>
                       <span *ngIf="tabCount"> ({{tabCount.paymentPending}})</span>
                    </span>
                    
                    <span class="tabLabel" *ngIf="userType=='CARRIER'" (click)="subTabMenuFilter('awardedLoads')" [ngClass]="currentStatus === 'awardedLoads' ? 'tabActive' : ''">
                      <span>Awarded Loads</span>
                       <span *ngIf="tabCount"> ({{tabCount.awardedLoads}})</span>
                    </span>
                    
                    <span class="tabLabel" *ngIf="userType=='CARRIER'" (click)="subTabMenuFilter('paymentPending')" [ngClass]="currentStatus === 'paymentPending' ? 'tabActive' : ''">
                      <span>Shipper to Confirm Payment</span>
                       <span *ngIf="tabCount"> ({{tabCount.paymentPending}})</span>
                    </span>
                  
                    <span class="tabLabel"  *ngIf="userType=='CARRIER'" (click)="subTabMenuFilter('loadsLost')" [ngClass]="currentStatus === 'loadsLost' ? 'tabActive' : ''">
                      <span>Loads Lost </span>
                       <span *ngIf="tabCount"> ({{tabCount.loadsLost }})</span>
                    </span>
               
                    <span class="tabLabel" *ngIf="userType=='SHIPPER'" (click)="subTabMenuFilter('expiredLoads')" [ngClass]="currentStatus === 'expiredLoads' ? 'tabActive' : ''">
                       <span>Load Expired</span>
                       <span *ngIf="tabCount"> ({{tabCount.expiredLoads}})</span>
                     </span>
                   </div>
                </div>
              </div>
                  
             <div class="upcomming_sub_tabs" *ngIf="currentStatus === 'allLoads' || currentStatus === 'quotedLoads'">
               <div *ngIf="!skeletonLoader">
                 <ngx-skeleton-loader [theme]="{ width: '87px', height: '14px' }" class="loader_m_0 skt_btn_loader mb-2"></ngx-skeleton-loader>
                 <ngx-skeleton-loader [theme]="{ width: '87px', height: '14px' }" class="loader_m_0 skt_btn_loader mb-2 ms-3"></ngx-skeleton-loader>
               </div>
              <div *ngIf="skeletonLoader">
               <span class="sub_tabs" (click)="subTabMenuFilter('allLoads')" [ngClass]="currentStatus === 'allLoads' ? 'active_sub_tabs' : ''">
                 <span>All Loads</span>
                 <span> ({{allLoadSpotCount ? allLoadSpotCount : '0'}})</span>
               </span>
  
               <span class="sub_tabs" (click)="subTabMenuFilter('quotedLoads')" [ngClass]="currentStatus === 'quotedLoads' ? 'active_sub_tabs' : ''">
                 <span>Quoted Loads</span>
                 <span>({{quotedLoadsCount ? quotedLoadsCount : '0'}})</span>
               </span>
              </div>
             </div>
             <app-load-view-filter [titleFilter]="titleFilter" [title]="title" [equipmentNameShow]="equipmentNameShow" (clearOneFilter)="singleFilterClose($event)"></app-load-view-filter>

            <div class="container-fluid p_20"  *ngIf="skeletonLoader && createdLoadList?.length > 0" infiniteScroll [infiniteScrollDistance]="3" [infiniteScrollThrottle]="100" 
              (scrolled)="onScroll()" [scrollWindow]="false" style="overflow-y: auto; height: 82vh;">
              <div class="row jj" style="padding: 0px 10px;">
                <div class="col-md-3" *ngFor="let sportRateLoadCreated of createdLoadList">
                  <app-spot-loads-card [shipmentRecord]="sportRateLoadCreated" [subscriptionData]="subscriptionData"
                    (eventPassAction)="eventAction($event)" [connectStripe]="connectStripe" [currentStatus]="currentStatus"
                    [currentRouteParams]="currentRouteParams" class="create_loads_empty_screen"></app-spot-loads-card>
                </div>
              </div>
            </div>
            <div *ngIf="!skeletonLoader && createdLoadList?.length === 0">
              <div class="row mx-auto">
                <div class="col-lg-3 col-md-6" *ngFor="let fake of generateFake(4)">
                  <app-load-skeleton-card></app-load-skeleton-card>
                </div>
              </div>
            </div>
            <div class="row mx-auto" *ngIf="skeletonLoader && createdLoadList?.length === 0">
              <div class="col-md-12">
                <app-load-empty-screen [currentStatus]="currentStatus"
                  [currentSearchStatus]="currentSearchStatus"></app-load-empty-screen>
              </div>
            </div>
          <!-- spinner loader -->
          <div class="content_loader mt-3 mb-4" *ngIf="createdLoadList?.length > 0 && spinnerLoader">
            <span class="loader_image">
              <img src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/loader-blue.svg" class="img-fluid">
            </span>
          </div>
          <div *ngIf="createdLoadList?.length > 0 && dataNotFound && totalPage > 1" class="noMore_record">
            <span>There are no more records to show.</span>
          </div>
        </div>
      </div>
     </div>
      <div class="net_filter_popup" *ngIf="showAdvancedFilter">
        <div>
          <div class="container filtr_top_arrow">
            <div class="custum_input_css" style="margin: 0 0 12px;">
              <div class="row">
                <div class="">
                  <div style="margin: 0 35px 0 0;">
                    <div class="row">
                      <div class="col-md-6 ic_dropdown">
                        <p class="fs_14 fsn_4 txt_lb mb-2">Equipment Type</p>
                        <mat-form-field appearance="none" class="removeappearance" class="drop_bg">
                          <mat-select placeholder="Equipment Type" class="form-control custom_input" [(ngModel)]="searchModel.equipmentId" #equipmentId="ngModel" [ngModelOptions]="{standalone: true}">
                            <mat-option (click)="equipmentEmpty()">Equipment Type</mat-option>
                            <mat-option *ngFor="let equip of equipmentListing" [value]="equip.id" (click)="equipmentSelected(equip)">{{equip.label}}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div style="margin: 0 0 0 35px;">
                    <div class="row">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="net_filter_action m-0">
            <div class="container">
              <div class="row">
                <div class="action_btn">
                  <span class="txt_p fs_16 fsm_5" style="opacity: .5;" *ngIf="filterCheck">Clear All</span>
                  <span class="pointer txt_p fs_16 fsm_5 " *ngIf="!filterCheck" (click)="clearFilter()">Clear All</span>
                  <div>
                    <span (click)="closeFilterPopup()" class="cancel_btn pointer">Cancel</span>
                    <button type="button" class="btn custum_button button_success" (click)="applyFilterSubmit()">Apply</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="" style="position: relative;" *ngIf="sidePanelData && (userType=='CARRIER' || currentStatus=='expiredLoads' || currentStatus=='expiredLoadsByLane')">
      <div class="side-show-panel">
        <div class="side_bar_con">
          <app-spot-loads-side-panel [shipmentDetail]="sidePanelData" [currentStatus]="currentStatus" [currentRouteParams]="currentRouteParams" [subscriptionData]="subscriptionData" (closeSidePanel)="closePanel($event)" (refreshList)="refreshListLoad()"></app-spot-loads-side-panel>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px',fullScreenBackdrop:true }"></ngx-loading>
