
export class StatusSetting {
  public countryList = ['US', 'CA', 'MX'];
  public static statusList = [
    {
      id: 3,
      name: 'Available',
      type: 'Inactive',
      colorClass: 'stats_available',
    },
    { id: 6, name: 'Active', type: 'Inactive', colorClass: 'stats_inTransit' },
    { id: 1, name: 'Invited', type: 'Inactive', colorClass: 'stats_invited' },
    { id: 2, name: 'Suspended', type: 'Inactive', colorClass: 'stats_suspend' },
  ];
  public static shipmentStatusList = [
    { id: 1, name: 'In Progress', slugName: 'inProgressByLoads', type: 'active' },
    { id: 2, name: 'upcoming', slugName: 'upcomingByLoads', type: 'Inactive' },
    { id: 3, name: 'Past', slugName: 'pastByLoads', type: 'Inactive' },
    { id: 4, name: 'Pending', slugName: 'pendingByLoads', type: 'Inactive' },
    { id: 5, name: 'In Progress', slugName: 'inProgress', type: 'active' },
    { id: 6, name: 'upcoming', slugName: 'upcoming', type: 'Inactive' },
    { id: 7, name: 'Past', slugName: 'past', type: 'Inactive' },
    { id: 8, name: 'Pending', slugName: 'pending', type: 'Inactive' },
    { id: 9, name: 'In Progress', slugName: 'inProgressByLane', type: 'active' },
    { id: 10, name: 'upcoming', slugName: 'upcomingByLane', type: 'Inactive' },
    { id: 11, name: 'Past', slugName: 'pastByLane', type: 'Inactive' },
    { id: 12, name: 'Pending', slugName: 'pendingByLane', type: 'Inactive' },
    {
      id: 13,
      name: 'Shipment & Bids',
      slugName: 'shipmentAndBids',
      type: 'Inactive',
    },
  ];
  public static shipmentDriverStatus = ['accept', 'navigateToPickup'];
  public static shipmentPickupStatus = [
    'atPickupLocation',
    'navigateToPickup',
    'shipmentLoading',
    'shipmentLoaded',
  ];
  public static shipmentDropOffStatus = [
    'atDropOffLocation',
    'navigateToDropOff',
    'shipmentUnloading',
    'shipmentUnloaded',
    'delivered',
  ];

  public static emptyScreenStatusCarrier = [
    {
      slug: 'inProgressByLoads',
      title: "No In-Transit <span class='txt_p '>Loads</span>",
      description: 'You can view all in-transit Loads here.',
      image: 'portal/images/after-login/Empty_State_icons/gr-no-shipment.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'loadsLostByLane',
      title: "Loads  <span class='txt_p '>Lost</span>",
      description:
        'When any carrier invites you to quote on a load, you can view all confirmed Carrier quotes and load details here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-available-loads.svg',
      buttonText: 'Visit the Network',
      buttonURL: '/network/my-network/connected',
      status: '1',
    },
    {
      slug: 'allLanes',
      title: "No Lanes <span class='txt_p '>All Lanes</span>",
      description: 'All Lanes available for quotes will appear here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-available-loads.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'quotedLanes',
      title: "No Lanes <span class='txt_p '>Quoted Lanes</span>",
      description: 'Quoted Lanes available for quotes will appear here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-available-loads.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'inProgress',
      title: "No In-Transit <span class='txt_p '>Loads</span>",
      description: 'You can view all in-transit Loads here.',
      image: 'portal/images/after-login/Empty_State_icons/gr-no-shipment.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'inProgressByLane',
      title: "No In-Transit <span class='txt_p '>Loads</span>",
      description: 'You can view all in-transit Loads here.',
      image: 'portal/images/after-login/Empty_State_icons/gr-no-shipment.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'upcomingByLoads',
      title: "No Confirmed <span class='txt_p '>Loads</span>",
      description: 'You can view all your confirmed Loads here.',
      image: 'portal/images/after-login/Empty_State_icons/gr-no-shipment.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'upcoming',
      title: "No Confirmed <span class='txt_p '>Loads</span>",
      description: 'You can view all your confirmed Loads here.',
      image: 'portal/images/after-login/Empty_State_icons/gr-no-shipment.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'upcomingByLane',
      title: "No Confirmed <span class='txt_p '>Loads</span>",
      description: 'You can view all your confirmed Loads here.',
      image: 'portal/images/after-login/Empty_State_icons/gr-no-shipment.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'pendingByLoads',
      title: "No Awaiting <span class='txt_p '>Loads</span>",
      description: 'You can view all your awaiting Loads here.',
      image: 'portal/images/after-login/Empty_State_icons/gr-no-shipment.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'pending',
      title: "No Awaiting <span class='txt_p '>Loads</span>",
      description: 'You can view all your awaiting Loads here.',
      image: 'portal/images/after-login/Empty_State_icons/gr-no-shipment.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'pendingByLane',
      title: "No Awaiting <span class='txt_p '>Loads</span>",
      description: 'You can view all your awaiting Loads here.',
      image: 'portal/images/after-login/Empty_State_icons/gr-no-shipment.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'pastByLoads',
      title: "No Completed <span class='txt_p '>Loads</span>",
      description: 'You can view all your completed Loads here.',
      image: 'portal/images/after-login/Empty_State_icons/gr-no-shipment.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'past',
      title: "No Completed <span class='txt_p '>Loads</span>",
      description: 'You can view all your completed Loads here.',
      image: 'portal/images/after-login/Empty_State_icons/gr-no-shipment.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'pastByLane',
      title: "No Completed <span class='txt_p '>Loads</span>",
      description: 'You can view all your completed Loads here.',
      image: 'portal/images/after-login/Empty_State_icons/gr-no-shipment.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'expired',
      title: "No Expired <span class='txt_p '>Loads</span>",
      description: 'You can view all your expired Loads here.',
      image: 'portal/images/after-login/Empty_State_icons/gr-no-shipment.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'availableToBid',
      title: "No Loads <span class='txt_p '>Available</span>",
      description: 'All Loads available for quotes will appear here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-available-loads.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'currentBids',
      title: "No Current <span class='txt_p '>Quotes</span>",
      description: 'You can view all current quotes activity here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-available-loads.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'awardedBids',
      title: "No Awarded <span class='txt_p '>Quotes</span>",
      description: 'You can view all awarded quotes activity here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-available-loads.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'nonAwardedBids',
      title: "No Non-Awarded <span class='txt_p '>Quotes</span>",
      description: 'You can view all non-awarded Quotes activity here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-available-loads.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'confirmedBids',
      title: "Confirm <span class='txt_p '>Quotes</span>",
      description:
        'When any carrier invites you to quote on a load, you can view all confirmed Carrier quotes and load details here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-available-loads.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'offerRate',
      title: "Shipper Offer <span class='txt_p '>Price</span>",
      description:
        'When any carrier invites you to quote on a load, you can view all offer rate Carrier quotes and load details here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-available-loads.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'offerRateConfirmed',
      title: "Offer Rate <span class='txt_p '>Confirmed</span>",
      description:
        'When any carrier invites you to quote on a load, you can view all offer rate Carrier quotes and load details here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-available-loads.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'dispute',
      title: "No More <span class='txt_p '>Dispute</span>",
      description:
        'When a dispute is raised on any shipment by you or shipper, it will appear here.',
      image: 'portal/images/after-login/Empty_State_icons/gr-no-dispute.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'newDispute',
      title: "No New <span class='txt_p '>Disputes</span>",
      description:
        'When a dispute is raised on any shipment by you or shipper, it will appear here.',
      image: 'portal/images/after-login/Empty_State_icons/gr-no-dispute.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'ongoingDispute',
      title: "No Active <span class='txt_p '>Disputes</span>",
      description:
        'If you have any active disputes regarding an existing shipment you can view the details here.',
      image: 'portal/images/after-login/Empty_State_icons/gr-no-dispute.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'resolvedDispute',
      title: "No Resolved <span class='txt_p '>Disputes</span>",
      description: 'All past disputes and their outcomes can be viewed here.',
      image: 'portal/images/after-login/Empty_State_icons/gr-no-dispute.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'shipmentAndBids',
      title: "No Active <span class='txt_p '>Quotes</span>",
      description:
        'When Quote is ongoing on any shipment, it will appear here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-shipment-bidding.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'postedLanes',
      title: "No Current <span class='txt_p '>Posted Lanes</span>",
      description:
        'When any carrier invites you to quote on a lane, you can view all current Carrier quotes and lane details here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-shipment-bidding.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'allLanes',
      title: "No Lanes <span class='txt_p '>All Lanes</span>",
      description: 'All Lanes available for quotes will appear here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-available-loads.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'quotedLanes',
      title: "No Lanes <span class='txt_p '>Quoted Lanes</span>",
      description: 'Quoted Lanes available for quotes will appear here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-available-loads.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'confirmedLanes',
      title: "No Confirmed <span class='txt_p '>Quotes</span>",
      description:
        'When any carrier invites you to quote on a load, you can view all confirmed Carrier quotes and load details here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-available-loads.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'allLoads',
      title: "All <span class='txt_p '>Loads</span>",
      description:
        'When any carrier invites you to quote on a load, you can view all confirmed Carrier quotes and load details here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-available-loads.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'allLoadsByLane',
      title: "All <span class='txt_p '>Loads</span>",
      description:
        'When any carrier invites you to quote on a load, you can view all confirmed Carrier quotes and load details here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-available-loads.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'quotedLoadsByLane',
      title: "All <span class='txt_p '>Loads</span>",
      description:
        'When any carrier invites you to quote on a load, you can view all confirmed Carrier quotes and load details here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-available-loads.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'awardedLoads',
      title: "Awarded <span class='txt_p '>Loads</span>",
      description:
        'When any carrier invites you to quote on a load, you can view all confirmed Carrier quotes and load details here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-available-loads.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'loadsAwardedByLane',
      title: "Awarded <span class='txt_p '>Loads</span>",
      description:
        'When any carrier invites you to quote on a load, you can view all confirmed Carrier quotes and load details here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-available-loads.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'paymentPending',
      title: "Payment <span class='txt_p'>Pending</span>",
      description:
        'When any carrier invites you to quote on a load, you can view all confirmed Carrier quotes and load details here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-available-loads.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'loadsLost',
      title: "Load <span class='txt_p'>Lost</span>",
      description:
        'When any carrier invites you to quote on a load, you can view all confirmed Carrier quotes and load details here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-available-loads.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'quotedLoads',
      title: "Qouted <span class='txt_p '>Loads</span>",
      description:
        'When any carrier invites you to quote on a load, you can view all confirmed Carrier quotes and load details here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-available-loads.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    
    {
      slug: 'search',
      title: 'No results found…',
      description:
        'Your search returns no results. Please update your search criteria.',
      image: 'portal/images/after-login/cl-icons/gr-no-search-results.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
  ];

  public static emptyScreenStatusShipper = [
    {
      slug: 'inProgressByLoads',
      title: "No In-Transit <span class='txt_p '>Loads</span>",
      description: 'You can view all in-transit Loads here.',
      image: 'portal/images/after-login/Empty_State_icons/gr-no-shipment.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'inProgressByLane',
      title: "No In-Transit <span class='txt_p '>Loads</span>",
      description: 'You can view all in-transit Loads here.',
      image: 'portal/images/after-login/Empty_State_icons/gr-no-shipment.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'upcomingByLoads',
      title: "No Confirmed <span class='txt_p '>Loads</span>",
      description: 'You can view all your confirmed Loads here.',
      image: 'portal/images/after-login/Empty_State_icons/gr-no-shipment.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'upcomingByLane',
      title: "No Confirmed <span class='txt_p '>Loads</span>",
      description: 'You can view all your confirmed Loads here.',
      image: 'portal/images/after-login/Empty_State_icons/gr-no-shipment.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'pendingByLoads',
      title: "No Awaiting <span class='txt_p '>Loads</span>",
      description: 'You can view all your awaiting Loads here.',
      image: 'portal/images/after-login/Empty_State_icons/gr-no-shipment.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'allLanes',
      title: "No Lanes <span class='txt_p '>All Lanes</span>",
      description: 'All Lanes available for quotes will appear here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-available-loads.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'quotedLanes',
      title: "No Lanes <span class='txt_p '>Quoted Lanes</span>",
      description: 'Quoted Lanes available for quotes will appear here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-available-loads.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'pendingByLane',
      title: "No Awaiting <span class='txt_p '>Loads</span>",
      description: 'You can view all your awaiting Loads here.',
      image: 'portal/images/after-login/Empty_State_icons/gr-no-shipment.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'pastByLoads',
      title: "No Completed <span class='txt_p '>Loads</span>",
      description: 'You can view all your completed Loads here.',
      image: 'portal/images/after-login/Empty_State_icons/gr-no-shipment.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'pastByLane',
      title: "No Completed <span class='txt_p '>Loads</span>",
      description: 'You can view all your completed Loads here.',
      image: 'portal/images/after-login/Empty_State_icons/gr-no-shipment.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'expired',
      title: "No Expired <span class='txt_p '>Loads</span>",
      description: 'You can view all your expired Loads here.',
      image: 'portal/images/after-login/Empty_State_icons/gr-no-shipment.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'currentBids',
      title: "No Current <span class='txt_p '>Quotes</span>",
      description:
        'When any carrier invites you to quote on a load, you can view all current Carrier quotes and load details here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-available-loads.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'confirmedBids',
      title: "No Confirmed <span class='txt_p '>Quotes</span>",
      description:
        'When any carrier invites you to quote on a load, you can view all confirmed Carrier quotes and load details here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-available-loads.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },

    {
      slug: 'offerRate',
      title: "Offer <span class='txt_p '>Rate</span>",
      description:
        'When any carrier invites you to quote on a load, you can view all offer rate Carrier quotes and load details here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-available-loads.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'offerRateConfirmed',
      title: "Offer Rate <span class='txt_p '>Confirmed</span>",
      description:
        'When any carrier invites you to quote on a load, you can view all offer rate Carrier quotes and load details here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-available-loads.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },

    {
      slug: 'dispute',
      title: "No More <span class='txt_p '>Dispute</span>",
      description:
        'When a dispute is raised on any shipment by you or carrier, it will appear here.',
      image: 'portal/images/after-login/Empty_State_icons/gr-no-dispute.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'newDispute',
      title: "No New <span class='txt_p '>Disputes</span>",
      description:
        'When a dispute is raised on any shipment by you or carrier, it will appear here.',
      image: 'portal/images/after-login/Empty_State_icons/gr-no-dispute.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'ongoingDispute',
      title: "No Active <span class='txt_p '>Disputes</span>",
      description:
        'If you have any active disputes regarding an existing shipment you can view the details here.',
      image: 'portal/images/after-login/Empty_State_icons/gr-no-dispute.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'resolvedDispute',
      title: "No Resolved <span class='txt_p '>Disputes</span>",
      description: 'All past disputes and their outcomes can be viewed here.',
      image: 'portal/images/after-login/Empty_State_icons/gr-no-dispute.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'shipmentAndBids',
      title: "No Active <span class='txt_p '>Quotes</span>",
      description: 'You can view all active Quotes activity here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-shipment-bidding.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },

    {
      slug: 'postedLanes',
      title: "No Current <span class='txt_p '>Posted Lanes</span>",
      description:
        'When any carrier invites you to quote on a lane, you can view all current Carrier quotes and lane details here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-shipment-bidding.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'confirmedLanes',
      title: "No Confirmed <span class='txt_p '>Quotes</span>",
      description:
        'When any carrier invites you to quote on a load, you can view all confirmed Carrier quotes and load details here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-available-loads.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'archivedLanes',
      title: "No <span class='txt_p '>Archived Lanes</span>",
      description:
        'No Archived Lanes.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-shipment-bidding.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'confirmedLaneList',
      title: "No Confirm <span class='txt_p '> Lane</span>",
      description: 'You can view all confirm lane here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-shipment-bidding.svg',
      buttonText: 'Visit Confirmed Lanes',
      buttonURL: '/booking/lane/confirmedLanes',
      status: '1',
    },
    {
      slug: 'confirmRateHistory',
      title: "No Confirm <span class='txt_p '> Rate History</span>",
      description: 'You can view all confirm lane here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-shipment-bidding.svg',
      buttonText: 'Visit Confirmed Lanes',
      buttonURL: 'recurring-lanes/active-lanes/inProgressByLane',
      status: '1',
    },
    {
      slug: 'bidding',
      title: "No Active <span class='txt_p '>Quotes</span>",
      description:
        'You can view all active quotes activity here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-shipment-bidding.svg',
      buttonText: 'Visit Posted Lanes',
      buttonURL: '/recurring-lanes/create-lanes/allLanes',
      status: '1',
    },
    {
      slug: 'booked',
      title: "Confirm <span class='txt_p '>Quotes</span>",
      description:
        'When any carrier invites you to quote on a load, you can view all confirmed Carrier quotes and load details here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-available-loads.svg',
      buttonText: 'Visit Posted Lanes',
      buttonURL: '/recurring-lanes/create-lanes/allLanes',
      status: '1',
    },
    {
      slug: 'confirmedLoads',
      title: "Confirm <span class='txt_p '>Quotes</span>",
      description:
        'When any carrier invites you to quote on a load, you can view all confirmed Carrier quotes and load details here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-available-loads.svg',
      buttonText: 'Visit Posted Lanes',
      buttonURL: '/recurring-lanes/create-lanes/allLanes',
      status: '1',
    },
    {
      slug: 'allLoads',
      title: "All <span class='txt_p '>Loads</span>",
      description:
        'When any carrier invites you to quote on a load, you can view all confirmed Carrier quotes and load details here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-available-loads.svg',
      buttonText: 'Visit the Network',
      buttonURL: '/network/my-network/connected',
      status: '1',
    },
    {
      slug: 'allLoadsByLane',
      title: "All <span class='txt_p '>Loads</span>",
      description:
        'When any carrier invites you to quote on a load, you can view all confirmed Carrier quotes and load details here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-available-loads.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'quotedLoadsByLane',
      title: "All <span class='txt_p '>Loads</span>",
      description:
        'When any carrier invites you to quote on a load, you can view all confirmed Carrier quotes and load details here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-available-loads.svg',
      buttonText: 'Visit the Network',
      buttonURL: '/network/my-network/connected',
      status: '1',
    },
    {
      slug: 'quotedLoads',
      title: "Quoted <span class='txt_p '>Loads</span>",
      description:
        'When any carrier invites you to quote on a load, you can view all confirmed Carrier quotes and load details here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-available-loads.svg',
      buttonText: 'Visit the Network',
      buttonURL: '/network/my-network/connected',
      status: '1',
    },
    {
      slug: 'paymentPending',
      title: "Load Confirmed - Awaiting Payment <span class='txt_p '></span>",
      description:
        'When any carrier invites you to quote on a load, you can view all confirmed Carrier quotes and load details here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-available-loads.svg',
      buttonText: 'Visit the Network',
      buttonURL: '/network/my-network/connected',
      status: '1',
    },
    {
      slug: 'paymentPendingByLane',
      title: "Load Confirmed - Awaiting Payment <span class='txt_p '></span>",
      description:
        'When any carrier invites you to quote on a load, you can view all confirmed Carrier quotes and load details here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-available-loads.svg',
      buttonText: 'Visit the Network',
      buttonURL: '/network/my-network/connected',
      status: '1',
    },

    {
      slug: 'loadsLost',
      title: "Loads  <span class='txt_p '>Lost</span>",
      description:
        'When any carrier invites you to quote on a load, you can view all confirmed Carrier quotes and load details here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-available-loads.svg',
      buttonText: 'Visit the Network',
      buttonURL: '/network/my-network/connected',
      status: '1',
    },
  

    {
      slug: 'awardedLoads',
      title: "Awarded <span class='txt_p '>Loads</span>",
      description:
        'When any carrier invites you to quote on a load, you can view all confirmed Carrier quotes and load details here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-available-loads.svg',
      buttonText: 'Visit the Network',
      buttonURL: '/network/my-network/connected',
      status: '1',
    },
    {
      slug: 'awardedLoadsByLane',
      title: "Awarded <span class='txt_p '>Loads</span>",
      description:
        'When any carrier invites you to quote on a load, you can view all confirmed Carrier quotes and load details here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-available-loads.svg',
      buttonText: 'Visit the Network',
      buttonURL: '/network/my-network/connected',
      status: '1',
    },


    {
      slug: 'expiredLoads',
      title: "Expired <span class='txt_p '>Loads</span>",
      description:
        'When any carrier invites you to quote on a load, you can view all confirmed Carrier quotes and load details here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-available-loads.svg',
      buttonText: 'Visit the Network',
      buttonURL: '/network/my-network/connected',
      status: '1',
    },
    {
      slug: 'expiredLoadsByLane',
      title: "Expired <span class='txt_p '>Loads</span>",
      description:
        'When any carrier invites you to quote on a load, you can view all confirmed Carrier quotes and load details here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-available-loads.svg',
      buttonText: 'Visit the Network',
      buttonURL: '/network/my-network/connected',
      status: '1',
    },
    {
      slug: 'search',
      title: 'No results found…',
      description:
        'Your search returns no results. Please update your search criteria.',
      image: 'portal/images/after-login/cl-icons/gr-no-search-results.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
  ];

  public static emptyScreenStatusOrderShipper = [
    {
      slug: 'orders',
      title: "No Posted <span class='txt_p '> Orders</span>",
      description: 'You can view all Posted Orders here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-shipment-bidding.svg',
      buttonText: 'Visit the Storage Network',
      buttonURL: '/storage/shipper',
      status: '1',
    },
    {
      slug: 'bidding',
      title: "No Active <span class='txt_p '> Quotes</span>",
      description: 'You can view all active quotes activity here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-shipment-bidding.svg',
      buttonText: 'Visit the Storage Network',
      buttonURL: '/storage/shipper',
      status: '1',
    },
    {
      slug: 'confirmed',
      title: "No Confirmed <span class='txt_p '> Orders</span>",
      description: 'You can view all Confirmed Orders here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-shipment-bidding.svg',
      buttonText: 'Visit the Storage Network',
      buttonURL: '/storage/shipper',
      status: '1',
    },

    {
      slug: 'search',
      title: 'No results found…',
      description:
        'Your search returns no results. Please update your search criteria.',
      image: 'portal/images/after-login/cl-icons/gr-no-search-results.svg',
      buttonText: 'Visit the Storage Network',
      buttonURL: '/storage/shipper',
      status: '1',
    },
  ];

  public static emptyScreenStatusStorage = [
    {
      slug: 'orders',
      title: "No Available <span class='txt_p '> Orders</span>",
      description: 'You can view all Available Orders here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-shipment-bidding.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'bidding',
      title: "No Active <span class='txt_p '>Quotes</span>",
      description: 'You can view all active Quotes activity here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-shipment-bidding.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
    {
      slug: 'confirmed',
      title: "No Assigned <span class='txt_p '> Orders</span>",
      description: 'You can view all Assigned Orders here.',
      image:
        'portal/images/after-login/Empty_State_icons/gr-no-shipment-bidding.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },

    {
      slug: 'search',
      title: 'No results found…',
      description:
        'Your search returns no results. Please update your search criteria.',
      image: 'portal/images/after-login/cl-icons/gr-no-search-results.svg',
      buttonText: 'Visit the Network',
      buttonURL: 'network/my-network/connected',
      status: '1',
    },
  ];

  public static sliderList = [
    {
      id: 1,
      status: 'selectedSlide',
      name: 'Direct Connection',
      description:
        'Send, receive and accept unlimited Carrier connection requests for free',
      image:
        'https://staticprod.laneaxis.com/portal/images/before-login/direct-connections-slide-min.png?v=1',
      subImage:
        'https://staticprod.laneaxis.com/portal/images/before-login/ic-direct-connections-min.png?v=1',
    },

    {
      id: 2,
      status: 'hide',
      name: 'Contractual Control',
      description:
        'Carrier must accept Shipper’s contract, including option to list Shipper on Carrier’s Insurance certificate.',
      image:
        'https://staticprod.laneaxis.com/portal/images/before-login/direct-connections-slide-2-min.png?v=1',
      subImage:
        'https://staticprod.laneaxis.com/portal/images/before-login/ic-contractual-control-min.png?v=1',
    },

    {
      id: 3,
      status: 'hide',
      name: 'Rate Negotiations',
      description:
        'Negotiate with specific Carriers or broadcast a shipment to Network for quotes. Shippers have the final say and control over their freight',
      image:
        'https://staticprod.laneaxis.com/portal/images/before-login/direct-connections-slide-3-min.png?v=1',
      subImage:
        'https://staticprod.laneaxis.com/portal/images/before-login/ic-rate-negotiation-min.png?v=1',
    },

    {
      id: 4,
      status: 'hide',
      name: 'Load Management',
      description:
        'LaneAxis Mobile Application provides real-time tracking beginning prior-to-pickup, e-docs, in-app messaging, instantly archived load history',
      image:
        'https://staticprod.laneaxis.com/portal/images/before-login/direct-connections-slide-4-min.png?v=1',
      subImage:
        'https://staticprod.laneaxis.com/portal/images/before-login/ic-load-management-min.png?v=1',
    },

    {
      id: 5,
      status: 'hide',
      name: 'Delivery and Payment',
      description:
        'Shipper payment to Carrier held in escrow and not released until completion of 24-hour dispute window following Proof of Delivery.',
      image:
        'https://staticprod.laneaxis.com/portal/images/before-login/direct-connections-slide-5-min.png?v=1',
      subImage:
        'https://staticprod.laneaxis.com/portal/images/before-login/ic-delivery-payment-min.png?v=1',
    },

    {
      id: 6,
      status: 'hide',
      name: 'Full Integration Options',
      description:
        'From front-end TMS systems to backend accounting systems and more',
      image:
        'https://staticprod.laneaxis.com/portal/images/before-login/direct-connections-slide-6-min.png?v=1',
      subImage:
        'https://staticprod.laneaxis.com/portal/images/before-login/ic-full-integrations-min.png?v=1',
    },
  ];

  public static fontList = [
    { class: 'Sacramento_txt' },
    { class: 'GreatVibes_txt' },
    { class: 'Yellowtail_txt' },
    { class: 'Tangerine_txt' },
    { class: 'Cedarville_txt' },
    { class: 'DancingScript_txt' },
  ];

  public static equipmentType = [
    {
      id: '1',
      name: 'Dry Van Trailer',
    },

    {
      id: '2',
      name: 'FlatBed Trailer',
    },
    {
      id: '3',
      name: 'Step Deck',
    },
    {
      id: '4',
      name: 'Conestoga Trailer',
    },
    {
      id: '5',
      name: 'RGN Trailer',
    },
    {
      id: '6',
      name: 'Stretch RGN Trailer',
    },
    {
      id: '7',
      name: 'Lowboy Trailer',
    },
    {
      id: '8',
      name: 'Refrigerated Trailer',
    },
    {
      id: '9',
      name: 'Power Unit Only',
    },
    {
      id: '10',
      name: 'Box Truck',
    },
    {
      id: '11',
      name: 'Refrigerated Box Truck',
    },
  ];

  public static timeZoneList = [
    { id: 1, tzShort: 'PST', tzLong: 'Pacific Standard Time' },
    { id: 2, tzShort: 'MST', tzLong: 'Mountain Standard Time' },
    { id: 3, tzShort: 'CST', tzLong: 'Central Standard Time' },
    { id: 4, tzShort: 'EST', tzLong: 'Eastern Standard Time' },
    { id: 5, tzShort: 'HST', tzLong: 'Hawaii Standard Time' },
    { id: 6, tzShort: 'AKST', tzLong: 'Alaska Standard Time' },
  ];

  public static newTimeZoneList = [
    { id: 1, tzShort: 'PST', tzLong: 'Pacific Standard Time' },
    { id: 2, tzShort: 'MST', tzLong: 'Mountain Standard Time' },
    { id: 3, tzShort: 'CST', tzLong: 'Central Standard Time' },
    { id: 4, tzShort: 'EST', tzLong: 'Eastern Standard Time' },
    { id: 5, tzShort: 'HST', tzLong: 'Hawaii Standard Time' },
    { id: 6, tzShort: 'AKST', tzLong: 'Alaska Standard Time' },
    { id: 7, tzShort: 'NST', tzLong: 'Newfoundland Standard Time' },
    { id: 8, tzShort: 'AST', tzLong: 'Atlantic Standard Time' },
  ];

  public static state = [
    {
      name: 'Alabama',
      sortname: 'AL',
    },
    {
      name: 'Alaska',
      sortname: 'AK',
    },
    {
      name: 'American Samoa',
      sortname: 'AS',
    },
    {
      name: 'Arizona',
      sortname: 'AZ',
    },
    {
      name: 'Arkansas',
      sortname: 'AR',
    },
    {
      name: 'California',
      sortname: 'CA',
    },
    {
      name: 'Colorado',
      sortname: 'CO',
    },
    {
      name: 'Connecticut',
      sortname: 'CT',
    },
    {
      name: 'Delaware',
      sortname: 'DE',
    },
    {
      name: 'District Of Columbia',
      sortname: 'DC',
    },
    {
      name: 'Federated States Of Micronesia',
      sortname: 'FM',
    },
    {
      name: 'Florida',
      sortname: 'FL',
    },
    {
      name: 'Georgia',
      sortname: 'GA',
    },
    {
      name: 'Guam',
      sortname: 'GU',
    },
    {
      name: 'Hawaii',
      sortname: 'HI',
    },
    {
      name: 'Idaho',
      sortname: 'ID',
    },
    {
      name: 'Illinois',
      sortname: 'IL',
    },
    {
      name: 'Indiana',
      sortname: 'IN',
    },
    {
      name: 'Iowa',
      sortname: 'IA',
    },
    {
      name: 'Kansas',
      sortname: 'KS',
    },
    {
      name: 'Kentucky',
      sortname: 'KY',
    },
    {
      name: 'Louisiana',
      sortname: 'LA',
    },
    {
      name: 'Maine',
      sortname: 'ME',
    },
    {
      name: 'Marshall Islands',
      sortname: 'MH',
    },
    {
      name: 'Maryland',
      sortname: 'MD',
    },
    {
      name: 'Massachusetts',
      sortname: 'MA',
    },
    {
      name: 'Michigan',
      sortname: 'MI',
    },
    {
      name: 'Minnesota',
      sortname: 'MN',
    },
    {
      name: 'Mississippi',
      sortname: 'MS',
    },
    {
      name: 'Missouri',
      sortname: 'MO',
    },
    {
      name: 'Montana',
      sortname: 'MT',
    },
    {
      name: 'Nebraska',
      sortname: 'NE',
    },
    {
      name: 'Nevada',
      sortname: 'NV',
    },
    {
      name: 'New Hampshire',
      sortname: 'NH',
    },
    {
      name: 'New Jersey',
      sortname: 'NJ',
    },
    {
      name: 'New Mexico',
      sortname: 'NM',
    },
    {
      name: 'New York',
      sortname: 'NY',
    },
    {
      name: 'North Carolina',
      sortname: 'NC',
    },
    {
      name: 'North Dakota',
      sortname: 'ND',
    },
    {
      name: 'Northern Mariana Islands',
      sortname: 'MP',
    },
    {
      name: 'Ohio',
      sortname: 'OH',
    },
    {
      name: 'Oklahoma',
      sortname: 'OK',
    },
    {
      name: 'Oregon',
      sortname: 'OR',
    },
    {
      name: 'Palau',
      sortname: 'PW',
    },
    {
      name: 'Pennsylvania',
      sortname: 'PA',
    },
    {
      name: 'Puerto Rico',
      sortname: 'PR',
    },
    {
      name: 'Rhode Island',
      sortname: 'RI',
    },
    {
      name: 'South Carolina',
      sortname: 'SC',
    },
    {
      name: 'South Dakota',
      sortname: 'SD',
    },
    {
      name: 'Tennessee',
      sortname: 'TN',
    },
    {
      name: 'Texas',
      sortname: 'TX',
    },
    {
      name: 'Utah',
      sortname: 'UT',
    },
    {
      name: 'Vermont',
      sortname: 'VT',
    },
    {
      name: 'Virgin Islands',
      sortname: 'VI',
    },
    {
      name: 'Virginia',
      sortname: 'VA',
    },
    {
      name: 'Washington',
      sortname: 'WA',
    },
    {
      name: 'West Virginia',
      sortname: 'WV',
    },
    {
      name: 'Wisconsin',
      sortname: 'WI',
    },
    {
      name: 'Wyoming',
      sortname: 'WY',
    },
    {
      name: 'Alberta',
      sortname: 'AB',
    },
    {
      name: 'British Columbia',
      sortname: 'BC',
    },
    {
      name: 'Manitoba',
      sortname: 'MB',
    },
    {
      name: 'New Brunswick',
      sortname: 'NB',
    },
    {
      name: 'Newfoundland and Labrador',
      sortname: 'NL',
    },
    {
      name: 'Northwest Territories',
      sortname: 'NT',
    },
    {
      name: 'Nova Scotia',
      sortname: 'NS',
    },
    {
      name: 'Nunavut',
      sortname: 'NU',
    },
    {
      name: 'Ontario',
      sortname: 'ON',
    },
    {
      name: 'Prince Edward Island',
      sortname: 'PE',
    },
    {
      name: 'Quebec',
      sortname: 'QC',
    },
    {
      name: 'Saskatchewan',
      sortname: 'SK',
    },
    {
      name: 'Yukon',
      sortname: 'YT',
    },
    {
      name: 'Aguascalientes',
      sortname: 'Ags.',
    },
    {
      name: 'Baja California',
      sortname: 'B.C.',
    },
    {
      name: 'Baja California Sur',
      sortname: 'BCS',
    },
    {
      name: 'Campeche',
      sortname: 'Camp.',
    },
    {
      name: 'Chiapas',
      sortname: 'Chis.',
    },
    {
      name: 'Chihuahua',
      sortname: 'Chih.',
    },
    {
      name: 'Coahuila',
      sortname: 'Coah.',
    },
    {
      name: 'Colima',
      sortname: 'Col.',
    },
    {
      name: 'Durango',
      sortname: 'Dgo.',
    },
    {
      name: 'Guanajuato',
      sortname: 'Gto.',
    },
    {
      name: 'Guerrero',
      sortname: 'YT',
    },
    {
      name: 'BCS',
      sortname: 'YT',
    },
    {
      name: 'Jalisco',
      sortname: 'Jal.',
    },
    {
      name: 'Mexico',
      sortname: 'CDMX',
    },
    {
      name: 'Michoacan',
      sortname: 'Mich.',
    },
    {
      name: 'Morelos',
      sortname: 'Mor.',
    },
    {
      name: 'Nuevo Leon',
      sortname: 'N.L.',
    },
    {
      name: 'Nayarit',
      sortname: 'Nay.',
    },
    {
      name: 'Oaxaca',
      sortname: 'Oax.',
    },
    {
      name: 'Puebla',
      sortname: 'Pue.',
    },
    {
      name: 'Queretaro',
      sortname: 'Qro.',
    },
    {
      name: 'Quintana Roo',
      sortname: 'Q.R.',
    },
    {
      name: 'San Luis Potosi',
      sortname: 'S.L.P.',
    },
    {
      name: 'Sinaloa',
      sortname: 'Sin.',
    },
    {
      name: 'Sonora',
      sortname: 'Son.',
    },
    {
      name: 'Tabasco',
      sortname: 'Tab.',
    },
    {
      name: 'Tamaulipas',
      sortname: 'Tamps.',
    },
    {
      name: 'Tlaxcala',
      sortname: 'Tlax.',
    },
    {
      name: 'Veracruz',
      sortname: 'Ver.',
    },
    {
      name: 'Yucatan',
      sortname: 'Yuc.',
    },
    {
      name: 'Zacatecas',
      sortname: 'Zac.',
    },
  ];

  public static caState = [
    {
      name: 'Alberta',
      sortname: 'AB',
    },
    {
      name: 'British Columbia',
      sortname: 'BC',
    },
    {
      name: 'Manitoba',
      sortname: 'MB',
    },
    {
      name: 'New Brunswick',
      sortname: 'NB',
    },
    {
      name: 'Newfoundland and Labrador',
      sortname: 'NL',
    },
    {
      name: 'Northwest Territories',
      sortname: 'NT',
    },
    {
      name: 'Nova Scotia',
      sortname: 'NS',
    },
    {
      name: 'Nunavut',
      sortname: 'NU',
    },
    {
      name: 'Ontario',
      sortname: 'ON',
    },
    {
      name: 'Prince Edward Island',
      sortname: 'PE',
    },
    {
      name: 'Quebec',
      sortname: 'QC',
    },
    {
      name: 'Saskatchewan',
      sortname: 'SK',
    },
    {
      name: 'Yukon',
      sortname: 'YT',
    },

  ]
  public static mxState = [
    {
      name: 'Aguascalientes',
      sortname: 'Ags.',
    },
    {
      name: 'Baja California',
      sortname: 'B.C.',
    },
    {
      name: 'Baja California Sur',
      sortname: 'BCS',
    },
    {
      name: 'Campeche',
      sortname: 'Camp.',
    },
    {
      name: 'Chiapas',
      sortname: 'Chis.',
    },
    {
      name: 'Chihuahua',
      sortname: 'Chih.',
    },
    {
      name: 'Coahuila',
      sortname: 'Coah.',
    },
    {
      name: 'Colima',
      sortname: 'Col.',
    },
    {
      name: 'Durango',
      sortname: 'Dgo.',
    },
    {
      name: 'Guanajuato',
      sortname: 'Gto.',
    },
    {
      name: 'Guerrero',
      sortname: 'YT',
    },
    {
      name: 'BCS',
      sortname: 'YT',
    },
    {
      name: 'Jalisco',
      sortname: 'Jal.',
    },
    {
      name: 'Mexico',
      sortname: 'CDMX',
    },
    {
      name: 'Michoacan',
      sortname: 'Mich.',
    },
    {
      name: 'Morelos',
      sortname: 'Mor.',
    },
    {
      name: 'Nuevo Leon',
      sortname: 'N.L.',
    },
    {
      name: 'Nayarit',
      sortname: 'Nay.',
    },
    {
      name: 'Oaxaca',
      sortname: 'Oax.',
    },
    {
      name: 'Puebla',
      sortname: 'Pue.',
    },
    {
      name: 'Queretaro',
      sortname: 'Qro.',
    },
    {
      name: 'Quintana Roo',
      sortname: 'Q.R.',
    },
    {
      name: 'San Luis Potosi',
      sortname: 'S.L.P.',
    },
    {
      name: 'Sinaloa',
      sortname: 'Sin.',
    },
    {
      name: 'Sonora',
      sortname: 'Son.',
    },
    {
      name: 'Tabasco',
      sortname: 'Tab.',
    },
    {
      name: 'Tamaulipas',
      sortname: 'Tamps.',
    },
    {
      name: 'Tlaxcala',
      sortname: 'Tlax.',
    },
    {
      name: 'Veracruz',
      sortname: 'Ver.',
    },
    {
      name: 'Yucatan',
      sortname: 'Yuc.',
    },
    {
      name: 'Zacatecas',
      sortname: 'Zac.',
    },
  ]

  public static usState = [
    {
      name: 'Alabama',
      sortname: 'AL',
    },
    {
      name: 'Alaska',
      sortname: 'AK',
    },
    {
      name: 'American Samoa',
      sortname: 'AS',
    },
    {
      name: 'Arizona',
      sortname: 'AZ',
    },
    {
      name: 'Arkansas',
      sortname: 'AR',
    },
    {
      name: 'California',
      sortname: 'CA',
    },
    {
      name: 'Colorado',
      sortname: 'CO',
    },
    {
      name: 'Connecticut',
      sortname: 'CT',
    },
    {
      name: 'Delaware',
      sortname: 'DE',
    },
    {
      name: 'District Of Columbia',
      sortname: 'DC',
    },
    {
      name: 'Federated States Of Micronesia',
      sortname: 'FM',
    },
    {
      name: 'Florida',
      sortname: 'FL',
    },
    {
      name: 'Georgia',
      sortname: 'GA',
    },
    {
      name: 'Guam',
      sortname: 'GU',
    },
    {
      name: 'Hawaii',
      sortname: 'HI',
    },
    {
      name: 'Idaho',
      sortname: 'ID',
    },
    {
      name: 'Illinois',
      sortname: 'IL',
    },
    {
      name: 'Indiana',
      sortname: 'IN',
    },
    {
      name: 'Iowa',
      sortname: 'IA',
    },
    {
      name: 'Kansas',
      sortname: 'KS',
    },
    {
      name: 'Kentucky',
      sortname: 'KY',
    },
    {
      name: 'Louisiana',
      sortname: 'LA',
    },
    {
      name: 'Maine',
      sortname: 'ME',
    },
    {
      name: 'Marshall Islands',
      sortname: 'MH',
    },
    {
      name: 'Maryland',
      sortname: 'MD',
    },
    {
      name: 'Massachusetts',
      sortname: 'MA',
    },
    {
      name: 'Michigan',
      sortname: 'MI',
    },
    {
      name: 'Minnesota',
      sortname: 'MN',
    },
    {
      name: 'Mississippi',
      sortname: 'MS',
    },
    {
      name: 'Missouri',
      sortname: 'MO',
    },
    {
      name: 'Montana',
      sortname: 'MT',
    },
    {
      name: 'Nebraska',
      sortname: 'NE',
    },
    {
      name: 'Nevada',
      sortname: 'NV',
    },
    {
      name: 'New Hampshire',
      sortname: 'NH',
    },
    {
      name: 'New Jersey',
      sortname: 'NJ',
    },
    {
      name: 'New Mexico',
      sortname: 'NM',
    },
    {
      name: 'New York',
      sortname: 'NY',
    },
    {
      name: 'North Carolina',
      sortname: 'NC',
    },
    {
      name: 'North Dakota',
      sortname: 'ND',
    },
    {
      name: 'Northern Mariana Islands',
      sortname: 'MP',
    },
    {
      name: 'Ohio',
      sortname: 'OH',
    },
    {
      name: 'Oklahoma',
      sortname: 'OK',
    },
    {
      name: 'Oregon',
      sortname: 'OR',
    },
    {
      name: 'Palau',
      sortname: 'PW',
    },
    {
      name: 'Pennsylvania',
      sortname: 'PA',
    },
    {
      name: 'Puerto Rico',
      sortname: 'PR',
    },
    {
      name: 'Rhode Island',
      sortname: 'RI',
    },
    {
      name: 'South Carolina',
      sortname: 'SC',
    },
    {
      name: 'South Dakota',
      sortname: 'SD',
    },
    {
      name: 'Tennessee',
      sortname: 'TN',
    },
    {
      name: 'Texas',
      sortname: 'TX',
    },
    {
      name: 'Utah',
      sortname: 'UT',
    },
    {
      name: 'Vermont',
      sortname: 'VT',
    },
    {
      name: 'Virgin Islands',
      sortname: 'VI',
    },
    {
      name: 'Virginia',
      sortname: 'VA',
    },
    {
      name: 'Washington',
      sortname: 'WA',
    },
    {
      name: 'West Virginia',
      sortname: 'WV',
    },
    {
      name: 'Wisconsin',
      sortname: 'WI',
    },
    {
      name: 'Wyoming',
      sortname: 'WY',
    },

  ];
  
  public static shipmentTypeArray = {
    inProgressByLoads: [
      {
        slugName: 'changeDriver',
        labelName: 'Change Driver',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'navigateToPickup',
        labelName: 'Navigate To Pickup',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'reachedInPickupGeofence',
        labelName: 'Reached In Pickup Geo Fence',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'atPickupLocation',
        labelName: 'At Pickup Location',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'shipmentLoading',
        labelName: 'Shipment Loading',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'shipmentLoaded',
        labelName: 'Shipment Loaded',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'verifyingPickupDocuments',
        labelName: 'Verifying Pickup Documents',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'accident',
        labelName: 'Accident',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'rest',
        labelName: 'Rest',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'underMaintenance',
        labelName: 'Under Maintenance',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'etc',
        labelName: 'Etc',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'navigateToDropOff',
        labelName: 'Navigate To DropOff',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'reachedAtDropOffGeofence',
        labelName: 'Reached At DropOff Geo Fence',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'atDropOffLocation',
        labelName: 'At DropOff Location',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'shipmentUnloading',
        labelName: 'Shipment Unloading',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'shipmentUnloaded',
        labelName: 'Shipment Unloaded',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'verifyingDropOffDocuments',
        labelName: 'Verifying DropOff Documents',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
    ],
    inProgress: [
      {
        slugName: 'changeDriver',
        labelName: 'Change Driver',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'navigateToPickup',
        labelName: 'Navigate To Pickup',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'reachedInPickupGeofence',
        labelName: 'Reached In Pickup Geo Fence',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'atPickupLocation',
        labelName: 'At Pickup Location',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'shipmentLoading',
        labelName: 'Shipment Loading',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'shipmentLoaded',
        labelName: 'Shipment Loaded',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'verifyingPickupDocuments',
        labelName: 'Verifying Pickup Documents',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'accident',
        labelName: 'Accident',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'rest',
        labelName: 'Rest',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'underMaintenance',
        labelName: 'Under Maintenance',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'etc',
        labelName: 'Etc',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'navigateToDropOff',
        labelName: 'Navigate To DropOff',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'reachedAtDropOffGeofence',
        labelName: 'Reached At DropOff Geo Fence',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'atDropOffLocation',
        labelName: 'At DropOff Location',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'shipmentUnloading',
        labelName: 'Shipment Unloading',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'shipmentUnloaded',
        labelName: 'Shipment Unloaded',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'verifyingDropOffDocuments',
        labelName: 'Verifying DropOff Documents',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
    ],
    inProgressByLane: [
      {
        slugName: 'changeDriver',
        labelName: 'Change Driver',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'navigateToPickup',
        labelName: 'Navigate To Pickup',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'reachedInPickupGeofence',
        labelName: 'Reached In Pickup Geo Fence',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'atPickupLocation',
        labelName: 'At Pickup Location',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'shipmentLoading',
        labelName: 'Shipment Loading',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'shipmentLoaded',
        labelName: 'Shipment Loaded',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'verifyingPickupDocuments',
        labelName: 'Verifying Pickup Documents',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'accident',
        labelName: 'Accident',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'rest',
        labelName: 'Rest',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'underMaintenance',
        labelName: 'Under Maintenance',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'etc',
        labelName: 'Etc',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'navigateToDropOff',
        labelName: 'Navigate To DropOff',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'reachedAtDropOffGeofence',
        labelName: 'Reached At DropOff Geo Fence',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'atDropOffLocation',
        labelName: 'At DropOff Location',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'shipmentUnloading',
        labelName: 'Shipment Unloading',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'shipmentUnloaded',
        labelName: 'Shipment Unloaded',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'verifyingDropOffDocuments',
        labelName: 'Verifying DropOff Documents',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
    ],
    upcomingByLoads: [
      {
        slugName: 'accept',
        labelName: 'Accept',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
    ],
    upcoming: [
      {
        slugName: 'accept',
        labelName: 'Accept',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
    ],
    upcomingByLane: [
      {
        slugName: 'accept',
        labelName: 'Accept',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
    ],
    pastByLoads: [
      {
        slugName: 'cancel',
        labelName: 'Canceled',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'delivered',
        labelName: 'Delivered',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'completed',
        labelName: 'Completed',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'paymentReleased',
        labelName: 'Payment Released',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'paymentInitiated',
        labelName: 'Payment Initiated',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'paymentOnHold',
        labelName: 'Payment On Hold',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
    ],
    past: [
      {
        slugName: 'cancel',
        labelName: 'Canceled',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'delivered',
        labelName: 'Delivered',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'completed',
        labelName: 'Completed',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'paymentReleased',
        labelName: 'Payment Released',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'paymentInitiated',
        labelName: 'Payment Initiated',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'paymentOnHold',
        labelName: 'Payment On Hold',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
    ],
    pastByLane: [
      {
        slugName: 'cancel',
        labelName: 'Canceled',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'delivered',
        labelName: 'Delivered',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'completed',
        labelName: 'Completed',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'paymentReleased',
        labelName: 'Payment Released',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'paymentInitiated',
        labelName: 'Payment Initiated',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
      {
        slugName: 'paymentOnHold',
        labelName: 'Payment On Hold',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
    ],
    expired: [
      {
        slugName: 'expired',
        labelName: 'Expired',
        isActiveForRoles: ['CARRIER', 'SHIPPER'],
      },
    ],
    pendingByLoads: [
      { slugName: 'draft', labelName: 'Draft', isActiveForRoles: [] },
      {
        slugName: 'create',
        labelName: 'Create',
        isActiveForRoles: ['CARRIER'],
      },
      {
        slugName: 'assign',
        labelName: 'Assigned',
        isActiveForRoles: ['CARRIER'],
      },
      {
        slugName: 'reject',
        labelName: 'Rejected',
        isActiveForRoles: ['CARRIER'],
      },
      { slugName: 'unAssign', labelName: 'Unassigned', isActiveForRoles: [] },
      {
        slugName: 'paymentConfirmed',
        labelName: 'Payment Confirmed',
        isActiveForRoles: [],
      },
      {
        slugName: 'assignToCarrier',
        labelName: 'Assign To Carrier',
        isActiveForRoles: ['SHIPPER'],
      },
    ],
    pending: [
      { slugName: 'draft', labelName: 'Draft', isActiveForRoles: [] },
      {
        slugName: 'create',
        labelName: 'Create',
        isActiveForRoles: ['CARRIER'],
      },
      {
        slugName: 'assign',
        labelName: 'Assigned',
        isActiveForRoles: ['CARRIER'],
      },
      {
        slugName: 'reject',
        labelName: 'Rejected',
        isActiveForRoles: ['CARRIER'],
      },
      { slugName: 'unAssign', labelName: 'Unassigned', isActiveForRoles: [] },
      {
        slugName: 'paymentConfirmed',
        labelName: 'Payment Confirmed',
        isActiveForRoles: [],
      },
      {
        slugName: 'assignToCarrier',
        labelName: 'Assign To Carrier',
        isActiveForRoles: ['SHIPPER'],
      },
    ],
    pendingByLane: [
      { slugName: 'draft', labelName: 'Draft', isActiveForRoles: [] },
      {
        slugName: 'create',
        labelName: 'Create',
        isActiveForRoles: ['CARRIER'],
      },
      {
        slugName: 'assign',
        labelName: 'Assigned',
        isActiveForRoles: ['CARRIER'],
      },
      {
        slugName: 'reject',
        labelName: 'Rejected',
        isActiveForRoles: ['CARRIER'],
      },
      { slugName: 'unAssign', labelName: 'Unassigned', isActiveForRoles: [] },
      {
        slugName: 'paymentConfirmed',
        labelName: 'Payment Confirmed',
        isActiveForRoles: [],
      },
      {
        slugName: 'assignToCarrier',
        labelName: 'Assign To Carrier',
        isActiveForRoles: ['SHIPPER'],
      },
    ],
    newDispute: [
      { slugName: 'dispute', labelName: 'Dispute', isActiveForRoles: [] },
    ],

    ongoingDispute: [
      { slugName: 'dispute', labelName: 'Dispute', isActiveForRoles: [] },
    ],

    resolvedDispute: [
      { slugName: 'dispute', labelName: 'Dispute', isActiveForRoles: [] },
    ],
  };
  public static messageNotifictionList = [
    { slugName: 'changeDriver', labelName: 'Change Driver' },
    { slugName: 'navigateToPickup', labelName: 'Navigate To Pickup' },
    {
      slugName: 'reachedInPickupGeofence',
      labelName: 'Reached In Pickup Geo Fence',
    },
    { slugName: 'atPickupLocation', labelName: 'At Pickup Location' },
    { slugName: 'shipmentLoading', labelName: 'Shipment Loading' },
    { slugName: 'shipmentLoaded', labelName: 'Shipment Loaded' },
    {
      slugName: 'verifyingPickupDocuments',
      labelName: 'Verifying Pickup Documents',
    },
    { slugName: 'accident', labelName: 'Accident' },
    { slugName: 'rest', labelName: 'Rest' },
    { slugName: 'underMaintenance', labelName: 'Under Maintenance' },
    { slugName: 'etc', labelName: 'Etc' },
    { slugName: 'navigateToDropOff', labelName: 'Navigate To DropOff' },
    {
      slugName: 'reachedAtDropOffGeofence',
      labelName: 'Reached At DropOff Geo Fence',
    },
    { slugName: 'atDropOffLocation', labelName: 'At DropOff Location' },
    { slugName: 'shipmentUnloading', labelName: 'Shipment Unloading' },
    { slugName: 'shipmentUnloaded', labelName: 'Shipment Unloaded' },
    {
      slugName: 'verifyingDropOffDocuments',
      labelName: 'Verifying DropOff Documents',
    },
    { slugName: 'unAssign', labelName: 'Unassign' },
    { slugName: 'accept', labelName: 'Accepted Load' },
    { slugName: 'cancel', labelName: 'Cancel Load' },
    { slugName: 'delivered', labelName: 'Delivered Load' },
    { slugName: 'assign', labelName: 'Assign Load' },
    { slugName: 'reject', labelName: 'Rejected Load' },
    { slugName: 'unDelivered', labelName: 'UnDelivered' },
    { slugName: 'dispute', labelName: 'Dispute' },
    {
      slugName: 'newAccount',
      labelName: 'Congratulations! your account has been successfully created',
    },
    {
      slugName: 'newCompleteProfile',
      labelName: 'Please click here and update your profile',
    },
    { slugName: 'driverRegistered', labelName: 'Driver registered with us' },
    {
      slugName: 'paymentSuccess',
      labelName: 'Load has been purchased successfully',
    },
    { slugName: 'paymentFailed', labelName: 'Payment is Failed' },
  ];
  public static notificcation = [
    {
      class: 'noti_ship_accept',
      newClass: 'icon-ic-box',
    },
    {
      class: 'noti_ship_reject',
      newClass: 'icon-ic-box',
    },
    {
      class: 'noti_ship_undelivered',
      newClass: 'icon-ic-box',
    },
    {
      class: 'noti_ship_delivered',
      newClass: 'icon-ic-box',
    },
    {
      class: 'noti_ship_picup',
      newClass: 'icon-ic-pickup',
    },
    {
      class: 'noti_ship_dispute',
      newClass: 'icon-ic-dispute',
    },
    {
      class: 'noti_ship_accident',
      newClass: 'icon-ic-driver',
    },
    {
      class: 'noti_ship_driRegistered',
      newClass: 'icon-ic-driver',
    },
    {
      class: 'noti_ship_add_request',
      newClass: 'icon-ic-person',
    },
    {
      class: 'noti_ship_message',
      newClass: 'icon-ic-person',
    },
    {
      class: 'noti_ship_paymentSucc',
      newClass: 'icon-ic-money',
    },
    {
      class: 'noti_ship_paymentSucc',
      newClass: 'icon-ic-money',
    },
    {
      class: 'noti_ship_paymentFailed',
      newClass: 'icon-ic-money',
    },
    {
      class: 'noti_invite_request',
      newClass: 'icon-ic-envelope',
    },
    {
      class: 'noti_reinvite_request',
      newClass: 'icon-ic-envelope',
    },
    {
      class: 'noti_connected_request',
      newClass: 'icon-ic-envelope',
    },
  ];

  public static shipmentStatusType = {
    inProgressByLoads: [
      'changeDriver',
      'navigateToPickup',
      'reachedInPickupGeofence',
      'atPickupLocation',
      'shipmentLoading',
      'shipmentLoaded',
      'verifyingPickupDocuments',
      'accident',
      'rest',
      'underMaintenance',
      'etc',
      'navigateToDropOff',
      'reachedAtDropOffGeofence',
      'atDropOffLocation',
      'shipmentUnloading',
      'shipmentUnloaded',
      'verifyingDropOffDocuments',
    ],
    inProgress: [
      'changeDriver',
      'navigateToPickup',
      'reachedInPickupGeofence',
      'atPickupLocation',
      'shipmentLoading',
      'shipmentLoaded',
      'verifyingPickupDocuments',
      'accident',
      'rest',
      'underMaintenance',
      'etc',
      'navigateToDropOff',
      'reachedAtDropOffGeofence',
      'atDropOffLocation',
      'shipmentUnloading',
      'shipmentUnloaded',
      'verifyingDropOffDocuments',
    ],
    inProgressByLane: [
      'changeDriver',
      'navigateToPickup',
      'reachedInPickupGeofence',
      'atPickupLocation',
      'shipmentLoading',
      'shipmentLoaded',
      'verifyingPickupDocuments',
      'accident',
      'rest',
      'underMaintenance',
      'etc',
      'navigateToDropOff',
      'reachedAtDropOffGeofence',
      'atDropOffLocation',
      'shipmentUnloading',
      'shipmentUnloaded',
      'verifyingDropOffDocuments',
    ],
    upcomingByLoads: ['accept'],
    upcoming: ['accept'],
    upcomingByLane: ['accept'],
    pastByLoads: ['cancel', 'delivered'],
    past: ['cancel', 'delivered'],
    pastByLane: ['cancel', 'delivered'],
    pendingByLoads: ['assign', 'reject', 'unDelivered', 'dispute', 'unAssign'],
    pending: ['assign', 'reject', 'unDelivered', 'dispute', 'unAssign'],
    pendingByLane: ['assign', 'reject', 'unDelivered', 'dispute', 'unAssign'],
  };
  public static pickUpTime = [
    {
      time: '00:00 - 02:00',
      id: 1,
      disabled: false,
    },
    {
      time: '02:00 - 04:00',
      id: 2,
      disabled: false,
    },
    {
      time: '04:00 - 06:00',
      id: 3,
      disabled: false,
    },
    {
      time: '06:00 - 08:00',
      id: 4,
      disabled: false,
    },
    {
      time: '08:00 - 10:00',
      id: 5,
      disabled: false,
    },
    {
      time: '10:00 - 12:00',
      id: 6,
      disabled: false,
    },
    {
      time: '12:00 - 14:00',
      id: 7,
      disabled: false,
    },
    {
      time: '14:00 - 16:00',
      id: 8,
      disabled: false,
    },
    {
      time: '16:00 - 18:00',
      id: 9,
      disabled: false,
    },
    {
      time: '18:00 - 20:00',
      id: 10,
      disabled: false,
    },
    {
      time: '20:00 - 22:00',
      id: 11,
      disabled: false,
    },
    {
      time: '22:00 - 23:59',
      id: 12,
      disabled: false,
    },
  ];

  public static dropOffTime = [
    {
      time: '00:00 - 02:00',
      id: 1,
      disabled: false,
    },
    {
      time: '02:00 - 04:00',
      id: 2,
      disabled: false,
    },
    {
      time: '04:00 - 06:00',
      id: 3,
      disabled: false,
    },
    {
      time: '06:00 - 08:00',
      id: 4,
      disabled: false,
    },
    {
      time: '08:00 - 10:00',
      id: 5,
      disabled: false,
    },
    {
      time: '10:00 - 12:00',
      id: 6,
      disabled: false,
    },
    {
      time: '12:00 - 14:00',
      id: 7,
      disabled: false,
    },
    {
      time: '14:00 - 16:00',
      id: 8,
      disabled: false,
    },
    {
      time: '16:00 - 18:00',
      id: 9,
      disabled: false,
    },
    {
      time: '18:00 - 20:00',
      id: 10,
      disabled: false,
    },
    {
      time: '20:00 - 22:00',
      id: 11,
      disabled: false,
    },
    {
      time: '22:00 - 23:59',
      id: 12,
      disabled: false,
    },
  ];

  public static timeSet = [
    {
      time: '00:00 - 02:00',
      id: 1,
      disabled: false,
    },
    {
      time: '02:00 - 04:00',
      id: 2,
      disabled: false,
    },
    {
      time: '04:00 - 06:00',
      id: 3,
      disabled: false,
    },
    {
      time: '06:00 - 08:00',
      id: 4,
      disabled: false,
    },
    {
      time: '08:00 - 10:00',
      id: 5,
      disabled: false,
    },
    {
      time: '10:00 - 12:00',
      id: 6,
      disabled: false,
    },
    {
      time: '12:00 - 14:00',
      id: 7,
      disabled: false,
    },
    {
      time: '14:00 - 16:00',
      id: 8,
      disabled: false,
    },
    {
      time: '16:00 - 18:00',
      id: 9,
      disabled: false,
    },
    {
      time: '18:00 - 20:00',
      id: 10,
      disabled: false,
    },
    {
      time: '20:00 - 22:00',
      id: 11,
      disabled: false,
    },
    {
      time: '22:00 - 23:59',
      id: 12,
      disabled: false,
    },
  ];

  public static contyList = [
    {
      code: '1',
      flag: "assets/country/us.png",
      short: 'US',
      name: 'United States of America'
    },
    {
      code: '1',
      flag: "assets/country/ca.png",
      short: 'CA',
      name: 'Canada',
    },
    {
      code: '52',
      flag: "assets/country/mx.png",
      short: 'MX',
      name: 'Mexico',
    },
  ];

  public static usTimeZoneList = {
    AL: 'CST',
    AK: 'AKST',
    AZ: 'MST',
    AR: 'CST',
    CA: 'PST',
    CO: 'MST',
    CT: 'EST',
    DC: 'EST',
    DE: 'EST',
    FL: 'EST',
    GA: 'EST',
    GU: 'CHST',
    HI: 'HST',
    ID: 'MST',
    IL: 'CST',
    IN: 'EST',
    IA: 'CST',
    KS: 'CST',
    KY: 'CST',
    LA: 'CST',
    ME: 'EST',
    MD: 'EST',
    MA: 'EST',
    MI: 'EST',
    MN: 'CST',
    MS: 'CST',
    MO: 'CST',
    MT: 'MST',
    NE: 'CST',
    NV: 'PST',
    NH: 'EST',
    NJ: 'EST',
    NM: 'MST',
    NY: 'EST',
    NC: 'EST',
    ND: 'CST',
    OH: 'EST',
    OK: 'CST',
    OR: 'PST',
    PA: 'EST',
    RI: 'EST',
    SC: 'EST',
    SD: 'CST',
    TN: 'CST',
    TX: 'CST',
    UT: 'MST',
    VA: 'EST',
    WA: 'PST',
    WV: 'EST',
    WI: 'CST',
    WY: 'MST',
  };

  public static NewStateTimeZoneList = {
    AL: 'CST',
    AK: 'AKST',
    AZ: 'MST',
    AR: 'CST',
    CA: 'PST',
    CO: 'MST',
    CT: 'EST',
    DC: 'EST',
    DE: 'EST',
    FL: 'EST',
    GA: 'EST',
    GU: 'CHST',
    HI: 'HST',
    ID: 'MST',
    IL: 'CST',
    IN: 'EST',
    IA: 'CST',
    KS: 'CST',
    KY: 'CST',
    LA: 'CST',
    ME: 'EST',
    MD: 'EST',
    MA: 'EST',
    MI: 'EST',
    MN: 'CST',
    MS: 'CST',
    MO: 'CST',
    MT: 'MST',
    NE: 'CST',
    NV: 'PST',
    NH: 'EST',
    NJ: 'EST',
    NM: 'MST',
    NY: 'EST',
    NC: 'EST',
    ND: 'CST',
    OH: 'EST',
    OK: 'CST',
    OR: 'PST',
    PA: 'EST',
    RI: 'EST',
    SC: 'EST',
    SD: 'CST',
    TN: 'CST',
    TX: 'CST',
    UT: 'MST',
    VA: 'EST',
    WA: 'PST',
    WV: 'EST',
    WI: 'CST',
    WY: 'MST',
    BC: 'PST',
    AB: 'MST',
    YT: 'MST',
    NT: 'MST',
    SK: 'CST',
    MB: 'CST',
    ON: 'EST',
    QC: 'EST',
    NU: 'EST',
    NS: 'AST',
    NB: 'AST',
    PE: 'AST',
    NL: 'AST',
    NF: 'NST',
    AG: 'CST',
    BS: 'MST',
    CM: 'CST',
    CS: 'CST',
    CH: 'CST',
    CDMX: 'CST',
    CL: 'CST',
    DG: 'CST',
    GT: 'CST',
    GR: 'CST',
    HG: 'CST',
    JA: 'CST',
    NA: 'CST',
    OA: 'CST',
    PU: 'CST',
    QT: 'CST',
    QR: 'EST',
    SL: 'CST',
    SI: 'MST',
    SO: 'MST',
    TB: 'CST',
    TM: 'CST',
    TL: 'CST',
    VE: 'CST',
    YU: 'CST',
    ZA: 'CST'
  };



  public static trailerSpecs =
    ['Air-ride',
      'Blanket wrap',
      'B-train',
      'Chains',
      'Chassis',
      'Curtains',
      'E-track',
      'Extendable',
      'Haz-mat',
      'Hot shot',
      'Insulated',
      'Intermodal',
      'Lift-gate',
      'Load ramps',
      'Load out',
      'Pallet exchange',
      'Side kit',
      'Tarps',
      'Team driver',
      'Temp-controlled',
      'Vented',
      'Walking floor'
    ];

  // User Guide.

  public static eCarrierCheckToolTip = {
    lowRisk:'The company can be considered as hirable. They have few, if any, safety violations or crashes. We have not detected any suspicious activity on their authority, such as recent transfers, insurance drops, or refusal of audits. Their inspection activity is consistent with what we would expect of a company of that size. 55% of companies in our database meet these criteria.',
    
    highRisk:'Be very wary of companies with high risk ratings. These companies have seen suspicious activity regarding their operating authority. It has been granted of transferred recently or has a high amount of notices on their authority. Their inspection data may also be outside the bounds that we consider normal, especially if they have no inspections within the last 12 months. We may have found one or more companies that have the same contact information, address, phone number, email, or has been seen running the same equipment. Safe driving practices are also factored into this rating. 20% of companies in our database meet these criteria.',

    elevatedRisk:'The company may require a closer look. They are still considered hirable in most cases, but we have seen discrepancies to some normal business practices. Make sure to vet these carriers more closely. Pay attention to the inspection ratio, safety ratings, matching companies, and authority. 25% of companies in our database meet these criteria.',

    disqualified :'When carriers have one of a few common hiring disqualifiers for interstate hauling, we label the company as disqualified. For example, if they are missing active interstate operating authority (MC#), or are designated out-of-service by the FMCSA, or if their operation classification is not a hirable category. We also use this category for passenger carriers, although passenger carriers can still be hired for the purpose of transporting passengers (just not property).',
  }


public static SMSPercentiles ={
  unSafeDriving :'The FMCSA aggregates Safety Measurement System (SMS) scores for all active motor carriers. These scores are derived from violations and then converted into percentiles based on carriers peer groups, which consist of companies of similar sizes for Unsafe Driving',
  hoursOfService: 'The FMCSA aggregates Safety Measurement System (SMS) scores for all active motor carriers. These scores are derived from violations and then converted into percentiles based on carriers peer groups, which consist of companies of similar sizes for Hours of Service.',
  driverFitness:'The FMCSA aggregates Safety Measurement System (SMS) scores for all active motor carriers. These scores are derived from violations and then converted into percentiles based on carriers peer groups, which consist of companies of similar sizes for Driver Fitness.',
  vehicleMaintenance:'The FMCSA aggregates Safety Measurement System (SMS) scores for all active motor carriers. These scores are derived from violations and then converted into percentiles based on carriers peer groups, which consist of companies of similar sizes for Vehicle Maintenance.',
  drugsAndAlcohol:'The FMCSA aggregates Safety Measurement System (SMS) scores for all active motor carriers. These scores are derived from violations and then converted into percentiles based on carriers peer groups, which consist of companies of similar sizes for Drugs and Alcohol.',
}
 public static outOfServicePercentages ={
  vehicleOOS: 'This indicator refers to the  Out of service (OOS) for the vehicle that is temporarily or permanently unable to operate due to a variety of reasons. The Federal Motor Carrier Safety Administration (FMCSA) has established strict regulations to ensure the safety of drivers, passengers, and other motorists on the road.',
  driverOOS: 'This indicator refers to the  Out of service (OOS) for the Driver that is temporarily or permanently unable to operate due to a variety of reasons. The Federal Motor Carrier Safety Administration (FMCSA) has established strict regulations to ensure the safety of drivers, passengers, and other motorists on the road.',
  hazmatOOS: 'This indicator refers to the  Out of service (OOS) for the Hazmat Vehicles that are temporarily or permanently unable to operate due to a variety of reasons. The Federal Motor Carrier Safety Administration (FMCSA) has established strict regulations to ensure the safety of drivers, passengers, and other motorists on the road.'
 }

 public static authorityAndInsurance ={

 property: 'This tile shows the Operating Authority status for Property. We monitor authority decisions every day and notify you of any changes to your carriers. This includes when a carrier’s authority is put on notice, deactivated, or transferred to a new company. ',
 
 passenger:' This tile shows the Operating Authority status for Passenger. We monitor authority decisions every day and notify you of any changes to your carriers. This includes when a carrier’s authority is put on notice, deactivated, or transferred to a new company.' ,
 
 householdGoods: 'This tile shows the Operating Authority status for Household Goods. We monitor authority decisions every day and notify you of any changes to your carriers. This includes when a carrier’s authority is put on notice, deactivated, or transferred to a new company.' 
 }
}