import { Component, Inject, OnInit, ViewChild,ElementRef } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/commons/service/shared.service';
import { AlertService } from '../../commons/service/alert.service';
import { CommonService } from '../../commons/service/common.service';
import { AppSettings } from '../../commons/setting/app_setting';
import { environment } from 'src/environments/environment';

export interface DialogData {
  openPop: string;
  type: string;
  id:any;
  bidQuoteDetail: any;
  laneShipperDetail:any;
  laneDetail:any;
  pageType:any;
}

@Component({
  selector: 'app-bid-quote-assistance-popup',
  templateUrl: './bid-quote-assistance-popup.component.html',
  styleUrls: ['./bid-quote-assistance-popup.component.scss']
})
export class BidQuoteAssistancePopupComponent implements OnInit {
  @ViewChild("personalized") personalizedData: ElementRef;
  @ViewChild("milesone") milesData: ElementRef;
  public loading = false;
  public bidQuoteFormData: FormGroup;
  public submitted: boolean = false;
  public driverFuelExpansion: boolean = false;
  public userPrice='auto';
  public truckMilesPerGallon: any = 0;
  public dieselPricePerGal: any = 0;
  public driverWagePerMile: any = 0;
  public monthlyMilesDriven: any = 0;
  public pricePerGallonOfDieselPerMile: any = 0;

  public truckMilesPerGallonFixed: any = 0;
  public dieselPricePerGalFixed: any = 0;
  public driverWagePerMileFixed: any = 0;
  public monthlyMilesDrivenFixed: any = 0;
  public pricePerGallonOfDieselPerMileFixed: any = 0;
  public totalRatePerMileFixed: any = 0;

  public insurancePremium: any = 0;
  public insuranceWorkersComp: any = 0;
  public insuranceHealth: any = 0;
  public trailerPayment: any = 0;
  public permits: any = 0;
  public parkingExpense: any = 0;
  public licensePlates: any = 0;
  public monthlyFixExpensessPerMile: any = 0;

  public monthlyFixExpensessPerMileFixed: any = 0;

  public fuel: any = 0;
  public meals: any = 0;
  public telephone: any = 0;
  public tolls: any = 0;
  public lumperFees: any = 0;
  public tires: any = 0;
  public maintenance: any = 0;
  public factoringFees: any = 0;
  public miscellaneousExpenses: any = 0;
  public monthlyVariableExpensessPerMile: any = 0;
  public monthlyVariableExpensessPerMileFixed: any = 0;

  public accounting: any = 0;
  public internationalRegistrationPlan: any = 0;
  public subscriptions: any = 0;

  public driverFuelManagementTotal: any = 0;
  public monthlyFixExpensessTotal: any = 0;
  public monthlyVariableExpensessTotal: any = 0;
  public annualOverheadItemsTotal: any = 0;

  public driverFuelManagementTotalFixed: any = 0;
  public monthlyFixExpensessTotalFixed: any = 0;
  public monthlyVariableExpensessTotalFixed: any = 0;
  public annualOverheadItemsTotalFixed: any = 0;

  public annualOverheadCostPerMile: any = 0;
  public totalRatePerMileTotal: any;
  public annualOverheadCostPerMileFixed: any = 0;
  public totalRatePerMileTotalFixed: any;
  public totalRatePerMileTotalPersonal: any;
  public totalLanemiles = 0;
  public customTotalMiles:any = 0;
  public editablePrice:any;
  public editableMiles:any;
  public showEditPrice = false;
  public showEditMiles = false;
  public buttonType:any;
  public totalRatePerMile : any = 0;
  public fixedRateInformation = 'This quote rate is fixed with an estimated rate per mile and is calculated based on the cost estimates saved in the sections Driver and Fuel Management, Monthly Fixed & Variable Cost and Annual Overhead.'
  public personalisedRateInformation ='This quote rate is dynamic and is calculated based on the current changes made to your cost in the sections Driver and Fuel Management, Monthly Fixed & Variable Cost and Annual Overhead.'
  public patternZeroro = '^([0-9]*[1-9][0-9]*(.[0-9]+)?|[0]*.[0-9]*[1-9][0-9]*)$';
  public buttonDisabled=false;
  public serverPathForUrl: any;

  constructor(
    private formBuilder: FormBuilder,
    public sharedService: SharedService,
    private commonService: CommonService,
    private router: Router,
    private alertService: AlertService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<BidQuoteAssistancePopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit(): void {
    this.serverPathForUrl = environment.serverPathForUrl;
    if(this.data.openPop=='updateBidQuote'){
      this.buttonType='submit';
    }else{
      this.buttonType='button';
    }
    if(this.data.openPop=='updateBidQuote' || this.data.openPop=='bidQuoteAssistant'){
      this.totalLanemiles=this.data.bidQuoteDetail.totalLanemiles;
      this.editableMiles = this.data.bidQuoteDetail.totalLanemiles;
      this.bidQuoteFormData = this.formBuilder.group({
        truckMilesPerGallon:  [this.data.bidQuoteDetail.trucksMilesPerGal.value ? this.data.bidQuoteDetail.trucksMilesPerGal.value : '' ,  [Validators.required,Validators.pattern(this.patternZeroro)]],
        pricePerGallon: [this.data.bidQuoteDetail.dieselPricePerGal.value ? this.data.bidQuoteDetail.dieselPricePerGal.value : '' , [Validators.required, Validators.pattern(this.patternZeroro)]],
        driverWagePerMile: [this.data.bidQuoteDetail.driverWagePerMile.value ? this.data.bidQuoteDetail.driverWagePerMile.value : '', [Validators.required, Validators.pattern(this.patternZeroro)]],
        monthlyMilesDriven: [this.data.bidQuoteDetail.monthlyMilesDriven.value ? this.data.bidQuoteDetail.monthlyMilesDriven.value : '', [Validators.required, Validators.pattern(this.patternZeroro)]],
        insurancePremium: [this.data.bidQuoteDetail.insurancePremium.value ? this.data.bidQuoteDetail.insurancePremium.value : '' ],
        insuranceWorkersComp: [this.data.bidQuoteDetail.insuranceWorkersComp.value ? this.data.bidQuoteDetail.insuranceWorkersComp.value  : ''],
        insuranceHealth: [this.data.bidQuoteDetail.insuranceHealth.value ? this.data.bidQuoteDetail.insuranceHealth.value : '' ],
        trailerPayment: [this.data.bidQuoteDetail.trailerPayment.value ? this.data.bidQuoteDetail.trailerPayment.value : ''],
        permits: [this.data.bidQuoteDetail.permits.value ? this.data.bidQuoteDetail.permits.value :''],
        parkingExpense: [this.data.bidQuoteDetail.parkingExpense.value ? this.data.bidQuoteDetail.parkingExpense.value : ''],
        licensePlates: [this.data.bidQuoteDetail.licensePlates.value ? this.data.bidQuoteDetail.licensePlates.value :''],
        fuel: [this.data.bidQuoteDetail.fuel.value ? this.data.bidQuoteDetail.fuel.value : ''],
        meals: [this.data.bidQuoteDetail.meals.value ? this.data.bidQuoteDetail.meals.value :''] ,
        telephone: [this.data.bidQuoteDetail.telephone.value ? this.data.bidQuoteDetail.telephone.value :''],
        tolls: [this.data.bidQuoteDetail.tolls.value ? this.data.bidQuoteDetail.tolls.value : ''],
        lumperFees: [this.data.bidQuoteDetail.lumperFees.value ? this.data.bidQuoteDetail.lumperFees.value : ''],
        tires: [this.data.bidQuoteDetail.tires.value ? this.data.bidQuoteDetail.tires.value : '' ],
        maintenance: [this.data.bidQuoteDetail.maintenance.value ? this.data.bidQuoteDetail.maintenance.value : ''],
        factoringFees: [this.data.bidQuoteDetail.factoringFees.value ? this.data.bidQuoteDetail.factoringFees.value : ""],
        miscellaneousExpenses: [this.data.bidQuoteDetail.miscellaneousExpenses.value ? this.data.bidQuoteDetail.miscellaneousExpenses.value :''],
        accounting: [this.data.bidQuoteDetail.accounting.value ? this.data.bidQuoteDetail.accounting.value :''],
        internationalRegistrationPlan: [this.data.bidQuoteDetail.internationalRegistrationPlan.value ? this.data.bidQuoteDetail.internationalRegistrationPlan.value :''],
        subscriptions: [this.data.bidQuoteDetail.subscriptions.value ? this.data.bidQuoteDetail.subscriptions.value :''],
      })  

      if(this.data.bidQuoteDetail){     
      this.truckMilesPerGallon = Number(this.data.bidQuoteDetail.trucksMilesPerGal.value);
      this.monthlyMilesDriven = Number(this.data.bidQuoteDetail.monthlyMilesDriven.value);
      this.dieselPricePerGal = Number(this.data.bidQuoteDetail.dieselPricePerGal.value);
      this.driverWagePerMile = Number(this.data.bidQuoteDetail.driverWagePerMile.value);

      this.insurancePremium = Number(this.data.bidQuoteDetail.insurancePremium.value);
      this.insuranceWorkersComp = Number(this.data.bidQuoteDetail.insuranceWorkersComp.value);
      this.insuranceHealth = Number(this.data.bidQuoteDetail.insuranceHealth.value);
      this.trailerPayment = Number(this.data.bidQuoteDetail.trailerPayment.value);
      this.permits = Number(this.data.bidQuoteDetail.permits.value);
      this.parkingExpense = Number(this.data.bidQuoteDetail.parkingExpense.value);
      this.licensePlates = Number(this.data.bidQuoteDetail.licensePlates.value);

      this.fuel = Number(this.data.bidQuoteDetail.fuel.value);
      this.meals = Number(this.data.bidQuoteDetail.meals.value);
      this.telephone = Number(this.data.bidQuoteDetail.telephone.value);
      this.tolls = Number(this.data.bidQuoteDetail.tolls.value);
      this.lumperFees = Number(this.data.bidQuoteDetail.lumperFees.value);
      this.tires = Number(this.data.bidQuoteDetail.tires.value);
      this.maintenance = Number(this.data.bidQuoteDetail.maintenance.value);
      this.factoringFees = Number(this.data.bidQuoteDetail.factoringFees.value);
      this.miscellaneousExpenses = Number(this.data.bidQuoteDetail.miscellaneousExpenses.value);

      this.accounting = Number(this.data.bidQuoteDetail.accounting.value);
      this.internationalRegistrationPlan = Number(this.data.bidQuoteDetail.internationalRegistrationPlan.value);
      this.subscriptions = Number(this.data.bidQuoteDetail.subscriptions.value);

      this.truckMilesPerGallonFixed = Number(this.data.bidQuoteDetail.trucksMilesPerGal.value);
      this.monthlyMilesDrivenFixed = Number(this.data.bidQuoteDetail.monthlyMilesDriven.value);
      this.dieselPricePerGalFixed = Number(this.data.bidQuoteDetail.dieselPricePerGal.value);
      this.driverWagePerMileFixed = Number(this.data.bidQuoteDetail.driverWagePerMile.value);   

        this.driverFuelManagementFixed(this.data.bidQuoteDetail.dieselPricePerGal.value,this.data.bidQuoteDetail.driverWagePerMile.value);
        
        this.monthlyFixExpensessFixed(this.data.bidQuoteDetail.insurancePremium.value,this.data.bidQuoteDetail.insuranceWorkersComp.value,this.data.bidQuoteDetail.insuranceHealth.value, this.data.bidQuoteDetail.trailerPayment.value,this.data.bidQuoteDetail.permits.value,this.data.bidQuoteDetail.parkingExpense.value,this.data.bidQuoteDetail.licensePlates.value);
        this.monthlyVariableExpensessFixed(this.data.bidQuoteDetail.fuel.value,this.data.bidQuoteDetail.meals.value,this.data.bidQuoteDetail.telephone.value, this.data.bidQuoteDetail.tolls.value,this.data.bidQuoteDetail.lumperFees.value,this.data.bidQuoteDetail.tires.value,this.data.bidQuoteDetail.maintenance.value,this.data.bidQuoteDetail.factoringFees.value,this.data.bidQuoteDetail.miscellaneousExpenses.value);
        this.annualOverheadItemsFixed(this.data.bidQuoteDetail.accounting.value,this.data.bidQuoteDetail.internationalRegistrationPlan.value,this.data.bidQuoteDetail.subscriptions.value);

        this.driverFuelManagement(this.data.bidQuoteDetail.dieselPricePerGal.value,this.data.bidQuoteDetail.driverWagePerMile.value);
        this.monthlyFixExpensess(this.data.bidQuoteDetail.insurancePremium.value,this.data.bidQuoteDetail.insuranceWorkersComp.value,this.data.bidQuoteDetail.insuranceHealth.value, this.data.bidQuoteDetail.trailerPayment.value,this.data.bidQuoteDetail.permits.value,this.data.bidQuoteDetail.parkingExpense.value,this.data.bidQuoteDetail.licensePlates.value);
        this.monthlyVariableExpensess(this.data.bidQuoteDetail.fuel.value,this.data.bidQuoteDetail.meals.value,this.data.bidQuoteDetail.telephone.value, this.data.bidQuoteDetail.tolls.value,this.data.bidQuoteDetail.lumperFees.value,this.data.bidQuoteDetail.tires.value,this.data.bidQuoteDetail.maintenance.value,this.data.bidQuoteDetail.factoringFees.value,this.data.bidQuoteDetail.miscellaneousExpenses.value);
        this.annualOverheadItems(this.data.bidQuoteDetail.accounting.value,this.data.bidQuoteDetail.internationalRegistrationPlan.value,this.data.bidQuoteDetail.subscriptions.value);
        this.totalRatePerMileFun();
        this.totalRatePerMileFunFixed();
      }  
    } 
  }

  // Driver and Fuel Management to get total Fix value of diesel Price PerGallon and driver Wage PerMile
  driverFuelManagementFixed(dieselPricePerGal: any, driverWagePerMile: any) {
    this.driverFuelManagementTotalFixed = Number(dieselPricePerGal) + Number(driverWagePerMile);
    this.driverFuelManagementTotalFixed = this.driverFuelManagementTotalFixed.toFixed(2);
    this.pricePerGallonOfDieselPerMileFunFixed();
  }

  // To get value of Price per Gallon of diesel per mile fixed 
  pricePerGallonOfDieselPerMileFunFixed() {
    if (this.driverFuelManagementTotalFixed > 0) {
      this.pricePerGallonOfDieselPerMileFixed = ((this.monthlyMilesDrivenFixed / this.truckMilesPerGallonFixed) * this.dieselPricePerGalFixed) / this.monthlyMilesDrivenFixed;
      this.pricePerGallonOfDieselPerMileFixed = Number(this.pricePerGallonOfDieselPerMileFixed).toFixed(2);
    } else {
      this.pricePerGallonOfDieselPerMileFixed = 0;
    }
    this.totalRatePerMileFunFixed();
  }

  // Driver and Fuel Management to get total value of diesel Price PerGallon and driver Wage PerMile
  driverFuelManagement(dieselPricePerGal: any, driverWagePerMile: any) {
    this.driverFuelManagementTotal = Number(dieselPricePerGal) + Number(driverWagePerMile);
    this.driverFuelManagementTotal = this.driverFuelManagementTotal.toFixed(2);
    this.pricePerGallonOfDieselPerMileFun();
  }

  // function for Price per Gallon of diesel per mile calculation 
  pricePerGallonOfDieselPerMileFun() {
    if (this.driverFuelManagementTotal > 0) {
      this.pricePerGallonOfDieselPerMile = ((Number(this.monthlyMilesDriven) / Number(this.truckMilesPerGallon)) * Number(this.dieselPricePerGal)) / Number(this.monthlyMilesDriven);
      if(!isNaN(this.pricePerGallonOfDieselPerMile) && isFinite(this.pricePerGallonOfDieselPerMile)){
        this.pricePerGallonOfDieselPerMile = Number(this.pricePerGallonOfDieselPerMile).toFixed(2);
      }else{
        this.pricePerGallonOfDieselPerMile = Number(0);
      }
    } else {
      this.pricePerGallonOfDieselPerMile = Number(0);
    }
    this.totalRatePerMileFun();
  }

  // Truck miles per gallon values set by user input
  removeDecimal(value, formControl) {
    let roundOffValue = parseFloat(value).toFixed(0)
    this.bidQuoteFormData.get(formControl).setValue(roundOffValue);
  }

  truckMilesPerGallonFun(event: any, type: any) {
    if (type == "truckMilesPerGallon") {
    this.truckMilesPerGallon = event;
      this.bidQuoteFormData.get("truckMilesPerGallon").setValue(event);
      this.driverFuelManagement(this.dieselPricePerGal, this.driverWagePerMile);
    } else if (type == "dieselPricePerGal") {
      this.dieselPricePerGal = event;
      this.bidQuoteFormData.get("pricePerGallon").setValue(event);
      this.driverFuelManagement(this.dieselPricePerGal, this.driverWagePerMile);
    } else if (type == "driverWagePerMile") {
      this.driverWagePerMile = event;
      this.bidQuoteFormData.get("driverWagePerMile").setValue(event);
      this.driverFuelManagement(this.dieselPricePerGal, this.driverWagePerMile);
    } else if (type == "monthlyMilesDriven") {
      this.monthlyMilesDriven = event;
      this.bidQuoteFormData.get("monthlyMilesDriven").setValue(event);
      this.driverFuelManagement(this.dieselPricePerGal, this.driverWagePerMile);
    }
  }

  // To get total value of Monthly Fixed Expenses
  monthlyFixExpensessFixed(
    insurancePremium: any,
    insuranceWorkersComp: any,
    insuranceHealth: any,
    trailerPayment: any,
    permits: any,
    parkingExpense: any,
    licensePlates: any
  ) {
    this.monthlyFixExpensessTotalFixed =
      Number(insurancePremium) +
      Number(insuranceWorkersComp) +
      Number(insuranceHealth) +
      Number(trailerPayment) +
      Number(permits) +
      Number(parkingExpense) +
      Number(licensePlates);
    this.monthlyFixExpensessTotalFixed =
      this.monthlyFixExpensessTotalFixed.toFixed(2);
    this.monthlyFixExpensessPerMileFunFixed(
      insurancePremium,
      insuranceWorkersComp,
      insuranceHealth,
      trailerPayment,
      permits,
      parkingExpense,
      licensePlates
    );
  }

  // To get Monthly Fixed Expenses per mile
  monthlyFixExpensessPerMileFunFixed(
    insurancePremium: any,
    insuranceWorkersComp: any,
    insuranceHealth: any,
    trailerPayment: any,
    permits: any,
    parkingExpense: any,
    licensePlates: any
  ) {
    if (this.monthlyFixExpensessTotalFixed > 0) {
      this.monthlyFixExpensessPerMileFixed =
        Number(insurancePremium / this.monthlyMilesDrivenFixed) +
        Number(insuranceWorkersComp / this.monthlyMilesDrivenFixed) +
        Number(insuranceHealth / this.monthlyMilesDrivenFixed) +
        Number(trailerPayment / this.monthlyMilesDrivenFixed) +
        Number(permits / this.monthlyMilesDrivenFixed) +
        Number(parkingExpense / this.monthlyMilesDrivenFixed) +
        Number(licensePlates / this.monthlyMilesDrivenFixed);
      this.monthlyFixExpensessPerMileFixed = Number(
        this.monthlyFixExpensessPerMileFixed
      ).toFixed(2);
    } else {
      this.monthlyFixExpensessPerMileFixed = 0;
    }
    this.totalRatePerMileFunFixed();
  }

  // To get total fix Monthly Expenses 
  monthlyFixExpensess(
    insurancePremium: any,
    insuranceWorkersComp: any,
    insuranceHealth: any,
    trailerPayment: any,
    permits: any,
    parkingExpense: any,
    licensePlates: any
  ) {
    this.monthlyFixExpensessTotal =
      Number(insurancePremium) +
      Number(insuranceWorkersComp) +
      Number(insuranceHealth) +
      Number(trailerPayment) +
      Number(permits) +
      Number(parkingExpense) +
      Number(licensePlates);
    this.monthlyFixExpensessTotal = Number(
      this.monthlyFixExpensessTotal
    ).toFixed(2);
    this.monthlyFixExpensessPerMileFun(
      insurancePremium,
      insuranceWorkersComp,
      insuranceHealth,
      trailerPayment,
      permits,
      parkingExpense,
      licensePlates
    );
  }

  // Monthly Fixed Expenses per mile function formula
  monthlyFixExpensessPerMileFun(
    insurancePremium: any,
    insuranceWorkersComp: any,
    insuranceHealth: any,
    trailerPayment: any,
    permits: any,
    parkingExpense: any,
    licensePlates: any
  ) {
    if (this.monthlyFixExpensessTotal > 0) {
      this.monthlyFixExpensessPerMile =
        Number(insurancePremium / this.monthlyMilesDriven) +
        Number(insuranceWorkersComp / this.monthlyMilesDriven) +
        Number(insuranceHealth / this.monthlyMilesDriven) +
        Number(trailerPayment / this.monthlyMilesDriven) +
        Number(permits / this.monthlyMilesDriven) +
        Number(parkingExpense / this.monthlyMilesDriven) +
        Number(licensePlates / this.monthlyMilesDriven);
      this.monthlyFixExpensessPerMile = Number(
        this.monthlyFixExpensessPerMile
      ).toFixed(2);
    } else {
      this.monthlyFixExpensessPerMile = 0;
    }
    this.totalRatePerMileFun();
  }

  // Monthly Fixed Expenses values set by user input
  monthlyFixExpensessFun(event: any, type: any) {
    if (type == "insurancePremium") {
      this.insurancePremium = event;
      this.bidQuoteFormData.get("insurancePremium").setValue(event);
    }
    if (type == "insuranceWorkersComp") {
      this.insuranceWorkersComp = event;
      this.bidQuoteFormData.get("insuranceWorkersComp").setValue(event);
    }
    if (type == "insuranceHealth") {
      this.insuranceHealth = event;
      this.bidQuoteFormData.get("insuranceHealth").setValue(event);
    }
    if (type == "trailerPayment") {
      this.trailerPayment = event;
      this.bidQuoteFormData.get("trailerPayment").setValue(event);
    }
    if (type == "permits") {
      this.permits = event;
      this.bidQuoteFormData.get("permits").setValue(event);
    }
    if (type == "parkingExpense") {
      this.parkingExpense = event;
      this.bidQuoteFormData.get("parkingExpense").setValue(event);
    }
    if (type == "licensePlates") {
      this.licensePlates = event;
      this.bidQuoteFormData.get("licensePlates").setValue(event);
    }
    this.monthlyFixExpensess(
      this.insurancePremium,
      this.insuranceWorkersComp,
      this.insuranceHealth,
      this.trailerPayment,
      this.permits,
      this.parkingExpense,
      this.licensePlates
    );
  }

  // To get total value of fixed Monthly variable expensess
  monthlyVariableExpensessFixed(
    fuel: any,
    meals: any,
    telephone: any,
    tolls: any,
    lumperFees: any,
    tires: any,
    maintenance: any,
    factoringFees: any,
    miscellaneousExpenses: any
  ) {
    this.monthlyVariableExpensessTotalFixed =
      Number(fuel) +
      Number(meals) +
      Number(telephone) +
      Number(tolls) +
      Number(lumperFees) +
      Number(tires) +
      Number(maintenance) +
      Number(factoringFees) +
      Number(miscellaneousExpenses);
    this.monthlyVariableExpensessTotalFixed =
      this.monthlyVariableExpensessTotalFixed.toFixed(2);
    this.monthlyVariableExpensessPerMileFunFixed(
      fuel,
      meals,
      telephone,
      tolls,
      lumperFees,
      tires,
      maintenance,
      factoringFees,
      miscellaneousExpenses
    );
  }

  // to get value of Fixed Monthly variable expensess per mile
  monthlyVariableExpensessPerMileFunFixed(
    fuel: any,
    meals: any,
    telephone: any,
    tolls: any,
    lumperFees: any,
    tires: any,
    maintenance: any,
    factoringFees: any,
    miscellaneousExpenses: any
  ) {
    if (this.monthlyVariableExpensessTotalFixed > 0) {
      this.monthlyVariableExpensessPerMileFixed =
        fuel / this.monthlyMilesDrivenFixed +
        meals / this.monthlyMilesDrivenFixed +
        telephone / this.monthlyMilesDrivenFixed +
        tolls / this.monthlyMilesDrivenFixed +
        lumperFees / this.monthlyMilesDrivenFixed +
        tires / this.monthlyMilesDrivenFixed +
        maintenance / this.monthlyMilesDrivenFixed +
        factoringFees / this.monthlyMilesDrivenFixed +
        miscellaneousExpenses / this.monthlyMilesDrivenFixed;
      this.monthlyVariableExpensessPerMileFixed = Number(
        this.monthlyVariableExpensessPerMileFixed
      ).toFixed(2);
    } else {
      this.monthlyVariableExpensessPerMileFixed = 0;
    }
    this.totalRatePerMileFunFixed();
  }

  // Function for getting total monthly variable expenses
  monthlyVariableExpensess(
    fuel: any,
    meals: any,
    telephone: any,
    tolls: any,
    lumperFees: any,
    tires: any,
    maintenance: any,
    factoringFees: any,
    miscellaneousExpenses: any
  ) {
    this.monthlyVariableExpensessTotal =
      Number(fuel) +
      Number(meals) +
      Number(telephone) +
      Number(tolls) +
      Number(lumperFees) +
      Number(tires) +
      Number(maintenance) +
      Number(factoringFees) +
      Number(miscellaneousExpenses);
    this.monthlyVariableExpensessTotal =
      this.monthlyVariableExpensessTotal.toFixed(2);
    this.monthlyVariableExpensessPerMileFun(
      fuel,
      meals,
      telephone,
      tolls,
      lumperFees,
      tires,
      maintenance,
      factoringFees,
      miscellaneousExpenses
    );
  }

  // monthly Variable Expensess Per Mile formula
  monthlyVariableExpensessPerMileFun(
    fuel: any,
    meals: any,
    telephone: any,
    tolls: any,
    lumperFees: any,
    tires: any,
    maintenance: any,
    factoringFees: any,
    miscellaneousExpenses: any
  ) {
    if (this.monthlyVariableExpensessTotal > 0) {
      this.monthlyVariableExpensessPerMile =
        fuel / this.monthlyMilesDriven +
        meals / this.monthlyMilesDriven +
        telephone / this.monthlyMilesDriven +
        tolls / this.monthlyMilesDriven +
        lumperFees / this.monthlyMilesDriven +
        tires / this.monthlyMilesDriven +
        maintenance / this.monthlyMilesDriven +
        factoringFees / this.monthlyMilesDriven +
        miscellaneousExpenses / this.monthlyMilesDriven;
      this.monthlyVariableExpensessPerMile = Number(
        this.monthlyVariableExpensessPerMile
      ).toFixed(2);
    } else {
      this.monthlyVariableExpensessPerMile = 0;
    }
    this.totalRatePerMileFun();
  }

  // monthly Variable Expenses value set by user input
  monthlyVariableExpensessFun(event: any, type: any) {
    if (type == "fuel") {
      this.fuel = event;
      this.bidQuoteFormData.get("fuel").setValue(event);
    }
    if (type == "meals") {
      this.meals = event;
      this.bidQuoteFormData.get("meals").setValue(event);
    }
    if (type == "telephone") {
      this.telephone = event;
      this.bidQuoteFormData.get("telephone").setValue(event);
    }
    if (type == "tolls") {
      this.tolls = event;
      this.bidQuoteFormData.get("tolls").setValue(event);
    }
    if (type == "lumperFees") {
      this.lumperFees = event;
      this.bidQuoteFormData.get("lumperFees").setValue(event);
    }
    if (type == "tires") {
      this.tires = event;
      this.bidQuoteFormData.get("tires").setValue(event);
    }
    if (type == "maintenance") {
      this.maintenance = event;
      this.bidQuoteFormData.get("maintenance").setValue(event);
    }
    if (type == "factoringFees") {
      this.factoringFees = event;
      this.bidQuoteFormData.get("factoringFees").setValue(event);
    }
    if (type == "miscellaneousExpenses") {
      this.miscellaneousExpenses = event;
      this.bidQuoteFormData.get("miscellaneousExpenses").setValue(event);
    }
    this.monthlyVariableExpensess(
      this.fuel,
      this.meals,
      this.telephone,
      this.tolls,
      this.lumperFees,
      this.tires,
      this.maintenance,
      this.factoringFees,
      this.miscellaneousExpenses
    );
  }

  // to get the value of total Fixed Annual Overhead Items
  annualOverheadItemsFixed(
    accounting: any,
    internationalRegistrationPlan: any,
    subscriptions: any
  ) {
    this.annualOverheadItemsTotalFixed =
      Number(accounting) +
      Number(internationalRegistrationPlan) +
      Number(subscriptions);
    this.annualOverheadItemsTotalFixed =
      this.annualOverheadItemsTotalFixed.toFixed(2);
    this.annualOverheadCostPerMileFunFixed();
  }

  // Fixed Annual Overhead Cost Per Mile
  annualOverheadCostPerMileFunFixed() {
    if (
      this.annualOverheadItemsTotalFixed > 0 &&
      this.monthlyMilesDrivenFixed > 0
    ) {
      let monthlyMilesDrivenFixedTotal = this.monthlyMilesDrivenFixed * 12;
      this.annualOverheadCostPerMileFixed =
        this.annualOverheadItemsTotalFixed / monthlyMilesDrivenFixedTotal;
      this.annualOverheadCostPerMileFixed = Number(
        this.annualOverheadCostPerMileFixed
      ).toFixed(2);
    } else {
      this.annualOverheadCostPerMileFixed = 0;
    }

    this.totalRatePerMileFunFixed();
  }

  // to get the value of total Annual Overhead Items
  annualOverheadItems(
    accounting: any,
    internationalRegistrationPlan: any,
    subscriptions: any
  ) {
    this.annualOverheadItemsTotal =
      Number(accounting) +
      Number(internationalRegistrationPlan) +
      Number(subscriptions);
    this.annualOverheadItemsTotal = this.annualOverheadItemsTotal.toFixed(2);
    this.annualOverheadCostPerMileFun();
  }

  // to get the Annual Overhead Cost Per Mile calculation
  annualOverheadCostPerMileFun() {
    if (this.annualOverheadItemsTotal > 0 && this.monthlyMilesDriven > 0) {
      let monthlyMilesDrivenFixedTotal = this.monthlyMilesDriven * 12;
      this.annualOverheadCostPerMile =
        this.annualOverheadItemsTotal / monthlyMilesDrivenFixedTotal;
      this.annualOverheadCostPerMile = Number(
        this.annualOverheadCostPerMile
      ).toFixed(2);
    } else {
      this.annualOverheadCostPerMile = 0;
    }
    this.totalRatePerMileFun();
  }

  // Annual Overhead items value set by user input
  annualOverheadItemsFun(event: any, type: any) {
    if (type == "accounting") {
      this.accounting = event;
      this.bidQuoteFormData.get("accounting").setValue(event);
    }
    if (type == "internationalRegistrationPlan") {
      this.internationalRegistrationPlan = event;
      this.bidQuoteFormData.get("internationalRegistrationPlan").setValue(event);
    }
    if (type == "subscriptions") {
      this.subscriptions = event;
      this.bidQuoteFormData.get("subscriptions").setValue(event);
    }
    this.annualOverheadItems(
      this.accounting,
      this.internationalRegistrationPlan,
      this.subscriptions
    );
  }

  // To get the value of Fixed total rate Per Mile
  totalRatePerMileFunFixed() {
    this.customTotalMiles=null;
    this.totalRatePerMileFixed = Number(this.pricePerGallonOfDieselPerMileFixed) + Number(this.monthlyFixExpensessPerMileFixed) + Number(this.monthlyVariableExpensessPerMileFixed) + Number(this.annualOverheadCostPerMileFixed) + Number(this.driverWagePerMile);
    this.totalRatePerMileFixed = this.totalRatePerMileFixed.toFixed(2) ;
    if (this.totalLanemiles) {
       this.totalRatePerMileTotalFixed = (Number(this.totalRatePerMileFixed) * Number(this.totalLanemiles)).toFixed(2);
    } else {
      this.totalRatePerMileTotalFixed = (Number(this.totalRatePerMileFixed) * Number(this.totalLanemiles)).toFixed(2);
    }
  }

  // total rate Per Mile
  totalRatePerMileFun() {
    this.customTotalMiles=null;
    this.totalRatePerMile = Number(this.pricePerGallonOfDieselPerMile) + Number(this.monthlyVariableExpensessPerMile) + Number(this.monthlyFixExpensessPerMile) + Number(this.annualOverheadCostPerMile) + Number(this.driverWagePerMile);
    this.totalRatePerMileFunTotal();
  }

  // To get the value of total rate Per Mile for Personalized Rate
  totalRatePerMileFunTotal() {
    if (this.totalLanemiles) {
      this.totalRatePerMileTotalPersonal = (Number(this.totalRatePerMile) * Number(this.totalLanemiles)).toFixed(2);
    } else {
      this.totalRatePerMileTotalPersonal = (Number(this.totalRatePerMile) * Number(this.totalLanemiles)).toFixed(2);
    }
  }

  // Price edit for Personalized Rate
  editPrice(currentPrice) {
    this.editablePrice=currentPrice;
    this.showEditPrice=true;
    this.showEditMiles=false;
    setTimeout(() => this.personalizedData.nativeElement.focus());
  }  

  // Miles edit for Origin to Destination
  editMiles(miles){
    this.editableMiles = miles
    this.showEditMiles=true;
    this.showEditPrice=false;
    setTimeout(() => this.milesData.nativeElement.focus());
  }
  
  // Miles edit input blur function
  editableMilesBlur(){
    this.showEditMiles=false;
  }

  // Price edit input blur function for Personalized Rate
  editablePriceBlur(){
    this.showEditPrice=false;
  }
  
  // Select rate by radio button 
  selectRate(price){
    this.userPrice=price;
    this.checkUserAmoutAndMiles(price);
  }
  
  // Check Amount and miles for button disable 
  checkUserAmoutAndMiles(event){
    if(this.totalRatePerMileTotalPersonal && this.totalRatePerMileTotalPersonal > 0  && this.totalRatePerMileTotalFixed && this.totalRatePerMileTotalFixed >0){
      this.buttonDisabled=false;
    }else if(!this.totalRatePerMileTotalPersonal  && !this.totalRatePerMileTotalFixed){
      this.buttonDisabled=true;
    }else if(this.userPrice=='personal' && this.totalRatePerMileTotalPersonal > 0){
      this.buttonDisabled=false;
    }else if(this.userPrice=='auto' && this.totalRatePerMileTotalFixed > 0){
      this.buttonDisabled=false;
    }
  }

  // function for getting Personalized Rate
  personalizedAmout(event){
    this.totalRatePerMileTotalPersonal = event;
    if(event && event > 0){
      this.checkUserAmoutAndMiles(event);
    }else{
      this.checkUserAmoutAndMiles(event);
    }
  }

  // Calculated Miles From Origin to Destination
  totalRatePerMileFunTotalPersonal(event) {
    if (event && event > 0) {
      this.customTotalMiles = event;
      this.totalLanemiles=event;
      this.totalRatePerMileTotalFixed = (Number(this.totalRatePerMileFixed ? this.totalRatePerMileFixed : this.totalRatePerMile) * Number(event)).toFixed(2);
      this.totalRatePerMileTotalPersonal = (Number(this.totalRatePerMile) * Number(event)).toFixed(2);
      this.checkUserAmoutAndMiles(event)
    } else {
      this.customTotalMiles='';
      this.totalLanemiles=event;
      this.totalRatePerMileTotalPersonal = (Number(this.totalRatePerMile) * Number(event)).toFixed(2);
      this.totalRatePerMileTotalFixed = (Number(this.totalRatePerMile) * Number(event)).toFixed(2);
      this.checkUserAmoutAndMiles(event)
    }
  }

  // Submit values of Quote FormData to the API
  bidQuoteSumbit({value,valid}){
      this.submitted=true;  
      if (!valid) {
        this.driverFuelExpansion = true;
      }
      if(valid){
        this.submitted=false;  
        let addNewBidQuoteAssistant: any = {};
        addNewBidQuoteAssistant.trucksMilesPerGal = this.bidQuoteFormData.get('truckMilesPerGallon').value;
        addNewBidQuoteAssistant.dieselPricePerGal = this.bidQuoteFormData.get('pricePerGallon').value;
        addNewBidQuoteAssistant.driverWagePerMile = this.bidQuoteFormData.get('driverWagePerMile').value;
        addNewBidQuoteAssistant.monthlyMilesDriven = this.bidQuoteFormData.get('monthlyMilesDriven').value;
        addNewBidQuoteAssistant.insurancePremium = this.bidQuoteFormData.get('insurancePremium').value;
        addNewBidQuoteAssistant.insuranceWorkersComp = this.bidQuoteFormData.get('insuranceWorkersComp').value;
        addNewBidQuoteAssistant.insuranceHealth = this.bidQuoteFormData.get('insuranceHealth').value;
        addNewBidQuoteAssistant.trailerPayment = this.bidQuoteFormData.get('trailerPayment').value;
        addNewBidQuoteAssistant.permits = this.bidQuoteFormData.get('permits').value;
        addNewBidQuoteAssistant.parkingExpense = this.bidQuoteFormData.get('parkingExpense').value;
        addNewBidQuoteAssistant.licensePlates = this.bidQuoteFormData.get('licensePlates').value;
        addNewBidQuoteAssistant.fuel = this.bidQuoteFormData.get('fuel').value;
        addNewBidQuoteAssistant.meals = this.bidQuoteFormData.get('meals').value;
        addNewBidQuoteAssistant.telephone = this.bidQuoteFormData.get('telephone').value;
        addNewBidQuoteAssistant.tolls = this.bidQuoteFormData.get('tolls').value;
        addNewBidQuoteAssistant.lumperFees = this.bidQuoteFormData.get('lumperFees').value;
        addNewBidQuoteAssistant.tires = this.bidQuoteFormData.get('tires').value;
        addNewBidQuoteAssistant.maintenance = this.bidQuoteFormData.get('maintenance').value;
        addNewBidQuoteAssistant.factoringFees = this.bidQuoteFormData.get('factoringFees').value;
        addNewBidQuoteAssistant.miscellaneousExpenses = this.bidQuoteFormData.get('miscellaneousExpenses').value;
        addNewBidQuoteAssistant.accounting = this.bidQuoteFormData.get('accounting').value;
        addNewBidQuoteAssistant.internationalRegistrationPlan = this.bidQuoteFormData.get('internationalRegistrationPlan').value;
        addNewBidQuoteAssistant.subscriptions = this.bidQuoteFormData.get('subscriptions').value;
        this.loading=true;
        let uri = null;
        let APIparams = {
          apiKey: AppSettings.APIsNameArray.SHIPMENT.BIDASSISTANTCREATE,
          uri: uri,
          postBody: addNewBidQuoteAssistant,
        };
        this.commonService.post(APIparams).subscribe((ServerRes) => {
          this.loading=false;
          if (ServerRes.success === true) {
            this.successPopup();
            this.alertService.showNotificationMessage(
              'success',
              'bottom',
              'right',
              'txt_s',
              'check_circle',
              'Quote Assistant',
              ServerRes.message
            );
          } else if (ServerRes.success === false) {
            this.successPopup();
            this.alertService.showNotificationMessage(
              'danger',
              'bottom',
              'right',
              'txt_d',
              'check_circle',
              'Quote Assistant',
              ServerRes.message
            );
          }
        },(error) => {
            this.loading = false;
            this.alertService.showNotificationMessage(
              'danger',
              'bottom',
              'right',
              'txt_d',
              'cancel',
              'Quote Assistant Fail',
              error.error.message   
            )
          });
        }
    }

 // Success popup for API response 
  successPopup(){
      this.dialogRef.close({ event: 'Final' });    
  }

// For Update Quote Assistant popup close 
  onNoClick() {
    this.dialogRef.close({ event: 'close' });
    if(this.data.pageType){

    }else{
      if(this.data.openPop == 'updateBidQuote' ||  this.data.openPop == 'bidQuoteAssistant') {
        this.router.navigateByUrl('network/my-network/connected');
      }   
    }
  }

 // For showing number of trip days 
  public generateFake(count: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes; 
  }

  // for submit Quote Assistant popup at lane view page 
  submitBidQuote({value,valid}){
   if (!valid) {
      this.driverFuelExpansion = true;
      this.submitted=true;  
   }
   if (valid) {
    this.submitted = false;  
    let priceAmout;
    if(this.userPrice=="auto"){
      priceAmout = this.totalRatePerMileTotalFixed
    }else{
      priceAmout = this.totalRatePerMileTotalPersonal
    }
    if(this.data.pageType=='shipment'){
      this.dialogRef.close({ event: 'shipment', priceAmout: priceAmout});    
    }else if(this.data.pageType=='lane'){
      this.dialogRef.close({ event: 'lane', priceAmout: priceAmout});    
    }
   }
  }

  convertPixels(length) {
    if(length)
      return ((length.toString().length*10))
    else 
      return 31
  }


}
