import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../commons/guard/auth.guard';
import { PendingChangesGuard } from '../commons/guard/pending-changes.guard';
import { ShipmentCreatedByMeComponent } from './shipment-created-by-me/shipment-created-by-me.component';

const routes: Routes = [
  {
    path: 'load-created-by-me/:tabType',
    component: ShipmentCreatedByMeComponent,
    canActivate: [AuthGuard],
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'load-created-by-me/:tabType',
    children:[{path:'load/view', loadChildren: () =>
      import('../load/pages/view/view.module').then((mod) => mod.ViewModule)}]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ShipmentCreatedByMeRoutingModule { }
