<div class="vv_first_time_popup" *ngIf="data?.openPop == 'updateBidQuote' || data?.openPop == 'bidQuoteAssistant'"> 

  <div class="assits_popup"  *ngIf="data?.openPop == 'bidQuoteAssistant'" >
    <div class="p_reletive">
      <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon pointer p_absolute" (click)="onNoClick()"></div>
    <div class="input_box_title line_before">Quote Assistant</div>
    <div class="gbw_box">
        <div>
            <div class="box1 box">
              <span matTooltip="{{fixedRateInformation}}" class="material-icons-outlined info_icon pointer">info</span>
              <p class="mil_rate">{{totalRatePerMileTotalFixed ?'$'+totalRatePerMileTotalFixed : '-'}}</p>
              <span class="rate_p_m">${{totalRatePerMileFixed}} Rate per mile</span>
            </div>
            <mat-radio-group aria-label="Select an option">
                <mat-radio-button  (click)="selectRate('auto')" [checked]="userPrice == 'auto'"  [value]="auto" class="mt-3">Use This Rate</mat-radio-button>
            </mat-radio-group>
        </div>
        <div>
            <div class="box2 box">
              <span matTooltip="{{personalisedRateInformation}}" class="material-icons-outlined info_icon pointer">info</span>

              <p *ngIf="!showEditPrice" class="mil_rate">{{totalRatePerMileTotalPersonal ? '$'+totalRatePerMileTotalPersonal : '-' }}
                <span class="material-icons-outlined edit_icon pointer" (click)="editPrice(editablePrice)"> edit </span>
              </p>
                <input  #personalized (blur)='editablePriceBlur()' class="mil_rate pro_inpute" *ngIf="showEditPrice" matInput [(ngModel)]="totalRatePerMileTotalPersonal"  (input)="personalizedAmout($event.target.value)" maxlength="12" appTwoDigitDecimal>
                <span class="rate_p_m txt_b">Personalized Rate</span>
            </div>
            <mat-radio-group aria-label="Select an option">
                <mat-radio-button (click)="selectRate('personal')" [checked]="userPrice == 'personal'" [value]="personal" class="mt-3">Use This Rate</mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
    <div class="box3 box">
        <p class="fs_16 txt_b">Calculated Miles From Origin to Destination</p>
        <div class="miles_input" *ngIf="!showEditMiles">
            <span class="fs_24 fsb_6">{{editableMiles}}</span><span class="fs_16 ms-1 fsb_6"> {{' Miles'}}</span>
            <span class="material-icons-outlined edit_icon pointer" (click)="editMiles(editableMiles)">mode_edit</span>
        </div>
        <input #milesone *ngIf="showEditMiles" (blur)='editableMilesBlur()' (input)="totalRatePerMileFunTotalPersonal($event.target.value)" matInput  [(ngModel)]="editableMiles" class="edit_mils pro_inpute" maxlength="6" appTwoDigitDecimal>
    </div>
    <div class="circle_box">
        <p class="nu_title">Trip Days</p>
        <div class="latters_circles" *ngFor="let fake of generateFake(9)">
          <div class="circles pointer" [ngClass]="(this.data.bidQuoteDetail.tripdays == fake+1 ) ? 'active_circles' : (this.data.bidQuoteDetail.tripdays > 8 && fake == 8)  ? 'active_circles' : ''" >
            <span>{{fake+1}}</span>
        </div>
           </div>
    </div>
</div>
  
   <div class="assits_popup update_bid_quote"><div  *ngIf="data?.openPop == 'updateBidQuote'">
        <div class="input_box_title line_before">Update your Quote Assistant </div>
        <p class="fs_14 txt_lb mt-4">Please update your cost information and save it to enable a personalized rate based on
            your expenses.</p></div>
    <form [formGroup]="bidQuoteFormData" (ngSubmit)="bidQuoteSumbit(bidQuoteFormData)" class="mb-0 custum_input_css" autocomplete="off">
        <div id="qoute_bid_popup">
            <div class="p_10">
                  <div class="progress_input_box">
                      <mat-accordion>
                          <mat-expansion-panel [expanded]="driverFuelExpansion" (opened)="driverFuelExpansion = true" (closed)="driverFuelExpansion = false" class="mt-2">
                              <mat-expansion-panel-header>
                                  <mat-panel-title>
                                      <div class="L_title_btn"><span class="progress_input_box_title">Driver and Fuel Management</span></div>
                                  </mat-panel-title>
                                  <mat-panel-description>
                                      {{driverFuelManagementTotal ? '$' + driverFuelManagementTotal : ''}}
                                  </mat-panel-description>
                              </mat-expansion-panel-header>
                              <div class="row pro_row">
                                  <div class="col-md-6">
                                      <div class="progress_lab_bar">
                                        <mat-form-field appearance="none">                                            
                                          <p class="progress_lable m-0"><span class="fs_16">Truck's Miles Per Gallon<span class="text-danger ml_5"> *</span></span>
                                              <span class="fs_14 d-flex align-items-center">
                                                  <span *ngIf="data?.bidQuoteDetail?.trucksMilesPerGal?.isCurrency" class="p_absulute_45 margin_right_5">$</span>
                                                  <input matInput formControlName="truckMilesPerGallon" id="truckMilesPerGallonp" placeholder="0.00" (focusout)="removeDecimal(bidQuoteFormData.get('truckMilesPerGallon').value, 'truckMilesPerGallon')" (input)="truckMilesPerGallonFun($event.target.value,'truckMilesPerGallon')" appTwoDigitDecimal maxlength="5" [ngStyle]="{'width.px': convertPixels((bidQuoteFormData.get('truckMilesPerGallon').value))}" class="pro_inpute">
                                              </span> 
                                            </p>
                                            <section class="example-section" >
                                              <mat-slider  [min]="0" [max]='data?.bidQuoteDetail?.trucksMilesPerGal?.maxCap' [step]="data?.bidQuoteDetail?.trucksMilesPerGal?.interval" [value]="truckMilesPerGallon" (input)="truckMilesPerGallonFun($event.target.value,'truckMilesPerGallon')">
                                              <input matSliderThumb [(ngModel)]="truckMilesPerGallon" formControlName="truckMilesPerGallon" #slider>
                                            </mat-slider>
                                          </section>                                     
                                            <mat-error *ngIf="bidQuoteFormData?.get('truckMilesPerGallon').errors?.['required']"
                                            class="error_mess">Truck's Miles Per Gallon is required.</mat-error>
                                            <mat-error *ngIf="bidQuoteFormData?.get('truckMilesPerGallon').errors?.['pattern']"
                                            class="error_mess">Truck's Miles Per Gallon should not be 0.</mat-error>
                                        </mat-form-field>
                                      </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div class="progress_lab_bar">
                                      <mat-form-field appearance="none">                                            
                                        <p class="progress_lable m-0"><span class="fs_16">Price Per Gallon of Diesel<span class="text-danger ml_5"> *</span></span>
                                            <span class="fs_14 d-flex align-items-center">
                                                <span *ngIf="data?.bidQuoteDetail?.dieselPricePerGal?.isCurrency" class="p_absulute_45 margin_right_5">$</span>
                                                <input matInput formControlName="pricePerGallon" id="pricePerGallonP" placeholder="0.00" (input)="truckMilesPerGallonFun($event.target.value,'dieselPricePerGal')" appTwoDigitDecimal maxlength="5" [ngStyle]="{'width.px': convertPixels((bidQuoteFormData.get('pricePerGallon').value))}" class="pro_inpute">
                                            </span> 
                                          </p>
                                          <section class="example-section" >
                                            <mat-slider  [min]="0" [max]='data?.bidQuoteDetail?.dieselPricePerGal?.maxCap' [step]="data?.bidQuoteDetail?.dieselPricePerGal?.interval" [value]="dieselPricePerGal" (input)="truckMilesPerGallonFun($event.target.value,'dieselPricePerGal')">
                                            <input matSliderThumb [(ngModel)]="dieselPricePerGal" formControlName="pricePerGallon" #slider>
                                          </mat-slider>
                                        </section>                                      
                                            <mat-error *ngIf="bidQuoteFormData?.get('pricePerGallon').errors?.['required']"
                                            class="error_mess">Price Per Gallon of Diesel is required.</mat-error>
                                            <mat-error *ngIf="bidQuoteFormData?.get('pricePerGallon').errors?.['pattern']"
                                            class="error_mess">Price Per Gallon of Diesel should not be 0.</mat-error>
                                      </mat-form-field>
                                    </div>
                                </div>
                              </div>
                              <div class="row pro_row">
                                <div class="col-md-6">
                                    <div class="progress_lab_bar">
                                      <mat-form-field appearance="none">                                            
                                        <p class="progress_lable m-0"><span class="fs_16">Driver's Wage Per Mile<span class="text-danger ml_5"> *</span></span>
                                            <span class="fs_14 d-flex align-items-center">
                                                <span *ngIf="data?.bidQuoteDetail?.driverWagePerMile?.isCurrency" class="p_absulute_45 margin_right_5">$</span>
                                                <input matInput formControlName="driverWagePerMile" id="driverWagePerMile" placeholder="0.00" (input)="truckMilesPerGallonFun($event.target.value,'driverWagePerMile')" appTwoDigitDecimal maxlength="5" [ngStyle]="{'width.px': convertPixels((bidQuoteFormData.get('driverWagePerMile').value))}" class="pro_inpute">
                                            </span> 
                                          </p>      
                                          <section class="example-section" >
                                            <mat-slider  [min]="0" [max]='data?.bidQuoteDetail?.driverWagePerMile?.maxCap' [step]="data?.bidQuoteDetail?.driverWagePerMile?.interval" [value]="driverWagePerMile" (input)="truckMilesPerGallonFun($event.target.value,'driverWagePerMile')">
                                            <input matSliderThumb [(ngModel)]="driverWagePerMile" formControlName="driverWagePerMile" #slider>
                                          </mat-slider>
                                        </section>                                                                               
                                            <mat-error *ngIf="bidQuoteFormData?.get('driverWagePerMile').errors?.['required']"
                                            class="error_mess">Driver's Wage Per Mile is required.</mat-error> 
                                            <mat-error *ngIf="bidQuoteFormData?.get('driverWagePerMile').errors?.['pattern']"
                                            class="error_mess">Driver's Wage Per Mile should not be 0.</mat-error> 
                                      </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="progress_lab_bar">
                                    <mat-form-field appearance="none">                                            
                                      <p class="progress_lable m-0"><span class="fs_16">Miles driven in a Month<span class="text-danger ml_5"> *</span></span>
                                          <span class="fs_14 d-flex align-items-center">
                                              <span *ngIf="data?.bidQuoteDetail?.monthlyMilesDriven?.isCurrency" class="p_absulute_45 margin_right_5">$</span>
                                              <input matInput formControlName="monthlyMilesDriven" id="monthlyMilesDrivenP" placeholder="0.00" (focusout)="removeDecimal(bidQuoteFormData.get('monthlyMilesDriven').value, 'monthlyMilesDriven')" (input)="truckMilesPerGallonFun($event.target.value,'monthlyMilesDriven')" appTwoDigitDecimal maxlength="5" [ngStyle]="{'width.px': convertPixels((bidQuoteFormData.get('monthlyMilesDriven').value))}" class="pro_inpute">
                                          </span> 
                                        </p>
                                        <section class="example-section" >
                                          <mat-slider  [min]="0" [max]='data?.bidQuoteDetail?.monthlyMilesDriven?.maxCap' [step]="data?.bidQuoteDetail?.monthlyMilesDriven?.interval" [value]="monthlyMilesDriven" (input)="truckMilesPerGallonFun($event.target.value,'monthlyMilesDriven')">
                                          <input matSliderThumb [(ngModel)]="monthlyMilesDriven" formControlName="monthlyMilesDriven" #slider>
                                        </mat-slider>
                                      </section>                                  
                                          <mat-error *ngIf="bidQuoteFormData?.get('monthlyMilesDriven').errors?.['required']"
                                          class="error_mess">Miles driven in a Month is required.</mat-error>   
                                       <mat-error *ngIf="bidQuoteFormData?.get('monthlyMilesDriven').errors?.['pattern']"
                                         class="error_mess">Miles driven in a Month should not be 0.</mat-error>  
                                    </mat-form-field>
                                  </div>
                              </div>
                            </div>
                          </mat-expansion-panel>
                          <mat-expansion-panel >
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <div class="L_title_btn"><span class="progress_input_box_title">Monthly Fixed Expenses</span></div>
                                </mat-panel-title>
                                <mat-panel-description>
                                    {{monthlyFixExpensessTotal ? '$' + monthlyFixExpensessTotal : ''}}
                                </mat-panel-description>
                            </mat-expansion-panel-header>
            
                            <div class="row pro_row">
                                <div class="col-md-6">
                                    <div class="progress_lab_bar">
                                      <mat-form-field appearance="none">                                            
                                        <p class="progress_lable m-0"><span class="fs_16">Insurance Premium</span>
                                            <span class="fs_14 d-flex align-items-center">
                                                <span *ngIf="data?.bidQuoteDetail?.insurancePremium?.isCurrency" class="p_absulute_45 margin_right_5">$</span>
                                                <input matInput formControlName="insurancePremium" id="insurancePremiumP" placeholder="0.00" (input)="monthlyFixExpensessFun($event.target.value,'insurancePremium')" appTwoDigitDecimal maxlength="5" [ngStyle]="{'width.px': convertPixels((bidQuoteFormData.get('insurancePremium').value))}" class="pro_inpute">
                                            </span> 
                                          </p>
                                          <section class="example-section" >
                                            <mat-slider  [min]="0" [max]='data?.bidQuoteDetail?.insurancePremium?.maxCap' [step]="data?.bidQuoteDetail?.insurancePremium?.interval" [value]="insurancePremium" (input)="monthlyFixExpensessFun($event.target.value,'insurancePremium')">
                                            <input matSliderThumb [(ngModel)]="insurancePremium" formControlName="insurancePremium" #slider>
                                          </mat-slider>
                                        </section>                                
                                      </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="progress_lab_bar">
                                      <mat-form-field appearance="none">                                            
                                        <p class="progress_lable m-0"><span class="fs_16">Insurance - Worker's Comp</span>
                                            <span class="fs_14 d-flex align-items-center">
                                                <span *ngIf="data?.bidQuoteDetail?.insuranceWorkersComp?.isCurrency" class="p_absulute_45 margin_right_5">$</span>
                                                <input matInput formControlName="insuranceWorkersComp" id="insuranceWorkersCompP" placeholder="0.00" (input)="monthlyFixExpensessFun($event.target.value,'insuranceWorkersComp')" appTwoDigitDecimal maxlength="5" [ngStyle]="{'width.px': convertPixels((bidQuoteFormData.get('insuranceWorkersComp').value))}" class="pro_inpute">
                                            </span> 
                                          </p>
                                          <section class="example-section" >
                                            <mat-slider [min]="0" [max]='data?.bidQuoteDetail?.insuranceWorkersComp?.maxCap' [step]="data?.bidQuoteDetail?.insuranceWorkersComp?.interval" [value]="insuranceWorkersComp" (input)="monthlyFixExpensessFun($event.target.value,'insuranceWorkersComp')">
                                            <input matSliderThumb [(ngModel)]="insuranceWorkersComp" formControlName="insuranceWorkersComp" #slider>
                                          </mat-slider>
                                        </section>                                  
                                      </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="progress_lab_bar">
                                      <mat-form-field appearance="none">                                            
                                        <p class="progress_lable m-0"><span class="fs_16">Insurance - Health</span>
                                            <span class="fs_14 d-flex align-items-center">
                                                <span *ngIf="data?.bidQuoteDetail?.insuranceHealth?.isCurrency" class="p_absulute_45 margin_right_5">$</span>
                                                <input matInput formControlName="insuranceHealth" id="insuranceHealth" placeholder="0.00" (input)="monthlyFixExpensessFun($event.target.value,'insuranceHealth')" appTwoDigitDecimal maxlength="5" [ngStyle]="{'width.px': convertPixels((bidQuoteFormData.get('insuranceHealth').value))}" class="pro_inpute">
                                            </span> 
                                          </p>
                                          <section class="example-section" >
                                            <mat-slider [min]="0" [max]='data?.bidQuoteDetail?.insuranceHealth?.maxCap' [step]="data?.bidQuoteDetail?.insuranceHealth?.interval" [value]="insuranceHealth" (input)="monthlyFixExpensessFun($event.target.value,'insuranceHealth')">
                                            <input matSliderThumb [(ngModel)]="insuranceHealth" formControlName="insuranceHealth" #slider>
                                          </mat-slider>
                                        </section>                                       
                                      </mat-form-field>
                                    </div>
                                </div>
                                
                                <div class="col-md-6">
                                    <div class="progress_lab_bar">
                                      <mat-form-field appearance="none">                                            
                                        <p class="progress_lable m-0"><span class="fs_16">Trailer Payment</span>
                                            <span class="fs_14 d-flex align-items-center">
                                                <span *ngIf="data?.bidQuoteDetail?.trailerPayment?.isCurrency" class="p_absulute_45 margin_right_5">$</span>
                                                <input matInput formControlName="trailerPayment" id="trailerPayment" placeholder="0.00" (input)="monthlyFixExpensessFun($event.target.value,'trailerPayment')" appTwoDigitDecimal maxlength="5" [ngStyle]="{'width.px': convertPixels((bidQuoteFormData.get('trailerPayment').value))}" class="pro_inpute" >
                                            </span> 
                                          </p>
                                          <section class="example-section" >
                                            <mat-slider [min]="0" [max]='data?.bidQuoteDetail?.trailerPayment?.maxCap' [step]="data?.bidQuoteDetail?.trailerPayment?.interval" [value]="trailerPayment" (input)="monthlyFixExpensessFun($event.target.value,'trailerPayment')">
                                            <input matSliderThumb [(ngModel)]="trailerPayment" formControlName="trailerPayment" #slider>
                                          </mat-slider>
                                        </section>                                    
                                      </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="progress_lab_bar">
                                      <mat-form-field appearance="none">                                            
                                        <p class="progress_lable m-0"><span class="fs_16">Permits</span>
                                            <span class="fs_14 d-flex align-items-center">
                                                <span *ngIf="data?.bidQuoteDetail?.permits?.isCurrency" class="p_absulute_45 margin_right_5">$</span>
                                                <input matInput formControlName="permits" id="permits" placeholder="0.00" (input)="monthlyFixExpensessFun($event.target.value,'permits')" appTwoDigitDecimal maxlength="5" [ngStyle]="{'width.px': convertPixels((bidQuoteFormData.get('permits').value))}" class="pro_inpute">
                                            </span> 
                                          </p>
                                          <section class="example-section" >
                                            <mat-slider  [min]="0" [max]='data?.bidQuoteDetail?.permits?.maxCap' [step]="data?.bidQuoteDetail?.permits?.interval" [value]="permits" (input)="monthlyFixExpensessFun($event.target.value,'permits')">
                                            <input matSliderThumb [(ngModel)]="permits" formControlName="permits" #slider>
                                          </mat-slider>
                                        </section>                                 
                                      </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="progress_lab_bar">
                                      <mat-form-field appearance="none">                                            
                                        <p class="progress_lable m-0"><span class="fs_16">Parking Expense</span>
                                            <span class="fs_14 d-flex align-items-center">
                                                <span *ngIf="data?.bidQuoteDetail?.parkingExpense?.isCurrency" class="p_absulute_45 margin_right_5">$</span>
                                                <input matInput formControlName="parkingExpense" id="parkingExpense" placeholder="0.00" (input)="monthlyFixExpensessFun($event.target.value,'parkingExpense')" appTwoDigitDecimal maxlength="5" [ngStyle]="{'width.px': convertPixels((bidQuoteFormData.get('parkingExpense').value))}" class="pro_inpute">
                                            </span> 
                                          </p>
                                          <section class="example-section" >
                                            <mat-slider  [min]="0" [max]='data?.bidQuoteDetail?.parkingExpense?.maxCap' [step]="data?.bidQuoteDetail?.parkingExpense?.interval" [value]="parkingExpense" (input)="monthlyFixExpensessFun($event.target.value,'parkingExpense')">
                                            <input matSliderThumb [(ngModel)]="parkingExpense" formControlName="parkingExpense" #slider>
                                          </mat-slider>
                                        </section>                                          
                                      </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="progress_lab_bar">
                                      <mat-form-field appearance="none">                                            
                                        <p class="progress_lable m-0"><span class="fs_16">License Plates</span>
                                            <span class="fs_14 d-flex align-items-center">
                                                <span *ngIf="data?.bidQuoteDetail?.licensePlates?.isCurrency" class="p_absulute_45 margin_right_5">$</span>
                                                <input matInput formControlName="licensePlates" id="licensePlates" (input)="monthlyFixExpensessFun($event.target.value,'licensePlates')" appTwoDigitDecimal maxlength="5" [ngStyle]="{'width.px': convertPixels((bidQuoteFormData.get('licensePlates').value))}" class="pro_inpute" placeholder="0.00">
                                            </span> 
                                          </p>
                                          <section class="example-section" >
                                            <mat-slider  [min]="0" [max]='data?.bidQuoteDetail?.licensePlates?.maxCap' [step]="data?.bidQuoteDetail?.licensePlates?.interval" [value]="licensePlates" (input)="monthlyFixExpensessFun($event.target.value,'licensePlates')">
                                            <input matSliderThumb [(ngModel)]="licensePlates" formControlName="licensePlates" #slider>
                                          </mat-slider>
                                        </section>                                    
                                      </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </mat-expansion-panel>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <div class="L_title_btn"><span class="progress_input_box_title">Monthly Variable Expenses</span></div>
                                </mat-panel-title>
                                <mat-panel-description>
                                    {{monthlyVariableExpensessTotal ? '$' + monthlyVariableExpensessTotal :''}}
                                </mat-panel-description>
                            </mat-expansion-panel-header>
            
                            <div class="row pro_row">

                                <div class="col-md-6">
                                    <div class="progress_lab_bar">
                                      <mat-form-field appearance="none">                                            
                                        <p class="progress_lable m-0"><span class="fs_16">Fuel</span>
                                            <span class="fs_14 d-flex align-items-center">
                                                <span *ngIf="data?.bidQuoteDetail?.fuel?.isCurrency" class="p_absulute_45 margin_right_5">$</span>
                                                <input matInput formControlName="fuel" id="fuel" placeholder="0.00" (input)="monthlyVariableExpensessFun($event.target.value,'fuel')" appTwoDigitDecimal maxlength="5" [ngStyle]="{'width.px': convertPixels((bidQuoteFormData.get('fuel').value))}" class="pro_inpute">
                                            </span> 
                                          </p>
                                          <section class="example-section" >
                                            <mat-slider  [min]="0" [max]='data?.bidQuoteDetail?.fuel?.maxCap' [step]="data?.bidQuoteDetail?.fuel?.interval" [value]="fuel" (input)="monthlyVariableExpensessFun($event.target.value,'fuel')">
                                            <input matSliderThumb [(ngModel)]="fuel" formControlName="fuel" #slider>
                                          </mat-slider>
                                        </section>                                      
                                      </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="progress_lab_bar">
                                      <mat-form-field appearance="none">                                            
                                        <p class="progress_lable m-0"><span class="fs_16">Meals/Lodging</span>
                                            <span class="fs_14 d-flex align-items-center">
                                                <span *ngIf="data?.bidQuoteDetail?.meals?.isCurrency" class="p_absulute_45 margin_right_5">$</span>
                                                <input matInput formControlName="meals" id="meals" placeholder="0.00" (input)="monthlyVariableExpensessFun($event.target.value,'meals')" appTwoDigitDecimal maxlength="5" [ngStyle]="{'width.px': convertPixels((bidQuoteFormData.get('meals').value))}" class="pro_inpute">
                                            </span> 
                                          </p>
                                          <section class="example-section" >
                                            <mat-slider [min]="0" [max]='data?.bidQuoteDetail?.meals?.maxCap' [step]="data?.bidQuoteDetail?.meals?.interval" [value]="meals" (input)="monthlyVariableExpensessFun($event.target.value,'meals')">
                                            <input matSliderThumb [(ngModel)]="meals" formControlName="meals" #slider>
                                          </mat-slider>
                                        </section>                                   
                                      </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="progress_lab_bar">
                                      <mat-form-field appearance="none">                                            
                                        <p class="progress_lable m-0"><span class="fs_16">Telephone</span>
                                            <span class="fs_14 d-flex align-items-center">
                                                <span *ngIf="data?.bidQuoteDetail?.telephone?.isCurrency" class="p_absulute_45 margin_right_5">$</span>
                                                <input matInput formControlName="telephone" id="telephone" placeholder="0.00" (input)="monthlyVariableExpensessFun($event.target.value,'telephone')" appTwoDigitDecimal maxlength="5" [ngStyle]="{'width.px': convertPixels((bidQuoteFormData.get('telephone').value))}" class="pro_inpute">
                                            </span> 
                                          </p>        
                                          <section class="example-section" >
                                            <mat-slider  [min]="0" [max]='data?.bidQuoteDetail?.telephone?.maxCap' [step]="data?.bidQuoteDetail?.telephone?.interval" [value]="telephone" (input)="monthlyVariableExpensessFun($event.target.value,'telephone')">
                                            <input matSliderThumb [(ngModel)]="telephone" formControlName="telephone" #slider>
                                          </mat-slider>
                                        </section>                                                                             
                                      </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="progress_lab_bar">
                                      <mat-form-field appearance="none">                                            
                                        <p class="progress_lable m-0"><span class="fs_16">Tolls</span>
                                            <span class="fs_14 d-flex align-items-center">
                                                <span *ngIf="data?.bidQuoteDetail?.tolls?.isCurrency" class="p_absulute_45 margin_right_5">$</span>
                                                <input matInput formControlName="tolls" id="tolls" placeholder="0.00" (input)="monthlyVariableExpensessFun($event.target.value,'tolls')" appTwoDigitDecimal maxlength="5" [ngStyle]="{'width.px': convertPixels((bidQuoteFormData.get('tolls').value))}" class="pro_inpute">
                                            </span> 
                                          </p>
                                          <section class="example-section" >
                                            <mat-slider  [min]="0" [max]='data?.bidQuoteDetail?.tolls?.maxCap' [step]="data?.bidQuoteDetail?.tolls?.interval" [value]="tolls" (input)="monthlyVariableExpensessFun($event.target.value,'tolls')">
                                            <input matSliderThumb [(ngModel)]="tolls" formControlName="tolls" #slider>
                                          </mat-slider>
                                        </section>                                       
                                      </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="progress_lab_bar">
                                      <mat-form-field appearance="none">                                            
                                        <p class="progress_lable m-0"><span class="fs_16">Lumper Fees</span>
                                            <span class="fs_14 d-flex align-items-center">
                                                <span *ngIf="data?.bidQuoteDetail?.lumperFees?.isCurrency" class="p_absulute_45 margin_right_5">$</span>
                                                <input matInput formControlName="lumperFees" id="lumperFees" placeholder="0.00" (input)="monthlyVariableExpensessFun($event.target.value,'lumperFees')" appTwoDigitDecimal maxlength="5" [ngStyle]="{'width.px': convertPixels((bidQuoteFormData.get('lumperFees').value))}" class="pro_inpute">
                                            </span> 
                                          </p>
                                          <section class="example-section" >
                                            <mat-slider [min]="0" [max]='data?.bidQuoteDetail?.lumperFees?.maxCap' [step]="data?.bidQuoteDetail?.lumperFees?.interval" [value]="lumperFees" (input)="monthlyVariableExpensessFun($event.target.value,'lumperFees')">
                                            <input matSliderThumb [(ngModel)]="lumperFees" formControlName="lumperFees" #slider>
                                          </mat-slider>
                                        </section>                                  
                                      </mat-form-field>
                                    </div>
                                </div>

                                <div class="col-md-6">
                                    <div class="progress_lab_bar">
                                      <mat-form-field appearance="none">                                            
                                        <p class="progress_lable m-0"><span class="fs_16">Tires</span>
                                            <span class="fs_14 d-flex align-items-center">
                                                <span *ngIf="data?.bidQuoteDetail?.tires?.isCurrency" class="p_absulute_45 margin_right_5">$</span>
                                                <input matInput formControlName="tires" id="tires" placeholder="0.00" (input)="monthlyVariableExpensessFun($event.target.value,'tires')" maxlength="5" [ngStyle]="{'width.px': convertPixels((bidQuoteFormData.get('tires').value))}" appTwoDigitDecimal  class="pro_inpute">
                                            </span> 
                                          </p>
                                          <section class="example-section" >
                                            <mat-slider [min]="0" [max]='data?.bidQuoteDetail?.tires?.maxCap' [step]="data?.bidQuoteDetail?.tires?.interval" [value]="tires" (input)="monthlyVariableExpensessFun($event.target.value,'tires')">
                                            <input matSliderThumb [(ngModel)]="tires" formControlName="tires" #slider>
                                          </mat-slider>
                                        </section>                                 
                                      </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="progress_lab_bar">
                                      <mat-form-field appearance="none">                                            
                                        <p class="progress_lable m-0"><span class="fs_16">Maintenance</span>
                                            <span class="fs_14 d-flex align-items-center">
                                                <span *ngIf="data?.bidQuoteDetail?.maintenance?.isCurrency" class="p_absulute_45 margin_right_5">$</span>
                                                <input matInput formControlName="maintenance" id="maintenance" placeholder="0.00" (input)="monthlyVariableExpensessFun($event.target.value,'maintenance')" appTwoDigitDecimal maxlength="5" [ngStyle]="{'width.px': convertPixels((bidQuoteFormData.get('maintenance').value))}" class="pro_inpute">
                                            </span> 
                                          </p>
                                          <section class="example-section" >
                                            <mat-slider [min]="0" [max]='data?.bidQuoteDetail?.maintenance?.maxCap' [step]="data?.bidQuoteDetail?.maintenance?.interval" [value]="maintenance" (input)="monthlyVariableExpensessFun($event.target.value,'maintenance')">
                                            <input matSliderThumb [(ngModel)]="maintenance" formControlName="maintenance" #slider>
                                          </mat-slider>
                                        </section>                                         
                                      </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="progress_lab_bar">
                                      <mat-form-field appearance="none">                                            
                                        <p class="progress_lable m-0"><span class="fs_16">Factoring Fees</span>
                                            <span class="fs_14 d-flex align-items-center">
                                                <span *ngIf="data?.bidQuoteDetail?.factoringFees?.isCurrency" class="p_absulute_45 margin_right_5">$</span>
                                                <input matInput formControlName="factoringFees" id="factoringFees" placeholder="0.00" (input)="monthlyVariableExpensessFun($event.target.value,'factoringFees')" appTwoDigitDecimal maxlength="5" [ngStyle]="{'width.px': convertPixels((bidQuoteFormData.get('factoringFees').value))}" class="pro_inpute">
                                            </span> 
                                          </p>
                                          <section class="example-section" >
                                            <mat-slider [min]="0" [max]='data?.bidQuoteDetail?.factoringFees?.maxCap' [step]="data?.bidQuoteDetail?.factoringFees?.interval" [value]="factoringFees" (input)="monthlyVariableExpensessFun($event.target.value,'factoringFees')">
                                            <input matSliderThumb [(ngModel)]="factoringFees" formControlName="factoringFees" #slider>
                                          </mat-slider>
                                        </section>                                   
                                      </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="progress_lab_bar">
                                      <mat-form-field appearance="none">                                            
                                        <p class="progress_lable m-0"><span class="fs_16">Miscellaneous Expenses</span>
                                            <span class="fs_14 d-flex align-items-center">
                                                <span *ngIf="data?.bidQuoteDetail?.miscellaneousExpenses?.isCurrency" class="p_absulute_45 margin_right_5">$</span>
                                                <input matInput formControlName="miscellaneousExpenses" id="miscellaneousExpenses" placeholder="0.00" (input)="monthlyVariableExpensessFun($event.target.value,'miscellaneousExpenses')" appTwoDigitDecimal maxlength="5" [ngStyle]="{'width.px': convertPixels((bidQuoteFormData.get('miscellaneousExpenses').value))}" class="pro_inpute">
                                            </span> 
                                          </p>
                                          <section class="example-section" >
                                            <mat-slider  [min]="0" [max]='data?.bidQuoteDetail?.miscellaneousExpenses?.maxCap' [step]="data?.bidQuoteDetail?.miscellaneousExpenses?.interval" [value]="miscellaneousExpenses" (input)="monthlyVariableExpensessFun($event.target.value,'miscellaneousExpenses')">
                                            <input matSliderThumb [(ngModel)]="miscellaneousExpenses" formControlName="miscellaneousExpenses" #slider>
                                          </mat-slider>
                                        </section>                                      
                                      </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </mat-expansion-panel>
                        <mat-expansion-panel >
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <div class="L_title_btn"><span class="progress_input_box_title">Annual Overhead Items</span></div>
                                </mat-panel-title>
                                <mat-panel-description>
                                    {{annualOverheadItemsTotal ? '$' + annualOverheadItemsTotal : ''}}
                                </mat-panel-description>
                            </mat-expansion-panel-header>
        
                            <div class="row pro_row">
                                <div class="col-md-6">
                                    <div class="progress_lab_bar">
                                      <mat-form-field appearance="none">                                            
                                        <p class="progress_lable m-0"><span class="fs_16">Accounting</span>
                                            <span class="fs_14 d-flex align-items-center">
                                                <span *ngIf="data?.bidQuoteDetail?.accounting?.isCurrency" class="p_absulute_45 margin_right_5">$</span>
                                                <input matInput formControlName="accounting" id="accounting" placeholder="0.00" (input)="annualOverheadItemsFun($event.target.value,'accounting')" appTwoDigitDecimal maxlength="5" [ngStyle]="{'width.px': convertPixels((bidQuoteFormData.get('accounting').value))}" class="pro_inpute">
                                            </span> 
                                          </p>
                                          <section class="example-section" >
                                            <mat-slider [min]="0" [max]='data?.bidQuoteDetail?.accounting?.maxCap' [step]="data?.bidQuoteDetail?.accounting?.interval" [value]="accounting" (input)="annualOverheadItemsFun($event.target.value,'accounting')">
                                            <input matSliderThumb [(ngModel)]="accounting" formControlName="accounting" #slider>
                                          </mat-slider>
                                        </section>
                                      </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="progress_lab_bar">
                                      <mat-form-field appearance="none">                                            
                                        <p class="progress_lable m-0"><span class="fs_16">IRP</span>
                                            <span class="fs_14 d-flex align-items-center">
                                                <span *ngIf="data?.bidQuoteDetail?.internationalRegistrationPlan?.isCurrency" class="p_absulute_45 margin_right_5">$</span>
                                                <input matInput formControlName="internationalRegistrationPlan" id="internationalRegistrationPlan" placeholder="0.00" (input)="annualOverheadItemsFun($event.target.value,'internationalRegistrationPlan')" appTwoDigitDecimal maxlength="5" [ngStyle]="{'width.px': convertPixels((bidQuoteFormData.get('internationalRegistrationPlan').value))}" class="pro_inpute">
                                            </span> 
                                          </p>
                                          <section class="example-section" >
                                            <mat-slider [min]="0" [max]='data?.bidQuoteDetail?.internationalRegistrationPlan?.maxCap' [step]="data?.bidQuoteDetail?.internationalRegistrationPlan?.interval" [value]="internationalRegistrationPlan" (input)="annualOverheadItemsFun($event.target.value,'internationalRegistrationPlan')">
                                            <input matSliderThumb [(ngModel)]="internationalRegistrationPlan" formControlName="internationalRegistrationPlan" #slider>
                                          </mat-slider>
                                        </section>                                       
                                      </mat-form-field>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="progress_lab_bar">
                                      <mat-form-field appearance="none">                                            
                                        <p class="progress_lable m-0"><span class="fs_16">Subscriptions</span>
                                            <span class="fs_14 d-flex align-items-center">
                                                <span *ngIf="data?.bidQuoteDetail?.subscriptions?.isCurrency" class="p_absulute_45 margin_right_5">$</span>
                                                <input matInput formControlName="subscriptions" id="subscriptions" placeholder="0.00" (input)="annualOverheadItemsFun($event.target.value,'subscriptions')" appTwoDigitDecimal maxlength="5" [ngStyle]="{'width.px': convertPixels((bidQuoteFormData.get('subscriptions').value))}" class="pro_inpute">
                                            </span> 
                                          </p>
                                          <section class="example-section" >
                                            <mat-slider [min]="0" [max]='data?.bidQuoteDetail?.subscriptions?.maxCap' [step]="data?.bidQuoteDetail?.subscriptions?.interval" [value]="subscriptions" (input)="annualOverheadItemsFun($event.target.value,'subscriptions')">
                                            <input matSliderThumb [(ngModel)]="subscriptions" formControlName="subscriptions" #slider>
                                          </mat-slider>
                                        </section>                                      
                                      </mat-form-field>
                                    </div>
                                </div>
                            
                            </div>
                        </mat-expansion-panel>            
                      </mat-accordion>
                  </div>
              </div>              
        </div>   
        <div class="cn_sub_btn">
            <button class="cn_btn" type="button" (click)="onNoClick()">Cancel</button>
            <button  *ngIf="buttonType=='submit'" class="submit_btn"  type="submit">Submit Quote</button>
            <button  *ngIf="buttonType=='button'" class="submit_btn" (click)="submitBidQuote(bidQuoteFormData)" type="button" [disabled]="buttonDisabled">Submit Quote</button>
        </div>
    </form>
    </div>
</div>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px',fullScreenBackdrop:true }"></ngx-loading>