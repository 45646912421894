<div class="detail_card_con">
    <div class="ship_st_card">
      <p class="line_before  mb-0">Load Status</p>
      <button type="button" (click)="getWebTrackingLink()" class="btn custum_button button_primary fs_14 con_algn" [class.disabled]="!checkWebTrackShipmentCondition()">
         <span>Web Tracking Link</span>
         <span class="material-icons fs_16 ms-1">content_copy</span>
      </button>
    </div>
    <div class="list_scroll ummary_scrl">
       <div class="shipment_road_map" [ngClass]="summaryDataWaypoint.length <= 6 ? 'shipment_road_map_first' : ''">
         <div class="srm_box box_{{i}} {{waypointInfo?.yuclass}} {{waypointInfo?.zompOpen}} {{waypointInfo?.status}} {{waypointInfo?.daynamicClass}} {{waypointInfo?.roundClass}} "  *ngFor="let waypointInfo of summaryDataWaypoint ; let i=index;">
           <div class="srm_r_1">
             <p class="srm_tlt text_truncate" matTooltip="{{waypointInfo.type | titlecase }} ">
                 <span>{{waypointInfo.type | titlecase }} </span>
                 <span *ngIf="waypointInfo?.locationOrder">Point {{waypointInfo?.locationOrder}}</span>
              </p>
             <img *ngIf="waypointInfo.status === 'Completed'" src="{{serverPathForUrl}}/portal/images/after-login/icon_forward.png" class="icn_fr_arrow" />
             <img *ngIf="waypointInfo.status === 'Completed'" src="{{serverPathForUrl}}/portal/images/after-login/icon_truck_forward.png" class="icn_intrasit" />
             <img *ngIf="waypointInfo.status === 'Completed'" src="{{serverPathForUrl}}/portal/images/after-login/icon_intransit_curb.png" class="icn_intrasit_curve" />
             <img *ngIf="waypointInfo.status === 'In-Transit'" src="{{serverPathForUrl}}/portal/images/after-login/icon_forward.png" class="icn_fr_arrow" />
             <img *ngIf="waypointInfo.status === 'Open'" src="{{serverPathForUrl}}/portal/images/after-login/icon_forward.png" class="icn_fr_arrow" />
           </div>
           <div class="srm_r_2">
             <img src="{{waypointInfo?.locationTypeImage}}" />
           </div>
           <div class="srm_r_3">
              <p class="srm_loc text_truncate" *ngIf="waypointInfo?.city && waypointInfo?.state">
                <img src="{{serverPathForUrl}}/portal/images/after-login/icon-map_marker.png" class="img-fluid">
                <span>{{waypointInfo.city }}, {{waypointInfo.state}}<span *ngIf="waypointInfo?.countryName">, {{waypointInfo.countryName}}</span></span>
              </p>
              <p class="srm_tim text_truncate" *ngIf="waypointInfo?.date"><span>{{(waypointInfo?.date )| date: 'MMM dd, yyyy'}} {{waypointInfo?.timeSlot}}
               {{waypointInfo?.timeZone}}</span></p>
               <p class="srm_tim text_truncate" *ngIf="waypointInfo?.dateShow">
                  <span *ngIf="waypointInfo.status == 'Open'">{{(waypointInfo?.dateShow) | date: 'MMM dd, yyyy '}} </span>
                  <span *ngIf="waypointInfo.status == 'Completed'">{{(waypointInfo?.dateShow) | date: 'MMM dd, yyyy HH:mm '}} </span>
               </p>
            </div>
         </div>
         <div class="clearfix"></div>
       </div>
    </div>
  </div>
  
  <!-- Map View -->
  <div class="detail_card_con bdm_map">
     <div class="dri_card_head cus_ping_rate">
        <p class="line_before mb-0 ">Map View</p>
        <div class="crt_card">
           <div class="crt_card_crp">
  
              <div class="ccc_l">
                 <span class="ccc_l_txt"><span class="fsm_5 txt_lb">Current Ping Rate:</span> 
                 {{ pingRateValueShowPing(pingRateValueShow)}}</span>
  
                 <div *ngIf="!editPingRate && pingRateCheckDcisabled()" class="ccc_edt_icon pointer"
                    (click)="editPingrate(true)" matTooltip="Change Ping Rate">
                    <img
                       src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-edit.svg"
                       class="img-fluid">
                 </div>
                 <div *ngIf="!editPingRate && !pingRateCheckDcisabled()" [class.disabled]="!pingRateCheckDcisabled()"
                    class="ccc_edt_icon pointer" matTooltip="Change Ping Rate">
                    <img src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-edit.svg"
                       class="img-fluid">
                 </div>
              </div>
              <div class="ccc_r">
                 <div class="con_status" *ngIf="spinnerLoading">
                    <div class="spinner-border txt_p" role="status">
                       <span class="sr-only">
                          <!-- Loading... -->
                       </span>
                    </div>
                 </div>
                 <div *ngIf="!spinnerLoading">
                    <button *ngIf="currentStatus ==='upcoming' || currentStatus ==='inProgress'" type="type"
                       class="btn custum_button button_primary refresh_map" id="click" name="refresh"
                       (click)="refreshMap()" matTooltip="Refresh map">
                       <span class="material-icons">refresh</span>
                    </button>
                 </div>
                 <button *ngIf="currentStatus !=='upcoming' && currentStatus !=='inProgress'" type="type"
                    class="btn custum_button button_primary refresh_map" id="click" name="refresh" [disabled]="true"
                    matTooltip="Refresh map">
                    <span class="material-icons">refresh</span>
                 </button>
              </div>
           </div>
           <div class="edt_ping_rt" *ngIf="editPingRate">
              <form [formGroup]="pingRateForm" (ngSubmit)="pingRateFormSubmit(pingRateForm)" class="mb-0 custum_input_css"
                 autocomplete="off">
                 <div class="pay_method">
                    <div class="custom_toggle">
                       <span class="fs_16 fsm_5 me-3 visibilityl ">Standard</span>
                       <mat-slide-toggle formControlName="pingRateStatus" class="example-margin" (change)="setPingRate($event)">
                       </mat-slide-toggle>
                       <span class="fs_16 fsm_5 ms-3 visibilityr ">Custom</span>
                    </div>
                 </div>
                 <div class="row" *ngIf="pingRateStatus===2">
                    <div class="col-md-12">
                       <div class="custum_input_css">
                          <mat-form-field appearance="none" class="removeappearance" class="input_suffix">
                             <span matSuffix *ngIf="this.pingRateForm.get('pingRateValue').value == 1"
                                class="suffix_txt">Minute</span>
                             <span matSuffix *ngIf="this.pingRateForm.get('pingRateValue').value > 1"
                                class="suffix_txt">Minutes</span>
                             <input matInput type="text" maxlength="2" formControlName="pingRateValue"
                                placeholder="Ping Rate in minutes" class="form-control custom_input"
                                appZeroNotAllowFirst />
                             <mat-error *ngIf="shipmentPingSubmitted && pingRateForm.get('pingRateValue').errors?.['required']" class="error_mess">
                                Ping Rate required</mat-error>
                             <mat-error *ngIf="pingRateForm.get('pingRateValue').errors?.['max']" class="error_mess">Ping
                                Rate value should be between 1 to 60 minutes</mat-error>
                          </mat-form-field>
                       </div>
                    </div>
                 </div>
                 <button type="submit" class="btn custum_button button_primary" id="update" name="update">Update</button>
                 <button (click)="editPingrate(false)" type="button" class="btn custum_button cnl_btn ms-4" id="cancel"
                    name="cancel">Cancel</button>
              </form>
           </div>
        </div>
     </div>
     <div class="map_container pb-4">
        <div class="mapShow" *ngIf="mapStatus">
           <div class="map_live">
              <div class="cu_loca_map">
                 <div class="mapOn_shipView">
                    <app-mapbox-summary [latitude]="currentLat" [longitude]="currentLon"
                       [information]="shipmentDetail?.drivers"></app-mapbox-summary>
                 </div>
              </div>
           </div>
        </div>
     </div>
  </div>