import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoadDetailCommodityComponent } from '../load-detail-commodity/load-detail-commodity.component';

@Component({
  selector: 'app-waypoint-detail',
  templateUrl: './waypoint-detail.component.html',
  styleUrls: ['./waypoint-detail.component.css'],
})

export class WaypointDetailComponent implements OnInit {
  @Input() waypoint: any;
  public pickupWayPoint: any = [];
  public dropoffWayPoint: any = [];

  constructor(public dialog: MatDialog,) { }

  ngOnInit() {
    if (this.waypoint) {
      for (let shipmentIndex in this.waypoint) {
        if (this.waypoint && this.waypoint[shipmentIndex].type === 'pickup') {
          this.waypoint[shipmentIndex].date = this.getDateFormat(this.waypoint[shipmentIndex].date.toString());
          this.pickupWayPoint.push(this.waypoint[shipmentIndex]);
        }
        if (this.waypoint && this.waypoint[shipmentIndex].type === 'dropoff') {
          this.waypoint[shipmentIndex].date = this.getDateFormat(this.waypoint[shipmentIndex].date.toString());
          this.dropoffWayPoint.push(this.waypoint[shipmentIndex]);
        }
      }
    }
  }

  getDateFormat(inputDate: any) {
    if (inputDate !== '' && typeof inputDate !== undefined) {
      return inputDate.replaceAll('-', '/');
    } else {
      return '';
    }
  }

  showDetailCommodity(detail:any) {
    const dialogRef = this.dialog.open(LoadDetailCommodityComponent, {
      disableClose: true,
      backdropClass: 'cus_backdrop_class',
      panelClass: 'cus_popup_vw',
      width: '1140px',
      data: { openPop: 'showShipmentDetail' ,shipmentDetail:detail},
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.event === 'fail') {
      }
    });
  }

}
