<div class="detail_card_con p_24" *ngIf="acceptBidList?.length > 0 && !bidHistorySekeletonLoader">
    <p class="line_before mb-3">Accepted Bid</p>
    <div class="row currt_acceptdBid">
       <div class="col-md-6">
          <div class="fs_16 fsn_4 txt_b ps-0 img_name">
             <div class="carrierN pointer" (click)="eventActionProfileSidePanel(acceptBidList,'carrier_side_panel')">
                <div class="initial_nameImg">
                   <img *ngIf="acceptBidList[0]?.profileImage" src="{{acceptBidList[0]?.profileImage}}"
                      class="img-fluid h_100">
                   <span class="initial_name initial_r_32"
                      *ngIf="!acceptBidList[0]?.profileImage">{{acceptBidList[0]?.userName | acronymOfName}}</span>
                </div>
                <div class="d-flex">
                <span class="fs_16 fsm_5 txt_b d-flex">
                   <span class="abl_ca_name text_truncate">{{acceptBidList[0]?.userName ? acceptBidList[0]?.userName : '-'}}</span>
                   <span *ngIf="currentId === acceptBidList[0].actionBy"> (Me)</span>
                </span>
                <img *ngIf="acceptBidList[0]?.subscription"
                   src="{{serverPathForUrl}}/portal/images/after-login/premium_plan_icon.png" class="img-fluid verified_icon">
                </div>
             </div>
             <div class="ecarrier_check_box mt-2" *ngIf="userType=='SHIPPER' && acceptBidList[0]?.shipperCarrierScoreMapping ">
                <div>
                   <span class="d-block pointer">
                      <img src="\assets\icon\info_icon_green.svg" matTooltip="{{eCarrierCheckRisk?.lowRisk}}"
                         *ngIf="acceptBidList[0]?.carrierSafetyScore?.riskLevel=='4'">
                      <img src="\assets/icon/ecarrier_info_light_red.svg" matTooltip="{{eCarrierCheckRisk?.highRisk}}"
                         *ngIf="acceptBidList[0]?.carrierSafetyScore?.riskLevel=='2'">
                      <img src="\assets/icon/ingo_icon_yellow.svg" matTooltip="{{eCarrierCheckRisk?.elevatedRisk}}"
                         *ngIf="acceptBidList[0]?.carrierSafetyScore?.riskLevel=='3'">
                      <img src="\assets/icon/ecarrier_info_light_pure_red.svg"
                         matTooltip="{{eCarrierCheckRisk?.disqualified}}"
                         *ngIf="acceptBidList[0]?.carrierSafetyScore?.riskLevel=='1'">
                   </span>
                   <span class="fs_10 txt_b">{{acceptBidList[0]?.carrierSafetyScore?.riskLabel ?
                      acceptBidList[0]?.carrierSafetyScore?.riskLabel : '-'}}</span>
                </div>
 
                <div *ngIf="acceptBidList[0]?.carrierSafetyScore?.authority">
                   <span class="d-block"><img src="\assets\icon\ecarrier_succes_icon.svg"></span>
                   <span class="fs_10 txt_b">Authority</span>
                </div>
 
                <div>
                   <span class="d-block"><img src="\assets\icon\ecarrier_crases_icon_red.svg"></span>
                   <span class="fs_10 txt_b">Crashes<span class="text_red ms-1">({{acceptBidList[0]?.carrierSafetyScore?.recentCrashes ?
                         acceptBidList[0]?.carrierSafetyScore?.recentCrashes : '0'}})</span></span>
                </div>
 
                <div>
                   <span class="d-block"><span class="c_nu">{{acceptBidList[0]?.carrierSafetyScore?.recentInspections ?
                         acceptBidList[0]?.carrierSafetyScore?.recentInspections : '0'}}</span></span>
                   <span class="fs_10 txt_b">Inspection</span>
                </div>
                <div (click)="ecarriercheck(acceptBidList)">
                   <span class="fs_10 txt_p fsm_5 pointer">View Safety Report</span>
                </div>
             </div>
             <div class="unlock_btn"
                *ngIf="userType=='SHIPPER' && !acceptBidList[0]?.shipperCarrierScoreMapping && acceptBidList[0]?.actionBy && acceptBidList[0]?.actionBy !==userId"
                (click)="unlockSafetyScore(acceptBidList[0]?.dotNumber,'unlockSafetyScore')">
                <button><img src="\assets\icon\unlock_btn_icon.svg" class="mr-1">Unlock Safety Score</button>
             </div>
          </div>
       </div>
       <div class="col-md-5 text-center">
          <div class="fs_12 fsn_4 txt_lb">
             {{acceptBidList[0]?.actionTs | date: 'MMM dd, yyyy'}} at {{acceptBidList[0]?.actionTs | date: 'HH:mm:ss'}}
          </div>
          <div class="fs_14 fsn_4 txt_lb" *ngIf="loadDetail?.isOfferRate==='N'">
             <span class="v_centerQW">
                <span class="mb-0 txt_b fsm_5">${{acceptBidList[0]?.bidAmount | twoDecimalPipe}}</span>
                <app-rate-per-mile class="fs_12 fsm_5 txt_lb ml_2 d-flex" [amount]="acceptBidList[0]?.bidAmount"
                   [miles]="loadDetail?.miles">
                </app-rate-per-mile>
             </span>
             <span class="bid_message txt_b pointer" matTooltip="{{acceptBidList[0]?.note}}">{{acceptBidList[0]?.note ?
                acceptBidList[0]?.note : '-'}}</span>
          </div>
       </div>
       <div class="col-md-1">
          <div class="fs_16 fsn_4 txt_b" *ngIf="loadDetail?.isOfferRate==='Y'">
             <span class="v_centerQW">
                <span class="mb-0 usdUdsd contract_offer_Rate fsm_5 txt_b">${{acceptBidList[0]?.bidAmount |
                   twoDecimalPipe}}</span>
                <app-rate-per-mile class="fs_12 fsm_5 txt_lb ml_2 d-flex" [amount]="acceptBidList[0]?.bidAmount"
                   [miles]="loadDetail?.miles">
                </app-rate-per-mile>
             </span>
             <span class="bid_message txt_b pointer" matTooltip="{{acceptBidList[0]?.note}}">{{acceptBidList[0]?.note ?
                acceptBidList[0]?.note : '-'}}</span>
          </div>
          <div class="fs_16 fsb_7 txt_s pe-0 text-end">
             {{acceptBidList[0]?.statusLabel}}
          </div>
       </div>
    </div>
 </div>
 <div class="detail_card_con p_24" *ngIf="newPreviousBidHistory?.length > 0 && !bidHistorySekeletonLoader">
    <div id="all_Drivers" class="list_scroll previouBids_scroll" infiniteScroll [infiniteScrollDistance]="3"
       [infiniteScrollThrottle]="100" (scrolled)="onBidHiScroll()" [scrollWindow]="false">
       <div class="bid_history_lcard">
             <div class="row align-items-center border_bottom" *ngFor="let historyRecord of newPreviousBidHistory;let j=index;">
                <div class="col-lg-6 col-md-5">
                   <div class="fs_16 fsn_4 txt_lb ps-0 img_name">
                      <div class="carrierN pointer pointer"
                         (click)="eventActionProfileSidePanel(historyRecord,'carrier_side_panel')">
                         <div class="initial_nameImg">
                            <img *ngIf="historyRecord?.profileImage" src="{{historyRecord?.profileImage}}"
                               class="img-fluid h_100">
                            <span class="initial_name initial_r_32" *ngIf="!historyRecord?.profileImage">{{historyRecord.userName | acronymOfName}}</span>
                         </div>
                         <span class="fs_16 fsm_5 txt_b d-flex">
                            <span class="abl_ca_name text_truncate">{{historyRecord.userName ? historyRecord.userName : '-'}}</span>
                            <span *ngIf="currentId === historyRecord.actionBy"> (Me)</span>
                         </span>
                         <img *ngIf="historyRecord?.subscription"
                            src="{{serverPathForUrl}}/portal/images/after-login/premium_plan_icon.png" class="img-fluid verified_icon">
                      </div>
                      <div class="ecarrier_check_box mt-2"
                         *ngIf="userType=='SHIPPER' && historyRecord?.shipperCarrierScoreMapping">
                         <div>
                            <span class="d-block pointer">
                               <img src="\assets\icon\info_icon_green.svg" matTooltip="{{eCarrierCheckRisk?.lowRisk}}"
                                  *ngIf="historyRecord?.carrierSafetyScore?.riskLevel=='4'">
                               <img src="\assets/icon/ecarrier_info_light_red.svg"
                                  matTooltip="{{eCarrierCheckRisk?.highRisk}}"
                                  *ngIf="historyRecord?.carrierSafetyScore?.riskLevel=='2'">
                               <img src="\assets/icon/ingo_icon_yellow.svg"
                                  matTooltip="{{eCarrierCheckRisk?.elevatedRisk}}"
                                  *ngIf="historyRecord?.carrierSafetyScore?.riskLevel=='3'">
                               <img src="\assets/icon/ecarrier_info_light_pure_red.svg"
                                  matTooltip="{{eCarrierCheckRisk?.disqualified}}"
                                  *ngIf="historyRecord?.carrierSafetyScore?.riskLevel=='1'">
                            </span>
                            <span class="fs_10 txt_b">{{historyRecord?.carrierSafetyScore?.riskLabel ?
                               historyRecord?.carrierSafetyScore?.riskLabel : '-'}}</span>
                         </div>
 
                         <div *ngIf="historyRecord?.carrierSafetyScore?.authority">
                            <span class="d-block"><img src="\assets\icon\ecarrier_succes_icon.svg"></span>
                            <span class="fs_10 txt_b">Authority</span>
                         </div>
 
                         <div>
                            <span class="d-block"><img src="\assets\icon\ecarrier_crases_icon_red.svg"></span>
                            <span class="fs_10 txt_b">Crashes<span
                                  class="text_red ms-1">({{historyRecord?.carrierSafetyScore?.recentCrashes ?
                                  historyRecord?.carrierSafetyScore?.recentCrashes : '0'}})</span></span>
                         </div>
 
                         <div>
                            <span class="d-block"><span
                                  class="c_nu">{{historyRecord?.carrierSafetyScore?.recentInspections ?
                                  historyRecord?.carrierSafetyScore?.recentInspections : '0'}}</span></span>
                            <span class="fs_10 txt_b">Inspection</span>
                         </div>
                         <div (click)="ecarriercheck(historyRecord)">
                            <span class="fs_10 txt_p fsm_5 pointer">View Safety Report</span>
                         </div>
                      </div>
                      <div class="unlock_btn"
                         *ngIf="userType=='SHIPPER' && !historyRecord?.shipperCarrierScoreMapping && historyRecord?.actionBy && historyRecord?.actionBy !==userId"
                         (click)="unlockSafetyScore(historyRecord?.dotNumber,'unlockSafetyScore')">
                         <button><img src="\assets\icon\unlock_btn_icon.svg" class="mr-1">Unlock Safety Score</button>
                      </div>
                   </div>
                </div>
                <div class="col-lg-5 col-md-4 text-center">
                   <div class="fs_12 fsn_4 txt_lb">{{historyRecord.actionTs | date: 'MMM dd, yyyy'}} at {{historyRecord.actionTs | date: 'HH:mm:ss'}}
                   </div>
                   <div class="fs_14 fsn_4 txt_lb">
                      <p class="mb-0 usdUdsd" *ngIf="loadDetail?.isOfferRate==='N'">
                         <span class="v_centerQW">
                            <span *ngIf="historyRecord.bidAmount && loadDetail?.paymentMethod === 1" class="fsm_5 txt_b">${{historyRecord.bidAmount | twoDecimalPipe}}</span>
                            <app-rate-per-mile *ngIf="historyRecord.bidAmount && loadDetail?.paymentMethod === 1"
                               class="fs_12 fsm_5 txt_lb ml_2" [amount]="historyRecord.bidAmount"
                               [miles]="loadDetail?.miles"></app-rate-per-mile>
                         </span>
                         <span *ngIf="historyRecord.bidAmount && loadDetail?.paymentMethod === 2"
                            class="ms-1 usdc_iconS"></span>
                         <span *ngIf="historyRecord.bidAmount && loadDetail?.paymentMethod === 2">{{historyRecord.bidAmount | sixDecimalPipe}}</span>
                         <span *ngIf="!historyRecord.bidAmount">-</span>
                      </p>
                      <p class="mb-0 usdUdsd contract_offer_Rate" *ngIf="loadDetail?.isOfferRate==='Y'">
                         <span class="v_centerQW">
                            <span *ngIf="historyRecord.bidAmount && loadDetail?.paymentMethod === 1" class="fsm_5 txt_b">${{historyRecord.bidAmount | twoDecimalPipe}}</span>
                            <app-rate-per-mile *ngIf="historyRecord.bidAmount && loadDetail?.paymentMethod === 1"
                               class="fs_12 fsm_5 txt_lb ml_2" [amount]="historyRecord.bidAmount"
                               [miles]="loadDetail?.miles"></app-rate-per-mile>
                         </span>
                         <span *ngIf="historyRecord.bidAmount && loadDetail?.paymentMethod === 2"
                            class="ms-1 usdc_iconS"></span>
                         <span *ngIf="historyRecord.bidAmount && loadDetail?.paymentMethod === 2">{{historyRecord.bidAmount | sixDecimalPipe}}</span>
                         <span *ngIf="!historyRecord.bidAmount">-</span>
                      </p>
                      <span class="bid_message txt_lb pointer" matTooltip="{{historyRecord.note}}">{{historyRecord.note ?
                         historyRecord.note : '-'}}</span>
                   </div>
                </div>
                <div class="col-lg-1">
                   <div *ngIf="historyRecord.statusLabel==='Withdrawn'" class="lable_txt fsb_7 txt_d pe-0" style="text-align: end;">{{historyRecord.statusLabel ?  historyRecord.statusLabel : '-'}}</div>
                   <div *ngIf="historyRecord.statusLabel !=='Withdrawn'"
                      class="lable_txt fsb_7 txt_p pe-0" style="text-align: end;"> {{historyRecord.statusLabel ? historyRecord.statusLabel : '-'}}
                   </div>
                </div>
             </div>
       </div>
       <!-- spinner loader -->
       <div class="text-center" *ngIf="spinnerLoaderBid">
          <div class="spinner-border txt_p" role="status">
             <span class="sr-only">
                <!-- Loading... -->
             </span>
          </div>
       </div>
    </div>
 </div>
 <div *ngIf="newPreviousBidHistory && bidHistorySekeletonLoader">
    <table class="table mb-0 shipment_bidding_car">
       <tbody>
          <tr style="height: 65px;" *ngFor="let fake of generateFake(5)">
             <td>
                <div style="width: 10%; float: left; height: 40px;">
                   <span>
                      <ngx-skeleton-loader appearance="circle" [theme]="{ width: '40px', height: '40px' }"
                         class="loader_m_0"></ngx-skeleton-loader>
                   </span>
                </div>
                <div style="width: 80%; float: right;">
                   <span>
                      <ngx-skeleton-loader count="1" [theme]="{ 'border-radius': '0', width: '80%', height: '12px' }"
                         class="loader_mb_0">
                      </ngx-skeleton-loader>
                   </span>
                </div>
             </td>
             <td>
                <div class="loader_mb_0">
                   <ngx-skeleton-loader count="1" [theme]="{ 'border-radius': '0', width: '80%', height: '12px' }"
                      class="loader_mb_0">
                   </ngx-skeleton-loader>
                </div>
             </td>
             <td>
                <div class="loader_mb_0">
                   <ngx-skeleton-loader count="1" [theme]="{ 'border-radius': '0', width: '80%', height: '12px' }"
                      class="loader_mb_0">
                   </ngx-skeleton-loader>
                </div>
             </td>
             <td>
                <div class="loader_mb_0">
                   <ngx-skeleton-loader count="1" [theme]="{ 'border-radius': '0', width: '80%', height: '12px' }"
                      class="loader_mb_0">
                   </ngx-skeleton-loader>
                </div>
             </td>
          </tr>
       </tbody>
    </table>
 </div>
 <div *ngIf="!bidHistorySekeletonLoader && newPreviousBidHistory?.length === 0  && acceptBidList?.length === 0">
    <div class="Empty_State_body">
       <div class="Empty_State_con">
          <div class="Empty_State_img">
             <img class="img-fluid"
                src="{{serverPathForUrl}}/portal/images/after-login/Empty_State_icons/graphic_no-transactions.svg ">
          </div>
          <div>
             <p class="fs_24 fsb_6 txt_b pb-1" style="margin: 30px 0 12px;">No Quote History...
                <span class="txt_p"> Yet</span>
             </p>
             <p class="fs_16 fsn_4 txt_lb pb-1" style="margin: 0px 0 20px;">You currently dont have any
                Bids on this load. Assign the Load to the carriers in your network to start the
                Bidding process. You can directly negotiate the Load rate by Accepting a Quote or
                Re-Bidding on the Carriers Quote price.
             </p>
          </div>
       </div>
    </div>
 </div>
 <div class="" style="position: relative;" *ngIf="sidePanelProfileDataId">
    <div class="side-show-panel">
       <div class="side_bar_con">
          <app-shipper-side-panel [sidePanelProfileData]="sidePanelProfileData"
             [sidePanelProfileDataId]="sidePanelProfileDataId" [userType]="userType"
             (closeSidePanel)="closePanel($event)"></app-shipper-side-panel>
       </div>
    </div>
 </div>
 
 <div class="" style="position: relative;" *ngIf="sidePanelProfileDataId">
    <div class="side-show-panel">
       <div class="side_bar_con">
          <app-carrier-side-panel [sidePanelProfileData]="sidePanelProfileData"
             [sidePanelProfileDataId]="sidePanelProfileDataId" [userType]="userType"
             (closeSidePanel)="closePanel($event)"></app-carrier-side-panel>
       </div>
    </div>
 </div>
 <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px',fullScreenBackdrop:true }"></ngx-loading>