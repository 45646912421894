<div class="user_pro_banner mt_30" >
    <div class="lane_booked_card_r1">
      <div class="carrierN_img" [ngClass]="laneBidRecord?.carrier?.legalName !=this.userName ? 'pointer' : ''"  (click)="eventActionProfileSidePanel(laneBidRecord,'profile_side_panel')">
        <span *ngIf="laneBidRecord?.isPreferred === 1" class="material-icons star_marked">star</span>
        <img *ngIf="laneBidRecord?.carrier?.profileImage" src="{{laneBidRecord?.carrier?.profileImage}}" class="carrierN_img">
        <span class="initial_name initial_r_32" *ngIf="!laneBidRecord?.carrier?.profileImage" >{{laneBidRecord?.carrier?.legalName | acronymOfName}}</span>
      </div>
  
      <div class="ms-3" [ngClass]="laneBidRecord?.carrier?.legalName !=this.userName ? 'pointer' : ''"  >
        <div class="cr_name_tag" (click)="eventActionProfileSidePanel(laneBidRecord,'profile_side_panel')">
          <span class="fs_18 fw_500 txt_b text-truncate" matTooltip="{{laneBidRecord?.carrier?.legalName ? laneBidRecord?.carrier?.legalName : '-'}}">{{laneBidRecord?.carrier?.legalName ? laneBidRecord?.carrier?.legalName : '-'}}</span>
           <img *ngIf="laneBidRecord?.carrier?.subscription" src="{{serverPathForUrl}}/portal/images/after-login/premium_plan_icon.png"
            class="img-fluid verified_icon">
        </div>
        <div class="unlock_btn" *ngIf="userType=='SHIPPER' && !laneBidRecord?.carrier?.shipperCarrierScoreMapping" (click)="unlockSafetyScore(laneBidRecord,laneBidRecord?.carrier?.dotNumber,'unlockSafetyScore')">
          <button><img src="\assets\icon\unlock_btn_icon.svg" class="mr-1">Unlock Safety Score</button>
        </div>
  
        <div class="mt-2" *ngIf="userType=='SHIPPER' && laneBidRecord?.carrier?.shipperCarrierScoreMapping">
          <div class="ecarrier_check_box">
            <div>
              <span class="d-block pointer">
                <img src="\assets\icon\info_icon_green.svg" matTooltip="{{eCarrierCheckRisk?.lowRisk}}" *ngIf="laneBidRecord?.carrier?.carrierSafetyScore?.riskLevel=='4'">
                <img src="\assets/icon/ecarrier_info_light_red.svg" matTooltip="{{eCarrierCheckRisk?.highRisk}}" *ngIf="laneBidRecord?.carrier?.carrierSafetyScore?.riskLevel=='2'">
                <img src="\assets/icon/ingo_icon_yellow.svg" matTooltip="{{eCarrierCheckRisk?.elevatedRisk}}" *ngIf="laneBidRecord?.carrier?.carrierSafetyScore?.riskLevel=='3'">
                <img src="\assets/icon/ecarrier_info_light_pure_red.svg" matTooltip="{{eCarrierCheckRisk?.disqualified}}" *ngIf="laneBidRecord?.carrier?.carrierSafetyScore?.riskLevel=='1'">
              </span>
              <span class="fs_10 txt_b">{{laneBidRecord?.carrier?.carrierSafetyScore?.riskLabel ? laneBidRecord?.carrier?.carrierSafetyScore?.riskLabel : '-'}}</span>
            </div>
    
            <div *ngIf="laneBidRecord?.carrier?.carrierSafetyScore?.authority">
              <span class="d-block"><img src="\assets\icon\ecarrier_succes_icon.svg"></span>
              <span class="fs_8 txt_b">Authority</span>
            </div>
    
            <div>
              <span class="d-block"><img src="\assets\icon\ecarrier_crases_icon_red.svg"></span>
              <span class="fs_10 txt_b">Crashes<span class="text_red ms-1">({{laneBidRecord?.carrier?.carrierSafetyScore?.recentCrashes ? laneBidRecord?.carrier?.carrierSafetyScore?.recentCrashes : '0'}})</span></span>
            </div>
    
            <div>
              <span class="d-block"><span class="c_nu">{{laneBidRecord?.carrier?.carrierSafetyScore?.recentInspections ? laneBidRecord?.carrier?.carrierSafetyScore?.recentInspections : '0'}}</span></span>
              <span class="fs_10 txt_b">Inspection</span>
            </div>
            <div style="text-align: right;" class="view_snapshot_btn pointer" (click)="ecarriercheck(laneBidRecord?.carrier)">
              <button class="fs_8 txt_p fsm_5">View Safety Report</button></div>
          </div>
        </div>
      </div>
    </div>
  
    <div class="text-truncate user_mess" style="text-align: center;" matTooltip="{{laneBidRecord?.note ? laneBidRecord?.note : ''}}">{{laneBidRecord?.note ? laneBidRecord?.note : '-'}}</div>
    <div class="w_11">
      <p class="txt_p cu_bid">Current Quote Rate:</p>
      <p class="price_p">
        <span>${{laneBidRecord?.bidAmount | twoDecimalPipe}}</span>
        <span class="txt_lb">
          <app-rate-per-mile class="fs_14 fsm_5 mt-1" [amount]="laneBidRecord?.bidAmount" [miles]="laneBidRecord?.lane?.miles"></app-rate-per-mile>
        </span>
      </p>
    </div>
  
    <div *ngIf="!isArchived" class="d_flex_w_27">
      <span class="re_bid_btn" *ngIf="laneBidRecord?.bidAmount && laneBidRecord?.status != 2"><button class="btn" (click)="viewLaneBidHistory(laneBidRecord)">View history</button></span>
      <span class="re_bid_btn" *ngIf="laneBidRecord?.status != 2"><button class="btn" (click)="eventAction(laneBidRecord,'laneRebid')">Re-quote</button></span>
      <span class="Accept_btn" *ngIf="laneBidRecord?.actionBy != selfUserId && laneBidRecord?.status != 2 && laneBidRecord?.isConnected == true"><button class="btn" (click)="eventAction(laneBidRecord,'laneAcceptBidCarrier')">Accept</button></span>
      <span class="Accept_btn adisabled" *ngIf="laneBidRecord?.actionBy == selfUserId && laneBidRecord?.status != 2 && laneBidRecord?.isConnected == true"><button class="btn">Accept</button></span>
      <span class="Accept_btn" *ngIf="laneBidRecord?.actionBy != selfUserId && laneBidRecord?.isConnected == false && laneBidRecord?.status != 2"><button class="btn" (click)="eventAction(laneBidRecord,'connectInviteLane')">Accept</button></span>
      <span class="Accept_btn adisabled" *ngIf="laneBidRecord?.actionBy == selfUserId && laneBidRecord?.status != 2 && laneBidRecord?.isConnected == false"><button class="btn">Accept</button></span>
      <span *ngIf="laneBidRecord?.status == 2" class="confi_btn"><span class="txt_green">Confirmed Rate</span></span>
    </div>
  
    <div *ngIf="isArchived == true" class="d_flex_w_27">
      <span class="re_bid_btn" *ngIf="laneBidRecord?.status != 2"><button class="btn adisabled">Re-quote</button></span>
      <span class="Accept_btn" *ngIf="laneBidRecord?.actionBy != selfUserId && laneBidRecord?.status != 2 && laneBidRecord?.isConnected == true"><button class="btn adisabled">Accept</button></span>
      <span class="Accept_btn" *ngIf="laneBidRecord?.actionBy == selfUserId && laneBidRecord?.status != 2 && laneBidRecord?.isConnected == true"><button class="btn adisabled">Accept</button></span>
      <span class="Accept_btn" *ngIf="laneBidRecord?.actionBy != selfUserId && laneBidRecord?.isConnected == false && laneBidRecord?.status != 2"><button class="btn adisabled">Accept</button></span>
      <span class="Accept_btn" *ngIf="laneBidRecord?.actionBy == selfUserId && laneBidRecord?.status != 2 && laneBidRecord?.isConnected == false"><button class="btn adisabled">Accept</button></span>
      <span *ngIf="laneBidRecord?.status == 2" class="confi_btn"><span class="txt_green">Confirmed Rate</span></span>
    </div>
  </div>
  
  <div class="" style="position: relative;" *ngIf="sidePanelProfileDataId">
    <div class="side-show-panel">
      <div class="side_bar_con">
        <app-carrier-side-panel [sidePanelProfileData]="sidePanelProfileData" [sidePanelProfileDataId]="sidePanelProfileDataId" [userType]="userType" (closeSidePanel)="closePanel($event)"></app-carrier-side-panel>
      </div>
    </div>
  </div>
  <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px',fullScreenBackdrop:true }"></ngx-loading>
  
  