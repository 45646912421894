import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/commons/service/alert.service';
import { CommonService } from 'src/app/commons/service/common.service';
import { SharedService } from 'src/app/commons/service/shared.service';
import { AppSettings } from 'src/app/commons/setting/app_setting';
import { LoadPopupComponent } from 'src/app/loads-shared/load-popup/load-popup.component';
import { UserPopupComponent } from 'src/app/profile-shared/user-popup/user-popup.component';
import { SubscriptionPopupComponent } from 'src/app/shared/subscription-popup/subscription-popup.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-create-lanes',
  templateUrl: './create-lanes.component.html',
  styleUrls: ['./create-lanes.component.css']
})
export class CreateLanesComponent implements OnInit {
  public sidePanelProfileDataId: any;
  public sidePanelData:any;
  public loadData:any
  public paymentPendingByLaneCount:any;
  public loadsAwardedByLaneCount:any
  public loadsLostByLaneCount:any
  public createdLoadList = [];
  public showAdvancedFilter = false;
  public totalShipment: any;
  public createdLaneLoadsCount:any;
  public titleFilter: any;
  public serverPathForUrl: any;
  public pickupDate: any;
  public dropDate: any;
  public statusLabelShow: any;
  public statusLabel: any;
  public equipmentTypeFilter: any;
  public equipmentName: any;
  public equipmentNameShow: any;
  public equipmentTypeId: any;
  public driverName: any;
  public driverId: any;
  public commonDriverId: any;
  public carrierName: any;
  public carrierId: any;
  public allLoadsByLaneCount: any;
  public selected;
  public dropLoc = false;
  public selfId: any;
  public radius: any;
  public isLane = true;
  public currentSearchStatus: any;
  public equipmentListing: any = [];
  public postedLanesCount:any;
  public availableLanes:any;
  public confirmCount:any;
  public confirmedLanesCount:any;
  public archivedLanesCount:any;
  public subTabMenu: any;
  public clearAllEnable = false;
  public userType: any;
  public phStates: any;
  public currentRouteParams: any;
  public params: any;
  public cancelRequestUser: any = null;
  public searchBoxError = false;
  public filterCheck = true;
  public countSpace = 1;
  page = 1;
  public allLanesCount:any;
  public stateShow: any;
  public stateName: any;
  totalPage = 1;
  public currentStatus = 'allLanes';
  public tabCount: any;
  public userDetails: any;
  public lanesList = [];
  public tabActionDisabled = false;
  public skeletonLoader = false;
  public subscriptionData:any;
  public searchModel: any = { pickupLocation: '', dropoffLocation: '' };
  public totalLanes: any;
  loading: boolean;
  public spinnerLoader = false;
  public dataNotFound = false;
  public currentquotedLanesCount:any;
  stateId: any;
  totalBids: any;
  totalBidsForC: any;
  public awardedBidByLanesCount: any;
  public isAwardedBidByLanes: boolean = false;
  public createByLaneCount:any;
  public quotedLoadsByLaneCount:any;
  public loadsLostByLaneLanesCount:any
  public isCncelByLoads: any;
  public cancelByLoadsCount: any
  public confirmedBidByLoadsCount: any;
  public isExpiredByLane: boolean = false
  public expiredByLaneCount: any;
  public paymentPendingByLanesCount:any
  public awardedBidByLaneCount: any;
  public isawardedBidByLane: boolean = false;
  public isCreateByLaneCount:boolean=false;
  public isQuotedLoadsByLane:boolean=false;
  public isPaymentPendingByLaneCount:boolean=false;
  public isloadsLostByLaneLanes:boolean=false;
  public isConfirmedBidByLoads = false;
  public allLanesCounts: any
  expiredLoadsByLaneCount: any;

  constructor(
    public location: Location,
    public commonService: CommonService,
    public dialog: MatDialog,
    private router:Router,
    private sharedService: SharedService,
    private alertService: AlertService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.markReadAlertNotification('newLoadsByLane');
    this.currentStatus=this.route.snapshot.params['tabType'];
    this.serverPathForUrl = environment.serverPathForUrl;
    this.userType=localStorage.getItem('user_type');
    this.params = {
      limit: (this.currentStatus == 'expiredLoadsByLane' || this.currentStatus == 'expiredLoads') ? 16 : 12,
    };
    this.subTabMenuFilter(this.currentStatus)
    let userDetail = this.sharedService.getAddress();
    if (userDetail !== undefined) {
      this.userInfo(userDetail);
    }
    this.commonService.userData.subscribe((userData) => {
      this.userInfo(userData);
    });
    this.getStates();
    let configValue = this.sharedService.getConfig();
    if (configValue !== undefined) {
      this.getConfigSet(configValue);
    }
    this.commonService.configData.subscribe((userData) => {
      this.getConfigSet(userData);
    });
    if (this.userType === 'SHIPPER') {
      this.getSubscriptionAlert();
    }
  }

  // To get Equipment Type
  equipmentSelected(equipment: any) {
    this.filterCheck = false;
    this.searchModel.equipmentId = parseInt(equipment.id);
    this.equipmentName = equipment.label;
    this.equipmentTypeId = parseInt(equipment.id);
  }

  // To get state name
  stateSelected(state: any) {
    this.filterCheck = false;
    this.searchModel.stateId = state.sortname;
    this.stateName = state.name;
    this.stateId = state.sortname;
  }
    
  searchQuantity(event: any) {
   let searchStr = event.target.value;
     this.searchModel.title = searchStr;
     if(searchStr){
          this.filterCheck=false;
     }
    else if(this.titleFilter){
      this.filterCheck=false;
    }
    else{
      this.filterCheck=true;
    }
    let lastword = searchStr.charAt(searchStr.length - 1);
      if (lastword === ' ') {
        this.countSpace = this.countSpace + 1;
      } else {
         this.countSpace = 0;
      }
     if (this.countSpace >= 2 || (lastword === ' ' && searchStr.length === 1)) {
       this.searchModel.title = this.searchModel.title.substring(0, this.searchModel.title.length - 1);
          this.searchBoxError = true;
     } else {
       this.searchBoxError = false;
     }
   }

    // Function to get data from config API 
    getConfigSet(configValue: any) {
      this.equipmentListing = configValue.equipmentTypes;
    }

    // Function to get user info.
    userInfo(userDtail) {
      this.userDetails = userDtail;
    }
      
    // To get states list from API call
    getStates(){
      this.commonService.getStateList().subscribe((serverRes) => {
        if (serverRes.success == true) {
         this.phStates = serverRes.response;
        } else if (serverRes.success == false) {
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_d',
            'check_circle',
            'States',
            'Unexpected Error.'
          );
        }
      });
    }
   // To apply filter by selected value 
   applyFilterSubmit() {
    if (this.userType == 'SHIPPER' && this.filterCheck == false && (this.subscriptionData?.isPremium != true || this.subscriptionData?.subscriptionStatus == 'canceled'))
    {
      this.getSubscriptionAlertPopup();
    } else {
      this.skeletonLoader = false;
      this.lanesList = [];
      this.tabCount = '';
      this.page = 1;
      this.clearAllEnable = true;
      if (this.searchModel.title) {
        this.params.title = this.searchModel.title;
        this.titleFilter = this.searchModel.title;
      } else {
        this.params.title = null;
        this.titleFilter = null;
        this.searchModel.title=null
      }
      if (this.equipmentName) {
        this.params.equipmentType = this.searchModel.equipmentId;
        this.equipmentNameShow = this.equipmentName;
        this.equipmentTypeId = this.searchModel.equipmentId;
      } else {
        this.params.equipmentType = null;
        this.equipmentNameShow = null;
        this.equipmentTypeId = null;
      }
      if (this.stateName) {
        this.params.stateId = this.searchModel.stateId;
        this.searchModel.stateId = this.searchModel.stateId;
        this.stateShow = this.stateName; 
      } else {
        this.params.stateId = null;
        this.stateShow = null;
        this.stateId = null;
      }
      if (this.searchModel.equipmentId == null && this.searchModel.stateId == null && this.searchModel.title == null){
        this.filterCheck = true;
      }
      else{
        this.filterCheck = false;
      }
      if (
        this.searchModel.title ||
        this.equipmentName ||
        this.stateName
      ) {
        this.currentSearchStatus = 'search';
      } else {
        this.currentSearchStatus = '';
      }

      if (this.currentStatus != 'availableToBid' && this.currentStatus != 'currentBids') {
        this.laneList();
      }
    }
  }

  // To clear all the data from filter popup 
  clearFilter(event) {
    this.clearAllEnable=false;
    this.params.title=null;
    this.equipmentTypeFilter = null;
    this.searchModel.equipmentId = null;
    this.params.equipmentType = null;
    this.equipmentName = null;
    this.searchModel.title = null;
    this.params.state = null;
    this.stateName = null
    this.searchModel.stateId = null;
    this.params.stateId = null;
    this.stateId = null;
    if (event){
      this.currentStatus = event;
    }else{
      this.currentStatus = this.currentStatus;
      this.applyFilterSubmit();

    }
  }

  singleFilterClose(event) {
    if (event === 'title') {
      this.titleFilter = null;
      this.params.title = null;
      this.searchModel.title = null; 
    }
    if (event === 'equipment') {
      this.equipmentTypeFilter = null;
      this.params.equipmentType = null;
      this.searchModel.equipmentType = null;
      this.searchModel.equipmentId = null;
      this.equipmentNameShow = null;
      this.equipmentName = null;
      this.equipmentTypeId = null;
    }
    if (event === 'state') {
      this.params.state = null;
      this.params.stateId = null;
      this.searchModel.state = null;
      this.searchModel.stateId = null;
      this.stateShow = null;
      this.stateName = null;
      this.stateId = null;
    }
    if (this.searchModel.equipmentId == null && this.searchModel.stateId == null && this.searchModel.title == null){
      this.filterCheck = true;
    }
    this.lanesList = [];
    this.page = 1;
    this.skeletonLoader = false;
    if (this.equipmentName) {
      this.currentSearchStatus = 'search';
    } else {
      this.currentSearchStatus = '';
    }
    this.laneList(this.params);
  }

  // function for page scrolling
  onScroll() {
    if (this.page < this.totalPage && (this.currentStatus === 'allLanes' || this.currentStatus === 'quotedLanes'
    || this.currentStatus === 'confirmedLanes' || this.currentStatus === 'archivedLanes')) {
      this.page = this.page + 1;
      this.getMoreData(null);
    }
    if (this.page < this.totalPage && (this.currentStatus === 'allLoadsByLane' || this.currentStatus === 'quotedLoadsByLane' 
    || this.currentStatus === 'loadsAwardedByLane' || this.currentStatus === 'loadsLostByLane' || this.currentStatus === 'paymentPendingByLane' || this.currentStatus === 'expiredLoadsByLane')) {
      this.page = this.page + 1;
      this.getMoreDataForLoad(null);
    }
  }

  getSubscriptionAlertPopup(){
    const dialogRef = this.dialog.open(SubscriptionPopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      data: { 
        openPop: 'subscriptionAlert',
        subscriptionData: this.subscriptionData
       },
    });
    dialogRef.afterClosed().subscribe((result) => {
    });
  }

   // Function for generate duplicate loaders 
   public generateFake(count: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes;
  }

  // To change sub tab menues and call api according to the event 
  subTabMenuFilter(event: any) {
    this.lanesList=[];
    this.skeletonLoader=false;
    if (event === 'allLanes') {
      this.clearFilter('allLanes');
      this.currentStatus = 'allLanes';
      this.filterByStatus('allLanes');
    }
    if (event === 'quotedLanes') {
      this.clearFilter('quotedLanes');
      this.currentStatus = 'quotedLanes';
      this.filterByStatus('quotedLanes');
    } 
    else if (event === 'confirmedLanes') {
      this.clearFilter('confirmedLanes');
      this.currentStatus = 'confirmedLanes';
      this.filterByStatus('confirmedLanes');
    } else if (event === 'archivedLanes') {
      this.clearFilter('archivedLanes');
      this.subTabMenu = 'archivedLanes';
      this.currentStatus = 'archivedLanes';
      this.filterByStatus('archivedLanes');
    } 
    else if (event === 'allLoadsByLane') {
      this.clearFilter('allLoadsByLane');
      this.currentStatus = 'allLoadsByLane';
      this.filterByStatus('allLoadsByLane');
      this.markReadAlertNotification('allLoadsByLane');
    }
    else if (event === 'quotedLoadsByLane') {
      this.clearFilter('quotedLoadsByLane');
      this.currentStatus = 'quotedLoadsByLane';
      this.filterByStatus('quotedLoadsByLane');
    }
    else if (event === 'paymentPendingByLane') {
      this.clearFilter('paymentPendingByLane');
      this.subTabMenu = 'paymentPendingByLane';
      this.currentStatus = 'paymentPendingByLane';
      this.filterByStatus('paymentPendingByLane');
      this.markReadAlertNotification('paymentPendingByLane');
    }
    else if (event === 'expiredLoadsByLane') {
      this.clearFilter('expiredLoadsByLane');
      this.currentStatus = 'expiredLoadsByLane';
      this.filterByStatus('expiredLoadsByLane');
    }
    else if (event === 'loadsAwardedByLane') {
      this.clearFilter('loadsAwardedByLane');
      this.currentStatus = 'loadsAwardedByLane';
      this.filterByStatus('loadsAwardedByLane');
      this.markReadAlertNotification('loadsAwardedByLane');
    }
    else if (event === 'loadsLostByLane') {
      this.clearFilter('loadsLostByLane');
      this.currentStatus = 'loadsLostByLane';
      this.filterByStatus('loadsLostByLane');
      this.markReadAlertNotification('loadsLostByLane');
    }
  }

   // To filter tabs according to currentStatus which is included in route
   filterByStatus(event: any) {
    this.dataNotFound = false;
    this.page = 1;
    if (event !== null) {
        if (event === 'allLanes') {
          this.currentRouteParams = 'allLanes';
          this.currentStatus = 'allLanes';
        }  else if (event === 'quotedLanes') {
          this.currentRouteParams = 'quotedLanes';
          this.currentStatus = 'quotedLanes';
        }else if (event === 'confirmedLanes') {
          this.currentRouteParams = 'confirmedLanes';
          this.currentStatus = 'confirmedLanes';
        }
        else if (event === 'allLoadsByLane') {
          this.currentRouteParams = 'allLoadsByLane';
          this.currentStatus = 'allLoadsByLane';
        }
        else if (event === 'quotedLoadsByLane') {
          this.currentRouteParams = 'quotedLoadsByLane';
          this.currentStatus = 'quotedLoadsByLane';
          this.subTabMenu = 'quotedLoadsByLane';
        } else if (event === 'archivedLanes') {
          this.currentRouteParams='archivedLanes';
          this.currentStatus = 'archivedLanes';
        }
        else if(event === 'paymentPendingByLane') {
          this.currentRouteParams='paymentPendingByLane';
          this.currentStatus = 'paymentPendingByLane';
        }
        else if (event === 'expiredLoadsByLane') {
          this.currentRouteParams = 'expiredLoadsByLane';
          this.currentStatus = 'expiredLoadsByLane';
        }
        if (event === 'loadsAwardedByLane') {
         this.currentRouteParams = 'loadsAwardedByLane';
         this.currentStatus = 'loadsAwardedByLane';
       }
       else if (event === 'loadsLostByLane') {
         this.currentRouteParams = 'loadsLostByLane';
         this.currentStatus = 'loadsLostByLane';
       }
      this.currentStatus = event;
    }
    this.applyFilterSubmit();
  }

  // To get lanes list from API
  laneList(params = {}) {
    if((this.currentStatus=='allLanes') || (this.currentStatus=='confirmedLanes') || (this.currentStatus=='quotedLanes') || (this.currentStatus=='archivedLanes')){
      this.tabCount = '';
      this.lanesList = [];
      this.page = 1;
      let uri = null;
      var APIparams = this.getAPIParams(null);
      if (this.userType=='SHIPPER') {
        this.router.navigate(['/recurring-lanes/create-lanes/' + this.currentStatus], {
         queryParams: {
           equipmentType: this.params?.equipmentType,
           equipmentName: this.equipmentName,
           title: this.params?.title,
           state: this.stateName,
           stateId: this.params?.stateId,
         },
        });
       }
       if (this.userType=='CARRIER') {
        this.router.navigate(['/recurring-lanes/available-lanes/' + this.currentStatus], {
         queryParams: {
           equipmentType: this.params?.equipmentType,
           equipmentName: this.equipmentName,
           title: this.params?.title,
           state: this.stateName,
           stateId: this.params?.stateId,
         },
        });
       }
       this.createLoadList()
       this.cancelRequestUser = this.commonService.getList(APIparams).subscribe(
         (ServerRes) => {
           if (ServerRes.success === true) {
             this.cancelRequestUser = null;
             this.tabActionDisabled = false;
             this.totalLanes = (ServerRes.response?.tabCount?.allLanes + ServerRes.response?.tabCount?.quotedLanes);
             this.totalPage = ServerRes.response.totalPage;
             this.tabCount = ServerRes.response?.tabCount;
             this.confirmedLanesCount = ServerRes.response?.tabCount?.confirmedLanes;
             this.postedLanesCount = ServerRes.response.tabCount?.allLanes;
             this.archivedLanesCount = ServerRes.response.tabCount?.archivedLanes;
             this.availableLanes = ServerRes.response?.tabCount?.availableLanes;
             this.confirmCount = ServerRes.response?.tabCount?.confirmedLanes;
             this.currentquotedLanesCount=ServerRes.response?.tabCount?.quotedLanes;
             this.allLanesCount=ServerRes.response?.tabCount?.allLanes;
             this.skeletonLoader = true;
             let result = ServerRes.response.lanes;
             if (result && result.length > 0) {
               for (let v = 0; v < result.length; v++) {
               }
               this.lanesList = result;
              
             } else {
               this.lanesList = [];
             }
             this.loading = false;
           } else {
             this.lanesList = [];
             this.totalLanes = 0;
             this.loading = false;
             this.totalPage = 0;
             this.tabCount = 0;
             this.skeletonLoader = true;
             this.tabActionDisabled = false;
           }
         },
         (error) => {
           this.skeletonLoader = true;
           this.lanesList = [];
           this.totalLanes = 0;
           this.loading = false;
           this.totalPage = 0;
           this.tabCount = 0;
           this.tabActionDisabled = false;
         }
       );
    }
    if((this.currentStatus=='allLoadsByLane') || (this.currentStatus=='paymentPendingByLane') || (this.currentStatus=='quotedLoadsByLane') || (this.currentStatus=='expiredLoadsByLane') || (this.currentStatus=='loadsAwardedByLane') || (this.currentStatus=='loadsLostByLane')){
      this.tabCount = '';
      this.createdLoadList = [];
      this.page = 1;
      let uri = null;
      //get dynamic URI for APIs
      if(this.userType=='SHIPPER'){
        this.router.navigate(['/recurring-lanes/create-lanes/' + this.currentStatus], {
          queryParams: {
            batchId: this.params?.batchId,
            title: this.params?.title,
            pickupDate: this.params?.pickupDate,
            dropDate: this.params?.dropDate,
            equipmentType: this.params?.equipmentType,
            equipmentName: this.equipmentName,
            state: this.stateName,
            stateId: this.params?.stateId,
            driverId: this.driverId,
            driverName: this.driverName,
            carrierId: this.carrierId,
            carrierName: this.carrierName,
            selfId: this.selfId,
            status: this.params?.status,
            statuslabel: this.statusLabelShow,
          },
        });
      }
      if(this.userType=='CARRIER'){
        this.router.navigate(['/recurring-lanes/available-lanes/' + this.currentStatus], {
          queryParams: {
            batchId: this.params?.batchId,
            title: this.params?.title,
            pickupDate: this.params?.pickupDate,
            dropDate: this.params?.dropDate,
            equipmentType: this.params?.equipmentType,
            equipmentName: this.equipmentName,
            driverId: this.driverId,
            driverName: this.driverName,
            carrierId: this.carrierId,
            carrierName: this.carrierName,
            selfId: this.selfId,
            status: this.params?.status,
            statuslabel: this.statusLabelShow,
          },
        });
      }

      this.createLaneList();
      var APIparams = this.getAPIParam(null);
       this.commonService.getList(APIparams).subscribe(
        (ServerRes) => {
          if (ServerRes.success === true) {
            this.totalShipment = ServerRes.response.totalShipment;
            this.totalPage = ServerRes.response.totalPage;
            this.tabCount = ServerRes.response.tabCount;
            this.createdLaneLoadsCount=(ServerRes.response.tabCount?.allLoadsByLane + ServerRes.response.tabCount?.quotedLoadsByLane);
            this.paymentPendingByLaneCount= ServerRes.response.tabCount?.paymentPendingByLane
            this.loadsAwardedByLaneCount= ServerRes.response.tabCount?.loadsAwardedByLane
            this.loadsLostByLaneCount= ServerRes.response.tabCount?.loadsLostByLane
            this.allLoadsByLaneCount = ServerRes.response?.tabCount?.allLoadsByLane;
            this.quotedLoadsByLaneCount = ServerRes.response?.tabCount?.quotedLoadsByLane;
            this.expiredLoadsByLaneCount = ServerRes.response?.tabCount?.expiredLoadsByLane;
            this.skeletonLoader = true;
            let result = ServerRes.response.shipments;
            if (result && result.length > 0) {
              for (let v = 0; v < result.length; v++) {
                result[v].pickupLocationLat = result[v].waypoints[0].latitude;
                result[v].pickupLocationLong = result[v].waypoints[0].longitude;
                result[v].pickupDate = result[v].waypoints[0].date;
                result[v].pickupTimeZone = result[v].waypoints[0].timeZone;
                result[v].pickupTimeslot = result[v].waypoints[0].timeSlot;
                result[v].pickupLocationLongName = result[v].waypoints[0].locationLongName;
                result[v].pickupInstructions = result[v].waypoints[0].specialRequirement;
                result[v].pickupCity = result[v].waypoints[0].city;
                result[v].pickupState = result[v].waypoints[0].state;
                result[v].pickupPincode = result[v].waypoints[0].zip;
                result[v].dropStatus = result[v].waypoints[result[v].waypoints.length - 1].status;
                result[v].dropTimeZone = result[v].waypoints[result[v].waypoints.length - 1].timeZone;
                result[v].dropTimeslot = result[v].waypoints[result[v].waypoints.length - 1].timeSlot;
                result[v].dropDate = result[v].waypoints[result[v].waypoints.length - 1].date;
                result[v].dropLocationLat = result[v].waypoints[result[v].waypoints.length - 1].latitude;
                result[v].dropLocationLong = result[v].waypoints[result[v].waypoints.length - 1].longitude;
                result[v].dropInstructions = result[v].waypoints[result[v].waypoints.length - 1].specialRequirement;
                result[v].dropLocationLongName = result[v].waypoints[result[v].waypoints.length - 1].locationLongName;
                result[v].dropCity = result[v].waypoints[result[v].waypoints.length - 1].city;
                result[v].dropState = result[v].waypoints[result[v].waypoints.length - 1].state;
                result[v].dropPincode = result[v].waypoints[result[v].waypoints.length - 1].zip;
                result[v].newCreatedAt = result[v].createdAt;
                result[v].newUpdatedAt = result[v].updatedAt;
              }
              this.createdLoadList = result;
            } else {
  
              this.createdLoadList = [];
            }
            this.loading = false;
          } else {
            this.createdLoadList = [];
            this.totalShipment = 0;
            this.loading = false;
            this.totalPage = 0;
            this.tabCount = 0;
            this.skeletonLoader = true;
          }
        },
        (error) => {
          this.skeletonLoader = true;
          this.createdLoadList = [];
          this.totalShipment = 0;
          this.loading = false;
          this.totalPage = 0;
          this.tabCount = 0;
        }
      );
    }
    
  }
       // To get required params for API 
    getAPIParams(str) {
      let APIparams, params;
      params = {
        limit: (this.currentStatus == 'expiredLoadsByLane' || this.currentStatus == 'expiredLoads') ? 16 : 12,
        page: this.page,
          state: this.stateName,
          equipmentType: this.params?.equipmentType,
          equipmentName: this.equipmentName,
          title: this.params?.title,
          orderBy: 'DESC',
          tabType: this.currentStatus,
      };
      APIparams = {
        apiKey: AppSettings.APIsNameArray.SHIPMENT.NEWLANELIST,
        uri: this.commonService.getAPIUriFromParams(params),
      };
      return APIparams;
    }

    createLane(){
      this.router.navigateByUrl('recurring-lanes/create/shipper')
    }

     // Functions to get subscription alert popup
     getSubscriptionAlert() {
      this.commonService.getSubscriptionAlert().subscribe((serverRes) => {
         this.subscriptionData =  serverRes.response;
      });
     }

  // To get required params for API 
  getAPIParam(str) {
    this.radius = null;
    let APIparams, params;
    params = {
      limit: (this.currentStatus == 'expiredLoadsByLane' || this.currentStatus == 'expiredLoads') ? 16 : 12,
      radius: this.radius,
      page: this.page,
      tabType: this.currentStatus,
      batchId: this.params?.batchId,
      title: this.params?.title,
      pickupDate: this.params?.pickupDate,
      dropDate: this.params?.dropDate,
      equipmentType: this.params?.equipmentType,
      equipmentName: this.equipmentName,
      driverId: this.params?.commonDriverId,
      driverName: this.driverName,
      status: this.params?.status,
      carrierId: this.params?.carrierId,
      statuslabel: this.statusLabelShow,
    };
    APIparams = {
      apiKey: AppSettings.APIsNameArray.SHIPMENT.CREATELOADSLIST,
      uri: this.commonService.getAPIUriFromParams(params),
    };
    return APIparams;
  }

    // Get more data by pagination for load.
    getMoreDataForLoad(str) {
      this.spinnerLoader = true;
      var APIparams = this.getAPIParam(str);
      this.commonService.getList(APIparams).subscribe((ServerRes) => {
        let result = ServerRes.response.shipments;
        this.totalShipment = ServerRes.response.totalShipment;   
        this.spinnerLoader = false;
        if (ServerRes.response.shipments && ServerRes.response.shipments.length > 0) {
          for (let v = 0; v < result.length; v++) {
            if (result[v]) this.createdLoadList.push(result[v]);
          }
          if (this.page >= this.totalPage) {
            this.dataNotFound = true;
          }
        }
      });
    }

    // Get more data by pagination for lane.
    getMoreData(str) {
      this.spinnerLoader = true;
      var APIparams = this.getAPIParams(str);
      this.commonService.getList(APIparams).subscribe((ServerRes) => {
        let result = ServerRes.response.lanes;
        this.totalLanes = ServerRes.response.totalLanes;
        this.spinnerLoader = false;
        if (ServerRes.response.lanes && ServerRes.response.lanes.length > 0) {
          for (let v = 0; v < result.length; v++) {
            if (result[v]) this.lanesList.push(result[v]);
          }
          if (this.page >= this.totalPage) {
            this.dataNotFound = true;
          }
        }
      });
    }


    closePanel(event: any) {
      if (event.type === 'close') {
        this.sidePanelProfileDataId = '';
        this.sidePanelData = '';
      }
    }

  // Get events for lane bidding along with event type and laneRecord
  eventAction(event,laneRecord) {
    if(event.type === 'carrier_side_panel'){
      this.sidePanelProfileDataId = laneRecord?.shippers?.id;
    }
    if (event.type === 'firstLaneBid') {
      this.newLanewBid(event.event);
    } else if (event.type === 'laneRebid') {
      if (event.event && event.event.bids) {
        this.newLaneReBid(event.event);
      } else {
        this.newLanewBid(event.event);
      }
     } else if (event.type === 'laneAcceptBidCarrier') {
        this.acceptLaneBidCarrier(event.event);
      } 
      else if (event.type === 'laneAcceptBidForFixRate') {
        this.acceptLaneBidFixRate(event.event);
      }  else if (event.type === 'connectInviteLane') {
        this.acceptConnectInvite(event.event);
     }  else if (event.type === 'connectInviteLaneFixRate') {
      this.acceptConnectInviteFixRate(event.event);
   }  else if (event.type === 'confirm') {
    this.confirmBid(event.event);
  } else if (event.type === 'acceptBid') {
    this.confirmAcceptBid(event.event);
  } else if (event.type === 'acceptOffer') {
    this.acceptOfferRate(event.event);
  } else if (event.type === 'confirmOfferPrice') {
    this.confirmBidOfferRate(event.event);
  }
  if (event.type === 'side_panel') {
    this.sidePanelData = event.event;
    const body = document.getElementsByClassName('sm_navbar_hide')[0];
    body.classList.add('side_panel_manage');
  } else if (event.type === 'bid') {
    this.newBid(event.event);
  } else if (event.type === 'rebid') {
    if (event.event && event.event.bids) {
      this.newRedBid(event.event);
    } else {
      this.newBid(event.event);
    }
  } else if (event.type === 'confirm') {
    this.confirmBid(event.event);
  } 
  }

    // First bid by carrier for lane
    newLanewBid(lanes) {
      const dialogRef = this.dialog.open(LoadPopupComponent, {
        backdropClass: AppSettings.backdropClass,
        width: AppSettings.popWidth,
        data: {
          openPop: 'firstLaneBid',
          laneId: lanes.id,
          laneDetail: lanes,
          amount: lanes.estimatedPrice,
          userNameOther: lanes.shippers.companyName,
          userName: lanes.shippers.companyName,
          bidFor: lanes.shippers.userId,
          contractDocument: lanes.shippers.contractDocument,
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          if (result.event === 'Ok') {
            this.skeletonLoader = false;
            this.laneList();
          }
        }
      });
    }

    // Accept bid for lane by carrier popup 
    acceptLaneBidCarrier(lanes) {
      let amount;
      if(lanes.bids.bidAmount){
        amount = lanes.bids.bidAmount;
      } else {
        amount = lanes.estimatedPrice;
      }
      const dialogRef = this.dialog.open(LoadPopupComponent, {
        backdropClass: AppSettings.backdropClass,
        disableClose: true,
        width: AppSettings.popWidth,
        data: {
          openPop: 'laneAcceptBidCarrier',
          laneId: lanes.id,
          laneDetail: lanes,
          amount: amount,
          userNameOther: lanes.shippers.companyName,
          userName: lanes.shippers.companyName,
          bidFor: lanes.shippers.userId,
          bidingStatus: lanes.bids.status,
          laneBidId: parseInt(lanes.bids.id),
          rateType: lanes.rateType,
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          if (result.event === 'Ok') {
            this.skeletonLoader = false;
            this.laneList();
          }
        }
      });
    }

      //Confirm bid by carrier
  confirmBidOfferRate(record: any) {
    let getUserName;
    if (localStorage.getItem('user_id') === record.bids.actionBy) {
      getUserName = record.carriers.legalName;
    } else {
      getUserName = record.shippers.companyName;
    }
    const dialogRef = this.dialog.open(LoadPopupComponent, {
      backdropClass: AppSettings.backdropClass,
      disableClose: true,
      width: AppSettings.popWidth,
      data: {
        openPop: 'acceptBidConfirmOffer',
        userName: getUserName,
        userNameOther: record.shippers.companyName,
        amount: record.bids.bidAmount,
        note: record.title,
        bidId: parseInt(record.bids.id),
        shipmentId: record.id,
        uniqueId: record.uniqueId,
        paymentMethod: record.paymentMethod,
        bidingStatus: record.bids.status,
        occurence: 'main',
        shipmentDetail: record,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'Ok') {
          this.refreshListLoad();
        }
      }
    });
  }
  
    // To accept offer rate 
    acceptOfferRate(record: any){
      if(this.userDetails?.coiExpired){
        this.checkCertificate(record);
        }else{
          this.acceptOfferRateCheck(record);
      }
    }

     //Accept Offer Rate 
   acceptOfferRateCheck(shipment) {
    let actionByOffer;
    if (shipment.isOfferRate === 'Y' && shipment.overBid === 'Y') {
      actionByOffer = 'confirmOfferPrice';
    } else {
      actionByOffer = 'No';
    }
    this.loadData = null;
    const dialogRef = this.dialog.open(LoadPopupComponent, {
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      data: {
        openPop: 'acceptOfferRate',
        userNameOther: shipment.shippers.companyName,
        userName: shipment.shippers.companyName,
        shipmentId: shipment.id,
        bidFor: shipment.shippers.userId,
        amount: shipment.freightRate,
        paymentMethod: shipment.paymentMethod,
        contractDocument: shipment.shippers.contractDocument,
        actionByOffer: actionByOffer,
        occurence: 'main',
        shipmentDetail: shipment,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'Ok') {
          this.router.navigate([
            '/load/view',
            shipment.id,
            'carrierLoadBids',
          ]);
        }
      }
    });
  }

     //Accept & Confirm bid by carrier
  confirmAcceptBid(record: any) {
    if (this.userDetails?.coiExpired) {
       this.checkCertificate(record);
    } else {
       this.acceptBidCarrier(record);
    }
  }

    // Accept bid for fix rate lane 
    acceptLaneBidFixRate(lanes) {
      const dialogRef = this.dialog.open(LoadPopupComponent, {
        backdropClass: AppSettings.backdropClass,
        disableClose: true,
        width: AppSettings.popWidth,
        data: {
          openPop: 'laneAcceptBidForFixRate',
          laneId: lanes.id,
          laneDetail: lanes,
          amount: lanes.estimatedPrice,
          userNameOther: lanes.shippers.companyName,
          userName: lanes.shippers.companyName,
          rateType: lanes.rateType,
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          if (result.event === 'Ok') {
            this.skeletonLoader = false;
            this.laneList();
          }
        }
      });
    }
  
    // ReBid popup for lane
    newLaneReBid(lanes) {
      let userName;
      if (lanes.bids && lanes.bids.actionBy === localStorage.getItem('user_id')) {
        userName = localStorage.getItem('userName');
      } else {
        userName = lanes.shippers.companyName;
      }
      const dialogRef = this.dialog.open(LoadPopupComponent, {
        backdropClass: AppSettings.backdropClass,
        disableClose: true,
        width: AppSettings.popWidth,
        data: {
          openPop: 'laneRebid',
          userNameOther: lanes.shippers.companyName,
          userName: userName,
          amount: lanes.bids.bidAmount,
          bidFor: lanes.shippers.userId,
          laneDetail: lanes,
          laneId: lanes.id,
          contractDocument: lanes.shippers.contractDocument,
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          if (result.event === 'Ok') {
            this.skeletonLoader = false;
            this.laneList();
          }
        }
      });
    }

    // Accept connect Invite lane popup
    acceptConnectInvite(lanes) {
      const dialogRef = this.dialog.open(LoadPopupComponent, {
        backdropClass: AppSettings.backdropClass,
        disableClose: true,
        width: AppSettings.popWidth,
        data: {
          openPop: 'connectInviteLane',
          laneId: lanes.id,  
          laneDetail: lanes,
          amount: lanes.bids ? lanes.bids.bidAmount : lanes.estimatedPrice,
          userNameOther: lanes.shippers ? lanes.shippers.companyName : lanes.carrier.legalName,
          userName: lanes.carrier ? lanes.carrier.legalName : lanes.shippers.companyName,
          bidingStatus: lanes.status,
          laneBidId: parseInt(lanes.bids.id),
          city: lanes.shippers.city,
          state: lanes.shippers.state,
          equipmentType: lanes.shippers.equipmentType,
          preferredRegions: lanes.shippers.preferredRegions,
          regions: lanes.shippers.regions,
          contractDocument: lanes.shippers.contractDocument,
          rateType: lanes.rateType,
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          if (result.event === 'Ok') {
            this.skeletonLoader = true;
            this.laneList();
          }
        }
      });
    }

    // Accept connect Invite lane popup for fix rate
    acceptConnectInviteFixRate(lanes) {
      const dialogRef = this.dialog.open(LoadPopupComponent, {
        backdropClass: AppSettings.backdropClass,
        disableClose: true,
        width: AppSettings.popWidth,
        data: {
          openPop: 'connectInviteLaneFixRate',
          laneId: lanes.id,  
          laneDetail: lanes,
          amount: lanes.bids ? lanes.bids.bidAmount : lanes.estimatedPrice,
          userNameOther: lanes.shippers ? lanes.shippers.companyName : lanes.carrier.legalName,
          userName: lanes.carrier ? lanes.carrier.legalName : lanes.shippers.companyName,
          bidingStatus: lanes.status,
          city: lanes.shippers.city,
          state: lanes.shippers.state,
          equipmentType: lanes.shippers.equipmentType,
          preferredRegions: lanes.shippers.preferredRegions,
          regions: lanes.shippers.regions,
          contractDocument: lanes.shippers.contractDocument,
          rateType: lanes.rateType,
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          if (result.event === 'Ok') {
            this.skeletonLoader = true;
            this.laneList();
          }
        }
      });
    }

    private getFullPath(route: ActivatedRoute): string {
      const segments = [];
      while (route) {
        if (route.snapshot.url.length) {
          segments.unshift(...route.snapshot.url.map(segment => segment.path));
        }
        route = route.parent;
      }
      
      return segments.join('/');
    }
    

  newBid(shipment) {
    const fullPath = this.getFullPath(this.route);
    this.loadData = null;
    const dialogRef = this.dialog.open(LoadPopupComponent, {
      backdropClass: AppSettings.backdropClass,
      width: AppSettings.popWidth,
      data: {
        openPop: 'bid',
        userNameOther: shipment.shippers.companyName,
        userName: shipment.shippers.companyName,
        shipmentId: shipment.id,
        bidFor: shipment.shippers.userId,
        amount: shipment.freightRate,
        paymentMethod: shipment.paymentMethod,
        contractDocument: shipment.shippers.contractDocument,
        occurence: 'main',
        shipmentDetail: shipment,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'Ok') {
          this.router.navigate([
            fullPath+'/load/view',
            shipment.id,
            'carrierLoadBids',
          ]);
        }
      }
    });
  }

    // Rebid by carrier
    newRedBid(shipment) {
      const fullPath = this.getFullPath(this.route);
      let userName;
      if (
        shipment.bids &&
        shipment.bids.actionBy === localStorage.getItem('user_id')
      ) {
        userName = localStorage.getItem('userName');
      } else {
        userName = shipment.shippers.companyName;
      }
      const dialogRef = this.dialog.open(LoadPopupComponent, {
        backdropClass: AppSettings.backdropClass,
        width: AppSettings.popWidth,
        data: {
          openPop: 'rebid',
          userNameOther: shipment.shippers.companyName,
          userName: userName,
          shipmentId: shipment.id,
          bidFor: shipment.shippers.userId,
          amount: shipment.bids.bidAmount,
          paymentMethod: shipment.paymentMethod,
          occurence: 'main',
          shipmentDetail: shipment,
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          if (result.event === 'Ok') {
            this.router.navigate([
              fullPath+'/load/view',
              shipment.id,
              'carrierLoadBids',
            ]);
          }
        }
      });
    }
  
  // To check/Update certificate of insurance 
  checkCertificate(record:any){
    const dialogRef = this.dialog.open(UserPopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width: "670px",
      data: { openPop: "certificate"},
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.event=='Ok') {
        this.confirmBidCheck(record)
      }
    });
  }
  
    //Confirm bid by carrier
    confirmBid(record: any) {
      if(this.userDetails?.coiExpired){
      this.checkCertificate(record);
      }else{
        this.confirmBidCheck(record);
      }
    }

       // Carrier side accept bid popup
   acceptBidCarrier(record: any) {
    let getUserName;
    if (localStorage.getItem('user_id') === record?.bids?.actionBy) {
      getUserName = record?.carriers?.legalName;
    } else {
      getUserName = record?.shippers?.companyName;
    }
    const dialogRef = this.dialog.open(LoadPopupComponent, {
      backdropClass: AppSettings.backdropClass,
      disableClose: true,
      width: AppSettings.popWidth,
      data: {
        openPop: 'acceptBid',
        userName: getUserName,
        userNameOther: record.shippers.companyName,
        amount: record.bids.bidAmount,
        note: record.title,
        bidId: parseInt(record.bids.id),
        shipmentId: record.id,
        uniqueId: record.uniqueId,
        paymentMethod: record.paymentMethod,
        bidingStatus: record.bids.status,
        occurence: 'main',
        shipmentDetail: record,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'Ok') {
          this.refreshListLoad();
        }
        else if(result.event === 'accept_and_confirm'){
          this.acceptConfirm(record);
        }
      }
    });
  }

   // Carrier side confirm bid popup
   acceptConfirm(record: any){
    if(this.userDetails?.coiExpired){
      const dialogRef = this.dialog.open(UserPopupComponent, {
        disableClose: true,
        backdropClass: AppSettings.backdropClass,
        width: "670px",
        data: { openPop: "certificate"},
      });
      dialogRef.afterClosed().subscribe((result) => {
        if(result.event=='Ok'){
          // this.getHistoryData();
          this.refreshListLoad();
        }
      });
   }else{
    this.acceptConfirmCheck(record)
   }
}


  // To Accept and confirm load by popup
  acceptConfirmCheck(record) {
    let getUserName;
    if (localStorage.getItem('user_id') === record.bids.actionBy) {
      getUserName = record?.carriers?.legalName;
    } else {
      getUserName = record?.shippers?.companyName;
    }
    const dialogRef = this.dialog.open(LoadPopupComponent, {
      backdropClass: AppSettings.backdropClass,
      disableClose: true,
      width: AppSettings.popWidth,
      data: {
        openPop: 'acceptBidConfirm',
        userName: getUserName,
        userNameOther: record.shippers.companyName,
        amount: record.bids.bidAmount,
        note: record.title,
        bidId: parseInt(record.bids.id),
        shipmentId: record.id,
        uniqueId: record.uniqueId,
        paymentMethod: record.paymentMethod,
        bidingStatus: record.bids.status,
        occurence: 'main',
        shipmentDetail: record,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'Ok') {
          this.refreshListLoad();
        }
      }
    });
  }
       // To accept and confirm shipment 
   confirmBidCheck(record: any) {
    let getUserName;
    if (localStorage.getItem('user_id') === record.bids.actionBy) {
      getUserName = record.carriers.legalName;
    } else {
      getUserName = record.shippers.companyName;
    }
    const dialogRef = this.dialog.open(LoadPopupComponent, {
      backdropClass: AppSettings.backdropClass,
      disableClose: true,
      width: AppSettings.popWidth,
      data: {
        openPop: 'acceptBidConfirm',
        userName: getUserName,
        userNameOther: record.shippers.companyName,
        amount: record.bids.bidAmount,
        note: record.title,
        bidId: parseInt(record.bids.id),
        shipmentId: record.id,
        uniqueId: record.uniqueId,
        paymentMethod: record.paymentMethod,
        bidingStatus: record.bids.status,
        occurence: 'main',
        shipmentDetail: record,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'Ok') {
          this.refreshListLoad();
        }
      }
    });
  }

  refreshListLoad() {
    this.skeletonLoader = false;
    this.laneList();
  }

    // To mark as read notification by badge icon
    markReadAlertNotification(value: any) {
      let shipper = localStorage.getItem('user_type') === 'SHIPPER';
      let carrier = localStorage.getItem('user_type') === 'CARRIER';
      this.commonService.putAlertNotificationOnRead(value).subscribe((data) => {
        if (data.response) {
          if (shipper) {
            this.totalBids = data.response.currentBid + data.response.confirmedBids;
          }
          if (carrier) {
            this.totalBidsForC = data.response.currentBidByLoads + data.response.createByLoads + data.response.newLoadsByLoads;
            this.allLanesCounts = data.response.newLoadsByLanes;
          }
          if (carrier && data.response.awardedBidByLane !== 0) {
            this.awardedBidByLanesCount = data.response.awardedBidByLane;
            this.isAwardedBidByLanes = true;
          } else {
            this.isAwardedBidByLanes = false;
          }
          if (carrier && data.response.LostByLane !== 0) {
            this.loadsLostByLaneLanesCount = data.response.LostByLane;
            this.isloadsLostByLaneLanes = true;
          } else {
            this.isAwardedBidByLanes = false;
          }
  
          if (carrier && data.response.awardedBidByLane !== 0) {
            this.awardedBidByLaneCount = data.response.awardedBidByLane;
            this.isawardedBidByLane = true;
          } else {
            this.isawardedBidByLane = false;
          }
          if (carrier && data.response.createByLane!== 0) {
            this.createByLaneCount = data.response.createByLane;
            this.isCreateByLaneCount = true;
          } else {
            this.isCreateByLaneCount = false;
          }
          if (data.response.quotedLoadsByLane!== 0) {
            this.quotedLoadsByLaneCount = data.response.quotedLoadsByLane;
            this.isQuotedLoadsByLane = true;
          } else {
            this.isQuotedLoadsByLane = false;
          }
          if (shipper && data.response.paymentPendingByLane!== 0) {
            this.paymentPendingByLanesCount = data.response.paymentPendingByLane;
            this.isPaymentPendingByLaneCount = true;
          } else {
            this.isPaymentPendingByLaneCount = false;
          }
          if (shipper && data.response.confirmedBidByLoads !== 0) {
            this.confirmedBidByLoadsCount = data.response.confirmedBidByLoads;
            this.isConfirmedBidByLoads = true;
          } else {
            this.isConfirmedBidByLoads = false;
          }
          if (shipper && data.response.expiredByLane !== 0) {
            this.expiredByLaneCount = data.response.expiredByLane;
            this.isExpiredByLane = true;
          } else {
            this.isExpiredByLane = false;
          }
          if (carrier && data.response.cancelByLoads !== 0) {
            this.cancelByLoadsCount = data.response.cancelByLoads;
            this.isCncelByLoads = true;
          } else {
            this.isCncelByLoads = false;
          }
        } else {
        }
      });
    }

    // To get required params for API 
    getAPIParamcount(str) {
      this.radius = null;
      let APIparams, params;
      params = {
        limit: (this.currentStatus == 'expiredLoadsByLane' || this.currentStatus == 'expiredLoads') ? 16 : 12,
        radius: this.radius,
        page: this.page,
        tabType: 'paymentPendingByLane',
        batchId: this.params?.batchId,
        title: this.params?.title,
        pickupDate: this.params?.pickupDate,
        dropDate: this.params?.dropDate,
        equipmentType: this.params?.equipmentType,
        equipmentName: this.equipmentName,
        driverId: this.params?.commonDriverId,
        driverName: this.driverName,
        status: this.params?.status,
        carrierId: this.params?.carrierId,
        statuslabel: this.statusLabelShow,
      };
      APIparams = {
        apiKey: AppSettings.APIsNameArray.SHIPMENT.CREATELOADSLIST,
        uri: this.commonService.getAPIUriFromParams(params),
      };
      return APIparams;
    }

  createLoadList() {
    var APIparams = this.getAPIParamcount(null);
    this.commonService.getList(APIparams).subscribe(
     (ServerRes) => {
       if (ServerRes.success === true) {
         this.totalShipment = ServerRes.response.totalShipment;
         this.createdLaneLoadsCount=(ServerRes.response.tabCount?.allLoadsByLane + ServerRes.response.tabCount?.quotedLoadsByLane);
         this.paymentPendingByLaneCount= ServerRes.response.tabCount?.paymentPendingByLane
         this.loadsAwardedByLaneCount= ServerRes.response.tabCount?.loadsAwardedByLane
         this.loadsLostByLaneCount= ServerRes.response.tabCount?.loadsLostByLane
         this.tabCount = ServerRes.response?.tabCount;
         this.allLoadsByLaneCount = ServerRes.response?.tabCount?.allLoadsByLane;
         this.quotedLoadsByLaneCount = ServerRes.response?.tabCount?.quotedLoadsByLane;
         this.expiredLoadsByLaneCount = ServerRes.response?.tabCount?.expiredLoadsByLane;
         let result = ServerRes.response.shipments;
       } 
     },
     (error) => {
       this.lanesList = [];
       this.totalLanes = 0;
       this.totalPage = 0;
       this.tabCount = 0;
     }
   );
  }

  getAPIParamscount(str) {
    let APIparams, params;
    params = {
      limit: (this.currentStatus == 'expiredLoadsByLane' || this.currentStatus == 'expiredLoads') ? 16 : 12,
      page: this.page,
        state: this.stateName,
        equipmentType: this.params?.equipmentType,
        equipmentName: this.equipmentName,
        title: this.params?.title,
        tabType: 'allLanes',
    };
    APIparams = {
      apiKey: AppSettings.APIsNameArray.SHIPMENT.NEWLANELIST,
      uri: this.commonService.getAPIUriFromParams(params),
    };
    return APIparams;
  }

  createLaneList() {
    var APIparams = this.getAPIParamscount(null);
    this.commonService.getList(APIparams).subscribe(
     (ServerRes) => {
       if (ServerRes.success === true) {
         this.totalLanes = (ServerRes.response?.tabCount?.allLanes + ServerRes.response?.tabCount?.quotedLanes);
         this.totalPage = ServerRes.response.totalPage;
         this.tabCount = ServerRes.response?.tabCount;
         this.confirmedLanesCount = ServerRes.response?.tabCount?.confirmedLanes;
         this.postedLanesCount = ServerRes.response.tabCount?.allLanes;
         this.archivedLanesCount = ServerRes.response.tabCount?.archivedLanes;
         this.availableLanes = ServerRes.response?.tabCount?.availableLanes;
         this.confirmCount = ServerRes.response?.tabCount?.confirmedLanes;
         this.currentquotedLanesCount=ServerRes.response?.tabCount?.quotedLanes;
         this.allLanesCount=ServerRes.response?.tabCount?.allLanes;
         let result = ServerRes.response.lanes;
       } 
     },
     (error) => {
       this.createdLoadList = [];
       this.totalShipment = 0;
       this.totalPage = 0;
       this.tabCount = 0;
     }
   );
  }

}
