<div *ngIf="driverListPoup==='show'">
  <app-assign-driver [shipmentRecord]="data.shipmentRecord" [shipmentDetail]="data.shipmentDetail" (closePopup)="close($event)" (driverConfirmToAssign)="confirmToAssign($event)"></app-assign-driver>
</div>

<!-- Assign driver confirm -->
<div *ngIf="showConfirm==='assignShipmentConfirm'">
  <div class="text-center" style="margin: 0; padding: 1rem;">
    <p class="fs_20 fsm_5 txt_b " style="margin: 0 0 2rem;">Assigning Driver</p>
    <p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;"> 
      <span> Assign </span> 
      <span class="fsm_5 txt_b" *ngIf="driverInfo?.legalName"> {{driverInfo?.legalName}}</span> 
      <span class="fsm_5 txt_b" *ngIf="driverInfo?.name">{{driverInfo?.name}}</span>
      <span> to this shipment?</span> 
    </p>
    <div class="text-center">
      <button *ngIf="!loaderSpinner"  type="button" class="btn custum_button button_success w-100 fs_16 fsm_5" (click)="driverShipmentAssign()" style="margin:0 0 12px;">Assign Driver</button>
      <div class="con_status" *ngIf="loaderSpinner">
        <div class="spinner-border txt_p" role="status">
          <span class="sr-only"></span>
        </div>
      </div>

      <span class="custum_button fsm_5 txt_lb d-block w-100 " (click)="backToDriverList()">Back</span>
    </div>
  </div>
</div>

<!-- confirm section -->
<div *ngIf="showConfirm==='shipmentConfirm'">
  <div class="text-center" style="margin: 0; padding: 1rem;" class="asign_dirver_popup">
    <p class="fs_20 fsm_5 txt_b ">Assigning Driver</p>
    <p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;"> 
      <span> Are you sure you want to assign this load to  </span> 
      <span class="fsm_5 txt_b" *ngIf="driverInfo?.legalName"> {{driverInfo?.legalName}}?</span> 
      <span class="fsm_5 txt_b" *ngIf="driverInfo?.name"> {{driverInfo?.name}}?</span> 
    </p>
    <div class="text-center">
      <button *ngIf="!loaderSpinner"  type="button" class="btn custum_button button_success w-100 fs_16 fsn_4" (click)="driverShipmentAssign()" style="margin:0 0 12px;">Yes, Assign</button>
      <div class="con_status" *ngIf="loaderSpinner">
         <div class="spinner-border txt_p" role="status">
              <span class="sr-only"><!-- Loading... --></span>
          </div>
      </div>
      <span class="custum_button fsm_5 txt_lb d-block w-100 " (click)="backToDriverList()">Back</span>
    </div>
  </div>
</div>

<!-- Success popu -->
<div *ngIf="data.openPop === 'shipmentSuccess'">
  <div class="text-center" style="margin: 0; padding: 1rem;">
    <span class="material-icons" style="margin: 0 0 1.5rem;font-size: 48px;color: var(--clGrna);">check_circle</span>
    <p class="fs_20 fsm_5 txt_b " style="margin: 0 0 2rem;">Congratulations</p>
    <p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">
      <span> Load with ID </span>
      <span *ngIf="data.shipmentRecord?.uniqueId" class="fsm_5 txt_b"> #{{data.shipmentRecord?.uniqueId}} </span>
      <span *ngIf="data.shipmentUniqueId" class="txt_b fsm_5"> #{{data.shipmentUniqueId}} </span>
      <span> has been successfully created</span>
      <span *ngIf="data.driverName"> and assigned to</span> 
      <span class="fsm_5 txt_b" *ngIf="data.driverName"> {{data.driverName}}</span>
      <span class="fsm_5 txt_b" *ngIf="driverInfo?.legalName"> {{driverInfo?.legalName}}</span>
      <span class="fsm_5 txt_b" *ngIf="driverInfo?.name"> {{driverInfo.name}}</span>.
    </p>
    <div class="text-center">
      <button *ngIf="data.shipmentRecord?.id" type="button" class="btn custum_button button_success w-100 fs_16 fsm_5" [routerLink]="['/load-created-by-me/pending']"  (click)="close($event)" style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;">OK, View Details</button>
      <button *ngIf="data.shipmentId" type="button" class="btn custum_button button_success w-100 fs_16 fsm_5"  [routerLink]="['/load-created-by-me/pending']"  (click)="close($event)" style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;">OK, View Details</button>
      <span class="custum_button fsm_5 txt_p d-block w-100 "   (click)="successShipmentCreate()">Create another</span>
    </div>
  </div>
</div>

<div *ngIf="showSuccess === 'assignDriver' || showSuccess === 'changeDriver'">
  <div class="text-center" style="margin: 0; padding: 1rem;">
     <span class="material-icons" style="margin: 0 0 1.5rem;font-size: 48px;color: var(--clGrna);">check_circle</span>
    <p class="fs_20 fsm_5 txt_b " style="margin: 0 0 2rem;">Congratulations</p>
    <p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">
      <span> Driver have been successfully assigned.</span>
    </p>
    <div class="text-center">     
      <button  type="button" class="btn custum_button button_success w-100 fs_16 fsn_4" style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;" (click)="successShipment()">OK</button>
      <span *ngIf="data.shipmentDetail?.id" class="custum_button fsm_5 txt_p d-block w-100 " [routerLink]="['/load/view/',data.shipmentDetail?.id,'details']" (click)="close($event)">View Details</span>
      <span *ngIf="data.shipmentId" class="custum_button fsm_5 txt_p d-block w-100 " [routerLink]="['/load/view/',data.shipmentId,'details']"  (click)="close($event)">View Details</span>
    </div>
  </div>
</div>

