import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { take, mergeMap } from 'rxjs/operators';
import { BehaviorSubject, Subject, of } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable } from 'rxjs';
import { AppSettings } from '../setting/app_setting';
import { CommonService } from './common.service';
import { AlertService } from './alert.service';
import { SharedService } from './shared.service';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { LoadPopupComponent } from 'src/app/loads-shared/load-popup/load-popup.component';
declare var require: any;

@Injectable()
export class MessagingService {
  public addDeviceValue: any = {};
  public notification: any = {};
  public gePayload: any = {};
  public userType: any;
  public shipmentId: any;
  currentMessage = new BehaviorSubject(null);
  public customSubject = new Subject<any>();
  public response: Observable<Array<string>>;

  constructor(
    private angularFireDB: AngularFireDatabase,
    private angularFireAuth: AngularFireAuth,
    private angularFireMessaging: AngularFireMessaging,
    private commonService: CommonService,
    private alertService: AlertService,
    private sharedService: SharedService,
    private deviceService: DeviceDetectorService,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
  ) {
    this.angularFireMessaging.messages.subscribe((_messaging: any) => {
      _messaging.onMessage = _messaging.onMessage.bind(_messaging);
      _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(_messaging);
    });
    this.angularFireMessaging.messages.subscribe((payload) => {
     
      if (payload) {
        this.gePayload = payload;
        let fcmMessageId = payload['fcmMessageId'];
        if (
          payload &&
          this.gePayload['data'].notification_type === 'Messages') {
          if (
            localStorage.getItem('currentRouteUrl') !==
            '/massages/driver/All' &&
            localStorage.getItem('currentRouteUrl') !==
            '/massages/past_load/All' &&
            localStorage.getItem('currentRouteUrl') !==
            '/massages/active_load/All' &&
            localStorage.getItem('currentRouteUrl') !==
            '/massages/shipper/All' &&
            localStorage.getItem('currentRouteUrl') !== '/massages/carrier/All'
          ) {
            this.alertService.showPushNotification(
              'success',
              'bottom',
              'right',
              this.gePayload['notification'].title,
              this.gePayload['notification'].body,
              this.gePayload['data'].url
            );
          }
        }
        if (
          this.gePayload['data'].isNotiPush === '1' &&
          this.gePayload['data'].isNotiBell === '1'
        ) {
          this.alertService.showPushNotification(
            'success',
            'bottom',
            'right',
            this.gePayload['notification'].title,
            this.gePayload['notification'].body,
            this.gePayload['data'].url
          );
          this.commonService.bellNotificationUpdate.next(null);
        } else if (
          this.gePayload['data'].isNotiPush === '0' &&
          this.gePayload['data'].isNotiBell === '1'
        ) {
          this.commonService.bellNotificationUpdate.next(null);
        } else if (
          this.gePayload['data'].isNotiPush === '1' &&
          this.gePayload['data'].isNotiBell === '0'
        ) {
          this.alertService.showPushNotification(
            'success',
            'bottom',
            'right',
            this.gePayload['notification'].title,
            this.gePayload['notification'].body,
            this.gePayload['data'].url
          );
        } else if (
          this.gePayload['data'].isNotiPush === '0' &&
          this.gePayload['data'].isNotiBell === '0'
        ) {
          if (
            this.gePayload['data'].notification_type === 'ShipperPaymentACH'
          ) {
            this.sharedService.ACHService.next(null);
            this.sharedService.connectAccountService.next(null);
            this.alertService.showPushNotification(
              'success',
              'bottom',
              'right',
              this.gePayload['notification'].title,
              this.gePayload['notification'].body,
              this.gePayload['data'].url
            );
          } else if (
            this.gePayload['data'].notification_type === 'PaymentAccount'
          ) {
            this.sharedService.connectAccountService.next(null);
            this.alertService.showPushNotification(
              'success',
              'bottom',
              'right',
              this.gePayload['notification'].title,
              this.gePayload['notification'].body,
              this.gePayload['data'].url
            );
          } else {
            this.alertService.showPushNotification(
              'success',
              'bottom',
              'right',
              this.gePayload['notification'].title,
              this.gePayload['notification'].body,
              this.gePayload['data'].url
            );
          }
        } else {
          console.log('comsoerier');
        }

        this.userType = localStorage.getItem('user_type');
        if ((this.gePayload['data'].notification_type === 'bidConfirmed' || this.gePayload['data'].notification_type === 'acceptOfferPrice') && this.userType === 'SHIPPER') {
          this.confettiFun();
        }
        if ((this.gePayload['data'].notification_type === 'carrierPaymentSuccess' || this.gePayload['data'].notification_type === 'shipperPaymentSuccess')
          && this.userType === 'CARRIER') {
          this.confettiFun();
        }
      }
    });

    this.sharedService.changeFirebaseToken.subscribe((userData) => { });
  }

  confettiFun() {
    let shipmenId = this.route.snapshot.params['loadId'];
    const dialogRef = this.dialog.open(LoadPopupComponent, {
      backdropClass: AppSettings.backdropClass,
      width: '100vw',
      height: '100vh',
      maxWidth: '100vw',
      data: { openPop: 'ConfettiPopupShow' },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'Ok') {
        }
      }
    });
  }

  deleteOldToken() {
    this.angularFireMessaging.getToken
      .pipe(mergeMap((token) => this.angularFireMessaging.deleteToken(token)))
      .subscribe(
        (token) => {
        },
        (err) => {
        }
      );
  }
  /**
   * update token in firebase database
   *
   * @param userId
   * @param token
   */
  updateToken(userId, token) {
    // we can change this function to request our backend service
    this.angularFireAuth.authState.pipe(take(1)).subscribe(() => {
      const data = {};
      data[userId] = token;
      this.angularFireDB.object('fcmTokens/').update(data);
    });
  }

  /**
   * request permission for notification from firebase cloud messaging
   *
   * @param userId userId
   */
  requestPermission() {
    localStorage.setItem('notificationError', 'false');
    const userId = localStorage.getItem('user_id');
    sessionStorage.setItem('userId', userId);
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        
        if (Notification.permission == 'denied') {
          if (!localStorage.getItem('lastfcmToken')) {
            if (localStorage.getItem('fcmToken')) {
              localStorage.setItem('lastfcmToken', localStorage.getItem('fcmToken'));
            }
          }
          localStorage.setItem('fcmToken', token);
          sessionStorage.setItem('fcmToken', token);
        }
        else if (Notification.permission == 'granted') {
          if (userId) {
            localStorage.setItem('fcmToken', token);
            sessionStorage.setItem('fcmToken', token);
            this.addFcmToken(token);
          }
        }
      },
      (err) => {
        if (err) {
          localStorage.setItem('notificationError', 'true');
        }
      }
    );
  }
  receiveMessage() {
    let notification = {};
  }

  addFcmToken(token) {
    let data = require('../../../../package.json');
    const getInfo = this.deviceService.getDeviceInfo();
    if (token) {
      let uuid = token.substring(10, 50);
      localStorage.setItem('uniqueId', uuid);
      this.addDeviceValue.token = token;
      this.addDeviceValue.deviceId = uuid;
      this.addDeviceValue.deviceType = '3';
      this.addDeviceValue.osVersion = getInfo.os_version;
      this.addDeviceValue.appVersion = data.version;
      this.addDeviceValue.userId = localStorage.getItem('user_id');
      let APIparams = {
        apiKey: AppSettings.APIsNameArray.NOTIFICATION.ADDTOKEN,
        uri: '',
        postBody: this.addDeviceValue,
      };
      this.commonService.post(APIparams).subscribe((success) => {
        localStorage.setItem('addedApiCalled', 'granted');
      });
    }
  }
}
