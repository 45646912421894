<div id="shipment_card_Q">
    <div class="shipment_card">
      <div class="shipment_card_T pointer">
        <div class="sc_head">
          <div class="sc_head_l" (click)="eventAction(shipmentRecord,'side_panel')">
                <span class="ship_id">#{{ shipmentRecord?.title ? shipmentRecord?.title : "-"}}</span>
          </div>
          <div class="live_shipment" data-toggle="dropdown">
            <div class="dropdown_menu_list">
              <i class="fa-solid fa-ellipsis"></i>
              <div class="dropdown-menu ">
                <span class="dropdown-item pointer" (click)="openNewTabRecord(shipmentRecord)">Open in new tab</span>
                <span class="dropdown-item pointer" [class.disabled]=""
                  (click)="getWebTrackingLink()">Web Tracking Link </span>
                <span class="dropdown-item pointer" (click)="redirectToMessages('All')"
                  *ngIf="this.currentStatus === 'pastByLoads' && (this.currentStatus !== 'inProgressByLoads' || this.currentStatus !== 'upcomingByLoads' || this.currentStatus !== 'pendingByLoads')"
                  [routerLink]="['/messages/past_load/All']"
                  [queryParams]="{activeChat: 'true', id : shipmentRecord?.id, title : shipmentRecord?.title}"
                  [class.disabled]="!checkMessageShipmentCondition()">Message Load</span>
    
                <span class="dropdown-item pointer" (click)="redirectToMessages('Active')"
                  *ngIf="this.currentStatus !== 'pastByLoads' && ( this.currentStatus === 'inProgressByLoads' || this.currentStatus === 'upcomingByLoads' || this.currentStatus === 'pendingByLoads')"
                  [routerLink]="['/messages/active_load/All']"
                  [queryParams]="{activeChat: 'true', id : shipmentRecord?.id, title : shipmentRecord?.title}"
                  [class.disabled]="!checkMessageShipmentCondition()">Message Load</span>
    
                <span class="dropdown-item pointer" (click)="redirectToEdit()"
                  [class.disabled]="!checkEditShipmentCondition()">Edit Load</span>
                  
                <span class="dropdown-item pointer" [class.disabled]="!checkCancelShipmentCondition()"
                  (click)="newShipmentCancel(shipmentRecord?.id ,shipmentRecord?.uniqueId)">Cancel
                  Load</span>
                <span [class.disabled]="!checkFileDisputeShipmentCondition()" class="dropdown-item pointer"
                  (click)="disputeShipment($event,shipmentRecord?.id,shipmentRecord.uniqueId ,shipmentRecord?.title)">File
                  A Dispute</span>
              </div>
            </div>
          </div>
        </div>
  
        <div class="blue_keypoints">
          <span class="keypoints">{{shipmentRecord?.equipmentName}}</span>
          <span class="keypoints mes-2">{{shipmentRecord?.miles + ' Miles'}}</span>
          <span class="ship_status" *ngIf="shipmentRecord">
            <span class="status_label txt_w" *ngIf="currentStatus === 'upcoming' || currentStatus === 'inProgress'"
              [ngStyle]="{'background': shipmentRecord?.tripStatus?.Color }">{{shipmentRecord?.tripStatus?.Text}}
              <div *ngIf="!shipmentRecord?.tripStatus?.Text">
                <img src="{{serverPathForUrl}}/portal/images/after-login/LaneAxis-loader.gif" style="max-height: 16px;">
              </div>
            </span>
            <span class="edit_ship_icon " *ngIf="currentStatus === 'past' || currentStatus === 'expired'">
              <span class="ship_status" *ngIf="shipmentRecord">
                <span class="status_label bg_y txt_w" *ngIf="shipmentRecord?.status === 60">Payment On Hold</span>
                <span class="status_label bg_s txt_w" *ngIf="shipmentRecord?.status === 54">Completed</span>
                <span class="status_label txt_w" *ngIf="shipmentRecord?.status === 15"
                  style="background: var(--clReda);">Canceled</span>
                <span class="status_label txt_w" *ngIf="shipmentRecord?.status === 59"
                  style="background:#A9A9A9;">Expired</span>
                <span *ngIf="shipmentRecord?.status === 51 && shipmentRecord.checkTimeDispute"
                  class="status_label txt_p pointer" style="background: #b9d0e8;"
                  matTooltip="File a Dispute">Delivered</span>
                <span *ngIf="shipmentRecord?.status === 51 && !shipmentRecord.checkTimeDispute" class="status_label txt_p"
                  style="background: #b9d0e8;" matTooltip="">Delivered</span>
              </span>
            </span>
          </span>
        </div>
  
      
        <div class="sc_locations" (click)="eventAction(shipmentRecord,'side_panel')">
          <div class="sc_loca_picup">
            <span class="icon-ic-pickupq loca_iconFlag">
              <img src="\assets\icon\new-flag-card.svg" class="img-fluid picup_blue">
            </span>
            <div class="p_loca_time">
              <p class="loca_picup text_truncate"> {{shipmentRecord?.waypoints[0]?.city +", "+
                shipmentRecord?.waypoints[0]?.state}}</p>
              <p class="loca_picup_time" *ngIf="shipmentRecord?.waypoints[0]?.date">
                {{shipmentRecord?.waypoints[0]?.date | date: 'MMM dd, yyyy'}} at
                {{shipmentRecord?.waypoints[0]?.timeSlot ? shipmentRecord?.waypoints[0]?.timeSlot : "-"}}
                {{shipmentRecord?.waypoints[0]?.timeZone ? shipmentRecord?.waypoints[0]?.timeZone : "-"}}</p>
            </div>
          </div>
          <div class="sc_loca_drop">
            <span><img src="\assets\icon\new-location-icon.svg" class="loca_icon"></span>
            <div class="d_loca_time">
              <p class="loca_drop text_truncate">{{shipmentRecord?.waypoints[shipmentRecord?.waypoints?.length - 1]?.city +", "+
                shipmentRecord?.waypoints[shipmentRecord?.waypoints?.length - 1]?.state}}</p>
              <p class="loca_drop_time"> {{shipmentRecord?.waypoints[shipmentRecord?.waypoints?.length - 1]?.date | date: 'MMM dd, yyyy'}} at
                {{shipmentRecord?.waypoints[shipmentRecord?.waypoints?.length - 1]?.timeSlot ? shipmentRecord?.waypoints[shipmentRecord?.waypoints?.length - 1]?.timeSlot : "-"}}
                {{shipmentRecord?.waypoints[shipmentRecord?.waypoints?.length - 1]?.timeZone ? shipmentRecord?.waypoints[shipmentRecord?.waypoints?.length - 1]?.timeZone : "-"}}</p>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="currentStatus === 'inProgress' && waypointIntransitStatus">
        <div class="shipment_card_B border_t_w" *ngFor="let item of waypointIntransitStatus; let i = index;">
          <p class="loca_drop text_truncate">Navigating to {{item?.type ? (item?.type | titlecase) : '-'}} Point
            {{item?.locationOrder}}</p>
            <div class="d-flex align-items-center"><img src="\assets\icon\new-location-icon.svg" class="loca_icon grey_scale">
              <span class="loca_drop_time"> {{item?.city}} {{item?.state}} </span></div>
        </div>
      </div>
      <div *ngIf="currentStatus === 'inProgress' && waypointCompletedStatus && !intransitStatus">
        <div class="shipment_card_B border_t_w">
          <p class="loca_drop text_truncate">Reached at {{waypointCompletedStatus?.type ? (waypointCompletedStatus?.type |
            titlecase) : '-'}} Point {{waypointCompletedStatus?.locationOrder}}</p>
            <div class="d-flex align-items-center"><img src="\assets\icon\new-location-icon.svg" class="loca_icon grey_scale">
              <span class="loca_drop_time"> {{waypointCompletedStatus?.city}} {{waypointCompletedStatus?.state}}
              </span></div>
        </div>
      </div>
      <div class="shipment_card_B border_t_w"
        [ngClass]="[userType === 'SHIPPER' ? 'bottomSecion' : '', (currentStatus === 'past'|| currentStatus === 'pending' || currentStatus === 'upcoming' || currentStatus === 'inProgress' || currentStatus==='expired' ) ? 'shipment_card_B_min' : '']">
        <div
          *ngIf="currentStatus==='inProgress' || currentStatus === 'upcoming' || currentStatus === 'past' || currentStatus==='pending' || currentStatus==='expired'">
          <div class="user_detail" *ngIf="shipmentRecord?.drivers && userType==='CARRIER'">
            <div class="detail_img" [ngClass]="shipmentRecord?.drivers?.userId != this.userId ? 'pointer' : ''"
              (click)="eventActionProfileSidePanel(shipmentRecord,'profile_side_panel')">
              <div *ngIf="shipmentRecord?.drivers?.profileImage" class="s_user_img">
                <img src="{{shipmentRecord?.drivers?.profileImage}}" class="img-fluid">
              </div>
              <div *ngIf="!shipmentRecord?.drivers?.profileImage" class="s_user_img">
                <span class="initial_name initial_r_38"
                  *ngIf="shipmentRecord?.drivers?.name">{{shipmentRecord?.drivers?.name | acronymOfName}}</span>
              </div>
              <div class="u_detail">
                <p class="u_detail_value text_truncate" *ngIf="userId !== shipmentRecord?.drivers?.userId">
                  {{shipmentRecord?.drivers?.name ? shipmentRecord?.drivers?.name : '-'}}</p>
                <p *ngIf="userId === shipmentRecord?.drivers?.userId" class="u_detail_value text_truncate">You</p>
                <p class="u_detail_lable">
                  <span>Driver</span>
                  <span *ngIf="currentStatus === 'pending'" class="txt_d list_dot_only">Not accepted yet</span>
                </p>
              </div>
            </div>
            <div class="contact_icons" *ngIf="userType === 'CARRIER'">
              <span *ngIf="userId !== shipmentRecord?.drivers?.userId" class="icon_bg mess_icon m-0"
                [routerLink]="['/messages/driver/All']"
                [queryParams]="{activeChat: 'true', id : shipmentRecord?.drivers?.userId, title : shipmentRecord?.drivers?.name}">
                <img src="\assets\icon\message-icon.svg" class="img-fluid">
              </span>
              <span *ngIf="shipmentRecord?.drivers?.phone" class="phone_icon"
                matTooltip="{{shipmentRecord?.drivers?.phone | phone : shipmentRecord?.countryCode}}"><img
                  src="\assets\icon\phone_dialer_icon.svg"></span>
            </div>
          </div>
          <div *ngIf="!shipmentRecord?.drivers && userType === 'CARRIER' && currentStatus ==='pending'">
            <div class="detail_img">
              <div class="assign_driver pointer" (click)="shipmentAssignDriver(shipmentRecord)">
                <span class="driver_name">Assign Driver</span>
              </div>
            </div>
          </div>
          <div class="emt_align" *ngIf="!shipmentRecord?.drivers && userType === 'CARRIER' && currentStatus ==='past'"
            style="height: 29px;">
            <p class="u_detail_value fsm_5 txt_lb mx_w_100">No Drivers Were Assigned</p>
          </div>
  
          <div *ngIf="userType === 'SHIPPER'">
            <div *ngIf="shipmentRecord?.carriers" class="d-flex align-items-center justify-content-between">
              <div class="detail_img">
                <div *ngIf="shipmentRecord?.carriers?.profileImage" class="s_user_img">
                  <img src="{{shipmentRecord?.carriers?.profileImage}}" class="img-fluid">
                </div>
                <div *ngIf="!shipmentRecord?.carriers?.profileImage" class="s_user_img">
                  <span class="initial_name initial_r_38">{{shipmentRecord?.carriers?.legalName | acronymOfName}}</span>
                </div>
                <div class="u_detail" (click)="eventAction(shipmentRecord,'profile_side_panel')">
                  <div style="display: flex; text-align: center;">
                    <p class="u_detail_value pointer"
                      [ngClass]="shipmentRecord?.carriers?.legalName.length >10 ? 'text_truncate' : ''"
                      matTooltip="{{shipmentRecord?.carriers?.legalName}}">{{shipmentRecord?.carriers?.legalName ?
                      shipmentRecord?.carriers?.legalName : '-'}}</p>
                    <span *ngIf='shipmentRecord?.carriers?.subscription'><img
                        src="{{serverPathForUrl}}/portal/images/after-login/premium_plan_icon.png"
                        class="img-fluid verified_icon"></span>
                  </div>
                  <p class="u_detail_lable">
                    <span>Carrier</span>
                  </p>
                </div>
              </div>
              <div class="car_ship_ratting">
                <div class="ship_card_rating"
                  *ngIf="currentStatus !=='newDispute' && currentStatus !=='ongoingDispute' && currentStatus !=='resolvedDispute'">
                  <app-rating-view [rate]="shipmentRecord?.carriers?.ratingReview?.rate"
                    [ratingReview]="shipmentRecord?.carriers?.ratingReview" [ratingOwnerDetail]="ratingOwnerDetail"
                    [shipmentDetail]="shipmentRecord"
                    *ngIf="shipmentRecord?.carriers?.ratingReview?.rate && userType ==='SHIPPER'"
                    id="shipment_card_ratting"></app-rating-view>
                </div>
                <div class="ship_card_rating"
                  *ngIf="currentStatus !=='newDispute' && currentStatus !=='ongoingDispute' && currentStatus !=='resolvedDispute'">
                  <app-rating-view [rate]="shipmentRecord?.drivers?.ratingReview?.rate"
                    [ratingReview]="shipmentRecord?.drivers?.ratingReview" [ratingOwnerDetail]="ratingOwnerDetail"
                    [shipmentDetail]="shipmentRecord"
                    *ngIf="shipmentRecord?.drivers?.ratingReview?.rate && userType ==='CARRIER'"
                    id="shipment_card_ratting"></app-rating-view>
                </div>
              </div>
              <div class="contact_icons" *ngIf="userType === 'CARRIER'">
                <span class="icon-ic-envelope mess_icon"
                  [routerLink]="['/messages/driver',shipmentRecord?.drivers?.userId]"></span>
                <span *ngIf="shipmentRecord?.drivers?.phone" class="icon-ic-phone phone_icon"
                  matTooltip="{{+shipmentRecord?.drivers?.countryCode + ' ' + shipmentRecord?.drivers?.phone | phone }}"><i class="fa-solid fa-phone"></i></span>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</div>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px', fullScreenBackdrop: true }"></ngx-loading>
