// import { MapsAPILoader } from '@agm/core';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../../environments/environment';
import { CommonService } from '../../commons/service/common.service';
import { SharedService } from '../../commons/service/shared.service';
import { AppSettings } from '../../commons/setting/app_setting';
import { CarrierSidePopupComponent } from '../../shared/carrier-side-popup/carrier-side-popup.component';

@Component({
  selector: 'app-driver-detail',
  templateUrl: './driver-detail.component.html',
  styleUrls: ['./driver-detail.component.scss']
})
export class DriverDetailComponent implements OnInit {
  @Input() skeletonLoader: any;
  @Input() loadDetail: any;
  @Input() waypointData: any;
  @Input() userType: any;
  @Input() ratingReview: any;
  @Input() gifLoader: any;
  @Input() statusInformation: any;
  @Output() eventPassAction = new EventEmitter();
  @Output() selectedTab = new EventEmitter();
  public serverPathForUrl: any;
  public userId: any;
  public countryList:any;
  public countryFlag:any;
  public sidePanelProfileDataId:any;
  public sidePanelProfileData:any
  public currentlocation: any;
  public currentLon: any;
  public ratingOwnerDetail: {};
  public currentLat: any;
  public geoCoder: any;
  public userName:any;

  constructor(
    public sharedService: SharedService,
    public commonService: CommonService,
    public dialog: MatDialog,
    // private mapsAPILoader: MapsAPILoader
  ) { }

  ngOnInit() {
    this.userType = localStorage.getItem('user_type');
    this.userName = localStorage.getItem('userName');
    if (this.userType === 'CARRIER' && this.loadDetail?.carrierId !=this.loadDetail?.driverId) {
      this.ratingOwnerDetail = {
        key: 'driverId',
        id: this.loadDetail?.drivers?.userId,
        ratingPlace: 'driverDetail'
      }
    }
    else if (this.userType === 'CARRIER' && this.loadDetail?.carrierId ==this.loadDetail?.driverId) {
      this.ratingOwnerDetail = {
        key: 'carrierId',
        id: this.loadDetail?.carriers?.userId,
        ratingPlace: 'driverDetail'
      }
    }
    if (this.userType === 'SHIPPER' && this.loadDetail?.carrierId !=this.loadDetail?.driverId) {
      this.ratingOwnerDetail = {
        key: 'driverId',
        id: this.loadDetail?.drivers?.userId,
        ratingPlace: 'driverDetail'
      }
    }
    else if (this.userType === 'SHIPPER' && this.loadDetail?.carrierId ==this.loadDetail?.driverId) {
      this.ratingOwnerDetail = {
        key: 'carrierId',
        id: this.loadDetail?.carriers?.userId,
        ratingPlace: 'driverDetail'
      }
    }
    else if(!this.loadDetail?.driverId){
      this.ratingOwnerDetail = {
        key: 'carrierId',
        id: this.loadDetail?.carriers?.userId,
        ratingPlace: 'driverDetail'
      }
    }

    this.serverPathForUrl = environment.serverPathForUrl;
    this.userId = localStorage.getItem('user_id');
    // this.mapsAPILoader.load().then(() => {
    //   this.geoCoder = new google.maps.Geocoder();
    // });
    let configValue = this.sharedService.getConfig();
    if (configValue !== undefined) {
      this.getConfigSet(configValue);
      }
  }

  ngAfterViewInit() {
    if (this.loadDetail && this.loadDetail.drivers) {
      this.selecteCountryFlag(this.loadDetail?.drivers?.countryCode);
      if (this.loadDetail.drivers.latitude && this.loadDetail.drivers.longitude) {
        this.currentLat = this.loadDetail.drivers.latitude;
        this.currentLon = this.loadDetail.drivers.longitude;
        this.driverCurrentLocation();
      }
    }
    this.getMilesCompleted(this.loadDetail);
  }

  getConfigSet(configValue: any) {
    this.countryList = configValue.countries;
  }

 // Funtion to get completed miles by status 
  getMilesCompleted(shipment) {
    if (
      shipment.status === 51 ||
      shipment.status === 53 ||
      shipment.status === 54 ||
      shipment.status === 55 ||
      shipment.status === 58
    ) {
      this.loadDetail.tripStatus = {
        milesCompleted: shipment.miles,
      };
    }
  }

  async driverCurrentLocation() {
    this.currentlocation = await this.showPosition(          
      this.loadDetail.drivers.latitude,
      this.loadDetail.drivers.longitude
    );
  }

  // showPosition(locationLat: any, locationLong: any, index) {
  //   let addressDriver = [];
  //   let that = this;
  //   this.geocodingService.getAddressFromLatLng(locationLat, locationLong).subscribe(
  //     (results) => {
  //       let newname = '';
  //             if (results[0].address_components[3] !== undefined) {
  //               newname = results[0].address_components[3].long_name;
  //             } else {
  //               newname = '';
  //             }
  //             addressDriver['shortAddress'] =
  //               results[0].address_components[2].long_name + ', ' + newname;
  //             addressDriver['fullAddress'] = results[0].formatted_address;
  //     },
  //     (error) => {
  //     }
  //   );
  // }

  showPosition(lat, lng) {
    let addressDriver = [];
    const apiKey = 'AIzaSyAMMBwZYg03hSwJEnODnIWf14YJKjWz_2A';
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;
    let that = this;
    if(!lat || !lng)
      {
        return
      }
    return new Promise((resolve, reject) => {
    fetch(url)
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(response => {
      let results = response?.results
      let newname = '';
              if (results[0].address_components[3] !== undefined) {
                newname = results[0].address_components[3].long_name;
              } else if (results[1]?.address_components[3]?.long_name !== undefined) {
                newname = results[1].address_components[3].long_name;
              }else {
                newname = '';
              }
              addressDriver['shortAddress'] =
                (results[0].address_components[2].long_name ? results[0].address_components[2].long_name : results[1].address_components[2].long_name) + ', ' + newname;
              addressDriver['fullAddress'] = (results[0].formatted_address ? results[0].formatted_address : results[1].formatted_address);
              resolve(addressDriver);
    })
    .catch(error => {
      console.error('Error fetching address:', error);
      resolve(null);
    })
    });
  }


  
  checkEquipmentType(equipment: any) {
    if (equipment && equipment.length > 0 && Array.isArray(equipment)) {
      return equipment.toString()
    }
    else if (equipment && equipment.length > 0) {
      return equipment
    }
    else {
      return '-'
    }
  }

  shipmentAssignDriver(loadDetail: any) {
    const dialogRef = this.dialog.open(CarrierSidePopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width: '530px',
      data: {
        openPop: 'crtAssignDriver',
        shipmentRecord: this.waypointData,
        shipmentDetail: loadDetail,
        typeSection: 'changeDriver',
        occurence: 'shipment_view_page',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.event === 'success') {
        this.selectedTab.emit();
      }
    });
  }

  checkAssignDriver(status:any){
    if(status===15 || status === 51 || !this.loadDetail?.carriers){
      return true;
    }else{
      return false;
    }
  }

  selecteCountryFlag(event: any) {
    const getRecord = this.countryList?.filter((item) => item.countryAbbr == event);
    this.countryFlag =  getRecord[0]?.flag;
   }

  eventActionProfileSidePanel(event: any, type: any) {
    if (event.drivers?.legalName !== this.userName) {
      this.sidePanelProfileDataId = event?.driverId;
      this.sidePanelProfileData=event;
      this.eventPassAction.emit({ event, type });
    }
  }
  
  closePanel(event){
    this.sidePanelProfileDataId='';
  }

}
