<!--Update user information-->
<div *ngIf="data.openPop ==='carrierContactInfo'">
  <div class="header_popup">
    <p class="fs_20 fsb_6 txt_b line_before ">Contact Info</p>
    <span class="close_popup_Icon" (click)="closePopup()">
      <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
    </span>
  </div>
  <form autocomplete="off" [formGroup]="carrierContactInfo" (ngSubmit)="carrerInfoSave(carrierContactInfo)"
    class="mb-0 custum_input_css inputInPopup" autocomplete="off">
    <div class="v_scrollBar popup_vh" style="margin: 0 -24px; padding: 0 24px;">
      <div class="row">
        <div [ngClass]="userType==='CARRIER' ? 'col-md-6' : 'col-md-12'">
          <p class="fs_14 fsn_4 txt_lb mb-1">Company Name <span class="txt_d"> *</span></p>
          <mat-form-field appearance="none">
            <input matInput placeholder="Enter Company Name" class="form-control custom_input"
              formControlName="companyName" maxlength="64">
            <mat-error *ngIf="submitted && carrierContactInfo.get('companyName').errors?.['required']"
              class="error_mess">Company name is required.</mat-error>
            <mat-error *ngIf="carrierContactInfo.get('companyName').errors?.['pattern']" class="error_mess">Please enter
              valid information.</mat-error>
            <mat-error *ngIf="carrierContactInfo.get('companyName').errors?.['maxlength']" class="error_mess">Only 64
              characters are allowed.</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6" *ngIf="userType==='CARRIER'">
          <p class="fs_14 fsn_4 txt_lb mb-1">DOT Number<span class="txt_d"> *</span></p>
          <mat-form-field appearance="none">
            <input matInput placeholder="Enter Dot Number" class="form-control custom_input" formControlName="dotNumber"
              readonly="readonly">
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <p class="fs_14 fsn_4 txt_lb mb-1">Contact Person First Name <span class="txt_d"> *</span></p>
          <mat-form-field appearance="none">
            <input matInput type="text" placeholder="Enter Contact Person First Name" maxlength="65"
              formControlName="firstName" class="form-control custom_input" />
            <mat-error *ngIf="submitted && carrierContactInfo.get('firstName').errors?.['required']" class="error_mess">
              Contact person first name is required.</mat-error>
            <mat-error *ngIf="carrierContactInfo.get('firstName').errors?.['maxlength']" class="error_mess">Only 64
              characters are allowed.</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <p class="fs_14 fsn_4 txt_lb mb-1">Contact Person Last Name<span class="txt_d"> *</span></p>
          <mat-form-field appearance="none">
            <input matInput formControlName="lastName" placeholder="Enter Contact Person Last Name" maxlength="65"
              class="form-control custom_input">
            <mat-error *ngIf="submitted && carrierContactInfo.get('lastName').errors?.['required']" class="error_mess">
              Contact person last name is required.</mat-error>

            <mat-error *ngIf="carrierContactInfo.get('lastName').errors?.['maxlength']" class="error_mess">Only 64
              characters are allowed.</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <p class="fs_14 fsn_4 txt_lb mb-1">Contact Number<span class="txt_d"> *</span>
            <img [src]="defualtPickupCountryFlag" name="cla" class="ms-2 c_flag_img">
          </p>
          <mat-form-field appearance="none">
            <input matInput formControlName="telephone" placeholder="Enter Contact Number (no spaces)"
              class="form-control custom_input" readonly="true">
          </mat-form-field>

        </div>
        <div class="col-md-6">
          <p class="fs_14 fsn_4 txt_lb mb-1">Email Address<span class="txt_d"> *</span></p>
          <mat-form-field appearance="none">
            <input matInput formControlName="emailAddress" placeholder="Enter Email Address"
              class="form-control custom_input" readonly="true">
          </mat-form-field>
        </div>
        <div class="col-md-6" *ngIf="userType ==='SHIPPER'">
          <p class="fs_14 fsn_4 txt_lb mb-1">Job Title <span class="txt_d"> *</span></p>
          <mat-form-field appearance="none">
            <input matInput formControlName="jobTitle" placeholder="Enter job title" maxlength="129"
              class="form-control custom_input">
            <mat-error *ngIf="submitted && carrierContactInfo.get('jobTitle').errors?.['required']" class="error_mess">
              Job title is required.</mat-error>
            <mat-error *ngIf="carrierContactInfo.get('jobTitle').errors?.['pattern']" class="error_mess">.</mat-error>
            <mat-error *ngIf="carrierContactInfo.get('jobTitle').errors?.['maxlength']" class="error_mess">Only 128
              characters are allowed.</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6" *ngIf="userType ==='CARRIER'">
          <p class="fs_14 fsn_4 txt_lb mb-1">Fax Number</p>
          <mat-form-field appearance="none">
            <input matInput formControlName="fax" placeholder="Enter Fax Number (no spaces)" maxlength="16"
              class="form-control custom_input">
          </mat-form-field>
        </div>
        <div class="col-md-6" *ngIf="userType ==='SHIPPER'">
          <p class="fs_14 fsn_4 txt_lb mb-1">LinkedIn Profile</p>
          <mat-form-field appearance="none">
            <input matInput formControlName="socialProfile" placeholder="Enter LinkedIn Profile"
              class="form-control custom_input" maxlength="256">
            <mat-error *ngIf="carrierContactInfo.get('socialProfile').errors?.['pattern']" class="error_mess">Please
              enter valid LinkedIn profile url.</mat-error>
          </mat-form-field>
        </div>
      </div>
      <p class="fs_20 fsm_5 txt_b mb-1 ">Add Physical Address</p>
      <div class="row">

        <div class="col-md-6">
          <p class="fs_14 fsn_4 txt_lb mb-1">Address Line 1 <span class="txt_d"> *</span></p>
          <div fxFlexAlign="center" class="autocomplete-container">
            <mat-form-field appearance="none">
              <input matInput formControlName="phyAddres1" ngx-gp-autocomplete maxlength="255"
                (onAddressChange)="pickAutoCompleteSelected($event)" [options]="searchRestriction"
                placeholder="Enter Address Line 1" class="form-control custom_input controls"
                [value]="carrierContactInfo.get('phyAddres1').value"
               >
              <mat-error *ngIf="carrierContactInfo.get('phyAddres1').errors?.['required'] && !addAutoNot"
                class="error_mess">Address Line 1 is required.</mat-error>
              <span *ngIf="addAutoNot" class="error_mess">Address Line 1 is required.</span>
              <mat-error *ngIf="carrierContactInfo.get('phyAddres1').errors?.['maxlength']" class="error_mess">Only 255
                characters are allowed.</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="col-md-6">
          <p class="fs_14 fsn_4 txt_lb mb-1">Address Line 2</p>
          <mat-form-field appearance="none">
            <input matInput formControlName="phyAddres2" placeholder="Enter Address Line 2" maxlength="255"
              class="form-control custom_input">
            <mat-error class="error_mess" *ngIf="carrierContactInfo.get('phyAddres2').errors?.['maxlength']">Only 255
              characters are allowed.</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <p class="fs_14 fsn_4 txt_lb mb-1">ZIP Code <span class="txt_d"> *</span></p>
          <mat-form-field appearance="none">
            <input matInput formControlName="phyPostalCode" type="text" placeholder="Enter ZIP Code" class="form-control custom_input" maxlength="8" minlength="4" />
            <mat-error *ngIf="carrierContactInfo.get('phyPostalCode').errors?.['required']" class="error_mess">ZIP Code is required.</mat-error>
            <mat-error class="error_mess" *ngIf="(carrierContactInfo.get('phyPostalCode').errors?.['minlength']) && !(carrierContactInfo.get('phyPostalCode').errors?.['pattern'])">ZIP Code should be minimum 4 digit.</mat-error>
            <mat-error *ngIf="carrierContactInfo.get('phyPostalCode').errors?.['pattern']" class="error_mess">{{zipCodeErrorMsg}}</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <p class="fs_14 fsn_4 txt_lb mb-1">City<span class="txt_d"> *</span></p>
          <mat-form-field appearance="none">
            <input matInput formControlName="phyCity" placeholder="Enter City" class="form-control custom_input"
              maxlength="33">
            <mat-error *ngIf="carrierContactInfo.get('phyCity').errors?.['required']" class="error_mess">City is
              required.</mat-error>
            <mat-error *ngIf="carrierContactInfo.get('phyCity').errors?.['maxlength']" class="error_mess">Only 32
              characters are allowed.</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6 ic_dropdown">
          <p class="fs_14 fsn_4 txt_lb mb-1">State/Province<span class="txt_d"> *</span></p>
          <mat-form-field appearance="none" class="">
            <mat-select formControlName="phyState" class="form-control custom_input" placeholder="Select state">
              <mat-option *ngFor="let state of phStates" [value]="state.sortname" class="mat_dropdown">
                {{state.name}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="carrierContactInfo.get('phyState').errors?.['required']" class="error_mess">State is
              required.</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6 ic_dropdown">
          <p class="fs_14 fsn_4 txt_lb mb-1">Country/Region<span class="txt_d"> *</span></p>
          <mat-form-field appearance="none">
            <input type="text" readonly matInput class="form-control custom_input" placeholder="Enter Country"
              formControlName="phyCountry" />
          </mat-form-field>
        </div>
      </div>
      <div class="custom_checkbox mb-2">
        <p class="fs_20 fsm_5 txt_b mb-2 " style="margin: 1.5rem 0 1rem;">Mailing Address</p>
        <mat-checkbox class="example-margin" value="checked" [checked]="checked" (change)="setAddress($event);"> Same as
          physical address</mat-checkbox>
      </div>
      <div class="row" *ngIf="!mailingSection">
        <div class="col-md-6 ">
          <p class="fs_14 fsn_4 txt_lb mb-1">Address Line 1<span class="txt_d"> *</span></p>
          <mat-form-field appearance="none">
            <input matInput placeholder="Enter Address Line 1" maxlength="255" class="form-control custom_input"
              formControlName="mailAddres1">
            <mat-error *ngIf="carrierContactInfo.get('mailAddres1').errors?.['required']" class="error_mess">Address
              Line 1 is required.</mat-error>
            <mat-error *ngIf="carrierContactInfo.get('mailAddres1').errors?.['maxlength']" class="error_mess">Only 255
              characters are allowed.</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <p class="fs_14 fsn_4 txt_lb mb-1">Address Line 2</p>
          <mat-form-field appearance="none">
            <input matInput formControlName="mailAddres2" maxlength="255" placeholder="Enter Address Line 2"
              class="form-control custom_input">
            <mat-error *ngIf="carrierContactInfo.get('mailAddres2').errors?.['maxlength']" class="error_mess">Address
              Line 2 max length 255 words.</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <p class="fs_14 fsn_4 txt_lb mb-1">ZIP Code <span class="txt_d"> *</span></p>
          <mat-form-field appearance="none">
            <input matInput formControlName="mailPostalCode" type="text" placeholder="Enter ZIP Code" class="form-control custom_input" maxlength="8" minlength="4"/>
            <mat-error *ngIf="carrierContactInfo.get('mailPostalCode').errors?.['required']" class="error_mess">ZIP Code is required.</mat-error>
            <mat-error class="error_mess" *ngIf="(carrierContactInfo.get('mailPostalCode').errors?.['minlength']) && !(carrierContactInfo.get('mailPostalCode').errors?.['pattern'])">ZIP Code should be minimum 4 digit.</mat-error>
            <mat-error *ngIf="carrierContactInfo.get('mailPostalCode').errors?.['pattern']" class="error_mess">{{zipCodeErrorMsg}}</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <p class="fs_14 fsn_4 txt_lb mb-1">City<span class="txt_d"> *</span></p>
          <mat-form-field appearance="none">
            <input matInput formControlName="mailCity" placeholder="Enter City" class="form-control custom_input"
              maxlength="33">
            <mat-error *ngIf="carrierContactInfo.get('mailCity').errors?.['required']" class="error_mess">City is
              required.</mat-error>
            <mat-error *ngIf="carrierContactInfo.get('mailCity').errors?.['maxlength']" class="error_mess">Only 32
              characters are allowed.</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6 ic_dropdown">
          <p class="fs_14 fsn_4 txt_lb mb-1">State/Province<span class="txt_d"> *</span></p>
          <mat-form-field appearance="none">
            <mat-select formControlName="mailState" class="form-control custom_input" placeholder="Select state">
              <mat-option *ngFor="let state of maStates" [value]="state.sortname" class="mat_dropdown">{{state.name}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="carrierContactInfo.get('mailState').errors?.['required']" class="error_mess">State is
              required.</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6 ic_dropdown">
          <p class="fs_14 fsn_4 txt_lb mb-1">Country/Region<span class="txt_d"> *</span></p>
          <mat-form-field appearance="none">
            <input type="text" readonly matInput class="form-control custom_input" placeholder="Enter Country"
              formControlName="mailCountry" />
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="form_action">
      <span class="fs_16 fsm_5 txt_lb pointer px-4 py-3 me-4 " (click)="closePopup()">Cancel</span>
      <button type="submit" class="btn custum_button button_success fs_16 fsm_5 ">Update</button>
    </div>
  </form>
</div>
<!--Update user information-->
<div *ngIf="data.openPop ==='storageContactInfo'">
  <div class="header_popup">
  <p class="fs_20 fsb_6 txt_b line_before ">Contact Info</p>
    <span class="close_popup_Icon" (click)="closePopup()">
      <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
    </span>
  </div>
  <form autocomplete="off" [formGroup]="storageContactInfo" (ngSubmit)="storageInfoSave(storageContactInfo)"
    class="mb-0 custum_input_css inputInPopup" autocomplete="off">
    <div class="v_scrollBar popup_vh" style="margin: 0 -24px; padding: 0 24px;">
      <div class="row">
        <div [ngClass]="userType==='STORAGE' ? 'col-md-6' : 'col-md-12'">
          <p class="fs_14 fsn_4 txt_lb mb-1">Company Name <span class="txt_d"> *</span></p>
          <mat-form-field appearance="none">
            <input matInput placeholder="Enter Company Name" class="form-control custom_input"
              formControlName="companyName" maxlength="65">
            <mat-error *ngIf="submitted && storageContactInfo.get('companyName').errors?.['required']"
              class="error_mess">Company name is required.</mat-error>
            <mat-error *ngIf="storageContactInfo.get('companyName').errors?.['pattern']" class="error_mess">Please enter
              valid information.</mat-error>
            <mat-error *ngIf="storageContactInfo.get('companyName').errors?.['maxlength']" class="error_mess">Only 64
              characters are allowed.</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <p class="fs_14 fsn_4 txt_lb mb-1">Contact Person First Name <span class="txt_d"> *</span></p>
          <mat-form-field appearance="none">
            <input matInput type="text" placeholder="Enter Contact Person First Name" maxlength="65"
              formControlName="firstName" class="form-control custom_input" />
            <mat-error *ngIf="submitted && storageContactInfo.get('firstName').errors?.['required']" class="error_mess">
              Contact person first name is required.</mat-error>
            <mat-error *ngIf="storageContactInfo.get('firstName').errors?.['pattern']" class="error_mess">Please enter
              valid information.</mat-error>
            <mat-error *ngIf="storageContactInfo.get('firstName').errors?.['maxlength']" class="error_mess">Only 64
              characters are allowed.</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <p class="fs_14 fsn_4 txt_lb mb-1">Contact Person Last Name<span class="txt_d"> *</span></p>
          <mat-form-field appearance="none">
            <input matInput formControlName="lastName" placeholder="Enter Contact Person Last Name" maxlength="65"
              class="form-control custom_input">
            <mat-error *ngIf="submitted && storageContactInfo.get('lastName').errors?.['required']" class="error_mess">
              Contact person last name is required.</mat-error>
            <mat-error *ngIf="storageContactInfo.get('lastName').errors?.['pattern']" class="error_mess">Please enter
              valid information.</mat-error>
            <mat-error *ngIf="storageContactInfo.get('lastName').errors?.['maxlength']" class="error_mess">Only 64
              characters are allowed.</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <p class="fs_14 fsn_4 txt_lb mb-1">Contact Number<span class="txt_d"> *</span>
            <img [src]="defualtPickupCountryFlag" name="cla" class="ms-2 c_flag_img">
          </p>
          <mat-form-field appearance="none">
            <input matInput formControlName="telephone" placeholder="Enter Contact Number (no spaces)"
              class="form-control custom_input" readonly="true">
          </mat-form-field>

        </div>
        <div class="col-md-6">
          <p class="fs_14 fsn_4 txt_lb mb-1">Email Address<span class="txt_d"> *</span></p>
          <mat-form-field appearance="none">
            <input matInput formControlName="emailAddress" placeholder="Enter Email Address"
              class="form-control custom_input" readonly="true">
          </mat-form-field>
        </div>
        <div class="col-md-6" *ngIf="userType ==='STORAGE'">
          <p class="fs_14 fsn_4 txt_lb mb-1">Fax Number</p>
          <mat-form-field appearance="none">
            <input matInput formControlName="fax" placeholder="Enter Fax Number (no spaces)" maxlength="16"
              class="form-control custom_input">
          </mat-form-field>
        </div>
        <div class="col-md-6" *ngIf="userType ==='STORAGE'">
          <p class="fs_14 fsn_4 txt_lb mb-1">LinkedIn Profile</p>
          <mat-form-field appearance="none">
            <input matInput formControlName="socialProfile" placeholder="Enter LinkedIn Profile"
              class="form-control custom_input" maxlength="256">
            <mat-error *ngIf="storageContactInfo.get('socialProfile').errors?.['pattern']" class="error_mess">Please
              enter valid LinkedIn profile url.</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6 ">
          <p class="fs_14 fsn_4 txt_lb mb-1">Company Website</p>
          <mat-form-field appearance="none">
            <input matInput formControlName="companyWebUrl" placeholder="Enter company website"
              class="form-control custom_input" maxlength="256">
            <mat-error *ngIf="storageContactInfo.get('companyWebUrl').errors?.['pattern']" class="error_mess">Enter only
              website url.</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6 mb-4 ic_dropdown">
          <p class="fs_14 fsn_4 txt_lb mb-1">Industries</p>
          <mat-select (selectionChange)="checkIndustOption($event)" [(ngModel)]="searchCtrl"
            [ngModelOptions]="{standalone: true}" name="industries" class="form-control custom_input"
            placeholder="Select Industry" autocomplete="off">
            <ngx-mat-select-search [(ngModel)]="searchBlank" [ngModelOptions]="{standalone: true}"
              noEntriesFoundLabel="'no matching industry found'" (keyup)="searchIndustry($event)"
              [placeholderLabel]="'Search...'" name="search"></ngx-mat-select-search>
            <mat-icon *ngIf="searchBlank" (click)="clearIndurties()" matSuffix class="clear_src">close</mat-icon>
            <mat-option *ngIf="searchCtrl" [value]="searchCtrl">{{searchCtrl}}</mat-option>
            <mat-optgroup *ngFor="let currentGroup of industriesList | keyvalue" label="{{currentGroup.key}}">
              <ng-container *ngFor="let induList of currentGroup.value">
                <mat-option *ngIf="searchCtrl  != induList.value" [value]="induList">{{ induList.value }}</mat-option>
              </ng-container>
            </mat-optgroup>
          </mat-select>
        </div>
      </div>
      <p class="fs_20 fsm_5 txt_b mb-1 ">Add Physical Address</p>
      <div class="row">
        <div class="col-md-6">
          <p class="fs_14 fsn_4 txt_lb mb-1">Address Line 1 <span class="txt_d"> *</span></p>
          <div>
            <!--  required -->
            <mat-form-field appearance="none">
              <input matInput formControlName="phyAddres1" ngx-gp-autocomplete
                (onAddressChange)="phyAddressStorage($event)" [options]="searchRestriction"
                placeholder="Enter Address Line 1" class="form-control custom_input controls"
                [value]="storageContactInfo.get('phyAddres1').value"
                (onAutocompleteSelected)="pickAutoCompleteSelected($event)">
              <mat-error *ngIf="storageContactInfo.get('phyAddres1').errors?.['required'] && !addAutoNot"
                class="error_mess">Address Line 1 is required.</mat-error>
              <span *ngIf="addAutoNot" class="error_mess">Address Line 1 is required.</span>
              <mat-error *ngIf="storageContactInfo.get('phyAddres1').errors?.['pattern'] && !addAutoNot"
                class="error_mess">Please enter valid information.</mat-error>
              <mat-error *ngIf="storageContactInfo.get('phyAddres1').errors?.['maxlength'] && !addAutoNot"
                class="error_mess">Only 64 characters are allowed.</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="col-md-6">
          <p class="fs_14 fsn_4 txt_lb mb-1">Address Line 2</p>
          <mat-form-field appearance="none">
            <input matInput formControlName="phyAddres2" placeholder="Enter Address Line 2"
              class="form-control custom_input">
            <mat-error class="error_mess" *ngIf="storageContactInfo.get('phyAddres2').errors?.['maxlength']">Only 64
              characters are allowed.</mat-error>
            <mat-error *ngIf="storageContactInfo.get('phyAddres2').errors?.['pattern']" class="error_mess">Please enter
              valid information.</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <p class="fs_14 fsn_4 txt_lb mb-1">ZIP Code <span class="txt_d"> *</span></p>
          <mat-form-field appearance="none">
            <input matInput formControlName="phyPostalCode" type="text" placeholder="Enter ZIP Code" class="form-control custom_input" maxlength="8" minlength="4" />
            <mat-error *ngIf="storageContactInfo.get('phyPostalCode').errors?.['required']" class="error_mess">ZIP Code is required.</mat-error>
            <mat-error class="error_mess" *ngIf="(storageContactInfo.get('phyPostalCode').errors?.['minlength']) && !(storageContactInfo.get('phyPostalCode').errors?.['pattern'])">ZIP Code should be minimum 4 digit.</mat-error>
            <mat-error *ngIf="storageContactInfo.get('phyPostalCode').errors?.['pattern']" class="error_mess">{{zipCodeErrorMsg}}</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <p class="fs_14 fsn_4 txt_lb mb-1">City<span class="txt_d"> *</span></p>
          <mat-form-field appearance="none">
            <input matInput formControlName="phyCity" placeholder="Enter City" class="form-control custom_input"
              maxlength="33">
            <mat-error *ngIf="storageContactInfo.get('phyCity').errors?.['required']" class="error_mess">City is
              required.</mat-error>
            <mat-error *ngIf="storageContactInfo.get('phyCity').errors?.['pattern']" class="error_mess">Please enter
              valid information.</mat-error>
            <mat-error *ngIf="storageContactInfo.get('phyCity').errors?.['maxlength']" class="error_mess">Only 32
              characters are allowed.</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6 ic_dropdown">
          <p class="fs_14 fsn_4 txt_lb mb-1">State/Province<span class="txt_d"> *</span></p>
          <mat-form-field appearance="none" class="">
            <mat-select formControlName="phyState" class="form-control custom_input" placeholder="Select state">
              <mat-option *ngFor="let state of phStates" [value]="state.sortname" class="mat_dropdown">
                {{state.name}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="storageContactInfo.get('phyState').errors?.['required']" class="error_mess">State is
              required.</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6 ic_dropdown">
          <p class="fs_14 fsn_4 txt_lb mb-1">Country/Region<span class="txt_d"> *</span></p>
          <mat-form-field appearance="none" [disabled]="ifGotCountry">
            <input type="text" readonly matInput class="form-control custom_input" placeholder="Enter Country"
              formControlName="phyCountry" />
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="form_action">
      <span class="fs_16 fsm_5 txt_lb pointer px-4 py-3 me-4 " (click)="closePopup()">Cancel</span>
      <button type="submit" class="btn custum_button button_success fs_16 fsm_5 ">Update</button>
    </div>
  </form>
</div>
<!--MCS detail -->
<div *ngIf="data.openPop ==='carrierMscInfo'">
  <div class="header_popup">
    <p class="fs_20 fsb_6 txt_b line_before ">MCS Info</p>
    <span class="close_popup_Icon" (click)="closePopup()">
      <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
    </span>
  </div>
  <form [formGroup]="mscDetail" (ngSubmit)="addMsc(mscDetail)" class="mb-0 custum_input_css inputInPopup"
    autocomplete="off">
    <div class="inviteDriver_pop v_scrollBar popup_vh">
      <div class="row">
        <div class="col-md-6">
          <div class="ic_calander">
            <p class="fs_14 fsn_4 txt_lb mb-1">MCS Date</p>
            <mat-form-field appearance="none">
              <input matInput formControlName="mcs150Date" [matDatepicker]="dp3" placeholder="MM/DD/YYYY" [max]="maxDate"
                [readonly]="true" class="form-control custom_input">
              <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
              <mat-datepicker #dp3></mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <div class="col-md-6">
          <p class="fs_14 fsn_4 txt_lb mb-1">MCS Mileage</p>
          <mat-form-field appearance="none">
            <input matInput placeholder="Enter Mileage" class="form-control custom_input" maxlength="255" maxlength="17"
              formControlName="mcs150Mileage">
            <mat-error *ngIf="mscDetail.get('mcs150Mileage').errors?.['pattern']" class="error_mess">MCS Mileage
              should be number</mat-error>
            <mat-error *ngIf="mscDetail.get('mcs150Mileage').errors?.['maxlength']" class="error_mess">Only 16
              characters are allowed.</mat-error>
          </mat-form-field>
        </div>
        <div class="col-md-6">
          <p class="fs_14 fsn_4 txt_lb mb-1">MCS Year</p>
          <mat-form-field appearance="none">
            <input matInput type="text" placeholder="Enter Year" class="form-control custom_input" maxlength="4"
              formControlName="mcs150MileageYear" />
            <mat-error *ngIf="mscDetail.get('mcs150MileageYear').errors?.['pattern']" class="error_mess">Year of MCS
              should be number</mat-error>
            <mat-error *ngIf="mscDetail.get('mcs150MileageYear').errors?.['max']" class="error_mess">Year is not valid
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="form_action">
      <span class="fs_16 fsm_5 txt_lb pointer px-4 py-3 me-4 " (click)="closePopup()">Cancel</span>
      <button type="submit" class="btn custum_button button_success fs_16 fsm_5 ">Update</button>
    </div>
  </form>
</div>
<div *ngIf="data.openPop ==='carrierBussinessServices'" class="business_popup">
  <div class="header_popup">
    <p class="fs_20 fsb_6 txt_b line_before ">Business Services</p>
    <span class="close_popup_Icon" (click)="closePopup()">
      <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
    </span>
  </div>
  <div class="cus_tab bus_ser_con">
    <mat-tab-group animationDuration="0ms">
      <mat-tab label="Equipment Type(s)">
        <form [formGroup]="caBussinessServices" (ngSubmit)="caBussiSerSave(caBussinessServices)" class="mb-0 custum_input_css inputInPopup" autocomplete="off">
          <div class="popup_vh">
            <div class="ic_dropdown mat_dropdown">
              <p class="popup_title">Truckload Type</p>
              <mat-form-field appearance="none">
                <mat-select formControlName='truckLoadType' name="loadType" class="form-control custom_input"
                  placeholder="Select Truckload Type" autocomplete="off">
                  <mat-option [value]="1">Full Truckload (FTL)</mat-option>
                  <mat-option [value]="2">Less Than Truckload (LTL)</mat-option>
                  <mat-option [value]="3">Both (FTL & LTL)</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <p class="popup_title " style="margin: 24px 0;">Equipment Type(s)</p>
            <div class="spinner equpments_loader" *ngIf="equipmentList.length === 0">
              <div class="bounce1"></div>
              <div class="bounce2"></div>
              <div class="bounce3"></div>
            </div>
            <div class="equpments" *ngIf="equipmentList && equipmentList.length > 0">
              <span *ngFor="let equipRecord of equipmentList; let i = index">
                <span class="chooseEqupment {{equipRecord.isChecked}}"
                  (click)="selectEquipmentType(equipRecord.id,equipRecord.isChecked)" id="{{equipRecord.dbName}}">
                  {{equipRecord.label}} </span>
              </span>
            </div>
            <div class="" *ngIf="userType==='CARRIER'">
              <p class="popup_title ">Operations</p>
              <mat-radio-group aria-label="Select an option" formControlName="carrierOperation"
                class="form-control checked_radio radio_color">
                <mat-radio-button value="Interstate" class="me-3">
                  <span class="material-icons">check_circle</span>
                  <span>Interstate</span>
                </mat-radio-button>
                <mat-radio-button value="Intrastate Hazmat" class="mx-3">
                  <span class="material-icons">check_circle</span>
                  <span>Intrastate Hazmat</span>
                </mat-radio-button>
                <mat-radio-button value="Intrastate Non-Hazmat" class="ms-3">
                  <span class="material-icons">check_circle</span>
                  <span>Intrastate Non-Hazmat</span>
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>
          <div class="form_action">
            <span class="fs_16 fsm_5 txt_lb pointer px-4 py-3 me-4 " (click)="closePopup()">Cancel</span>
            <button type="submit" class="btn custum_button button_success fs_16 fsm_5 ">Update</button>
          </div>
        </form>
      </mat-tab>
      <mat-tab label="Industries">
        <form [formGroup]="caBussinessServicesIndustrie"
          (ngSubmit)="caBussiSerSaveIndustrie(caBussinessServicesIndustrie)" class="mb-0 custum_input_css inputInPopup"
          autocomplete="off">
          <div class="ic_dropdown mat_dropdown">
            <p class="popup_title ">Industries</p>
            <mat-form-field appearance="none">
              <mat-select (selectionChange)="checkIndustOption($event)" [(ngModel)]="searchCtrl"
                [ngModelOptions]="{standalone: true}" name="industries" class="form-control custom_input"
                placeholder="Select Industry" autocomplete="off">
                <ngx-mat-select-search [(ngModel)]="searchBlank" [ngModelOptions]="{standalone: true}"
                  noEntriesFoundLabel="'no matching industry found'" (keyup)="searchIndustry($event)"
                  [placeholderLabel]="'Search...'" name="search"></ngx-mat-select-search>
                <mat-icon *ngIf="searchBlank" (click)="clearIndurties()" matSuffix class="clear_src">close</mat-icon>
                <mat-option *ngIf="searchCtrl" [value]="searchCtrl">{{searchCtrl}}</mat-option>
                <mat-optgroup *ngFor="let currentGroup of industriesList | keyvalue" label="{{currentGroup.key}}">
                  <ng-container *ngFor="let induList of currentGroup.value">
                    <mat-option *ngIf="searchCtrl  != induList.value" [value]="induList">{{ induList.value }}
                    </mat-option>
                  </ng-container>

                </mat-optgroup>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-12" *ngIf="userType==='SHIPPER'">
            <p class="fs_14 fsn_4 txt_lb mb-2">Industry <span class="txt_d"> *</span></p>
            <mat-form-field appearance="none">
              <input matInput formControlName="industry" placeholder="Enter Industry" class="form-control custom_input"
                maxlength="64">
              <span class="error_mess" *ngIf="industryError"> Industry is required.</span>
              <mat-error *ngIf="caBussinessServicesIndustrie.get('industry').errors?.['maxlength']" class="error_mess">
                Only 64 characters are allowed.</mat-error>
            </mat-form-field>
          </div>
          <div class="form_action">
            <span class="fs_16 fsm_5 txt_lb pointer px-4 py-3 me-4 " (click)="closePopup()">Cancel</span>
            <button type="submit" class="btn custum_button button_success fs_16 fsm_5 ">Update</button>
          </div>
        </form>
      </mat-tab>

      <mat-tab label="Other Info">
        <form *ngIf="userType==='CARRIER'" autocomplete="off" class="mb-0 custum_input_css inputInPopup"
          [formGroup]="caBussiSerOther" (ngSubmit)="caBussiSerOtherSave(caBussiSerOther)">
          <div class="popup_vh" style="margin: 24px 0 0;">
            <div class="row">
              <div class="col-md-6">
                <p class="fs_14 fsn_4 txt_lb mb-0">Number of Drivers</p>
                <mat-form-field appearance="none">
                  <input matInput formControlName="driverTotal" placeholder="Enter number of drivers"
                    class="form-control custom_input" maxlength="10" (input)="keyPasteDriverTotal($event)"
                    (keypress)="keyPressOnlyNumber($event)">
                  <mat-error *ngIf="caBussiSerOther.get('driverTotal').errors?.['pattern']" class="error_mess">Number of
                    driver should be number</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <p class="fs_14 fsn_4 txt_lb mb-0">Number of Trucks</p>
                <mat-form-field appearance="none">
                  <input matInput formControlName="truckTotal" placeholder="Enter number of trucks"
                    class="form-control custom_input" maxlength="10" (input)="keyPasteTrucks($event)"
                    (keypress)="keyPressOnlyNumberTrucks($event)">
                  <mat-error *ngIf="caBussiSerOther.get('truckTotal').errors?.['pattern']" class="error_mess">Number of
                    Trucks should be number</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-6 ">
                <p class="fs_14 fsn_4 txt_lb mb-0">Doing Business as</p>
                <mat-form-field appearance="none">
                  <input matInput formControlName="dbaName" [(ngModel)]="checkBdaName"
                    placeholder="Enter Doing Business as" maxlength="65" (keyup)="checkSpacedbaName($event)"
                    class="form-control custom_input">
                  <mat-error *ngIf="caBussiSerOther.get('dbaName').errors?.['pattern']" class="error_mess">DBA Name is
                    required.</mat-error>
                  <mat-error *ngIf="caBussiSerOther.get('dbaName').errors?.['maxlength']" class="error_mess">Only 64
                    characters are allowed.</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-6">
                <p class="fs_14 fsn_4 txt_lb mb-0">EIN(Federal Tax Id)</p>
                <mat-form-field appearance="none">
                  <input matInput placeholder="Enter Federal Tax Id" class="form-control custom_input" maxlength="17"
                    formControlName="einId" [(ngModel)]="checkEIN" (keyup)="checkSpaceEin($event)">
                  <mat-error *ngIf="caBussiSerOther.get('einId').errors?.['maxlength']" class="error_mess">Only 16
                    characters are allowed.</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-6 mb-2">
                <p class="fs_14 fsn_4 txt_lb" style="margin: 0 0 8px;">Hazmat</p>
                <mat-button-toggle-group formControlName="hmFlag" appearance="legacy" name="fontStyle"
                  aria-label="Font Style" class="cus_toggle">
                  <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                  <mat-button-toggle value="No">No</mat-button-toggle>
                </mat-button-toggle-group>
              </div>
              <div class="col-md-6 mb-2">
                <p class="fs_14 fsn_4 txt_lb" style="margin: 0 0 8px;">Passenger Carrier Unit</p>
                <mat-button-toggle-group appearance="legacy" formControlName="pcFlag" name="fontStyle"
                  aria-label="Font Style" class="cus_toggle">
                  <mat-button-toggle value="Yes">Yes</mat-button-toggle>
                  <mat-button-toggle value="No">No</mat-button-toggle>
                </mat-button-toggle-group>
              </div>
              <div class="col-md-6">
                <p class="fs_14 fsn_4 txt_lb mb-0">LinkedIn Profile</p>
                <mat-form-field appearance="none">
                  <input matInput placeholder="Enter LinkedIn Profile" class="form-control custom_input"
                    formControlName="socialProfile">
                  <mat-error *ngIf="caBussiSerOther.get('socialProfile').errors?.['pattern']" class="error_mess">Please
                    enter valid LinkedIn profile url.</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-6 ">
                <p class="fs_14 fsn_4 txt_lb mb-0">Company Website</p>
                <mat-form-field appearance="none">
                  <input matInput formControlName="companyWebUrl" placeholder="Enter company website" class="form-control custom_input" maxlength="256">
                  <mat-error *ngIf="caBussiSerOther.get('companyWebUrl').errors?.['pattern']" class="error_mess">Enter only website url.</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="form_action">
            <span class="fs_16 fsm_5 txt_lb pointer px-4 py-3 me-4 " (click)="closePopup()">Cancel</span>
            <button type="submit" class="btn custum_button button_success fs_16 fsm_5 ">Update</button>
          </div>
        </form>
        <form *ngIf="userType==='SHIPPER'" autocomplete="off" class="mb-0 custum_input_css inputInPopup"
          [formGroup]="shiBussiSerOther" (ngSubmit)="shiBussiSerOtherSave(shiBussiSerOther)">
          <div class="inviteDriver_pop v_scrollBar popup_vh" style="margin: 24px 0 0;">
            <div class="row">
              <div class="col-md-6">
                <p class="fs_14 fsn_4 txt_lb mb-2">Revenue (USD in millions)</p>
                <mat-form-field appearance="none">
                  <input matInput formControlName="companyRevenue" placeholder="Enter revenue (USD in millions)"
                    class="form-control custom_input" maxlength="16" (input)="keyPasteTrucks($event)"
                    (keypress)="keyPressOnlyNumberRevenue($event)">
                  <mat-error *ngIf="shiBussiSerOther.get('companyRevenue').errors?.['pattern']" class="error_mess">
                    Revenue should be number</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-6 ">
                <p class="fs_14 fsn_4 txt_lb mb-1">Company Email <span class="txt_d"> *</span></p>
                <mat-form-field appearance="none">
                  <input matInput formControlName="companyEmail" placeholder="Enter company email" maxlength="129"
                    class="form-control custom_input">
                  <mat-error *ngIf="shiBussiSerOther.get('companyEmail').errors?.['required']" class="error_mess"> Email
                    is required.</mat-error>
                  <mat-error *ngIf="shiBussiSerOther.get('companyEmail').errors?.['pattern']" class="error_mess"> Please
                    enter valid email address</mat-error>
                  <mat-error
                    *ngIf="(shiBussiSerOther.get('companyEmail').errors?.['maxlength']) && !(shiBussiSerOther.get('companyEmail').errors?.['pattern'])"
                    class="error_mess">Email must not exceed 128 characters.</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-6 ">
                <p class="fs_14 fsn_4 txt_lb mb-1">Company Website</p>
                <mat-form-field appearance="none">
                  <input matInput formControlName="companyWebUrl" placeholder="Enter company website"
                    class="form-control custom_input" maxlength="256">
                  <mat-error *ngIf="shiBussiSerOther.get('companyWebUrl').errors?.['pattern']" class="error_mess">Enter
                    only website url.</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-6 ">
                <p class="fs_14 fsn_4 txt_lb mb-1">LinkedIn Profile</p>
                <mat-form-field appearance="none">
                  <input matInput formControlName="socialProfile" placeholder="Enter LinkedIn Profile"
                    class="form-control custom_input" maxlength="256">
                  <mat-error *ngIf="shiBussiSerOther.get('socialProfile').errors?.['pattern']" class="error_mess">Enter
                    LinkedIn Profile URL.</mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="form_action">
            <span class="fs_16 fsm_5 txt_lb pointer px-4 py-3 me-4 " (click)="closePopup()">Cancel</span>
            <button type="submit" class="btn custum_button button_success fs_16 fsm_5 ">Update</button>
          </div>
        </form>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
<!--Update Cover Image & Profile Image -->
<div *ngIf="data.openPop ==='profileImage' || data.openPop ==='coverImage'">
  <div class="header_popup">
    <p class="fs_20 fsb_6 txt_b line_before " *ngIf="data.openPop ==='coverImage'">Cover Image</p>
    <p class="fs_20 fsb_6 txt_b line_before " *ngIf="data.openPop ==='profileImage'">Profile Image</p>
    <span class="close_popup_Icon" (click)="closePopup()">
      <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
    </span>
  </div>
  <form [formGroup]="profileImageForm" (ngSubmit)="profileImageSave(profileImageForm)"
    class="mb-0 custum_input_css inputInPopup" autocomplete="off">
    <div class="inviteDriver_popQW">
      <div class="v_scrollBar popup_vh" style="margin: 0 -24px; padding: 0 24px; max-height: 65vh;">
        <div class="row">
          <div class="col-md-12">
            <p *ngIf="data.openPop ==='coverImage'" class="fs_14 fsn_4 txt_lb input_label">Cover Image
              <span class="txt_d">* </span>
            </p>
            <p *ngIf="data.openPop ==='profileImage'" class="fs_14 fsn_4 txt_lb input_label">Profile Image
              <span class="txt_d">* </span>
            </p>
            <div class="upload_dock reg_upload_dockQW">
              <mat-form-field appearance="none" class="custom_matSuffix_icon">
                <input matInput type="text" placeholder="Browse Image" class="form-control custom_input"
                  readonly="readonly"> <!-- *ngIf="!showImgName" -->
              </mat-form-field>
              <div>
                <input accept=".png, .jpg, .jpeg" class="inputfile" id="file" name="file" type="file"
                  (change)="fileChangeEvent($event)" style="display: none;">
                 
                <label class="btn btn-primary py-0 mb-3" for="file"
                  style="background: #f5f5f5; border-radius: 0 4px 4px 0; margin: 0;">
                  <img class="icon icon-ic-upload fs_16 txt_lb" src="{{serverPathForUrl}}/portal/icon/ic_upload_grey_icon.svg">
                </label>
              </div>
              
            </div>
            <div class="img_croper_con" *ngIf="onlyCropper">
              <div *ngIf="data.openPop ==='coverImage'">
                <image-cropper *ngIf="showCoverCropper" [imageChangedEvent]="imageChangedEvent"
                  [maintainAspectRatio]="true" [aspectRatio]="5 / 1" (imageCropped)="imageCropped($event)"
                  (imageLoaded)="imageLoaded()" (loadImageFailed)="loadImageFailed()">
                </image-cropper>
              </div>
              <div *ngIf="data.openPop ==='profileImage'">
                <image-cropper *ngIf="showProfileCropper" [imageChangedEvent]="imageChangedEvent"
                  [maintainAspectRatio]="true" [aspectRatio]="1 / 1" (imageCropped)="imageCropped($event)"
                  (imageLoaded)="imageLoaded()" (loadImageFailed)="loadImageFailed()">
                </image-cropper>
              </div>
              <p class="crop_img_name" *ngIf="showImgName">{{imgName}}</p>
            </div>
            <mat-error class="error_mess" *ngIf="profileImageForm.get('profileImage').errors?.['required'] && data.openPop ==='coverImage' && profileSubmitted">Cover Image is required.</mat-error>
            <mat-error class="error_mess" *ngIf="profileImageForm.get('profileImage').errors?.['required'] && data.openPop ==='profileImage' && profileSubmitted">Profile Image is
                required.</mat-error>
            <p class="hints" *ngIf="data.openPop ==='profileImage'">Supported image size (512*512 OR 1:1). Supported
              image format png, jpg, jpeg.</p>
            <p class="hints" *ngIf="data.openPop ==='coverImage'">Supported image size (1440*320 OR 5:1). Supported
              image format png, jpg, jpeg.</p>
          </div>
          <div class="col-md-6 mx-auto text-center">
            <img *ngIf="imgURL" [src]="imgURL" style="width: 100px;height: 100px;">
          </div>
        </div>
      </div>
      <div class="form_action">
        <span class="fs_16 fsm_5 txt_lb pointer px-4 py-3 me-4 " (click)="closePopup()">Cancel</span>
        <button type="submit" [disabled]="disbu"
          class="btn custum_button button_success fs_16 fsm_5 ">Upload</button>
      </div>
    </div>
  </form>
</div>

<div *ngIf="data.openPop === 'driverProfileImage'">
  <div class="header_popup">
    <p class="fs_20 fsb_6 txt_b line_before ">Profile Image</p>
    <span class="close_popup_Icon" (click)="closePopup()">
      <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
    </span>
  </div>
  <form [formGroup]="driverProfileImageForm" (ngSubmit)="driverProfileImageSave(driverProfileImageForm)"
    class="mb-0 custum_input_css inputInPopup" autocomplete="off">
    <div class="inviteDriver_popQW">
      <div class="v_scrollBar popup_vh" style="margin: 0 -24px; padding: 0 24px; max-height: 65vh;">
        <div class="row">
          <div class="col-md-12">
            <p *ngIf="data.openPop === 'driverProfileImage'" class="fs_14 fsn_4 txt_lb input_label">Profile Image
              <span class="txt_d">* </span>
            </p>
            <div class="upload_dock reg_upload_dockQW">
              <mat-form-field appearance="none" class="custom_matSuffix_icon">
                <input matInput type="text" placeholder="Browse Image" class="form-control custom_input"
                  readonly="readonly"> <!-- *ngIf="!showImgName" -->
              </mat-form-field>
              <div>
                <input accept=".png, .jpg, .jpeg" class="inputfile" id="file" name="file" type="file"
                  (change)="fileChangeEvent($event)" style="display: none;">
                <label class="btn btn-primary py-0 mb-3" for="file"
                  style="background: #f5f5f5; border-radius: 0 4px 4px 0; margin: 0;">
                  <img class="icon icon-ic-upload fs_16 txt_lb" src="{{serverPathForUrl}}/portal/icon/ic_upload_grey_icon.svg">
                </label>
              </div>
            </div>
            <div class="img_croper_con" *ngIf="onlyCropper">
              <div *ngIf="data.openPop === 'driverProfileImage'">
                <image-cropper *ngIf="showProfileCropper" [imageChangedEvent]="imageChangedEvent"
                  [maintainAspectRatio]="true" [aspectRatio]="1 / 1" (imageCropped)="imageCropped($event)"
                  (imageLoaded)="imageLoaded()" (loadImageFailed)="loadImageFailed()">
                </image-cropper>
              </div>
              <p class="crop_img_name" *ngIf="showImgName">{{imgName}}</p>
            </div>
            <p class="hints" *ngIf="data.openPop === 'driverProfileImage'">Supported image format png, jpg, jpeg.</p>
          </div>
          <div class="col-md-6 mx-auto text-center">
            <img *ngIf="imgURL" [src]="imgURL" style="width: 100px;height: 100px;">
          </div>
        </div>
      </div>
      <div class="form_action">
        <span class="fs_16 fsm_5 txt_lb pointer px-4 py-3 me-4 " (click)="closePopup()">Cancel</span>
        <button type="submit" [disabled]="disbu"
          class="btn custum_button button_success fs_16 fsm_5 ">Upload</button>
      </div>
    </div>
  </form>
</div>

<!-- add another certificate popup -->
<div *ngIf="data.openPop ==='certificate'" class="pdf_doc_upload">
  <div class="header_popup">
    <p class="fs_20 fsb_6 txt_b line_before ">Upload New Certificate Of Insurance</p>
    <span class="close_popup_Icon" (click)="closePopup()">
      <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
    </span>
  </div>
  <form [formGroup]="certifcateVerify" (ngSubmit)="certifcateUpload(certifcateVerify)" class="mb-0 custum_input_css">
    <div class="inviteDriver_pop v_scrollBar popup_vh">
      <div class="">
        <p class="fs_14 fsn_4 txt_lb input_label">Upload Certificate Of Insurance <span class="txt_d"> *</span></p>
        <div class="upload_dock reg_upload_dock">
          <mat-form-field appearance="none" class="custom_matSuffix_icon">
            <input matInput type="text" formControlName="certificate" value="{{name}}"
              placeholder="Browse certificate of insurance" class="form-control custom_input" readonly="readonly">
            <mat-error *ngIf="certifcateVerify.get('certificate').errors?.['required']"
              class="error_mess">Certificate Of Insurance is required.</mat-error>
            <mat-error *ngIf="imagmessage" class="error_mess">{{ imagmessage }}</mat-error>
          </mat-form-field>
          <div>
            <input accept="application/pdf, image/jpeg, image/png, image/jpg" class="inputfile" id="file" name="file"
              type="file" (change)="onSelectedFileCertifcate($event)" style="display: none;">
            <label class="btn btn-primary py-0 mb-3" for="file"
              style="background: #f5f5f5; border-radius: 0 4px 4px 0; margin: 0;">
              <img class="icon icon-ic-upload fs_16 txt_lb" src="{{serverPathForUrl}}/portal/icon/ic_upload_grey_icon.svg">
            </label>
          </div>
        </div>
      </div>
      <div class="ic_calander">
        <p class="fs_14 fsn_4 txt_lb input_label">Expiry Date <span class="txt_d"> *</span></p>
        <mat-form-field appearance="none">
          <input matInput formControlName="insurance_expiry_date" [matDatepicker]="dp1" placeholder="Select Date"
            [min]="minDateBefore" [readonly]="true" class="form-control custom_input">
          <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
          <mat-datepicker #dp1 disabled="false"></mat-datepicker>
          <mat-error class="error_mess" *ngIf="certifcateVerify.get('insurance_expiry_date').errors?.['required']">
            Expiry date is required.</mat-error>
        </mat-form-field>
      </div>
      <p class="fs_14 fsn_4 txt_lb mb-0">Enter Insurance Amount <span class="txt_d"> *</span></p>
      <div fxFlexAlign="center" class="autocomplete-container">
        <mat-form-field appearance="none">
          <span matPrefix class="fsm_5 txt_b prefix_txt">$&nbsp;</span>
          <input matInput formControlName="insuranceAmount" placeholder="Enter Insurance Amount"
            class="form-control custom_input" maxlength="10">
          <mat-error *ngIf="certifcateVerify.get('insuranceAmount').errors?.['required']" class="error_mess">Insurance
            amount is required.</mat-error>
          <mat-error *ngIf="certifcateVerify.get('insuranceAmount').errors?.['pattern']" class="error_mess">Please enter
            valid information.</mat-error>
          <mat-error *ngIf="certifcateVerify.get('insuranceAmount').errors?.['maxlength']" class="error_mess">Only 10
            characters are allowed.</mat-error>
        </mat-form-field>
      </div>


      <p class="fs_14 fsn_4 txt_lb mb-0">Insurance Company Name <span class="txt_d"> *</span></p>
      <div fxFlexAlign="center" class="autocomplete-container">
        <mat-form-field appearance="none">
          <input matInput formControlName="insuranceCompany" placeholder="Enter Insurance Company Name"
            class="form-control custom_input" maxlength="32">
          <mat-error *ngIf="certifcateVerify.get('insuranceCompany').errors?.['required']" class="error_mess">Company
            name is required.</mat-error>
          <mat-error *ngIf="certifcateVerify.get('insuranceCompany').errors?.['pattern']" class="error_mess">Please
            enter valid information.</mat-error>
          <mat-error *ngIf="certifcateVerify.get('insuranceCompany').errors?.['maxlength']" class="error_mess">Only 32
            characters are allowed.</mat-error>
        </mat-form-field>
      </div>


      <p class="fs_14 fsn_4 txt_lb mb-0">Insurance Company Email<span class="txt_d"> *</span></p>
      <div fxFlexAlign="center" class="autocomplete-container">
        <mat-form-field appearance="none">
          <input matInput formControlName="insuranceEmail" placeholder="Enter Insurance Company Email"
            class="form-control custom_input" maxlength="129">
          <mat-error *ngIf="certifcateVerify.get('insuranceEmail').errors?.['required']" class="error_mess">Email is
            required.</mat-error>
          <mat-error *ngIf="certifcateVerify.get('insuranceEmail').errors?.['pattern']" class="error_mess">Please enter
            valid information.</mat-error>
          <mat-error *ngIf="certifcateVerify.get('insuranceEmail').errors?.['maxlength']" class="error_mess">Only 128
            characters are allowed.</mat-error>
        </mat-form-field>
      </div>
      <p class="fs_14 fsn_4 txt_d" style="margin: -1rem 0 2rem"></p>
    </div>
    <div class="form_action">
      <span class="fs_16 fsm_5 txt_lb pointer px-4 py-3 me-4 " (click)="closePopup()">Cancel</span>
      <button type="submit" [disabled]="disbu"
        class="btn custum_button button_success fs_16 fsm_5 ">Upload</button>
    </div>

  </form>
</div>
<!-- Upload Worker Compensation Document -->
<div *ngIf="data.openPop ==='workerCompensation'">
  <div class="header_popup">
    <p class="fs_20 fsb_6 txt_b line_before ">Upload Worker Compensation Document</p>
    <span class="close_popup_Icon" (click)="closePopup()">
      <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
    </span>
  </div>
  <form [formGroup]="workerCompensationForm" (ngSubmit)="workerCompensationSave(workerCompensationForm)"
    class="mb-0 custum_input_css inputInPopup" autocomplete="off">
    <div class="inviteDriver_pop">
      <div class="row">
        <div class="col-md-12">
          <p class="fs_14 fsn_4 txt_lb input_label">Upload Document <span class="txt_d"> *</span></p>
          <div class="upload_dock reg_upload_dock">
            <mat-form-field appearance="none" class="custom_matSuffix_icon">
              <input matInput type="text" formControlName="workerCompensation" value="{{name}}"
                placeholder="Browse Document" class="form-control custom_input" readonly="readonly">
              <mat-error *ngIf="imagmessage" class="error_mess">{{ imagmessage }}</mat-error>
            </mat-form-field>
            <div>
              <input accept="application/pdf, image/jpeg, image/png, image/jpg" class="inputfile" id="file" name="file"
                type="file" (change)="onSelectedWorkerCompensation($event)" style="display: none;">
                
              <label class="btn btn-primary py-0 mb-3" for="file"
                style="background: #f5f5f5; border-radius: 0 4px 4px 0; margin: 0;">
                <img class="icon icon-ic-upload fs_16 txt_lb" src="{{serverPathForUrl}}/portal/icon/ic_upload_grey_icon.svg">
              </label>
            </div>
          </div>
          <mat-error class="error_mess" *ngIf="workerCompensationForm.get('workerCompensation').errors?.['required'] && profileSubmitted">Worker Compensation Document is required.</mat-error>
        </div>
      </div>
    </div>
    <div class="form_action">
      <span class="fs_16 fsm_5 txt_lb pointer px-4 py-3 me-4 " (click)="closePopup()">Cancel</span>
      <button type="submit" [disabled]="disbu"
        class="btn custum_button button_success fs_16 fsm_5 ">Update</button>
    </div>
  </form>
</div>
<!-- Upload General Liability Document -->
<div *ngIf="data.openPop ==='generalLiability'">
  <div class="header_popup">
    <p class="fs_20 fsb_6 txt_b line_before ">Upload General Liability Document</p>
    <span class="close_popup_Icon" (click)="closePopup()">
      <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
    </span>
  </div>
  <form [formGroup]="generalLiabilityForm" (ngSubmit)="generalLiabilitySave(generalLiabilityForm)"
    class="mb-0 custum_input_css inputInPopup" autocomplete="off">
    <div class="inviteDriver_pop">
      <div class="row">
        <div class="col-md-12">
          <p class="fs_14 fsn_4 txt_lb input_label">Upload Document <span class="txt_d"> *</span></p>
          <div class="upload_dock reg_upload_dock">
            <mat-form-field appearance="none" class="custom_matSuffix_icon">
              <input matInput type="text" formControlName="generalLiability" value="{{name}}"
                placeholder="Browse Document" class="form-control custom_input" readonly="readonly">
              <mat-error *ngIf="imagmessage" class="error_mess">{{ imagmessage }}</mat-error>
            </mat-form-field>
            <div>
              <input accept="application/pdf, image/jpeg, image/png, image/jpg" class="inputfile" id="file" name="file"
                type="file" (change)="onSelectedFileGeneral($event)" style="display: none;">
              <label class="btn btn-primary py-0 mb-3" for="file"
                style="background: #f5f5f5; border-radius: 0 4px 4px 0; margin: 0;">
                <img class="icon icon-ic-upload fs_16 txt_lb" src="{{serverPathForUrl}}/portal/icon/ic_upload_grey_icon.svg">
              </label>
            </div>
          </div>
          <mat-error class="error_mess" *ngIf="generalLiabilityForm.get('generalLiability').errors?.['required'] && profileSubmitted">General Liability Document is required.</mat-error>
        </div>
      </div>
    </div>
    <div class="form_action">
      <span class="fs_16 fsm_5 txt_lb pointer px-4 py-3 me-4 " (click)="closePopup()">Cancel</span>
      <button type="submit" [disabled]="disbu"
        class="btn custum_button button_success fs_16 fsm_5 ">Update</button>
    </div>
  </form>
</div>
<!-- Upload Multiple Documents -->
<div *ngIf="data.openPop ==='multipleDoc'">
  <form [formGroup]="addDocUpload" (ngSubmit)="uploadDocumentMulti(addDocUpload)" class="mb-0">
    <div class="header_popup">
      <p class="fs_20 fsb_6 txt_b mb-0 line_before ">Upload New Document</p>
      <span class="close_popup_Icon" (click)="onNoConfirmClick()">
        <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
      </span>
    </div>
    <div class="">
      <p class="fs_14 fsn_4 txt_lb input_label">Document Name <span class="txt_d"> *</span></p>
      <mat-form-field appearance="none">
        <input matInput type="text" formControlName="description" placeholder="Enter document name"
          class="form-control custom_input" />
        <mat-error class="error_mess"
          *ngIf="documentSubmmited && addDocUpload.get('description').errors?.['required']">Name is
          required.</mat-error>
      </mat-form-field>
    </div>
    <div class="">
      <p class="fs_14 fsn_4 txt_lb input_label">Upload Document/Image<span class="txt_d"> *</span></p>
      <div class="upload_document">
        <mat-form-field appearance="none" class="custom_matSuffix_icon">
          <input matInput type="text" formControlName="document" value="{{name}}" placeholder="Browse image"
            class="form-control custom_input" readonly="readonly">
          <mat-error class="mt-1 text_truncate error_mess"
            *ngIf="documentSubmmited && addDocUpload.get('document').errors?.['required']">Document is
            required</mat-error>
          <span class="material-iconsicon">
            <input class="inputfile" id="file" name="file" type="file" (change)="onSelectedFile($event)"
              style="display: none;">
            <label class="btn btn-primary py-0 mb-3" for="file"
              style="background: #f5f5f5; border-radius: 0 4px 4px 0;">
              <img class="icon icon-ic-upload fs_16 txt_lb" src="{{serverPathForUrl}}/portal/icon/ic_upload_grey_icon.svg">
            </label>
          </span>
        </mat-form-field>
      </div>
    </div>
    <div class="form_action">
      <span class="fs_16 fsm_5 txt_lb pointer px-4 py-3 me-4 " (click)="onNoConfirmClick()">Cancel</span>
      <button type="submit" [disabled]="disbu" class="btn custum_button button_success fs_16 fsm_5 ">Add
        Document</button>
    </div>
  </form>
</div>
<!-- Upload Multiple Documents -->
<div *ngIf="data.openPop ==='shipperDocEdit'">
  <form [formGroup]="editDocUpload" (ngSubmit)="uploadEditDocumentMulti(editDocUpload)" class="mb-0">
    <div class="header_popup">
      <p class="fs_20 fsb_6 txt_b mb-0 line_before ">Edit Document Name</p>
      <span class="close_popup_Icon" (click)="onNoConfirmClick()">
        <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
      </span>
    </div>
    <div class="">
      <p class="fs_14 fsn_4 txt_lb input_label">Document Name <span class="txt_d"> *</span></p>
      <mat-form-field appearance="none">
        <input matInput type="text" formControlName="description" placeholder="Enter document name"
          class="form-control custom_input" />
        <mat-error class="error_mess"
          *ngIf="documentSubmmited &&  editDocUpload.get('description').errors?.['required']">Name is required.
        </mat-error>
      </mat-form-field>
    </div>
    <div class="btn_action">
      <span class="fs_16 fsm_5 txt_lb pointer px-4 py-3 me-4 " (click)="onNoConfirmClick()">Cancel</span>
      <button type="submit" class="btn custum_button button_primary btn_primary fs_16 fsm_5 "
        [disabled]="disbu">Edit
        Name </button>
    </div>

  </form>
</div>
<!-- Upload Contract Document -->
<div *ngIf="data.openPop ==='contractDocument'">
  <div class="header_popup">
    <p class="fs_20 fsb_6 txt_b line_before ">Upload Contract Document</p>
    <span class="close_popup_Icon" (click)="closePopup()">
      <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
    </span>
  </div>
  <form [formGroup]="contractForm" (ngSubmit)="contractUploadDocument(contractForm)" class="mb-0 custum_input_css">
    <div class="inviteDriver_pop v_scrollBar popup_vh">
      <div class="">
        <p class="fs_14 fsn_4 txt_lb input_label">Upload Document <span class="txt_d"> *</span></p>
        <div class="upload_dock reg_upload_dock">
          <mat-form-field appearance="none" class="custom_matSuffix_icon">
            <input matInput type="text" formControlName="contractDocument" value="{{name}}"
              placeholder="Browse Document" class="form-control custom_input" readonly="readonly">
            <mat-error *ngIf="imagmessage" class="error_mess">{{ imagmessage }}</mat-error>
          </mat-form-field>
          <div>
            <input accept="application/pdf" class="inputfile" id="file" name="file" type="file"
              (change)="onSelectedContract($event)" style="display: none;">
            <label class="btn btn-primary py-0 mb-3" for="file"
              style="background: #f5f5f5; border-radius: 0 4px 4px 0; margin: 0;">
              <img class="icon icon-ic-upload fs_16 txt_lb" src="{{serverPathForUrl}}/portal/icon/ic_upload_grey_icon.svg">
            </label>
          </div>
        </div>
      </div>
      <p class="fs_14 fsn_4 txt_d" style="margin: -1rem 0 2rem"></p>
    </div>
    <div class="form_action">
      <span class="fs_16 fsm_5 txt_lb pointer px-4 py-3 me-4 " (click)="closePopup()">Cancel</span>
      <button type="submit" [disabled]="disbu"
        class="btn custum_button button_success fs_16 fsm_5 ">Update</button>
    </div>
  </form>
</div>
<!-- Choose your singnature -->
<div *ngIf="data.openPop ==='signature'">
  <div class="header_popup">
    <p class="fs_20 fsb_6 txt_b line_before ">Choose Your Singnature</p>
    <span class="close_popup_Icon" (click)="closePopup()">
      <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
    </span>
  </div>
  <form [formGroup]="signatureForm" (ngSubmit)="signatureSave(signatureForm)" class="mb-0 custum_input_css inputInPopup"
    autocomplete="off">
    <div class="inviteDriver_pop v_scrollBar popup_vh">
      <p class="fs_14 fsm_5 txt_b mb-1">Name</p>
      <mat-form-field appearance="none">
        <input matInput placeholder="Enter name" formControlName="name" class="form-control custom_input"
          readonly="true">
      </mat-form-field>
      <p class="fs_14 fsm_5 txt_b">To update/edit your signature please update in profile</p>
      <div class="row" *ngIf="signatureSkeleton">
        <div class="col-md-6" *ngFor="let fake of generateFake(6)">
          <div class="sign_skelaton">
            <ngx-skeleton-loader count="1"
              [theme]="{ 'border-radius': '0', width: '100%', height: '90px', margin: '0px' }"></ngx-skeleton-loader>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="fontList?.length > 0 && !signatureSkeleton">
        <div class="col-md-{{columnSize}}" *ngFor="let fontType of fontList">
          <div class="signatureCon" (click)="selectFont(fontType.class)">
            <div class="sign_img" [ngClass]="selectedFont === fontType.class ? 'fontActive' : '' ">
              <span class="{{fontType.class}}">{{getRecord?.firstName}} {{getRecord?.lastName}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <mat-form-field appearance="none" appearance="outline" class="hide_input">
            <input matInput placeholder="Please enter font type" formControlName="type"
              class="form-control custom_input">
            <mat-error *ngIf="submitted && signatureForm.get('type').errors?.['required']" class="error_mess">Select
              font type required.</mat-error>
          </mat-form-field>
        </div>
      </div>
      <p class="fs_14 fsn_4 txt_lb mb-3">You can not change the signature once selected and the same will be used in all
        your documents</p>
    </div>
    <div class="form_action">
      <button type="submit" class="btn custum_button button_success fs_16 fsm_5 ">Update</button>
    </div>
  </form>
</div>
<!-- Connection for carrier and shipper both of-->
<div *ngIf="data.openPop ==='confirmConnectionPopup'">
  <div class="header_popup">
    <p class="fs_20 fsb_6 txt_b line_before " *ngIf="disableDriver">{{data.title}}</p>
    <p class="fs_20 fsb_6 txt_b line_before  ope_0">A</p>
    <span class="close_popup_Icon" (click)="closePopup()">
      <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
    </span>
  </div>
  <div class="" style="margin: 0;" *ngIf="disableDriver">
    <p class="fs_16 fsn_4 txt_lb" style="margin: 0; line-height: 24px;">{{data.contain}}</p>
    <div class="form_action Disconnect_popup">
      <span class="fs_16 fsm_5 txt_lb pointer px-4 py-3 " (click)="closePopup()" style="text-align: center;">Cancel</span>
      <button type="submit" class="btn custum_button button_success fs_16 fsm_5 "
        (click)="donePopup()">{{data.buttontext}}</button>
    </div>
  </div>
</div>
<div class="" *ngIf="data.openPop ==='connectionSuccess'">
  <div class="header_popup">
    <p class="fs_20 fsb_6 txt_b line_before  ope_0">A</p>
    <span class="close_popup_Icon" (click)="closePopup()">
      <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
    </span>
  </div>
  <div class="" style="margin: 0; padding: 1rem;">
    <p class="fs_20 fsm_5 txt_b" style="margin: 0 0 2rem;">Connection Request</p>
    <p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">{{data.successMeg}}</p>
    <div class="form_action">
      <button type="submit" class="btn custum_button button_success fs_16 fsm_5 "
        (click)="closePopup()">Done</button>
    </div>
  </div>
</div>
<div *ngIf="data.openPop ==='inviteCS'" style="margin: 0;padding: 1rem; position: relative;">
  <span class="close_popup_Icon" (click)="closePopup()">
    <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
  </span>
  <p class="fs_20 fsm_5 txt_b text-center" style="margin: 0 0 2rem;">
    <span *ngIf="data.status==='INVITE'">Invite</span>
    <span *ngIf="data.status==='REINVITE'">Reinvite</span> <span class="ms-1">{{data.driverName}} to Connect</span>
  </p>
  <div *ngIf="!noteShow">
    <p class="fs_14 fsn_4 txt_lb" style="margin: 0 0 1rem; line-height: 24px;">You can customize this Invitation</p>
    <p class="fs_14 fsn_4 txt_lb" style="margin: 0 0 1rem; line-height: 24px;">Add a note to personalize your request or
      to ask questions to <span class="txt_b">{{data.driverName}}</span></p>
  </div>
  <div *ngIf="noteShow">
    <p class="fs_14 fsn_4 txt_lb" style="margin: 0 0 1rem; line-height: 20px;">Build a quality network by connecting
      with Carriers based in your region, run the same lanes as you, and/or have the equipment required to haul your
      freight. If this information is unclear, you can ask them in the “message” section.</p>
    <div class="">
      <div class="mess_limit">
        <p class="fs_14 fsn_4 txt_lb mb-0">Message (Optional)</p>
        <p class="fs_14 fsn_4 txt_lb mb-0">200 characters</p>
      </div>
      <div class="cus_textarea">
        <mat-form-field appearance="none">
          <textarea matInput type="text" placeholder="Enter your message here…" class="form-control custom_input"
            [(ngModel)]="searchModel.message" #message="ngModel" [ngModelOptions]="{standalone: true}"
            style="background: var(--clBluh); min-height: 85px; padding: 0.9rem;"></textarea>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="form_action">
    <span class="fs_16 fsm_5 txt_lb pointer px-4 py-3 me-4 " (click)="addNote()">
      <span *ngIf="!noteShow" class="txt_p">Add a Note</span>
      <span *ngIf="noteShow">Cancel</span>
    </span>
    <button type="submit" class="btn custum_button button_success fs_16 fsm_5 " (click)="sendInvite()">
      <span *ngIf="data.status==='INVITE'">Send Invitation</span>
      <span *ngIf="data.status==='REINVITE'">Send Reinvitation</span>
    </button>
  </div>
</div>
<!-- Contract and  certificate of insurance -->
<div *ngIf="data.openPop ==='acceptContract'">

  <div *ngIf="!documentType">
    <div class="header_popup">
      <p class="fs_20 fsb_6 txt_b line_before ">{{data.title}}</p>
      <span class="close_popup_Icon" (click)="closePopup()">
        <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
      </span>
    </div>
    <div class="" style="margin: 0;">
      <p *ngIf="userType==='CARRIER'" class="fs_16 fsn_4 txt_lb" style="line-height: 24px;">You must accept contract
        from <span class="txt_b">{{data.name}}</span> before shipment can commence.</p>
      <p *ngIf="userType==='SHIPPER'" class="fs_16 fsn_4 txt_lb" style="line-height: 24px;">You must accept <span
          class="txt_b">{{data.name}}</span> insurance document before shipment can commence.</p>
    </div>
    <div class="doc_container">
      <div class="doc_containerl">
        <img src="{{serverPathForUrl}}/portal/images/tmp/demo_img1.png" class="img-fluid">
      </div>
      <div class="doc_containerr" *ngIf="userType==='CARRIER'">
        <a href="{{data.documentUrl}}" target="_blank">
          <p class="fs_14 fsm_5 txt_b m-0">Shipper Contract</p>
          <p class="fs_12 fsn_4 mb-0 txt_lb">
            <span class="create_date">Uploaded on {{data.date | date: 'MMM dd, yyyy' }}</span>
          </p>
        </a>
      </div>
      <div class="doc_containerr" *ngIf="userType==='SHIPPER'">
        <a href="{{data.documentUrl}}" target="_blank">
          <p class="fs_14 fsm_5 txt_b m-0">certificate-of-insurance.pdf</p>
          <p class="fs_12 fsn_4 txt_lb m-0">
            <span class="create_date" *ngIf="">Uploaded on {{data.date | date: 'MMM dd, yyyy' }}</span>
            <span class="expire_date"> Expired At {{data.expiredDate | date: 'MMM dd, yyyy' }} </span>
          </p>
        </a>
      </div>
    </div>
    <div class="contract_action">
      <div class="contract_actionl" (click)="acceptDoc()">
        <span class="material-icons txt_s fs_14 me-1">check_circle</span>
        <span class="fs_14 fsm_5 txt_s">Accept</span>
      </div>
    </div>
  </div>
  <!-- Success popu -->
  <div class="inviteDriver_con" *ngIf="documentType">
    <div class="text-center" style="margin: 0; padding: 1rem;">
      <span class="material-icons" style="margin: 0 0 1.5rem;font-size: 48px;color: var(--clGrna);">check_circle</span>
      <p class="fs_20 fsm_5 txt_b" style="margin: 0 0 2rem;">Congratulations</p>
      <p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">
        You have accepted <span class="txt_b">{{data.name}}</span>
        <span *ngIf="userType==='SHIPPER'"> COI.</span><span *ngIf="userType==='SHIPPER'">contract</span> Now you can
        start shipment with <span class="txt_b">{{data.name}}</span>
      </p>
      <div class="form_action">
        <button type="submit" class="btn custum_button button_success fs_16 fsm_5 "
          (click)="donePopup()">OK</button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="data.openPop ==='acceptContractDoc'">

  <div *ngIf="!documentType">
    <div class="header_popup">
      <p class="fs_20 fsb_6 txt_b line_before ">{{data.title}}</p>
      <span class="close_popup_Icon" (click)="closePopup()">
        <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
      </span>
    </div>
    <div class="" style="margin: 0;">
      <p *ngIf="userType==='CARRIER'" class="fs_16 fsn_4 txt_lb" style="line-height: 24px;">You must accept contract
        from <span class="txt_b">{{data.name}}</span> before shipment can commence.</p>
      <p *ngIf="userType==='SHIPPER'" class="fs_16 fsn_4 txt_lb" style="line-height: 24px;">You must accept <span
          class="txt_b">{{data.name}}</span> insurance document before shipment can commence.</p>
    </div>
    <div class="doc_container">
      <div class="doc_containerl">
        <img src="{{serverPathForUrl}}/portal/images/tmp/demo_img1.png" class="img-fluid">
      </div>
      <div class="doc_containerr" *ngIf="userType==='CARRIER'">
        <a href="{{data.documentUrl}}" target="_blank">
          <p class="fs_14 fsm_5 txt_b m-0">Shipper Contract</p>
          <p class="fs_12 fsn_4 mb-0 txt_lb">
            <span class="create_date">Uploaded on {{data.date | date: 'MMM dd, yyyy' }}</span>
          </p>
        </a>
      </div>
      <div class="doc_containerr" *ngIf="userType==='SHIPPER'">
        <a href="{{data.documentUrl}}" target="_blank">
          <p class="fs_14 fsm_5 txt_b m-0">certificate-of-insurance.pdf</p>
          <p class="fs_12 fsn_4 txt_lb m-0">
            <span class="expire_date txt_d"> Expired At {{data.expiredDate | date: 'MMM dd, yyyy' }} </span>
          </p>
        </a>
      </div>
    </div>
    <div class="contract_action">
    </div>
    <p *ngIf="userType==='SHIPPER'" class="fs_12 fsn_4 txt_lb" style="line-height: 24px;">Send a message to
      {{data.name}}, Inc. and request to upload an updated insurance document.</p>
  </div>
</div>
<div *ngIf="data.openPop ==='contractUpdateDocument'">
  <div class="header_popup">
    <p class="fs_20 fsb_6 txt_b line_before ">Contract Guidelines</p>
    <span class="close_popup_Icon" (click)="closePopup()">
      <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
    </span>
  </div>
  <div class="inviteDriver_pop v_scrollBar popup_vh">
    <div class="Guidelines">
      <p class="fs_16 fsn_4 txt_lb">The profile details below will be used to generate the LaneAxis Network standard
        contract. Please ensure all profile details are accurate. Once you and a Carrier sign off on all relevant
        documents, a standard LaneAxis contract is generated between the two parties, allowing Carriers to quote on
        your posted loads and accept an assigned load without any delay.</p>
      <ul>
        <li>
          <span class="Guide_title">Name</span>
          <span class="Guide_value" *ngIf="getRecord?.firstName && getRecord?.lastName">{{getRecord?.firstName +" "+
            getRecord?.lastName}}</span>
          <span class="Guide_value" *ngIf="getRecord?.firstName && !getRecord?.lastName">{{getRecord?.firstName}}</span>
        </li>
        <li>
          <span class="Guide_title">Company Name</span>
          <span class="Guide_value">{{getRecord?.companyName}}</span>
        </li>
        <li>
          <span class="Guide_title">Equipment Type</span>
          <span class="Guide_value" style="display: block;">
            <span *ngFor="let equipment of getRecord?.equipmentType; let i=index">{{ equipment}}<span
                *ngIf="i < getRecord?.equipmentType.length-1">, </span>
            </span>
          </span>
        </li>
        <li><span class="Guide_title">Address, State, ZIP Code</span>
          <span class="Guide_value">{{getRecord?.phyAddressLine1}}, {{getRecord?.phyState}}, {{getRecord?.phyZip}}
          </span>
        </li>
        <li>
          <span class="Guide_title">E-signature</span>
          <span class="Guide_value">
            <div class="sign_img">
              <span class="{{getRecord?.signatureFont}}">{{getRecord?.firstName}} {{getRecord?.lastName}}</span>
            </div>
          </span>
        </li>
        <li><span class="Guide_title">Job Title</span><span class="Guide_value"> {{getRecord?.title}}</span></li>
      </ul>
      <p class="fs_16 fsn_4 txt_lb" style="margin: 18px 0 0;">Please view and accept the Laneaxis Standard Contract,</p>
    </div>
    <div class="doc_container">
      <div class="doc_containerl">
        <img src="{{serverPathForUrl}}/portal/images/tmp/demo_img1.png" class="img-fluid">
      </div>
      <div class="doc_containerr">
        <a href="{{shipperContract}}" target="_blank">
          <p class="fs_14 fsm_5 txt_b m-0">Shipper Contract</p>
        </a>
      </div>
    </div>
  </div>
  <div class="form_action">
    <div class="contract_action">
      <div class="contract_actionl" (click)="acceptContractDocument()">
        <span class="material-icons txt_s fs_14 me-1">check_circle</span>
        <span class="fs_14 fsm_5 txt_s">Accept</span>
      </div>
      <div class="contract_actionr" (click)="closePopup()">
        <a href="{{shipperContract}}" target="_blank">
          <span class="fs_14 fsm_5 txt_p">View</span>
        </a>
      </div>
    </div>
  </div>
</div>
<!-- Success popu -->
<div class="" *ngIf="data.openPop ==='remove_document'">
  <div class="header_popup">
    <p class="fs_20 fsb_6 txt_b line_before ">{{data.title}}</p>
    <span class="close_popup_Icon" (click)="closePopup()">
      <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
    </span>
  </div>
  <div class="v_scrollBar pop_vh">
    <p class="fs_16 fsn_4 mb-3">{{data.contain}}</p>
  </div>
  <div class="form_action">
    <span class="fs_16 fsm_5 txt_lb pointer px-4 py-3 me-4 " (click)="closePopup()">Cancel</span>
    <button type="button" class="btn custum_button button_success fs_16 fsm_5 " (click)="donePopup()">OK</button>
  </div>
</div>
<!-- Region related -->
<div class="" *ngIf="data.openPop == 'regionAdd' || data.openPop == 'regionEdit'">
  <div class="header_popup">
    <p class="fs_20 fsb_6 txt_b line_before " *ngIf="data.openPop == 'regionAdd' ">Add Region</p>
    <p class="fs_20 fsb_6 txt_b line_before " *ngIf="data.openPop == 'regionEdit'">Edit Region</p>
    <span class="close_popup_Icon" (click)="closePopup()">
      <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
    </span>
  </div>
  <div class="crt_card pb-3">
    <form [formGroup]="selectRegionForm" (ngSubmit)="regionFormSubmit(selectRegionForm)">
      <div class="v_scrollBar vertical_scl">
        <div class="row">
          <div class="col-md-6 ic_dropdown mat_dropdown">
            <p class="fs_14 fsn_4 txt_lb mb-0">Select Region<span class="txt_d"> *</span></p>
            <mat-form-field appearance="none">
              <mat-select (selectionChange)="checkRegion($event.value)" formControlName="region"
                class="form-control custom_input" placeholder="Select Region">
                <mat-option *ngFor="let region of regionsList;  let i = index" [disabled]="checkArray(region)"
                  [value]="region">
                  {{region}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-6 ic_dropdown mat_dropdown">
            <p class="fs_14 fsn_4 txt_lb mb-0">Select State(s)<span class="txt_d"> *</span></p>
            <mat-form-field appearance="none">
              <mat-select placeholder="Select States" (selectionChange)="checkState($event)" multiple
                formControlName="state" class="form-control custom_input">
                <mat-option *ngFor="let regionData of statesList"
                  [value]="regionData.seletedId">{{regionData.seletedState}}</mat-option>
              </mat-select>
            </mat-form-field>
            <p *ngIf="selectedStatesList">{{selectedStatesList}}</p>
          </div>
        </div>
      </div>
      <div class="form_action">
        <span class="fs_16 fsm_5 txt_lb pointer px-4 py-3 me-4 " (click)="closePopup()">Cancel</span>
        <button [disabled]="checkValueRegion" type="submit" class="btn custum_button button_primary fs_16 fsm_5 "
          *ngIf="data.openPop == 'regionAdd'&& selectedStatesList">Add Region</button>
        <button [disabled]="checkValueRegion" type="submit" class="btn custum_button button_primary fs_16 fsm_5 "
          *ngIf="data.openPop == 'regionEdit' && selectedStatesList">Update Region</button>
      </div>
    </form>
  </div>
</div>
<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px',fullScreenBackdrop:true }"></ngx-loading>