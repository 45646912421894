import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AlertService } from 'src/app/commons/service/alert.service';
import { CommonService } from 'src/app/commons/service/common.service';
import { AppSettings } from 'src/app/commons/setting/app_setting';
import { SharedService } from 'src/app/commons/service/shared.service';
import { HereMapsService } from 'src/app/commons/service/here-maps.service';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { PopupComponent } from 'src/app/shared/popup/popup.component';
import { DisputePopupComponent } from '../dispute-popup/dispute-popup.component';
import { HereMapsService8 } from 'src/app/commons/service/here-maps8.service';
declare var require: any;

export interface DialogData {
  name: string;
}

@Component({
  selector: 'app-shipment-created-by-me-card',
  templateUrl: './shipment-created-by-me-card.component.html',
  styleUrls: ['./shipment-created-by-me-card.component.scss']
})
export class ShipmentCreatedByMeCardComponent implements OnInit {
  @Input() shipmentRecord: any;
  @Input() currentStatus: any;
  @Input() currentRouteParams: any;
  @Output() assingDriver = new EventEmitter();
  @Output() eventPassAction = new EventEmitter();
  @Output() refreshList = new EventEmitter();
  public userType: any;
  public etaInformation: any;
  public checkTimeDispute = false;
  public loading = false;
  public userId: any;
  public id: any;
  gifLoader: boolean;
  public ratingOwnerDetail: {};
  waypointIntransitStatus: any;
  waypointCompletedStatus: any;
  public waypoint: any; 
  intransitStatus: any;
  public currentTabShow: any;
  bidHistorySekeletonLoader: boolean;
  public currentlocation: any;
  public isHomePage: boolean = false;
  public serverPathForUrl: any;

  constructor(
    private router: Router,
    private alertService: AlertService,
    public commonService: CommonService,
    private cd: ChangeDetectorRef,
    private hereMapsService: HereMapsService,
    private hereMapsService8: HereMapsService8,
    private sharedService: SharedService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.userType = localStorage.getItem('user_type');
    this.userId =  localStorage.getItem('user_id');
    this.serverPathForUrl = environment.serverPathForUrl;
    if (this.userType === 'CARRIER') {
      if(this.shipmentRecord?.drivers?.userId != this.userId){
        this.ratingOwnerDetail = {
          key: 'driverId',
          id: this.shipmentRecord?.drivers?.userId,
        }}
        else{
        this.ratingOwnerDetail = {
        key: 'carrierId',
        id: this.shipmentRecord?.carriers?.userId,
      }
      }     
    }
    if (this.userType === 'SHIPPER' && this.shipmentRecord?.carriers?.userId) {
      this.ratingOwnerDetail = {
        key: 'carrierId',
        id: this.shipmentRecord?.carriers?.userId,
      }
    }
    this.getUpdatedWayPoints();
    this.getWaypointStatus();
  }

  eventActionProfileSidePanel(event: any, type: any){
    if(type==='profile_side_panel'){
      this.eventPassAction.emit({ event, type });
    }
  }

    // Multiple event for sidepanel.
    eventAction(event: any, type: any) {
      this.eventPassAction.emit({ event, type });
    }
  
  
   openNewTabRecord(shipmentRecord: any) {
    if (shipmentRecord &&  shipmentRecord.status === 15) {
      let newCurrentStatus = 'summary';
      const url = this.router.serializeUrl(
        this.router.createUrlTree([
          `/load/view/${shipmentRecord.id}/${newCurrentStatus}`,
        ])
      );
      window.open(url, '_blank');
    }else if (shipmentRecord && shipmentRecord.status === 59) {
      let newCurrentStatus = 'shipment';
      const url = this.router.serializeUrl(
        this.router.createUrlTree([
          `/load/view/${shipmentRecord.id}/${newCurrentStatus}`,
        ])
      );
      window.open(url, '_blank');
    } 
    else if (shipmentRecord && shipmentRecord.status === 3) {
      let newCurrentStatus = 'shipment';
      const url = this.router.serializeUrl(
        this.router.createUrlTree([
          `/load/view/${shipmentRecord.id}/${newCurrentStatus}`,
        ])
      );
      window.open(url, '_blank');
    }
    else {
      const url = this.router.serializeUrl(
        this.router.createUrlTree([
          `/load/view/${shipmentRecord.id}/${this.currentRouteParams}`,
        ])
      );
      window.open(url, '_blank');
    }
   }

   
  shipmentAssignDriver(event) {
    this.assingDriver.emit(event);
  }

  redirectToEdit() {
    this.router.navigate(['/load/edit', this.shipmentRecord.id]);
  }

  redirectToMessages(value) {
    if (value == 'Active') {
      this.router.navigate(['/messages/active_load/All/'], {
        queryParams: {activeChat: 'true', id: this.shipmentRecord?.id, title: this.shipmentRecord?.title},
      });
    }
    if (value == 'All') {
      this.router.navigate(['/messages/past_load/All/'], {
        queryParams: {activeChat: 'true', id: this.shipmentRecord?.id, title: this.shipmentRecord?.title},
      });
    }
  }
  
   //To copy web tracking url
   getWebTrackingLink() {
    if (
      this.shipmentRecord &&
      this.shipmentRecord.shippers &&
      this.shipmentRecord.shippers.email
    ) {
      var url = environment.domainPoint + '/load/track-your-load?load_id=' + this.shipmentRecord.uniqueId 
    } 
    else if (this.shipmentRecord && this.shipmentRecord.shipperEmail) {
      url = environment.domainPoint + '/load/track-your-load?load_id=' + this.shipmentRecord.uniqueId 
    }
    this.copyWebTrackingLink(url);
   }

   //copying web tracking link
   copyWebTrackingLink(val: string) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.alertService.showNotificationMessage(
      'success',
      'bottom',
      'right',
      'txt_s',
      'check_circle',
      'Web Tracking Link',
      'Web tracking link copied.'
    );
  }
  
   getWaypointStatus(){
    this.waypointIntransitStatus = this.shipmentRecord.waypoints.filter((x: { status: string; }) => x.status === 'In-Transit'); 
    this.intransitStatus = this.waypointIntransitStatus[0]?.status;
    this.waypointCompletedStatus = this.shipmentRecord.waypoints.filter((x: { status: string; }) => x.status === 'Completed');
    this.waypointCompletedStatus = this.waypointCompletedStatus[this.waypointCompletedStatus.length-1];
  }
  
  getUpdatedWayPoints() {
    if (this.shipmentRecord &&  this.shipmentRecord.waypoints && this.shipmentRecord.waypoints[0]?.date) {
        this.shipmentRecord.waypoints[0].date = this.getDateFormat(this.shipmentRecord.waypoints[0]?.date?.toString());
    }
    if (this.shipmentRecord &&  this.shipmentRecord.waypoints && this.shipmentRecord.waypoints[1]?.date) {
      this.shipmentRecord.waypoints[1].date = this.getDateFormat(this.shipmentRecord.waypoints[1]?.date?.toString());
    }
  }
  
  getDateFormat(inputDate: any) {
    if (inputDate !== '' && typeof inputDate !== undefined) {
      return inputDate.replaceAll('-', '/');
    } else {
      return '';
    }
  }
  
  checkMessageShipmentCondition() {
    if (
      this.shipmentRecord &&
      this.shipmentRecord.shippers &&
      this.shipmentRecord.shippers.email &&
      this.shipmentRecord.status !== '59'
    ) {
      return true;
    } else if (
      this.shipmentRecord &&
      this.shipmentRecord.shipperEmail &&
      this.shipmentRecord.status !== '59'
    ) {
      return true;
    } else {
      return false;
    }
  }

  checkEditShipmentCondition() {
    if (this.userType === 'SHIPPER' && this.shipmentRecord && this.shipmentRecord.shippers &&
      (this.shipmentRecord.statusSlug === 'readyToBid' || this.shipmentRecord.statusSlug === 'offerRate' ||
      this.currentStatus === 'inProgress' || this.currentStatus === 'upcoming' || this.currentStatus === 'pending')) {
      return true;
    } else if (this.userType === 'CARRIER' && this.shipmentRecord && this.shipmentRecord.carriers && !this.shipmentRecord.shippers &&
      (this.currentStatus === 'inProgress' || this.currentStatus === 'pending' ||
        !(this.currentStatus === 'past' || this.currentStatus === 'dispute' || this.currentStatus === 'expired'))) {
      return true;
    } else {
      return false;
    }
  }

  checkCancelShipmentCondition() {
    if (
      this.userType === 'SHIPPER' &&
      this.shipmentRecord &&
      this.shipmentRecord.shippers &&
      this.shipmentRecord.status !== '15' &&
      ( this.currentStatus === 'inProgress' ||
        this.currentStatus === 'inProgress' ||
        this.currentStatus === 'pending')
    ) {
      return true;
    } else if (
      this.userType === 'CARRIER' &&
      this.shipmentRecord &&
      this.shipmentRecord.carriers &&
      this.shipmentRecord.status !== '15' &&
      !this.shipmentRecord.shippers &&
      (this.currentStatus === 'inProgress' ||
        this.currentStatus === 'inProgress' ||
        this.currentStatus === 'pending')
    ) {
      return true;
    } else {
      return false;
    }
  }

  checkFileDisputeShipmentCondition() {
    if (this.shipmentRecord && this.shipmentRecord.updatedAt) {
      let days = 1;
      let deliveredDate = new Date(this.shipmentRecord.updatedAt + ' ' + 'UTC');
      deliveredDate.setDate(deliveredDate.getDate() + days);
      let now = new Date();
      const deliveryDateTime: any =
        moment(deliveredDate).format('YYYY-MM-DD HH:mm');
      const currentDateTime: any = moment(now).format('YYYY-MM-DD HH:mm');
      if (deliveryDateTime > currentDateTime) {
        this.checkTimeDispute = true;
      } else {
        this.checkTimeDispute = false;
      }
    } else {
      this.checkTimeDispute = false;
    }

    if (
      this.shipmentRecord &&
      this.checkTimeDispute &&
      this.shipmentRecord.shippers &&
      this.shipmentRecord.status === 51
    ) {
      return true;
    } else {
      return false;
    }
  }

  disputeShipment($event, id: any, uniqueId: any, title: any) {
    $event.stopPropagation();
    const dialogRef = this.dialog.open(DisputePopupComponent, {
      backdropClass: AppSettings.backdropClass,
      width: '600px',
      data: {
        openPop: 'fileDispute',
        shipmentId: id,
        uniqueId: uniqueId,
        title: title,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.event === 'Ok') {
        }
      }
    });
  }

    /*Cancel Shipment*/
    newShipmentCancel(id: any, uniqueId: any) {
      let confirmby: any;
      let buttontext = "Cancel";
      if ((this.userType === 'SHIPPER') && (this.currentStatus === 'pending' || this.currentStatus === 'upcoming' || this.currentStatus === 'inProgress')) {
        confirmby = 'confirmCancelShipment';
        this.cancelShipmentPayment(confirmby, id, uniqueId, buttontext);
      } else {
        confirmby = 'confirmcancel';
        this.cancelShipment(confirmby, id, uniqueId, buttontext);
      }
    }

    
    cancelShipmentPayment(confirmby: any, id: any, uniqueId: any, buttontext: any) {
      let buttonnew = 'Go Back';
      const dialogRef = this.dialog.open(PopupComponent, {
        backdropClass: AppSettings.backdropClass,
        width: AppSettings.popWidth,
        data: {
          openPop: confirmby,
          id: id,
          uniqueId: uniqueId,
          buttontext: buttontext,
          buttonnew: buttonnew,
        },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          if (result.event === 'Ok') {
            this.cancelShipmentSubmitPayment(id, 'firstTimeCall');
          }
        }
      });
    }

    async getCurrentStatus(type: any) {
      if (type === 'shipment') {
        if (!this.shipmentRecord) {
          this.shipmentRecord = await this.shipmentGet();
        } else {
          if (
            this.shipmentRecord &&
            this.shipmentRecord.tripStatus &&
            this.shipmentRecord.Text !== ''
          )
           {
            this.getTripStatus(this.shipmentRecord.tripStatus);}
        }
        if (this.shipmentRecord && this.shipmentRecord.createdAt) {
          this.shipmentRecord.createdAt = new Date(
            this.shipmentRecord.createdAt + ' ' + 'UTC'
          );
        }
        if (this.shipmentRecord && this.shipmentRecord.updatedAt) {
          this.shipmentRecord.updatedAt = new Date(
            this.shipmentRecord.updatedAt + ' ' + 'UTC'
          );
        }
      } else if (type === 'action') {
        this.shipmentRecord = await this.shipmentGet();
      }
      if (this.currentStatus === 'inProgressByLoads' || this.currentStatus === 'pendingByLoads')
        this.currentTabShow = 'driver';
      if (this.currentStatus === 'upcomingByLoads' || this.currentStatus === 'expired') {
        this.currentTabShow = 'shipment';
        this.bidHistorySekeletonLoader = false;
      }
      if (this.currentStatus === 'pastByLoads') {
        if (this.shipmentRecord.status !== 59 && this.shipmentRecord.status !== 15) {
          this.currentTabShow = 'document';
        } else {
          this.currentTabShow = 'shipment';
          this.bidHistorySekeletonLoader = false;
        }
  
      }

    }

    getTripStatus(tripStatus) {
      var moment = require('moment-timezone');
      if (tripStatus) {
        this.shipmentRecord.tripStatus = tripStatus;
        this.showPosition(
          this.shipmentRecord.tripStatus.latitude,
          this.shipmentRecord.tripStatus.longitude
        );
        this.gifLoader = false;
        if (tripStatus.ETA) {
          let dropTimeZone = this.hereMapsService.getTimeZone(
            this.shipmentRecord.dropTimeZone
          );
          let currentTime = moment().tz(dropTimeZone).format('MM/DD/YYYY HH:mm');
          let currentTS = Date.parse(currentTime);
          this.etaInformation = tripStatus.ETA + currentTS;
        }
      } else {
        this.etaInformation = null;
      }
    }

       // show user current location
  showPosition1(locationLat, locationLong) {
    let that = this;
    let geocoder = new google.maps.Geocoder();
    let selatlong = { lat: locationLat, lng: locationLong };
    geocoder.geocode({ location: selatlong }, function (results, status) {
      if (status === google.maps.GeocoderStatus.OK) {
        let newname = '';
        if (results[0].address_components[3] !== undefined) {
          newname = results[0].address_components[3].long_name;
        } else {
          newname = '';
        }
        that.currentlocation = results[0].formatted_address;
        that.cd.detectChanges();
      } else if (status === google.maps.GeocoderStatus.OVER_QUERY_LIMIT) {
        that.currentlocation = '-';
        that.cd.detectChanges(); // return addressDriver;
      } else {
      }
    });
  }


  showPosition(lat, lng) {
    const apiKey = 'AIzaSyAMMBwZYg03hSwJEnODnIWf14YJKjWz_2A';
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;
    let that = this;
    if(!lat || !lng)
      {
        return
      }
    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(response => {
        let results = response?.results
        let newname = '';
        if (results[0].address_components[3] !== undefined) {
          newname = results[0].address_components[3].long_name;
        } else {
          newname = '';
        }
        that.currentlocation = results[0].formatted_address;
        that.cd.detectChanges();
      })
      .catch(error => {
        console.error('Error fetching address:', error);
        that.currentlocation = '-';
        that.cd.detectChanges(); // // return addressDriver;
      });
  }



    shipmentGet() {
      this.bidHistorySekeletonLoader = true;
      return new Promise((resolve, reject) => {
        let uri = null;
        let newParams = {
          id: this.id,
        };
        if (newParams) uri = this.commonService.getAPIUriFromParams(newParams);
        let APIparams = {
          apiKey: AppSettings.APIsNameArray.SHIPMENT.NEWGET,
          uri: uri,
        };
        this.commonService.getList(APIparams).subscribe(async (ServerRes) => {
          if (ServerRes.success === true) {
            this.bidHistorySekeletonLoader = false;
            ServerRes.response.dropStatus = this.waypoint[this.waypoint.length - 1].status;
            ServerRes.response.dropTimeZone = this.waypoint[this.waypoint.length - 1].timeZone;
            ServerRes.response.dropTimeslot = this.waypoint[this.waypoint.length - 1].timeSlot;
            ServerRes.response.dropDate = this.waypoint[this.waypoint.length - 1].date;
            this.shipmentRecord = ServerRes.response;
            if (
              this.shipmentRecord && this.sharedService.checkEligiblityForTripStatus(ServerRes.response.status) && !this.isHomePage) {
              if (ServerRes.response.status < 42) {
                this.gifLoader = true;
                this.hereMapsService8
                  .calculateMultiRoute(this.shipmentRecord, this.waypoint).subscribe((hereMapsRoutingResponse)=>{   if (hereMapsRoutingResponse) {
                    var tripStatus = this.hereMapsService.calculateTripStatus(
                      hereMapsRoutingResponse,
                      this.shipmentRecord
                    );
                    if (tripStatus) this.getTripStatus(tripStatus);
                  }

                  })
              } else {
                var tripStatus =
                  this.hereMapsService.calculateRoutewithCallingHereMaps(
                    ServerRes.response
                  );
                this.getTripStatus(tripStatus);
              }
            } else {
              this.etaInformation = null;
            }
            resolve(ServerRes.response);
          } else {
            resolve(null);
            this.bidHistorySekeletonLoader = false;
          }
        });
      });
    }


  //Cancel shipment api called.
  cancelShipmentSubmitPayment(id: any, check: any) {
    this.loading = true;
    let postBody = null;
    let action = '';
    if (check === 'firstTimeCall') {
      action = 'cancel';
    } else {
      action = 'assign';
    }
    let driverId;
    if (this.shipmentRecord.drivers) {
      postBody = {
        shipmentId: this.shipmentRecord.id,
        driverId: driverId,
        carrierId: localStorage.getItem('user_id'),
        action: action,
      };
    } else {
      postBody = {
        shipmentId: this.shipmentRecord.id,
        carrierId: localStorage.getItem('user_id'),
        action: action,
      };
    }
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.NEWPAYMENT.CANCELSHIPMENTPAYMENT,
      uri: '',
      postBody: postBody,
    };
    this.commonService.post(APIparams).subscribe(
      async (success) => {
        if (success.success === true) {
          this.id = id;
          this.getCurrentStatus('action');
          this.refreshList.emit();
          if (check === 'firstTimeCall') {
          } else {
            this.alertService.showNotificationMessage(
              'success',
              'bottom',
              'right',
              'txt_s',
              'check_circle',
              'Cancel Shipment',
              success.message
            );
          }
          this.loading = false;
        } else if (success.success === false) {
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_d',
            'cancel',
            'Cancel Shipment',
            success.message
          );
          this.loading = false;
        }
      },
      (error) => {
        this.loading = false;
        this.alertService.showNotificationMessage(
          'danger',
          'bottom',
          'right',
          'txt_d',
          'cancel',
          'Cancel Shipment',
          AppSettings.ERROR
        );
      }
    );
  }

      //Cancel shipment popup
      cancelShipment(
        confirmby: any,
        id: any,
        uniqueId: any,
        buttontext: any
      ) {
        let buttonnew = 'Go Back';
        const dialogRef = this.dialog.open(PopupComponent, {
          backdropClass: AppSettings.backdropClass,
          width: AppSettings.popWidth,
          data: {
            openPop: confirmby,
            id: id,
            uniqueId: uniqueId,
            buttontext: buttontext,
            buttonnew: buttonnew,
          },
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            if (result.event === 'Ok') {
              this.cancelShipmentSubmit(id, 'firstTimeCall');
            }
          }
        });
      }

  //Cancel shipment api called.
  cancelShipmentSubmit(id: any, check: any) {
    this.loading = true;
    let postBody = null;
    let action = '';
    if (check === 'firstTimeCall') {
      action = 'cancel';
    } else {
      action = 'assign';
    }
    let driverId;
    if (this.shipmentRecord.drivers) {
      postBody = {
        shipmentId: this.shipmentRecord.id,
        driverId: driverId,
        carrierId: localStorage.getItem('user_id'),
        action: action,
      };
    } else {
      postBody = {
        shipmentId: this.shipmentRecord.id,
        carrierId: localStorage.getItem('user_id'),
        action: action,
      };
    }

    let APIparams = {
      apiKey: AppSettings.APIsNameArray.SHIPMENT.SHIPMENTCANCEL,
      uri: '',
      postBody: postBody,
    };
    this.commonService.post(APIparams).subscribe(
      async (success) => {
        if (success.success === true) {
          this.id = id;
          this.getCurrentStatus('action');
          this.refreshList.emit();
          if (check === 'firstTimeCall') {
          } else {
            this.alertService.showNotificationMessage(
              'success',
              'bottom',
              'right',
              'txt_s',
              'check_circle',
              'Cancel Shipment',
              success.message
            );
          }
          this.loading = false;
        } else if (success.success === false) {
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_d',
            'cancel',
            'Cancel Shipment',
            success.message
          );
          this.loading = false;
        }
      },
      (error) => {
        this.loading = false;
        this.alertService.showNotificationMessage(
          'danger',
          'bottom',
          'right',
          'txt_d',
          'cancel',
          'Cancel Shipment',
          AppSettings.ERROR
        );
      }
    );
  }


}
