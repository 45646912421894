<div class="load_his_head">
    <p class='line_before  mb-0'>Load History</p>
    <div class="custom_checkbox">
      <mat-checkbox class="cus_checkBox" [(ngModel)]="checkRoutePlan" (change)="FilterHistory()">Route Plan
        Modified</mat-checkbox>
      <mat-checkbox class="cus_checkBox ms-3" [(ngModel)]="checkShipmentEdit" (change)="FilterHistory()">Shipment
        Edited</mat-checkbox>
    </div>
  </div>
  <div class="load_his_info">
    <div *ngFor="let shipmentHistory of shipmentHistoryGetTrimmed; let i = index">
      <div class="status_history" *ngIf="!skeletonLoaderhistory"
        [ngClass]="typeOfSection==='side-panel' ? 'trip_history_sidePanel' : 'trip_history_detail'">
        <div class="list_row">
          <div class="event_dateTxt">
            <p class="event_date_yr">{{UTCDateOnly(shipmentHistory.date)}}</p>
          </div>
          <span class="load_icon"
            [ngStyle]="{'background-color': (shipmentHistory?.statusColorCode ? shipmentHistory?.statusColorCode : '#3787E6')}">
            <img src="{{shipmentHistory.mapIcon}}" class="img-fluid">
          </span>
          <div class="event_time ope_0">
            <p class="event_title">xyz</p>
          </div>
        </div>
  
        <div class="list_row active_row" *ngFor="let shipmentHistoryPoints of shipmentHistory.points; let y = index">
          <div class="event_dateTxt">
            <p *ngIf="shipmentHistoryPoints.actionType!='shipmentEdit' && shipmentHistoryPoints.actionType!='waypointEdit'"
              class="event_date evnt_time"> {{UTCtime(shipmentHistoryPoints.updatedAt)}} </p>
            <p *ngIf="shipmentHistoryPoints.actionType=='shipmentEdit' || shipmentHistoryPoints.actionType=='waypointEdit'"
              class="event_date">
              <span>{{UTCtime(shipmentHistoryPoints.updatedAt)}}</span>
            </p>
          </div>
  
          <span class="load_icon" [ngClass]="classFunction(shipmentHistoryPoints.mapIcon)"
            [ngStyle]="{'background-color': (shipmentHistoryPoints?.statusColorCode ? shipmentHistoryPoints?.statusColorCode : '#3787E6')}">
            <img src="{{shipmentHistoryPoints.mapIcon}}" class="img-fluid">
          </span>
          <div class="event_time">
            <p *ngIf="typeOfSection==='side-panel'" class="event_title pointer text_truncate "
              matTooltip="{{shipmentHistoryPoints?.statusLabel}}">{{shipmentHistoryPoints.statusLabel}}</p>
            <p *ngIf="typeOfSection==='shipment-view'" class="event_title text_truncate ">
              {{shipmentHistoryPoints.statusLabel}}</p>
  
            <div class="event_location">
              <p *ngIf="!shipmentHistoryPoints.locationLat && !shipmentHistoryPoints.locationLong && !shipmentHistoryPoints?.actionType"
                class="hideshow_{{i}} mb-0" id="hideshow_{{i}}">
                <img *ngIf="!shipmentHistoryPoints.locationLat && !shipmentHistoryPoints.locationLong"
                  src="https://s3.amazonaws.com/staticprod.laneaxis.com/portal/images/after-login/cl-icons/ic-location-dark-grey.svg"
                  class="img-fluid" id="hideshow_{{i}}">
                <span class="fs_12 fsn_4 txt_lb no_location">No Location shared</span>
              </p>
              <p *ngIf="shipmentHistoryPoints?.actionType" class="event_title text_truncate ">
                {{shipmentHistoryPoints?.label}}</p>
              <p *ngIf="shipmentHistoryPoints.locationLat && shipmentHistoryPoints.locationLong && !shipmentHistoryPoints?.currentLocation"
                class="hideshow_{{i}}" id="hideshow_{{i}}"
                [ngClass]="shipmentHistoryPoints?.showLocation==='show' ? 'hideOption' : 'showOption' ">
                <img *ngIf="!shipmentHistoryPoints?.currentLocation"
                  src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-eye.svg" class="img-fluid"
                  id="hideshow_{{i}}">
                <span id="hideshow_{{i}}" class="location_show ms-1 pointer"
                  (click)="showLocation(shipmentHistoryPoints.locationLat,shipmentHistoryPoints.locationLong,i,y,z,'action')">Show
                  Location</span>
              </p>
              <img *ngIf="shipmentHistoryPoints?.currentLocation"
                src="https://s3.amazonaws.com/staticprod.laneaxis.com/portal/images/after-login/cl-icons/ic-location-dark-grey.svg"
                class="img-fluid">
              <p class="location_detail" *ngIf="shipmentHistoryPoints?.currentLocation"
                matTooltip="{{shipmentHistoryPoints?.currentLocation}}">
                <span class="location_detailTxt pointer text_truncate">{{shipmentHistoryPoints?.currentLocation}}</span>
                <span class="location_hide  ms-1" (click)="hideLocation(i,y,z,'action')">Hide</span>
              </p>
            </div>
  
            <div *ngIf="shipmentHistoryPoints.actionType">
              <div class="event_location">
                <div *ngIf="shipmentHistoryPoints.showDetail!='show'">
                  <p class="hideshow_{{i}}" id="hideshow_{{i}}"
                    [ngClass]="shipmentHistoryPoints?.showLocation==='show' ? 'hideOption' : 'showOption' ">
                    <span id="hideshow_{{i}}" class="location_show pointer" (click)="showUpdatedShipment(i,y)">
                      <img src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-eye.svg"
                        class="img-fluid">
                      <span class=" ms-1">Show Details</span>
                    </span>
                  </p>
                </div>
                <span *ngIf="shipmentHistoryPoints.showDetail=='show'" class="location_hide" (click)="hideHistory(i,y)">
                  <img src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-eye.svg"
                    class="img-fluid">
                  <span class=" ms-1">Hide Details</span>
                </span>
              </div>
            </div>
            <div
              *ngIf="shipmentHistoryPoints.showDetail=='show' && shipmentHistoryPoints.actionType=='wayPointsChecklist'">
              <div class="updated_value_box_2"
                *ngFor="let shipmentWayPoints of shipmentHistoryPoints.waypoints; let z = index">
                <span class="sub_point_time">{{UTCtime(shipmentWayPoints?.updatedAt)}}</span>
  
                <div class="scnd_lable_row_con">
                  <div class="slrc_row {{trimForCss(shipmentWayPoints?.statusColorCode)}}">
                    <div class="slrc_rl">
                      <span class="slrc_icon" [ngStyle]="{'background-color': shipmentWayPoints?.statusColorCode}">
                        <img src="{{shipmentWayPoints.mapIcon}}" class="img-fluid">
                      </span>
                    </div>
                    <div class="slrc_rr">
                      <p class="event_title QW">{{(shipmentWayPoints.statusLabel)}}</p>
                      <div class="event_location">
                        <p *ngIf="!shipmentWayPoints.locationLat && !shipmentWayPoints.locationLong"
                          class="hideshow_{{i}} mb-0" id="hideshow_{{i}}">
                          <img
                            src="https://s3.amazonaws.com/staticprod.laneaxis.com/portal/images/after-login/cl-icons/ic-location-dark-grey.svg"
                            class="img-fluid" id="hideshow_{{i}}">
                          <span class="fs_12 fsn_4 txt_lb no_location">No Location shared</span>
                        </p>
                        <p *ngIf="shipmentWayPoints.locationLat && shipmentWayPoints.locationLong && !shipmentWayPoints?.currentLocation"
                          class="hideshow_{{i}}" id="hideshow_{{i}}"
                          [ngClass]="shipmentHistoryPoints?.showLocation==='show' ? 'hideOption' : 'showOption' ">
                          <img *ngIf="!shipmentWayPoints?.currentLocation"
                            src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-eye.svg"
                            class="img-fluid" id="hideshow_{{i}}">
                          <span id="hideshow_{{i}}" class="location_show ms-1 pointer"
                            (click)="showLocation(shipmentWayPoints.locationLat,shipmentWayPoints.locationLong,i,y,z, 'waypoints')">Show
                            Location</span>
                        </p>
                        <img *ngIf="shipmentWayPoints?.currentLocation && shipmentHistoryPoints?.showLocation=='show'"
                          src="https://s3.amazonaws.com/staticprod.laneaxis.com/portal/images/after-login/cl-icons/ic-location-dark-grey.svg"
                          class="img-fluid">
                        <div class="location_detail" *ngIf="shipmentWayPoints?.currentLocation"
                          matTooltip="{{shipmentWayPoints?.currentLocation}}">
                        <div style="display: flex;">
                          <img
                            src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-location-dark-grey.svg"
                            class="img-fluid">
                          <span
                            class="location_detailTxt pointer text_truncate ms-1">{{shipmentWayPoints?.currentLocation}}</span>
                        </div>
                        <div>
                        <span class="location_hide  ms-1" (click)="hideLocation(i,y,z,'waypoints')">Hide</span>
                      </div>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
  
            <div *ngIf="shipmentHistoryPoints.showDetail=='show' && shipmentHistoryPoints.actionType=='waypointEdit'">
              <div class="slrc_row px-0" *ngFor="let editWayPoints of shipmentHistoryPoints.editWaypoints; let z = index">
                <div class="scnd_lable_row_con">
                  <p class="event_title">{{editWayPoints.label}}</p>
                  <p class="mb-0" *ngIf="editWayPoints.key == 'addedWaypoint'">
                    <img
                      src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-location-dark-grey.svg"
                      class="img-fluid">
                    <span class="fs_12 fsn_4 txt_lb no_location">{{editWayPoints.newValue}}</span>
                  </p>
                  <p class="mb-0" *ngIf="editWayPoints.key == 'deleteWaypoint'">
                    <img
                      src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-location-dark-grey.svg"
                      class="img-fluid">
                    <span class="fs_12 fsn_4 txt_lb no_location">{{editWayPoints.oldValue}}</span>
                  </p>
                  <p class="mb-0" *ngIf="editWayPoints.key == 'waypointChanged'">
                    <img
                      src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/ic-location-dark-grey.svg"
                      class="img-fluid">
                    <span class="fs_12 fsn_4 txt_lb no_location">{{editWayPoints.newValue}}</span>
                  </p>
                </div>
              </div>
            </div>
  
            <div *ngIf="shipmentHistoryPoints.showDetail=='show' && shipmentHistoryPoints.actionType=='shipmentEdit'">
              <div class="slrc_row" *ngFor="let shipmentEdit of shipmentHistoryPoints.editPoints; let z = index">
                <div class="scnd_lable_row_con">
                  <p class="event_title">{{shipmentEdit.label}}</p>
                  <p class="fs_12 fsn_4 txt_b mb-0">
                    <span class="txt_lb">{{shipmentEdit.oldValue}}</span>
                    <span class="txt_lb mx-2">></span>
                    <span>{{shipmentEdit.newValue }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div *ngIf="shipmentHistoryGet?.length === 0">
    <div class="Empty_State_body b_shadow_0">
      <div class="Empty_State_con">
        <div class="Empty_State_img">
          <img class="img-fluid"
            src="{{serverPathForUrl}}/portal/images/after-login/Empty_State_icons/graphic_no-transactions.svg ">
        </div>
        <div>
          <p class="fs_24 fsb_6 txt_b pb-1 " style="margin: 30px 0 12px;">No History... <span
              class="txt_p "> Yet</span> </p>
          <p class="fs_16 fsn_4 txt_lb pb-1" style="margin: 0px 0 20px;">No bids were received on this shipment. Increase
            the shipment visibility by assigning it to multiple carriers from the LaneAxis Network or alternatively reach
            out to the Assigned carriers in your Network.</p>
        </div>
      </div>
    </div>
  </div>
  <div class="status_history skelaton_loader" *ngIf="skeletonLoaderhistory">
    <div class="event_time_skt" *ngFor="let fake of generateFake(5)">
      <ngx-skeleton-loader count="1" [theme]="{ width: '100%', height: '42px'}" class="loader_mb_0"></ngx-skeleton-loader>
    </div>
  </div>