import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { Loader } from '@googlemaps/js-api-loader';
import { ImageCropperModule } from 'ngx-image-cropper';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxLoadingModule } from 'ngx-loading';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { StripeAccountCreateService } from '../commons/service/stripeAccountCreate.service ';
import { MaterialModule } from '../material.module';
import { SharedModule } from '../shared/shared.module';
import { BeforeUserLoginNavbarComponent } from './before-user-login-navbar/before-user-login-navbar.component';
import { BidHistoryComponent } from './bid-history/bid-history.component';
import { DisputePopupComponent } from './dispute-popup/dispute-popup.component';
import { DocumentMultipleComponent } from './document-multiple/document-multiple.component';
import { DocumentPopupComponent } from './document-popup/document-popup.component';
import { DriverDetailComponent } from './driver-detail/driver-detail.component';
import { LaneCardComponent } from './lane-card/lane-card.component';
import { LaneDetailComponent } from './lane-detail/lane-detail.component';
import { LaneSummaryComponent } from './lane-summary/lane-summary.component';
import { LoadDetailCommodityComponent } from './load-detail-commodity/load-detail-commodity.component';
import { LoadDetailComponent } from './load-detail/load-detail.component';
import { LoadEmptyScreenComponent } from './load-empty-screen/load-empty-screen.component';
import { LoadPopupComponent } from './load-popup/load-popup.component';
import { LoadSideComponent } from './load-side/load-side.component';
import { LoadkeletonCardComponent } from './load-skeleton-card/load-skeleton-card.component';
import { LoadSummaryComponent } from './load-summary/load-summary.component';
import { LoadTabComponent } from './load-tab/load-tab.component';
import { LoadViewFilterComponent } from './load-view-filter/load-view-filter.component';
import { LoadViewLoaderComponent } from './load-view-loader/load-view-loader.component';
import { LoadViewTopSectionComponent } from './load-view-top-section/load-view-top-section.component';
import { MapBoxSummaryComponent } from './mapbox-summary/mapbox-summary.component';
import { ProgressLoadComponent } from './progress-load/progress-load.component';
import { RoutePlanComponent } from './route-plan/route-plan.component';
import { ShipmentCreatedByMeCardComponent } from './shipment-created-by-me-card/shipment-created-by-me-card.component';
import { ShipmentCreatedByMeSidePanelComponent } from './shipment-created-by-me-side-panel/shipment-created-by-me-side-panel.component';
import { SpotActiveLoadsCardComponent } from './spot-active-loads-card/spot-active-loads-card.component';
import { SpotLoadsCardComponent } from './spot-loads-card/spot-loads-card.component';
import { SpotLoadsSidePanelComponent } from './spot-loads-side-panel/spot-loads-side-panel.component';
import { TripHistoryComponent } from './trip-history/trip-history.component';
import { WaypointDetailComponent } from './waypoint-detail/waypoint-detail.component';

@NgModule({
  imports: [
    CommonModule,
    NgxLoadingModule.forRoot({}),
    NgxSkeletonLoaderModule.forRoot({}),
    MaterialModule,
    RouterModule,
    SharedModule,
    ImageCropperModule,
    FormsModule,
    DragDropModule,
    InfiniteScrollModule,
    NgxMatSelectSearchModule,
    MatInputModule,
    ReactiveFormsModule,
    NgxGpAutocompleteModule 
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyAMMBwZYg03hSwJEnODnIWf14YJKjWz_2A',
    //   libraries: ['places'],
    // }),
  ],
  declarations: [
    LoadEmptyScreenComponent,
    LoadkeletonCardComponent,
    SpotLoadsCardComponent,
    SpotActiveLoadsCardComponent,
    SpotLoadsSidePanelComponent,
    ShipmentCreatedByMeCardComponent,
    ShipmentCreatedByMeSidePanelComponent,
    BeforeUserLoginNavbarComponent,
    LoadSummaryComponent,
    LoadDetailComponent,
    LoadDetailCommodityComponent,
    RoutePlanComponent,
    DriverDetailComponent,
    LoadViewTopSectionComponent,
    ProgressLoadComponent,
    LoadSideComponent,
    LoadTabComponent,
    LoadViewLoaderComponent,
    LaneCardComponent,
    LaneSummaryComponent,
    LaneDetailComponent,
    DisputePopupComponent,
    BidHistoryComponent,
    DocumentMultipleComponent,
    DocumentPopupComponent,
    MapBoxSummaryComponent,
    LoadPopupComponent,
    LoadViewFilterComponent,
    TripHistoryComponent,
    WaypointDetailComponent,
  ],
  // entryComponents: [
  //   ProgressLoadComponent,
  //   LoadDetailCommodityComponent,
  //   DisputePopupComponent,
  //   DocumentMultipleComponent,
  //   DocumentPopupComponent,
  //   LoadPopupComponent,
  //   TripHistoryComponent
  // ],
  providers: [StripeAccountCreateService,  { provide: Loader,useValue: new Loader({
    apiKey: 'AIzaSyAMMBwZYg03hSwJEnODnIWf14YJKjWz_2A',
    libraries: ['places']
  }) }],
  exports: [
    LoadEmptyScreenComponent,
    LoadkeletonCardComponent,
    SpotLoadsCardComponent,
    SpotActiveLoadsCardComponent,
    SpotLoadsSidePanelComponent,
    ShipmentCreatedByMeCardComponent,
    ShipmentCreatedByMeSidePanelComponent,
    BeforeUserLoginNavbarComponent,
    LoadSummaryComponent,
    ProgressLoadComponent,
    LoadTabComponent,
    LoadViewTopSectionComponent,
    LoadSideComponent,
    LoadViewLoaderComponent,
    LoadDetailComponent,
    RoutePlanComponent,
    LoadDetailCommodityComponent,
    LaneCardComponent,
    LaneDetailComponent,
    LaneSummaryComponent,
    DriverDetailComponent,
    DisputePopupComponent,
    BidHistoryComponent,
    DocumentMultipleComponent,
    DocumentPopupComponent,
    MapBoxSummaryComponent,
    LoadPopupComponent,
    LoadViewFilterComponent,
    TripHistoryComponent,
    WaypointDetailComponent,
    DragDropModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class LoadsSharedModule { }
