<div *ngIf="data.openPop ==='multipleDoc'">
	<form [formGroup]="addDocUpload" (ngSubmit)="uploadDocumentMulti(addDocUpload)" class="mb-0">
	  <div class="header_popup">
		<p class="fs_20 fsb_6 txt_b mb-0 line_before">Upload New Document</p>
		<span class="close_popup_Icon" (click)="onNoConfirmClick()">
		  <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
		</span>
	  </div>
	  <div class="">
		<p class="fs_14 fsn_4 txt_lb input_label">Document Name <span class="txt_d"> *</span></p>
		<mat-form-field appearance="none">
		  <input matInput type="text" formControlName="description" placeholder="Enter document name" class="form-control custom_input" />
		  <mat-error class="error_mess" *ngIf="documentSubmmited && addDocUpload.get('description').errors?.['required']">Name is required.</mat-error>
		</mat-form-field>
	  </div>
	  <div class="">
		<p class="fs_14 fsn_4 txt_lb input_label">Upload Document/Image<span class="txt_d"> *</span></p>
		<div class="upload_document">
		  <mat-form-field appearance="none" class="custom_matSuffix_icon">
			<input matInput type="text" formControlName="document" value="{{name}}" placeholder="Browse image" class="form-control custom_input" readonly="readonly">
			<mat-error class="mt-1 text_truncate error_mess" *ngIf="documentSubmmited && addDocUpload.get('document').errors?.['required']">Document is required</mat-error>
			<span class="material-iconsicon">
			  <input class="inputfile" id="file" name="file" type="file" (change)="onSelectedFile($event)" style="display: none;">
			  <label class="btn btn-primary py-0 mb-3" for="file" style="background: #f5f5f5; border-radius: 0 4px 4px 0;">
				<i class="fa-solid fa-upload fs_16 txt_lb"></i>
			  </label>
			</span>
		  </mat-form-field>
		</div>
	  </div>
	  <div class="form_action">
		<span class="fs_16 fsm_5 txt_lb pointer px-4 py-3 me-4" (click)="onNoConfirmClick()">Cancel</span>
		<button type="submit" [disabled]="disbu" class="btn custum_button button_success fs_16 fsm_5">Add Document</button>
	  </div>
	</form>
  </div>
<div *ngIf="data.openPop ==='shipperDocEdit'">
	<form [formGroup]="editDocUpload" (ngSubmit)="uploadEditDocumentMulti(editDocUpload)" class="mb-0">
	  <div class="header_popup">
		<p class="fs_20 fsb_6 txt_b mb-0 line_before">Edit Document Name</p>
		<span class="close_popup_Icon" (click)="onNoConfirmClick()">
		  <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
		</span>
	  </div>
	  <div class="">
		<p class="fs_14 fsn_4 txt_lb input_label">Document Name <span class="txt_d"> *</span></p>
		<mat-form-field appearance="none">
		  <input matInput type="text" formControlName="description" placeholder="Enter document name" class="form-control custom_input" />
		  <mat-error class="error_mess" *ngIf="documentSubmmited &&  editDocUpload.get('description').errors?.['required']">Name is required.
		  </mat-error>
		</mat-form-field>
	  </div>
	  <div class="btn_action">
		<span class="fs_16 fsm_5 txt_lb pointer px-4 py-3 me-4" (click)="onNoConfirmClick()">Cancel</span>
		<button type="submit" class="btn custum_button button_primary btn_primary fs_16 fsm_5" [disabled]="disbu">Edit Name</button>
	  </div>
	</form>
  </div>

  <div class="" *ngIf="data.openPop ==='remove_document'">
	<div class="header_popup">
	  <p class="fs_20 fsb_6 txt_b line_before">{{data.title}}</p>
	  <span class="close_popup_Icon" (click)="closePopup()">
		<img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
	  </span>
	</div>
	<div class="v_scrollBar pop_vh">
	  <p class="fs_16 fsn_4 mb-3">{{data.contain}}</p>
	</div>
	<div class="form_action">
	  <span class="fs_16 fsm_5 txt_lb pointer px-4 py-3 me-4" (click)="closePopup()">Cancel</span>
	  <button type="button" class="btn custum_button button_success fs_16 fsm_5" (click)="donePopup()">OK</button>
	</div>
  </div>
  <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px',fullScreenBackdrop:true }"></ngx-loading>