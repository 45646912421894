<div *ngIf="data.openPop ==='waypointContinuesStope'">
   <div class="header_popup">
      <p class="fs_20 fsb_6 txt_b line_before ">Waypoint Information</p>
      <span class="close_popup_Icon" (click)="onNoClick()">
         <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
      </span>
   </div>
   <div class="text-center">
      <p class="fs_16 fsn_4 txt_lb mb_24">Do you want to finish adding the waypoints?</p>
      <div class="btn_action">
         <button type="button" class="btn custum_button button_primary btn_primary mb_16"
            (click)="closPopupWaypoint('Yes')">Yes, I’ve done adding waypoints</button>
         <span class="btn_trans txt_lb pointer " (click)="closPopupWaypoint('No')">No, keep adding
            Waypoints</span>
      </div>
   </div>
</div>

<div *ngIf="data.openPop ==='waypointInform'">
   <div class="header_popup">
      <p class="fs_20 fsb_6 txt_b line_before ">Waypoint Information</p>
      <span class="close_popup_Icon" (click)="onNoClick()">
         <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
      </span>
   </div>
   <div class="text-center">
      <p class="fs_16 fsn_4 txt_lb mb_24" *ngIf="data.point=='1' || data.point=='2' || data.point=='4'">To “Save & Next”, there must be atleast minimum 1 Pickup and 1 Dropoff waypoints.</p>
      <p class="fs_16 fsn_4 txt_lb mb_24" *ngIf="data.point=='3'">To “Save & Next”, the last waypoint should be a dropoff waypoint.</p>
      <p class="fs_16 fsn_4 txt_lb mb_24" *ngIf="data.point=='5'">First should be pickup and last should be dropoff.</p>
      <p class="fs_16 fsn_4 txt_lb mb_24" *ngIf="data.point=='6'">To “Save”, changes the last waypoint should be a dropoff waypoint.</p>
      <p class="fs_16 fsn_4 txt_lb mb_24" *ngIf="data.point=='7' || data.point=='8'">To “Save & Next”, the First waypoint should be a pickup waypoint and the last waypoint should be a dropoff waypoint.</p>
      <div class="btn_action">
         <button type="button" class="btn custum_button button_primary btn_primary mb_16" (click)="onNoClick()">OK</button>
      </div>
   </div>
</div>

<div class="rating_review_popup ships_popup" *ngIf="data.openPop==='showReviews'" id="rating_review_popup_one">
	<div class="header_popup">
		<span class="close_popup_Icon" (click)="onClose()">
			<img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
		</span>
	</div>
	<div class="rating_pro_head mb-2">
      <div class="d-flex align-items-center">
         <div class="rph_img">
            <span *ngIf="data?.shipmentDetail?.drivers?.profileImage && data?.ratingOwnerDetail?.key === 'driverId'">
               <img [src]="data?.shipmentDetail?.drivers?.profileImage" class="initial_name initial_r_64">
            </span>
            <span *ngIf="data?.shipmentDetail?.shippers?.profileImage && data?.ratingOwnerDetail?.key === 'shipperId'">
               <img [src]="data?.shipmentDetail?.shippers?.profileImage" class="initial_name initial_r_64">
            </span>
            <span *ngIf="data?.shipmentDetail?.carriers?.profileImage && data?.ratingOwnerDetail?.key === 'carrierId'">
               <img [src]="data?.shipmentDetail?.carriers?.profileImage" class="initial_name initial_r_46">
            </span>
            <span *ngIf="data?.shipmentDetail?.profileImage">
               <img [src]="data?.shipmentDetail?.profileImage" class="initial_name initial_r_46">
            </span>
            <span
               *ngIf="data?.shipmentDetail?.shippers?.companyName && !data?.shipmentDetail?.shippers?.profileImage && data?.ratingOwnerDetail?.key === 'shipperId'"
               class="initial_name initial_r_64 ">{{data?.shipmentDetail?.shippers?.companyName | acronymOfName}}</span>
            <span
               *ngIf="data?.shipmentDetail?.carriers?.legalName && !data?.shipmentDetail?.carriers?.profileImage && data?.ratingOwnerDetail?.key === 'carrierId'"
               class="initial_name initial_r_64 ">{{data?.shipmentDetail?.carriers?.legalName | acronymOfName}}</span>
            <span
               *ngIf="data?.shipmentDetail?.drivers?.name && !data?.shipmentDetail?.drivers?.profileImage && data?.ratingOwnerDetail?.key === 'driverId'"
               class="initial_name initial_r_64 ">{{data?.shipmentDetail?.drivers?.name | acronymOfName}}</span>
            <span
               *ngIf="data?.shipmentDetail?.legalName && !data?.shipmentDetail?.profileImage && data?.ratingOwnerDetail?.key === 'carrierId'"
               class="initial_name initial_r_64 ">{{data?.shipmentDetail?.legalName | acronymOfName}}</span>
            <span
               *ngIf="data?.shipmentDetail?.companyName && !data?.shipmentDetail?.profileImage && data?.ratingOwnerDetail?.key === 'shipperId'"
               class="initial_name initial_r_64 ">{{data?.shipmentDetail?.companyName | acronymOfName}}</span>
         </div>
         
	<div class="">
      <div class="rph_name">
         <p class="fs_14 fsm_5 txt_b mb-0"
            *ngIf="data?.shipmentDetail?.drivers?.name && data?.ratingOwnerDetail?.key === 'driverId'">
            {{data?.shipmentDetail?.drivers?.name}}</p>
         <p class="fs_14 fsm_5 txt_b mb-0"
            *ngIf="data?.shipmentDetail?.name && data?.ratingOwnerDetail?.key === 'driverId'">
            {{data?.shipmentDetail?.name}}</p>
         <p class="fs_14 fsm_5 txt_b mb-0"
            *ngIf="data?.shipmentDetail?.shippers?.companyName && data?.ratingOwnerDetail?.key === 'shipperId'">
            {{data?.shipmentDetail?.shippers?.companyName}}</p>
         <p class="fs_14 fsm_5 txt_b mb-0"
            *ngIf="data?.shipmentDetail?.carriers?.legalName && data?.ratingOwnerDetail?.key === 'carrierId'">
            {{data?.shipmentDetail?.carriers?.legalName}}</p>
         <p class="fs_14 fsm_5 txt_b mb-0"
            *ngIf="data?.shipmentDetail?.legalName && data?.ratingOwnerDetail?.key === 'carrierId'">
            {{data?.shipmentDetail?.legalName}}</p>
         <p class="fs_14 fsm_5 txt_b mb-0"
            *ngIf="data?.shipmentDetail?.companyName && data?.ratingOwnerDetail?.key === 'shipperId'">
            {{data?.shipmentDetail?.companyName}}</p>
      </div>
		<div class="tolt_rvw_rtg">
			<div class="rating_box">
				<div class="rating_stars rating_one">
					<app-rating-view [rate]="rateList?.totalRating" [ratingOwnerDetail]="ratingOwnerDetail" [type]="'listing'" *ngIf="rateList?.totalRating" [totalReviewForPopup]="totalReviewForPopup"></app-rating-view>
				</div>
			</div>
         <span class="rtn_no">{{rateList?.totalRating}} out of 5</span>
		</div>
      <p class="rvw_no" *ngIf="rateList?.totalRecords > 1">{{rateList?.totalRecords}} Reviews</p>
			<p class="rvw_no" *ngIf="rateList?.totalRecords == 1">{{rateList?.totalRecords}} Review</p>
	</div>
      </div>
   <div class="d-flex align-items-center" (click)="reviewAndRating()" *ngIf="(data?.shipmentDetail?.ratingReview) && (data?.shipmentDetail?.statusSlug==='delivered' || data?.shipmentDetail?.statusSlug==='completed') && (data?.shipmentDetail?.ratingReview?.shipperFinalRating === null)">
      <span class="edit_icon_write_review">
         <img src="{{serverPathForUrl}}/portal/icon/ic_edit_white.svg"
            class="img-fluid">
      </span>
      <u class="fs_12 txt_bf fsm_5 write_review_btn">Write a Review</u>
   </div>
	</div>

	<div class="sorting_box">
		<p class="sort_tlt me-2">Sort by</p>
      <div class="accordion ratting_dropdown" id="accordionExample">
         <div class="accordion-item">
           <h2 class="accordion-header" id="headingOne">
             <button class="accordion-button" type="button" data-bs-toggle="collapse" (click)="ratingDropdownFun()" [ngClass]="!ratingDropList ? 'collapsed' : ''" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
               {{ratingDropdownValue}}
             </button>
           </h2>
           <div id="collapseOne" *ngIf="ratingDropList" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
             <div class="accordion-body">
               <div class="sorting_btns" *ngIf="!skeletonLoader">
                  <span (click)="ratingTab('Relavent')" [ngClass]="currentStatus === 'relavent' ? 'active_sortg' : ''"
                     class="rvw_srtg_btn">Relevant</span>
                  <span (click)="ratingTab('Most Recent')" [ngClass]="currentStatus === 'mostRecent' ? 'active_sortg' : ''"
                     class="rvw_srtg_btn">Most Recent</span>
                  <span (click)="ratingTab('Highest')" [ngClass]="currentStatus === 'highest' ? 'active_sortg' : ''"
                     class="rvw_srtg_btn">Highest</span>
                  <span (click)="ratingTab('Lowest')" [ngClass]="currentStatus === 'lowest' ? 'active_sortg' : ''"
                     class="rvw_srtg_btn">Lowest</span>
               </div>
             </div>
           </div>
         </div>
       </div>
	</div>
	<div class="v_scrollBar review_scroll"  *ngIf="!skeletonLoader">
		<div class="revw_with_ratg" *ngFor="let item of rateList?.rating">
			<div class="rwr_l">
				<div class="rwr_l_img">
					<span *ngIf="item?.carrier?.profileImage">
						<img [src]="item?.carrier?.profileImage" class="initial_name initial_r_46">
					</span>
					<span *ngIf="item?.shipper?.profileImage">
						<img [src]="item?.shipper?.profileImage" class="initial_name initial_r_46">
					</span>
					<span *ngIf="item?.driver?.profileImage">
						<img [src]="item?.driver?.profileImage" class="initial_name initial_r_46">
					</span>
					<span *ngIf="item?.shipper?.companyName && !item?.shipper?.profileImage"
						class="initial_name initial_c_46 ">{{item?.shipper?.companyName | acronymOfName}}</span>
					<span *ngIf="item?.carrier?.legalName && !item?.carrier?.profileImage"
						class="initial_name initial_c_46 ">{{item?.carrier?.legalName | acronymOfName}}</span>
				</div>
			</div>
			<div class="rwr_r">
				<p class="revw_name" *ngIf="item?.shipper?.companyName">{{item?.shipper?.companyName}}</p>
				<p class="revw_name" *ngIf="item?.carrier?.legalName">{{item?.carrier?.legalName}}</p>
				<div class="rating_box">
					<div class="rating_stars">
						<app-rating-view [rate]="item?.rating" [type]="'listing'"
							[ratingOwnerDetail]="ratingOwnerDetail" *ngIf="item?.rating"></app-rating-view>
					</div>
				</div>
				<div class="star_box"></div>
				<p *ngIf="item?.shipperNote && data?.ratingOwnerDetail?.ratingPlace !== 'network' && data?.ratingOwnerDetail?.ratingPlace !== 'driverDetail' && (userType === 'CARRIER' || userType === 'DRIVER')" class="fs_14 txt_lb fsn_4 mb-0 revw_txt">{{item?.shipperNote}}</p>
				<p *ngIf="item?.carrierNote && data?.ratingOwnerDetail?.ratingPlace !== 'network' && data?.ratingOwnerDetail?.ratingPlace !== 'driverDetail' && userType === 'SHIPPER'" class="fs_14 txt_lb fsn_4 mb-0 revw_txt">{{item?.carrierNote}}</p>

				<p *ngIf="item?.shipperNote && data?.ratingOwnerDetail?.ratingPlace === 'network' && data?.ratingOwnerDetail?.ratingPlace !== 'driverDetail' && userType === 'SHIPPER'" class="fs_14 txt_lb fsn_4 mb-0 revw_txt">{{item?.shipperNote}}</p>
				<p *ngIf="item?.carrierNote && data?.ratingOwnerDetail?.ratingPlace === 'network' && data?.ratingOwnerDetail?.ratingPlace !== 'driverDetail' && (userType === 'CARRIER' || userType === 'DRIVER')" class="fs_14 txt_lb fsn_4 mb-0 revw_txt">{{item?.carrierNote}}</p>
			
				<p *ngIf="item?.shipperNote && data?.ratingOwnerDetail?.ratingPlace === 'driverDetail' && data?.ratingOwnerDetail?.ratingPlace !== 'network' && (userType === 'CARRIER' || userType === 'DRIVER' || userType === 'SHIPPER')" class="fs_14 txt_lb fsn_4 mb-0 revw_txt">{{item?.shipperNote}}</p>
			</div>
		</div>
	</div>

	<div class="rtg_rvw_skt" *ngIf="skeletonLoader">
		<div class="sorting_box">
			<ngx-skeleton-loader count="1" [theme]="{ width: '75%', height: '36px' }"
				class="loader_m_0 skt_btn_loader mb-2"></ngx-skeleton-loader>
		</div>
		<div class="raint_skt" *ngFor="let fake of generateFake(4)">
			<div class="rskt_l">
				<ngx-skeleton-loader appearance="circle" [theme]="{ width: '46px', height: '46px' }"
					class="loader_m_0 skt_btn_loader mb-2"></ngx-skeleton-loader>
			</div>
			<div class="rskt_r">
				<ngx-skeleton-loader count="4" [theme]="{ width: '100%', height: '14px' }"
					class="loader_m_0 skt_btn_loader mb-2"></ngx-skeleton-loader>
			</div>
		</div>
	</div>
</div>

<div *ngIf="data.openPop ==='removeLocation'">
   <div class="header_popup">
      <p class="fs_20 fsb_6 txt_b line_before ">Delete {{(data?.type| titlecase) +' Waypoint '+data?.locationOrder}}</p>
      <span class="close_popup_Icon" (click)="onNoClick()">
         <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
      </span>
   </div>
   <div class="text-center">
      <p class="fs_16 fsn_4 txt_lb mb_24">Are you sure you want to delete this waypoint?</p>
      <div class="btn_action">
         <button type="button" class="btn custum_button button_primary btn_primary mb_16"
            (click)="removeCardLocation(data?.index)">Yes</button>
         <span class="btn_trans txt_lb pointer " (click)="onNoClick()">Cancel</span>
      </div>
   </div>
</div>

<div *ngIf="data.openPop ==='deleteMultiStop'">
   <div class="header_popup">
      <p class="fs_20 fsb_6 txt_b line_before ">Delete Waypoint</p>
      <span class="close_popup_Icon" (click)="onNoClick()">
         <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
      </span>
   </div>
   <div class="text-center">
      <p class="fs_16 fsn_4 txt_lb mb_24">Are you sure you want to delete this Waypoint?</p>
      <div class="btn_action">
         <button type="button" class="btn custum_button button_primary btn_primary mb_16"
            (click)="routeDelete(data?.index)">Yes</button>
         <span class="btn_trans txt_lb pointer " (click)="onNoClick()">Cancel</span>
      </div>
   </div>
</div>

<div *ngIf="data?.openPop ==='dragAndDropAlert'">
   <div class="header_popup">
      <p class="fs_20 fsb_6 txt_b line_before ">Route Plan Alert</p>
      <span class="close_popup_Icon" (click)="onNoClick()">
         <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
      </span>
   </div>
   <div class="text-center">
      <p class="fs_16 fsn_4 txt_lb mb_24" *ngIf="data?.type==='pickup'">To “Save Route”, the
         first waypoint should be a pickup waypoint.</p>
      <p class=" fs_16 fsn_4 txt_lb mb_24" *ngIf="data?.type==='dropoff'">To “Save Route”, the
         last waypoint should be a dropoff waypoint.</p>
      <p class=" fs_16 fsn_4 txt_lb mb_24" *ngIf="data?.type==='pickup & dropoff'">To “Save Route”, the first waypoint
         should be pickup waypoint and the last waypoint should be dropoff waypoint. </p>
      <div class=" btn_action">
         <button type="button" class="btn custum_button button_primary btn_primary mb_16"
            (click)="onNoClick()">OK</button>
      </div>
   </div>
</div>

<div *ngIf="data?.openPop ==='dragAndDropCompleteAlert'">
   <div class="header_popup">
      <p class="fs_20 fsb_6 txt_b line_before ">Route Plan Alert</p>
      <span class="close_popup_Icon" (click)="onNoClick()">
         <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
      </span>
   </div>
   <div class="text-center">
      <p class="fs_16 fsn_4 txt_lb mb_24">Completed Waypoint cannot be moved.</p>
      <div class="btn_action">
         <button type="button" class="btn custum_button button_primary btn_primary mb_16"
            (click)="onNoClick()">OK</button>
      </div>
   </div>
</div>

<div *ngIf="data?.openPop ==='pickAndDropAlert'">
   <div class="header_popup">
      <p class="fs_20 fsb_6 txt_b line_before ">Route Plan Alert</p>
      <span class="close_popup_Icon" (click)="onNoClick()">
         <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
      </span>
   </div>
   <div class="text-center">
      <p class="fs_16 fsn_4 txt_lb mb_24">To “Save Route” There must be atleast minimum 1 Pickup and 1 Dropoff Waypoint.
      </p>
      <div class="btn_action">
         <button type="button" class="btn custum_button button_primary btn_primary mb_16"
            (click)="onNoClick()">OK</button>
      </div>
   </div>
</div>

<div *ngIf="data?.openPop ==='routePlaneGuardAlert'">
   <div class="header_popup">
      <p class="fs_20 fsb_6 txt_b line_before ">Route Plan Alert</p>
      <span class="close_popup_Icon" (click)="onNoClick()">
         <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
      </span>
   </div>
   <div class="text-center">
      <p class="fs_16 fsn_4 txt_lb mb_24">'WARNING: You have unsaved changes. press Cancel and save these changes, or
         press OK to leave this page and discard your changes.'</p>
      <div class="btn_action">
         <button type="button" class="btn custum_button button_primary btn_primary mb_16"
            (click)="onNoClick()">OK</button>
         <span class="btn_trans txt_lb pointer " (click)="onNoClick()">Cancle</span>
      </div>
   </div>
</div>

<div *ngIf="data?.openPop ==='regionAlert'">
   <div class="header_popup">
      <p class="fs_20 fsb_6 txt_b line_before ">Region Alert</p>
      <span class="close_popup_Icon" (click)="onNoClick()">
         <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
      </span>
   </div>
   <div class="text-center">
      <p class="fs_16 fsn_4 txt_lb mb_24">WARNING: You have unsaved changes, press Cancel and save these changes, or
         press OK to leave this page and discard your changes.</p>
      <div class="btn_action">
         <button type="button" class="btn custum_button button_primary btn_primary mb_16"
            (click)="onNoClickRegion('ok')">OK</button>
         <span class="btn_trans txt_lb pointer " (click)="onNoClickRegion('cancel')">Cancel</span>
      </div>
   </div>
</div>

<div *ngIf="data?.openPop ==='alertForCheckBox'">
   <div class="header_popup">
      <p class="fs_20 fsb_6 txt_b line_before ">Alert</p>
      <span class="close_popup_Icon" (click)="onNoClick()">
         <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
      </span>
   </div>
   <div class="text-center">
        
      <p class="fs_16 fsn_4 txt_lb mb_24">You will loose your current selection by navigating away from the section. Do you want to continue?</p>
      <div class="btn_action">
         <button type="button" class="btn custum_button button_primary btn_primary mb_16"
            (click)="onNoClickRegion('ok')">OK</button>
         <span class="btn_trans txt_lb pointer " (click)="onNoClickRegion('cancel')">Stay on this page.</span>
      </div>
   </div>
</div>

<!-- Edit Play Your Route -->
<div class="edit_Multi_Stop_form" *ngIf="data.openPop ==='editMultiStop'">
   <form class="mb-0 custum_input_css inputInPopup" [formGroup]="wayPointEditForm"
      (ngSubmit)="wayPointEditFormSubmit(wayPointEditForm)" autocomplete="off">
      <div class="add_storage_con">
         <div class="header_popup">
            <p class="fs_20 fsb_6 txt_b line_before">
               <span class="">Edit Waypoint Details </span>
               <span class="fs_16 fsm_5 txt_lb" style="margin: 0 0 0 6px;"> ({{data?.type | titlecase}} Waypoint
                  )</span>
            </p>
            <span class="close_popup_Icon" (click)="onNoClick()">
               <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
            </span>
         </div>
         <div class="cc_head_r custom_redio_btn" *ngIf="data?.index !== 0 && data?.index !== data?.waypointLength-1">
            <mat-radio-group formControlName="wayPointType" aria-label="Select an option"
               (change)="changeLocationType($event)">
               <mat-radio-button value="pickup"><span class="fs_16 fsm_5 ">Pickup</span></mat-radio-button>
               <mat-radio-button value="dropoff" class="ml_24"><span
                     class="fs_16 fsm_5 ">Dropoff</span></mat-radio-button>
            </mat-radio-group>
         </div>

         <div class="v_scrollBar popup_vh" style="margin: 0 -24px; padding: 0 24px;">
            <div class="row">
               <div class="col-md-3 ic_dropdown">
                  <p class="fs_14 fsn_4 txt_lb mb-1">Business Name </p>
                  <mat-form-field appearance="none">
                     <input matInput type="text" formControlName="pickupBussinessName" placeholder="Enter Business Name"
                        maxlength="65" class="form-control custom_input" />
                     <mat-error *ngIf="wayPointEditForm.get('pickupBussinessName').errors?.['maxlength']"
                        class="error_mess">Only 64 characters are allowed.</mat-error>
                  </mat-form-field>
               </div>
               <div class="col-md-3">
                  
                  <p class="fs_14 fsn_4 txt_lb mb-1">Waypoint Location<span class="txt_d">*</span></p>
                  <div fxFlex fxFlexAlign="center" class="autocomplete-container">
                     <mat-form-field appearance="none">
                        <input
                           matTooltip="Network Carriers will only see pickup and dropoff cities, but exact addresses will only be shown to Carrier who is assigned the load."
                           matInput ngx-gp-autocomplete (onAddressChange)="pickAutoCompleteSelected($event)"
                           (input)="noAutoselection('pick')" (focusout)="noFocusOut('pick')"
                           
                           [value]=" wayPointEditForm.get('pickupLocation').value" formControlName="pickupLocation"
                           maxlength="257" placeholder="Enter Exact Address"
                           class="form-control custom_input text_truncate controls" [options]="searchRestriction"
                           required />
                        <mat-error *ngIf="!pickAutoNot && wayPointEditForm.get('pickupLocation').errors?.['required']"
                           class="error_mess">Pickup Location is required.</mat-error>
                        <span *ngIf="pickAutoNot" class="error_mess">Pickup Location is required.</span>
                        <mat-error *ngIf="!pickAutoNot && wayPointEditForm.get('pickupLocation').errors?.['pattern']"
                           class="error_mess">First letter should not be space.</mat-error>
                        <small class="city_labl_address error_mess"
                           *ngIf="sameLocation && wayPointEditForm.value.pickupLocation">Pickup & dropoff location
                           should
                           not be same.</small>
                        <small class="city_labl_address error_mess"
                           *ngIf="pickupCityError && wayPointEditForm.value.pickupLocation">{{ pickupCityError
                           }}</small>
                        <small class="city_labl_address error_mess"
                           *ngIf="shortNameValue && wayPointEditForm.value.pickupLocation">{{ shortNameError }}</small>
                        <mat-error *ngIf="!pickAutoNot && wayPointEditForm.get('pickupLocation').errors?.['maxlength']"
                           class="error_mess">Only 256 characters are allowed.</mat-error>
                     </mat-form-field>
                  </div>
               </div>
               <div class="col-md-3">
                  <p class="fs_14 fsn_4 txt_lb mb-1">ZIP Code <span class="txt_d"> *</span></p>
                  <mat-form-field appearance="none">
                     <input matInput type="text" formControlName="pickupPincode" placeholder="Enter ZIP Code" class="form-control custom_input" minlength="4" maxlength="8" />
                     <mat-error *ngIf="wayPointEditForm.get('pickupPincode').errors?.['required']" class="error_mess">ZIP Code is required.</mat-error>
                   
                     <mat-error class="error_mess" *ngIf="(wayPointEditForm.get('pickupPincode').errors?.['minlength']) && !(wayPointEditForm.get('pickupPincode').errors?.['pattern'])">ZIP Code should be minimum 4 digit.</mat-error>
                     <mat-error *ngIf="wayPointEditForm.get('pickupPincode').errors?.['pattern']" class="error_mess">{{zipCodeErrorMsg}}</mat-error>
                  </mat-form-field>
               </div>
               <div class="col-md-3 ic_calander">
                  <p class="fs_14 fsn_4 txt_lb mb-1">Date <span class="txt_d"> *</span>
                  </p>
                  <mat-form-field appearance="none" class="drop_bg cal_input">
                     <input matInput formControlName="pickupDate" [matDatepicker]="dp2" placeholder="Select Date"
                        [min]="minDate" [max]="maxDate" (dateChange)="pickDateEvent($event)" [readonly]="true"
                        class="form-control custom_input" />
                     <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
                     <mat-datepicker #dp2 disabled="false"></mat-datepicker>
                     <mat-error *ngIf="wayPointEditForm.get('pickupDate').errors?.['required']"
                        class="error_mess">Pickup Date is required.</mat-error>
                     <mat-error *ngIf="wayPointEditForm.get('pickupDate').errors?.['matDatepickerMin']"
                        class="error_mess">Pickup Date is Expired.</mat-error>
                  </mat-form-field>
               </div>
               <div class="col-md-3 ic_dropdown">
                  <p class="fs_14 fsn_4 txt_lb mb-1">Select Time <span class="txt_d"> *</span></p>
                  <mat-form-field appearance="none">
                     <mat-select (selectionChange)="timeRestriction($event)" placeholder="Select Time"
                        formControlName="pickupTime" name="pickupTime" id="pickupTime"
                        class="form-control custom_input">
                        <mat-option *ngFor="let timevalue of pickupTimeArr" [value]="timevalue"
                           [disabled]="timevalue.disabled">{{ timevalue.time }}</mat-option>
                     </mat-select>
                     <mat-error *ngIf="wayPointEditForm.get('pickupTime').errors?.['required']"
                        class="error_mess">Pickup Time is required.</mat-error>
                     <small *ngIf="isTimeSloteAvailble" class="error_mess">{{noTimeSloteError}}</small>
                     <small *ngIf="isTimeSloteExpired" class="error_mess">Time slot has been expired</small>
                  </mat-form-field>
               </div>
               <div class="col-md-3 ic_dropdown">
                  <p class="fs_14 fsn_4 txt_lb mb-1">Time Zone <span class="txt_d"> *</span> </p>
                  <mat-form-field appearance="none">
                     <mat-select formControlName="pickupTimeZone" name="pickupTimeZone" id="pickupTimeZone"
                        placeholder="Select Time Zone" class="form-control custom_input">
                        <mat-option *ngFor="let piTimeZone of pickupTZ" [value]="piTimeZone.tzShort"
                           matTooltip="{{ piTimeZone.tzLong }}">{{ piTimeZone.tzShort }}</mat-option>
                     </mat-select>
                     <mat-error *ngIf="wayPointEditForm?.get('pickupTimeZone').errors?.['required']"
                        class="error_mess">Pickup Time Zone is required.</mat-error>
                  </mat-form-field>
               </div>
               <div class="col-md-3">
                  <p class="fs_14 fsn_4 txt_lb mb-1">
                    <span>Contact Number</span>
                     <img [src]="defualtPickupCountryFlag" name="cla" class="ms-2 c_flag_img">
                  </p>
                  <div class="num_country_code ic_dropdown">
                     <mat-form-field appearance="none" class="frm_cunty_code">
                        <div class="country_code_fld">
                           <mat-select formControlName="pickupCountryCode" name="pickupCountryCode" id="pickupCountryCode" (selectionChange)="selectPickCountryCode($event)" class="form-control custom_input" placeholder="+1">
                              <mat-option *ngFor="let cou of countryList" [(value)]="cou.countryAbbr" class="checkbox_flag">
                                 <img [src]="cou.flag" name="cla" height="15px" width="20px">
                                 <span class="ms-2">+{{cou.countryCode}}</span>
                              </mat-option>
                           </mat-select>
                        </div>
                     </mat-form-field>

                     <mat-form-field appearance="none" class="frm_phn_no">
                        <div class="country_code_fld">
                           <input matInput type="text" formControlName="pickPhoneNumber"
                              placeholder="Enter Contact Number (no spaces)" class="form-control custom_input"
                              (input)="keyPaste($event)" (keypress)="keyPress($event)" maxlength="10" />
                          
                        </div>
                     </mat-form-field>
                     <mat-error *ngIf="wayPointEditForm.get('pickPhoneNumber').errors?.['required']"
                     class="error_mess">Pickup Contact Number is required.</mat-error>
                  <mat-error *ngIf="wayPointEditForm.get('pickPhoneNumber').errors?.['maxlength']"
                     class="error_mess">Contact Number should be 10 digit.</mat-error>
                  <mat-error
                     *ngIf="!wayPointEditForm.get('pickPhoneNumber').errors?.['pattern'] && wayPointEditForm.get('pickPhoneNumber').errors?.['minlength']"
                     class="error_mess">Contact Number should be 10 digit.</mat-error>
                  <mat-error *ngIf="wayPointEditForm.get('pickPhoneNumber').errors?.['pattern']"
                     class="error_mess">Please enter valid information.</mat-error>
                  </div>
               </div>
               <div class="col-md-3">
                  <p class="fs_14 fsn_4 txt_lb mb-1">Contact Name </p>
                  <mat-form-field appearance="none" class="input_suffix">
                     <input matInput type="text" formControlName="pickupContactName" placeholder="Enter Contact Name"
                        class="form-control custom_input" maxlength="65" />
                     <mat-error *ngIf="wayPointEditForm.get('pickupContactName').errors?.['maxlength']"
                        class="error_mess">Only 64 characters are allowed.</mat-error>
                  </mat-form-field>
               </div>
               <div class="col-md-12">
                  <p class="fs_14 fsn_4 txt_lb mb-1">
                     <span>Special Requirements </span>
                     <span>(516 characters)</span>
                  </p>
                  <mat-form-field appearance="none">
                     <textarea matInput type="text" formControlName="pickInstructions"
                        placeholder="Special Requirements" (keypress)="keyInstructionPress($event, 'pickup')"
                        class="form-control custom_input" maxlength="517" rows="3"></textarea>
                     <mat-error *ngIf="wayPointEditForm.get('pickInstructions').errors?.['required']"
                        class="error_mess">Pickup Instructions is required.</mat-error>
                     <mat-error *ngIf="wayPointEditForm.get('pickInstructions').errors?.['maxlength']">Only 516
                        characters
                        are allowed</mat-error>
                  </mat-form-field>
               </div>
            </div>
            <div class="sapretor_ln">
               <hr>
            </div>
            <div class="col-md-12" *ngIf="!ifPickupLoadInfo">
               <span type="button" class="add_goods" (click)="addLoadInformationForEdit()">
                  <span class="ms-1 ">Add Commodity</span>
                  <span class="material-icons txt_p fs_20 ms-1">add_circle</span>
               </span>
            </div>
            <div class="ship_goods_info" *ngIf="ifPickupLoadInfo">
               
               <div formArrayName="commodityForm">
                  <p class="fs_20 fsb_6 txt_b line_before  mb-4">Commodity</p>
                  <div class="sgi_row" *ngFor="let communityData of commodityForm.controls; let i = index"
                     [formGroupName]="i">
                     <span (click)="removeCommunityFieldForEdit(i)"
               class="material-icons txt_d fs_18 remove_row_icn">cancel</span>
                     <div class="row edit_popup_comodity">
                        <div class="dimension_txt">Dimensions</div>
                        <div class="col-md-3">
                           <p class="fs_14 fsn_4 txt_lb mb-1">Commodity <span class="txt_d"> *</span></p>
                           <mat-form-field appearance="none">
                              <input matInput type="text" formControlName="commodity" placeholder="Enter Commodity"
                                 maxlength="129" class="form-control custom_input" />
                              <mat-error
                                 *ngIf="communityDataSubmiited &&  communityData.get('commodity').errors?.['required']"
                                 class="error_mess">Commodity is required.</mat-error>
                              <mat-error *ngIf="communityData.get('commodity').errors?.['maxlength']"
                                 class="error_mess">Only 128 characters are allowed</mat-error>
                           </mat-form-field>
                        </div>
                        <div class="col-md-3 ic_dropdown">
                           <p class="fs_14 fsn_4 txt_lb mb-1">Packaging Type <span class="txt_d"> *</span></p>
                           <mat-form-field appearance="none">
                              <mat-select class="form-control custom_input" formControlName="packagingType"
                                 placeholder="Select Packaging Type">
                                 <mat-option value="Pallet">Pallet</mat-option>
                                 <mat-option value="Skid">Skid</mat-option>
                                 <mat-option value="Crate">Crate</mat-option>
                                 <mat-option value="Box">Box</mat-option>
                              </mat-select>
                              <mat-error
                                 *ngIf="communityDataSubmiited && communityData.get('packagingType').errors?.['required']"
                                 class="error_mess">Packaging type is required.</mat-error>
                           </mat-form-field>
                        </div>
                        <div class="col-md-3">
                           <p class="fs_14 fsn_4 txt_lb mb-1">Quantity <span class="txt_d"> *</span></p>
                           <mat-form-field appearance="none">
                              <input matInput type="text" formControlName="quantity" placeholder="Enter Quantity"
                                 maxlength="129" appZeroNotAllowFirst class="form-control custom_input" />
                              <mat-error
                                 *ngIf="communityDataSubmiited && communityData.get('quantity').errors?.['required']"
                                 class="error_mess">Quantity is required.</mat-error>
                           </mat-form-field>
                        </div>
                        <div class="col-md-3">
                           <p class="fs_12 fsn_4 txt_lb mb-1">Length</p>
                           <mat-form-field appearance="none">
                             <input  matInput type="text" appDimension formControlName="length"
                               placeholder="" class="form-control custom_input p_9" maxlength="3" appZeroNotAllowFirst (keyup)="dimensionCombiner(i)" />
                             <mat-error *ngIf="communityData.get('length').errors?.['required']"
                               class="error_mess">Please enter length</mat-error>
                           </mat-form-field>
                         </div>
                         <div class="col-md-3">
                           <p class="fs_12 fsn_4 txt_lb mb-1">Width</p>
                           <mat-form-field appearance="none">
                             <input  matInput type="text" appDimension formControlName="width"
                               placeholder="" class="form-control custom_input p_9" maxlength="3" appZeroNotAllowFirst (keyup)="dimensionCombiner(i)" />
                             <mat-error *ngIf="communityData.get('width').errors?.['required']"
                               class="error_mess">Please enter width</mat-error>
                           </mat-form-field>
                         </div>
                         <div class="col-md-3">
                           <p class="fs_12 fsn_4 txt_lb mb-1">Height</p>
                           <mat-form-field appearance="none">
                             <input  matInput type="text" appDimension formControlName="height"
                               placeholder="" class="form-control custom_input p_9" maxlength="3" appZeroNotAllowFirst (keyup)="dimensionCombiner(i)" />
                             <mat-error *ngIf="communityData.get('height').errors?.['required']"
                               class="error_mess">Please enter height</mat-error>
                           </mat-form-field>
                         </div>
                        <div class="col-md-3">
                           <p class="fs_14 fsn_4 txt_lb mb-1">Weight</p>
                           <mat-form-field appearance="none">
                              <input matInput type="text" formControlName="weight" (input)="keyPaste($event)" (keypress)="keyPress($event)" placeholder="Enter Weight"
                                 maxlength="6" class="form-control custom_input" />
                           </mat-form-field>
                        </div>
                        <div class="col-md-3">
                           <p class="fs_14 fsn_4 txt_lb mb-1">$ Value</p>
                           <mat-form-field appearance="none">
                              <input matInput type="text" formControlName="value" placeholder="Enter Value"
                                 maxlength="65" appZeroNotAllowFirst class="form-control custom_input" />
                           </mat-form-field>
                        </div>
                        <div class="col-md-3">
                           <p class="fs_14 fsn_4 txt_lb mb-1">SKU #</p>
                           <mat-form-field appearance="none">
                              <input matInput type="text" formControlName="sku" placeholder="Enter SKU" maxlength="15" class="form-control custom_input" />
                                 
                                  <mat-error *ngIf="!communityData.get('sku').errors?.['maxlength'] && communityData.get('sku').errors?.['pattern']" class="error_mess">Only alpha numeric are allowed.</mat-error>
                           </mat-form-field>
                        </div>

                        <div class="col-md-3 upload_document">
                           <p class="fs_14 fsn_4 txt_lb mb-1">Upload Image</p>
                           <mat-form-field appearance="none" class="custom_matSuffix_icon">
                              <input matInput type="text" placeholder="Choose File.."
                                 class="form-control custom_input text_truncate" id="media_{{i}}" readonly="readonly">
                              <span class="material-iconsicon">
                                 <input class="inputfile" #uploader id="file_{{i}}" name="file" type="file"
                                    style="display: none;" (change)="onSelectedUploadeImage($event,i)"
                                    accept=".png, .jpg, .jpeg">
                                 <label class="btn btn-primary" for="file_{{i}}">
                                    <i class="fa-solid fa-upload fs_16 txt_lb"></i>
                                 </label>
                              </span>
                           </mat-form-field>
                           <div *ngIf="currentIndex===i">
                              <span *ngIf="showErrorImage" class="fs_11 txt_d fsm_5 ps-1">{{showErrorImage}}</span>
                           </div>
                        </div>
                        <div class="col-md-3">
                           <p class="fs_14 fsn_4 mb-2 ope_0">QWE</p>
                           <div class="uploded_img_box" *ngIf="wayPointEditForm.controls.commodityForm.value[i].media">
                              <span class="material-icons txt_d remove_row_icn" (click)="removeMedia(i)">cancel</span>
                              <img src="{{wayPointEditForm.controls.commodityForm.value[i].media}}" class="img-fluid">
                           </div>
                           <div class="uploded_img_loader" *ngIf="currentIndex===i && !showErrorImage">
                              <img src="{{serverPathForUrl}}/portal/images/after-login/LaneAxis-loader.gif"
                                 class="img-fluid">
                           </div>
                        </div>

                        <div class="col-md-6 custom_checkbox mb-3">
                           <mat-checkbox class="form-control border-0 ps-0" formControlName="handleWithCare"
                              style="border: none;">Handle With Care</mat-checkbox>
                        </div>
                       
                     </div>
                  </div>
               </div>
               <span type="button" class="add_goods" (click)="addCommunityFieldForEdit()">
                  <span class="ms-1 ">Add Another Commodity</span>
                  <span class="material-icons txt_p fs_20 ms-1">add_circle</span>
               </span>
            </div>

         </div>
         <div class="btn_action">
            <button type="submit" class="btn custum_button button_primary btn_primary" [disabled]="disabledAssign"
               style="max-width: 190px;">Update Waypoint</button>
         </div>
      </div>
   </form>
</div>

<!-- Add another Pickup or Drop point -->
<div class="add_picku_dropoff_form" *ngIf="data.openPop ==='addMultiStopRoute'">
   <form class="mb-0 custum_input_css inputInPopup" [formGroup]="wayPointAddForm"
      (ngSubmit)="wayPointAddFormSubmit(wayPointAddForm)" autocomplete="off">
      <div class="add_storage_con">
         <div class="header_popup">
            <p class="fs_20 fsb_6 txt_b line_before ">Add Waypoint Details</p>

            <span class="close_popup_Icon" (click)="onNoClick()">
               <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
            </span>
         </div>
         <div class="v_scrollBar popup_vh" style="margin: 0 -24px; padding: 0 24px;">
            <div class="row">
               <div class="col-md-12">
                  <div class="cc_head_r custom_redio_btn">
                     <mat-radio-group formControlName="wayPointType" aria-label="Select an option"
                        (change)="changeLocationType($event)">
                        <mat-radio-button value="pickup"><span
                              class="fs_16 fsm_5 ">Pickup</span></mat-radio-button>
                        <mat-radio-button value="dropoff" class="ml_24"><span
                              class="fs_16 fsm_5 ">Dropoff</span></mat-radio-button>
                     </mat-radio-group>
                  </div>
               </div>
               <div class="col-md-3 ic_dropdown">
                  <p class="fs_14 fsn_4 txt_lb mb-1">Business Name </p>
                  <mat-form-field appearance="none">
                     <input matInput type="text" formControlName="pickupBussinessName" placeholder="Enter Business Name"
                        maxlength="65" class="form-control custom_input" />
                     <mat-error *ngIf="wayPointAddForm.get('pickupBussinessName').errors?.['maxlength']"
                        class="error_mess">Only 64 characters are allowed.</mat-error>
                  </mat-form-field>
               </div>
               <div class="col-md-3">
                  <p class="fs_14 fsn_4 txt_lb mb-1">Waypoint Location <span class="txt_d">
                        *</span></p>
                  <div fxFlex fxFlexAlign="center" class="autocomplete-container">
                     <mat-form-field appearance="none">
                        <input
                           matTooltip="Network Carriers will only see pickup and dropoff cities, but exact addresses will only be shown to Carrier who is assigned the load."
                           matInput ngx-gp-autocomplete (onAddressChange)="pickAutoCompleteSelected($event)"
                           (input)="noAutoselection('pick')" (focusout)="noFocusOut('pick')"
                           [value]=" wayPointAddForm.get('pickupLocation').value" formControlName="pickupLocation"
                           maxlength="257" placeholder="Enter Exact Address" class="form-control custom_input"
                           [options]="searchRestriction" required />
                        <mat-error *ngIf="!pickAutoNot && wayPointAddForm.get('pickupLocation').errors?.['required']"
                           class="error_mess">Pickup Location is required.</mat-error>
                        <span *ngIf="pickAutoNot" class="error_mess">Pickup Location is required.</span>
                        <mat-error *ngIf="!pickAutoNot && wayPointAddForm.get('pickupLocation').errors?.['pattern']"
                           class="error_mess">First letter should not be space.</mat-error>
                        <small class="city_labl_address error_mess"
                           *ngIf="sameLocation && wayPointAddForm.value.pickupLocation">Pickup & dropoff location should
                           not be same.</small>
                        <small class="city_labl_address error_mess"
                           *ngIf="pickupCityError && wayPointAddForm.value.pickupLocation">{{ pickupCityError }}</small>
                        <small class="city_labl_address error_mess"
                           *ngIf="shortNameValue && wayPointAddForm.value.pickupLocation">{{ shortNameError }}</small>
                        <mat-error *ngIf="!pickAutoNot && wayPointAddForm.get('pickupLocation').errors?.['maxlength']"
                           class="error_mess">Only 256 characters are allowed.</mat-error>
                     </mat-form-field>
                  </div>
               </div>
               <div class="col-md-3">
                  <p class="fs_14 fsn_4 txt_lb mb-1">ZIP Code <span class="txt_d"> *</span></p>
                  <mat-form-field appearance="none">
                     <input matInput type="text" formControlName="pickupPincode" placeholder="Enter ZIP Code" class="form-control custom_input" minlength="4" maxlength="8" />
                     <mat-error *ngIf="wayPointAddForm.get('pickupPincode').errors?.['required']" class="error_mess">ZIP Code is required.</mat-error>
                     <mat-error *ngIf="wayPointAddForm.get('pickupPincode').errors?.['serverError']" class="error_mess">{{wayPointAddForm.get('pickupPincode').errors?.['serverError'] }}</mat-error>
                     <mat-error class="error_mess" *ngIf="(wayPointAddForm.get('pickupPincode').errors?.['minlength']) && !(wayPointAddForm.get('pickupPincode').errors?.['pattern'])">ZIP Code should be minimum 4 digit.</mat-error>
                     <mat-error *ngIf="wayPointAddForm.get('pickupPincode').errors?.['pattern']" class="error_mess">{{zipCodeErrorMsg}}</mat-error>
                  </mat-form-field>
               </div>
               <div class="col-md-3 ic_calander">
                  <p class="fs_14 fsn_4 txt_lb mb-1">Date & Time <span class="txt_d"> *</span></p>
                  <mat-form-field appearance="none" class="drop_bg cal_input">
                     <input matInput formControlName="pickupDate" [matDatepicker]="dp2" placeholder="Select Date"
                        [min]="minDate" (dateChange)="pickDateEventForAddWaypoint($event)" [readonly]="true"
                        class="form-control custom_input" />
                     <mat-datepicker-toggle matSuffix [for]="dp2"></mat-datepicker-toggle>
                     <mat-datepicker #dp2 disabled="false"></mat-datepicker>
                     <mat-error *ngIf="wayPointAddForm.get('pickupDate').errors?.['required']" class="error_mess">Pickup
                        Date is required.</mat-error>
                  </mat-form-field>
               </div>
               <div class="col-md-3 ic_dropdown">
                  <p class="fs_14 fsn_4 txt_lb mb-1">Select Time <span class="txt_d"> *</span></p>
                  <mat-form-field appearance="none">
                     <mat-select (selectionChange)="timeRestriction($event)" placeholder="Select Time"
                        formControlName="pickupTime" name="pickupTime" id="pickupTime"
                        class="form-control custom_input">
                        <mat-option *ngFor="let timevalue of pickupTimeArr" [value]="timevalue"
                           [disabled]="timevalue.disabled">{{ timevalue.time }}</mat-option>
                     </mat-select>
                     <mat-error *ngIf="wayPointAddForm.get('pickupTime').errors?.['required']" class="error_mess">Pickup
                        Time is required.</mat-error>
                     <small *ngIf="isTimeSloteAvailble && wayPointAddForm.get('pickupDate').value" class="error_mess">{{noTimeSloteError}}</small>
                     <small *ngIf="isTimeSloteExpired" class="error_mess">Time slot has been expired</small>
                  </mat-form-field>
               </div>
               <div class="col-md-3 ic_dropdown">
                  <p class="fs_14 fsn_4 txt_lb  mb-1">Time Zone <span class="txt_d"> *</span> </p>
                  <mat-form-field appearance="none">
                     <mat-select formControlName="pickupTimeZone" [(ngModel)]="timeZoneSelectedPick"
                        name="pickupTimeZone" id="pickupTimeZone" placeholder="Select Time Zone"
                        class="form-control custom_input">
                        <mat-option *ngFor="let piTimeZone of pickupTZ" [value]="piTimeZone.tzShort"
                           matTooltip="{{ piTimeZone.tzLong }}"> {{ piTimeZone.tzShort }}</mat-option>
                     </mat-select>
                     <mat-error *ngIf="wayPointAddForm.get('pickupTimeZone').errors?.['required']"
                        class="error_mess">Pickup Time Zone is required.</mat-error>
                  </mat-form-field>
               </div>
               <div class="col-md-3">
                  <p class="fs_14 fsn_4 txt_lb mb-1">
                    <span>Contact Number</span>
                     <img [src]="defualtPickupCountryFlag" name="cla" class="ms-2 c_flag_img">
                  </p>
                  <div class="num_country_code ic_dropdown">
                     <mat-form-field appearance="none" class="frm_cunty_code">
                        <div class="country_code_fld">
                           <mat-select formControlName="pickupCountryCode" (selectionChange)="selectPickCountryCode($event)" class="form-control custom_input" placeholder="+1">
                              <mat-option *ngFor="let cou of countryList" [value]="cou.countryAbbr" class="checkbox_flag">
                                 <img [src]="cou.flag" name="cla" height="15px" width="20px">
                                 <span class="ms-2">+{{cou.countryCode}}</span>
                              </mat-option>
                           </mat-select>
                        </div>
                     </mat-form-field>
                     <mat-form-field appearance="none" class="frm_phn_no">
                        <div class="country_code_fld">
                           <input matInput type="text" formControlName="pickPhoneNumber"
                              placeholder="Enter Contact Number (no spaces)" class="form-control custom_input"
                              (input)="keyPaste($event)" (keypress)="keyPress($event)" maxlength="10" />
                           <mat-error *ngIf="wayPointAddForm.get('pickPhoneNumber').errors?.['required']"
                              class="error_mess"> Pickup Contact Number is required.</mat-error>
                           <mat-error *ngIf="wayPointAddForm.get('pickPhoneNumber').errors?.['maxlength']"
                              class="error_mess"> Contact Number should be 10 digit.</mat-error>
                           <mat-error
                              *ngIf="!wayPointAddForm.get('pickPhoneNumber').errors?.['pattern'] && wayPointAddForm.get('pickPhoneNumber').errors?.['minlength']"
                              class="error_mess">Contact Number should be 10 digit.</mat-error>
                           <mat-error *ngIf="wayPointAddForm.get('pickPhoneNumber').errors?.['pattern']"
                              class="error_mess"> Please enter valid information.</mat-error>
                        </div>
                     </mat-form-field>
                  </div>
               </div>
               <div class="col-md-3">
                  <p class="fs_14 fsn_4 txt_lb mb-1">Contact Name </p>
                  <mat-form-field appearance="none" class="input_suffix">
                     <input matInput type="text" [(ngModel)]="pickupContactName" formControlName="pickupContactName"
                        placeholder="Enter Contact Name" class="form-control custom_input" maxlength="65" />
                     <mat-error *ngIf="wayPointAddForm.get('pickupContactName').errors?.['maxlength']"
                        class="error_mess">Only 64 characters are allowed.</mat-error>
                  </mat-form-field>
               </div>
               <div class="col-md-12">
                  <p class="fs_14 fsn_4 txt_lb insraction_label mb-1">
                     <span>Special Requirements </span>
                     <span>(516 characters)</span>
                  </p>
                  <mat-form-field appearance="none">
                     <textarea matInput type="text" formControlName="pickInstructions"
                        placeholder="Special Requirements" (keypress)="keyInstructionPress($event, 'pickup')"
                        class="form-control custom_input" maxlength="517" rows="3"></textarea>
                     <mat-error *ngIf="wayPointAddForm.get('pickInstructions').errors?.['required']"
                        class="error_mess">Pickup Instructions is required.</mat-error>
                     <mat-error *ngIf="wayPointAddForm.get('pickInstructions').errors?.['maxlength']">Only 516
                        characters are allowed</mat-error>
                  </mat-form-field>
               </div>
            </div>
            <div class="sapretor_ln">
               <hr>
            </div>
            <div class="col-md-12" *ngIf="!ifPickupLoadInfo">
               <span type="button" class="add_goods" (click)="addLoadInformation()">
                  <span class="ms-1 ">Add Commodity</span>
                  <span class="material-icons txt_p fs_20 ms-1">add_circle</span>
               </span>
            </div>
            <div class="ship_goods_info" *ngIf="ifPickupLoadInfo">
               <div formArrayName="commodityForm">
                  <p class="fs_20 fsb_6 txt_b line_before  mb-4">Commodity</p>
                  <div class="sgi_row" *ngFor="let communityData of commodityForm.controls; let i = index"
                     [formGroupName]="i">
                     <div class="row">
                        <div class="col-md-3">
                           <p class="fs_14 fsn_4 txt_lb mb-1">Commodity <span class="txt_d"> *</span></p>
                           <mat-form-field appearance="none">
                              <input matInput type="text" formControlName="commodity" placeholder="Enter Commodity"
                                 maxlength="129" class="form-control custom_input" />
                              <mat-error *ngIf="communityData.get('commodity').errors?.['required']"
                                 class="error_mess">Commodity is required.</mat-error>
                              <mat-error *ngIf="communityData.get('commodity').errors?.['maxlength']"
                                 class="error_mess">Only 128 characters are allowed</mat-error>
                           </mat-form-field>
                        </div>
                        <div class="col-md-3 ic_dropdown">
                           <p class="fs_14 fsn_4 txt_lb mb-1">Packaging Type <span class="txt_d"> *</span></p>
                           <mat-form-field appearance="none">
                              <mat-select class="form-control custom_input" formControlName="packagingType"
                                 placeholder="Select Packaging Type">
                                 <mat-option value="Pallet">Pallet</mat-option>
                                 <mat-option value="Skid">Skid</mat-option>
                                 <mat-option value="Crate">Crate</mat-option>
                                 <mat-option value="Box">Box</mat-option>
                              </mat-select>
                              <mat-error *ngIf="communityData.get('packagingType').errors?.['required']"
                                 class="error_mess">Packaging type is required.</mat-error>
                           </mat-form-field>
                        </div>
                        <div class="col-md-3">
                           <p class="fs_14 fsn_4 txt_lb mb-1">Quantity <span class="txt_d"> *</span></p>
                           <mat-form-field appearance="none">
                              <input matInput type="text" formControlName="quantity" placeholder="Enter Quantity"
                                 maxlength="129" appZeroNotAllowFirst class="form-control custom_input" />
                              <mat-error *ngIf="communityData.get('quantity').errors?.['required']"
                                 class="error_mess">Quantity is required.</mat-error>
                           </mat-form-field>
                        </div>
                        <div class="col-md-3">
                           <p class="fs_14 fsn_4 txt_lb mb-1">Dimension</p>
                           <mat-form-field appearance="none">
                              <input matInput type="text" appDimension formControlName="dimension"
                                 placeholder="Example (005x022x990)" class="form-control custom_input"  maxlength="11"  />
                              <mat-error *ngIf="communityData.get('dimension').errors?.['pattern']"
                                 class="error_mess">Please enter dimension in valid format</mat-error>
                           </mat-form-field>
                        </div>
                        <div class="col-md-3">
                           <p class="fs_14 fsn_4 txt_lb mb-1">Weight</p>
                           <mat-form-field appearance="none">
                              <input matInput type="text" formControlName="weight" placeholder="Enter Weight" (input)="keyPaste($event)" (keypress)="keyPress($event)"
                                 maxlength="6" class="form-control custom_input" />
                           </mat-form-field>
                        </div>
                        <div class="col-md-3">
                           <p class="fs_14 fsn_4 txt_lb mb-1">$ Value</p>
                           <mat-form-field appearance="none">
                              <input matInput type="text" formControlName="value" placeholder="Enter Value"
                                 maxlength="65" appZeroNotAllowFirst class="form-control custom_input" />
                           </mat-form-field>
                        </div>
                        <div class="col-md-3">
                           <p class="fs_14 fsn_4 txt_lb mb-1">SKU #</p>
                           <mat-form-field appearance="none">
                              <input matInput type="text" formControlName="sku" placeholder="Enter SKU" maxlength="15"
                                  class="form-control custom_input" />
                                 <mat-error *ngIf="!communityData.get('sku').errors?.['maxlength'] && communityData.get('sku').errors?.['pattern']" class="error_mess">Only alpha numeric are allowed.</mat-error>
                           </mat-form-field>
                        </div>

                        <div class="col-md-3 upload_document">
                           <p class="fs_14 fsn_4 txt_lb mb-1">Upload Image</p>
                           <mat-form-field appearance="none" class="custom_matSuffix_icon">
                              <input matInput type="text" placeholder="Choose File.."
                                 class="form-control custom_input text_truncate" id="media_{{i}}" readonly="readonly">
                              <span class="material-iconsicon">
                                 <input class="inputfile" #uploader id="file_{{i}}" name="file" type="file"
                                    style="display: none;" (change)="onSelectedUploadeImage($event,i)"
                                    accept=".png, .jpg, .jpeg">
                                 <label class="btn btn-primary" for="file_{{i}}">
                                    <i class="fa-solid fa-upload fs_16 txt_lb"></i>
                                 </label>
                              </span>
                           </mat-form-field>
                           <div *ngIf="currentIndex===i">
                              <span *ngIf="showErrorImage" class="fs_11 txt_d fsm_5 ps-1">{{showErrorImage}}</span>
                           </div>
                        </div>
                        <div class="col-md-3">
                           <p class="fs_14 fsn_4 mb-2 ope_0">QWE</p>
                           <div class="uploded_img_box" *ngIf="wayPointAddForm.controls.commodityForm.value[i].media">
                              <span class="material-icons txt_d remove_row_icn" (click)="removeMedia(i)">cancel</span>
                              <img src="{{wayPointAddForm.controls.commodityForm.value[i].media}}" class="img-fluid">
                           </div>
                           <div class="uploded_img_loader" *ngIf="currentIndex===i && !showErrorImage">
                              <img src="{{serverPathForUrl}}/portal/images/after-login/LaneAxis-loader.gif"
                                 class="img-fluid">
                           </div>
                        </div>

                        <div class="col-md-6 custom_checkbox mb-3">
                           <mat-checkbox class="form-control border-0 ps-0" formControlName="handleWithCare"
                              style="border: none;">Handle With Care</mat-checkbox>
                        </div>
                        <span (click)="removeCommunityField(i)" class="material-icons txt_d fs_18 remove_row_icn">cancel</span>
                     </div>
                  </div>
               </div>
               <span type="button" class="add_goods" (click)="addCommunityField()">
                  <span class="ms-1 ">Add Another Commodity</span>
                  <span class="material-icons txt_p fs_20 ms-1">add_circle</span>
               </span>
            </div>
         </div>
         <div class="btn_action">
            <button type="submit" class="btn custum_button button_primary btn_primary" [disabled]="disabledAssign">Add
               Waypoint</button>
         </div>
      </div>
   </form>
</div>

<!-- Add Payment Card -->
<div *ngIf="data.openPop ==='addPaymentCardPopup'">
   <div class="header_popup mb-2">
      <p class="fs_20 fsb_8 txt_b card_title ">Add Card Details </p>
      <span class="close_popup_Icon" (click)="onNoClick()">
         <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
      </span>
   </div>
   <form [formGroup]="paymentCard" (ngSubmit)="onSubmit(paymentCard)" class="mb-0 custum_input_css inputInPopup"
      autocomplete="off">
      <div class="v_scrollBar popup_vh" style="margin: 0 -24px; padding: 0 24px;">
         <div class="row">
            <div class="col-sm-12">
               <p class="fs_14 fsn_4 txt_lb" style="margin: 0.2rem 0 0.1rem;">Name On Card <span class="txt_d"> *</span>
               </p>
               <mat-form-field appearance="none">
                  <input matInput formControlName="cardName" type="text" placeholder="Enter Name on Card" maxlength="64"
                     class="form-control custom_input" [(ngModel)]="searchText" (keyup)="checkNameValue($event)" />
                  <mat-error class="error_mess" *ngIf="submitted && paymentCard.get('cardName').errors?.['required']">
                     Name on card is required.</mat-error>
                  <mat-error class="error_mess" *ngIf="paymentCard.get('cardName').errors?.['maxlength']">Maximum 64 characters are allowed.</mat-error>
               </mat-form-field>
            </div>
            <div class="col-sm-12 cus_payment_card">
               <p class="fs_14 fsn_4 txt_lb" >Card Number <span class="txt_d"> *</span>
               </p>
               <label class="card_inpt">
                  <div class="pymnt_input">
                     <div id="card-number-element" class="inpt_field card_no" name="card-number"></div>
                  </div>
                  <span class="brand" *ngIf="brandCard ==='unknown'"> <i class="material-icons">credit_card</i></span>
                  <div class="select_card_img">
                     <img class="img-fluid" *ngIf="brandCard !=='unknown'" id="{{brandCard}}1x"
                        src="{{serverPathForUrl}}/portal/images/after-login/img_trans.gif">
                  </div>
               </label>
               <span *ngIf="cardNumberError" class="pymnt_input_error">{{cardNumberError}}</span>
            </div>
            <div class="col-sm-7 cus_payment_card">
               <p class="fs_14 fsn_4 txt_lb" style="margin: 1.2rem 0 .6rem;">Expiration Date <span class="txt_d">
                     *</span></p>
               <label class="card_inpt">
                  <div class="pymnt_input">
                     <div id="card-expiry-element" class="inpt_field card_no" name="card-expiry"></div>
                  </div>
               </label>
               <span *ngIf="cardExpiryError" class="pymnt_input_error">{{cardExpiryError}}</span>
            </div>
            <div class="col-sm-5 cus_payment_card">
               <p class="fs_14 fsn_4 txt_lb" style="margin: 1.2rem 0 .6rem;">CVV Number<span class="txt_d"> *</span>
               </p>
               <label class="card_inpt">
                  <div class="pymnt_input">
                     <div id="card-cvc-element" class="inpt_field card_no" name="card-cvc"></div>
                  </div>
               </label>
               <span *ngIf="cardCvvError" class="pymnt_input_error">{{cardCvvError}}</span>
            </div>
         </div>
         <p class="fs_14 fsn_4 txt_lb" style="margin: 1.2rem 0 2rem;">By pressing the Save Card button, you agree to our
            <u class="txt_p"><a href="https://stripe.com/checkout/legal" target="_blank" class=" fsm_5">Terms &
                  Conditions.</a></u>
         </p>
      </div>
      <div class="text-center pt-2">
         <button type="submit" class="btn custum_button button_primary w-100 fs_16 fsn_4"
            style="margin: 0 0 1.5rem; height: 40px;" [disabled]="saveCardButton">Save Card</button>
         <span class="btn custum_button txt_lb boxS_0 bg_w w-100 mb-0" type="button" (click)="onNoClick()">Cancel</span>
      </div>
   </form>
</div>

<!-- New Add Payment Card -->
<div *ngIf="data.openPop ==='addPaymentCardPopup'" style="display: none;">
   <div class="header_popup mb-2">
      <p class="fs_20 fsm_5 txt_b line_before">Add Card Details </p>
      <span class="close_popup_Icon" (click)="onNoClick()">
         <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
      </span>
   </div>
   <form [formGroup]="paymentCard" (ngSubmit)="onSubmit(paymentCard)"
      class="mb-0 custum_input_css inputInPopup pay_card_icon" autocomplete="off">
      <div class="v_scrollBar popup_vh" style="margin: 0 -24px; padding: 0 24px;">
         <div class="row">
            <div class="col-sm-12">
               <p class="fs_14 fsn_4 txt_lb" style="margin: 0.2rem 0 0.1rem;">Name on Account <span class="txt_d">
                     *</span></p>
               <mat-form-field appearance="none" class="custom_matSuffix_icon">
                  <input matInput formControlName="cardName" type="text" placeholder="Enter Name on Card"
                     class="form-control custom_input" [(ngModel)]="searchText" (keyup)="checkNameValue($event)" />
                  <mat-icon matSuffix class="material-icons fs_20 txt_lb">credit_card</mat-icon>
                  <mat-error class="error_mess" *ngIf="submitted && paymentCard.get('cardName').errors?.['required']">
                     Name on card is required.</mat-error>
                  <mat-error class="error_mess" *ngIf="paymentCard.get('cardName').errors?.['pattern']">Please enter
                     valid information.</mat-error>
               </mat-form-field>
            </div>
            <div class="col-sm-12 cus_payment_card">
               <p class="fs_14 fsn_4 txt_lb" style="margin: 1.2rem 0 .6rem;">Card Number <span class="txt_d"> *</span>
               </p>
               <label class="card_inpt">
                  <div class="pymnt_input">
                     <div id="card-number-element" class="inpt_field card_no" name="card-number"></div>
                  </div>
                  <span class="brand" *ngIf="brandCard ==='unknown'"> <i class="material-icons">credit_card</i></span>
                  <div class="select_card_img">
                     <img class="img-fluid" *ngIf="brandCard !=='unknown'" id="{{brandCard}}1x"
                        src="{{serverPathForUrl}}/portal/images/after-login/img_trans.gif">
                  </div>
               </label>
               <span *ngIf="cardNumberError" class="pymnt_input_error">{{cardNumberError}}</span>
            </div>
            <div class="col-sm-7 cus_payment_card">
               <p class="fs_14 fsn_4 txt_lb" style="margin: 1.2rem 0 .6rem;">Expiration Date <span class="txt_d">
                     *</span></p>
               <label class="card_inpt">
                  <div class="pymnt_input">
                     <div id="card-expiry-element" class="inpt_field card_no" name="card-expiry"></div>
                  </div>
               </label>
               <span *ngIf="cardExpiryError" class="pymnt_input_error">{{cardExpiryError}}</span>
            </div>
            <div class="col-sm-5 cus_payment_card">
               <p class="fs_14 fsn_4 txt_lb" style="margin: 1.2rem 0 .6rem;">CVV Number <span class="txt_d"> *</span>
               </p>
               <label class="card_inpt">
                  <div class="pymnt_input">
                     <div id="card-cvc-element" class="inpt_field card_no" name="card-cvc"></div>
                  </div>
               </label>
               <span *ngIf="cardCvvError" class="pymnt_input_error">{{cardCvvError}}</span>
            </div>
         </div>
         <p class="fs_14 fsn_4 txt_lb" style="margin: 1.2rem 0 2rem;">By pressing the Save Card button, you agree to our
            <u class="txt_p"><a href="https://stripe.com/checkout/legal" target="_blank">Terms & Conditions.</a></u>
         </p>
      </div>
      <div class="text-center pt-2">
         <button type="submit" class="btn custum_button button_primary w-100 fs_16 fsn_4"
            [disabled]="saveCardButton">Save Card</button>
         <span class="fs_16 fsm_5 txt_lb pointer px-4 py-2 d-block" type="submit" (click)="onNoClick()">Cancel</span>
      </div>
   </form>
</div>


<!-- Confirm Payment -->
<div *ngIf="data.openPop ==='paymentConfirmAdditionalLoads'" class="text-center">
   <p class="fs_20 fsb_6 txt_b line_before">Confirm Payment</p>
   <p class="fs_16 fsn_4 txt_lb mt-3">Please confirm that you want to make a
      payment for this order.</p>
   <div class="text-center">
      <button type="submit" class="btn custum_button button_success w-100 text-capitalize fs_16 fsm_5"
         style="margin: 0 0 1rem; height: 40px;" (click)="onSuccessConfirm()">Confirm</button>
         <span *ngIf="data?.subscriptionOverviewPage" class="fs_16 fsm_5 txt_p pointer  px-4 py-2 d-block" type="button" (click)="backLoad()">Back</span>
   </div>
</div>

<!-- Confirm Payment -->
<div *ngIf="data.openPop ==='paymentConfirm'" class="text-center">
   <span class="material-icons" style="margin: 0 0 1.5rem;font-size: 48px;color: var(--clGrna);">check_circle</span>
   <p class="fs_20 fsb_6 txt_b line_before">Confirm Payment</p>
   <p class="fs_16 fsn_4 txt_lb mt-3">Please confirm that you want to make a
      payment for this order.</p>
   <div class="text-center">
      <button type="submit" class="btn custum_button button_success w-100 text-capitalize fs_16 fsm_5"
         style="margin: 0 0 1rem; height: 40px;" (click)="onSuccessConfirm()">Confirm</button>
      <span  class="fs_16 fsm_5 txt_p pointer  px-4 py-2 d-block" type="button" (click)="backLoad()">Back to
         Loads</span>
   </div>
</div>

<!-- Success Payment -->
<div *ngIf="data.openPop ==='loadSuccess'" class="text-center">
   <span class="material-icons" style="margin: 0 0 1.5rem;font-size: 48px;color: var(--clGrna);">check_circle</span>
   <p class="fs_20 fsb_6 txt_b " style="margin: 0 0 2rem;">Congratulations</p>
   <p *ngIf="!data?.subscriptionOverviewPage" class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">Your payment has been processed
      successfully. We've added {{data.loads}} Load credit(s) to your wallet.</p>
   <p *ngIf="data?.subscriptionOverviewPage" class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">Your payment has been processed
         successfully. We've added {{data.loads}} additional contract credit(s) to your wallet.</p>
   <div class="text-center">
      <button type="submit" class="btn custum_button button_success w-100 text-capitalize fs_16 fsm_5"
         style="margin: 0 0 1.5rem; height: 40px;" (click)="onSuccess()">OK</button>
   </div>
</div>

<!-- Faild Payment -->
<div *ngIf="data.openPop ==='loadFail'" class="text-center">
 
	<img src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/gr-payment-failed.svg" class="img-fluid pay_fld_img">
   <p class="fs_20 fsb_6 txt_b " style="margin: 0 0 2rem;">Oops</p>
   <p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">The transaction was not processed, please
      retry using a different payment method.</p>
   <div class="text-center">
      <button type="submit" class="btn custum_button button_danger w-100 text-capitalize fs_16 fsm_5"
         style="margin: 0 0 1.5rem; height: 40px;" (click)="onFail()">Retry</button>
   </div>
</div>

<!-- Physical Address for payment -->
<div *ngIf="data.openPop ==='addPaymentBillInfoPopup'">
   <div class="header_popup mb-2">
      <p class="fs_20 fsb_6 txt_b line_before ">Add Address</p>
      <span class="close_popup_Icon" (click)="onNoClick()">
         <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
      </span>
   </div>
   <form [formGroup]="addressForm" (ngSubmit)="carrierProfileSubmit(addressForm)" autocomplete="off"
      class="mb-0 custum_input_css inputInPopup" autocomplete="off">
      <div class="inviteDriver_pop">
         <div class="v_scrollBar popup_vh">
            <div class="ic_dropdown">
               <p class="fs_14 fsn_4 txt_lb mb-1">Address Type <span class="txt_d"> *</span></p>
               <mat-form-field appearance="none">
                  <mat-select formControlName="addressType" placeholder="Select address type"
                     class="form-control custom_input">
                     <mat-option class="mat_dropdown" *ngIf="this.mailAdd" value="Mailing Address">Mailing Address
                     </mat-option>
                     <mat-option class="mat_dropdown" *ngIf="this.phpAdd" value="Physical Address">Physical Address
                     </mat-option>
                  </mat-select>
                  <mat-error class="error_mess" *ngIf="carrierPA.get('addressType').errors?.['required']">
                     Address is required.
                  </mat-error>
               </mat-form-field>
            </div>
            <div class="">
               <p class="fs_14 fsn_4 txt_lb mb-1">Address Line 1 <span class="txt_d"> *</span></p>
               <mat-form-field appearance="none">
                  <input matInput placeholder="Enter address line 1" formControlName="street"
                     class="form-control custom_input" maxlength="255">
                  <mat-error class="error_mess" *ngIf="carrierPA.get('street').errors?.['required']">
                     Address is required.
                  </mat-error>
                  <mat-error class="error_mess" *ngIf="carrierPA.get('street').errors?.['maxlength']">
                     Address max length 255 words.
                  </mat-error>
               </mat-form-field>
            </div>
            <div class="">
               <p class="fs_14 fsn_4 txt_lb mb-1">Address Line 2 </p>
               <mat-form-field appearance="none">
                  <input matInput formControlName="addressline2" type="text" placeholder="Enter address line 2"
                     class="form-control custom_input" />
                  <mat-error class="error_mess" *ngIf="carrierPA.get('street').errors?.['maxlength']">
                     Address line 2 max length 255 words.
                  </mat-error>
               </mat-form-field>
            </div>
            <div class="">
               <p class="fs_14 fsn_4 txt_lb mb-1">ZIP Code <span class="txt_d"> *</span></p>
               <mat-form-field appearance="none">
                  <input matInput placeholder="ZIP Code" formControlName="zipCode" minlength="4" maxlength="8" class="form-control custom_input">
                  <mat-error class="error_mess" *ngIf="carrierPA.get('zipCode').errors?.['required']">ZIP Code is required.</mat-error>
                  <mat-error class="error_mess" *ngIf="(carrierPA.get('zipCode').errors?.['minlength']) && !(carrierPA.get('zipCode').errors?.['pattern'])">ZIP Code should be minimum 4 digit.</mat-error>
                  <mat-error *ngIf="carrierPA.get('zipCode').errors?.['pattern']" class="error_mess">{{zipCodeErrorMsg}}</mat-error>
               </mat-form-field>
            </div>
            <div class="">
               <p class="fs_14 fsn_4 txt_lb mb-1">City <span class="txt_d"> *</span></p>
               <mat-form-field appearance="none">
                  <input matInput placeholder="City" formControlName="cityBy" maxlength="100" maxlength="33"
                     class="form-control custom_input">
                  <mat-error class="error_mess" *ngIf="carrierPA.get('cityBy').errors?.['required']" class="error_mess">
                     City is required.</mat-error>
                  <mat-error class="error_mess" *ngIf="carrierPA.get('cityBy').errors?.['pattern']" class="error_mess">
                     Fill only aphanumeric value.</mat-error>
                  <mat-error class="error_mess" *ngIf="carrierPA.get('cityBy').errors?.['maxlength']"
                     class="error_mess">Only 32 characters are allowed.</mat-error>
               </mat-form-field>
            </div>
            <div class="">
               <p class="fs_14 fsn_4 txt_lb mb-1">State <span class="txt_d"> *</span></p>
               <mat-form-field appearance="none">
                  <mat-select formControlName="stateBy" class="form-control custom_input" placeholder="Select state">
                     <mat-option class="mat_dropdown" *ngFor="let state of states" [value]="state.sortname">
                        {{state.name}}
                     </mat-option>
                  </mat-select>
                  <mat-error class="error_mess" *ngIf="carrierPA.get('stateBy').errors?.['required']">
                     State is required.
                  </mat-error>
               </mat-form-field>
            </div>
            <div class="ic_dropdown">
               <p class="fs_14 fsn_4 txt_lb mb-1">Country <span class="txt_d"> *</span></p>
               <mat-form-field appearance="none">
                  <mat-select placeholder="Select country" class="form-control custom_input" formControlName="country">
                     <mat-option class="mat_dropdown" value="US">US</mat-option>
                  </mat-select>
               </mat-form-field>
            </div>
         </div>
         <div class="text-end">
            <button type="submit" class="btn custum_button button_primary fs_16 fsn_4 w-100" style="height: 40px;">Save
               Address</button>
            <span class="fs_16 fsm_5 txt_lb pointer  px-4 py-3 me-4 d-block" type="submit" (click)="onNoClick()"
               style="height: 40px;">Cancel</span>
         </div>
      </div>
   </form>
</div>



<div class="" *ngIf="data.openPop ==='connectionSuccess'">
   <div class="">
      <p class="fs_20 fsb_6 txt_b " style="margin: 0 0 2rem;">Connection Request</p>
      <p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">{{data.successMeg}}</p>
      <div class="text-center">
         <button type="submit" class="btn custum_button button_primary w-100 fs_16 fsm_5" (click)="onNoClick()"
            style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;">Done</button>

      </div>
   </div>
</div>
<div *ngIf="data.openPop ==='confirmConnectionPopup'">
   <div class="" *ngIf="disableDriver">
      <p class="fs_20 fsb_6 txt_b " style="margin: 0 0 2rem;">{{data.title}}</p>
      <p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">{{data.contain}}</p>
      <div class="text-center">
         <button type="submit" class="btn custum_button button_primary w-100 fs_16 fsm_5"
            (click)="onConfirmationClick()"
            style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;">{{data.buttontext}}</button>
         <span class="fs_16 fsm_5 txt_lb pointer  px-4 py-2" (click)="onNoConfirmClick()">Cancel</span>
      </div>
   </div>
</div>

<div *ngIf="data.openPop === 'uploadeDocument'" class="pdf_doc_upload">
   <form [formGroup]="addDocUpload" (ngSubmit)="uploadDocument(addDocUpload)" class="mb-0">
      <div class="header_popup">
         <p class="fs_20 fsb_6 txt_b mb-0 line_before ">Upload New Document</p>
         <span class="close_popup_Icon" (click)="onNoConfirmClick()">
            <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
         </span>
      </div>
      <div class="">
         <p class="fs_14 fsn_4 txt_lb input_label">Document Name <span class="txt_d"> *</span></p>
         <mat-form-field appearance="none">
            <input matInput type="text" formControlName="description" placeholder="Enter document name"
               class="form-control custom_input" />
            <mat-error class="error_mess" *ngIf="addDocUpload.get('description').errors?.['required']">Name is required.
            </mat-error>
         </mat-form-field>
      </div>
      <div class="">
         <p class="fs_14 fsn_4 txt_lb input_label">Upload Image</p>
         <div class="upload_document">
            <mat-form-field appearance="none" class="custom_matSuffix_icon">
               <input matInput type="text" formControlName="document" value="{{name}}" placeholder="Browse image"
                  class="form-control custom_input" readonly="readonly">
               <span class="material-iconsicon">
                  <input class="inputfile" id="file" name="file" type="file" (change)="onSelectedFile($event)"
                     style="display: none;">
                  <label class="btn btn-primary py-0 mb-3" for="file"
                     style="background: #f5f5f5; border-radius: 0 4px 4px 0;">
                     <i class="fa-solid fa-upload fs_16 txt_lb"></i>
                  </label>
               </span>
            </mat-form-field>
         </div>
      </div>
      <div class="btn_action">
         <button type="submit" class="btn custum_button button_primary btn_primary mb_16" [disabled]="disbu">Add
            Document</button>
         <span class="btn_trans txt_lb pointer " (click)="onNoConfirmClick()">Cancel</span>
      </div>
     
   </form>
</div>

<div *ngIf="data.openPop ==='hereMap'">
   <div class="text-center popup_activity">
      <p class="fs_14 fsm_5 txt_b header_mess"> Unable to not create route. Please try after some time.</p>
      <div class="text-center mt-4">
         <span class="fs_10 txt_p fsm_5 pointer " (click)="onNoClick()"><u>close</u></span>
      </div>
   </div>
</div>
<div *ngIf="data.openPop ==='mapboxMap'">
   <div class="text-center popup_activity">
      <p class="fs_14 fsm_5 txt_b header_mess"> Unable to not create route.</p>
      <div class="text-center mt-4">
         <span class="fs_10 txt_p fsm_5 pointer " (click)="onNoClick()"><u>close</u></span>
      </div>
   </div>
</div>

<div *ngIf="data.openPop ==='notificationSuccessUpdate'" class="text-center">
   <span class="material-icons" style="margin: 0 0 1.5rem;font-size: 48px;color: var(--clGrna);">check_circle</span>
   <p class="fs_20 fsb_6 txt_b " style="margin: 0 0 2rem;">Congratulations</p>
   <p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">Notification panel is updated successfully.</p>
   <div class="text-center">
      <button type="submit" class="btn custum_button button_success w-100 text-capitalize fs_16 fsm_5"
         style="margin: 0 0 1.5rem; height: 40px;" (click)="onNotificationSuccess()">OK</button>
   </div>
</div>

<!-- confirm section -->
<div *ngIf="data.openPop ==='confirm'">
   <div class="text-center">
      <p class="fs_20 fsb_6 txt_b " style="margin: 0 0 2rem;"> {{data.title}}</p>
      <p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;"> {{data.contain}}</p>
      <div class="text-center">
         <button type="button" class="btn custum_button button_primary w-100 fs_16 fsm_5"
            (click)="deleteRecord(data.id,data.driverId)"
            style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;">Yes</button>
         <span class="fs_16 fsm_5 txt_lb pointer  px-4 py-2" (click)="onNoClick()">Cancel</span>
      </div>
   </div>
</div>
<!-- Assign Driver-->
<div *ngIf="data.openPop ==='confirmBy'">
   <div class="header_popup">
      <p class="fs_20 fsb_6 txt_b mb-0 line_before ">Assign Driver</p>
      <span class="close_popup_Icon" (click)="onNoClick()">
         <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
      </span>
   </div>
   <div class="text-center p-3">
      <p class="fs_16 fsn_4 txt_lb mb_24">
         Assign Load id “<span class="txt_b fsm_5">#{{data.id}}</span>” to <span
            class="txt_b fsm_5">{{data.driverName}}</span> Driver ?
      </p>
      <div class="btn_action">
         <button type="button" (click)="deleteRecordBy()" class="btn custum_button button_primary btn_primary mb_16">
            {{data.buttontext}} </button>
         <span class="btn_trans txt_lb pointer" (click)="onNoClick()">
            <span *ngIf="data.buttonnew" class="">{{data.buttonnew}}</span>
            <span *ngIf="!data.buttonnew" class="">Cancel</span>
         </span>
      </div>
   </div>
</div>
<!-- confirm section -->
<div *ngIf="data.openPop ==='shipmentConfirm'">
   <div class="text-center">
      <p class="fs_20 fsb_6 txt_b " style="margin: 0 0 2rem;"> {{data.title}}</p>
      <p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">
         Assign <span class="fsm_5 txt_b">{{data.driverName}}</span> to this shipment?
      </p>
      <div class="text-center">
         <button type="button" class="btn custum_button button_primary w-100 fs_16 fsm_5" (click)="goToDriverList()"
            style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;"> {{data.buttontext}}
         </button>
         <span class="fs_16 fsm_5 txt_lb pointer  px-4 py-2" (click)="onNoClick()">Cancel</span>
      </div>
   </div>
</div>
<!-- Success popu -->
<div class="inviteDriver_con" *ngIf="data.openPop ==='shipmentSuccess'">
   <div class="text-center">
      <span class="material-icons" style="margin: 0 0 1.5rem;font-size: 48px;color: var(--clGrna);">check_circle</span>
      <p class="fs_20 fsb_6 txt_b " style="margin: 0 0 2rem;">Congratulations</p>
      <p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">
         <span>Load with ID </span>
         <span class="fsm_5 txt_b"> #{{data.shipmentUniqueId}}</span>
         <span>has been successfully created and assigned to</span>
         <span class="fsm_5 txt_b">{{data.driverName}}</span>.
      </p>
      <p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;"> {{data.contain}}</p>
      <div class="text-center">
         <button type="submit" class="btn custum_button button_success w-100 text-capitalize fs_16 fsm_5"
            [routerLink]="['/load/list/pending']" (click)="onNoClick()"
            style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;">OK</button>

         <span class="fs_16 fsn_4 txt_p pointer  px-4 py-2" [routerLink]="['/load/view/',data.shipmentId]"
            (click)="onNoClick()">View Details</span>

      </div>
   </div>
</div>

<!-- Set default -->
<div *ngIf="data.openPop ==='disabled'">
   <div class="text-center popup_activity">
      <p class="fs_14 fsm_5 txt_b header_mess">{{data.contain}}</p>
      <div class="">
         <button type="submit" class="btn custum_button button_primary me-4"
            (click)="setDefault()">{{data.buttontext}}</button>
         <span class="fs_10 txt_p fsm_5 pointer " (click)="onNoClick()"><u>Cancel</u></span>
      </div>
   </div>
</div>
<!-- Undo -->
<div *ngIf="data.openPop ==='undoAddress'">
   <div class="text-center">
      <p class="fs_20 fsb_6 txt_b " style="margin: 0 0 2rem;">Canceled Shipment</p>
      <p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">{{data.contain}}</p>
      <div class="text-center">

         <button type="submit" class="btn custum_button button_success w-100 text-capitalize fs_16 fsm_5"
            (click)="onNoClick()"
            style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;">Done</button>
      </div>
   </div>
</div>
<div *ngIf="data.openPop === '11'">
   <div class="text-center">
      <p class="fs_20 fsb_6 txt_b " style="margin: 0 0 2rem;">Discard Changes</p>
      <p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">Leaving this page will discard unsaved
         changes.</p>
      <div class="text-center">
         <button type="submit" class="btn custum_button button_success w-100 text-capitalize fs_16 fsm_5"
            (click)="confirmReturn()"
            style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;">Yes</button>
         <span class="fs_16 fsn_4 txt_p pointer  px-4 py-2" (click)="onNoClick()">Cancel</span>
      </div>
   </div>

</div>
<div *ngIf="data.openPop ==='addresSuccess'">
   <div class="text-center popup_activity">
      <p class="fs_14 fsm_5 txt_b header_mess">{{data.contain}}</p>
      <div class="">
         <button type="button" class="btn custum_button button_primary" (click)="onNoClick()">OK</button>
      </div>
   </div>
</div>


<div *ngIf="data.openPop === '6'">
   <div class="text-center profile_pic_upload" *ngIf="!hideImageRemove">
      <div class="d-flex justify-content-center">
         <div class="pe-4">
            <input type="file" name="fileName" id="fileName" class="inputfile" (change)="onSeleted($event)"
               accept='image/*' style="display: none;">
            <label for="fileName" class="m-0">
               <span class="material-icons fs_20 pointer">desktop_windows</span>
            </label>
            <p class="fs_12 txt_p fsn_4">Select form your PC</p>
         </div>
         <div class="ps-4">
            <span class="material-icons fs_20 pointer" (click)="removeImage()">delete_outline</span>
            <p class="fs_12 txt_p fsn_4">Remove Photo</p>
         </div>
      </div>
      <p class="text-danger" *ngIf="imagmessage">{{imagmessage}}</p>
      <span class="fs_12 txt_p fsm_5 pointer " (click)="onNoClick()"><u>Cancel</u></span>
      <p class="img_size_validation fs_10 txt_p fsm_5 m-0"><span class="txt_d fs_12">*</span> Profile Image must be in 500*500</p>
   </div>
   <div class="text-center popup_activity" *ngIf="hideImageRemove">
      <p class="fs_14 fsm_5 txt_b header_mess">Are you sure to remove this profile image?</p>
      <div class="text-center mt-4">
         <button type="button" class="btn custum_button button_primary me-4" (click)="removeImageYes()">Yes</button>
         <span class="fs_10 txt_p fsm_5 pointer " (click)="removeImageCancel()">Cancel</span>
      </div>
   </div>
</div>


<!-- Cancel Shipment -->
<div *ngIf="data.openPop ==='confirmcancel'">
   <div class="text-center">
      <p class="fs_20 fsb_6 txt_b " style="margin: 0 0 2rem;">Cancel Shipment</p>
      <p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">
         Cancel this shipment? "<span class="txt_b fsm_5" *ngIf="data.uniqueId">#{{data.uniqueId}}</span>"
      </p>
      <div class="text-center">
         <button type="button" (click)="deleteRecordBy()" class="btn custum_button button_primary w-100 fs_16 fsm_5"
            style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;">{{data.buttontext}}
         </button>
         <span class="fs_16 fsm_5 txt_lb pointer  px-4 py-2" (click)="onNoClick()">Close</span>
      </div>
   </div>
</div>
<div *ngIf="data.openPop ==='confirmCancelShipment'">
   <div class="text-center">
      <p class="fs_20 fsb_6 txt_b " style="margin: 0 0 2rem;">Cancel Shipment</p>
      <p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">If you cancel this shipment you will be
         charged 50% of the shipment rate. Do you want to proceed with cancelation?</p>
      <div class="text-center">
         <button type="button" (click)="deleteRecordBy()" class="btn custum_button button_primary w-100 fs_16 fsm_5"
            style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;">{{data.buttontext}}
         </button>
         <span class="fs_16 fsm_5 txt_lb pointer  px-4 py-2" (click)="onNoClick()">Close</span>
      </div>
   </div>
</div>
<!-- Cancel Shipment -->
<div *ngIf="data.openPop ==='confirmchange'">
   <div class="text-center">
      <p class="fs_20 fsb_6 txt_b " style="margin: 0 0 2rem;">Change Driver</p>
      <p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">
         <span>Reassign Load to new driver?</span>
         <span class="txt_b" *ngIf="data.uniqueId"># {{ data.uniqueId}}</span>
      </p>
      <div class="text-center">
         <button type="button" (click)="deleteRecordBy()" class="btn custum_button button_primary w-100 fs_16 fsm_5"
            style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;">{{data.buttontext}}
         </button>
         <span class="fs_16 fsm_5 txt_lb pointer  px-4 py-2" (click)="onNoClick()">close</span>
      </div>
   </div>
</div>
<!-- payment section success -->
<!-- confirm section -->
<div *ngIf="data.openPop ==='success_payment'">
   <div class="text-center">
      <span class="material-icons" style="margin: 0 0 1.5rem;font-size: 48px;color: var(--clGrna);">check_circle</span>
      <p class="fs_20 fsb_6 txt_b " style="margin: 0 0 2rem;"> {{data.title}}</p>
      <p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;"> {{data.contain}}</p>
      <div class="text-center">
         <button type="button" class="btn custum_button button_primary w-100 fs_16 fsm_5" (click)="successPayment()"
            style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;">OK</button>
      </div>
   </div>
</div>
<div *ngIf="data.openPop ==='fail_payment'">
   <div class="text-center">
      <p class="fs_20 fsb_6 txt_b " style="margin: 0 0 2rem;"> {{data.title}}</p>
      <p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;"> {{data.contain}}</p>
      <div class="text-center">
         <button type="button" class="btn custum_button button_primary w-100 fs_16 fsm_5" (click)="onNoClick()"
            style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;">Try Again</button>
      </div>
   </div>
</div>
<!-- Order section -->
<div class="inviteDriver_con" *ngIf="data.openPop ==='shipmentSuccessOrder'">
   <div class="text-center">
      <span class="material-icons" style="margin: 0 0 1.5rem;font-size: 48px;color: var(--clGrna);">check_circle</span>
      <p class="fs_20 fsb_6 txt_b " style="margin: 0 0 2rem;">Congratulations</p>
      <p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">
         Order with ID <span class="fsm_5 txt_b">#{{data.shipmentUniqueId}}</span>
         has been successfully created.
      </p>
      <div class="text-center">
         <button type="submit" class="btn custum_button button_success w-100 text-capitalize fs_16 fsm_5"
            [routerLink]="['/storage-order/list/orders']" (click)="onNoClick()"
            style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;">OK</button>
         <span class="fs_16 fsn_4 txt_p pointer px-4 py-2 " [routerLink]="['/storage-order/create']"
            (click)="resetAllData()">Create another</span>
      </div>
   </div>
</div>

<div class="inviteDriver_con" *ngIf="data?.openPop ==='routePlanSuccess'">
   <div class="text-center">
      <div style="margin: 0 0 1.5rem; text-align: center;">
         <img src="{{serverPathForUrl}}/portal/images/after-login/icon_thumb_up.png" class="img-fluid"
            style="max-width: 80px;">
      </div>
      <p class="fs_20 fsb_6 txt_b " style="margin: 0 0 2rem;">Congratulations</p>
      <p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">Your Route Plan Saved Successfully.</p>
      <div class="text-center">
         <button type="submit" class="btn custum_button button_success w-100 text-capitalize fs_16 fsm_5"
            (click)="onNoClick()"
            style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;">FINISH</button>
       
      </div>
   </div>
</div>

<!-- Shipper section -->
<!-- Success popup -->
<div class="inviteDriver_con" *ngIf="data.openPop ==='shipmentSuccessShipper'">
   <div class="text-center">
      <span class="material-icons" style="margin: 0 0 1.5rem;font-size: 48px;color: var(--clGrna);">check_circle</span>
      <p class="fs_20 fsb_6 txt_b " style="margin: 0 0 2rem;">Congratulations</p>
      <p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">
         Load with ID <span class="fsm_5 txt_b">#{{data.shipmentUniqueId}}</span>
         has been successfully created.
      </p>
      <div class="text-center">
         <button *ngIf="data.isDraft==='N' && !data.offerRate" type="submit"
            class="btn custum_button button_success w-100 text-capitalize fs_16 fsm_5"
            [routerLink]="['/spot-rate-loads/created-loads/allLoads']" (click)="onNoClick()"
            style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;">OK</button>
         <button *ngIf="data.isDraft==='Y' && !data.offerRate" type="submit"
            class="btn custum_button button_success w-100 text-capitalize fs_16 fsm_5"
            [routerLink]="['/recurring-lanes/active-lanes/pendingByLane']" (click)="onNoClick()"
            style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;">OK</button>
         <button *ngIf="data.offerRate" type="submit"
            class="btn custum_button button_success w-100 text-capitalize fs_16 fsm_5"
            [routerLink]="['/spot-rate-loads/created-loads/allLoads']" (click)="onNoClick()"
            style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;">OK</button>
         <span class="fs_16 fsn_4 txt_p pointer px-4 py-2 "
         (click)="successShipmentCreate()">Create another</span>
      </div>
   </div>
</div>
<div class="inviteDriver_con" *ngIf="data.openPop ==='shipmentBylaneSuccessShipper'">
   <div class="text-center">
      <span class="material-icons" style="margin: 0 0 1.5rem;font-size: 48px;color: var(--clGrna);">check_circle</span>
      <p class="fs_20 fsb_6 txt_b " style="margin: 0 0 2rem;">Congratulations</p>
      <p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">
         Load with ID <span class="fsm_5 txt_b">#{{data.shipmentUniqueId}}</span>
         has been successfully created.
      </p>
      <div class="text-center">
         <button *ngIf="data.isDraft==='N' && !data.offerRate" type="submit"
            class="btn custum_button button_success w-100 text-capitalize fs_16 fsm_5"
            [routerLink]="['/recurring-lanes/create-lanes/allLoadsByLane']" (click)="onNoClick()"
            style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;">OK</button>
         <button *ngIf="data.isDraft==='Y' && !data.offerRate" type="submit"
            class="btn custum_button button_success w-100 text-capitalize fs_16 fsm_5"
            [routerLink]="['/recurring-lanes/create-lanes/allLoadsByLane']" (click)="onNoClick()"
            style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;">OK</button>
         <button *ngIf="data.offerRate" type="submit"
            class="btn custum_button button_success w-100 text-capitalize fs_16 fsm_5"
            [routerLink]="['/recurring-lanes/create-lanes/allLoadsByLane']" (click)="onNoClick()"
            style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;">OK</button>
      </div>
   </div>
</div>

<!-- Success forceCarRoute popup -->
<div class="inviteDriver_con"
   *ngIf="data.openPop ==='forceCarRoute' || data.openPop ==='forceCarRouteCarrier' || data.openPop ==='carRoutePlan'">
   <div class="text-center">
      <span class="material-icons" style="margin: 0 0 1.5rem;font-size: 48px;color: var(--clGrna);">error_outline</span>
      <p class="fs_20 fsb_6 txt_b " style="margin: 0 0 2rem;">Only Car Route Available.</p>
      <p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">You can select standard car routing or
         update your location addresses.</p>
      <div class="text-center">
         <button type="submit" class="btn custum_button button_success w-100 text-capitalize fs_16 fsm_5"
            (click)="onCarRoute()" style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;">Use
            Standard Car Routing</button>
         <span *ngIf="data.openPop ==='forceCarRoute'" class="fs_16 fsn_4 txt_p pointer  px-4 py-2" (click)="backToAddPage()">Update Addresses</span>
         <span *ngIf="data.openPop ==='forceCarRouteCarrier'" class="fs_16 fsn_4 txt_p pointer  px-4 py-2"
            (click)="backToAddPage()">Update Addresses</span>
         <span *ngIf="data.openPop ==='carRoutePlan'" class="fs_16 fsn_4 txt_p pointer  px-4 py-2"
            (click)="backToAddPage()">Update Addresses</span>
      </div>
   </div>
</div>

<!-- Success forceCarRoute popup -->

<div class="inviteDriver_con" *ngIf="data.openPop ==='mapboxMapRoute'">
   <div class="text-center">
      <span class="material-icons" style="margin: 0 0 1.5rem;font-size: 48px;color: var(--clGrna);">error_outline</span>
      <p class="fs_20 fsb_6 txt_b " style="margin: 0 0 2rem;">No Route Available</p>
      <p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">
         There is no direct navigation route available between your locations.
      </p>
      <div class="text-center">
         <button class="btn custum_button button_success w-100 text-capitalize fs_16 fsm_5"
            style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;"
            (click)="onNoClick()">OK</button>
      </div>
   </div>
</div>

<div class="inviteDriver_con"
   *ngIf="data.openPop ==='noRoute' || data.openPop ==='noRouteCarrier' || data.openPop ==='noRoutePlan'">
   <div class="text-center">
      <span class="material-icons" style="margin: 0 0 1.5rem;font-size: 48px;color: var(--clGrna);">error_outline</span>
      <p class="fs_20 fsb_6 txt_b " style="margin: 0 0 2rem;">No Route Available</p>
      <p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">
         There is no direct navigation route available between the locations you selected. Please re-enter one or both
         addresses.
      </p>
      <div class="text-center">
         <button *ngIf="data.openPop ==='noRoute'" type="submit"
            class="btn custum_button button_success w-100 text-capitalize fs_16 fsm_5" (click)="backToAddPage()"
            style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;">Update Addresses</button>
         <button *ngIf="data.openPop ==='noRouteCarrier'" type="submit"
            class="btn custum_button button_success w-100 text-capitalize fs_16 fsm_5" (click)="backToAddPage()"
            style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;">Update Addresses</button>
         <button *ngIf="data.openPop ==='noRoutePlan'" type="submit"
            class="btn custum_button button_success w-100 text-capitalize fs_16 fsm_5" (click)="backToAddPage()"
            style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;">Update Addresses</button>
       
      </div>
   </div>
</div>
<!-- popupPublicConfirmBiddingPage -->
<div class="inviteDriver_con" *ngIf="data.openPop ==='confirmPublicPopup'">
   <div class="text-center">
      <p class="fs_20 fsb_6 txt_b " style="margin: 0 0 2rem;">Visibility</p>
      <p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem;">Are you sure you want to make this shipment public?</p>

      <div class="text-center">
         <button type="submit" class="btn custum_button button_success w-100 text-capitalize fs_16 fsm_5"
            (click)="onPublic()"
            style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;">Yes</button>
         <span class="fs_16 fsm_5 txt_lb pointer  px-4 py-2" (click)="onNoClick()">Cancel</span>
      </div>
   </div>
</div>

<!-- login Required for Track Shipment -->
<div *ngIf="data.openPop ==='loginRequiredTrackShipment'">
   <div class="text-center">
      <span class="icon-ic-emergency txt_d" style="font-size: 48px; color: var(--clGrna);"></span>
      <p class="fs_20 fsb_6 txt_b " style="margin: 1rem 0 2rem;">Login required</p>
      <p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">Sign in or Create a free account to
         message the driver on the shipment. Click below to register with a qucik one step process.</p>
      <div class="text-center">
         <button type="button" class="btn custum_button button_primary w-100 fs_16 fsm_5" (click)="loginPage()"
            style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;">Sign In / Register for
            FREE</button>
         <span class="fs_16 fsm_5 txt_lb pointer  px-4 py-2" (click)="onNoClick()">Cancel</span>
      </div>
   </div>
</div>

<div *ngIf="data.openPop ==='contractDocument'" class="pdf_doc_upload">
   <form [formGroup]="contractForm" (ngSubmit)="contractUploadDocument(contractForm)" class="mb-0 custum_input_css">
      <div class="inviteDriver_pop">
         <p class="fs_20 fsb_6 txt_b text-center " style="margin: 0 0 2rem;">Upload New Document</p>
         <div class="">
            <p class="fs_14 fsn_4 txt_lb input_label">Upload Document <span class="txt_d"> *</span></p>
            <div class="upload_dock reg_upload_dock">
               <mat-form-field appearance="none" class="custom_matSuffix_icon">
                  <input matInput type="text" formControlName="contractDocument" value="{{name}}"
                     placeholder="Browse Document" class="form-control custom_input" readonly="readonly">
                  <mat-error *ngIf="imagmessage" class="error_mess">{{ imagmessage }}</mat-error>
               </mat-form-field>
               <div>
                  <input accept="application/pdf" class="inputfile" id="file" name="file" type="file"
                     (change)="onSelectedContract($event)" style="display: none;">
                  <label class="btn btn-primary py-0 mb-3" for="file"
                     style="background: #f5f5f5; border-radius: 0 4px 4px 0; margin: 0;">
                     <i class="fa-solid fa-upload txt_lb"></i>
                  </label>
               </div>
            </div>
         </div>

         <p class="fs_14 fsn_4 txt_d" style="margin: -1rem 0 2rem"></p>
         <div class="text-center">
            <button type="submit" class="btn custum_button button_primary w-100 fs_16 fsm_5" [disabled]="disbu"
               style="margin: 0 0 1.5rem; height: 40px;">Upload</button>
            <span class="fs_16 fsm_5 txt_lb pointer  px-4 py-2" type="button" (click)="onNoClick()">Cancel</span>
         </div>
      </div>
   </form>
</div>

<div *ngIf="data.openPop ==='certificate'" class="pdf_doc_upload">
   <form [formGroup]="certifcateVerify" (ngSubmit)="certifcateUpload(certifcateVerify)" class="mb-0 custum_input_css">
      <div class="inviteDriver_pop">
         <p class="fs_20 fsb_6 txt_b text-center " style="margin: 0 0 2rem;">Upload New Certificate Of Insurance
         </p>
         <div class="">
            <p class="fs_14 fsn_4 txt_lb input_label">Upload Certificate Of Insurance <span class="txt_d"> *</span></p>
            <div class="upload_dock reg_upload_dock">
               <mat-form-field appearance="none" class="custom_matSuffix_icon">
                  <input matInput type="text" formControlName="certificate" value="{{name}}"
                     placeholder="Browse certificate of insurance" class="form-control custom_input"
                     readonly="readonly">
                  <mat-error *ngIf="imagmessage" class="error_mess">{{ imagmessage }}</mat-error>
               </mat-form-field>
               <div>
                  <input accept="application/pdf" class="inputfile" id="file" name="file" type="file"
                     (change)="onSelectedFileCertifcate($event)" style="display: none;">
                  <label class="btn btn-primary py-0 mb-3" for="file"
                     style="background: #f5f5f5; border-radius: 0 4px 4px 0; margin: 0;">
                     <i class="fa-solid fa-upload fs_16 txt_lb"></i>
                  </label>
               </div>
            </div>
         </div>
         <div class="ic_calander">
            <p class="fs_14 fsn_4 txt_lb input_label">Expiry Date <span class="txt_d"> *</span></p>
            <mat-form-field appearance="none">
               <input matInput formControlName="insurance_expiry_date" [matDatepicker]="dp1" placeholder="Select Date"
                  [min]="minDateBefore" [readonly]="true" class="form-control custom_input">
               <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
               <mat-datepicker #dp1 disabled="false"></mat-datepicker>
               <mat-error class="error_mess" *ngIf="certifcateVerify.get('insurance_expiry_date').errors?.['required']">
                  Expiry date is required.</mat-error>
            </mat-form-field>
         </div>

         <p class="fs_14 fsn_4 txt_d" style="margin: -1rem 0 2rem"></p>
         <div class="text-center">
            <button type="submit" class="btn custum_button button_primary w-100 fs_16 fsm_5" [disabled]="disbu"
               style="margin: 0 0 1.5rem; height: 40px;">Upload</button>
            <span class="fs_16 fsm_5 txt_lb pointer  px-4 py-2" type="submit" (click)="onNoClick()">Cancel</span>
         </div>
      </div>
   </form>
</div>

<div *ngIf="data.openPop ==='autoRenew'">
   <div class="">
      <p class="fs_20 fsb_6 txt_b text-center " style="margin: 0 0 2rem;">Auto Renew Subscription.</p>
      <p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">Do you want to stop auto renew
         subscription.</p>
      <div class="text-center">
         <button type="submit" class="btn custum_button button_primary w-100 fs_16 fsm_5" (click)="closePopUp('OK')"
            style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;">Yes</button>
         <span class="fs_16 fsm_5 txt_lb pointer  px-4 py-2" (click)="onNoConfirmClick()">Cancel</span>
      </div>
   </div>
</div>

<div *ngIf="data.openPop ==='cardConfirmation'">
   <div class="">
      <p class="fs_20 fsb_6 txt_b text-center  line_before">Payment Method</p>
      <p class="fs_16 fsn_4 txt_lb" style="margin: 1.5rem 0 2rem; line-height: 24px;">Do you want to remove this payment
         method?</p>
      <div class="text-center">
         <button type="submit" class="btn custum_button button_primary w-100 fs_16 fsm_5"
            (click)="onConfirmationClick()"
            style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;">Yes</button>
         <span class="fs_16 fsm_5 txt_lb pointer  px-4 py-2" (click)="onNoConfirmClick()">No</span>
      </div>
   </div>
</div>

<!-- Video popup -->
<div *ngIf="data.openPop ==='videoShow'">
   <div class="header_popup">
      <p class="fs_20 fsb_6 txt_b line_before ">LaneAxis Video</p>
      <span class="close_popup_Icon" (click)="closePoup()">
         <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
      </span>
   </div>
   <div class="" *ngIf="data.type==='carrier_demo'">
      <iframe title="vimeo-player" src="https://player.vimeo.com/video/464283096" width="640" height="360"
         frameborder="0" allowfullscreen></iframe>
   </div>

   <div class="" *ngIf="data.type==='carrier_shipment'">
      <iframe title="vimeo-player" src="https://player.vimeo.com/video/468014185" width="640" height="360"
         frameborder="0" allowfullscreen></iframe>
   </div>

   <div class="" *ngIf="data.type==='carrier_bid'">
      <iframe title="vimeo-player" src="https://player.vimeo.com/video/497807393 " width="640" height="360"
         frameborder="0" allowfullscreen></iframe>
   </div>

   <div class="" *ngIf="data.type==='carrier_driver'">
      <iframe title="vimeo-player" src="https://player.vimeo.com/video/466744086" width="640" height="360"
         frameborder="0" allowfullscreen></iframe>
   </div>

   <div class="" *ngIf="data.type==='carrier_network'">
      <iframe title="vimeo-player" src="https://player.vimeo.com/video/466735694" width="640" height="360"
         frameborder="0" allowfullscreen></iframe>
   </div>


   <div class="" *ngIf="data.type==='shipper_demo'">
      <iframe title="vimeo-player" src="https://player.vimeo.com/video/474194405" width="640" height="360"
         frameborder="0" allowfullscreen></iframe>
   </div>

   <div class="" *ngIf="data.type==='shipper_shipment'">
      <iframe title="vimeo-player" src="https://player.vimeo.com/video/495648299" width="640" height="360"
         frameborder="0" allowfullscreen></iframe>
   </div>

   <div class="" *ngIf="data.type==='shipper_bid'">
      <iframe title="vimeo-player" src="https://player.vimeo.com/video/497807393" width="640" height="360"
         frameborder="0" allowfullscreen></iframe>
   </div>

   <div class="" *ngIf="data.type==='shipper_network'">
      <iframe title="vimeo-player" src="https://player.vimeo.com/video/497807393" width="640" height="360"
         frameborder="0" allowfullscreen></iframe>
   </div>

</div>
<!-- Address current location show for confirmation -->
<div *ngIf="data.openPop ==='currenLocationAddress'">
   <div class="text-center">
      <p class="fs_20 fsb_6 txt_b " style="margin: 0 0 2rem;">Location found </p>
      <p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;"></p>
      <div class="text-center">
         <button type="button" class="btn custum_button button_primary w-100 fs_16 fsm_5" (click)="onSuccess()"
            style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;">Confirm Address</button>
         <span class="fs_16 fsm_5 txt_lb pointer  px-4 py-2" (click)="onNoClick()">Cancel</span>
      </div>
   </div>
</div>
<!-- popup for mobile device -->
<div *ngIf="data.openPop ==='appDownLoad'">
   <div class="header_popup">
      <p class="fs_20 fsb_6 txt_b mb-0 line_before ">LaneAxis Mobile Application</p>
      <span class="close_popup_Icon" (click)="onNoClick()">
         <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
      </span>
   </div>
   <div class="v_scrollBar pop_vh1">
      <p class="fs_16 mb-3">Please Download LaneAxis Mobile Application from the Apple or Google store for a better
         experience.</p>
      <div class="app_icons">
         <a href="https://play.google.com/store/apps/details?id=com.laneaxis.app" target="_blank">
            <img src="{{serverPathForUrl}}/portal/images/after-login/google_playStore.png"
               class="img-fluid">
         </a>
         <a href="https://apps.apple.com/us/app/laneaxis-network/id1470109173?ls=1" target="_blank">
            <img src="{{serverPathForUrl}}/portal/images/after-login/app_store.png"
               class="img-fluid">
         </a>
      </div>
   </div>
</div>

<!-- Success popu -->
<div class="inviteDriver_con" *ngIf="data.openPop ==='shipmentSuccessShipperUpdate'">
   <div class="text-center">
      <span class="material-icons" style="margin: 0 0 1.5rem;font-size: 48px;color: var(--clGrna);">check_circle</span>
      <p class="fs_20 fsb_6 txt_b " style="margin: 0 0 2rem;">Congratulations</p>
      <p class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">
         Load with id <span class="txt_b">#{{data.shipmentUniqueId}}</span>
         has been successfully updated.<span class="txt_b"></span>
      </p>
      <div class="text-center">
         <button type="submit" class="btn custum_button button_success w-100 text-capitalize fs_16 fsm_5"
             (click)="onNoClick()"
            style="margin: 0 0 1.5rem; height: 40px; border-radius: 30px; box-shadow: none;" 
            [routerLink]="(data?.shipmentStatus =='offerRate' || data?.shipmentStatus =='readyToBid') ? ['/load/view/',data.shipmentId,'details'] : ['/load/view/',data.shipmentId,'details']">View Details</button>
      </div>
   </div>
</div>


<div *ngIf="data.openPop ==='invoicePopup'">
   <form class="mb-0 custum_input_css inputInPopup" [formGroup]="sendInvoiceFrom"
      (ngSubmit)="sendInvoiceFromSubmit(sendInvoiceFrom)" autocomplete="off">
      <div class="inviteDriver_popQW">
         <div class="header_popup">
            <p class="fs_20 fsb_6 txt_b line_before ">Send Invoice to Email</p>
            <span class="close_popup_Icon" (click)="onNoClick()">
               <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
            </span>
         </div>
         <p class="fs_14 fsn_4 txt_lb">Please enter the email address to whom you want to send invoice on email.</p>
         <div class="">
            <p class="fs_14 fsn_4 txt_lb mb-1">Email Address<span class="txt_d"> *</span></p>
            <mat-form-field appearance="none">
               <input matInput type="text" formControlName="emailAddress" placeholder="Enter Email Address"
                  class="form-control custom_input" maxlength="129" />
               <mat-error *ngIf="sendInvoiceFrom.get('emailAddress').errors?.['required']" class="error_mess"> Email is
                  required.
               </mat-error>
               <mat-error *ngIf="sendInvoiceFrom.get('emailAddress').errors?.['email']" class="error_mess"> Please enter
                  valid
                  email address.</mat-error>
            
            </mat-form-field>
         </div>
         <div class="">
            <p class="fs_14 fsn_4 txt_lb mb-1">Message</p>
            <mat-form-field appearance="none">
               <textarea matInput type="text" formControlName="message" placeholder="Enter something here..."
                  class="form-control custom_input" rows="3" maxlength="256"></textarea>
               <mat-error *ngIf="sendInvoiceFrom.get('message').errors?.['maxlength']" class="error_mess">Email must not
                  exceed 255 characters.</mat-error>
            </mat-form-field>
         </div>
         <p class="fs_14 fsn_4 txt_d" style="margin: -1rem 0 2rem" *ngIf="errorMessage">{{errorMessage}}</p>
         <div class="text-center">
            <button type="submit" class="btn custum_button button_primary w-100 fs_16 fsm_5 "
               style="margin: 0 0 1.5rem; height: 40px;">Send Email</button>
            <span class="fs_16 fsm_5 txt_lb pointer px-4 py-2 " type="button" (click)="onNoClick()">Cancel</span>
         </div>
      </div>
   </form>
</div>
<!--subscription popup -->
<div *ngIf="data.openPop ==='SubscriptionActionPopup'" class="invitations_status_pop">
   <div class="header_popup">
      <p class="fs_20 fsb_6 txt_b line_before ">Oh No! Thinking about leaving?</p>
      <span class="close_popup_Icon" (click)="onNoClick()">
         <img src="{{serverPathForUrl}}/portal/icon/ic_close_white.svg" class="close_Icon">
      </span>
   </div>
    <div class="v_scrollBar" style="max-height: 80vh;">
      <div class="cn_sub_header">
         <p>We would really hate to see you go and we'd love to keep your support while we do everything possible to grow a brokerless future for supply chain.</p>
         <p *ngIf="userType=='CARRIER'">Direct Contract Invites Balance </p>
         <p *ngIf="userType=='SHIPPER'">Safety Report Credit Balance </p>
         <p *ngIf="userType=='CARRIER'">{{data?.otherData?.invitationCount}} Invitations Remaining</p>
         <p *ngIf="userType=='SHIPPER'">{{data?.otherData?.invitationCount}} Credits Remaining</p>
         <p>{{data?.otherData?.daysLeft}} Days Left As A Premium User</p>
         <p>If you cancel your subscription, you will be downgraded to free plan on  {{data?.otherData?.expiryDate | date: 'MMMM dd, yyyy'}}.</p>
      </div>
      <div class="isp_points">
         <ul class="list_style">
            <li class="m_l_5" *ngFor="let plan of data?.otherData?.planList?.benefits">
              <img src="{{plan?.iconUrl}}" class="check_icn">
              <span *ngFor="let language of plan.language" [ngClass]="language.isBold ? 'fsb_7 m_10' : 'm_10'"> <span >{{language?.text}}</span></span>
            </li>
          </ul>
      </div>
      <div class="cn_sub_footer">
        <span (click)="closePopUp()" class="btn custum_button button_primary mb-3">Settings</span>
        <span (click)="autoRenewOff()" class="btn custum_button btn_trans" *ngIf="data.type=='autoRenew'">Turn Off Now</span>
        <span (click)="cancelSubscription()" class="btn custum_button btn_trans" *ngIf="data.type=='subscription'">Cancel Subscription Now</span>
      </div>
    </div>
 </div>

 <!-- Success popup subscription -->
<div class="inviteDriver_con" *ngIf="data.openPop ==='CongratulationsPopupSubscription'">
   <div class="container">
      <div class="popup_card">
        <div style="position: relative;">
          <p class="heading_img">
            <img src="https://laneaxis.com/wp-content/uploads/2023/03/image-6.png">
          </p>
          <p class="heading">Congratulations!</p>
        </div>
        <div style="margin: 0 0 2rem;">
         
            <p *ngIf="!data?.subscriptionOverviewPage" class="congrate_txt">Your payment has been processed successfully. We've added {{data.loads}} Load credit(s) to your wallet.</p>
            <p *ngIf="data?.subscriptionOverviewPage && userType=='CARRIER'" class="congrate_txt">Additional invite credits has been added to your account</p>
            <p *ngIf="data?.subscriptionOverviewPage && userType=='SHIPPER'" class="congrate_txt">Additional Safety Report Credits added to your account</p>
        </div>
        <div class="text-center" style="margin: 0 0 1rem;">
          <button class="btn custum_button button_primary" (click)="onSuccessCredits()">OK</button>
        </div>
      </div>
    </div>
</div>

<!-- Please Review or verify or edit route popup -->
<div class="container v_scrollBar"  style="max-height: 80vh;" *ngIf="data.openPop ==='routeVerify'">
   <div class="edit_route_popup">
       <span  *ngIf="notVerifiedLanes.length" class="material-icons-outlined close_icon pointer" (click)="closePoup()"> close </span>
       <div>
           <p class=" fs_21 fw_500">Please review or verify or edit route</p>
       </div>
       <div >
      <div class="pic_loc_box" *ngFor="let i of generateFake(this.data.contain.length-1)">
         <div class="row">
            <div class="in_pic_txt col-md-3">
               <p class="pic_loc_count">{{this.data.contain[i].title}} {{this.data.contain[i].sequence}}</p>
               <p class="pic_loc"><span>{{this.data.contain[i].locationLongName}} </span></p>
            </div>
            <div class="status_br col-md-3">
               <div class="p_dot" *ngFor="let fake of generateFake(5)"></div>
            </div>
            <div class="in_pic_txt col-md-3">
               <p class="pic_loc_count">{{this.data.contain[i+1].title}} {{this.data.contain[i+1].sequence}}</p>
               <p class="pic_loc"><span>{{this.data.contain[i+1].locationLongName}}</span></p>
            </div>
            <div class="col-md-3" style="display: flex; text-align: start; justify-content: right;">
               <div class="very_status">
                  <span *ngIf="routeStatus[i]=='Verified'" class="material-icons-outlined"> check_circle_outline </span>
                  <span *ngIf="routeStatus[i]!='Verified'" style="color: #FC0A0A"
                     class="material-icons-outlined"> error</span>
                    
                  <span [ngClass]="routeStatus[i]=='Verified' ? 'very_txt' : 'not_very_txt'">{{routeStatus[i]}}</span>
               </div>
            </div>
         </div>
      </div>
       <div *ngIf="notVerifiedLanes.length" class="error_massage"><p >No route available for lane : <span (click)="closePoup()" *ngFor="let location of notVerifiedLanes, let index = index" class="txt_p pointer">{{trimNoRoute(location,index)}}</span></p></div>
       <div  *ngIf="notVerifiedLanes.length" (click)="closePoup()" class="edit_route_btn"><button class="btn fw_500 txt_w">Update & Review</button></div>
   </div>
   </div>
</div>


<!-- Success verify Route -->
<div *ngIf="data.openPop ==='verifiedRoute'" class="text-center">
   <span class="material-icons" style="margin: 0 0 1.5rem;font-size: 48px;color: var(--clGrna);">check_circle</span>
   <p class="fs_20 fsb_6 txt_b " style="margin: 0 0 2rem;">Congratulations</p>
   <p *ngIf="!data?.subscriptionOverviewPage" class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">All routes have been verified and lane
     with ID <span class="txt_b">{{data.id}} </span> created successfully.</p>
      <button type="submit" class="btn custum_button button_success w-100 text-capitalize fs_16 fsm_5"
         style="margin: 0 0 1.5rem; height: 40px; max-width: 300px;" (click)="onSuccessLanes()">Okay</button>
</div>

<!-- Success verify Route -->
<div *ngIf="data.openPop ==='updateRoute'" class="text-center">
   <span class="material-icons" style="margin: 0 0 1.5rem;font-size: 48px;color: var(--clGrna);">check_circle</span>
   <p class="fs_20 fsb_6 txt_b " style="margin: 0 0 2rem;">Congratulations</p>
   <p *ngIf="!data?.subscriptionOverviewPage" class="fs_16 fsn_4 txt_lb" style="margin: 0 0 2rem; line-height: 24px;">All routes have been verified and lane with ID <span class="txt_b">{{data.id}} </span>
      updated successfully.</p>
      <button type="submit" class="btn custum_button button_success w-100 text-capitalize fs_16 fsm_5"
         style="margin: 0 0 1.5rem; height: 40px; max-width: 300px;" (click)="onSuccessLanes()" >Okay</button>
</div>

<!-- Equipment matching alert -->
<div *ngIf="data.openPop ==='equipmentNotMatch'" class="text-center" id="equipment_type_popup">
   <span  (click)="onNoConfirmClick()" class="material-icons-outlined close_icon pointer">
      close
      </span>
   <div class="fs_20 fsb_6 line_before mb-3"> Alert</div>
   <p *ngIf="!data?.subscriptionOverviewPage" class="fs_16 fsn_4 txt_lb">Equipment Type <span style="font-weight: bold;">{{this.data?.contain?.equipmentName}}</span> is required on this Lane. The rate on this Lane will be locked in for the Duration of <span style="font-weight: bold;">{{this.data?.contain?.lockPeriod ? this.data?.contain?.lockPeriod > 1 ? this.data?.contain?.lockPeriod +' Months' :this.data?.contain?.lockPeriod +' Month':'-' }}.</span><span *ngIf="this.data?.contain?.rateType=='1'"> By submitting a quote, you are confirming to the terms and Equipment type required on the Lane. </span><span *ngIf="this.data?.contain?.rateType=='2'"> By accepting a fixed rate, you are confirming to the terms and Equipment type required on the Lane.</span>  </p>
   <div class="equip_popup_btn">
      <button type="submit" class="btn custum_button button_success text-capitalize fs_16 fsm_5"
        (click)="onConfirmationClick()">Continue</button>
    <button (click)="onNoConfirmClick()"  class="btn custum_button button_primary text-capitalize fs_16 fsm_5 mt-3">back</button>
   </div>
</div>

<!-- Equipment Notification -->
<div *ngIf="data.openPop ==='equipmentNotMatchShipment'" class="text-center" id="equipment_type_popup">
   <span  (click)="onNoConfirmClick()" class="material-icons-outlined close_icon pointer">
      close
      </span>
   <div class="fs_20 fsb_6 line_before mb-3 "> Alert</div>
   <p *ngIf="!data?.subscriptionOverviewPage" class="fs_16 fsn_4 txt_lb">Equipment Type <span style="font-weight: bold;">{{this.data.contain.equipmentName}}</span> is required on this Load.<span *ngIf="this.data.contain.isOfferRate=='N'"> By submitting a quote, you are confirming to the terms and Equipment type required on the Load.</span><span *ngIf="this.data.contain.isOfferRate!='N'"> By accepting an offer rate, you are confirming to the terms and Equipment type required on the Load.</span>  </p>
   <div class="equip_popup_btn">
      <button type="submit" class="btn custum_button button_success text-capitalize fs_16 fsm_5"
        (click)="onConfirmationClick()">Continue</button>
    <button (click)="onNoConfirmClick()"  class="btn custum_button button_primary text-capitalize fs_16 fsm_5 mt-3">back</button>
   </div>
</div>

<!-- Driver Fine notice  -->
<div *ngIf="data.openPop ==='driverMobileNotification'" id="equipment_type_popup">
   <span  (click)="onNoConfirmClick()" class="material-icons-outlined close_icon pointer">
      close
      </span>
   <div class="fs_20 fsb_6 line_before mb-3 "> Alert</div>
   <p  class="fs_16 fsn_4 txt_lb">All loads require LaneAxis app tracking. As the "Carrier," it is your responsibility to inform your drivers of the requirements to complete loads using the app. </p>

   <p> When adding new drivers, they will receive a link to download the LaneAxis App . Before being assigned a load, drivers must upload their CDL and complete the basic necessary enrollment information. It is important to note that each load requires the driver to complete all items on the pickup and delivery checklist.</p>
      <ul class="loads_alret_list">
      <li>Arrived at Pickup/Delivery Location</li>
      
      <li>Loading/Unloading</li>
      
      <li>Loading/Unloading Complete</li>
      
      <li>Upload BOL/Signed POD</li>
      
      <li>Start/Stop Tracking/Navigation</li>
   </ul>
      
    <p class="fs_16 fsn_4 txt_lb">  Once the checklists are completed and the delivery location is verified via GPS, the final payment will be released. 
      Failure to use the app as it is intended throughout the delivery will result in a $200 fine and could delay final payment.</p>
   <div class="equip_popup_btn">
      <button type="submit" class="btn custum_button button_success text-capitalize fs_16 fsm_5"
        (click)="onConfirmationClick()">Continue</button>
    <button (click)="onNoConfirmClick()"  class="btn custum_button button_primary text-capitalize fs_16 fsm_5 mt-3">back</button>
   </div>
</div>

<!-- Edit profile -->
<div *ngIf="data.openPop ==='editLaneLocation'" style="position: relative;">
   <span class="material-icons-outlined close_icon pointer P_absolute" (click)="closePoup()"> close </span>
   <p class=" fs_21 fw_500 line_before mb-3">Edit Lane Location</p>
   <form [formGroup]="EditLaneForm" (ngSubmit)="onEditLanes(EditLaneForm)">
      <p class="fs_14 fsn_4 txt_lb mb-1">Select Location<span class="text-danger ml_5"> *</span> </p>
   <mat-form-field appearance="none" style="position: relative;">
      <input
         matTooltip="Network Carriers will only see pickup and dropoff cities, but exact addresses will only be shown to Carrier who is assigned the load."
         matInput ngx-gp-autocomplete (onAddressChange)="editLaneAutoCompleteSelected($event)"
         (input)="noAutoselection('editlane')" [options]="searchRestriction" (focusout)="noFocusOut('editlane')"
        
         [value]=" EditLaneForm.get('editLocation').value" formControlName="editLocation"
         maxlength="257" placeholder="Enter Exact Address" class="form-control custom_input"
         [options]="searchRestriction" required />
         <small *ngIf="submitted && EditLaneForm.get('editLocation').errors?.['required']" class="error_mess">Location is required.</small>
         <mat-error *ngIf="!editLaneAutoNot && EditLaneForm.get('editLocation').errors?.['pattern']" class="error_mess"> First letter should not be space.</mat-error>
         <small class="city_labl_address error_mess" *ngIf="pickupCityError && EditLaneForm.value.editLocation"> {{ pickupCityError }}</small>
         <small class="city_labl_address error_mess" *ngIf="shortNameValue && EditLaneForm.value.editLocation"> {{ shortNameError }}</small>
         <mat-error *ngIf="!editLaneAutoNot && EditLaneForm.get('editLocation').errors?.['maxlength']" class="error_mess"> Only 256 characters are allowed.</mat-error>
      </mat-form-field>
      <button type="submit" class="btn custum_button  w-100 text-capitalize fs_16 fsm_5 bg_p text-white" [disabled]="shortNameValue"
         style="height: 40px;
         margin: 0 auto;
         text-align: center;
         display: flex;
         max-width: 200px;
         align-items: center;
         justify-content: center;">Update</button>
      </form>
</div>


<div *ngIf="data.openPop ==='editLaneLocationBidQuest'" style="position: relative;">
   <span class="material-icons-outlined close_icon pointer P_absolute" (click)="closePoup()"> close </span>
   <p class=" fs_21 fw_500 line_before mb-3">Edit Lane Location</p>
   <form [formGroup]="editBidQuoteForm" (ngSubmit)="onEditBidQuots(editBidQuoteForm)">
      <p class="fs_14 fsn_4 txt_lb mb-1">Select Location<span class="text-danger ml_5"> *</span> </p>
   <mat-form-field appearance="none" style="position: relative;">
      <input
         matTooltip="Network Carriers will only see pickup and dropoff cities, but exact addresses will only be shown to Carrier who is assigned the load."
         matInput ngx-gp-autocomplete (onAddressChange)="editBidAutoCompleted($event)"
         (input)="noAutoselection('editlane')" (focusout)="noFocusOut('editlane')"
         [value]="editBidQuoteForm.get('editLocation').value" formControlName="editLocation"
         maxlength="257" placeholder="Enter Exact Address" class="form-control custom_input"
         [options]="searchRestriction" required />
         <small *ngIf="submitted && editBidQuoteForm.get('editLocation').errors?.['required']" class="error_mess">Location is required.</small>
         <mat-error *ngIf="!editLaneAutoNot && editBidQuoteForm.get('editLocation').errors?.['pattern']" class="error_mess"> First letter should not be space.</mat-error>
         <small class="city_labl_address error_mess" *ngIf="pickupCityError && editBidQuoteForm.value.editLocation"> {{ pickupCityError }}</small>
         <small class="city_labl_address error_mess" *ngIf="shortNameValue && editBidQuoteForm.value.editLocation"> {{ shortNameError }}</small>
         <mat-error *ngIf="!editLaneAutoNot && editBidQuoteForm.get('editLocation').errors?.['maxlength']" class="error_mess"> Only 256 characters are allowed.</mat-error>
      </mat-form-field>
      <button type="submit" class="btn custum_button  w-100 text-capitalize fs_16 fsm_5 bg_p text-white" [disabled]="shortNameValue"
         style="height: 40px;
         margin: 0 auto;
         text-align: center;
         display: flex;
         max-width: 200px;
         align-items: center;
         justify-content: center;">Update</button>
      </form>
</div>

<!-- Success verify Route -->
<div *ngIf="data.openPop ==='confirmLanenotShipment'" class="text-center" id="equipment_type_popup">
      <span _ngcontent-gjd-c148="" class="material-icons check_circle">check_circle</span>
   <div class="fs_20 fsb_6 mb-3 ">Lane Confirmed</div>
   <p *ngIf="!data?.subscriptionOverviewPage" class="fs_16 fsn_4 txt_lb mb-3">Rate has been confirmed. When a new shipment is created and ready to be assigned, you will be notified. This is not a shipment confirmation, only a rate confirmation for this particular lane.</p>
   <div class="equip_popup_btn">
      <button type="submit" class="btn custum_button button_success text-capitalize fs_16 fsm_5"
        (click)="onConfirmationClick()">Ok</button>
   </div>
</div>


<div *ngIf="data.openPop=='openBidQuote'" class="text-center" id="equipment_type_popup">
   <span (click)="onNoConfirmClick()" class="material-icons-outlined close_icon pointer">
      close
      </span>
      <img _ngcontent-mmd-c421="" src="https://s3.us-east-2.amazonaws.com/staticdev.laneaxis.com/portal/images/subscription/premium-plan.png" class="img-fluid pre_ap_img">
   <div class="fs_20 fsb_6 mb-3 ">JOIN CARRIER COMMUNITY PLAN</div>
   <p  class="fs_16 fsn_4 txt_lb mb-3">
   <quote>Your subscription is over. Please upgrade to LaneAxis Premium to avail quote assistant and more.</quote></p>
   <div class="equip_popup_btn">
      <button type="submit" class="btn custum_button button_primary text-capitalize fs_16 fsm_5"
        (click)="purchaseNow()">Upgrade Now</button>
   </div>
</div>

<ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px',fullScreenBackdrop:true }"></ngx-loading>