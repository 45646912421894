import { Component, OnInit, Input } from '@angular/core';
import { CommonService } from '../../commons/service/common.service';
import { AppSettings } from '../../commons/setting/app_setting';
import { SharedService } from '../../commons/service/shared.service';
// import { MapsAPILoader } from '@agm/core';
import { environment } from '../../../environments/environment';
import * as moment from 'moment';

@Component({
  selector: 'app-trip-history',
  templateUrl: './trip-history.component.html',
  styleUrls: ['./trip-history.component.css'],
})
export class TripHistoryComponent implements OnInit {
  @Input() shipmentId: any;
  @Input() status: any;
  @Input() typeOfSection: any;
  public shipmentHistoryGet: any;
  public shipmentHistoryGetTrimmed: any;
  public skeletonLoaderhistory = false;
  public showLocationLoader = false;
  public geoCoder: any;
  public showDetail=false;
  public checkRoutePlan=true;
  public checkShipmentEdit=true;
  public serverPathForUrl: any;

  constructor(
    public sharedService: SharedService,
    public commonService: CommonService,
    // private mapsAPILoader: MapsAPILoader
  ) { }

  ngOnInit() {
    this.serverPathForUrl = environment.serverPathForUrl;

    // this.mapsAPILoader.load().then(() => {
    //   this.geoCoder = new google.maps.Geocoder();
    // });
    this.skeletonLoaderhistory = true;
    this.shipmentHistory( null, true, true);
  }

  public generateFake(count: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes;
  }

  shipmentHistory(params = {}, waypoint, shipment) {
    this.skeletonLoaderhistory = true;
    let uri = null;
    params = {
      shipmentId: this.shipmentId,
    };
    //get dynamic URI for APIs
    if (params) uri = this.commonService.getAPIUriFromParams(params);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.SHIPMENT.SHIPMENTHISTORY,
      uri: uri,
    };
    this.commonService.getList(APIparams).subscribe(
      (ServerRes) => {
        if (ServerRes.success === true) {
          this.shipmentHistoryGet = ServerRes.response;
          for (var docIndex in this.shipmentHistoryGet) {
            this.shipmentHistoryGet[docIndex].createdAt = new Date(
              this.shipmentHistoryGet[docIndex].createdAt + ' ' + 'UTC'
            );
            this.shipmentHistoryGet[docIndex].currentLocation = null;
            this.shipmentHistoryGet[docIndex].showLocation = 'hide';
          }
          this.skeletonLoaderhistory = false;
        } else {
          this.shipmentHistoryGet = [];
          this.skeletonLoaderhistory = false;
        }
           this.Trimming(ServerRes.response,  waypoint, shipment)

      },
      (error) => {
        this.shipmentHistoryGet = [];
        this.skeletonLoaderhistory = false;
      }
    );
  }

  async showLocation( lati:any, longi:any ,firstIndex: any , secondIndex: any , thirdIndex: any, typeofArray:any) {
    let lat, long;
    this.showLocationLoader = true;
   
    if(typeofArray != 'action')
    {
      this.shipmentHistoryGet[firstIndex].points[secondIndex][typeofArray][thirdIndex].showLocation = 'show';
      lat = this.shipmentHistoryGet[firstIndex].points[secondIndex][typeofArray][thirdIndex].locationLat
      long = this.shipmentHistoryGet[firstIndex].points[secondIndex][typeofArray][thirdIndex].locationLong
      if (lat && long) {
        this.shipmentHistoryGet[firstIndex].points[secondIndex][typeofArray][thirdIndex].currentLocation =
          await this.showPosition(lat, long);
      } else {
        this.shipmentHistoryGet[firstIndex].points[secondIndex][typeofArray][thirdIndex].currentLocation =  'No Location shared';
      }}
      else 
      {
        lat = this.shipmentHistoryGet[firstIndex].points[secondIndex].locationLat
        long = this.shipmentHistoryGet[firstIndex].points[secondIndex].locationLong
        this.shipmentHistoryGet[firstIndex].points[secondIndex].showLocation = 'show';
      if (lat && long) {
          this.shipmentHistoryGet[firstIndex].points[secondIndex].currentLocation =
          await this.showPosition(lat, long);
      } else {
        this.shipmentHistoryGet[firstIndex].points[secondIndex].currentLocation =  'No Location shared';
      }

      }
    this.showLocationLoader = false;
  }

  hideLocation(firstIndex: any , secondIndex: any , thirdIndex: any, typeofArray:any) {
    if(typeofArray != 'action') {
      this.shipmentHistoryGet[firstIndex].points[secondIndex][typeofArray][thirdIndex].showLocation = 'hide';
      this.shipmentHistoryGet[firstIndex].points[secondIndex][typeofArray][thirdIndex].currentLocation =  '';
    }
      else {
       this.shipmentHistoryGet[firstIndex].points[secondIndex].showLocation = 'hide';
       this.shipmentHistoryGet[firstIndex].points[secondIndex].currentLocation =  '';
    }

  }

  showUpdatedShipment(firstIndex: any, secondIndex:any) {
    this.showLocationLoader = true;
    this.shipmentHistoryGet[firstIndex].points[secondIndex].showDetail = 'show'
    this.showDetail=true;
    this.showLocationLoader = false;
  }

  hideHistory(firstIndex: any, secondIndex:any) {
    this.shipmentHistoryGet[firstIndex].points[secondIndex].showDetail = 'hide'
  }

  trimForCss(className)
  {
    return 'cl_'+className.slice(1);
  }



  showPosition(lat, lng) {
    let addressDriver = [];
    const apiKey = 'AIzaSyAMMBwZYg03hSwJEnODnIWf14YJKjWz_2A';
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;
    let that = this;
    if(!lat || !lng)
      {
        return
      }
    return new Promise((resolve, reject) => {
    fetch(url)
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(response => {
      let results = response?.results
      let newname = '';
      if (results[0].address_components[3] !== undefined) {
        newname = results[0].address_components[3].long_name;
      }else if (results[1]?.address_components[3]?.long_name !== undefined) {
        newname = results[1].address_components[3].long_name;
      }  else {
        newname = '';
      }
      resolve(results[0].formatted_address ? results[0].formatted_address : results[1].formatted_address);
    })
    .catch(error => {
      console.error('Error fetching address:', error);
      resolve('No Location shared');
    })
    });
  }


  FilterHistory()
  {
    this.shipmentHistory(null, this.checkRoutePlan, this.checkShipmentEdit)
  }

  Trimming(getValue, waypoint, shipment)
  {
    let trimmedObject=getValue;
    const mainArr = []
    for(let i=0; i < trimmedObject.length; i++)
    {
          const removePointsIndex = []
          trimmedObject[i].points.forEach(function (value, index) {
            if (value.actionType && value.actionType == 'waypointEdit' && !waypoint) {
                      removePointsIndex.push(index)
                  }
                  if (value.actionType &&  value.actionType == 'shipmentEdit' && !shipment ) {
                    removePointsIndex.push(index)
                }
                  
        });
          for (let y = removePointsIndex.length -1; y >= 0; y--) {
            trimmedObject[i].points.splice(removePointsIndex[y], 1);
          }
          trimmedObject[i].points = trimmedObject[i].points;
          if(trimmedObject[i].points.length)
          mainArr.push(trimmedObject[i])
    }
    this.shipmentHistoryGetTrimmed = mainArr;
  }

  classFunction(event)
  {
    let splitUrl = event.split('/')
   return splitUrl[splitUrl.length-1].split('.')[0]
  }

  UTCDateOnly(date: any) {
    date = moment(date).format('MM/DD/YYYY')
   date = new Date(
    date + ' ' + 'UTC'
    );
    return moment(date).local().format('MMM DD, YYYY ');
  }

  UTCtime(date: any) {
    date = new Date(
      date + ' ' + 'UTC'
      );
    return moment(date).local().format('HH:mm');
  }
  
}
