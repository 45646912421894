import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { DisputeRoutingModule } from './dispute-routing.module';
import { DisputeComponent } from './dispute/dispute.component';
import { MatTabsModule } from '@angular/material/tabs';
import { NgxLoadingModule } from 'ngx-loading';
// import { AgmCoreModule } from '@agm/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from '../material.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { LoadsSharedModule } from '../loads-shared/loads-shared.module';

@NgModule({
  declarations: [
    DisputeComponent
  ],
  imports: [
    CommonModule,
    DisputeRoutingModule,
    CommonModule,
    MatTabsModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgxLoadingModule,
    LoadsSharedModule,
    // AgmCoreModule.forRoot({
    //   apiKey: 'AIzaSyAMMBwZYg03hSwJEnODnIWf14YJKjWz_2A',
    //   libraries: ['places'],
    // }),
    // MatGoogleMapsAutocompleteModule,
    NgxSkeletonLoaderModule,
    MaterialModule,
    InfiniteScrollModule,
    SharedModule
  ],
  providers: [DatePipe],
  exports: [],
})
export class DisputeModule { }
