// import { MapsAPILoader } from '@agm/core';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { AlertService } from 'src/app/commons/service/alert.service';
import { NetworkPopupComponent } from 'src/app/network-shared/network-popup/network-popup.component';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/commons/service/common.service';
import { SharedService } from 'src/app/commons/service/shared.service';
import { AppSettings } from 'src/app/commons/setting/app_setting';
import { StatusSetting } from 'src/app/commons/setting/status_setting';
import { SubscriptionPopupComponent } from 'src/app/shared/subscription-popup/subscription-popup.component';

@Component({
  selector: 'app-dispute',
  templateUrl: './dispute.component.html',
  styleUrls: ['./dispute.component.scss']
})
export class DisputeComponent implements OnInit {
  public currentStatus = 'newDispute';
  public currentRouteParams: any;
  public searchModel: any = { pickupLocation: '', dropoffLocation: '' };
  countSpace: any;
  searchBoxError: boolean;
  public userDetails: any;
  page = 1;
  totalPage = 1;
  public shipmentList = [];
  public spinnerLoader = false;
  public dataNotFound = false;
  public tabCount: any;
  public totalShipment: any;
  public params: any;
  public skeletonLoader = false;
  public radius: any;
  public userType: any;
  public subscriptionData:any;
  public dropoffLocationShow: any;
  public pickupLocationShow: any;
  public pickupLocationRandom: any;
  public droupLocationRandom: any;
  public randomPickupTextError = false;
  public randomDropupTextError = false;
  public checkSelfDisabled = false;
  public dropoffLocationNew: any;
  public pickupLocationNew: any;
  public showAdvancedFilter = false;
  public equipmentListing: any = [];
  public driverList: any = [];
  public finalStatus = [];
  public geoCoder: any;
  public loading = false;
  public shipmentStatus: any;
  public shipmentStatusType: any;
  public titleStatus = '';
  public totalFilter: any;
  public pickupLatitude: number;
  public pickupLongitude: number;
  public dropLatitude: number;
  public dropLongitude: number;
  public selfId: any;
  public selfchecked = false;
  public driverDisabled = false;
  public currentSearchStatus: any;
  /*Filter*/
  public titleFilter: any;
  public pickupDateFilter: any;
  public pickupDate: any;
  public dropDateFilter: any;
  public dropDate: any;
  public statusFilter: any;
  public statusFilterShow: any;
  public statusLabelShow: any;
  public statusLabel: any;
  public equipmentTypeFilter: any;
  public equipmentName: any;
  public equipmentNameShow: any;
  public equipmentTypeId: any;
  public batchFilter: any;
  public driverFilter: any;
  public driverName: any;
  public driverId: any;
  public driverNameShow: any;
  public commonDriverId: any;
  public carrierName: any;
  public carrierId: any;
  public carrierNameShow: any;
  public selected;
  public pickLoc = false;
  public dropLoc = false;
  public carrierList: any = [];
  public serverPathForUrl: any;
  public searchRestriction: any;
  public selectedIndex = 0;
  public sidePanelData: any;
  public sidePanelProfileDataId: any;
  public sidePanelProfileData:any;
  public userName: any;
  public invitationTimer = 0;
  public timeCount: any;
  public assignUserData: any;
  public currentUrlTab: any;
  public tabActionDisabled = false;
  public getwindow: any;
  public cancelRequestUser: any = null;
  public showSidePanel: any;
  isAwaitingCount: boolean = false;
  awaitingCount: any;
  isConfirmedCount: boolean = false;
  confirmedCount: any;
  isNavigateToPickupCount: boolean = false;
  navigateToPickupCount: any;
  isDeliveredCount: boolean = false;
  isTotalShipmentCounts: boolean = false;
  deliveredCount: any;
  totalShipmentCounts: any;
  totalDisputCounts: any;
  isExpiredCount : any;
  expiredCount: any; 
  isDispute: boolean = false;
  disputeCount: any;
  isTotalDisputeCounts: boolean = false;

  constructor(
    private commonService: CommonService,
    // private mapsAPILoader: MapsAPILoader,
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private alertService: AlertService,
  ) { 
  }

  async ngOnInit() {
    this.userType = localStorage.getItem('user_type');
    this.userName = localStorage.getItem('userName');
    this.skeletonLoader = false;
    this.markReadAlertNotification('availableToBid');
    const body: any = document.getElementsByTagName('body')[0];
    body.classList.remove('network_sugBox');
    let userDetail: any;
     userDetail = this.sharedService.getAddress();
      if (userDetail !== null) {
        this.userDetails = userDetail;
        this.userInfo(userDetail);
      }

      this.commonService.userData.subscribe((userData) => {
        if (userData && this.userDetails === undefined) {
          this.userInfo(userData);
        }
      });

    this.serverPathForUrl = environment.serverPathForUrl;
    let autoCompleteOptions = {
      componentRestrictions: { country: environment.searchMultipleRestriction }
    };
    this.searchRestriction = autoCompleteOptions

    if (this.userType === 'SHIPPER') {
      this.getActiveSubscription();
    }
    let configValue = this.sharedService.getConfig();
    if (configValue !== undefined) {
      this.equipmentListing = configValue.equipmentTypes;
      this.getConfigSet(configValue);
    }

    this.commonService.configData.subscribe((userData) => {
      this.getConfigSet(userData);
    });

   

    this.params = {
      limit: 12,
    };

    this.shipmentStatusType = StatusSetting.shipmentTypeArray;
    if (localStorage.getItem('user_type') === 'CARRIER') {
      this.driverList = await this.driverListRecord();
    }
    if (localStorage.getItem('user_type') === 'SHIPPER') {
      this.getConnected();
    }
  }

  ngOnDestroy()
  {
    const body = document.getElementsByClassName('sm_navbar_hide')[0];
    body.classList.remove('side_panel_manage');
  }

  // mark read notification for badge 
  markReadAlertNotification(value: any) {
    if (value == "newDispute") {
      value = "dispute";
      this.commonService.putAlertNotificationOnRead(value).subscribe((data) => {
        if (data.response) {
          if (data.response.assign !== 0 || data.response.assignToCarrier !== 0 || data.response.create !== 0) {
            this.awaitingCount = data.response.assign + data.response.assignToCarrier + data.response.create;
            this.isAwaitingCount = true;
          } else {
            this.isAwaitingCount = false;
          }
          if (data.response.accept !== 0) {
            this.confirmedCount = data.response.accept;
            this.isConfirmedCount = true;
          } else {
            this.isConfirmedCount = false;
          }
          if (data.response.navigateToPickup !== 0) {
            this.navigateToPickupCount = data.response.navigateToPickup;
            this.isNavigateToPickupCount = true;
          } else {
            this.isNavigateToPickupCount = false;
          }
          if (data.response.delivered !== 0 || data.response.cancel !== 0) {
            this.deliveredCount = data.response.delivered + data.response.cancel;
            this.isDeliveredCount = true;
          } else {
            this.isDeliveredCount = false;
          }
          if (data.response.expired !== 0) {
            this.expiredCount = data.response.expired;
            this.isExpiredCount = true;
          } else {
            this.isExpiredCount = false;
          }
          if (data.response.dispute !== 0) {
            this.disputeCount = data.response.dispute;
            this.isDispute = true;
          } else {
            this.isDispute = false;
          }
          if (data !== null) {
            this.totalShipmentCounts = data.response.assign + data.response.assignToCarrier + data.response.create + data.response.accept + data.response.navigateToPickup + data.response.delivered + data.response.cancel + data.response.expired;
            this.totalDisputCounts = data.response.dispute;
          }
          if (this.totalShipmentCounts !== 0) {
            this.isTotalShipmentCounts = true;
          } else {
            this.isTotalShipmentCounts = false;
          }
          if (this.totalDisputCounts !== 0) {
            this.isTotalDisputeCounts = true;
          } else {
            this.isTotalDisputeCounts = false;
          }
          this.sharedService.AlertNotifyService.next(this.totalDisputCounts + this.totalShipmentCounts);
        } else {
        }
      });
    } else {
      this.commonService.putAlertNotificationOnRead(value).subscribe((data) => {
        if (data.response) {
          if (data.response.assign !== 0 || data.response.assignToCarrier !== 0 || data.response.create !== 0) {
            this.awaitingCount = data.response.assign + data.response.assignToCarrier + data.response.create;
            this.isAwaitingCount = true;
          } else {
            this.isAwaitingCount = false;
          }
          if (data.response.accept !== 0) {
            this.confirmedCount = data.response.accept;
            this.isConfirmedCount = true;
          } else {
            this.isConfirmedCount = false;
          }
          if (data.response.navigateToPickup !== 0) {
            this.navigateToPickupCount = data.response.navigateToPickup;
            this.isNavigateToPickupCount = true;
          } else {
            this.isNavigateToPickupCount = false;
          }
          if (data.response.delivered !== 0 || data.response.cancel !== 0) {
            this.deliveredCount = data.response.delivered + data.response.cancel;
            this.isDeliveredCount = true;
          } else {
            this.isDeliveredCount = false;
          }
          if (data.response.expired !== 0) {
            this.expiredCount = data.response.expired;
            this.isExpiredCount = true;
          } else {
            this.isExpiredCount = false;
          }
          if (data.response.dispute !== 0) {
            this.disputeCount = data.response.dispute;
            this.isDispute = true;
          } else {
            this.isDispute = false;
          }
          if (data !== null) {
            this.totalShipmentCounts = data.response.assign + data.response.assignToCarrier + data.response.create + data.response.accept + data.response.navigateToPickup + data.response.delivered + data.response.cancel + data.response.expired;
            this.totalDisputCounts = data.response.dispute;
          }
          if (this.totalShipmentCounts !== 0) {
            this.isTotalShipmentCounts = true;
          } else {
            this.isTotalShipmentCounts = false;
          }
          if (this.totalDisputCounts !== 0) {
            this.isTotalDisputeCounts = true;
          } else {
            this.isTotalDisputeCounts = false;
          }
          this.sharedService.AlertNotifyService.next({ name: 'shipment', counts: this.totalShipmentCounts + this.totalDisputCounts });
        } else {
        }
      });
    }
  }

// Getting values from config API
getConfigSet(configValue: any) {
  this.equipmentListing = configValue.equipmentTypes;
}

// Function to get user info.
userInfo(userDtail) {
  this.userDetails = userDtail;
}

ngAfterViewInit() {
  this.route.queryParams.subscribe((params) => {
    if (Object.keys(params).length > 0) {
      if (params['pickupDate']) {
        this.pickupDate = params['pickupDate'];
      }
      if (params['dropDate']) {
        this.dropDate = params['dropDate'];
      }
      if (params['driverId']) {
        this.searchModel.driver = params['driverId'];
        this.driverId = params['driverId'];
        this.driverName = params['driverName'];
      }
      if (params['carrierId']) {
        this.searchModel.carrierId = params['carrierId'];
        this.carrierId = params['carrierId'];
        this.carrierName = params['carrierName'];
      }
      if (params['selfId']) {
        this.selfId = params['selfId'];
        this.selfchecked = true;
      }
      if (params['equipmentType']) {
        this.searchModel.equipmentId = parseInt(params['equipmentType']);
        this.equipmentTypeId = parseInt(params['equipmentType']);
        this.equipmentName = params['equipmentName'];
      }
      if (params['status']) {
        this.searchModel.status = params['status'];
        this.statusLabel = params['statuslabel'];
      }

      this.searchModel.title = params['title'];
      this.searchModel.batchId = params['batchId'];

      if (
        params['pickupLatitude'] !== null &&
        params['pickupLongitude'] !== null
      ) {
        this.pickupLatitude = params['pickupLatitude'];
        this.pickupLongitude = params['pickupLongitude'];
        this.pickupLocationNew = params['pickupAddress'];
      }
      if (
        params['dropLatitude'] !== null &&
        params['dropLongitude'] !== null
      ) {
        this.dropLatitude = params['dropLatitude'];
        this.dropLongitude = params['dropLongitude'];
        this.dropoffLocationNew = params['dropoffAddress'];
      }
    }
  });

  const data = this.route.snapshot.params['tabType'].split('?');
  this.filterByStatus(data[0]);
}

/*Search filter value*/
searchQuantity(event: any) {
  let searchStr = event.target.value;
  let lastword = searchStr.charAt(searchStr.length - 1);
  if (lastword === ' ') {
    this.countSpace = this.countSpace + 1;
  } else {
    this.countSpace = 0;
  }
  if (this.countSpace >= 2 || (lastword === ' ' && searchStr.length === 1)) {
    this.searchModel.title = this.searchModel.title.substring(
      0,
      this.searchModel.title.length - 1
    );
    this.searchBoxError = true;
  } else {
    this.searchBoxError = false;
  }
}

// apply filter for search 
applyFilterSearch() {
  if (this.userType == 'SHIPPER' && (this.subscriptionData?.isPremium != true || this.subscriptionData?.subscriptionStatus == 'canceled'))
  {
    this.getSubscriptionAlertPopup();
  } else {
  if (this.searchModel.title || this.titleFilter) {
    if (!this.searchModel.title && this.titleFilter) {
      this.titleFilter = null;
      this.searchModel.title = null;
      this.params.title = null;
    }
    this.applyFilterSubmit();
    var dimensions = null;
    if (this.searchModel.title !== null) {
      if (this.searchModel.title) {
        dimensions = 'searchText=' + this.searchModel.title;
      }
      var obj = {
        eventName: 'search_shipment',
        event_category: 'Shipment',
        Occurence: 'main',
        Dimensions: dimensions ? dimensions : '-',
        userType: localStorage.getItem('user_type'),
        EmailID: localStorage.getItem('email'),
      };
    }
    this.sharedService.track(obj);
  }
 }
}

 // function for page scrolling
 onScroll() {
  if (this.page < this.totalPage) {
    this.page = this.page + 1;
    this.getMoreData(null);
  }
}

// for select carries 
carrierSelected(carrier: any) {
  this.searchModel.carrierId = carrier.userId;
  this.carrierName = carrier.legalName;
  this.carrierId = carrier.userId;
}

// remove selected carriers 
emptyCarrier() {
  this.searchModel.carrierId = null;
  this.carrierName = null;
  this.carrierId = null;
}

// select drivers 
driverSelected(driver: any) {
  this.searchModel.driver = driver.userId;
  this.driverName = driver.name;
  this.driverId = driver.userId;
  this.checkSelfDisabled = true;
}

// remove selected drivers 
driverEmpty() {
  this.searchModel.driver = null;
  this.driverName = null;
  this.driverId = null;
  this.checkSelfDisabled = false;
}

// select equipmentType 
equipmentSelected(equipment: any) {
  this.searchModel.equipmentId = parseInt(equipment.id);
  this.equipmentName = equipment.label;
  this.equipmentTypeId = parseInt(equipment.id);
}

// remove selected equipmentType 
equipmentEmpty() {
  this.searchModel.equipmentId = null;
  this.equipmentName = null;
  this.params.equipmentType = null;
  this.equipmentTypeId = null;
}

// select shipment status 
statusSelected(statusType: any) {
  this.statusLabel = statusType.labelName;
}

// remove selected shipment status 
statusEmpty() {
  this.statusLabel = null;
  this.searchModel.status = null;
}


// Shipment Assigned to Self
selfSelected(event) {
  if (event.checked === true) {
    this.selfchecked = event.checked;
    this.driverDisabled = true;
  } else if (event.checked === false) {
    this.selfchecked = event.checked;
    this.driverDisabled = false;
  } else {
    this.selfchecked = event.checked;
    this.driverDisabled = false;
  }
}

getSubscriptionAlertPopup(){
  const dialogRef = this.dialog.open(SubscriptionPopupComponent, {
    disableClose: true,
    backdropClass: AppSettings.backdropClass,
    width: AppSettings.popWidth,
    data: { 
      openPop: 'subscriptionAlert',
      subscriptionData: this.subscriptionData
     },
  });
  dialogRef.afterClosed().subscribe((result) => {

  });
}


// for filter apply 
applyFilterSubmit() {
  this.randomPickupTextError = false;
  this.randomDropupTextError = false;
  if (
    (this.pickupLocationRandom && this.pickupLocationNew === null) ||
    (this.droupLocationRandom && this.dropoffLocationNew === null)
  ) {
    if (this.pickupLocationRandom && this.pickupLocationNew === null) {
      this.randomPickupTextError = true;
    }
    if (this.droupLocationRandom && this.dropoffLocationNew === null) {
      this.randomDropupTextError = true;
    }
  } else {
    this.skeletonLoader = false;
    this.shipmentList = [];
    this.tabCount = '';
    this.page = 1;
    if (this.showAdvancedFilter) {
      this.advanceFilter();
    } else {
      this.cancelFilterPopup();
    }
    if (this.searchModel.title) {
      this.params.title = this.searchModel.title;
      this.titleFilter = this.searchModel.title;
    }
    if (this.driverName) {
      this.params.driverId = this.searchModel.driver;
      this.driverNameShow = this.driverName;
      this.checkSelfDisabled = true;
    } else {
      this.params.driverId = null;
      this.driverNameShow = null;
      this.checkSelfDisabled = false;
      this.driverId = null;
    }
    if (this.carrierName) {
      this.params.carrierId = this.searchModel.carrierId;
      this.carrierNameShow = this.carrierName;
    } else {
      this.params.carrierId = null;
      this.carrierNameShow = null;
      this.carrierId = null;
    }
    if (this.equipmentName) {
      this.params.equipmentType = this.searchModel.equipmentId;
      this.equipmentNameShow = this.equipmentName;
      this.equipmentTypeId = this.searchModel.equipmentId;
    } else {
      this.params.equipmentType = null;
      this.equipmentNameShow = null;
      this.equipmentTypeId = null;
    }
    if (
      this.pickupLatitude &&
      this.pickupLongitude &&
      this.pickupLocationNew
    ) {
      this.pickupLocationShow = this.pickupLocationNew;
      this.searchModel.pickupLocation = this.pickupLocationNew;
      this.params.pickupLatitude = this.pickupLatitude;
      this.params.pickupLongitude = this.pickupLongitude;
      this.params.radius = 30;
    } else {
      this.params.pickupLatitude = null;
      this.params.pickupLongitude = null;
      this.pickupLatitude = null;
      this.pickupLongitude = null;
      this.params.radius = null;
      this.pickupLocationShow = null;
      this.searchModel.pickupLocation = null;
    }

    if (this.dropLatitude && this.dropLongitude && this.dropoffLocationNew) {
      this.dropoffLocationShow = this.dropoffLocationNew;
      this.searchModel.dropoffLocation = this.dropoffLocationNew;
      this.params.dropLatitude = this.dropLatitude;
      this.params.dropLongitude = this.dropLongitude;
      this.params.radius = 30;
    } else {
      this.dropLatitude = null;
      this.dropLongitude = null;
      this.dropoffLocationShow = null;
      this.params.dropLatitude = null;
      this.params.dropLongitude = null;
      this.params.radius = null;
    }
    if (this.statusLabel) {
      this.params.status = this.searchModel.status;
      this.statusLabelShow = this.statusLabel;
    } else {
      this.params.status = null;
      this.statusLabelShow = null;
      this.searchModel.status = null;
    }
    if (this.pickupDate) {
      this.params.pickupDate = this.pickupDate;
      this.pickupDateFilter = this.pickupDate;
      this.searchModel.pickupDate = new Date(this.pickupDate);

    } else {
      this.params.pickupDate = null;
      this.pickupDateFilter = null;
      this.searchModel.pickupDate = null;
    }
    if (this.dropDate) {
      this.params.dropDate = this.dropDate;
      this.dropDateFilter = this.dropDate;
      this.searchModel.dropDate = new Date(this.dropDate);
    } else {
      this.params.dropDate = null;
      this.dropDateFilter = null;
      this.searchModel.dropDate = null;
    }

    if (this.selfchecked) {
      this.commonDriverId = localStorage.getItem('user_id');
      this.selfId = localStorage.getItem('user_id');
      this.params.driverId = this.commonDriverId;
      this.driverDisabled = true;
    }

    if (!this.selfchecked && this.commonDriverId && this.selfId) {
      this.params.driverId = null;
      this.selfId = null;
      this.driverDisabled = false;
      this.commonDriverId = null;
    }

    if (
      this.totalFilter ||
      this.searchModel.title ||
      this.selfchecked ||
      this.carrierName ||
      this.dropDate ||
      this.pickupDate ||
      this.statusLabel ||
      (this.dropLatitude && this.dropLongitude) ||
      (this.pickupLatitude && this.pickupLongitude) ||
      this.equipmentName ||
      this.driverName ||
      this.carrierNameShow
    ) {
      this.currentSearchStatus = 'search';
    } else {
      this.currentSearchStatus = '';
    }
    this.list();
  }
  // Google Analytics code
  if (
    this.searchModel.pickupLocation !== null ||
    this.searchModel.dropoffLocation !== null ||
    this.carrierName !== null ||
    this.statusLabel !== null ||
    this.equipmentName !== null ||
    this.searchModel.dropDate !== null ||
    this.searchModel.pickupDate !== null ||
    this.searchModel.driver !== null ||
    this.selfchecked !== false ||
    this.equipmentName !== null
  ) {
    var dimensions = null;
    if (this.carrierName) {
      dimensions = 'carrierName=' + this.carrierName;
    }
    if (this.statusLabel) {
      const shipmentStatus = 'shipmentStatus=' + this.statusLabel;
      dimensions = dimensions
        ? dimensions + '&' + shipmentStatus
        : shipmentStatus;
    }
    if (this.searchModel.driver) {
      const driverName = 'driverName=' + this.driverName;
      dimensions = dimensions ? dimensions + '&' + driverName : driverName;
    }
    if (this.selfchecked) {
      const selfAssign = 'selfAssign=' + this.selfchecked;
      dimensions = dimensions ? dimensions + '&' + selfAssign : selfAssign;
    }
    var obj = {
      eventName: 'search_shipment',
      event_category: 'Shipment',
      Occurence: 'main',
      userType: localStorage.getItem('user_type'),
      EmailID: localStorage.getItem('email'),
      Dimensions: dimensions ? dimensions : '-',
      EquipmentType: this.equipmentName ? this.equipmentName : '-',
      SourceLocation: this.searchModel.pickupLocation
        ? this.searchModel.pickupLocation
        : '-',
      TargetLocation: this.searchModel.dropoffLocation
        ? this.searchModel.dropoffLocation
        : '-',
    };
    this.sharedService.track(obj);
  }
}

// show/hide filter form 
advanceFilter() {
  if (this.userType == 'SHIPPER' && (this.subscriptionData?.isPremium != true || this.subscriptionData?.subscriptionStatus == 'canceled'))
  {
    this.getSubscriptionAlertPopup();
  } else {
    this.showAdvancedFilter = !this.showAdvancedFilter;
  }
}

// cancle filter 
cancelFilterPopup() {
  this.showAdvancedFilter = false;
}

// close filter 
closeFilterPopup() {
  this.showAdvancedFilter = false;
  if (this.driverNameShow) {
    this.driverName = this.driverNameShow;
    this.searchModel.driver = this.driverId;
    this.checkSelfDisabled = true;
  } else if (this.driverName) {
    this.driverId = null;
    this.driverName = null;
    this.searchModel.driver = null;
    this.checkSelfDisabled = false;
  } else {
    this.checkSelfDisabled = false;
  }
  if (this.selfchecked && this.commonDriverId) {
    this.driverDisabled = true;
  } else if (this.selfchecked) {
    this.selfchecked = false;
    this.selfId = null;
    this.driverDisabled = false;
  } else {
    this.driverDisabled = false;
  }

  if (this.params.pickupDate) {
    this.pickupDate = this.params.pickupDate;
    this.pickupDateFilter = this.pickupDate;
    this.searchModel.pickupDate = moment(this.pickupDate).format('YYYY-MM-DD');
  }
  if (this.params.dropDate) {
    this.dropDate = this.params.dropDate;
    this.dropDateFilter = this.dropDate;
    this.searchModel.dropDate = moment(this.dropDate).format('YYYY-MM-DD');
  }
  if (this.carrierNameShow) {
    this.searchModel.carrierId = this.carrierId;
    this.params.carrierId = this.carrierId;
    this.carrierName = this.carrierNameShow;
  }
  if (this.equipmentNameShow) {
    this.searchModel.equipmentId = this.equipmentTypeId;
    this.equipmentName = this.equipmentNameShow;
    this.params.equipmentType = this.equipmentTypeId;
  }
  if (this.statusLabelShow) {
    this.statusLabel = this.statusLabelShow;
    this.searchModel.status = this.params.status;
  }
  this.searchModel.pickupLocation = null;
  this.searchModel.dropoffLocation = null;
  if (this.pickupLocationShow) {
    this.pickupLocationNew = this.pickupLocationShow;
    this.searchModel.pickupLocation = this.pickupLocationShow;
  }
  if (this.dropoffLocationShow) {
    this.dropoffLocationNew = this.dropoffLocationShow;
    this.searchModel.dropoffLocation = this.dropoffLocationShow;
  }
  if (this.selfId) {
    this.selfchecked = true;
  }
}

// clear filter record 
clearRecordFilter(event) {
  if (event === 'clear') {
    this.searchModel.carrierId = null;
    this.params.carrierId = null;
    this.carrierName = null;
    this.equipmentTypeFilter = null;
    this.searchModel.equipmentId = null;
    this.params.equipmentType = null;
    this.equipmentName = null;
    this.searchModel.status = null;
    this.statusLabel = null;
    this.statusFilter = null;
    this.pickupLocationNew = null;
    this.randomPickupTextError = false;
    this.randomDropupTextError = false;
    this.pickupLocationRandom = null;
    this.droupLocationRandom = null;
    this.searchModel.pickupLocation = null;
    this.pickLoc = false;
    this.dropoffLocationNew = null;
    this.searchModel.dropoffLocation = null;
    this.dropLoc = false;
    this.driverDisabled = false;
    this.checkSelfDisabled = false;
    this.selfchecked = false;
    this.searchModel.driver = null;
    this.driverName = null;
    this.driverFilter = null;
    this.searchModel.pickupDate = null;
    this.pickupDateFilter = null;
    this.pickupDate = null;
    this.searchModel.dropDate = null;
    this.dropDateFilter = null;
    this.dropDate = null;
    this.titleFilter = null;
    this.searchModel.title = null;
    this.params.title = null;

    this.applyFilterSubmit();
  }
}

camelToSnake(string) {
  return string.replace(/[\w]([A-Z])/g, function (m) {
    return m[0] + ' ' + m[1];
  });
}

// Filter by tab menu
filterByStatus(event: any) {
  if (this.cancelRequestUser) {
    this.cancelRequestUser.unsubscribe();
  }
  this.tabActionDisabled = true;
  this.titleStatus = null;
  this.dataNotFound = false;
  this.page = 1;
  if (event !== null) {
    const titleValue = this.camelToSnake(event);
    if (localStorage.getItem('user_type') === 'CARRIER') {
       if (
        event === 'dispute' ||
        event === 'newDispute' ||
        event === 'ongoingDispute' ||
        event === 'resolvedDispute'
      ) {
        this.currentRouteParams = 'dispute';
        this.selectedIndex = 3;
      }  else {
        this.currentRouteParams = 'summary';
        this.titleStatus =
          titleValue.charAt(0).toUpperCase() + titleValue.slice(1);
      }
    }
    if (localStorage.getItem('user_type') === 'SHIPPER') {
      if (
        event === 'dispute' ||
        event === 'newDispute' ||
        event === 'ongoingDispute' ||
        event === 'resolvedDispute'
      ) {
        this.selectedIndex = 2;
        this.currentRouteParams = 'dispute';
      }  else {
        this.currentRouteParams = 'summary';
        this.titleStatus =
          titleValue.charAt(0).toUpperCase() + titleValue.slice(1);
      }
    }
    this.params.tabType = event;
    this.currentStatus = event;
    this.finalStatus = this.shipmentStatusType[event];
  }
  this.applyFilterSubmit();
}

// filter for sub tabs  
subTabMenuFilter(event: any) {
  this.clearRecordFilter('clear');
   if (event === 'newDispute') {
     this.filterByStatus('newDispute');
     this.markReadAlertNotification('newDispute');
   } else if (event === 'ongoingDispute') {
     this.filterByStatus('ongoingDispute');
   } else if (event === 'resolvedDispute') {
     this.filterByStatus('resolvedDispute');
   }
}

/*Single filter clear*/
singleFilterClose(event) {
  if (event === 'self') {
    this.selfchecked = false;
    this.selfId = null;
    this.commonDriverId = null;
    this.params.driverId = null;
    this.driverDisabled = false;
  }
  if (event === 'pickupDate') {
    this.pickupDateFilter = null;
    this.pickupDate = null;
    this.params.pickupDate = null;
    this.searchModel.pickupDate = null;
  }
  if (event === 'dropDate') {
    this.dropDateFilter = null;
    this.dropDate = null;
    this.params.dropDate = null;
    this.searchModel.dropDate = null;
  }
  if (event === 'title') {
    this.titleFilter = null;
    this.params.title = null;
    this.searchModel.title = null;
  }
  if (event === 'batch') {
    this.batchFilter = null;
    this.params.batchId = null;
    this.searchModel.batchId = null;
  }
  if (event === 'status') {
    this.statusFilter = null;
    this.params.status = null;
    this.searchModel.status = null;
    this.statusLabel = null;
    this.statusLabelShow = null;
  }
  if (event === 'driver') {
    this.driverFilter = null;
    this.params.driverId = null;
    this.searchModel.driver = null;
    this.driverName = null;
    this.driverNameShow = null;
    this.driverId = null;
    this.checkSelfDisabled = false;
  }
  if (event === 'carrier') {
    this.carrierId = null;
    this.carrierName = null;
    this.carrierNameShow = null;
    this.params.carrierId = null;
    this.searchModel.carrierId = null;
  }
  if (event === 'equipment') {
    this.equipmentTypeFilter = null;
    this.params.equipmentType = null;
    this.searchModel.equipmentType = null;
    this.searchModel.equipmentId = null;
    this.equipmentNameShow = null;
    this.equipmentName = null;
    this.equipmentTypeId = null;
  }
  if (event === 'pickUp') {
    this.pickupLocationNew = null;
    this.pickupLocationShow = null;
    this.searchModel.pickupLocation = null;
    this.params.pickupLatitude = null;
    this.params.pickupLongitude = null;
    this.pickupLatitude = null;
    this.pickupLongitude = null;
    this.pickLoc = false;
    this.pickupLocationRandom = null;
    if (this.dropLatitude === null && this.dropLongitude === null) {
      this.params.radius = null;
    }
  }
  if (event === 'dropOff') {
    this.dropoffLocationNew = null;
    this.dropoffLocationShow = null;
    this.searchModel.dropoffLocation = null;
    this.params.dropLatitude = null;
    this.params.dropLongitude = null;
    this.dropLatitude = null;
    this.dropLongitude = null;
    this.droupLocationRandom = null;
    this.dropLoc = false;
    if (this.pickupLatitude === null && this.pickupLongitude === null) {
      this.params.radius = null;
    }
  }

  this.shipmentList = [];
  this.tabCount = '';
  this.page = 1;
  this.skeletonLoader = false;
  if (
    this.totalFilter ||
    this.searchModel.title ||
    this.selfchecked ||
    this.carrierName ||
    this.dropDate ||
    this.pickupDate ||
    this.statusLabel ||
    (this.dropLatitude && this.dropLongitude) ||
    (this.pickupLatitude && this.pickupLongitude) ||
    this.equipmentName ||
    this.driverName ||
    this.carrierNameShow
  ) {
    this.currentSearchStatus = 'search';
  } else {
    this.currentSearchStatus = '';
  }
  this.list(this.params);
}

// Shipment list through get data.
list(params = {}) {
  this.tabCount = '';
  this.shipmentList = [];
  this.page = 1;
  var APIparams = this.getAPIParam(null);
  this.router.navigate(['/dispute/' + this.currentStatus], {
    queryParams: {
      batchId: this.params.batchId,
      title: this.params.title,
      pickupDate: this.params.pickupDate,
      dropDate: this.params.dropDate,
      equipmentType: this.params.equipmentType,
      equipmentName: this.equipmentName,
      driverId: this.driverId,
      driverName: this.driverName,
      carrierId: this.carrierId,
      carrierName: this.carrierName,
      selfId: this.selfId,
      status: this.params.status,
      statuslabel: this.statusLabelShow,
      pickupLatitude: this.params.pickupLatitude,
      pickupLongitude: this.params.pickupLongitude,
      pickupAddress: this.pickupLocationShow,
      dropLatitude: this.params.dropLatitude,
      dropLongitude: this.params.dropLongitude,
      dropoffAddress: this.dropoffLocationShow,
    },
  });
  this.cancelRequestUser = this.commonService.getList(APIparams).subscribe(
    (ServerRes) => {
      if (ServerRes.success === true) {
        this.cancelRequestUser = null;
        this.tabActionDisabled = false;
        this.totalShipment = ServerRes.response.totalShipment;
        this.totalPage = ServerRes.response.totalPage;
        this.tabCount = ServerRes.response.tabCount;
        this.skeletonLoader = true;
        let result = ServerRes.response.shipments;

        if (result && result.length > 0) {
          for (let v = 0; v < result.length; v++) {

            result[v].pickupLocationLat = result[v].waypoints[0].latitude;
            result[v].pickupLocationLong = result[v].waypoints[0].longitude;
            result[v].pickupDate = result[v].waypoints[0].date.replaceAll('-', '/');
            result[v].pickupTimeZone = result[v].waypoints[0].timeZone;
            result[v].pickupTimeslot = result[v].waypoints[0].timeSlot;
            result[v].pickupLocationLongName = result[v].waypoints[0].locationLongName;
            result[v].pickupInstructions = result[v].waypoints[0].specialRequirement;
            result[v].pickupCity = result[v].waypoints[0].city;
            result[v].pickupState = result[v].waypoints[0].state;
            result[v].pickupPincode = result[v].waypoints[0].zip;

            result[v].dropStatus = result[v].waypoints[result[v].waypoints.length - 1].status;
            result[v].dropTimeZone = result[v].waypoints[result[v].waypoints.length - 1].timeZone;
            result[v].dropTimeslot = result[v].waypoints[result[v].waypoints.length - 1].timeSlot;
            result[v].dropDate = result[v].waypoints[result[v].waypoints.length - 1].date.replaceAll('-', '/');
            result[v].dropLocationLat = result[v].waypoints[result[v].waypoints.length - 1].latitude;
            result[v].dropLocationLong = result[v].waypoints[result[v].waypoints.length - 1].longitude;
            result[v].dropInstructions = result[v].waypoints[result[v].waypoints.length - 1].specialRequirement;
            result[v].dropLocationLongName = result[v].waypoints[result[v].waypoints.length - 1].locationLongName;
            result[v].dropCity = result[v].waypoints[result[v].waypoints.length - 1].city;
            result[v].dropState = result[v].waypoints[result[v].waypoints.length - 1].state;
            result[v].dropPincode = result[v].waypoints[result[v].waypoints.length - 1].zip;

            if (result[v].deliverydate && result[v].updatedAt && result[v].status === 51) {
              let days = 1;
              let deliveredDate = new Date(result[v].updatedAt + ' ' + 'UTC');
              deliveredDate.setDate(deliveredDate.getDate() + days);
              let now = new Date();
              const deliveryDateTime: any = moment(deliveredDate).format('YYYY-MM-DD HH:mm');
              const currentDateTime: any = moment(now).format('YYYY-MM-DD HH:mm');
              if (deliveryDateTime > currentDateTime) {
                result[v].checkTimeDispute = true;
              } else {
                result[v].checkTimeDispute = false;
              }
            } else {
              result[v].checkTimeDispute = false;
            }
          }
          this.shipmentList = result;
        } else {
          this.shipmentList = [];
        }
        this.loading = false;
      } else {
        this.shipmentList = [];
        this.totalShipment = 0;
        this.loading = false;
        this.totalPage = 0;
        this.tabCount = 0;
        this.skeletonLoader = true;
        this.tabActionDisabled = false;
      }
    },
    (error) => {
      this.skeletonLoader = true;
      this.shipmentList = [];
      this.totalShipment = 0;
      this.loading = false;
      this.totalPage = 0;
      this.tabCount = 0;
      this.tabActionDisabled = false;
    }
  );
}


getAPIParam(str) {
  if (
    (this.params.pickupLatitude !== null &&
      this.params.pickupLongitude !== null) ||
    (this.params.dropLatitude !== null && this.params.dropLongitude !== null)
  ) {
    this.radius = 30;
  } else {
    this.radius = null;
  }

  let APIparams, params;
  params = {
    limit: 12,
    radius: this.radius,
    page: this.page,
    tabType: this.currentStatus,
    orderBy: 'DESC',
    orderDir: 'updated_at',
    batchId: this.params.batchId,
    title: this.params.title,
    pickupDate: this.params.pickupDate,
    dropDate: this.params.dropDate,
    equipmentType: this.params.equipmentType,
    equipmentName: this.equipmentName,
    driverId: this.params.driverId,
    driverName: this.driverName,
    status: this.params.status,
    carrierId: this.params.carrierId,
    statuslabel: this.statusLabelShow,
    pickupLatitude: this.params.pickupLatitude,
    pickupLongitude: this.params.pickupLongitude,
    pickAddress: this.pickupLocationShow,
    dropLatitude: this.params.dropLatitude,
    dropLongitude: this.params.dropLongitude,
    dropoffAddress: this.dropoffLocationShow,
  };
  APIparams = {
    apiKey: AppSettings.APIsNameArray.SHIPMENT.NEWSHIPMENTLIST,
    uri: this.commonService.getAPIUriFromParams(params),
  };
  return APIparams;
}

// Shipment list through get data with pagination.
getMoreData(str) {
  this.spinnerLoader = true;
  var APIparams = this.getAPIParam(str);
  this.commonService.getList(APIparams).subscribe((ServerRes) => {
    let result = ServerRes.response.shipments;
    this.totalShipment = ServerRes.response.totalShipment;
    this.spinnerLoader = false;
    if (
      ServerRes.response.shipments &&
      ServerRes.response.shipments.length > 0
    ) {
      for (let v = 0; v < result.length; v++) {
        result[v].pickupLocationLat = result[v].waypoints[0].latitude;
        result[v].pickupLocationLong = result[v].waypoints[0].longitude;
        result[v].pickupDate = result[v].waypoints[0].date;
        result[v].pickupTimeZone = result[v].waypoints[0].timeZone;
        result[v].pickupTimeslot = result[v].waypoints[0].timeSlot;
        result[v].pickupLocationLongName = result[v].waypoints[0].locationLongName;
        result[v].pickupInstructions = result[v].waypoints[0].specialRequirement;
        result[v].pickupCity = result[v].waypoints[0].city;
        result[v].pickupState = result[v].waypoints[0].state;
        result[v].pickupPincode = result[v].waypoints[0].zip;

        result[v].dropStatus = result[v].waypoints[result[v].waypoints.length - 1].status;
        result[v].dropTimeZone = result[v].waypoints[result[v].waypoints.length - 1].timeZone;
        result[v].dropTimeslot = result[v].waypoints[result[v].waypoints.length - 1].timeSlot;
        result[v].dropDate = result[v].waypoints[result[v].waypoints.length - 1].date;
        result[v].dropLocationLat = result[v].waypoints[result[v].waypoints.length - 1].latitude;
        result[v].dropLocationLong = result[v].waypoints[result[v].waypoints.length - 1].longitude;
        result[v].dropInstructions = result[v].waypoints[result[v].waypoints.length - 1].specialRequirement;
        result[v].dropLocationLongName = result[v].waypoints[result[v].waypoints.length - 1].locationLongName;
        result[v].dropCity = result[v].waypoints[result[v].waypoints.length - 1].city;
        result[v].dropState = result[v].waypoints[result[v].waypoints.length - 1].state;
        result[v].dropPincode = result[v].waypoints[result[v].waypoints.length - 1].zip;

        if (result[v].deliverydate && result[v].updatedAt && result[v].status === 51) {
          let days = 1;
          let deliveredDate = new Date(result[v].updatedAt + ' ' + 'UTC');
          deliveredDate.setDate(deliveredDate.getDate() + days);
          let now = new Date();
          const deliveryDateTime: any = moment(deliveredDate).format('YYYY-MM-DD HH:mm');
          const currentDateTime: any = moment(now).format('YYYY-MM-DD HH:mm');
          if (deliveryDateTime > currentDateTime) {
            result[v].checkTimeDispute = true;
          } else {
            result[v].checkTimeDispute = false;
          }
        } else {
          result[v].checkTimeDispute = false;
        }
        if (result[v]) this.shipmentList.push(result[v]);
      }
    }
  });
}

  /*Get All record driver*/
  driverListRecord() {
    return new Promise((resolve, reject) => {
      let uri = null;
      let newParams = {
        limit: 100,
        status: 'message',
      };
      //get dynamic URI for APIs
      if (newParams) uri = this.commonService.getAPIUriFromParams(newParams);
      let APIparams = {
        apiKey: AppSettings.APIsNameArray.DRIVER.LIST,
        uri: uri,
      };
      this.commonService.getList(APIparams).subscribe((ServerRes) => {
        if (ServerRes.success === true) {
          resolve(ServerRes.response.drivers);
        } else {
          resolve(null);
        }
      });
    });
  }

  getAPIParamUser() {
    let APIparams, params;
    params = { limit: 15, page: this.page, tabType: 'CONNECTED' };
    let url;
    url = AppSettings.APIsNameArray.CARRIER.LIST;
    APIparams = {
      apiKey: url,
      uri: this.commonService.getAPIUriFromParams(params),
    };
    return APIparams;
  }
  // get carrier detial.
  getConnected() {
    this.page = 1;
    var APIparams = this.getAPIParamUser();
    this.commonService.getList(APIparams).subscribe((ServerRes) => {
      if (ServerRes.success === true) {
        this.carrierList = ServerRes.response.carriers;
      } else {
        this.carrierList = [];
      }
    });
  }

public generateFake(count: number): Array<number> {
  const indexes = [];
  for (let i = 0; i < count; i++) {
    indexes.push(i);
  }
  return indexes;
}

// Open sidepanel
eventAction(event) {  
  if (event.type === 'side_panel') {
    this.sidePanelData = event.event;
    const body = document.getElementsByClassName('sm_navbar_hide')[0];
    body.classList.add('side_panel_manage');
  }
  else if(event.event?.drivers?.legalName !== this.userName){
    this.sidePanelProfileData=event.event
     if(event.type === 'profile_side_panel'){
      if(this.userType==='SHIPPER'){
      this.sidePanelProfileDataId = event?.event?.carriers?.id;
     }
    else{
      this.sidePanelProfileDataId = event?.event?.driverId;
    }
  }
 }
}

closePanel(event: any) {
  if (event.type === 'connect') {
    if (this.currentUrlTab !== 'search') {
      this.sidePanelProfileDataId = '';
      this.sidePanelData = '';
    }
    this.assignUserData = event.value;
    this.connect(event.value);
  }
  if (event.type === 'close') {
    this.skeletonLoader = false;
    this.list();
    this.sidePanelProfileDataId = '';
    this.sidePanelData = '';
  }
     
  const body = document.getElementsByClassName('sm_navbar_hide')[0];
  body.classList.remove('side_panel_manage');
}

connect(event: any) {
  let name;
  let status;
  if (event.companyName) {
    name = event.companyName;
  }
  if (event.legalName) {
    name = event.legalName;
  }
  if (event.networkLabel === 'CONNECT') {
    status = 'INVITE';
  }
  if (event.networkLabel === 'PENDING') {
    status = 'REINVITE';
  }
    let typeOfPopup; 
    let typeOfwidth;
    if(this.userType=='CARRIER'){
      typeOfPopup='inviteCSNew';
      typeOfwidth='600px'
     }else{
      typeOfPopup='inviteCS'
      typeOfwidth=AppSettings.popWidth
     }
    const dialogRef = this.dialog.open(NetworkPopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width: '526px',
      data: {
        openPop: typeOfPopup,
        allRecord: event,
        id: event.id,
        driverName: name,
        status: status,
        planDetail:this.subscriptionData,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.event === 'Ok') {
        if (!this.assignUserData) {
          let data;
          let loader = true;
          data = { status: 'CONNECT', loader: loader, userId: event.id };
          this.sharedService.networkPassData.next(data);
        }
        if (this.assignUserData) {
          this.loading = true;
        }
        this.connectRequest(
          event.id,
          name,
          event,
          status,
          result.message,
          result.documentType,
          result.isKnown,
          event.dotNumber
        );
      }
    });
}

connectRequest(
  id: any,
  name: any,
  targetUserData: any,
  status: any,
  message: any,
  documentType: any,
  know:any,
  dotNumber: any
) {
  let docType;
  if (documentType === true) {
    docType = 1;
  } else {
    docType = null;
  }
  let checkValue;
    if(know==='yes'){
      checkValue=true;
    }else{
      checkValue=false;
    }
  var APIparams = {
    apiKey: AppSettings.APIsNameArray.MANAGE_CONNECTION[status],
    uri: '',
    postBody: { userPkId: id, note: message, isDocumentVerified: docType ,isKnown:checkValue, },
  };
  this.commonService.post(APIparams).subscribe((ServerRes) => {
    if (ServerRes.success === true) {
      if(this.userType==='CARRIER'){
        this.getActiveSubscription()
      }
      let targetLocation = '-';
      if (
        targetUserData &&
        targetUserData.phyCity &&
        targetUserData.phyState
      ) {
        targetLocation = targetUserData.phyCity + ',' + targetUserData.phyState;
      }
      if (status === 'INVITE') {
        var obj = {
          eventName: 'network_invitation',
          event_category: 'Network',
          Occurence: 'main',
          userType: localStorage.getItem('user_type'),
          EmailID: localStorage.getItem('email'),
          TargetID: name,
          dotNumber: dotNumber ? dotNumber : '-',
          TargetLocation: targetLocation,
          SourceLocation: this.sharedService.getUserLocationType(),
          TimeSpent: this.invitationTimer,
        };
        this.sharedService.track(obj);
      }
      if (status === 'REINVITE') {
        var obj = {
          eventName: 'network_send_reminder',
          event_category: 'Network',
          Occurence: 'main',
          userType: localStorage.getItem('user_type'),
          EmailID: localStorage.getItem('email'),
          TargetID: name,
          dotNumber: dotNumber ? dotNumber : '-',
          TargetLocation: targetLocation,
          SourceLocation: this.sharedService.getUserLocationType(),
          TimeSpent: this.invitationTimer,
        };
        this.sharedService.track(obj);
      }
      if (!this.assignUserData) {
        let data;
        data = { status: 'PENDING', loader: false, userId: id };
        this.sharedService.networkPassData.next(data);
      }
      if (this.assignUserData) {
        this.assignUserData.networkLabel = 'PENDING';
        if (this.currentUrlTab !== 'search') {
          this.showSidePanel = this.assignUserData;
        }

        this.assignUserData = '';
        this.loading = false;
      }
      this.loading = false;
      if (this.userType === 'SHIPPER')
        this.alertService.showNotificationMessage(
          'success',
          'bottom',
          'right',
          'txt_s',
          'check_circle',
          'Connection Request',
          'Connection request has been sent successfully.'
        );
      if (this.userType === 'CARRIER')
        this.alertService.showNotificationMessage(
          'success',
          'bottom',
          'right',
          'txt_s',
          'check_circle',
          'Connection Request',
          'Connection request has been sent successfully.'
        );
    } else {
      if (!this.assignUserData) {
        let data;
        data = { status: 'CONNECT', loader: false, userId: id };
        this.sharedService.networkPassData.next(data);
      }
      if (this.assignUserData) {
        this.assignUserData.networkLabel = 'CONNECT';
        if (this.currentUrlTab !== 'search') {
          this.showSidePanel = this.assignUserData;
        }
        this.assignUserData = '';
        this.loading = false;
      }
      this.loading = false;
      if (this.userType === 'SHIPPER')
        this.alertService.showNotificationMessage(
          'danger',
          'bottom',
          'right',
          'txt_d',
          'check_circle',
          'Connection Request',
          'Request Failed.'
        );
      if (this.userType === 'CARRIER')
        this.alertService.showNotificationMessage(
          'danger',
          'bottom',
          'right',
          'txt_d',
          'check_circle',
          'Connection Request',
          'Request Failed.'
        );
    }
    if (this.timeCount) {
      this.timeCount.unsubscribe();
    }
  });
}

getActiveSubscription() {
  let APIparams = {
    apiKey: AppSettings.APIsNameArray.SUBSCRIPTION.ACTIVEPLAN,
  };
  this.commonService.getList(APIparams).subscribe((ServerRes) => {
    if (ServerRes.success === true) {
      this.subscriptionData = ServerRes.response;
     }
  });
}


}
