import { Component, HostListener, OnInit, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AppSettings } from 'src/app/commons/setting/app_setting';
import { CommonService } from 'src/app/commons/service/common.service';
import { SharedService } from 'src/app/commons/service/shared.service';
import { AlertService } from 'src/app/commons/service/alert.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-confirm-lane-list',
  templateUrl: './confirm-lane-list.component.html',
  styleUrls: ['./confirm-lane-list.component.scss']
})

export class ConfirmLaneListComponent implements OnInit, AfterViewInit {
  public getTotalHeight: any;
  public skeletonLoader = false;
  public totalPage = 1;
  public page = 1;
  public lanesList = [];
  public spinnerLoader = false;
  public dataNotFound = false;
  public params: any;
  public totalLanes: any;
  public currentStatus='confirmedLaneList';
  public tabType ='confirmedLanes';
  public searchModel: any = { };
  public titleFilter: any;
  public title:any;
  public currentSearchStatus: any;
  public isLane = true;
  public countSpace = 1;
  public searchBoxError = false;
  public equipmentListing: any;
  public phStates: any;
  public filterCheck = true;
  public stateId: any;
  public equipmentTypeFilter: any;
  public equipmentTypeFilterShow: any;
  public equipmentName: any;
  public equipmentNameShow: any;
  public stateShow: any;
  public stateName: any;
  public equipmentTypeId: any;
  public serverPathForUrl: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private sharedService: SharedService,
    public commonService: CommonService,
    private alertService: AlertService
  ) { }

    @HostListener('window:scroll', ['$event'])
    onWindowScroll(event) {
      this.getTotalHeight = window.innerHeight + window.scrollY;
      if (
        $(window).scrollTop() + 1 >=
        $(document).height() - $(window).height()
      ) {
        if (
          this.page !== this.totalPage &&
          this.page >= 1 &&
          this.totalPage &&
          this.lanesList.length > 0
        ) {
          this.page = this.page + 1;
          this.spinnerLoader = true;
          this.dataNotFound = false;
          this.getMoreData(null);
        } else if (this.spinnerLoader === false) {
          this.dataNotFound = true;
        }
      }
   }

  ngOnInit(): void {
    this.serverPathForUrl = environment.serverPathForUrl;
    let configValue = this.sharedService.getConfig();
    if (configValue !== undefined) {
      this.getConfigSet(configValue);
    }
    this.commonService.configData.subscribe((userData) => {
      this.getConfigSet(userData);
    });
    this.getStates();
    this.skeletonLoader = false;
    this.params = {
      limit: 10,
    };
  }

  ngAfterViewInit() {
    this.route.queryParams.subscribe((params) => {
      if (Object.keys(params).length > 0) {
        if (params['equipmentType']) {
          this.searchModel.equipmentId = parseInt(params['equipmentType']);
          this.equipmentTypeId = parseInt(params['equipmentType']);
          this.params.equipmentType =parseInt(params['equipmentType']);
          this.equipmentName = params['equipmentName'];
        }
        this.searchModel.title = params['title'];
        if (params['state']) {
          this.searchModel.stateId = params['stateId'];
          this.stateId = params['stateId'];
          this.params.stateId = params['stateId'];
          this.stateName = params['state'];
        }
      }
    });
    this.applyFilterSubmit();
  }
 
  // Function to get data from config API 
  getConfigSet(configValue: any) {
    this.equipmentListing = configValue.equipmentTypes;
  }
  
  // To get states list from API call
  getStates(){
    this.commonService.getStateList().subscribe((serverRes) => {
      if (serverRes.success == true) {
       this.phStates = serverRes.response;
      } else if (serverRes.success == false) {
        this.alertService.showNotificationMessage(
          'danger',
          'bottom',
          'right',
          'txt_d',
          'check_circle',
          'States',
          'Unexpected Error.'
        );
      }
    });
  }

  // To get Equipment Type
  equipmentSelected(equipment: any) {
    this.filterCheck = false;
    this.searchModel.equipmentId = parseInt(equipment.id);
    this.equipmentName = equipment.label;
    this.equipmentTypeId = parseInt(equipment.id);
  }

  // To get state name
  stateSelected(state: any) {
    this.filterCheck = false;
    this.searchModel.stateId = state.sortname;
    this.stateName = state.name;
    this.stateId = state.sortname;
  }

     /*Search filter by keyup value*/
    searchQuantity(event: any) {
      let searchStr = event.target.value;
      this.searchModel.title = searchStr;
      if(searchStr){
        this.filterCheck=false;
      }
      else if(this.titleFilter){
        this.filterCheck=false;
      }
      else{
        this.filterCheck=true;
  
      }
     
      let lastword = searchStr.charAt(searchStr.length - 1);
      if (lastword === ' ') {
        this.countSpace = this.countSpace + 1;
      } else {
        this.countSpace = 0;
      }
      if (this.countSpace >= 2 || (lastword === ' ' && searchStr.length === 1)) {
        this.searchModel.title = this.searchModel.title.substring(
          0,
          this.searchModel.title.length - 1
        );
        this.searchBoxError = true;
      } else {
        this.searchBoxError = false;
      }
    }

  // Function for generate duplicate loaders 
  public generateFake(count: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes; 
  }

  // To apply filter by selected value 
  applyFilterSubmit() {
    this.skeletonLoader = false;
    this.lanesList = [];
    this.page = 1;
    if (this.searchModel.title) {
      this.params.title = this.searchModel.title;
      this.titleFilter = this.searchModel.title;
    } else {
      this.params.title = null;
      this.titleFilter = null;
      this.searchModel.title=null
    }
     if (this.equipmentName) {
      this.params.equipmentType = this.searchModel.equipmentId;
      this.equipmentNameShow = this.equipmentName;
      this.equipmentTypeId = this.searchModel.equipmentId;
    } else {
      this.params.equipmentType = null;
      this.equipmentNameShow = null;
      this.equipmentTypeId = null;
    }
    if (this.stateName) {
      this.params.stateId = this.searchModel.stateId;
      this.searchModel.stateId = this.searchModel.stateId;
      this.stateShow = this.stateName; 
    } else {
      this.params.stateId = null;
      this.stateShow = null;
      this.stateId = null;
    }    
    if (this.searchModel.equipmentId == null && this.searchModel.stateId == null && this.searchModel.title == null){
      this.filterCheck = true;
    }
    else{
      this.filterCheck = false;
    }
    if (this.searchModel.title || this.equipmentName || this.stateName) {
      this.currentSearchStatus = 'search';
    } else {
      this.currentSearchStatus = '';
    }
    this.laneList();
  }

  // To clear all the data from filter popup 
    clearFilter() {
      this.titleFilter = null;
      this.params.title=null;
      this.equipmentTypeFilter = null;
      this.searchModel.equipmentId = null;
      this.params.equipmentType = null;
      this.equipmentName = null;
      this.searchModel.title = null;
      this.params.state = null;
      this.stateName = null
      this.searchModel.stateId = null;
      this.params.stateId = null;
      this.stateId = null;

      this.applyFilterSubmit();
    }

  /*Remove data using filter.*/
  singleFilterClose(event) {
    if (event === 'title') {
      this.titleFilter = null;
      this.params.title = null;
      this.searchModel.title = null; 
    }
    if (event === 'equipment') {
      this.equipmentTypeFilter = null;
      this.params.equipmentType = null;
      this.searchModel.equipmentType = null;
      this.searchModel.equipmentId = null;
      this.equipmentNameShow = null;
      this.equipmentName = null;
      this.equipmentTypeId = null;
    }

    if (event === 'state') {
      this.params.state = null;
      this.params.stateId = null;
      this.searchModel.state = null;
      this.searchModel.stateId = null;
      this.stateShow = null;
      this.stateName = null;
      this.stateId = null;
    }

    if (this.searchModel.equipmentId == null && this.searchModel.stateId == null && this.searchModel.title == null){
      this.filterCheck = true;
    }

    this.lanesList = [];
    this.page = 1;
    this.skeletonLoader = false;
    if (this.equipmentName) {
      this.currentSearchStatus = 'search';
    } else {
      this.currentSearchStatus = '';
    }
    this.laneList(this.params);
  }

  // To navigate create shipment by lane
  laneByCreateShipment(record:any){
    this.router.navigate(['/recurring-lanes/create/load/'+record.id])
  }

  // To get required params for API 
  getAPIParam(str) {
    let APIparams, params;
    params = {
      limit: 10,
      page: this.page,
      state: this.stateName,
      equipmentType: this.params.equipmentType,
      equipmentName: this.equipmentName,
      title: this.params.title,
      orderBy: 'DESC',
      tabType: this.tabType,
    };

    APIparams = {
      apiKey: AppSettings.APIsNameArray.SHIPMENT.LANELIST,
      uri: this.commonService.getAPIUriFromParams(params),
    };
    return APIparams;
  }
  
  // Pass data in url 
  passDataUrl(){
      this.router.navigate(['/recurring-lanes/confirm-list'], {
        queryParams: {
          equipmentType: this.params.equipmentType,
          equipmentName: this.equipmentName,
          title: this.params.title,
          state: this.stateName,
          stateId: this.params.stateId,
        },
      });
  }

  // To get lanes list from API
  laneList(params = {}) {
    this.lanesList = [];
    this.page = 1;
    let uri = null;
    //get dynamic URI for APIs
    var APIparams = this.getAPIParam(null);  
     this.passDataUrl()
     this.commonService.getList(APIparams).subscribe(
      (ServerRes) => {
        if (ServerRes.success === true) {
          this.totalLanes = ServerRes.response.totalLanes;
          this.totalPage = ServerRes.response.totalPage;
          this.skeletonLoader = true;
          let result = ServerRes.response.lanes;
          if (result && result.length > 0) {
            this.lanesList = result;
          } else {
            this.lanesList = [];
          }
        } else {
          this.lanesList = [];
          this.totalPage = 0;
          this.skeletonLoader = true;
          this.totalLanes = 0;
        }
      },
      (error) => {
        this.skeletonLoader = true;
        this.lanesList = [];
        this.totalPage = 0;
        this.totalLanes = 0;
      }
    );
  }

  // Get more data by pagination from lane list.
  getMoreData(str) {
    var APIparams = this.getAPIParam(str);
    this.commonService.getList(APIparams).subscribe((ServerRes) => {
      let result = ServerRes.response.lanes;
      this.totalLanes = ServerRes.response.totalLanes;
      this.spinnerLoader = false;
      if (ServerRes.response.lanes && ServerRes.response.lanes.length > 0) {
        for (let v = 0; v < result.length; v++) {
          if (result[v]) this.lanesList.push(result[v]);
        }
      }
    });
  }

  // back to previous page
  backHistory(){
    history.back()
  }
  
}
