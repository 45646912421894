import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AlertService } from '../service/alert.service';
import { CommonService } from '../service/common.service';
import * as moment from 'moment';
import { timer } from 'rxjs';
import { environment } from '../../../environments/environment';
declare var gtag: Function;
declare global {
  interface Window {
    attachEvent: any;
    dataLayer: any;
    hj: any;
    _hjSettings: any;
  }
}

@Injectable()
export class SharedService {
  public totalStrenth = 0;
  public _config: any;
  public _address: any;
  public emailId: any;
  public userType: any;
  public configLoginCallUrl = new Subject<string>();
  public networkPassData = new Subject<string>();
  public networkVertical = new Subject<string>();
  public closePopupMultipleTime = new Subject<string>();
  public updateBidAmount = new Subject<any>();
  public connectAccountService = new Subject<any>();
  public checkNotificationMenuVisiblity: Subject<string> = new Subject();
  public notifyEmailVerification: Subject<string> = new Subject();
  public changeFirebaseToken = new Subject<any>();
  public FirebaseLogout = new Subject<any>();
  public ACHService = new Subject<any>();
  public AlertNotifyService = new Subject<any>();
  public NetworkNotifyService = new Subject<any>();
  public updateDriverList = new Subject<any>();
  public updateLeftMenuByBell = new Subject<any>();
  public callAPIForTripStatusUpdate = new Subject<any>();
  public isEnableGuard = false;
  public platform = 'Portal';
  public SourceLocation = '';
  public timeCount: any;
  public TimeSpent = 0;
  public isHotjarScriptLoaded: boolean = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public commonService: CommonService,
    public alertService: AlertService,
  ) { }

  getStorageServices() {
    return [
      {
        id: 1,
        name: 'Frozen',
        category: [
          { name: 'All Zones', id: 'all_zones' },
          { name: 'Zone 1', id: 'zone_1' },
          { name: 'Zone 2 -Frozen', id: 'zone_2' },
          { name: 'Zone 3 -Frozen', id: 'zone_3' },
        ],
      },
      {
        id: 2,
        name: 'Refrigerated',
        category: [
          { name: 'All Zones', id: 'all_zones' },
          { name: 'Zone 1', id: 'zone_1' },
          { name: 'Zone 2 -Refrigerated', id: 'zone_2' },
          { name: 'Zone 3 -Refrigerated', id: 'zone_3' },
        ],
      },
      {
        id: 3,
        name: 'Heated',
        category: [
          { name: 'All Zones', id: 'all_zones' },
          { name: 'Zone 1', id: 'zone_1' },
          { name: 'Zone 2 -Heated', id: 'zone_2' },
          { name: 'Zone 3 -Heated', id: 'zone_3' },
        ],
      },
      {
        id: 4,
        name: 'Receiving',
        category: [
          { name: 'Pallet Inbound', id: 'pallet_inbound' },
          {
            name: 'Courier/Delivery Carrier Inbound',
            id: 'courier_carrier_inbound',
          },
          {
            name: 'Container Load or Offload',
            id: 'container_load_or_offload',
          },
          { name: 'Sort & Segregate', id: 'sort_and_segregate' },
        ],
      },
      {
        id: 5,
        name: 'Dry Goods',
        category: [
          { name: 'Pallet size 1', id: 'pallet_size_1' },
          { name: 'Pallet size 2', id: 'pallet_size_2' },
          { name: 'Pallet size 3', id: 'pallet_size_3' },
        ],
      },
      {
        id: 6,
        name: 'Outbound',
        category: [
          { name: 'Case Pick', id: 'case_pick' },
          { name: 'Shrink Wrap', id: 'shrink_wrap' },
          { name: 'Restacking', id: 'restacking' },
          { name: 'Pick And Pack', id: 'pick_and_pack' },
        ],
      },
    ];
  }

  setGuardValue() {
    this.isEnableGuard = true;
  }

  setGuardValueFalse() {
    this.isEnableGuard = false;
  }

  getGuardValue() {
    return this.isEnableGuard;
  }

 
  setUserLocationType(type) {
    this.SourceLocation = type;
  }

  getUserLocationType() {
    return this.SourceLocation;
  }

  setPlatformType(type) {
    this.platform = type;
  }

  getPlatformType() {
    return this.platform;
  }

  startTimer() {
    this.timeCount = timer(1000, 1000).subscribe((val) => {
      this.TimeSpent = val;
    });
  }

  stopTimer() {
    var obj = {
      eventName: 'page_view_time',
      Occurence: 'main',
      userType: localStorage.getItem('user_type'),
      EmailID: localStorage.getItem('email'),
      SourceLocation: this.getUserLocationType(),
      TimeSpent: this.TimeSpent == 0 ? 1 : this.TimeSpent ,
    };
    this.track(obj);
    if (this.timeCount) {
      this.timeCount.unsubscribe();
    }
    this.TimeSpent = 0;
  }

  getLocation(waypointArray, type ){
    let Location = ""
    if(waypointArray && waypointArray.length > 0){
    let locDropPoint = waypointArray.filter(x => x.type === type); 
    Location = locDropPoint && locDropPoint.length > 0 ? locDropPoint[0].locationLongName : '-'; 
    }
    return Location; 
  }

  track(eventProperties) {
    if (window.dataLayer) {
      this.setConfigForCustomDimensions(eventProperties);
      this.callGoogleAnalytics(eventProperties);
    }
  }

  setConfigForCustomDimensions(obj) {

    gtag('config', environment.googleAnalyticsKey, {
      event_category: obj.event_category ? obj.event_category : 'General',
      send_page_view: false,
      custom_map: {
        dimension1: 'UserType',
        dimension2: 'PageURL',
        dimension3: 'Platform',
        dimension4: 'SourceLocation',
        dimension5: 'EmailID',
        dimension6: 'AttemptCount',
        dimension7: 'PhoneNumber',
        dimension8: 'Dot',
        dimension9: 'TargetID',
        dimension10: 'TargetLocation',
        dimension11: 'Dimensions',
        dimension12: 'EquipmentType',
        dimension13: 'Occurence',
        dimension14: 'TimeSpent',
        dimension15: 'ShipmentType',
        dimension16: 'ShipmentID'
      },
    });
  }

  callGoogleAnalytics(obj) {
    gtag('event', obj.eventName, {
      UserType: obj.userType ? obj.userType : '-',
      PageURL: window.location.href,
      Platform: obj.Platform ? obj.Platform : 'Portal',
      SourceLocation: obj.SourceLocation ? obj.SourceLocation : '-',
      EmailID: obj.EmailID ? obj.EmailID : '-',
      AttemptCount: obj.attemptCount ? obj.attemptCount : '-',
      PhoneNumber: obj.phoneNumber ? obj.phoneNumber : '-',
      Dot: obj.dotNumber ? obj.dotNumber : '-',
      TargetID: obj.TargetID ? obj.TargetID : '-',
      TargetLocation: obj.TargetLocation ? obj.TargetLocation : '-',
      Dimensions: obj.Dimensions ? obj.Dimensions : '-',
      EquipmentType: obj.EquipmentType ? obj.EquipmentType : '-',
      Occurence: obj.Occurence ? obj.Occurence : '-',
      TimeSpent: obj.TimeSpent ? obj.TimeSpent : '0',
      ShipmentType: obj.ShipmentType ? obj.ShipmentType : '-',
      ShipmentID: obj.ShipmentID ? obj.ShipmentID: '-'
    });
  }



  getEquipmentType(id){
     let list = this.getConfig(); 
     return list.equipmentTypes.filter(x=>x.id === id)[0].label; 
  }

  faceBookPixelRemove(faceBookPixel: any) { }

  faceBookPixel(faceBookPixel: any) {
    let url =
      'https://www.facebook.com/tr?id=' +
      faceBookPixel +
      '&ev=PageView&noscript=1';
    const body = <HTMLDivElement>document.body;
    const imgData = document.createElement('img');
    imgData.style.display = 'none';
    imgData.style.height = '1';
    imgData.style.width = '1';
    imgData.src = url;
    imgData.id = 'facebookpixel';
    body.appendChild(imgData);
    this.loadFacebookPickel(faceBookPixel);
  }

  loadFacebookPickel(faceBookPixel: any) {
    (function (f: any, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod
          ? n.callMethod.apply(n, arguments)
          : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(
      window,
      document,
      'script',
      'https://connect.facebook.net/en_US/fbevents.js'
    );
    if (localStorage.getItem('user_type') === 'CARRIER') {
      (window as any).fbq('init', faceBookPixel);
      (window as any).fbq('track', 'PageView');
    }
  }
  getFirstLetter(name) {
    let acronym;
    let countLength;
    if (name) {
      acronym = name
        .split(/\s/)
        .reduce((response, word) => (response += word.slice(0, 1)), '');
      countLength = acronym.length;
      if (countLength === 1) {
        acronym = name.substr(0, 2).toUpperCase();
      } else if (countLength >= 2) acronym = acronym.substr(0, 2).toUpperCase();
    } else acronym = '';
    return acronym;
  }

  generateFakeSkeleton(count: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes;
  }

  checkHotjarScriptLoaded() {
    this.loadHotjarScript();
  }

  loadHotjarScript() {
    (function (h, o, t, j, a, r) {
      h.hj =
        h.hj ||
        function () {
          (h.hj.q = h.hj.q || []).push(arguments);
        };
      h._hjSettings = { hjid: 3147779, hjsv: 6 };
      a = o.getElementsByTagName('head')[0];
      r = o.createElement('script');
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');

    // this.isHotjarScriptLoaded = true;
  }

  public getConfig() {
    return this._config;
  }

  public setConfig(value: any) {
    if (value !== 'undefined') {
      this._config = value;
    }
  }

  public getAddress() {
    return this._address;
  }

  public setAddress(value: any) {
    if (value !== 'undefined') {
      this._address = value;
    }
  }

  checkEligiblityForTripStatus(status) {
    var eligiblityArray = [
      21, 22, 23, 24, 25, 26, 31, 32, 33, 34, 35, 36, 41, 42, 43, 44, 45, 46,
      13,
    ];
    if (eligiblityArray.includes(status)) return true;
    else {
      return false;
    }
  }

  /*Magic link error code*/
  magicLinkError(errorCode: any) {
    let status = {
      title: '',
      message: '',
    };
    let title, message;

    switch (errorCode) {
      case -10005:
        (title = 'User Email Update'),
          (message =
            'The user has stopped the login request because they want to edit the provided email.');
        break;

      case -10004:
        (title = 'Update Email Failed'),
          (message =
            'An update email request was unsuccessful, either due to an invalid email being supplied or the user Canceled the action.');
        break;

      case -10003:
        (title = 'User Already Login'),
          (message =
            'A user is already logged in. If a new user should replace the existing user, make sure to call logout before proceeding.');
        break;

      case -10006:
        (title = 'Link Invalid Redirect'),
          (message =
            'If using the redirectURI parameter for the magic link flow, this error is recevied if the provided value is invalid for some reason.');
        break;

      case -10002:
        (title = 'Rate Limited'),
          (message =
            'If the showUI parameter is set to false, this error will communicate the email rate limit has been reached. Please debounce your method calls if this occurs.');
        break;

      case -10001:
        (title = 'Link Expired'),
          (message =
            'The user clicked their magic link after it had expired (this can happen if the user takes more than 10 minutes to check their email).');
        break;

      case -10000:
        (title = 'Failed Verification'),
          (message =
            'The magic link failed verification, possibly due to an internal service error or a generic network error.');
        break;

      case -32603:
        (title = 'Internal error'),
          (message =
            'Request is not sent from a verified domain. Please visit https://dashboard.magic.link to verify your domain');
        break;
    }
    status = {
      title: title,
      message: message,
    };
    this.alertService.showNotificationMessage(
      'danger',
      'bottom',
      'right',
      'txt_d',
      'cancel',
      status.title,
      status.message
    );
    //return status;
  }


  /*Load status*/
  getShipmentBannerStatus(statusSlug, updatedAt, deliverydate) {
    let update1;
    let status = {
      message: '',
    };
    if (updatedAt) {
      updatedAt = moment.utc(updatedAt).format("MMM DD, YYYY HH:mm");
   
    }
    if (deliverydate) {
      updatedAt = moment.utc(deliverydate).format("MMM DD, YYYY HH:mm");
 
    }
    let message;

    switch (statusSlug) {
      case 'draft':
        message = 'Load under draft';
        break;
      case 'readyToBid':
        message = 'Load is ready for bidding';
        break;
      case 'assignToCarrier':
        message = 'Load is assign to carrier';
        break;
      case 'create':
        message = 'Load created';
        break;
      case 'unAssign':
        message = 'Load not assigned';
        break;
      case 'assign':
        message = 'Load assigned';
        break;
      case 'accept':
        message = 'Load accepted';
        break;
      case 'reject':
        message = 'Load rejected';
        break;
      case 'cancel':
        message = 'Load canceled';
        break;
      case 'changeDriver':
        message = 'Load assinged to new driver';
        break;
      case 'navigateToPickup':
        message = 'Driver navigate To pickup location';
        break;
      case 'reachedInPickupGeofence':
        message = 'Driver reached pickup geofence';
        break;
      case 'atPickupLocation':
        message = 'Driver reached at pickup location';
        break;

      case 'shipmentLoading':
        message = 'Load start loading';
        break;
      case 'shipmentLoaded':
        message = 'Load loaded';
        break;
      case 'verifyingPickupDocuments':
        message = 'Driver verified pickup documents';
        break;
      case 'accident':
        message = 'Driver verified pickup documents';
        break;
      case 'rest':
        message = 'Driver resting from';
        break;
      case 'underMaintenance':
        message = 'Vehicle under maintenance from';
        break;
      case 'fuelling':
        message = 'Vehicle fuelling';
        break;
      case 'navigateToDropOff':
        message = 'Driver navigate To DropOff location';
        break;
      case 'reachedAtDropOffGeofence':
        message = 'Driver reached in DropOff Geo-fence';
        break;
      case 'atDropOffLocation':
        message = 'Driver reached at DropOff location';
        break;
      case 'shipmentUnloading':
        message = 'Load start unloading';
        break;
      case 'shipmentUnloaded':
        message = 'Load unloaded';
        break;
      case 'verifyingDropOffDocuments':
        message = 'Driver verified DropOff documents';
        break;
      case 'delivered':
        message = 'Load successfully delivered';
        break;
      case 'unDelivered':
        message = 'Driver unable to deliver load';
        break;
      case 'dispute':
        message = 'Load status is disputed';
        break;
      case 'completed':
        message = 'Load successfully completed';
        break;
      case 'expired':
        message = 'Load has expired';
        break;
      case 'offerRate':
        message = 'Load offer rate';
        break;
      case 'paymentOnHold':
        message = 'Load payment is on hold';
        break;
    }
    status = {
      message: message,
    };
    return status;
  }

  getCurrentStatusForURL(status: any, shipment: any) {
    if (
      status === 21 ||
      status === 22 ||
      status === 23 ||
      status === 24 ||
      status === 25 ||
      status === 26 ||
      status === 31 ||
      status === 32 ||
      status === 33 ||
      status === 34 ||
      status === 35 ||
      status === 41 ||
      status === 42 ||
      status === 43 ||
      status === 44 ||
      status === 45 ||
      status === 46
    ) {
      return 'inProgress';
    }
    if (status === 13) {
      return 'upcoming';
    }
    if (status === 15 || status === 51 || status === 54 || status === 59) {
      return 'past';
    }
    if (
      status === 10 ||
      status === 18 ||
      status === 12 ||
      status === 11 ||
      status === 14 ||
      status === 52 ||
      status === 53 ||
      status === 1
    ) {
      return 'pending';
    }
    if (status === 11 || status === 14) {
      return 'assignShipmentOnDriver';
    }
    if (status === 2) {
      return 'availableToBid';
    }
    if (status === 53) {
      return 'dispute';
    }
    if (status === 2) {
      if (shipment.carriers) return 'currentBids';
      else return 'nonAwardedBids';
    }
  }

  getCurrentStatusBack(status: any, shipmentDetail: any) {
    if (
      status === 21 ||
      status === 22 ||
      status === 23 ||
      status === 24 ||
      status === 25 ||
      status === 26 ||
      status === 31 ||
      status === 32 ||
      status === 33 ||
      status === 34 ||
      status === 35 ||
      status === 41 ||
      status === 42 ||
      status === 43 ||
      status === 44 ||
      status === 45 ||
      status === 46
    ) {
      return 'inProgress';
    } else if (status === 13 && shipmentDetail.carriers) {
      return 'upcoming';
    } else if (
      status === 15 ||
      status === 51 ||
      (status === 54 && shipmentDetail.carriers)
    ) {
      return 'past';
    } else if (
      (status === 12 ||
        status === 11 ||
        status === 14 ||
        status === 52 ||
        status === 1 ||
        status === 18) &&
      shipmentDetail.carriers
    ) {
      return 'pending';
    } else if (status === 53) {
      return 'newDispute';
    } else {
      return 'inProgress';
    }
  }
  
  carrierStrenth(info: any) {
    let numberOfFiled = 12;
    let fullStrenth = 100;
    let totalStrenth = 0;
    let remainingPoints = { totalStrenth: totalStrenth, otherData: [] }
    let numberOfValue = fullStrenth / numberOfFiled;

    if (info.legalName !== null) {
      totalStrenth += numberOfValue;
    }

    if (info.dotNumber !== null) {
      totalStrenth += numberOfValue;
    }

    if (info.telephone) {
      totalStrenth += numberOfValue;
    }

    if (info.email !== null) {
      totalStrenth += numberOfValue;
    }

    if (info.profileImage !== null) {
      totalStrenth += numberOfValue;
    }
    if (info.bannerImage !== null) {
      totalStrenth += numberOfValue;
    }

    if (info.signatureFont !== null) {
      totalStrenth += numberOfValue;
    }
    // document
    if (
      info.amountOfInsurance ! == null || 
      info.value ! == null || 
      info.companyName ! == null || 
      info.companyEmail ! == null)
     {
      totalStrenth += numberOfValue;
    }
    //address
    if (
      info.phyAddressLine1 !== null ||
      info.phyAddressLine2 !== null ||
      info.phyCity !== null ||
      info.phyState !== null ||
      info.phyCountry !== null ||
      info.phyZip !== null
    ) {
      totalStrenth += numberOfValue;
    }

    //Equipment & Lanes
    if (info.equipmentType !== null) {
      totalStrenth += numberOfValue;
    }
    if (info.preferredRegions.length > 0 || info.regions === 1) {
      totalStrenth += numberOfValue;
    }

    if(info.firstName ! == null || 
      info.LastName ! == null){
      totalStrenth += numberOfValue;
    }
    remainingPoints.totalStrenth = totalStrenth;

    return remainingPoints;

  }

  shipperStrenth(info: any) {
    let numberOfFiled = 13;
    let fullStrenth = 100;
    let totalStrenth = 0;
    let remainingPoints = { totalStrenth: totalStrenth, otherData: [] }
    let numberOfValue = fullStrenth / numberOfFiled;
    if (info.companyName !== null) {
      totalStrenth += numberOfValue;
    }

    if(info.firstName !== null ||  info.lastName !== null){
      totalStrenth += numberOfValue;
    }

    if(info.jobTitle !== null){
      totalStrenth += numberOfValue;
    }

    if(info.truckLoadType){
    
      totalStrenth += numberOfValue;
    }

    if (info.profileImage !== null) {
      totalStrenth += numberOfValue;
    }

    if (info.bannerImage !== null) {
      totalStrenth += numberOfValue;
    }

    if (info.mobileNumber !== null) {
      totalStrenth += numberOfValue;
    }

    if (info.signatureFont !== null) {
      totalStrenth += numberOfValue;
    }

    // Contract information
    if (info.contractDocument !== null) {
      totalStrenth += numberOfValue;
    } 
    // Contact information
    if (info.email !== null) {
      totalStrenth += numberOfValue; 
    }
    //address
    if (
      info.phyAddressLine1 !== null ||
      info.phyAddressLine2 !== null ||
      info.phyCity !== null ||
      info.phyState !== null ||
      info.phyCountry !== null ||
      info.phyZip !== null
    ) {
      totalStrenth += numberOfValue;

    }
    // Equipment type lane
    if (info.equipmentType !== null) {
      totalStrenth += numberOfValue;

    } 
    if (info.preferredRegions.length > 0 || info.regions === 1) {
      totalStrenth += numberOfValue;

    } 
    remainingPoints.totalStrenth = totalStrenth;
    return remainingPoints;
  }
}
