<div class="wapper_body_rb_con">
  <div class="create_lanes_p" id="booking_tab">
    <div class="recurring_lanes_filter"
      *ngIf="currentStatus=='allLanes' || currentStatus=='quotedLanes' || currentStatus=='confirmedLanes' || currentStatus=='archivedLanes'">
      <div class="filter_input_bx" *ngIf="skeletonLoader">
        <div class="row">
          <div class="col-md-2 ic_dropdown">
            <p class="fltr_lans_lbl fs_12 fsn_4 txt_lb">Equipment Type</p>
            <mat-form-field appearance="none" class="removeappearance" class="drop_bg">
              <mat-select placeholder="Select Equipment Type" class="form-control custom_input"
                [(ngModel)]="searchModel.equipmentId" #equipmentId="ngModel" [ngModelOptions]="{standalone: true}">
                <mat-option *ngFor="let equip of equipmentListing" [value]="equip?.id"
                  (click)="equipmentSelected(equip)">{{equip?.label}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-md-2 ic_dropdown">
            <p class="fltr_lans_lbl fs_12 fsn_4 txt_lb">State</p>
            <mat-form-field appearance="none" class="">
              <mat-select class="form-control custom_input" placeholder="Select State" [(ngModel)]="searchModel.stateId"
                #stateId="ngModel" [ngModelOptions]="{standalone: true}">
                <mat-option class="mat_dropdown" *ngFor="let state of phStates" [value]="state?.sortname"
                  (click)="stateSelected(state)" class="mat_dropdown"> {{state?.name}}</mat-option>
              </mat-select>
              <mat-error class="error_mess"></mat-error>
            </mat-form-field>
          </div>

          <div class="col-md-3">
            <p class="fltr_lans_lbl fs_12 fsn_4 txt_lb">Search</p>
            <mat-form-field appearance="none">
              <input matInput placeholder="Search by Lane Name and Lane ID" maxlength="64"
                [(ngModel)]="searchModel.title" #title="ngModel" [ngModelOptions]="{standalone: true}"
                (keyup)="searchQuantity($event)" autocomplete="off" class="form-control custom_input">
            </mat-form-field>
          </div>

          <div class="col-md-4 d-flex align-items-end">
            <div class="filter_btn">
              <span class="ap_btn unactive_ap_btn" *ngIf="filterCheck">Apply Filter</span>
              <span class="ap_btn active_ap_btn" *ngIf="!filterCheck" (click)="applyFilterSubmit()">Apply Filter</span>
              <span *ngIf="filterCheck" class="ap_btn unactive_clr_btn ms-1">Clear All</span>
              <span *ngIf="!filterCheck" (click)="clearFilter()" class="ap_btn active_clr_btn ms-1">Clear All</span>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4">
      <app-load-view-filter [titleFilter]="titleFilter" [stateShow]="stateShow" [isLane]="isLane" [equipmentNameShow]="equipmentNameShow" (clearOneFilter)="singleFilterClose($event)"></app-load-view-filter>
    </div>
      <div class="" *ngIf="!skeletonLoader">
        <ngx-skeleton-loader [theme]="{ width: '100%', height: '12px' }" class=""></ngx-skeleton-loader>
        <ngx-skeleton-loader [theme]="{ width: '100%', height: '30px' }" class=""></ngx-skeleton-loader>
      </div>
    </div>
    <div style="position: relative;">
      <div class="booking_lane_container network_container cus_matTab">
        <div animationDuration="0ms" class="parent_tabs">
          <div label="Lanes" id="lanes">
            <div class="" *ngIf="userType === 'SHIPPER'">
              <div *ngIf="!skeletonLoader" class="mb-1 mt-4">
                <ngx-skeleton-loader [theme]="{ width: '110px', height: '13px' }" class="loader_m_0 skt_btn_loader mb-2"
                  *ngFor="let fake of generateFake(4)"></ngx-skeleton-loader>
              </div>

              <div class="lane_sub_tabs mt-4" *ngIf="skeletonLoader">
                <span class="tabLabel"
                  [ngClass]="currentStatus === 'allLanes' || currentStatus === 'quotedLanes' ? 'active' : ''"
                  (click)="subTabMenuFilter('allLanes')">Posted Lanes ({{totalLanes}})</span>
                <span class="tabLabel" [ngClass]="currentStatus === 'confirmedLanes' ? 'active' : ''"
                  (click)="subTabMenuFilter('confirmedLanes')">Confirmed - Create Load From Lane
                  ({{confirmedLanesCount}})</span>
                <span class="tabLabel"
                  [ngClass]="currentStatus === 'allLoadsByLane' || currentStatus=='quotedLoadsByLane' ? 'active' : ''"
                  (click)="subTabMenuFilter('allLoadsByLane')">Created Loads ({{createdLaneLoadsCount}})</span>
                <!-- <span *ngIf="isCreateByLaneCount" class="badge custom_mess_badge">{{createByLaneCount}}</span> -->

                <span class="tabLabel" [ngClass]="currentStatus === 'paymentPendingByLane' ? 'active' : ''"
                  (click)="subTabMenuFilter('paymentPendingByLane')" style="position: relative;">
                  Payment Pending({{paymentPendingByLaneCount}})
                  <!-- <span *ngIf="isPaymentPendingByLaneCount" class="badge custom_mess_badge">{{paymentPendingByLanesCount}}</span> -->
                </span>
                <span [ngClass]="currentStatus === 'archivedLanes' ? 'active' : ''" class="tabLabel"
                  (click)="subTabMenuFilter('archivedLanes')">Archived Lanes ({{archivedLanesCount}})</span>
                <span [ngClass]="currentStatus === 'expiredLoadsByLane' ? 'active' : ''" class="tabLabel"
                  (click)="subTabMenuFilter('expiredLoadsByLane')">Expired Loads ({{expiredLoadsByLaneCount}})</span>
                <!-- <span *ngIf="isExpiredByLane" class="badge custom_mess_badge">{{expiredByLaneCount}}</span> -->
              </div>
              <div class="upcomming_sub_tabs mb-4 mt-3"
                *ngIf="currentStatus=='allLanes' || currentStatus=='quotedLanes'">
                <div *ngIf="!skeletonLoader">
                  <ngx-skeleton-loader [theme]="{ width: '87px', height: '14px' }"
                    class="loader_m_0 mb-2"></ngx-skeleton-loader>
                  <ngx-skeleton-loader [theme]="{ width: '87px', height: '14px' }"
                    class="loader_m_0 mb-2 ms-3"></ngx-skeleton-loader>
                </div>
                <div *ngIf="skeletonLoader">
                  <span class="tabLabel fs_14 lane_subtabs" (click)="subTabMenuFilter('allLanes')"
                    [ngClass]="currentStatus === 'allLanes' ? 'tabActive_line' : ''">
                    <span>All Lanes </span>
                    <span *ngIf="tabCount"> ({{allLanesCount ? allLanesCount : '0'}})</span>
                  </span>

                  <span class="tabLabel fs_14 lane_subtabs" (click)="subTabMenuFilter('quotedLanes')"
                    [ngClass]="currentStatus === 'quotedLanes' ? 'tabActive_line' : ''">
                    <span>Quoted Lanes</span>
                    <span *ngIf="tabCount"> ({{currentquotedLanesCount ? currentquotedLanesCount : '0'}})</span>
                  </span>
                </div>
              </div>
              <div class="upcomming_sub_tabs"
                *ngIf="currentStatus === 'allLoadsByLane' || currentStatus === 'quotedLoadsByLane'">
                <div *ngIf="!skeletonLoader">
                  <ngx-skeleton-loader [theme]="{ width: '87px', height: '14px' }"
                    class="loader_m_0 skt_btn_loader mb-2"></ngx-skeleton-loader>
                  <ngx-skeleton-loader [theme]="{ width: '87px', height: '14px' }"
                    class="loader_m_0 skt_btn_loader mb-2"></ngx-skeleton-loader>
                </div>
                <div *ngIf="skeletonLoader">
                  <span class="tabLabel fs_14 lane_subtabs" (click)="subTabMenuFilter('allLoadsByLane')"
                    [ngClass]="currentStatus === 'allLoadsByLane' ? 'tabActive_line' : ''">
                    <span>All Loads</span>
                    <span> ({{allLoadsByLaneCount ? allLoadsByLaneCount : '0'}})</span>
                    <!-- <span *ngIf="allLanesCounts" class="badge custom_mess_badge">{{allLanesCounts}}</span> -->
                  </span>

                  <span class="tabLabel fs_14 lane_subtabs" (click)="subTabMenuFilter('quotedLoadsByLane')"
                    [ngClass]="currentStatus === 'quotedLoadsByLane' ? 'tabActive_line' : ''">
                    <span>Quoted Loads</span>
                    <span> ({{quotedLoadsByLaneCount ? quotedLoadsByLaneCount : '0'}})</span>
                    <!-- <span *ngIf="isQuotedLoadsByLane" class="badge custom_mess_badge">{{quotedLoadsByLaneCount}}</span> -->
                  </span>
                </div>
              </div>

              <div *ngIf="currentStatus=='allLanes' || currentStatus=='quotedLanes' || currentStatus=='confirmedLanes' || currentStatus=='archivedLanes'">
                <div class="height_78vh" *ngIf="skeletonLoader && lanesList?.length > 0" infiniteScroll [infiniteScrollDistance]="3" [infiniteScrollThrottle]="100" 
                  (scrolled)="onScroll()" [scrollWindow]="false">
                  <div class="row" >
                    <div class="col-md-3" *ngFor="let laneRecord of lanesList">
                      <app-lane-card [laneRecord]="laneRecord" [currentRouteParams]="currentRouteParams"
                        [currentStatus]="currentStatus" [subscriptionData]="subscriptionData" [isDisabled]="isDisabled"
                        (eventPassAction)="eventAction($event)"></app-lane-card>
                    </div>
                  </div>
                </div>
                <div class="row mx-auto" *ngIf="!skeletonLoader && lanesList?.length === 0">
                  <div class="col-lg-3 col-md-6" *ngFor="let fake of generateFake(4)">
                    <app-load-skeleton-card></app-load-skeleton-card>
                  </div>
                </div>
                <div class="row mx-auto" *ngIf="skeletonLoader && lanesList?.length === 0">
                  <div class="col-md-12">
                      <app-load-empty-screen [currentStatus]="currentStatus"
                      [currentSearchStatus]="currentSearchStatus"></app-load-empty-screen>
                  </div>
                </div>
              </div>
              <div class="height_78vh" *ngIf="currentStatus =='allLoadsByLane' || currentStatus =='quotedLoadsByLane' || currentStatus =='paymentPendingByLane' || currentStatus =='expiredLoadsByLane'" infiniteScroll [infiniteScrollDistance]="3" [infiniteScrollThrottle]="100" (scrolled)="onScroll()"
              [scrollWindow]="false">
              <div class="row mt-4">
                <div class="col-lg-3 col-md-6" *ngFor="let sportRateLoadCreated of createdLoadList">
                  <app-spot-loads-card [shipmentRecord]="sportRateLoadCreated" [subscriptionData]="subscriptionData"
                    (eventPassAction)="eventAction($event)" [connectStripe]="connectStripe" [isMLWallet]="isMLWallet"
                    [currentStatus]="currentStatus" [currentRouteParams]="currentRouteParams"></app-spot-loads-card>
                </div>
                <div class="row mx-auto" *ngIf="skeletonLoader && createdLoadList?.length === 0">
                  <div class="col-md-12">
                    <app-load-empty-screen [currentStatus]="currentStatus"
                      [currentSearchStatus]="currentSearchStatus"></app-load-empty-screen>
                  </div>
                </div>
                <div *ngIf="!skeletonLoader && createdLoadList?.length === 0">
                  <div class="row mx-auto">
                    <div class="col-lg-3 col-md-6" *ngFor="let fake of generateFake(4)">
                      <app-load-skeleton-card></app-load-skeleton-card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
            <div class="" *ngIf="userType === 'CARRIER'">
              <div *ngIf="!skeletonLoader" class="mb-4">
                <ngx-skeleton-loader [theme]="{ width: '87px', height: '14px' }"
                  class="loader_m_0 skt_btn_loader mb-2"></ngx-skeleton-loader>
                <ngx-skeleton-loader [theme]="{ width: '87px', height: '14px' }"
                  class="loader_m_0 skt_btn_loader mb-2"></ngx-skeleton-loader>
              </div>
              <div class="lane_sub_tabs" *ngIf="skeletonLoader">
                <span class="tabLabel"
                  [ngClass]="currentStatus === 'allLanes' || currentStatus === 'quotedLanes' ? 'active' : ''"
                  (click)="subTabMenuFilter('allLanes')">Available Lanes ({{totalLanes}})</span>
                <span class="tabLabel" [ngClass]="currentStatus === 'confirmedLanes' ? 'active' : ''"
                  (click)="subTabMenuFilter('confirmedLanes')">Confirmed Lane Rates ({{confirmedLanesCount}})</span>
                <span class="tabLabel"
                  [ngClass]="currentStatus === 'allLoadsByLane' || currentStatus === 'quotedLoadsByLane' ? 'active' : ''"
                  (click)="subTabMenuFilter('allLoadsByLane')">Loads Available From Lanes ({{createdLaneLoadsCount}})</span>
                <span class="tabLabel" [ngClass]="currentStatus === 'loadsAwardedByLane' ? 'active' : ''"
                  (click)="subTabMenuFilter('loadsAwardedByLane')">Loads Awarded ({{loadsAwardedByLaneCount}})</span>
                <!-- <span *ngIf="isAwardedBidByLanes" class="badge custom_mess_badge">{{awardedBidByLanesCount}}</span> -->

                <span [ngClass]="currentStatus === 'loadsLostByLane' ? 'active' : ''" class="tabLabel"
                  (click)="subTabMenuFilter('loadsLostByLane')">Loads Lost ({{loadsLostByLaneCount}})</span>
              </div>
              <div class="upcomming_sub_tabs" *ngIf="currentStatus=='allLanes' || currentStatus=='quotedLanes'">
                <div *ngIf="!skeletonLoader">
                  <ngx-skeleton-loader [theme]="{ width: '87px', height: '14px' }"
                    class="loader_m_0 mb-2 me-3"></ngx-skeleton-loader>
                  <ngx-skeleton-loader [theme]="{ width: '87px', height: '14px' }"
                    class="loader_m_0 mb-2"></ngx-skeleton-loader>
                </div>
                <div *ngIf="skeletonLoader">
                  <span class="tabLabel fs_14 lane_subtabs" (click)="subTabMenuFilter('allLanes')"
                    [ngClass]="currentStatus === 'allLanes' ? 'tabActive_line' : ''">
                    <span>All Lanes </span>
                    <span *ngIf="tabCount"> ({{allLanesCount}})</span>
                    <!-- <span *ngIf="allLanesCount" class="">{{allLanesCount}}</span> -->
                  </span>

                  <span class="tabLabel fs_14 lane_subtabs" (click)="subTabMenuFilter('quotedLanes')"
                    [ngClass]="currentStatus === 'quotedLanes' ? 'tabActive_line' : ''">
                    <span>Quoted Lanes</span>
                    <span *ngIf="tabCount"> ({{currentquotedLanesCount}})</span>
                    <!-- <span *ngIf="isCurrentBidsForC" class="">{{currentquotedLanesCount}}</span> -->
                  </span>


                </div>
              </div>
              <div class="upcomming_sub_tabs"
                *ngIf="currentStatus === 'allLoadsByLane' || currentStatus === 'quotedLoadsByLane'">
                <div *ngIf="!skeletonLoader">
                  <ngx-skeleton-loader [theme]="{ width: '87px', height: '14px' }"
                    class="loader_m_0 skt_btn_loader mb-2"></ngx-skeleton-loader>
                  <ngx-skeleton-loader [theme]="{ width: '87px', height: '14px' }"
                    class="loader_m_0 skt_btn_loader mb-2"></ngx-skeleton-loader>
                </div>
                <div *ngIf="skeletonLoader">
                  <span class="tabLabel fs_14 lane_subtabs" (click)="subTabMenuFilter('allLoadsByLane')"
                    [ngClass]="currentStatus === 'allLoadsByLane' ? 'tabActive_line' : ''">
                    <span>All Loads</span>
                    <span> ({{allLoadsByLaneCount ? allLoadsByLaneCount : '0'}})</span>
                    <!-- <span *ngIf="allLanesCounts" class="badge custom_mess_badge">{{allLanesCounts}}</span> -->
                  </span>

                  <span class="tabLabel fs_14 lane_subtabs" (click)="subTabMenuFilter('quotedLoadsByLane')"
                    [ngClass]="currentStatus === 'quotedLoadsByLane' ? 'tabActive_line' : ''">
                    <span>Quoted Loads</span>
                    <span> ({{quotedLoadsByLaneCount ? quotedLoadsByLaneCount : '0'}})</span>
                    <!-- <span *ngIf="isQuotedLoadsByLane" class="badge custom_mess_badge">{{quotedLoadsByLaneCount}}</span> -->
                  </span>
                </div>
              </div>

              <div *ngIf="currentStatus=='allLanes' || currentStatus=='quotedLanes' || currentStatus=='confirmedLanes'">
                <div class="height_78vh" *ngIf="skeletonLoader && lanesList?.length > 0" infiniteScroll [infiniteScrollDistance]="3" [infiniteScrollThrottle]="100" 
                  (scrolled)="onScroll()" [scrollWindow]="false">
                  <div class="row mt-4" >
                    <div class="col-md-3" *ngFor="let laneRecord of lanesList">
                      <app-lane-card [laneRecord]="laneRecord" [currentRouteParams]="currentRouteParams"
                        [currentStatus]="currentStatus" [subscriptionData]="subscriptionData"
                        (eventPassAction)="eventAction($event)"></app-lane-card>
                    </div>
                  </div>
                </div>
                <div class="row mx-auto mt-4" *ngIf="skeletonLoader && lanesList?.length === 0">
                  <div class="col-md-12">
                    <app-load-empty-screen [currentStatus]="currentStatus"
                      [currentSearchStatus]="currentSearchStatus"></app-load-empty-screen>
                  </div>
                </div>
                <div class="row mx-auto mt-4" *ngIf="!skeletonLoader && lanesList?.length === 0">
                  <div class="col-lg-3 col-md-6" *ngFor="let fake of generateFake(4)">
                    <app-load-skeleton-card></app-load-skeleton-card>
                  </div>
                </div>
              </div>

              <div *ngIf="currentStatus =='allLoadsByLane' || currentStatus =='quotedLoadsByLane' || currentStatus =='loadsAwardedByLane' || currentStatus =='loadsLostByLane'" infiniteScroll [infiniteScrollDistance]="3" [infiniteScrollThrottle]="100" (scrolled)="onScroll()" [scrollWindow]="false" class="height_78vh">
                <div class="row mt-3">
                  <div class="col-lg-3 col-md-6" *ngFor="let sportRateLoadCreated of createdLoadList">
                    <app-spot-loads-card [shipmentRecord]="sportRateLoadCreated" [subscriptionData]="subscriptionData"
                      (eventPassAction)="eventAction($event)" [connectStripe]="connectStripe" [isMLWallet]="isMLWallet"
                      [currentStatus]="currentStatus" [currentRouteParams]="currentRouteParams"></app-spot-loads-card>
                  </div>
                </div>
                <div class="row mx-auto" *ngIf="skeletonLoader && createdLoadList?.length === 0">
                  <div class="col-md-12">
                    <app-load-empty-screen [currentStatus]="currentStatus"
                      [currentSearchStatus]="currentSearchStatus"></app-load-empty-screen>
                  </div>
                </div>
                <div *ngIf="!skeletonLoader && createdLoadList?.length === 0">
                  <div class="row mx-auto">
                    <div class="col-lg-3 col-md-6" *ngFor="let fake of generateFake(4)">
                      <app-load-skeleton-card></app-load-skeleton-card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="content_loader mt-3 mb-4" *ngIf="(lanesList?.length > 0 && spinnerLoader) || (createdLoadList?.length > 0 && spinnerLoader)">
          <span class="loader_image">
            <img src="{{serverPathForUrl}}/portal/images/after-login/cl-icons/loader-blue.svg"
              class="img-fluid">
          </span>
        </div>
        <div *ngIf="(lanesList?.length > 0 && dataNotFound && totalPage > 1) || (createdLoadList?.length > 0 && dataNotFound && totalPage > 1)" class="noMore_record">
          <span>There are no more records to show.</span>
        </div>
      </div>
    </div>

    <div class="net_filter_popup" *ngIf="showAdvancedFilter">
      <div>
        <div class="container filtr_top_arrow">
          <div class="custum_input_css" style="margin: 0 0 12px;">
            <div class="row">
              <div class="">
                <div style="margin: 0 35px 0 0;">
                  <div class="row">
                    <div class="col-md-6 ic_dropdown">
                      <p class="fs_14 fsn_4 txt_lb mb-2">Equipment Type</p>
                      <mat-form-field appearance="none" class="removeappearance" class="drop_bg">
                        <mat-select placeholder="Equipment Type" class="form-control custom_input"
                          [(ngModel)]="searchModel.equipmentId" #equipmentId="ngModel"
                          [ngModelOptions]="{standalone: true}">
                          <mat-option (click)="equipmentEmpty()">Equipment Type</mat-option>
                          <mat-option *ngFor="let equip of equipmentListing" [value]="equip.id"
                            (click)="equipmentSelected(equip)">{{equip.label}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div style="margin: 0 0 0 35px;">
                  <div class="row">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="net_filter_action m-0">
          <div class="container">
            <div class="row">
              <div class="action_btn">
                <span class="pointer txt_p fs_16 fsm_5 " (click)="clearFilter()">Clear All</span>
                <div>
                  <span (click)="closeFilterPopup()" class="cancel_btn pointer">Cancel</span>
                  <button type="button" class="btn custum_button button_success"
                    (click)="applyFilterSubmit()">Apply</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="" style="position: relative;" *ngIf="sidePanelProfileDataId">
  <div class="side-show-panel">
    <div class="side_bar_con">
      <app-shipper-side-panel [sidePanelProfileDataId]="sidePanelProfileDataId" [userType]="userType"
        [subscriptionData]="subscriptionData" (closeSidePanel)="closePanel($event)"></app-shipper-side-panel>
    </div>
  </div>
</div>
<div class="" style="position: relative;" *ngIf="sidePanelData && (userType=='CARRIER' || currentStatus=='expiredLoads' || currentStatus=='expiredLoadsByLane')">
  <div class="side-show-panel">
    <div class="side_bar_con">
      <app-spot-loads-side-panel [shipmentDetail]="sidePanelData" [currentStatus]="currentStatus" [currentRouteParams]="currentRouteParams" [subscriptionData]="subscriptionData" (closeSidePanel)="closePanel($event)" (refreshList)="refreshListLoad()"></app-spot-loads-side-panel>
    </div>
  </div>
</div>