import { Component, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AlertService } from 'src/app/commons/service/alert.service';
import { environment } from '../../../environments/environment';
import { CommonService } from '../../commons/service/common.service';
import { SharedService } from '../../commons/service/shared.service';
import { AppSettings } from '../../commons/setting/app_setting';
import { DocumentPopupComponent } from '../document-popup/document-popup.component';

@Component({
  selector: 'app-document-multiple',
  templateUrl: './document-multiple.component.html',
  styleUrls: ['./document-multiple.component.css'],
})
export class DocumentMultipleComponent implements OnInit {
  public shipmentDocumentGet: any;
  public shipperDocumentGet: any;
  public documentSklitor = false;
  public serverPathForUrl: any;
  public userType: any;
  @Input() shipmentId: any;
  @Input() type: any;
  @Input() loadDetail: any;

  constructor(
    public dialogRef: MatDialogRef<DocumentPopupComponent>,
    public dialog: MatDialog,
    private alertService: AlertService,
    public sharedService: SharedService,
    public commonService: CommonService
  ) { }

  ngOnInit() {
    this.userType = localStorage.getItem('user_type');
    this.serverPathForUrl = environment.serverPathForUrl;
    this.documentSklitor = true;
    this.shipmentDocument();
  }

  generateFake(count: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes;
  }

  shipmentDocument(params = {}) {
    this.documentSklitor = true;
    let uri = null;
    params = {
      shipmentId: this.shipmentId,
      limit: 100,
      orderDir: 'ASC',
      orderBy: 'id',
    };
    //get dynamic URI for APIs
    if (params) uri = this.commonService.getAPIUriFromParams(params);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.SHIPMENT.SHIPMENTDOCUMENT,
      uri: uri,
    };
    this.commonService.getList(APIparams).subscribe(
      (ServerRes) => {
        if (ServerRes.success === true) {
          this.shipmentDocumentGet = ServerRes.response.shipmentMedias;
          for (var docIndexPickup in this.shipmentDocumentGet.medias) {
            this.shipmentDocumentGet.medias[docIndexPickup].createdAt = new Date(
              this.shipmentDocumentGet.medias[docIndexPickup].createdAt + ' ' + 'UTC'
            );
          }
          this.shipperDocumentGet = ServerRes.response.shipperMedias;
          for (var docIndex in this.shipperDocumentGet) {
            this.shipperDocumentGet[docIndex].updatedAt = new Date(
              this.shipperDocumentGet[docIndex].updatedAt + ' ' + 'UTC'
            );
          }
          this.documentSklitor = false;
        } else {
          this.shipmentDocumentGet = '';
          this.shipperDocumentGet = [];
          this.documentSklitor = false;
        }
      },
      (error) => {
        this.shipmentDocumentGet = '';
        this.shipperDocumentGet = [];
        this.documentSklitor = false;
      }
    );
  }

  imageCheck(url: any) {
    const extension = url.split('.').pop();
    if (extension === 'pdf') {
      return (
        this.serverPathForUrl +
        '/portal/images/after-login/cl-icons/gr-pdf-r.svg'
      );
    } else {
      return (
        this.serverPathForUrl + '/portal/images/after-login/cl-icons/gr-jpg.svg'
      );
    }
  }

  userPopup(type: any, userDetail: any) {
    event.preventDefault();
    event.stopPropagation();
    const dialogRef = this.dialog.open(DocumentPopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width: '670px',
      data: { openPop: type, id: this.shipmentId, title: userDetail },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.event === 'Ok') {
        this.shipmentDocument();
      }
    });
  }

  navigate(url) {
    window.open(url.toString(), '_blank')
  }

  deleteDocu(shipmentDocument) {
    event.preventDefault();
    event.stopPropagation();
    const dialogRef = this.dialog.open(DocumentPopupComponent, {
      disableClose: true,
      backdropClass: AppSettings.backdropClass,
      width: '670px',
      data: { openPop: 'remove_document', title: 'Remove Document', contain: 'Are you sure you want to delete the document?' },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.event === 'OK') {
        this.deleteDoc(shipmentDocument)
      }
    });
  }

  deleteDoc(shipperDocumentGet) {
    let uri = '';
    this.documentSklitor = true;
    let params = {
      docId: shipperDocumentGet.id,
    };
    //get dynamic URI for APIs
    if (params) uri = this.commonService.getAPIUriFromParams(params);
    let APIparams = {
      apiKey: AppSettings.APIsNameArray.SHIPMENT.SHIPPERDOCDELETE,
      uri: uri,
    };
    this.commonService.delete(APIparams).subscribe(
      (success) => {
        if (success.success === true) {
          this.alertService.showNotificationMessage(
            'success',
            'bottom',
            'right',
            'txt_s',
            'check_circle',
            'Shipper Document',
            success.message
          );
          this.shipmentDocument();
        } else if (success.success === false) {
          this.alertService.showNotificationMessage(
            'danger',
            'bottom',
            'right',
            'txt_d',
            'check_circle',
            'Shipper Document',
            success.message
          );
          this.shipmentDocument();
        }
      },
      (error) => {
        this.alertService.showNotificationMessage(
          'danger',
          'bottom',
          'right',
          'txt_g',
          'error',
          'Unexpected Error',
          AppSettings.ERROR
        );
        this.shipmentDocument();
      }
    );
  }

}
