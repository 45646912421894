import { Component, EventEmitter, Input, Output } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-load-view-filter',
  templateUrl: './load-view-filter.component.html',
  styleUrls: ['./load-view-filter.component.css']
})
export class LoadViewFilterComponent {
  public serverPathForUrl: any;
  @Input() titleFilter;
  @Input() title;
  @Input() pickupLocationShow;
  @Input() dropoffLocationShow;
  @Input() pickupDateFilter;
  @Input() dropOffDateFilter;
  @Input() driverNameShow;
  @Input() statusLabelShow;
  @Input() equipmentNameShow;
  @Input() selfId;
  @Input() carrierNameShow;
  @Input() stateShow;
  @Input() isLane;
  @Input() carrierNameListShow;
  @Output() clearOneFilter = new EventEmitter();

  constructor() { }

  ngOnInit() {
    this.serverPathForUrl = environment.serverPathForUrl;
  }

  // To close single filter
  singleFilterClose(event) {
    this.clearOneFilter.emit(event);
  }

}
