<div class="create_lanes_p">
    <div class="common_header">
      <div class="container">
        <span class="headding">
          <span class="material-icons-outlined pointe pointer fsb_6" (click)="moveBack()">
            arrow_back
          </span><span class=" fs_30 fw_500">Create New Lane</span>
        </span>
      </div>
    </div>
    <div class="container">
      <div class="row" style="margin-top: 20px;">
        <p class="step_discription ">Fill out the details below to quickly create a Lane and broadcast it to the
          Carriers.</p>
        <div class="location_added_card_con col-md-3" *ngFor="let wayPointCared of pickCardArrays; let i = index"
          [ngClass]="notVerifiedIndex[i] == 'error' ? 'error_card' : ''">
          <div class="location_added_card" [ngClass]="wayPointCared.type === 'pickup' ? 'l_pick' : 'l_drop'">
            <div class="lac_l">
              <div class="lac_lt">
                <p class="lac_tlt">{{wayPointCared?.title | titlecase}} {{wayPointCared?.locationOrder}} </p>
                <p class="lac_dis h_auto">{{wayPointCared.streetAdress ? wayPointCared.streetAdress :
                  wayPointCared.locationLongName}}</p>
                <p class="lac_dis h_auto">{{wayPointCared?.cityState ? wayPointCared?.cityState : '-'}}</p>
                <p class="lac_dis h_auto">{{wayPointCared?.countryName}} {{wayPointCared?.zip ? wayPointCared?.zip : '-'}}
                </p>
              </div>
            </div>
            <div class="lac_r">
              <img (click)="editWayPointCard(wayPointCared,i)"
                src="https://s3.us-east-1.amazonaws.com/staticprod.laneaxis.com/portal/images/after-login/edit_lb.svg"
                class="img-fluid">
              <img *ngIf="i > 0 && (i!= pickCardArrays.length -1 || wayPointCared.wayType == 'pick')"
                (click)="removeWayPointCard(i,wayPointCared.type,wayPointCared?.locationOrder)"
                src="https://s3.us-east-1.amazonaws.com/staticprod.laneaxis.com/portal/images/after-login/delete_lb.svg"
                class="img-fluid">
            </div>
          </div>
          <p class="error_mess" *ngIf="wayPointCared.same == [i]">This waypoint date & time should always be grathen then
            the last waypoint Date & Time.</p>
        </div>
      </div>
      <form [formGroup]="createNewLaneForm" (ngSubmit)="checkRoute(createNewLaneForm)" class="mb-0 custum_input_css"
        autocomplete="off" *ngIf="notSaved">
  
        <div class="creat_lanes_input_bx">
          <p class=" fs_21 fw_500 line_before mb-3">Create New Lane</p>
          <div class="row">
            <div class="col-md-4">
              <p class="fs_14 fsn_4 txt_lb mb-0">Lane Name<span class="text-danger ml_5">*</span> </p>
              <mat-form-field appearance="none" class="removeappearance">
                <input matInput type="text" formControlName="laneName" placeholder="Enter Lane Name" maxlength="65"
                  class="form-control custom_input" />
                <mat-error *ngIf="createNewLaneForm.get('laneName').errors?.['required'] && submitted"
                  class="error_mess">Lane name is required.</mat-error>
                <mat-error *ngIf="createNewLaneForm.get('laneName').errors?.['maxlength']" class="error_mess">Only 64
                  characters are allowed.</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-4">
              <p class="fs_14 fsn_4 txt_lb mb-0">Pickup Point<span class="text-danger ml_5"
                  *ngIf="pickCardArrays[0]?.wayType != 'pick'">*</span> </p>
              <mat-form-field appearance="none" class="removeappearance "
                [ngClass]="((this.createNewLaneForm.get('pickupLocation').value ) && !pickAutoNot && pickCardArrays && !shortNameErrorPick ) ? 'disableInuputField' : ''">
                <input
                  matTooltip="Network Carriers will only see pickup and dropoff cities, but exact addresses will only be shown to Carrier who is assigned the lane."
                  matInput ngx-gp-autocomplete (onAddressChange)="pickAutoCompleteSelected($event)"
                  (input)="noAutoselection('pick')" (focusout)="noFocusOut('pick')"
                  
                  [value]="createNewLaneForm.get('pickupLocation').value" formControlName="pickupLocation" maxlength="257"
                  placeholder="Enter Exact Address" class="form-control custom_input text_truncate"
                  [options]="searchRestriction" required />
                  <!-- <span>{{createNewLaneForm.get('pickupLocation').errors?.['required']}}</span>
                  <span>{{pickAutoNot}}</span> -->
                <mat-error
                  *ngIf="submitted && !pickAutoNot && createNewLaneForm.get('pickupLocation').errors?.['required']"
                  class="error_mess"> Pickup point is required.</mat-error>
                <span *ngIf="pickAutoNot" class="error_mess"> Pickup point is required.</span>
                <mat-error *ngIf="!pickAutoNot && createNewLaneForm.get('pickupLocation').errors?.['pattern']"
                  class="error_mess"> First letter should not be space.</mat-error>
                <small class="city_labl_address error_mess"
                  *ngIf="pickupCityError && createNewLaneForm.value.pickupLocation"> {{ pickupCityError }}</small>
                <small class="city_labl_address error_mess"
                  *ngIf="shortNameValue && createNewLaneForm.value.pickupLocation"> {{ shortNameErrorPick }}</small>
                <mat-error *ngIf="!pickAutoNot && createNewLaneForm.get('pickupLocation').errors?.['maxlength']"
                  class="error_mess"> Only 256 characters are allowed.</mat-error>
              </mat-form-field>
              <p class="add_more_btn"><button type="button" [disabled]="!createNewLaneForm.get('pickupLocation').value"
                  (click)="addMoreLocations('pick')"
                  [ngClass]="!createNewLaneForm.get('pickupLocation').value ? 'disableInuputField' : ''"
                  class=" fs_16 fw_500">Add More Pickup</button></p>
            </div>
            <div class="col-md-4">
              <p class="fs_14 fsn_4 txt_lb mb-0">Dropoff Point<span
                  *ngIf="pickCardArrays[pickCardArrays.length - 1]?.wayType != 'drop'" class="text-danger ml_5">*</span>
              </p>
              <mat-form-field appearance="none" class="removeappearance"
                [ngClass]="this.createNewLaneForm.get('dropLocation').value && !shortNameErrorDrop  && !dropAutoNot  ?'disableInuputField' : ''">
                <input
                  matTooltip="Network Carriers will only see pickup and dropoff cities, but exact addresses will only be shown to Carrier who is assigned the lane."
                  matInput ngx-gp-autocomplete (onAddressChange)="dropAutoCompleteSelected($event)"
                  (input)="noAutoselection('drop')" (focusout)="noFocusOut('drop')"
                  
                  [value]="createNewLaneForm.get('dropLocation').value" formControlName="dropLocation" maxlength="257"
                  placeholder="Enter Exact Address" class="form-control custom_input text_truncate"
                  [options]="searchRestriction" required />
                <mat-error
                  *ngIf="submitted && !dropAutoNot && createNewLaneForm.get('dropLocation').errors?.['required'] && !pickCardArrays[pickCardArrays.length - 1]?.wayType == 'pick'"
                  class="error_mess"> Dropoff Point is required.</mat-error>
                <span *ngIf="dropAutoNot" class="error_mess"> Dropoff Point is required.</span>
                <mat-error *ngIf="!dropAutoNot && createNewLaneForm.get('dropLocation').errors?.['pattern']"
                  class="error_mess"> First letter should not be space.</mat-error>
                <small class="city_labl_address error_mess"
                  *ngIf="dropoffCityError && createNewLaneForm.value.dropLocation"> {{ pickupCityError }}</small>
                <small class="city_labl_address error_mess"
                  *ngIf="shortNameErrorDrop && createNewLaneForm.value.dropLocation"> {{ shortNameErrorDrop }}</small>
                <mat-error *ngIf="!dropAutoNot && createNewLaneForm.get('dropLocation').errors?.['maxlength']"
                  class="error_mess"> Only 256 characters are allowed.</mat-error>
                <span *ngIf="pickCardArrays[pickCardArrays.length - 1]?.wayType == 'pick' && submitted "
                  class="error_mess"> Last point should be dropoff.</span>
              </mat-form-field>
  
  
              <p class="add_more_btn"><button type="button" [disabled]="!createNewLaneForm.get('dropLocation').value"
                  (click)="addMoreLocations('drop')"
                  [ngClass]="!createNewLaneForm.get('dropLocation').value ? 'disableInuputField' : ''"
                  class=" fs_16 fw_500">Add More Dropoff</button></p>
            </div>
  
            <div class="col-md-12 d-flex justify-content-end" *ngIf="!ifPickupLoadInfo">
              <p type="button" class="add_goods mb-1" (click)="addLoadInformation()">
                <span class="ms-1 ">Add Commodity</span>
                <span class="material-icons txt_p fs_20 ms-1">add_circle</span>
              </p>
            </div>
            <div class="ship_goods_info" *ngIf="ifPickupLoadInfo">
              <div formArrayName="commodityForm">
                <p class="fs_20 fsb_6 txt_b line_before  mb-4">Commodity</p>
                <div class="sgi_row" *ngFor="let communityData of commodityForm.controls; let i = index;"
                  [formGroupName]="i">
                  <div class="row">
                    <div class="col-md-3">
                      <p class="fs_15 fsn_4 txt_lb">Commodity<span class="txt_d"> *</span></p>
                      <mat-form-field appearance="none">
                        <input matInput type="text" formControlName="commodity" placeholder="Enter Commodity"
                          maxlength="129" class="form-control custom_input" />
                        <mat-error *ngIf="communityDataSubmiited && communityData.get('commodity').errors?.['required']"
                          class="error_mess">Commodity is required</mat-error>
                        <mat-error *ngIf="communityData.get('commodity').errors?.['maxlength']" class="error_mess">Only
                          128 characters are allowed</mat-error>
                      </mat-form-field>
                    </div>
  
                    <div class="col-md-3">
                      <p class="fs_15 fsn_4 txt_lb">Weight <span class="txt_d"> *</span></p>
                      <mat-form-field appearance="none">
                        <input matInput type="text" formControlName="weight" placeholder="Enter Weight"
                          appZeroNotAllowFirst maxlength="6" class="form-control custom_input" />
                        <mat-error *ngIf="communityDataSubmiited && communityData.get('commodity').errors?.['required']"
                          class="error_mess">Commodity weight is required</mat-error>
                      </mat-form-field>
                    </div>
  
                    <div class="col-md-12 custom_checkbox mb-3">
                      <mat-checkbox class="form-control border-0 ps-0" formControlName="handleWithCare"
                        style="border: none;">Handle With Care</mat-checkbox>
                    </div>
                    <span (click)="removeCommunityField(i)"
                      class="material-icons txt_d fs_18 remove_row_icn">cancel</span>
                  </div>
                </div>
              </div>
              <p type="button" class="add_goods d-flex justify-content-end align-items-center"
                (click)="addCommunityField()">
                <span class=" fs_16 fw_500 txt_b t_end">Add More Commodity</span>
                <span class="material-icons txt_p fs_20 ms-1">add_circle</span>
              </p>
            </div>
  
            <div>
              <div class="row">
                <div class="col-md-4 ic_dropdown">
                  <p class="fs_14 fsn_4 txt_lb mb-0">Equipment Type <span class="txt_d"> *</span></p>
                  <mat-form-field appearance="none" class="removeappearance" class="drop_bg">
                    <mat-select placeholder="Select Equipment Type" class="form-control custom_input"
                      formControlName="equipmentType" (selectionChange)="selectEquipmentLength($event)">
                      <mat-option *ngFor="let equi of equipmentListing" [value]="equi.id">{{equi.label}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="createNewLaneForm.get('equipmentType').errors?.['required'] && submitted"
                      class="error_mess">Equipment type is required.</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-4 ic_dropdown">
                  <p class="fs_14 fsn_4 txt_lb mb-0">Equipment Length <span class="txt_d"> *</span></p>
                  <mat-form-field appearance="none" class="removeappearance" class="drop_bg">
                    <mat-select placeholder="Select Equipment Length" class="form-control custom_input"
                      formControlName="equipmentTypeLength" [disabled]="checkEmptyValue === '0' ? true : false">
                      <mat-option *ngFor="let equipLength of equipmentLengthList" [value]="equipLength.lengthValue">{{
                        equipLength.lengthValue }}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="createNewLaneForm.get('equipmentTypeLength').errors?.['required'] && submitted"
                      class="error_mess">Equipment length is required.</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-md-4"></div>
                <div class="col-md-4">
                  <p class="fs_14 fsn_4 txt_lb mb-0">Monthly Frequency<span class="text-danger ml_5">*</span></p>
                  <mat-form-field appearance="none" class="removeappearance">
                    <input matInput type="text" formControlName="frequency" appZeroNotAllowFirst
                      placeholder="Enter Monthly Frequency" maxlength="65" class="form-control custom_input" />
                    <mat-error *ngIf="createNewLaneForm.get('frequency').errors?.['required'] && submitted"
                      class="error_mess">Frequency is required.</mat-error>
                  </mat-form-field>
                </div>.
  
                <div class="col-md-6 circle_box">
                  <p class="fs_14 fsn_4 txt_lb"><span>Weekly Frequency</span><span class="fs_12">(select weekly
                      reoccurrence)</span> </p>
                  <div class="latters_circles">
                    <div class="circles pointer" *ngFor="let weekNameSort of fequeryMonthShow; let i=index;"
                      [ngClass]="weekNameSort.isChecked === 'checked' ? 'active_circle' : '' "
                      (click)="selectWeekDays(weekNameSort,i)">
                      <span>{{weekNameSort.dayAbbr}}</span>
                    </div>
                  </div>
                  <mat-form-field appearance="outline" class="hide_input">
                    <input matInput type="text" formControlName="frequencyWeek" placeholder="Enter Monthly Frequency"
                      class="form-control custom_input" />
                  </mat-form-field>
                </div>
  
                <div class="col-md-4">
                  <p class="fs_14 fsn_4 txt_lb mb-3">Rate Type<span class="text-danger ml_5">*</span></p>
                  <p class="j_around">
                    <mat-radio-group aria-label="Select an option" formControlName="rateType"
                      (change)="changeRateType($event)">
                      <mat-radio-button value="1" class="fs_14 fsn_4 txt_lb ml-1">Quote Rate </mat-radio-button>
                      <mat-radio-button value="2" class="fs_14 fsn_4 txt_lb ml-1">Fixed Rate</mat-radio-button>
                    </mat-radio-group>
                  </p>
                </div>
                <br><br>
                
                <div class="col-md-4">
                  <p *ngIf="createNewLaneForm.get('rateType').value!=2" class="fs_14 fsn_4 txt_lb mb-0">Expected Rate</p>
                  <p *ngIf="createNewLaneForm.get('rateType').value==2" class="fs_14 fsn_4 txt_lb mb-0">Fixed Rate<span
                      class="txt_d"> *</span></p>
                  <mat-form-field appearance="none" class="removeappearance">
                    <input matInput type="text" formControlName="price" appTwoDigitDecimal placeholder="Enter Rate"
                      maxlength="10" class="form-control custom_input" />
                    <mat-error *ngIf="createNewLaneForm.get('price').errors?.['maxlength']" class="error_mess">Only 64
                      characters are allowed.</mat-error>
                    <span
                      *ngIf="createNewLaneForm.get('rateType').value==2 && !createNewLaneForm.get('price').value && submitted"
                      class="error_mess">Rate is required.</span>
                    <span *ngIf="createNewLaneForm.get('price').errors?.['min'] && submitted" class="error_mess">Rate
                      cannot be zero.</span>
                  </mat-form-field>
                </div>
  
                <div class="col-md-4 ic_dropdown">
                  <p class="fs_14 fsn_4 txt_lb mb-1">Lane Duration <span class="txt_d"> *</span></p>
                  <mat-form-field appearance="none" class="removeappearance" class="drop_bg">
                    <mat-select placeholder="Select lane duration time" class="form-control custom_input"
                      formControlName="lockPeriod" (selectionChange)="durationSelection($event)">
                      <mat-option *ngFor="let lane of laneDurations" [value]="lane">{{lane > 1 ? lane+' Months' : lane+' Month'}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="createNewLaneForm.get('lockPeriod').errors?.['required'] && submitted"
                      class="error_mess">Lane duration is required.</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <p class="creat_btn mb-4"><button type="submit">Save & Next</button></p>
        </div>
      </form>
    </div>
  </div>
  <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '14px',fullScreenBackdrop:true }"></ngx-loading>